/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { topCloseActions } from './topCloseState';
import { getDatas } from '../../../services/base.service';

function* getData() {
  try {
    const result = yield call(getDatas, REST_API.TOP.CLOSE);
    yield put(topCloseActions.getDatasSuccess(result));
  } catch (_) {}
}

function* topCloseSaga() {
  yield takeLatest('topClose/getDatas', getData);
}

export default topCloseSaga;
