import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import DeleteConfirmDialog from '../../../components/templates/deleteConfirmDialog/DeleteConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import Link from '../../../components/atoms/link/Link';
import urls from '../../../constants/frontUrls';
import RoleDetailDialog from './RoleDetailDialog';
import { checkIsDisabled } from '../../../utils/authCheck.helper';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [deleteConfopen, setDeleteConfopen] = React.useState(false);
  const [detailModalOpen, setDetailModalOpen] = React.useState(false);
  const isSaveSuccess = useSelector((state) => state.roleDetailStore.isSaveSuccess);
  const saveId = useSelector((state) => state.roleDetailStore.saveId);
  const userInfo = useSelector((state) => state.loginStore.userInfo).user;
  const navigate = useNavigate();
  const isDisabled = checkIsDisabled(userInfo);

  React.useEffect(() => {
    if (isSaveSuccess) {
      navigate(urls.ROLE_DETAIL + saveId);
    }
  }, [isSaveSuccess]);

  const {
    rows, totalElements,
    rowsPerPage, page, handleChangePage,
    handleChangeRowsPerPage, handleRequestSort,
    order, orderBy,
  } = props;

  const headCells = [
    { id: 'auth_name', label: '権限名' },
    { id: 'is_approval', label: '承認権限' },
    { id: 'is_affairs', label: '事務権限' },
    { id: 'is_bp', label: 'BP用' },
  ];

  const handleSelectAllClick = (event) => {
    let selectedData = [];
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const onPreDelete = () => {
    setDeleteConfopen(true);
  };

  const onDelete = () => {
    setDeleteConfopen(false);
    setSelected([]);
    props.onDelete(selected);
  };

  const handleClose = () => {
    setDeleteConfopen(false);
    setDetailModalOpen(false);
  };

  const onAdd = () => {
    setDetailModalOpen(true);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Tooltip title="権限の削除">
                <Button color="secondary" variant="outlined" onClick={onPreDelete} disabled={isDisabled}>権限の削除</Button>
              </Tooltip>
            </>
          )}
          {(selected.length === 0 && !isDisabled) && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div" />
              <Tooltip title="権限の追加">
                <Button color="primary" variant="outlined" onClick={onAdd} disabled={isDisabled}>権限の追加</Button>
              </Tooltip>
            </>
          )}
        </Toolbar>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!isDisabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((row) => row.deleteable).length === 0 || isDisabled}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!isDisabled && (
                      <TableCell padding="checkbox">
                        {row.deleteable && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                            disabled={isDisabled}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell>
                      <Link to={urls.ROLE_DETAIL + row.id}>{row.authName}</Link>
                    </TableCell>
                    <TableCell>{row.approval ? '有' : ''}</TableCell>
                    <TableCell>{row.affairs ? '有' : ''}</TableCell>
                    <TableCell>{row.bp ? 'BP' : ''}</TableCell>
                  </TableRow>
                );
              })}
              {rows.length < 1 && (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                    データがありません
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <DeleteConfirmDialog
          open={deleteConfopen}
          onClose={handleClose}
          onDelete={onDelete}
          procName="権限"
        />
        <RoleDetailDialog
          open={detailModalOpen}
          handleClose={handleClose}
        />
      </Paper>
    </div>
  );
}
