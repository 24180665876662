/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { companyBalanceActions } from './companyBalanceState';
import {
  getDatas,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.COMPANY_BALANCE.COMPANY_BALANCE, payload);
    yield put(companyBalanceActions.getDataSuccess(result));
  } catch (_) {}
}

function* companyBalanceSaga() {
  yield takeLatest('companyBalance/getData', getData);
}

export default companyBalanceSaga;
