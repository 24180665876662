/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { roleDetailActions } from './roleDetailState';
import {
  getDataById, saveData, updateData, getDatas,
} from '../../../services/base.service';

function* getScreen({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SETTING.SCREEN_LIST, payload);
    yield put(roleDetailActions.getScreenSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SETTING.ROLE_DETAIL_ID, payload);
    yield put(roleDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SETTING.ROLE_LIST, payload);
    if (payload.id) {
      yield put(roleDetailActions.updateDataSuccess(result));
    } else {
      yield put(roleDetailActions.saveDataSuccess(result));
    }
  } catch (_) {}
}

function* saveScreenData({ payload }) {
  try {
    yield call(updateData, REST_API.SETTING.ROLE_DETAIL_ID, payload);
    yield put(roleDetailActions.saveScreenDataSuccess());
  } catch (_) {}
}

function* roleDetailSaga() {
  yield takeLatest('roleDetail/getScreen', getScreen);
  yield takeLatest('roleDetail/getData', getData);
  yield takeLatest('roleDetail/saveData', saveDatas);
  yield takeLatest('roleDetail/saveScreenData', saveScreenData);
}

export default roleDetailSaga;
