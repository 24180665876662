/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { claimActions } from './claimState';
import {
  getDatas, deleteDatas, saveData, getDataById, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.CLAIM.CLAIM, payload);
    yield put(claimActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.CLAIM.CLAIM, payload);
    yield put(claimActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.CLAIM.CLAIM_ID, payload);
    yield put(claimActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.CLAIM.CLAIM, payload);
    yield put(claimActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.CLAIM.CLAIM, payload);
    yield put(claimActions.updateDataSuccess(result));
  } catch (_) {}
}

function* updateStatus({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.CLAIM.CLAIM_ID, payload);
    yield put(claimActions.updateStatusSuccess());
  } catch (_) {}
}

function* getPdfData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.CLAIM.CLAIM_PDF, payload);
    yield put(claimActions.getPdfDataSuccess(result));
  } catch (_) {}
}

function* updatePdfData({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.CLAIM.CLAIM_PDF, payload);
    yield put(claimActions.updatePdfDataSuccess());
  } catch (_) {}
}

function* claimSaga() {
  yield takeLatest('claim/getDatas', getData);
  yield takeLatest('claim/deleteData', deleteData);
  yield takeLatest('claim/getData', getDataFromId);
  yield takeLatest('claim/insertData', insertData);
  yield takeLatest('claim/updateData', updateDatas);
  yield takeLatest('claim/updateStatus', updateStatus);
  yield takeLatest('claim/getPdfData', getPdfData);
  yield takeLatest('claim/updatePdfData', updatePdfData);
}

export default claimSaga;
