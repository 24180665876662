import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { format, subDays } from 'date-fns';
import {
  Paper, Box, Button, CircularProgress,
} from '@mui/material';
import ja from 'date-fns/locale/ja';
import { workClockActions } from '../../redux/work/workClock/workClockState';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
}));

class Clock extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const yesterDay = subDays(new Date(), 1);
    this.state = {
      data: {},
      yesterDay: format(yesterDay, 'yyyy年MM月dd日'),
      yesterDayWeek: format(yesterDay, 'EEEEEE', { locale: ja }),
      yesterDayHour: parseInt(format(today, 'HH'), 10) + 24,
      yesterDayTime: format(today, 'mm:ss'),
      date: format(today, 'yyyy年MM月dd日'),
      time: format(today, 'HH:mm:ss'),
      week: format(today, 'EEEEEE', { locale: ja }),
    };
    this.props.dispatch(workClockActions.getDatas());
  }

  componentDidMount() {
    this.intervalId = setInterval(() => this.tick(), 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.workClock.data !== prevProps.workClock.data) {
      if (this.props.workClock.data) {
        this.setInitDatas(this.props.workClock.data);
      }
    }
    if (this.props.workClock.isSaveSuccess !== prevProps.workClock.isSaveSuccess) {
      if (this.props.workClock.isSaveSuccess) {
        this.successProc();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  successProc = () => {
    this.props.dispatch(workClockActions.getDatas());
  };

  setInitDatas = (data) => {
    this.setState({ data });
  };

  onClick = (_, start, isYesterday) => {
    const { data } = this.state;
    const targetDate = format(subDays(new Date(), isYesterday ? 1 : 0), 'yyyy/MM/dd');
    const hour = parseInt(format(new Date(), 'HH'), 10) + (isYesterday ? 24 : 0);
    const time = format(new Date(), 'mm');
    this.props.dispatch(workClockActions.saveData({
      targetDate,
      targetTime: `${hour}:${time}`,
      start,
      flex: data.flex,
    }));
  };

  tick() {
    const yesterDay = subDays(new Date(), 1);
    this.setState({
      time: format(new Date(), 'HH:mm:ss'),
      week: format(new Date(), 'EEEEEE', { locale: ja }),
      yesterDay: format(yesterDay, 'yyyy年MM月dd日'),
      yesterDayWeek: format(yesterDay, 'EEEEEE', { locale: ja }),
      yesterDayHour: parseInt(format(new Date(), 'HH'), 10) + 24,
      yesterDayTime: format(new Date(), 'mm:ss'),
    });
  }

  render() {
    const {
      date, time, week, yesterDay, yesterDayWeek, yesterDayHour, yesterDayTime, data,
    } = this.state;
    if (!data.target) {
      return (
        <Item>
          <CircularProgress />
        </Item>
      );
    }
    return (
      <div>
        {data.target === '3' && (
          <Item>
            <Box>{`${yesterDay}(${yesterDayWeek})`}</Box>
            <Box fontWeight="bold" fontSize={30}>{`${yesterDayHour}:${yesterDayTime}`}</Box>
            <Button variant="contained" onClick={(e) => this.onClick(e, false, true)}>退勤</Button>
          </Item>
        )}
        {data.target !== '3' && (
          <Item>
            <Box>{`${date}(${week})`}</Box>
            <Box fontWeight="bold" fontSize={30}>{time}</Box>
            {data.target === '1' && (
              <Box>
                <Button variant="contained" onClick={(e) => this.onClick(e, true, false)}>出勤</Button>
              </Box>
            )}
            {data.target === '2' && (
              <Box>
                <Button variant="contained" onClick={(e) => this.onClick(e, false, false)}>退勤</Button>
              </Box>
            )}
          </Item>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workClock: state.workClockStore,
});

export default connect(mapStateToProps)(Clock);
