import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Table, TableBody, TableCell, Box,
  TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';
import { isValid, format } from 'date-fns';
import { vacationActions } from '../../../redux/office/vacation/vacationState';
import withParams from '../../../components/atoms/withParam/WithParams';
import urls from '../../../constants/frontUrls';
import Link from '../../../components/atoms/link/Link';
import YearPicker from '../../../components/atoms/yearPicker/YearPicker';

class VacationDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const syainId = id;
    const targetYear = format(new Date(), 'yyyy/MM/dd');
    this.state = {
      data: {
        resultList: [],
        syainName: '',
      },
      syainId,
      targetYear,
    };
    props.dispatch(vacationActions.getDetailData({ syainId, targetYear: format(new Date(), 'yyyy') }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vacationInfo.detailData !== this.props.vacationInfo.detailData) {
      if (this.props.vacationInfo.detailData) {
        this.setData(this.props.vacationInfo.detailData);
      }
    }
  }

  setData(data) {
    this.setState({ data });
  }

  onChange = (event) => {
    const { syainId } = this.state;
    const { value } = event.target;
    if (!value || !isValid(new Date(value))) {
      return;
    }
    this.setState({ data: { resultList: [] }, targetYear: value });
    this.props.dispatch(vacationActions.getDetailData({ syainId, targetYear: format(new Date(value), 'yyyy') }));
  };

  render() {
    const { data, targetYear } = this.state;

    return (
      <Container maxWidth={false}>
        <Box mb={2} display="flex">
          <Box display="flex" alignItems="center" mr={2} sx={{ fontWeight: 'bold' }}>{data.syainName}</Box>
          <YearPicker
            handleChange={this.onChange}
            value={targetYear}
            name="targetYear"
          />
        </Box>
        <Paper>
          <TableContainer sx={{ maxHeight: 750 }}>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '10%' }}>付与日</TableCell>
                  <TableCell style={{ width: '10%' }}>付与数</TableCell>
                  <TableCell>使用日</TableCell>
                  <TableCell>使用数</TableCell>
                  <TableCell>残</TableCell>
                  <TableCell style={{ width: '50%' }}>備考</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.resultList.map((row) => (
                  <>
                    <TableRow hover key={row.id}>
                      <TableCell rowSpan={row.useList.length + 1} style={{ verticalAlign: 'top' }}>{row.grantDay}</TableCell>
                      <TableCell rowSpan={row.useList.length + 1} style={{ verticalAlign: 'top' }}>{row.grantCount}</TableCell>
                      <TableCell colSpan={2} />
                      <TableCell>{row.remaining}</TableCell>
                      <TableCell />
                    </TableRow>
                    {row.useList.map((detail) => (
                      <TableRow hover key={`${row.id}/${detail.id}`}>
                        <TableCell>
                          <Link to={urls.WORK_REQUEST_DETAIL + detail.id}>{detail.targetFrom}</Link>
                        </TableCell>
                        <TableCell>{detail.dispUseCount}</TableCell>
                        <TableCell>{detail.remaining}</TableCell>
                        <TableCell>{detail.reason}</TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
                {
                  data.resultList.length < 1 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  vacationInfo: state.vacationStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(VacationDetail));
