import React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog as MuiDialog } from '@mui/material';

const CustomDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Dialog(props) {
  const {
    children,
    isOpen,
    onClickClose,
    maxWidth,
    fullWidth,
  } = props;

  const onClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClickClose(event);
    }
  };

  return (
    <CustomDialog
      onClose={onClose}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      disableEscapeKeyDown
    >
      {children}
    </CustomDialog>
  );
}
