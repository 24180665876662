/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {},
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
  },
});

export const calendarActions = calendarSlice.actions;

export default calendarSlice.reducer;
