import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import {
  Paper, Box, Grid,
} from '@mui/material';
import { workDeviationActions } from '../../redux/work/workDeviation/workDeviationState';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
}));

class PrivateDeviation extends React.Component {
  constructor(props) {
    super(props);
    const syainId = props.loginUser && props.loginUser.userInfo ? props.loginUser.userInfo.user.id : '';
    const searchCondition = {
      targetDate: format(new Date(), 'yyyy/MM/dd'),
      syainId,
    };

    this.state = {
      data: {},
    };

    this.isSearch = false;
    if (syainId) {
      props.dispatch(workDeviationActions.getData(searchCondition));
      this.isSearch = true;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workDeviationInfo.datas !== this.props.workDeviationInfo.datas) {
      if (this.props.workDeviationInfo.datas) {
        this.setDatas(this.props.workDeviationInfo.datas);
      }
    }
    if (prevProps.loginUser.userInfo !== this.props.loginUser.userInfo) {
      if (this.props.loginUser.userInfo.user.id) {
        this.getData(this.props.workDeviationInfo.datas);
      }
    }
  }

  getData() {
    if (this.isSearch) {
      return;
    }
    const searchCondition = {
      targetDate: format(new Date(), 'yyyy/MM/dd'),
      syainId: this.props.loginUser.userInfo.user.id,
    };
    this.props.dispatch(workDeviationActions.getData(searchCondition));
  }

  setDatas(datas) {
    if (datas.length > 0) {
      this.setState({ data: datas[0] });
    }
  }

  render() {
    const { data } = this.state;
    if (!data.syainId) {
      return (
        <div />
      );
    }
    return (
      <Grid item xs={6} md={3}>
        <Item>
          <Box>作業時間乖離</Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box fontWeight="bold" fontSize={{ xs: 15, md: 20 }}>現時点</Box>
              <Box fontSize={20}>{data.nowDeviation}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box fontWeight="bold" fontSize={{ xs: 15, md: 20 }}>月末予想</Box>
              <Box fontSize={20}>{data.allDeviation}</Box>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  workDeviationInfo: state.workDeviationStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(PrivateDeviation);
