import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box, LinearProgress,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import { trafficRequestDetailActions } from '../../../../redux/request/trafficRequest/trafficRequestDetailState';

export default function ReceiptTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleSelectAllClick = (event) => {
    const { rows } = props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const {
    rows,
    loading,
    onAdd,
    header,
  } = props;

  const handleClose = () => {
    setConfOpen(false);
  };

  const onClickConfirm = () => {
    setConfOpen(true);
  };

  const onProcClick = () => {
    setConfOpen(false);
    props.onProcStart();
    dispatch(trafficRequestDetailActions.deleteFile({ ids: selected }));
    setSelected([]);
  };

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {(selected.length > 0 && header.statusCode === '0') && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Box mr={2}>
                <Tooltip title="削除">
                  <Button color="secondary" variant="contained" onClick={onClickConfirm}>削除</Button>
                </Tooltip>
              </Box>
            </>
          )}
          {(selected.length === 0 && header.statusCode === '0') && (
            <>
              <Box style={{ flex: '1 1 30%' }} sx={{ fontWeight: 'bold' }} ml={2}>
                定期券
              </Box>
              <Tooltip title="定期券アップロード">
                <Button color="primary" variant="outlined" onClick={onAdd}>定期券アップロード</Button>
              </Tooltip>
            </>
          )}
        </Toolbar>
        {loading && (<LinearProgress />)}
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell key="table-header-check" padding="checkbox">
                  {header.statusCode === '0' && (
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.length === 0}
                    />
                  )}
                </TableCell>
                <TableCell>名前</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      {header.statusCode === '0' && (
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell><Button href={row.url} target="_blank">{row.name}</Button></TableCell>
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={2}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmDialog
          isError
          open={confOpen}
          onClose={handleClose}
          title="選択した申請削除"
          content="選択した申請を削除します"
          onClick={onProcClick}
          procName="削除する"
        />
      </Paper>
    </Box>
  );
}
