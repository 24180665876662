import React from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  Container, Table, Box, ToggleButtonGroup, ToggleButton,
  TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon, TableView as TableViewIcon,
} from '@mui/icons-material';
import { workPlanActions } from '../../../redux/work/workPlan/workPlanState';
import { commonActions } from '../../../redux/common/commonState';
import { getCalnedarMonthly, getData, isRested } from './work-plan-proc';
import CalendarContent from './CalendarContent';
import SelectSyainWithMonth from '../../../components/organisms/selectSyainWithMonth/SelectSyainWithMonth';
import WorkPlanInputDialog from './WorkPlanInputDialog';
import WorkPlanDeleteDialog from './WorkPlanDeleteDialog';
import WorkTable from './WorkTable';

const HeaderTd = styled('th')(({ rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  verticalAlign: 'top',
  ...(rested && {
    color: 'red',
  }),
}));

const DataTd = styled('td')(({ disabled, rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  height: '80px',
  verticalAlign: 'top',
  ...(disabled && {
    background: '#e6e6e6',
    color: '#999999',
  }),
  ...(rested && {
    color: 'red',
  }),
  ...(!disabled && {
    '&:hover': {
      background: '#f1f3f4',
    },
  }),
}));

class WorkPlan extends React.Component {
  constructor(props) {
    super(props);
    let today = format(new Date(), 'yyyy/MM/dd');
    let syainId = '';
    if (props.loginInfo.userInfo) {
      syainId = this.props.loginInfo.userInfo.user.id;
    }
    if (this.props.workPlanInfo.searchCondition) {
      today = this.props.workPlanInfo.searchCondition.targetMonth;
    }
    this.state = {
      displayType: 'calendar',
      targetMonth: today,
      syainId,
      datas: [],
      isInputDialogOpen: false,
      isDeleteDialogOpen: false,
      targetData: {},
      modalTargetDate: '',
      summary: {},
      restCalendars: [],
      isBp: props.loginInfo.userInfo.user.bp,
    };
    if (syainId) {
      this.getDatas(syainId, today);
    }
    this.props.dispatch(workPlanActions.getRest());
    this.props.dispatch(commonActions.getRestCalendar({ targetMonth: today }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginInfo.userInfo !== prevProps.loginInfo.userInfo) {
      if (this.props.loginInfo.userInfo.user) {
        this.setInitDatas(this.props.loginInfo.userInfo.user.id);
      }
    }
    if (this.props.workPlanInfo.data !== prevProps.workPlanInfo.data) {
      if (this.props.workPlanInfo.data) {
        this.setDatas(this.props.workPlanInfo.data);
      }
    }
    if (this.props.workPlanInfo.isSaveSuccess !== prevProps.workPlanInfo.isSaveSuccess) {
      if (this.props.workPlanInfo.isSaveSuccess) {
        this.onGetDatas();
      }
    }
    if (this.props.workPlanInfo.isDeleteSuccess !== prevProps.workPlanInfo.isDeleteSuccess) {
      if (this.props.workPlanInfo.isDeleteSuccess) {
        this.onGetDatas();
      }
    }
    if (this.props.common.restCalendars !== prevProps.common.restCalendars) {
      if (this.props.common.restCalendars) {
        this.setRestCalendars(this.props.common.restCalendars);
      }
    }
  }

  setRestCalendars = (restCalendars) => {
    this.setState({ restCalendars });
  };

  setDatas = (data) => {
    this.setState({
      datas: data.datas,
      summary: {
        syoteiDays: data.syoteiDays,
        syoteiMinutes: data.syoteiTime.toFixed(2),
        workDays: data.workDays,
        workMinutes: data.workTime.toFixed(2),
        diff: data.diff.toFixed(2),
      },
    });
  };

  setInitDatas = (syainId) => {
    this.setState({ syainId });
    this.getDatas(syainId, this.state.targetMonth);
  };

  onGetDatas = () => {
    const { syainId, targetMonth } = this.state;
    this.getDatas(syainId, targetMonth);
  };

  getDatas = (syainId, targetMonth) => {
    this.props.dispatch(
      workPlanActions.getWorkPlan({
        syainId,
        targetMonth,
      }),
    );
  };

  onDisplayChange = (_, displayType) => {
    if (!displayType) {
      return;
    }
    this.setState({ displayType });
  };

  onChange = (event) => {
    const { syainId, targetMonth } = event;
    const originMonth = this.state.targetMonth;
    if (originMonth !== targetMonth) {
      this.props.dispatch(commonActions.getRestCalendar({ targetMonth }));
    }
    this.setState({ syainId, targetMonth });
    this.getDatas(syainId, targetMonth);
  };

  onDayClick = (_, data, modalTargetDate) => {
    this.setState({ isInputDialogOpen: true, targetData: data, modalTargetDate });
  };

  onDayDeleteClick = (_, data, modalTargetDate) => {
    this.setState({ isDeleteDialogOpen: true, targetData: data, modalTargetDate });
  };

  onModalCloseClick = () => {
    this.setState({ isInputDialogOpen: false, isDeleteDialogOpen: false });
  };

  render() {
    const {
      displayType, datas, targetMonth, isInputDialogOpen, targetData, modalTargetDate, syainId,
      isDeleteDialogOpen, summary, restCalendars, isBp,
    } = this.state;
    const calendarMonthly = getCalnedarMonthly(targetMonth);
    return (
      <Container maxWidth={false}>
        <WorkPlanInputDialog
          restCalendars={restCalendars}
          syainId={syainId}
          isOpen={isInputDialogOpen}
          onClickClose={this.onModalCloseClick}
          data={targetData}
          targetDateItem={modalTargetDate}
        />
        <WorkPlanDeleteDialog
          restCalendars={restCalendars}
          syainId={syainId}
          isOpen={isDeleteDialogOpen}
          onClickClose={this.onModalCloseClick}
          data={targetData}
          targetDateItem={modalTargetDate}
        />
        <Box>
          <SelectSyainWithMonth
            isBp={isBp}
            disableClearable
            targetMonth={targetMonth}
            syainId={syainId}
            onChange={this.onChange}
          />
        </Box>
        <Box sx={{ textAlign: 'right' }} my={1}>
          <ToggleButtonGroup
            size="small"
            value={displayType}
            exclusive
            onChange={this.onDisplayChange}
            aria-label="text alignment"
          >
            <ToggleButton value="calendar">
              <CalendarMonthIcon />
            </ToggleButton>
            <ToggleButton value="table">
              <TableViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box my={2}>
          <Table sx={{ maxWidth: 500, border: '1px solid rgba(224, 224, 224, 1)' }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="right">所定日数</TableCell>
                <TableCell align="right">所定時間</TableCell>
                <TableCell align="right">作業日数</TableCell>
                <TableCell align="right">作業時間</TableCell>
                <TableCell align="right">所定差分</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="right">{summary.syoteiDays}</TableCell>
                <TableCell align="right">{summary.syoteiMinutes}</TableCell>
                <TableCell align="right">{summary.workDays}</TableCell>
                <TableCell align="right">{summary.workMinutes}</TableCell>
                <TableCell align="right">{summary.diff}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        {displayType === 'calendar' && (
          <Table
            width="100%"
            sx={{
              border: 'solid 1px #DDD',
              borderCollapse: 'collapse',
              textAlign: 'center',
            }}
          >
            <thead>
              <tr>
                {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
                  <HeaderTd key={week} rested={week === '日' || week === '土'}>{week}</HeaderTd>
                ))}
              </tr>
            </thead>
            <tbody>
              {calendarMonthly.map((weekRow, index) => (
                <tr key={`${weekRow[index].date}month`}>
                  {weekRow.map((item) => {
                    const rested = isRested(restCalendars, item.formatDate);
                    const data = getData(datas, item.formatDate);
                    return (
                      <DataTd
                        key={item.day}
                        disabled={!item.isSomeMonth}
                        rested={rested}
                      >
                        <CalendarContent
                          onDayClick={this.onDayClick}
                          onDayDeleteClick={this.onDayDeleteClick}
                          data={data}
                          item={item}
                        />
                      </DataTd>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {displayType === 'table' && (
          <WorkTable
            onDayDeleteClick={this.onDayDeleteClick}
            targetMonth={targetMonth}
            onDayClick={this.onDayClick}
            datas={datas}
            restCalendars={restCalendars}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
  workPlanInfo: state.workPlanStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(WorkPlan);
