import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, DialogTitle, DialogContent, DialogActions, Box, TextField,
} from '@mui/material';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { loginUserActions } from '../../../redux/setting/loginUser/loginUserState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';

const tmpForm = {
  id: '',
  syainName: '',
  loginId: '',
  roleId: null,
  slackId: '',
};

export default function EmployeeCreateDialog(props) {
  const [form, setForm] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const { isOpen, onClose, row } = props;
  const selects = useSelector((state) => state.loginUserStore.selects);

  React.useEffect(() => {
    if (isOpen) {
      setForm(row);
    }
  }, [isOpen]);

  const onClickSave = () => {
    if (!form.loginId || !form.mstRoleId) {
      return;
    }

    dispatch(loginUserActions.update(form));
    onClose();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>{`${form.syainName}の変更`}</DialogTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="ログインID" isRequired />
          <TextField value={form.loginId} name="loginId" inputProps={{ maxLength: 40 }} onChange={onChange} />
        </Box>
        <Box mt={2}>
          <FormTitle title="権限" isRequired />
          <SearchSelectBox
            name="mstRoleId"
            disableClearable
            options={selects}
            value={form.mstRoleId}
            onChange={onChange}
          />
        </Box>
        <Box mt={2}>
          <FormTitle title="SlackId" />
          <TextField value={form.slackId} name="slackId" inputProps={{ maxLength: 40 }} onChange={onChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave} disabled={!form.loginId || !form.mstRoleId}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
