import React from 'react';
import { DialogContent, CircularProgress, Box } from '@mui/material';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';

export default function LoadingDialog(props) {
  const { open } = props;

  return (
    <MaterialDialog open={open}>
      <DialogColorTitle>出力中...</DialogColorTitle>
      <DialogContent dividers>
        <Box textAlign="center">
          <Box mb={2}>出力しています。このままお待ちください。</Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
}
