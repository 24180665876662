const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const checkValidation = (value, flg, kbn) => {
  if (!flg && !value) return '';
  if (!value) return `${kbn}にチェックした場合、入力してください`;
  return '';
};

class Validation {
  static formValidate = (type, value, tFlg, sFlg) => {
    switch (type) {
      case 'clientName':
      case 'clientNickName':
        return requiredValidation(value);
      case 'tTesuryoKbn':
      case 'tokuisakiTesuryo':
        return checkValidation(value, tFlg, '得意先');
      case 'sTesuryoKbn':
      case 'siresakiTesuryo':
        return checkValidation(value, sFlg, '仕入先');
      default:
        return '';
    }
  };

  static compareDateValidate = (valueStart, valueEnd) => {
    if (valueStart > valueEnd) return '開始より未来を設定してください';
    return '';
  };
}

export default Validation;
