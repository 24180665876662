import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Button, Backdrop, CircularProgress,
} from '@mui/material';
import { salesCloseActions } from '../../redux/close/salesClose/salesCloseState';
import SalesCheckClose from './SalesCheckClose';
import MonthPicker from '../../components/atoms/monthPicker/MonthPicker';

class CheckSalesClose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetMonth: '',
      isShowCheck: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salesCloseInfo.checkData !== this.props.salesCloseInfo.checkData) {
      if (this.props.salesCloseInfo.checkData) {
        this.setCheckData(this.props.salesCloseInfo.checkData);
      }
    }
  }

  setCheckData(check) {
    this.setState({ check, isShowCheck: true, isLoading: false });
  }

  onCheck = () => {
    const { targetMonth } = this.state;
    if (!targetMonth) {
      return;
    }
    this.props.dispatch(salesCloseActions.checkData({ processMonth: targetMonth }));
    this.setState({ isLoading: true });
  };

  onChange = (e) => {
    this.setState({ targetMonth: e.target.value });
  };

  render() {
    const {
      isLoading, check, isShowCheck, targetMonth,
    } = this.state;

    if (isLoading) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return (
      <Container maxWidth="xl">
        <Box mt={2}>
          <Box mt={2} display="flex">
            <Box>
              <MonthPicker
                handleChange={this.onChange}
                value={targetMonth}
                name="costProcessedMonth"
              />
            </Box>
            <Box ml={2}>
              <Button variant="outlined" onClick={this.onCheck}>仮締め前チェック</Button>
            </Box>
          </Box>
        </Box>
        {isShowCheck && (
          <SalesCheckClose data={check} open={isShowCheck} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  salesCloseInfo: state.salesCloseStore,
});

export default connect(mapStateToProps)(CheckSalesClose);
