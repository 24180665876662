/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const projectBalanceSlice = createSlice({
  name: 'projectBalance',
  initialState: {
    detail: {},
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    updateDetail: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDetailSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    getDetailSelect: () => {},
    getDetailSelectSuccess: (state, action) => {
      state.select = action.payload;
    },
    getCost: (state) => {
      state.tanka = null;
    },
    getCostSuccess: (state, action) => {
      state.tanka = action.payload;
    },
    getDetail: () => {},
    getDetailSuccess: (state, action) => {
      state.detail = action.payload;
    },
    getListSelect: () => {},
    getListSelectSuccess: (state, action) => {
      state.selectList = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.balanceData = action.payload;
    },
  },
});

export const projectBalanceActions = projectBalanceSlice.actions;

export default projectBalanceSlice.reducer;
