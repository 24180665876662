/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const passwordSlice = createSlice({
  name: 'password',
  initialState: {
    isSuccess: false,
  },
  reducers: {
    updatePassword: (state) => {
      state.isSuccess = false;
    },
    updatePasswordSuccess: (state) => {
      state.isSuccess = true;
    },
  },
});

export const passwordActions = passwordSlice.actions;

export default passwordSlice.reducer;
