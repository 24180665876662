import React from 'react';
import { connect } from 'react-redux';
import SearchSelectBox from '../../atoms/searchSelectBox/SearchSelectBox';
import { commonActions } from '../../../redux/common/commonState';

class SelectClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.common.clients || [],
    };
    this.props.dispatch(
      commonActions.getClients(),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.clients !== this.props.common.clients) {
      if (this.props.common.clients) {
        this.setData(this.props.common.clients);
      }
    }
  }

  setData = (options) => {
    this.setState({ options });
  };

  render() {
    const { options } = this.state;
    const {
      value, error, name, onChange, isDisabled,
    } = this.props;

    return (
      <SearchSelectBox
        error={error}
        name={name}
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={onChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({ common: state.commonStore });

export default connect(mapStateToProps)(SelectClient);
