import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  Grid, Container, Box, FormControl, RadioGroup, FormControlLabel, Radio, TextField, Switch,
} from '@mui/material';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { assetRequestListActions } from '../../../redux/request/assetRequest/assetRequestListState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { checkIsDisabled } from '../../../utils/authCheck.helper';

class AssetRequestList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      searchSisanId: '',
      selectFin: false,
      searchSisanKubun: '',
      searchSyainId: props.loginUser.userInfo.user.id,
      status: null,
      searchKbn: '1',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    let searchKbn = '1';
    if (props.assetRequestInfo.searchCondition) {
      searchCondition = props.assetRequestInfo.searchCondition;
      searchKbn = props.assetRequestInfo.searchCondition.searchKbn;
    }
    const params = new URLSearchParams(window.location.search);
    if (params.get('key') && params.get('key') === '1') {
      searchKbn = '1';
      searchCondition = {
        searchSisanId: params.get('assetId'),
        selectFin: false,
        searchSisanKubun: '',
        searchSyainId: params.get('requestId'),
        status: '1',
        searchKbn: '1',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      };
    }
    if (params.get('key') && params.get('key') === '2') {
      searchKbn = '1';
      searchCondition = {
        searchSisanId: params.get('assetId'),
        selectFin: false,
        searchSisanKubun: '',
        searchSyainId: params.get('requestId'),
        status: '3',
        searchKbn: '1',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      };
    }
    props.dispatch(assetRequestListActions.getCodes());
    props.dispatch(assetRequestListActions.getAssetCodes());

    props.dispatch(assetRequestListActions.setSearchCondition(searchCondition));
    props.dispatch(assetRequestListActions.getDatas(searchCondition));
    this.state = {
      datas: {},
      searchKbn,
      searchCondition,
      isSuccessOpen: false,
      isLoading: true,
      statuses: [],
      assetKbns: [],
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetRequestInfo.data !== this.props.assetRequestInfo.data) {
      if (this.props.assetRequestInfo.data) {
        this.setDatas(this.props.assetRequestInfo.data);
      }
    }
    if (prevProps.assetRequestInfo.codes !== this.props.assetRequestInfo.codes) {
      if (this.props.assetRequestInfo.codes) {
        this.setCodes(this.props.assetRequestInfo.codes);
      }
    }
    if (prevProps.assetRequestInfo.assetCodes !== this.props.assetRequestInfo.assetCodes) {
      if (this.props.assetRequestInfo.assetCodes) {
        this.setAssetCodes(this.props.assetRequestInfo.assetCodes);
      }
    }
    if (prevProps.assetRequestInfo.isReturnedSuccess
      !== this.props.assetRequestInfo.isReturnedSuccess) {
      if (this.props.assetRequestInfo.isReturnedSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess = () => {
    this.setState({ isLoading: false, isSuccessOpen: true });
    this.onSearch();
  };

  setDatas(datas) {
    this.setState({ datas, isLoading: false });
  }

  setCodes(statuses) {
    this.setState({ statuses });
  }

  setAssetCodes(assetKbns) {
    this.setState({ assetKbns });
  }

  onProcStart = () => {
    this.setState({ isLoading: true, isSuccessOpen: false });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isLoading: true });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.setState({ searchKbn: searchCondition.searchKbn });
    this.props.dispatch(assetRequestListActions.setSearchCondition(searchCondition));
    this.props.dispatch(assetRequestListActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onToggleChange = (event) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      selectFin: event.target.checked,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  render() {
    const {
      datas,
      statuses,
      assetKbns,
      searchCondition,
      isSuccessOpen,
      isLoading,
      searchKbn,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Box>
            <FormTitle title="検索区分" />
            <FormControl>
              <RadioGroup row value={searchCondition.searchKbn} name="searchKbn" onChange={this.onChange}>
                <FormControlLabel value="1" control={<Radio />} label="借用" />
                <FormControlLabel value="0" control={<Radio />} label="新規" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="資産ID" />
              <TextField size="small" placeholder="資産ID" fullWidth variant="outlined" name="searchSisanId" value={searchCondition.searchSisanId} onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="資産区分" />
              <SearchSelectBox options={assetKbns} onChange={this.onChange} name="searchSisanKubun" value={searchCondition.searchSisanKubun} />
            </Grid>
            {searchCondition.searchKbn === '1' && (
              <>
                {!isDisabled && (
                  <Grid item xs={12} md={6}>
                    <FormTitle title="名前" />
                    <SelectSyain onChange={this.onChange} name="searchSyainId" value={searchCondition.searchSyainId} targetMonth={format(new Date(), 'yyyy/MM/dd')} />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <FormTitle title="状況" />
                  <SearchSelectBox options={statuses} onChange={this.onChange} name="status" value={searchCondition.status} />
                </Grid>
                <Grid item xs={12}>
                  <FormTitle title="返却済" />
                  <FormControlLabel control={<Switch checked={searchCondition.selectFin} onChange={this.onToggleChange} />} label="返却含む" />
                </Grid>
              </>
            )}
          </Grid>
        </SearchBox>

        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          loading={isLoading}
          searchKbn={searchKbn}
          onResearch={this.onSearch}
          onProcStart={this.onProcStart}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message="返却確認済処理が完了しました"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  assetRequestInfo: state.assetRequestListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(AssetRequestList);
