import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import {
  Grid, Box, Container, Typography, Paper,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';
import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { salaryDetailActions } from '../../../redux/office/salary/salaryDetailState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import Input from './Input';
import calc from './salary-result-proc';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

class SalaryDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.salaryDetail.isInsertSuccess) {
      successSnackOpen = true;
      successSnackMessage = '保存が完了しました';
    }
    props.dispatch(salaryDetailActions.resetFlg());

    this.state = {
      data: {
        id: null,
        name: '',
        kzShkyGk: 0,
        shkyGk: 0,
        kjyGk: 0,
        diffGk: 0,
        khGk: 0,
        stdSrlMnth: 0,
      },
      successSnackOpen,
      successSnackMessage,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };

    props.dispatch(salaryDetailActions.getData({ id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salaryDetail.data !== this.props.salaryDetail.data) {
      if (this.props.salaryDetail.data) {
        this.setData(this.props.salaryDetail.data);
      }
    }
    if (prevProps.salaryDetail.isUpdateSuccess !== this.props.salaryDetail.isUpdateSuccess) {
      if (this.props.salaryDetail.isUpdateSuccess) {
        this.setUpdateSuccess();
      }
    }
  }

  setData(data) {
    this.setState({ data });
  }

  onBlur = (event) => {
    const { data } = this.state;
    const { name, value } = event.target;
    const tempData = {
      ...data,
      [name]: value || 0,
    };
    this.setState({
      data: calc(tempData),
    });
  };

  onChange = (event) => {
    const { data } = this.state;
    const { name, value } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });
  };

  setUpdateSuccess = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '保存が完了しました',
    });
    this.props.dispatch(salaryDetailActions.getData({ id: this.state.data.id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  };

  onRecalc = () => {
    const { data } = this.state;
    this.props.dispatch(salaryDetailActions.recalc(data));
  };

  onRecalcInsurance = () => {
    const { data } = this.state;
    this.props.dispatch(salaryDetailActions.recalcInsurance(data));
  };

  onSave = () => {
    const { data } = this.state;
    this.props.dispatch(salaryDetailActions.updateData(data));
  };

  render() {
    const {
      data, successSnackOpen, successSnackMessage, isDisabled,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth={false}>
        <Box>
          <Accordion defaultExpanded>
            <AccordionSummary>
              <Box flex="auto">{`${data.syainName} - ${format(new Date(data.targetMonth || null), 'yyyy年MM月分')}`}</Box>
              {!isDisabled && (
                <LoadingButton variant="contained" loading={isLoading} onClick={this.onRecalc} size="small">再計算</LoadingButton>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={4} md={2}>
                  <FormTitle title="課税支給額" />
                  <Box>{data.kzShkyGk.toLocaleString()}</Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormTitle title="支給額" />
                  <Box>{data.shkyGk.toLocaleString()}</Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormTitle title="控除額" />
                  <Box>{data.kjyGk.toLocaleString()}</Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormTitle title="差引額" />
                  <Box>{data.diffGk.toLocaleString()}</Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormTitle title="経費額" />
                  <Box>{data.khGk.toLocaleString()}</Box>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormTitle title="標準報酬月額" />
                  <Box>{data.stdSrlMnth.toLocaleString()}</Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mt={2} mb={10}>
          <Grid container spacing={5}>

            <Grid item xs={12} md={4}>
              <Paper variant="outlined">
                <Box p={1}>
                  <Typography gutterBottom>支給額</Typography>
                  <Input title="役員報酬" data={data} name="yknHsh" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="年俸基本給" data={data} name="nnpKhnKyu" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="基本給" data={data} name="abilityKyu" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="管理職手当" data={data} name="mgTt" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="勤労手当" data={data} name="seikinTt" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="職務手当" data={data} name="sykmTt" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="調整手当" data={data} name="seikaKyu" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="特別手当" data={data} name="tkbtsTt" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="残業手当" data={data} name="zngyTt" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="欠勤控除" data={data} name="kknKj" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="不就労控除" data={data} name="hsrKj" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="年末調整還付金" data={data} name="nnmtsTysKnpkn" onChange={this.onChange} onBlur={this.onBlur} />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper variant="outlined">
                <Box p={1}>
                  <Box display="flex">
                    <Typography gutterBottom style={{ flex: 'auto' }}>控除額</Typography>
                    {!isDisabled && (
                      <LoadingButton variant="contained" loading={isLoading} onClick={this.onRecalcInsurance} size="small">保険料再計算</LoadingButton>
                    )}
                  </Box>
                  <Input title="健康保険" data={data} name="knkHk" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="介護保険" data={data} name="kgHkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="厚生年金" data={data} name="ksnNkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="雇用保険" data={data} name="kyHkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="所得税" data={data} name="sytkZei" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="住民税" data={data} name="jymnZei" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="社員会費" data={data} name="syainKaihi" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="貸付返済" data={data} name="kstskHns" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="寮費" data={data} name="ryh" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="通信費" data={data} name="ponGk" onChange={this.onChange} onBlur={this.onBlur} />
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper variant="outlined">
                <Box p={1}>
                  <Typography gutterBottom>経費額</Typography>
                  <Input title="席料" data={data} name="skRy" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="会社負担健康保険" data={data} name="ksyKnkHk" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="会社負担介護保険" data={data} name="ksyKgHkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="会社負担厚生年金" data={data} name="ksyKsnNkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="児童手当拠出金" data={data} name="jdTtTystKn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="会社負担雇用保険" data={data} name="ksyKyHkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="労災保険" data={data} name="rsHkn" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="間接費" data={data} name="knstHi" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="経費申請費" data={data} name="apkhGk" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="交通費" data={data} name="kth" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="健診代" data={data} name="knkSdDai" onChange={this.onChange} onBlur={this.onBlur} />
                  <Input title="他社発注分" data={data} name="tsyHttyBun" onChange={this.onChange} onBlur={this.onBlur} />
                </Box>
              </Paper>
            </Grid>

          </Grid>
        </Box>
        {!isDisabled && (
          <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
          </Box>
        )}
        <SuccessSnackbar
          open={successSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successSnackMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  salaryDetail: state.salaryDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(SalaryDetail));
