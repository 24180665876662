export default function getColumn() {
  const columns = [
    {
      title: '月',
      name: 'month',
      type: 'dropdown',
      source: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      width: '70',
      align: 'center',
    },
    {
      title: '日',
      name: 'day',
      width: '70',
      type: 'dropdown',
      source: [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
        '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31',
      ],
      align: 'center',
    },
    {
      title: '曜日(1:日曜日-',
      name: 'yobi',
      type: 'dropdown',
      width: '150',
      source: [
        '1', '2', '3', '4', '5', '6', '7',
      ],
      align: 'center',
    },
    {
      title: '休み(休みの場合1',
      name: 'rested',
      type: 'dropdown',
      width: '150',
      source: [
        '1', '0',
      ],
      align: 'center',
    },
  ];

  return columns;
}
