import React from 'react';
import { connect } from 'react-redux';
import {
  Tabs, Box, Container, Tab, Grid, Paper,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { employeeLicenseHistoryActions } from '../../../redux/office/employee/employeeLicenseHistoryState';
import { certificateCampaignActions } from '../../../redux/office/certificateCampaign/certificateCampaignState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import HistoryList from './HistoryList';
import Validation from './validation';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';

class CertificateCampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      data: {
        id,
        mstCertificateId: '',
        startedOn: '',
        endedOn: '',
        paymentAmount: '',
        paymentCount: '',
      },
      targets: [],
      errorMessage: {
        mstCertificateId: '',
        startedOn: '',
        endedOn: '',
        paymentAmount: '',
        paymentCount: '',
      },
      tabValue: 0,
      select: [],
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };

    props.dispatch(employeeLicenseHistoryActions.getSelect());
    if (id) {
      props.dispatch(certificateCampaignActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.certificateCampaignInfo.data !== this.props.certificateCampaignInfo.data) {
      if (this.props.certificateCampaignInfo.data) {
        this.setData(this.props.certificateCampaignInfo.data);
      }
    }
    if (prevProps.certificateCampaignInfo.isSaveSuccess
      !== this.props.certificateCampaignInfo.isSaveSuccess) {
      if (this.props.certificateCampaignInfo.isSaveSuccess) {
        this.setUpdateSuccess(this.props.certificateCampaignInfo.saveId);
      }
    }
    if (prevProps.employeeLicenseHistoryInfo.selects
      !== this.props.employeeLicenseHistoryInfo.selects) {
      if (this.props.employeeLicenseHistoryInfo.selects) {
        this.setSelect(this.props.employeeLicenseHistoryInfo.selects);
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setData(data) {
    this.setState({ data: data.entity, targets: data.targets });
  }

  onChange = (event) => {
    const { data, errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  setUpdateSuccess = (id) => {
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存が完了しました',
    });
    this.props.dispatch(certificateCampaignActions.getData({ id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSave = () => {
    const { data, errorMessage } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(data).forEach((key) => {
      const msg = Validation.formValidate(key, data[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.startedOn && !tempMessage.endedOn) {
      const msg = Validation.compareDateValidate(data.startedOn, data.endedOn);
      if (msg) {
        isError = true;
      }
      tempMessage.endedOn = msg;
    }

    this.setState({ errorMessage: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(certificateCampaignActions.saveData(data));
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const {
      data, isSuccessOpen, successMessage, isDisabled, tabValue, errorMessage,
      select, targets,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
            <Tab label="基本情報" />
            {data.id && (
              <Tab label="取得者" />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} isAll>
          <Box>
            <FormTitle title="資格" isRequired />
            <SearchSelectBox
              error={errorMessage.mstCertificateId !== ''}
              name="mstCertificateId"
              disableClearable
              options={select}
              value={data.mstCertificateId}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.mstCertificateId}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="開始日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="startedOn"
              error={errorMessage.startedOn !== ''}
              value={data.startedOn}
            />
            <FormErrorText>{errorMessage.startedOn}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="終了日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="endedOn"
              error={errorMessage.endedOn !== ''}
              value={data.endedOn}
            />
            <FormErrorText>{errorMessage.endedOn}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="支給額" isRequired />
            <MoneyInput value={data.paymentAmount} name="paymentAmount" error={errorMessage.paymentAmount !== ''} onChange={this.onChange} />
            <FormErrorText>{errorMessage.paymentAmount}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="支給回数" isRequired />
            <MoneyInput value={data.paymentCount} name="paymentCount" error={errorMessage.paymentCount !== ''} onChange={this.onChange} />
            <FormErrorText>{errorMessage.paymentCount}</FormErrorText>
          </Box>
          {data.id && (
            <Paper variant="outlined" style={{ padding: '10px', marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <FormTitle title="作成日" />
                  <Box>{data.createdAt}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="作成者" />
                  <Box>{data.createdBy}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="更新日" />
                  <Box>{data.updatedAt}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="更新者" />
                  <Box>{data.updatedBy}</Box>
                </Grid>
              </Grid>
            </Paper>
          )}
        </TabPanel>

        {data.id && (
          <TabPanel value={tabValue} index={1} isAll>
            <HistoryList datas={targets} />
          </TabPanel>
        )}

        {(!isDisabled && tabValue === 0) && (
          <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
          </Box>
        )}
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  certificateCampaignInfo: state.certificateCampaignStore,
  employeeLicenseHistoryInfo: state.employeeLicenseHistoryStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(CertificateCampaignDetail));
