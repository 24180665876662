import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button,
} from '@mui/material';
import ExcelJS from 'exceljs';
import axios from 'axios';
import TextArea from '../../../components/atoms/textArea/TextArea';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import withParams from '../../../components/atoms/withParam/WithParams';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { expenseRequestDetailActions } from '../../../redux/request/expenseRequest/expenseRequestDetailState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import MemberDataTable from './detail/MemberDataTable';
import ErrorDialog from '../../../components/templates/errorDialog/ErrorDialog';
import { excelData } from './expense-request-proc';

class ExpenseRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      isDisabled: false,
      successMessage: '',
      isSuccessOpen: false,
      isAffairs: props.loginUser.userInfo.user.affairs,
      isApproval: props.loginUser.userInfo.user.approval,
      form: {
        id,
        offerId: props.loginUser.userInfo.user.id,
        offerName: props.loginUser.userInfo.user.name,
        useDt: '',
        offerAmount: '',
        usePurpose: '',
        expectedEffect: '',
        detailList: [],
      },
      errorMessages: {
        useDt: '',
        offerAmount: '',
        usePurpose: '',
        expectedEffect: '',
      },
      isError: false,
      isEdit: true,
      errorMessage: [],
    };
    if (id) {
      props.dispatch(expenseRequestDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expenseRequestDetailInfo.data !== this.props.expenseRequestDetailInfo.data) {
      if (this.props.expenseRequestDetailInfo.data) {
        this.setDatas(this.props.expenseRequestDetailInfo.data);
      }
    }
    if (prevProps.expenseRequestDetailInfo.isSaveSuccess
      !== this.props.expenseRequestDetailInfo.isSaveSuccess) {
      if (this.props.expenseRequestDetailInfo.isSaveSuccess) {
        this.setSaveSuccess(this.props.expenseRequestDetailInfo.saveId);
      }
    }
    if (prevProps.expenseRequestDetailInfo.isStatusUpdateSuccess
      !== this.props.expenseRequestDetailInfo.isStatusUpdateSuccess) {
      if (this.props.expenseRequestDetailInfo.isStatusUpdateSuccess) {
        this.setSaveStatusSuccess();
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  download = async (e) => {
    e.preventDefault();
    const res = await axios.get('/経費請求書兼精算表.xlsx', { responseType: 'arraybuffer' });
    const data = new Uint8Array(res.data);
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(data);
    const { form } = this.state;
    const worksheet = workbook.getWorksheet('経費請求書');
    worksheet.pageSetup = {
      orientation: 'portrait', paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 99,
    };
    worksheet.pageSetup.margins = {
      left: 1,
      right: 1,
      top: 0.5,
      bottom: 0.2,
      header: 0.5,
      footer: 0.5,
    };
    excelData(form, worksheet);
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = '経費請求書兼精算表.xlsx';
    a.click();
    a.remove();
  };

  setSaveStatusSuccess = () => {
    const { form, updateStatus } = this.state;
    this.props.dispatch(expenseRequestDetailActions.getData({ id: form.id }));
    let proc = '取下';
    if (updateStatus === '1') {
      proc = '申請';
    }
    if (updateStatus === '3') {
      proc = '総務承認';
    }
    if (updateStatus === '4') {
      proc = '部長承認';
    }
    this.setState({ isSuccessOpen: true, successMessage: `${proc}処理が完了しました` });
  };

  setSaveSuccess = (id) => {
    this.props.dispatch(expenseRequestDetailActions.getData({ id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました' });
  };

  setDatas = (form) => {
    this.setState({
      form, details: form.detailList, isDisabled: form.statusCode !== '1' && form.statusCode !== '0', isEdit: false,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessages } = this.state;
    const tempForm = {
      ...form,
      [name]: value,
    };
    this.setState({
      form: tempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onSave = () => {
    const { form, errorMessages, details } = this.state;

    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(expenseRequestDetailActions.saveData({ ...form, detailList: details }));
  };

  onSaveStatus = (_, status) => {
    const { form, details } = this.state;
    let detailMoney = 0;
    details.forEach((c) => {
      detailMoney += parseInt(c.offerAmount.replaceAll(',', ''), 10);
    });
    const offerAmount = parseInt(String(form.offerAmount).replaceAll(',', ''), 10);
    if (status === '2' && detailMoney !== offerAmount) {
      this.setState({ isError: true, errorMessage: '内訳と経費額が一致しません' });
      return;
    }

    this.setState({ updateStatus: status, isSuccessOpen: false });
    this.props.dispatch(expenseRequestDetailActions.saveStatus({ id: form.id, status }));
  };

  onCloseError = () => {
    this.setState({ isError: false, errorMessage: '内訳と経費額が一致しません' });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onChangeDetail = (details) => {
    this.setState({ details });
  };

  onEdit = () => {
    this.setState({ isEdit: true });
  };

  render() {
    const {
      form, errorMessages, isSuccessOpen, successMessage, isDisabled, isAffairs, isApproval,
      errorMessage, isError, isEdit,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box p={1} textAlign="right">
          {(!form.id || form.statusCode === '1') && (
            <Box sx={{ flex: '0 0 auto' }}>
              {(form.id && !isEdit) && (<Button variant="contained" size="medium" onClick={(e) => this.onSaveStatus(e, '2')} style={{ marginRight: '5px' }}>申請</Button>)}
              {!isEdit && (<Button variant="outlined" size="medium" onClick={this.onEdit}>編集</Button>)}
              {isEdit && (<Button variant="contained" size="medium" onClick={this.onSave}>下書き保存</Button>)}
            </Box>
          )}
          <Box sx={{ flex: '0 0 auto' }}>
            {(form.statusCode === '2' && isAffairs) && (
              <Button variant="contained" size="medium" onClick={(e) => this.onSaveStatus(e, '3')} style={{ marginRight: '5px' }}>総務承認</Button>
            )}
            {((form.statusCode === '2' || form.statusCode === '3') && isApproval) && (
              <Button variant="contained" size="medium" onClick={(e) => this.onSaveStatus(e, '4')} style={{ marginRight: '5px' }}>部長承認</Button>
            )}
            {(form.id && form.statusCode !== '1' && form.statusCode !== '0' && isAffairs) && (
              <Button variant="contained" color="secondary" size="medium" onClick={(e) => this.onSaveStatus(e, '1')}>取下</Button>
            )}
            {(form.id && form.statusCode === '4' && isAffairs) && (
              <Button variant="contained" style={{ marginLeft: '5px' }} size="medium" onClick={this.download}>経費請求書</Button>
            )}
          </Box>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" style={{ padding: '10px' }}>
              <Box>
                <FormTitle title="名前" />
                <Box>{form.offerName}</Box>
              </Box>
              <Box mt={2}>
                <FormTitle title="使用日" isRequired />
                <Box>
                  <DatePicker handleChange={this.onChange} name="useDt" value={form.useDt} isDisabled={isDisabled || !isEdit} error={errorMessages.useDt !== ''} />
                  <FormErrorText>{errorMessages.useDt}</FormErrorText>
                </Box>
              </Box>
              <Box mt={2}>
                <FormTitle title="経費額" isRequired />
                <Box>
                  <MoneyInput fullWidth value={form.offerAmount} isDisabled={isDisabled || !isEdit} name="offerAmount" error={errorMessages.offerAmount !== ''} onChange={this.onChange} />
                  <FormErrorText>{errorMessages.offerAmount}</FormErrorText>
                </Box>
              </Box>
              <Box mt={2}>
                <FormTitle title="他社参加者" />
                <TextArea
                  value={form.otherJoin}
                  name="otherJoin"
                  onChange={this.onChange}
                  isDisabled={isDisabled || !isEdit}
                />
              </Box>
              <Box mt={2}>
                <FormTitle title="目的" isRequired />
                <TextArea
                  value={form.usePurpose}
                  name="usePurpose"
                  onChange={this.onChange}
                  isDisabled={isDisabled || !isEdit}
                />
                <FormErrorText>{errorMessages.usePurpose}</FormErrorText>
              </Box>
              <Box mt={2}>
                <FormTitle title="期待効果" isRequired />
                <TextArea
                  value={form.expectedEffect}
                  name="expectedEffect"
                  onChange={this.onChange}
                  isDisabled={isDisabled || !isEdit}
                />
                <FormErrorText>{errorMessages.expectedEffect}</FormErrorText>
              </Box>
              {form.id && (
                <Grid container mt={2}>
                  <Grid item xs={6} md={2}>
                    <FormTitle title="請求日" />
                    <Box>{form.offerDt}</Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormTitle title="承認No" />
                    <Box>{form.approvarNo}</Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormTitle title="承認日" />
                    <Box>{form.approvarDt}</Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormTitle title="承認者" />
                    <Box>{form.approvarName}</Box>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" style={{ padding: '10px' }}>
              <Box>
                <MemberDataTable isEdit={isEdit} rows={form.detailList} status={form.statusCode || '1'} onChangeDetail={this.onChangeDetail} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <ErrorDialog open={isError} title="エラーがあります" content={errorMessage} onClose={this.onCloseError} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  expenseRequestDetailInfo: state.expenseRequestDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ExpenseRequestDetail));
