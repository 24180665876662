/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import ExcelJS from 'exceljs';
import encoding from 'encoding-japanese';
import {
  DialogContent, Box, CircularProgress,
} from '@mui/material';
import DialogColorTitle from '../../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';
import getHeader from './csv-proc';
import { salaryListActions } from '../../../../redux/office/salary/salaryListState';

class CsvDownloadModal extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.getDatas(this.props.open);
      }
    }
    if (prevProps.salaryListInfo.csvData !== this.props.salaryListInfo.csvData) {
      if (this.props.salaryListInfo.csvData) {
        this.setDatas(this.props.salaryListInfo.csvData);
      }
    }
  }

  getDatas = () => {
    const { targetMonth } = this.props;
    this.props.dispatch(salaryListActions.getCsvData({ targetMonth }));
  };

  setDatas = (datas) => {
    this.download(datas);
  };

  download = async (data) => {
    const { targetMonth } = this.props;
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');

    worksheet.columns = getHeader();
    worksheet.addRows(data);
    worksheet.insertRow(1, ['//768　株式会社　ソデック　　　　　　　　　　　　29年', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
    worksheet.insertRow(2, ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
    const uint8Array = new Uint8Array(
      encoding.convert(await workbook.csv.writeBuffer(), {
        from: 'UTF8',
        to: 'SJIS',
      }),
    );
    const blob = new Blob([uint8Array], {
      type: 'application/octet-binary',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `salary_${format(new Date(targetMonth), 'yyyyMM')}.csv`;
    a.click();
    a.remove();
    this.props.onClose();
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <MaterialDialog open={open} onClose={onClose}>
        <DialogColorTitle>CSV出力</DialogColorTitle>
        <DialogContent dividers>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
            <Box>出力準備中です。このままお待ちください</Box>
          </Box>
        </DialogContent>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  salaryListInfo: state.salaryListStore,
});

export default connect(mapStateToProps)(CsvDownloadModal);
