import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Button, Box, DialogContent, CircularProgress,
  FormControl, Select, MenuItem, DialogActions,
} from '@mui/material';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import Dropzone from '../../../components/atoms/dropzone/Dropzone';
import { inputActions } from '../../../redux/file/input/inputState';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      type: 0,
      uploadFile: null,
      isSuccess: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inputInfo.isSuccess !== this.props.inputInfo.isSuccess) {
      if (this.props.inputInfo.isSuccess) {
        this.success(this.props.inputInfo.uploadCount);
      }
    }
  }

  onClose = () => {
    this.setState({ isLoading: false });
    window.location.reload();
  };

  success = () => {
    this.setState({ isSuccess: true, isLoading: false });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  uploadFile = (uploadFile) => {
    this.setState({ uploadFile });
  };

  onUploadFile = () => {
    const { uploadFile, type } = this.state;
    this.setState({ isLoading: true });
    if (type === 0) {
      this.props.dispatch(inputActions.updateExpense({ uploadFile }));
    }
    if (type === 1) {
      this.props.dispatch(inputActions.updateEmployee({ uploadFile }));
    }
  };

  render() {
    const { isLoading, type, isSuccess } = this.state;

    return (
      <Box>
        <Container maxWidth="md">
          <Box my={2}>
            <FormTitle title="取込タイプ" />
            <FormControl>
              <Select
                value={type}
                name="type"
                onChange={this.onChange}
              >
                <MenuItem value={0}>経費</MenuItem>
                <MenuItem value={1}>標準報酬</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Dropzone multiple={false} handleChange={this.uploadFile} />
          </Box>
          <Box mt={2}>
            <Button variant="contained" onClick={this.onUploadFile}>取込</Button>
          </Box>
        </Container>
        <MaterialDialog open={isLoading}>
          <DialogColorTitle>取込中...</DialogColorTitle>
          <DialogContent dividers>
            <Box textAlign="center">
              <Box mb={2}>取込しています。このままお待ちください。</Box>
              <Box>
                <CircularProgress />
              </Box>
            </Box>
          </DialogContent>
        </MaterialDialog>
        <MaterialDialog open={isSuccess}>
          <DialogColorTitle>完了</DialogColorTitle>
          <DialogContent dividers>
            <Box textAlign="center">
              <Box mb={2}>
                {this.props.inputInfo.uploadCount}
                件の取込が完了しました。
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>閉じる</Button>
          </DialogActions>
        </MaterialDialog>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  inputInfo: state.inputStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(Input);
