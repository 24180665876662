import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const requiredValidationWithCategory = (value, category) => {
  if (category === '0') return '';
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const dateValidationWithCategory = (value, category) => {
  if (category !== '0') return '';
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value, category) => {
    switch (type) {
      case 'fee':
      case 'rideRoute':
      case 'ridePoint':
      case 'rideOffRoute':
      case 'rideOffPoint':
        return requiredValidation(value);
      case 'content':
        return requiredValidationWithCategory(value, category);
      case 'targetDay':
        return dateValidation(value);
      case 'regularStartDate':
      case 'regularEndDate':
        return dateValidationWithCategory(value, category);
      default:
        return '';
    }
  };

  static compareDateValidate = (valueStart, valueEnd) => {
    if (valueStart > valueEnd) return '開始より未来を設定してください';
    return '';
  };
}

export default Validation;
