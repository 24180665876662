/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isLoading: false,
    isServerError: false,
    isAddressSuccess: false,
    errorMessages: [],
    restCalendars: [],
    syains: [],
    authers: [],
    statuses: [],
    projects: [],
    clients: [],
    address: '',
  },
  reducers: {
    getStatuses: (state) => {
      state.statuses = [];
    },
    getStatusesSuccess: (state, action) => {
      state.statuses = action.payload;
    },
    getRestCalendar: (state) => {
      state.restCalendars = [];
    },
    getRestCalendarSuccess: (state, action) => {
      state.restCalendars = action.payload;
    },
    getSyainList: (state) => {
      state.syains = [];
    },
    getSyainsSuccess: (state, action) => {
      state.syains = action.payload;
    },
    getSyainListGreaterThan: (state) => {
      state.syainGreaters = [];
    },
    getSyainListGreaterThanSuccess: (state, action) => {
      state.syainGreaters = action.payload;
    },
    getProjects: (state) => {
      state.projects = [];
    },
    getProjectsSuccess: (state, action) => {
      state.projects = action.payload;
    },
    getAuthorizerList: (state) => {
      state.authers = [];
    },
    getAuthorizerSuccess: (state, action) => {
      state.authers = action.payload;
    },
    resetServerError: (state) => {
      state.isServerError = false;
    },
    serverError: (state, action) => {
      state.errorMessages = action.payload.errorMessages;
      state.isServerError = true;
      state.isLoading = false;
    },
    executeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getAddress: (state) => {
      state.address = '';
      state.isAddressSuccess = false;
    },
    getAddressSuccess: (state, action) => {
      state.address = action.payload;
      state.isAddressSuccess = true;
    },
    getClients: (state) => {
      state.clients = [];
    },
    getClientsSuccess: (state, action) => {
      state.clients = action.payload;
    },
    getClientContacts: (state) => {
      state.contacts = [];
    },
    getClientContactsSuccess: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

export const commonActions = commonSlice.actions;

export default commonSlice.reducer;
