/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { costCloseActions } from './costCloseState';
import { getDatas, saveData } from '../../../services/base.service';

function* getData() {
  try {
    const result = yield call(getDatas, REST_API.CLOSE.COST);
    yield put(costCloseActions.getDatasSuccess(result));
  } catch (_) {}
}

function* updateData() {
  try {
    yield call(saveData, REST_API.CLOSE.COST);
    yield put(costCloseActions.updateDataSuccess());
  } catch (_) {}
}

function* costCloseSaga() {
  yield takeLatest('costClose/getDatas', getData);
  yield takeLatest('costClose/updateData', updateData);
}

export default costCloseSaga;
