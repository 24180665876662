import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextField, DialogActions, DialogContent, Button,
  LinearProgress, Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { reportActions } from '../../../redux/summary/report/reportState';
import FreeSoloBox from '../../../components/atoms/freeSoloBox/FreeSoloBox';
import Validation from './validation';
import TextArea from '../../../components/atoms/textArea/TextArea';

const tmpForm = {
  id: null,
  category: '',
  name: '',
  reportUrl: '',
  remarks: '',
};

class ReportDetailDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: [],
      info: tmpForm,
      errorMessage: tmpForm,
    };
    this.props.dispatch(reportActions.getCategory());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.reportInfo.category !== this.props.reportInfo.category) {
      if (this.props.reportInfo.category) {
        this.setSelect(this.props.reportInfo.category);
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  handleSave = () => {
    const { info, errorMessage } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(info).forEach((key) => {
      const msg = Validation.formValidate(key, info[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessage: tempMessage });

    if (isError) {
      return;
    }
    this.props.dispatch(reportActions.saveData(info));
  };

  onChange = (event) => {
    const { value, name } = event.target;
    const { info, errorMessage } = this.state;
    this.setState({
      info: { ...info, [name]: value },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  init() {
    const { id, report } = this.props;

    let info = tmpForm;
    if (id) {
      info = {
        id,
        ...report,
      };
    }

    this.setState({
      info,
      errorMessage: tmpForm,
    });
  }

  render() {
    const {
      open, handleClose,
    } = this.props;

    const {
      info, errorMessage, loading, btnLoading, select,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={handleClose}>
        <DialogColorTitle>{ info.id ? 'レポートの変更' : 'レポートの追加' }</DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Box>
            <FormTitle title="カテゴリ" isRequired />
            <FreeSoloBox
              error={errorMessage.category !== ''}
              name="category"
              disableClearable
              options={select}
              value={info.category}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.category}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="名前" isRequired />
            <TextField
              id="auth-name"
              name="name"
              autoComplete="off"
              size="small"
              variant="outlined"
              value={info.name}
              onChange={this.onChange}
              fullWidth
              error={errorMessage.name !== ''}
              inputProps={{
                maxLength: 50,
              }}
            />
            <FormErrorText>{errorMessage.name}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="URL" isRequired />
            <TextField
              name="reportUrl"
              autoComplete="off"
              size="small"
              variant="outlined"
              value={info.reportUrl}
              onChange={this.onChange}
              fullWidth
              error={errorMessage.reportUrl !== ''}
              inputProps={{
                maxLength: 2048,
              }}
            />
            <FormErrorText>{errorMessage.reportUrl}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="備考" />
            <TextArea name="remarks" value={info.remarks} onChange={this.onChange} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">キャンセル</Button>
          <LoadingButton
            onClick={this.handleSave}
            loading={btnLoading}
          >
            保存する
          </LoadingButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  reportInfo: state.reportStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(ReportDetailDialog);
