import React from 'react';
import {
  Page, Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, NumberCol,
} from '../../../../components/pdf/Common';
import syaban from '../../../../asset/syaban.png';

export default function ClaimWorkFile(props) {
  const { claim, detail } = props.data || {};
  const { isDispTanto } = props;
  return (
    <Document>
      <Page size="A4" wrap>
        <Root>
          <Title>請求書</Title>
          <Grid>
            <Col flexBasis={claim.clientName.length > 20 ? '100%' : '70%'}>
              <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                <Text style={{ textAlign: 'center' }}>{claim.clientName}</Text>
                {!claim.contactName && (<Text style={{ marginLeft: '20px' }}>御中</Text>)}
              </Box>
            </Col>
            {claim.clientName.length <= 20 && (
              <Col flexBasis="30%">
                <Box style={{ flexDirection: 'row', width: '100%' }}>
                  <Box style={{ minWidth: '40px' }}><Text>No.</Text></Box>
                  <Box><Text>{claim.seikyuNo}</Text></Box>
                </Box>
              </Col>
            )}
          </Grid>
          {claim.clientName.length > 20 && (
            <Grid>
              <Col flexBasis="70%">
                {claim.contactName && (
                  <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                    <Text style={{ textAlign: 'center' }}>{claim.contactName}</Text>
                    <Text style={{ marginLeft: '20px' }}>様</Text>
                  </Box>
                )}
              </Col>
              <Col flexBasis="30%">
                <Box style={{ flexDirection: 'row', width: '100%' }}>
                  <Box style={{ minWidth: '40px' }}><Text>No.</Text></Box>
                  <Box><Text>{claim.seikyuNo}</Text></Box>
                </Box>
              </Col>
            </Grid>
          )}
          <Grid>
            <Col flexBasis="70%">
              {(claim.contactName && claim.clientName.length <= 20) && (
                <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                  <Text style={{ textAlign: 'center' }}>{claim.contactName}</Text>
                  <Text style={{ marginLeft: '20px' }}>様</Text>
                </Box>
              )}
            </Col>
            <Col flexBasis="30%">
              <Box style={{ flexDirection: 'row', width: '100%' }}>
                <Box style={{ minWidth: '40px' }}><Text>請求日</Text></Box>
                <Box><Text>{claim.claimDate}</Text></Box>
              </Box>
            </Col>
          </Grid>
          <Grid style={{ marginTop: '30px' }}>
            <Col flexBasis="70%">
              <Grid>
                <Col
                  flexBasis="20%"
                  subTitle
                  style={{
                    alignItems: 'center', textAlign: 'center', justifyContent: 'center',
                  }}
                >
                  <View><Text>件名</Text></View>
                </Col>
                <Col
                  borderRight
                  borderTop
                  borderBottom
                  style={{
                    paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                  }}
                >
                  {claim.title && claim.title.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="30%">
              <Text>株式会社ソデック</Text>
              <Text>〒104-0042</Text>
            </Col>
          </Grid>
          <Grid>
            <Col flexBasis="70%">
              <Text style={{ marginBottom: '10px' }}>下記の通り、ご請求申し上げます。</Text>
            </Col>
            <Col flexBasis="30%">
              <Box style={{ position: 'relative' }}>
                <Text>東京都中央区入船1丁目4番10号</Text>
                <Text>八丁堀プレイス6F</Text>
                <Text>TEL：03-3297-3030</Text>
                {(isDispTanto && claim.eigyoTnto) && (
                  <Text>{`担当：${claim.eigyoTnto}`}</Text>
                )}
                <Text>登録番号：T9010001092589</Text>
                {claim.approvalStatus === '4' && (
                  <Image
                    src={syaban}
                    style={{
                      width: '40%', position: 'absolute', top: '-15px', right: '0px',
                    }}
                  />
                )}
              </Box>
            </Col>
          </Grid>
          <Grid style={{ marginTop: '20px' }}>
            <Col flexBasis="60%">
              <Grid style={{ height: '40px' }}>
                <Box
                  flexBasis="20%"
                  subTitle
                  style={{
                    alignItems: 'center', justifyContent: 'center', display: 'flex', border: '1px solid #E4E4E4', backgroundColor: '#E4E4E4', width: '70px', fontSize: '15px',
                  }}
                >
                  <View><Text>合計</Text></View>
                </Box>
                <Box style={{
                  alignItems: 'center', marginRight: '10px', justifyContent: 'center', display: 'flex', border: '1px solid #E4E4E4', width: '250px', fontSize: '15px',
                }}
                >
                  <Text>{`${claim.seikyuKingakuGokei ? claim.seikyuKingakuGokei : 0}円 (税込)`}</Text>
                </Box>
              </Grid>
            </Col>
            <Col flexBasis="40%">
              <Box style={{
                fontSize: '13px', borderBottom: '3px double #000', marginTop: '15px', textAlign: 'center',
              }}
              >
                <Text>{`お支払い期限：${claim.nyukinDt}`}</Text>
              </Box>
            </Col>
          </Grid>
          <Box style={{ marginTop: '20px' }}>
            <Text>{`注文No：${claim.chumonNo}`}</Text>
          </Box>
          <Grid>
            <Col style={{ textAlign: 'center' }} subTitle flexBasis="10%"><Text>作業期間</Text></Col>
            <Col flexBasis="90%" borderRight borderTop><Text style={{ paddingLeft: '5px' }}>{`${claim.workStartDate}～${claim.workEndDate}`}</Text></Col>
          </Grid>
          <Grid style={{ textAlign: 'center' }}>
            <Col subTitle flexBasis="4%"><Text>No.</Text></Col>
            <Col subTitle flexBasis="45%"><Text>摘要</Text></Col>
            <Col subTitle flexBasis="5%"><Text>数量</Text></Col>
            <Col subTitle flexBasis="20%"><Text>単価</Text></Col>
            <Col subTitle flexBasis="20%"><Text>金額</Text></Col>
            <Col subTitle flexBasis="6%"><Text>税</Text></Col>
          </Grid>
          {detail.map((item) => (
            <Grid>
              <Col borderLeft borderBottom flexBasis="4%" style={{ textAlign: 'center', justifyContent: 'center' }}><Text>{item.displayOrder}</Text></Col>
              <Col borderLeft borderBottom flexBasis="45%" style={{ paddingLeft: '5px' }}>
                {item.summary && item.summary.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
              </Col>
              <NumberCol borderLeft borderBottom flexBasis="5%"><Text>{`${item.quantity.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom flexBasis="20%"><Text>{`¥${item.unitPrice.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom flexBasis="20%"><Text>{`¥${item.totalPrice.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom borderRight flexBasis="6%"><Text>{`${item.zeiKubun}%`}</Text></NumberCol>
            </Grid>
          ))}
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="15%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>10%  小計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${claim.seikyuKazeiSyokei ? claim.seikyuKazeiSyokei : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="15%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>10%  消費税</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${claim.seikyuKingakuTax ? claim.seikyuKingakuTax : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="15%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>0%  小計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${claim.seikyuHikazeiSyokei ? claim.seikyuHikazeiSyokei : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="15%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>合計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${claim.seikyuKingakuGokei ? claim.seikyuKingakuGokei : 0}`}</Text></NumberCol>
          </Grid>
          {claim.workMemo && (
            <Text>{claim.workMemo}</Text>
          )}
          <Box style={{ marginTop: '10px' }}>
            <Text>お振込先</Text>
          </Box>
          <Grid>
            <Col flexBasis="40%">
              <Box style={{ border: '1px solid #000', padding: '5px' }}>
                <Box>
                  <Text>振込銀行：みずほ銀行 西葛西支店</Text>
                </Box>
                <Box style={{ marginTop: '2px' }}>
                  <Text>預金種目：普通</Text>
                </Box>
                <Box style={{ marginTop: '2px' }}>
                  <Text>口座番号：3232350</Text>
                </Box>
                <Box style={{ marginTop: '2px' }}>
                  <Text>口座名義：カ）ソデツク</Text>
                </Box>
              </Box>
            </Col>
            <Col flexBasis="60%" />
          </Grid>
          <Grid style={{ minHeight: '70px', marginTop: '20px' }}>
            <Col flexBasis="10%" style={{ textAlign: 'center', justifyContent: 'center' }} subTitle><Text>備考</Text></Col>
            <Col borderTop borderRight borderBottom flexBasis="90%" style={{ paddingLeft: '10px', justifyContent: 'center' }}>
              {claim.externalBiko && claim.externalBiko.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Grid>
        </Root>
      </Page>
    </Document>
  );
}
