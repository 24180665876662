import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@mui/material';
import { format } from 'date-fns';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { salaryListActions } from '../../../redux/office/salary/salaryListState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SelectSyainWithMonth from '../../../components/organisms/selectSyainWithMonth/SelectSyainWithMonth';
import { checkIsDisabled } from '../../../utils/authCheck.helper';

class SalaryList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
      syainId: null,
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: 'syain_id',
    };

    if (props.salaryListInfo.searchCondition) {
      searchCondition = props.salaryListInfo.searchCondition;
    }

    props.dispatch(salaryListActions.setSearchCondition(searchCondition));
    props.dispatch(salaryListActions.getDatas(searchCondition));
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      backupMonth: searchCondition.targetMonth,
      isLoading: true,
      isBp: props.loginUser.userInfo.user.bp,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salaryListInfo.data !== this.props.salaryListInfo.data) {
      if (this.props.salaryListInfo.data) {
        this.setDatas(this.props.salaryListInfo.data);
      }
    }
    if (prevProps.salaryListInfo.isDeleteSuccess !== this.props.salaryListInfo.isDeleteSuccess) {
      if (this.props.salaryListInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas, isLoading: false });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage, isLoading: false });
  }

  onProcStart = () => {
    this.setState({ isLoading: true, isSuccessOpen: false });
  };

  onSyainChange = (event) => {
    const { syainId, targetMonth } = event;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      syainId,
      targetMonth,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isLoading: true });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.setState({ backupMonth: searchCondition.targetMonth });
    this.props.dispatch(salaryListActions.setSearchCondition(searchCondition));
    this.props.dispatch(salaryListActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      backupMonth,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isLoading,
      isBp,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <SelectSyainWithMonth isBp={isBp} targetMonth={searchCondition.targetMonth} syainId={searchCondition.syainId} onChange={this.onSyainChange} syainLabel="名前" monthLabel="対象月" />
        </SearchBox>

        <DataTable
          targetMonth={backupMonth}
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          loading={isLoading}
          disabled={isDisabled}
          onProcStart={this.onProcStart}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  salaryListInfo: state.salaryListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(SalaryList);
