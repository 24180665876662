import React from 'react';
import {
  Snackbar, IconButton, Alert, Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function TransitionUp(props) {
  return <Slide {...props} direction="down" />;
}

export default function SuccessSnackbar(props) {
  const { open, onClose } = props;

  const handleCloseCheck = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleCloseCheck}
      TransitionComponent={TransitionUp}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        elevation={10}
        variant="filled"
        onClose={onClose}
        severity="error"
        action={(
          <IconButton aria-label="delete" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        データがありません
      </Alert>
    </Snackbar>
  );
}
