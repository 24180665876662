import React from 'react';
import {
  Tabs, Box, Tab, Badge, Accordion, AccordionSummary,
  AccordionDetails, List, ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import TabPanel from '../../components/atoms/tabPanel/TabPanel';
import urls from '../../constants/frontUrls';
import Link from '../../components/atoms/link/Link';

const StyledTab = styled(Tab)(() => ({
  minHeight: '0px',
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function SalesCheckClose(props) {
  const [tabValue, setTabValue] = React.useState(0);
  const [checkData, setCheckData] = React.useState({
    work: {}, projects: [], estimates: [], claims: [], orders: [], pays: [], inspects: [],
  });
  const { data, open } = props;

  const onTabChange = (_, value) => {
    setTabValue(value);
  };
  React.useEffect(() => {
    if (open) {
      setCheckData(data);
    }
  }, [open]);

  const getCounts = (targets) => {
    if (targets.length <= 0) {
      return 0;
    }
    let counts = 0;
    targets.forEach((v) => {
      counts += v.errorList.length;
    });
    return counts;
  };

  return (
    <Box mt={2}>
      <Box sx={{ maxWidth: { xs: 320, sm: 900 }, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={onTabChange} scrollButtons="auto" variant="scrollable">
          <StyledTab icon={<StyledBadge badgeContent={checkData.work.error ? 1 : 0} color="primary" />} iconPosition="end" label="作業報告" />
          <StyledTab icon={<StyledBadge badgeContent={getCounts(checkData.projects)} color="primary" />} iconPosition="end" label="プロジェクト" />
          <StyledTab icon={<StyledBadge badgeContent={getCounts(checkData.estimates)} color="primary" />} iconPosition="end" label="注文" />
          <StyledTab icon={<StyledBadge badgeContent={getCounts(checkData.inspects)} color="primary" />} iconPosition="end" label="検収" />
          <StyledTab icon={<StyledBadge badgeContent={getCounts(checkData.claims)} color="primary" />} iconPosition="end" label="請求" />
          <StyledTab icon={<StyledBadge badgeContent={getCounts(checkData.orders)} color="primary" />} iconPosition="end" label="発注" />
          <StyledTab icon={<StyledBadge badgeContent={getCounts(checkData.pays)} color="primary" />} iconPosition="end" label="支払" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0} isAll>
        <Box>
          {checkData.work.error}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1} isAll>
        <Box>
          {checkData.projects.map((check) => (
            <Accordion key={check.error}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>{check.error}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {check.errorList.map((item) => (
                    <ListItem key={`${check.error} ${item.id}`}>
                      <Link to={urls.PROJECT_DETAIL + item.id}>{item.projectName}</Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={2} isAll>
        <Box>
          {checkData.estimates.map((check) => (
            <Accordion key={check.error}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>{check.error}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {check.errorList.map((item) => (
                    <ListItem key={`${check.error} ${item.id}`}>
                      <Link to={urls.ESTIMATE_DETAIL + item.id}>{item.chumonNo}</Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={3} isAll>
        <Box>
          {checkData.inspects.map((check) => (
            <Accordion key={check.error}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>{check.error}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {check.errorList.map((item) => (
                    <ListItem key={`${check.error} ${item.id}`}>
                      <Link to={urls.INSPECT_DETAIL + item.id}>{item.inspectNo}</Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={4} isAll>
        <Box>
          {checkData.claims.map((check) => (
            <Accordion key={check.error}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>{check.error}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {check.errorList.map((item) => (
                    <ListItem key={`${check.error} ${item.id}`}>
                      <Link to={urls.CLAIM_DETAIL + item.id}>{item.seikyuNo}</Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={5} isAll>
        <Box>
          {checkData.orders.map((check) => (
            <Accordion key={check.error}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>{check.error}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {check.errorList.map((item) => (
                    <ListItem key={`${check.error} ${item.id}`}>
                      <Link to={urls.ORDER_DETAIL + item.id}>{item.orderNo}</Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={6} isAll>
        <Box>
          {checkData.pays.map((check) => (
            <Accordion key={check.error}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>{check.error}</Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {check.errorList.map((item) => (
                    <ListItem key={`${check.error} ${item.id}`}>
                      <Link to={urls.PAY_DETAIL + item.id}>{item.payNo}</Link>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </TabPanel>
    </Box>
  );
}
