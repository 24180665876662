/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const overtimeRequestListSlice = createSlice({
  name: 'overtimeRequestList',
  initialState: {
    data: {},
    searchCondition: null,
    isApprovalSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveApproval: (state) => {
      state.isApprovalSuccess = false;
    },
    saveApprovalSuccess: (state) => {
      state.isApprovalSuccess = true;
    },
  },
});

export const overtimeRequestListActions = overtimeRequestListSlice.actions;

export default overtimeRequestListSlice.reducer;
