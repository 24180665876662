/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { estimateActions } from './estimateState';
import {
  getDatas, deleteDatas, saveData, getDataById, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.ESTIMATE.ESTIMATE_ID, payload);
    yield put(estimateActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ESTIMATE.ESTIMATE, payload);
    yield put(estimateActions.updateDataSuccess(result));
  } catch (_) {}
}

function* updateStatus({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.ESTIMATE.ESTIMATE_ID, payload);
    yield put(estimateActions.updateStatusSuccess());
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.ESTIMATE.ESTIMATE_SELECT, payload);
    yield put(estimateActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getPdfData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.ESTIMATE.ESTIMATE_PDF, payload);
    yield put(estimateActions.getPdfDataSuccess(result));
  } catch (_) {}
}

function* updatePdfData({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.ESTIMATE.ESTIMATE_PDF, payload);
    yield put(estimateActions.updatePdfDataSuccess());
  } catch (_) {}
}

function* copyData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ESTIMATE.ESTIMATE_COPY, payload);
    yield put(estimateActions.copyDataSuccess(result));
  } catch (_) {}
}

function* copyToInspect({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ESTIMATE.ESTIMATE_COPY_INSPECT, payload);
    yield put(estimateActions.copyToInspectSuccess(result));
  } catch (_) {}
}

function* copyToClaim({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ESTIMATE.ESTIMATE_COPY_CLAIM, payload);
    yield put(estimateActions.copyToClaimSuccess(result));
  } catch (_) {}
}

function* estimateSaga() {
  yield takeLatest('estimate/getDatas', getData);
  yield takeLatest('estimate/deleteData', deleteData);
  yield takeLatest('estimate/getData', getDataFromId);
  yield takeLatest('estimate/insertData', insertData);
  yield takeLatest('estimate/updateData', updateDatas);
  yield takeLatest('estimate/updateStatus', updateStatus);
  yield takeLatest('estimate/getSelect', getSelect);
  yield takeLatest('estimate/getPdfData', getPdfData);
  yield takeLatest('estimate/updatePdfData', updatePdfData);
  yield takeLatest('estimate/copyData', copyData);
  yield takeLatest('estimate/copyToInspect', copyToInspect);
  yield takeLatest('estimate/copyToClaim', copyToClaim);
}

export default estimateSaga;
