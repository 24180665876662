import React from 'react';
import {
  View, StyleSheet, Font, Text,
} from '@react-pdf/renderer';
import fontNomal from '../../asset/NotoSansJP-Regular.ttf';
import fontBold from '../../asset/NotoSansJP-Bold.ttf';

export function Root(props) {
  Font.register({
    family: 'NotoSansJP-Regular',
    src: fontNomal,
  });
  Font.register({
    family: 'NotoSansJP-Bold',
    src: fontBold,
  });
  Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, '']));
  const styles = StyleSheet.create({
    root: {
      fontFamily: 'NotoSansJP-Regular',
      padding: '20px',
      fontSize: '10px',
    },
  });
  return (
    <View style={styles.root}>
      {props.children}
    </View>
  );
}

export function Title(props) {
  const styles = StyleSheet.create({
    title: {
      textAlign: 'center',
      marginTop: '10px',
      marginBottom: '20px',
      fontSize: '18px',
      fontFamily: 'NotoSansJP-Bold',
    },
  });
  return (
    <View style={styles.title}><Text size="large">{props.children}</Text></View>
  );
}

export function Grid(props) {
  const styles = StyleSheet.create({
    gird: {
      flexDirection: 'row',
      ...props.style,
    },
  });
  return (
    <View style={styles.gird}>{props.children}</View>
  );
}

export function Col(props) {
  const styles = StyleSheet.create({
    col: {
      flexGrow: 0,
      flexBasis: props.flexBasis,
      backgroundColor: props.subTitle ? '#E4E4E4' : '#ffffff',
      borderLeft: props.borderLeft ? '1px solid #E4E4E4' : null,
      borderRight: props.borderRight ? '1px solid #E4E4E4' : null,
      borderTop: props.borderTop ? '1px solid #E4E4E4' : null,
      borderBottom: props.borderBottom ? '1px solid #E4E4E4' : null,
      ...props.style,
    },
  });
  return (
    <View style={styles.col}>{props.children}</View>
  );
}

export function Box(props) {
  const styles = StyleSheet.create({
    box: {
      ...props.style,
      display: 'block',
    },
  });
  return (
    <View style={styles.box}>{props.children}</View>
  );
}

export function NumberCol(props) {
  const styles = StyleSheet.create({
    box: {
      flexGrow: 0,
      flexBasis: props.flexBasis,
      backgroundColor: props.subTitle ? '#E4E4E4' : '#ffffff',
      borderLeft: props.borderLeft ? '1px solid #E4E4E4' : null,
      borderRight: props.borderRight ? '1px solid #E4E4E4' : null,
      borderTop: props.borderTop ? '1px solid #E4E4E4' : null,
      borderBottom: props.borderBottom ? '1px solid #E4E4E4' : null,
      textAlign: 'right',
      paddingRight: '5px',
      justifyContent: 'center',
      ...props.style,
    },
  });
  return (
    <View style={styles.box}>{props.children}</View>
  );
}
