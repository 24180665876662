import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Box,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import AddMemberDialog from './AddMemberDialog';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [addMemberOpen, setAddMemberOpen] = React.useState(false);
  const [isMemberEdit, setMemberEdit] = React.useState(false);

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.syainId);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const onAdd = () => {
    setAddMemberOpen(true);
    setSelectedRow(null);
  };

  const onCloseMember = () => {
    setAddMemberOpen(false);
  };

  const handleClose = () => {
    setConfOpen(false);
  };

  const onDeleteProc = () => {
    const targets = [];
    rows.forEach((v) => {
      let isExist = false;
      selected.forEach((c) => {
        if (c === v.syainId) {
          isExist = true;
        }
      });
      if (!isExist) {
        targets.push(v);
      }
    });
    setConfOpen(false);
    setSelected([]);
    setRows(targets);
    props.onChangeDetail(targets);
  };

  const onClickDelete = () => {
    setConfOpen(true);
  };

  const onMemberAdd = (e) => {
    setAddMemberOpen(false);
    const tempRows = [];
    let isChange = false;
    rows.forEach((v) => {
      if (v.syainId === e.syainId) {
        tempRows.push(e);
        isChange = true;
      } else {
        tempRows.push(v);
      }
    });
    if (!isMemberEdit && !isChange) {
      tempRows.push(e);
    }
    setRows(tempRows);
    setMemberEdit(false);
    props.onChangeDetail(tempRows);
  };

  const { status, isEdit } = props;

  const onEdit = (_, row) => {
    setAddMemberOpen(true);
    setMemberEdit(true);
    setSelectedRow(row);
  };

  const onCopy = (_, row) => {
    setAddMemberOpen(true);
    setSelectedRow({
      ...row,
      syainId: '',
      syainMei: '',
    });
  };

  return (
    <Box mt={1}>
      <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
        {selected.length > 0 ? (
          <>
            <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
              <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
              {selected.length}
              {' '}
              件選択中
            </Typography>
            <Box mr={2}>
              <Tooltip title="削除">
                <Button color="secondary" variant="contained" onClick={onClickDelete}>削除</Button>
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
              内訳
            </Typography>
            {(status === '1' && isEdit) && (
              <Tooltip title="追加">
                <Button color="primary" variant="outlined" onClick={onAdd}>追加</Button>
              </Tooltip>
            )}
          </>
        )}
      </Toolbar>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {(status === '1' && isEdit) && (
                <TableCell key="table-header-check" padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0
                      && rows.length !== selected.length
                    }
                    checked={
                      selected.length > 0
                      && rows.length === selected.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color="primary"
                  />
                </TableCell>
              )}
              <TableCell>名前</TableCell>
              <TableCell>金額</TableCell>
              {isEdit && (<TableCell />)}
              {isEdit && (<TableCell />)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.syainId);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.syainId}
                  selected={isItemSelected}
                >
                  {(status === '1' && isEdit) && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                        onClick={(event) => handleClick(event, row.syainId)}
                      />
                    </TableCell>
                  )}
                  <TableCell>{row.syainMei}</TableCell>
                  <TableCell>{row.offerAmount}</TableCell>
                  {isEdit && (<TableCell><Button size="small" onClick={(e) => onEdit(e, row)}>編集</Button></TableCell>)}
                  {isEdit && (<TableCell><Button size="small" onClick={(e) => onCopy(e, row)}>コピー</Button></TableCell>)}
                </TableRow>
              );
            })}
            {
              rows.length < 1 && (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                    データがありません
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        isError
        open={confOpen}
        onClose={handleClose}
        title="選択した申請削除"
        content="選択した申請を削除します"
        onClick={onDeleteProc}
        procName="削除する"
      />
      <AddMemberDialog
        open={addMemberOpen}
        onClose={onCloseMember}
        onAdd={onMemberAdd}
        selectedRow={selectedRow}
      />
    </Box>
  );
}
