/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { certificateActions } from './certificateState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getSelect() {
  try {
    const result = yield call(getDatas, REST_API.MASTER.CERTIFICATE.CERTIFICATE_SELECT);
    yield put(certificateActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.MASTER.CERTIFICATE.CERTIFICATE, payload);
    yield put(certificateActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.MASTER.CERTIFICATE.CERTIFICATE, payload);
    yield put(certificateActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.MASTER.CERTIFICATE.CERTIFICATE_ID, payload);
    yield put(certificateActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.MASTER.CERTIFICATE.CERTIFICATE, payload);
    yield put(certificateActions.saveDataSuccess(result));
  } catch (_) {}
}

function* certificateSaga() {
  yield takeLatest('certificate/getDatas', getData);
  yield takeLatest('certificate/getSelect', getSelect);
  yield takeLatest('certificate/deleteData', deleteData);
  yield takeLatest('certificate/getData', getDataFromId);
  yield takeLatest('certificate/saveData', saveDatas);
}

export default certificateSaga;
