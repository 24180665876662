export default function getColumn() {
  const columns = [
    {
      title: '表示順',
      name: 'displayOrder',
      width: '100',
      align: 'right',
      type: 'numeric',
    },
    {
      title: '摘要', width: '500', name: 'summary', align: 'left',
    },
    {
      title: '数量',
      name: 'quantity',
      width: '100',
      align: 'right',
      type: 'numeric',
      mask: '[-]#,##.00',
    },
    {
      title: '単価',
      name: 'unitPrice',
      width: '200',
      align: 'right',
      type: 'numeric',
    },
    {
      title: '合計金額',
      name: 'totalPrice',
      width: '200',
      mask: '[-]#,##',
      align: 'right',
      type: 'numeric',
      readOnly: true,
    },
  ];

  return columns;
}
