/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { closeEditActions } from './closeEditState';
import { getDatas, saveData } from '../../../services/base.service';

function* getData() {
  try {
    const result = yield call(getDatas, REST_API.CLOSE.EDIT);
    yield put(closeEditActions.getDatasSuccess(result));
  } catch (_) {}
}

function* updateData({ payload }) {
  try {
    yield call(saveData, REST_API.CLOSE.EDIT, payload);
    yield put(closeEditActions.updateDataSuccess());
  } catch (_) {}
}

function* closeEditSaga() {
  yield takeLatest('closeEdit/getDatas', getData);
  yield takeLatest('closeEdit/updateData', updateData);
}

export default closeEditSaga;
