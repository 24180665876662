/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow,
  Box,
} from '@mui/material/';
import { styled } from '@mui/material/styles';

const TableCell = styled(MuiTableCell)(() => ({
  padding: 8,
  border: '1px solid #ddd',
}));

const HeadTableCell = styled(MuiTableCell)(() => ({
  background: '#F3F3F3',
  padding: 8,
  border: '1px solid #ddd',
}));

export default function DetailDisplayTable(props) {
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeadTableCell style={{ width: '10%' }} align="right">表示順</HeadTableCell>
              <HeadTableCell>適用</HeadTableCell>
              <HeadTableCell align="right">数量</HeadTableCell>
              <HeadTableCell align="right">単価</HeadTableCell>
              {props.isTax && (<HeadTableCell style={{ width: '10%' }}>税</HeadTableCell>)}
              <HeadTableCell align="right">合計金額</HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.datas.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="right">{row.displayOrder}</TableCell>
                <TableCell>{row.summary && row.summary.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</TableCell>
                <TableCell align="right">{row.quantity ? row.quantity.toLocaleString() : row.quantity}</TableCell>
                <TableCell align="right">
                  {row.unitPrice
                    ? row.unitPrice.toLocaleString() : row.unitPrice}
                </TableCell>
                {props.isTax && (<TableCell>{row.zeiKubun}</TableCell>)}
                <TableCell align="right">
                  {row.totalPrice
                    ? row.totalPrice.toLocaleString() : row.totalPrice}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
