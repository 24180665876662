export default function getHeader() {
  return [
    { header: '社員コード', key: 'syainId' },
    { header: '出勤日数', key: 'workCnt' },
    { header: '出勤時数', key: 'workTm' },
    { header: '欠勤', key: 'noWorkCnt' },
    { header: '不就労', key: 'huSyuRou' },
    { header: '平日普通', key: 'overTm' },
    { header: '平日深夜', key: 'midOverTm' },
    { header: '休日普通', key: 'htkyjtTm' },
    { header: '休日深夜', key: 'holidMidWorkTm' },
    { header: '有休取得日数', key: 'payoffCnt' },
    { header: '有休残日数', key: 'payoffLeftCnt' },
    { header: '基本給', key: 'yknHsh' },
    { header: '手当１', key: 'tt' },
    { header: '手当２', key: 'mgTt' },
    { header: '手当３', key: 'seikaKyu' },
    { header: '手当４', key: 'sdTt' },
    { header: '手当５', key: 'seikinTt' },
    { header: '手当６', key: 'sykmTt' },
    { header: '手当７', key: 'teateSeven' },
    { header: '手当８', key: 'teateEight' },
    { header: '手当９', key: 'tkbtsTt' },
    { header: '時間外手当', key: 'zngyTt' },
    { header: '通勤手当', key: 'tknTt' },
    { header: '不就労控除', key: 'kknKj' },
    { header: '総支給額', key: 'souSikyuGaku' },
    { header: '健康保険', key: 'kenkoHoken' },
    { header: '介護保険', key: 'kaigoHoken' },
    { header: '厚生年金', key: 'kouseiNenkin' },
    { header: '年金基金', key: 'nenkinKikin' },
    { header: '雇用保険', key: 'koyoHoken' },
    { header: '所得税', key: 'syotokuZei' },
    { header: '住民税', key: 'jymnZei' },
    { header: '控除１', key: 'kojo1' },
    { header: '控除２', key: 'kojo2' },
    { header: '控除３', key: 'kojo3' },
    { header: '控除４', key: 'kojo4' },
    { header: '控除５', key: 'kojo5' },
    { header: '控除６', key: 'kojo6' },
    { header: '控除計', key: 'kojoKei' },
    { header: '年末調整', key: 'nnmtsTysKnpkn' },
    { header: '調整額', key: 'tyoseiGaku' },
    { header: '差引支給額', key: 'sasihikiShiharaiGaku' },
    { header: '銀行振込１', key: 'bank1' },
    { header: '銀行振込２', key: 'bank2' },
    { header: '現金', key: 'genkin' },
  ];
}
