import React from 'react';
import { connect } from 'react-redux';
import { format, isValid } from 'date-fns';
import { Box } from '@mui/material';
import getColumn from './calendar-proc';
import DataGridSheet from '../../../components/templates/dataGridSheet/DataGridSheet';
import { calendarActions } from '../../../redux/setting/calendar/calendarState';
import YearPicker from '../../../components/atoms/yearPicker/YearPicker';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

class CalendarList extends React.Component {
  constructor(props) {
    super(props);
    this.columns = getColumn();
    this.dataGridRef = React.createRef();
    const year = format(new Date(), 'yyyy');
    this.state = {
      datas: [],
      year,
      isSuccess: false,
    };
    props.dispatch(calendarActions.getDatas({ year }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.calendarInfo.datas !== this.props.calendarInfo.datas) {
      if (this.props.calendarInfo.datas) {
        this.setDatas(this.props.calendarInfo.datas);
      }
    }
    if (prevProps.calendarInfo.isSaveSuccess
      !== this.props.calendarInfo.isSaveSuccess) {
      if (this.props.calendarInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setDataGridRef = (ref) => {
    this.dataGridRef = ref;
  };

  onSave = (datas) => {
    const { year } = this.state;
    this.props.dispatch(calendarActions.saveData({
      year: format(new Date(year), 'yyyy'),
      datas,
    }));
  };

  setSuccess = () => {
    this.setState({ isSuccess: true });
  };

  onClose = () => {
    this.setState({ isSuccess: false });
  };

  onChange = (event) => {
    const { value } = event.target;
    if (!value || !isValid(new Date(value))) {
      return;
    }
    this.setState({ year: value });
    this.props.dispatch(calendarActions.getDatas({ year: format(new Date(value), 'yyyy') }));
  };

  render() {
    const { datas, year, isSuccess } = this.state;
    const { isDisabled } = this.props;
    return (
      <Box>
        <Box mb={3}>
          <YearPicker
            handleChange={this.onChange}
            value={year}
            name="targetYear"
          />
        </Box>
        <DataGridSheet
          data={datas}
          isNonTooltip
          isDisabled={isDisabled}
          onSave={this.onSave}
          dataGridRef={this.dataGridRef}
          setDataGridRef={this.setDataGridRef}
          columns={this.columns}
        />
        <SuccessSnackbar
          open={isSuccess}
          handleClose={this.onClose}
          message="保存が完了しました"
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  calendarInfo: state.calendarStore,
});

export default connect(mapStateToProps)(CalendarList);
