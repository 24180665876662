/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workPlanSlice = createSlice({
  name: 'workPlan',
  initialState: {
    rest: {},
    data: {},
    searchCondition: null,
    isSaveSuccess: false,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getRest: () => {},
    getRestSuccess: (state, action) => {
      state.rest = action.payload;
    },
    getWorkPlan: () => {},
    getWorkPlanSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveWorkPlan: (state) => {
      state.isSaveSuccess = false;
    },
    saveWorkPlanSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    deleteWorkPlan: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteWorkPlanSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
  },
});

export const workPlanActions = workPlanSlice.actions;

export default workPlanSlice.reducer;
