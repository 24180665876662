import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container,
} from '@mui/material';
import { format } from 'date-fns';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { inspectActions } from '../../../redux/sales/inspect/inspectState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';

class InspectList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'seikyu_no', label: '検収番号' },
      { id: 'seikyu_dt', label: '計上月' },
      { id: 'seikyu_kingaku', label: '検収額' },
      { id: 'seikyu_kingaku_tax', label: '消費税' },
      { id: 'seikyu_kingaku_gokei', label: '合計額' },
      { id: 'project_name', label: 'プロジェクト' },
      { id: 'status', label: '状況' },
    ];

    let searchCondition = {
      searchAppStatus: [],
      searchInspectStatus: [],
      searchInspectNo: '',
      searchKeyword: '',
      searchMonth: format(new Date(), 'yyyy/MM/dd'),
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.inspectInfo.searchCondition) {
      searchCondition = props.inspectInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      appStatus: [],
      inspectStatus: [],
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    props.dispatch(inspectActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inspectInfo.datas !== this.props.inspectInfo.datas) {
      if (this.props.inspectInfo.datas) {
        this.setDatas(this.props.inspectInfo.datas);
      }
    }
    if (prevProps.inspectInfo.select !== this.props.inspectInfo.select) {
      if (this.props.inspectInfo.select) {
        this.setSelect(this.props.inspectInfo.select);
      }
    }
    if (prevProps.inspectInfo.isDeleteSuccess
      !== this.props.inspectInfo.isDeleteSuccess) {
      if (this.props.inspectInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setSelect = (select) => {
    this.setState({ appStatus: select.appStatus, inspectStatus: select.inspectStatus });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(inspectActions.setSearchCondition(searchCondition));
    this.props.dispatch(inspectActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(inspectActions.deleteData({ ids }));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      appStatus,
      inspectStatus,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="検収番号" />
              <TextField value={searchCondition.searchInspectNo} fullWidth name="searchInspectNo" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="承認" />
              <SearchMultipleSelectBox
                name="searchAppStatus"
                options={appStatus}
                values={searchCondition.searchAppStatus}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="状況" />
              <SearchMultipleSelectBox
                name="searchInspectStatus"
                options={inspectStatus}
                values={searchCondition.searchInspectStatus}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} my={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="計上月" />
              <MonthPicker value={searchCondition.searchMonth} name="searchMonth" handleChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="プロジェクト名／取引先名" />
              <TextField value={searchCondition.searchKeyword} fullWidth name="searchKeyword" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DeleteDataTable
          url={urls.INSPECT_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          onAddOpen={this.onAdd}
          procName="検収"
          isNotAdd
          exclusion={['approvalStatus']}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectInfo: state.inspectStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(InspectList);
