import React from 'react';
import {
  TableSortLabel, TableCell,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled('span')(() => ({
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
}));

export default function TableHeaderCell(props) {
  const {
    headCell, orderBy, order, handleRequestSort,
  } = props;

  const onSortHandle = (event, property) => {
    let newOrder = 'asc';
    let newOrderBy = property;
    if (orderBy === property && order === 'asc') {
      newOrder = 'desc';
    }
    if (orderBy === property && order === 'desc') {
      newOrderBy = '';
    }
    handleRequestSort(event, { order: newOrder, orderBy: newOrderBy });
  };

  return (
    <TableCell
      key={headCell.id}
      align={headCell.numeric ? 'right' : 'left'}
      sortDirection={orderBy === headCell.id ? order : false}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={(event) => onSortHandle(event, headCell.id)}
        style={{ verticalAlign: 'baseline', width: '100%' }}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <Item>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Item>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
}
