import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, InputAdornment, IconButton, OutlinedInput, Alert,
  DialogContent, DialogActions, Button, Paper, Avatar, Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { passwordActions } from '../../../redux/profile/password/passwordState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import urls from '../../../constants/frontUrls';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import logo from '../../../asset/logo.png';

class InitPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confPassword: '',
      passwordError: '',
      confPasswordError: '',
      showPassword: false,
      showConfPassword: false,
      open: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.passwordInfo.isSuccess !== this.props.passwordInfo.isSuccess) {
      if (this.props.passwordInfo.isSuccess) {
        this.onSuccessOpen();
      }
    }
  }

  onSuccessOpen = () => {
    this.setState({ open: true });
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleClickShowConfPassword = () => {
    const { showConfPassword } = this.state;
    this.setState({ showConfPassword: !showConfPassword });
  };

  onPasswordChange = (event) => {
    const { confPassword, confPasswordError } = this.state;
    const { value } = event.target;

    let tempConfPasswordError = confPasswordError;
    if (confPassword) {
      tempConfPasswordError = Validation.formValidate('confPassword', value, confPassword);
    }
    this.setState({
      password: event.target.value,
      passwordError: Validation.formValidate('password', value, null),
      confPasswordError: tempConfPasswordError,
    });
  };

  onConfPasswordChange = (event) => {
    const { password } = this.state;

    this.setState({
      confPassword: event.target.value,
      confPasswordError: Validation.formValidate('confPassword', password, event.target.value),
    });
  };

  onSave = () => {
    const { password, confPassword } = this.state;
    const passwordError = Validation.formValidate('password', password, null);
    const confPasswordError = Validation.formValidate('confPassword', password, confPassword);
    const isError = (passwordError || confPasswordError);

    this.setState({ passwordError, confPasswordError });

    if (isError) {
      return;
    }

    this.props.dispatch(passwordActions.updatePassword({ password }));
  };

  // eslint-disable-next-line class-methods-use-this
  onClose = () => {
    window.location.href = urls.COMMON.LOGIN.url;
  };

  render() {
    const {
      password, passwordError,
      confPassword, confPasswordError,
      showPassword, showConfPassword, open,
    } = this.state;

    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={15} style={{ padding: '30px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            mb={2}
          >
            <Avatar sx={{ m: 1 }} src={logo} />
            <Typography component="h1" variant="h5">
              初期パスワード変更
            </Typography>
          </Box>
          <Alert severity="info">
            <Box>パスワード変更後、自動ログアウトします。</Box>
            <Box>8文字以上16文字以下で半角英数字、記号(! # $ @ . _)のみが使用できます。</Box>
          </Alert>
          <Box my={2}>
            <FormTitle title="パスワード" />
            <OutlinedInput
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={password}
              name="password"
              id="password"
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              autoComplete="off"
              onChange={this.onPasswordChange}
            />
            <FormErrorText>{passwordError}</FormErrorText>
          </Box>
          <Box>
            <FormTitle title="確認パスワード" />
            <OutlinedInput
              required
              fullWidth
              type={showConfPassword ? 'text' : 'password'}
              value={confPassword}
              name="confPassword"
              id="confPassword"
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClickShowConfPassword}
                    edge="end"
                  >
                    {showConfPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              autoComplete="off"
              onChange={this.onConfPasswordChange}
            />
            <FormErrorText>{confPasswordError}</FormErrorText>
          </Box>
          <Box mt={2} textAlign="center">
            <LoadingButton variant="contained" loading={this.props.common.isLoading} onClick={this.onSave} disabled={passwordError || confPasswordError}>保存</LoadingButton>
          </Box>
        </Paper>
        <MaterialDialog open={open} onClose={this.onClose}>
          <DialogColorTitle>パスワード変更</DialogColorTitle>
          <DialogContent dividers>
            <Box>パスワードの変更が完了しました</Box>
            <Box>再ログインしてください</Box>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.onClose}>ログアウトする</Button>
          </DialogActions>
        </MaterialDialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  passwordInfo: state.passwordStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(InitPassword);
