import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import { cacheActions } from '../../../redux/office/cache/cacheState';
import urls from '../../../constants/frontUrls';
import Link from '../../../components/atoms/link/Link';
import ConfCheckDialog from './ConfCheckDialog';
import TesuryoDialog from './TesuryoDialog';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [unconfOpen, setUnconfOpen] = React.useState(false);
  const [confOpen, setConfOpen] = React.useState(false);
  const [tesuryoOpen, setTesuryoOpen] = React.useState(false);
  const dispatch = useDispatch();
  const isUnConfirm = useSelector((state) => state.cacheStore.isUnConfirm);
  const isConfirm = useSelector((state) => state.cacheStore.isConfirm);
  const isChange = useSelector((state) => state.cacheStore.isChange);

  const handleSelectAllClick = (event) => {
    const { rows } = props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.filter((data) => data.targetDt).map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const {
    rows,
    disabled,
    searchKbn,
    onResearch,
  } = props;

  React.useEffect(() => {
    setSelected([]);
  }, [searchKbn]);

  const handleClose = () => {
    setUnconfOpen(false);
    setConfOpen(false);
    setTesuryoOpen(false);
  };

  React.useEffect(() => {
    if (isChange) {
      handleClose();
      setSelected([]);
      dispatch(cacheActions.resetFlg());
      onResearch('手数料変更処理が完了しました');
    }
  }, [isChange]);
  React.useEffect(() => {
    if (isConfirm) {
      handleClose();
      setSelected([]);
      dispatch(cacheActions.resetFlg());
      onResearch('確認処理が完了しました');
    }
  }, [isConfirm]);
  React.useEffect(() => {
    if (isUnConfirm) {
      handleClose();
      setSelected([]);
      dispatch(cacheActions.resetFlg());
      onResearch('未確認処理が完了しました');
    }
  }, [isUnConfirm]);

  const onProcUnconfirm = () => {
    dispatch(cacheActions.updateUnConfirm({ targetKbn: searchKbn, ids: { ids: selected } }));
    setUnconfOpen(false);
    setSelected([]);
  };

  const onClickUnconfirm = () => {
    setUnconfOpen(true);
  };

  const onClickConfirm = () => {
    setConfOpen(true);
  };

  const onClickTesuryo = () => {
    setTesuryoOpen(true);
  };

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Box mr={2}>
                <Tooltip title="手数料変更">
                  <Button color="primary" variant="contained" onClick={onClickTesuryo}>手数料変更</Button>
                </Tooltip>
              </Box>
              <Box mr={2}>
                <Tooltip title="未確認">
                  <Button color="secondary" variant="contained" onClick={onClickUnconfirm}>未確認</Button>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="確認済">
                  <Button color="primary" variant="contained" onClick={onClickConfirm}>確認済</Button>
                </Tooltip>
              </Box>
            </>
          )}
        </Toolbar>
        <TableContainer sx={{ maxHeight: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!disabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((data) => data.targetDt).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((data) => data.targetDt).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((data) => data.targetDt).length === 0}
                    />
                  </TableCell>
                )}
                <TableCell>{`${searchKbn === '0' ? '入金' : '支払'}予定日`}</TableCell>
                <TableCell>取引先</TableCell>
                <TableCell>金額</TableCell>
                <TableCell>手数料</TableCell>
                <TableCell>手数料区分</TableCell>
                <TableCell>{`${searchKbn === '0' ? '請求' : '支払'}No`}</TableCell>
                <TableCell>{`${searchKbn === '0' ? '入金' : '支払'}完了日`}</TableCell>
                <TableCell>確認者</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${searchKbn}+${row.kingaku}+${row.seikyuNo}+${row.id}`}
                    selected={isItemSelected}
                  >
                    {!disabled && (
                      <TableCell padding="checkbox">
                        {row.targetDt && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell>{row.targetDt}</TableCell>
                    <TableCell>{row.clientName}</TableCell>
                    <TableCell>{row.kingaku ? row.kingaku.toLocaleString() : 0}</TableCell>
                    <TableCell>{row.tesuryo}</TableCell>
                    <TableCell>{row.tesuryoKbnMei}</TableCell>
                    <TableCell>
                      {row.targetDt && (
                        <Link to={(searchKbn === '0' ? urls.CLAIM_DETAIL : urls.PAY_DETAIL) + row.id}>{row.seikyuNo}</Link>
                      )}
                    </TableCell>
                    <TableCell>{row.checkedOn}</TableCell>
                    <TableCell>{row.checkedIdMei}</TableCell>
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={9}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <TesuryoDialog
          open={tesuryoOpen}
          searchKbn={searchKbn}
          onClose={handleClose}
          selected={selected}
        />
        <ConfCheckDialog
          open={confOpen}
          searchKbn={searchKbn}
          onClose={handleClose}
          selected={selected}
        />
        <ConfirmDialog
          open={unconfOpen}
          isError
          onClose={handleClose}
          title="未確認処理"
          content="選択したデータを未確認にします"
          onClick={onProcUnconfirm}
          procName="OK"
        />
      </Paper>
    </Box>
  );
}
