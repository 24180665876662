/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const salaryListSlice = createSlice({
  name: 'salaryList',
  initialState: {
    data: {},
    searchCondition: null,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    getCsvData: (state) => {
      state.csvData = null;
    },
    getCsvDataSuccess: (state, action) => {
      state.csvData = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
  },
});

export const salaryListActions = salaryListSlice.actions;

export default salaryListSlice.reducer;
