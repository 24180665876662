/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workSummarySlice = createSlice({
  name: 'workSummary',
  initialState: {
    data: {},
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getData: (state) => {
      state.data = {};
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const workSummaryActions = workSummarySlice.actions;

export default workSummarySlice.reducer;
