/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    data: {},
    select: {},
    searchCondition: null,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getSelect: (state) => {
      state.select = { roleList: [], rankList: [], busyoList: [] };
    },
    getSelectSuccess: (state, action) => {
      state.select = action.payload;
    },
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetFlg: (state) => {
      state.isInsertSuccess = false;
      state.isUpdateSuccess = false;
    },
    insertData: (state) => {
      state.isInsertSuccess = false;
    },
    insertDataSuccess: (state, action) => {
      state.isInsertSuccess = true;
      state.saveId = action.payload;
    },
    updateData: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
  },
});

export const employeeActions = employeeSlice.actions;

export default employeeSlice.reducer;
