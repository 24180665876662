import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Box, DialogTitle, DialogContent, DialogActions, Grid,
  FormControl, RadioGroup, Radio, FormControlLabel,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { orderActions } from '../../../redux/sales/order/orderState';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import urls from '../../../constants/frontUrls';
import SelectProject from '../../../components/organisms/selectProject/SelectProject';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { clientActions } from '../../../redux/sales/client/clientState';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';

const tmpMessage = {
  orderNo: '',
  projectId: '',
  orderStDt: '',
  orderEdDt: '',
  orderAmount: '',
  clientId: '',
};

const tmpForm = {
  id: null,
  orderNo: '',
  projectId: '',
  orderStDt: '',
  orderEdDt: '',
  taxType: '10',
  clientNo: '',
  orderAmount: '',
  clientId: '',
};

export default function OrderDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const [select, setSelect] = React.useState({ clients: [] });
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, orderId, projectId, onSaveSuccess,
  } = props;
  const data = useSelector((state) => state.orderStore.data);
  const dbSelect = useSelector((state) => state.orderStore.select);
  const isCreateSuccess = useSelector((state) => state.orderStore.isCreateSuccess);
  const isUpdateSuccess = useSelector((state) => state.orderStore.isUpdateSuccess);
  const saveId = useSelector((state) => state.orderStore.saveId);
  const clientData = useSelector((state) => state.clientStore.data);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (isCreateSuccess) {
      navigate(urls.ORDER_DETAIL + saveId);
    }
  }, [isCreateSuccess]);
  React.useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(orderActions.resetFlg());
      onSaveSuccess();
    }
  }, [isUpdateSuccess]);
  React.useEffect(() => {
    if (dbSelect) {
      setSelect(dbSelect);
    }
  }, [dbSelect]);

  React.useEffect(() => {
    if (isOpen) {
      if (orderId) {
        dispatch(
          orderActions.getData({ id: orderId }),
        );
        setLoading(true);
      }
      setForm({
        ...tmpForm,
        projectId,
      });
      setErrorMessage(tmpMessage);
      dispatch(orderActions.getSelect());
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setLoading(false);
      setForm(data);
    }
  }, [data]);
  React.useEffect(() => {
    if (clientData) {
      setForm({
        ...form,
        clientNo: clientData.clientNo,
      });
    }
  }, [clientData]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (!tempMessage.orderStDt && !tempMessage.orderEdDt) {
      const msg = Validation.compareDateValidate(form.orderStDt, form.orderEdDt);
      if (msg) {
        isError = true;
      }
      tempMessage.orderEdDt = msg;
    }

    if (isError) {
      return;
    }

    if (!orderId) {
      dispatch(orderActions.insertData(form));
    } else {
      dispatch(orderActions.updateData(form));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    let tempStatus = form.status;
    if (name === 'status') {
      tempStatus = value;
    }
    if (name === 'clientId') {
      dispatch(clientActions.getData({ id: value }));
    }
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value, tempStatus) });
  };

  return (
    <MaterialDialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>発注編集</DialogTitle>
      <LinearProgress style={{ display: loading ? '' : 'none' }} />
      <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="発注番号" isRequired />
            <TextField variant="outlined" error={errorMessage.orderNo !== ''} autoComplete="off" fullWidth value={form.orderNo} inputProps={{ maxLength: 30 }} name="orderNo" onChange={onChange} />
            <FormErrorText>{errorMessage.orderNo}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="取引先番号" />
            <Box>{form.clientNo}</Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="契約開始" isRequired />
            <DatePicker
              handleChange={onChange}
              error={errorMessage.orderStDt !== ''}
              value={form.orderStDt}
              name="orderStDt"
            />
            <FormErrorText>{errorMessage.orderStDt}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="契約終了" isRequired />
            <DatePicker
              handleChange={onChange}
              value={form.orderEdDt}
              error={errorMessage.orderEdDt !== ''}
              name="orderEdDt"
            />
            <FormErrorText>{errorMessage.orderEdDt}</FormErrorText>
          </Grid>
        </Grid>
        <Box mt={2}>
          <FormTitle title="発注先" isRequired />
          <SearchSelectBox options={select.clients} onChange={onChange} name="clientId" value={form.clientId} error={errorMessage.clientId !== ''} />
          <FormErrorText>{errorMessage.clientId}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="プロジェクト" isRequired />
          <SelectProject
            name="projectId"
            disableClearable
            targetMonth={form.orderStDt}
            value={form.projectId}
            onChange={onChange}
            error={errorMessage.projectId !== ''}
          />
          <FormErrorText>{errorMessage.projectId}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="消費税" isRequired />
          <FormControl>
            <RadioGroup
              row
              value={form.contractKbn}
              onChange={onChange}
              name="taxType"
            >
              <FormControlLabel
                checked={form.taxType === '0'}
                value="0"
                control={<Radio />}
                label="なし"
              />
              <FormControlLabel
                checked={form.taxType === '10'}
                value="10"
                control={<Radio />}
                label="10%"
              />
            </RadioGroup>
          </FormControl>
          <FormErrorText>{errorMessage.taxType}</FormErrorText>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} color="inherit" onClick={onClose}>
          キャンセル
        </LoadingButton>
        <LoadingButton loading={loading} color="primary" onClick={onClickSave}>
          保存
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
