import * as React from 'react';
import { TextField as MateralTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextField = styled(MateralTextField)(() => ({
  '& .MuiOutlinedInput-input': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: '3px',
    paddingLeft: '3px',
    textAlign: 'right',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    '-webkit-text-fill-color': '#000',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px',
  },
  '& .Mui-disabled': {
    color: '#000',
    background: 'rgba(0, 0, 0, 0.03)',
  },
}));

export default function SmallNumberInput(props) {
  const {
    value, onChange, error, name, disabled, onBlur,
  } = props;
  return (
    <TextField variant="outlined" error={error} disabled={disabled} onBlur={onBlur} value={value} name={name} inputProps={{ type: 'number' }} onChange={onChange} />
  );
}
