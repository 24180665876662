import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Accordion, AccordionDetails, AccordionSummary, Typography, Paper,
} from '@mui/material';
import {
  Person as PersonIcon, ExpandMore as ExpandMoreIcon, Info as InfoIcon,
} from '@mui/icons-material';
import { topNoticeActions } from '../../redux/top/notice/topNoticeState';
import RichTextReadOnly from '../../components/templates/wysiwyg/RichTextReadOnly';

class TopNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      isBp: props.loginInfo.userInfo ? props.loginInfo.userInfo.user.bp : true,
    };
    this.props.dispatch(topNoticeActions.getDatas());
  }

  componentDidUpdate(prevProps) {
    if (this.props.topNotice.datas !== prevProps.topNotice.datas) {
      if (this.props.topNotice.datas) {
        this.setInitDatas(this.props.topNotice.datas);
      }
    }
    if (this.props.loginInfo.userInfo !== prevProps.loginInfo.userInfo) {
      if (this.props.loginInfo.userInfo) {
        this.setUser(this.props.loginInfo.userInfo);
      }
    }
  }

  setUser = (userInfo) => {
    this.setState({ isBp: userInfo.user.bp });
  };

  setInitDatas = (datas) => {
    this.setState({ datas });
  };

  render() {
    const { datas, isBp } = this.state;
    if (isBp) {
      return null;
    }
    return (
      <Box>
        {datas.length > 0 && (
          <Paper elevation={3}>
            <Box display="flex" py={2} pl={2}>
              <InfoIcon />
              <Box>お知らせ</Box>
            </Box>
            {datas.map((data) => (
              <Accordion key={data.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ width: '65%', flexShrink: 0 }}>{data.title}</Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{data.updatedAt}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="css-notice">
                    <RichTextReadOnly content={data.content} />
                  </div>
                  <Box display="flex">
                    <Box style={{ flex: '50%' }} />
                    <Box display="flex">
                      <PersonIcon />
                      <Box>{data.syainMei}</Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  topNotice: state.topNoticeStore,
  loginInfo: state.loginStore,
});

export default connect(mapStateToProps)(TopNotice);
