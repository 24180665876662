/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const employeeHistorySlice = createSlice({
  name: 'employeeHistory',
  initialState: {
    datas: {},
    searchCondition: null,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = [];
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    insertData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    updateData: (state) => {
      state.isSaveSuccess = false;
    },
  },
});

export const employeeHistoryActions = employeeHistorySlice.actions;

export default employeeHistorySlice.reducer;
