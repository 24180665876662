import React from 'react';
import { format, addMonths } from 'date-fns';
import { connect } from 'react-redux';
import {
  Container, Box, Button, Backdrop, CircularProgress,
  FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { salesCloseActions } from '../../redux/close/salesClose/salesCloseState';
import SalesCheckClose from './SalesCheckClose';

class SalesClose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { processMonth: '', processKbn: '' },
      displayMonth: '',
      form: {
        checkOne: false, checkTwo: false, checkThree: false, checkFour: false,
      },
      nextMonth: '',
      nextDispMonth: '',
      isShowCheck: false,
      isProcShow: false,
      isLoading: false,
      check: {
        work: null,
        projects: [],
      },
    };
    props.dispatch(salesCloseActions.getDatas());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salesCloseInfo.data !== this.props.salesCloseInfo.data) {
      if (this.props.salesCloseInfo.data) {
        this.setDatas(this.props.salesCloseInfo.data);
      }
    }
    if (prevProps.salesCloseInfo.isUpdateSuccess !== this.props.salesCloseInfo.isUpdateSuccess) {
      if (this.props.salesCloseInfo.isUpdateSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.salesCloseInfo.checkData !== this.props.salesCloseInfo.checkData) {
      if (this.props.salesCloseInfo.checkData) {
        this.setCheckData(this.props.salesCloseInfo.checkData);
      }
    }
  }

  setCheckData(check) {
    this.setState({ check, isShowCheck: true, isLoading: false });
  }

  setDatas(data) {
    this.setState({
      data,
      isLoading: false,
      displayMonth: format(new Date(data.processMonth), 'yyyy年MM月'),
      nextMonth: format(addMonths(new Date(data.processMonth), 1), 'yyyy/MM/dd'),
      nextDispMonth: format(addMonths(new Date(data.processMonth), 1), 'yyyy年MM月'),
    });
  }

  setSuccess = () => {
    this.setState({ isProcShow: false, isShowCheck: false });
    this.props.dispatch(salesCloseActions.getDatas());
  };

  onClick = (_, processMonth, processKbn, cancel) => {
    this.props.dispatch(salesCloseActions.updateData({
      processMonth, processKbn, cancel,
    }));
    const form = {
      checkOne: false, checkTwo: false, checkThree: false, checkFour: false,
    };
    this.setState({ isLoading: true, isShowCheck: false, form });
  };

  onCheck = (_, processMonth) => {
    this.props.dispatch(salesCloseActions.checkData({ processMonth }));
    this.setState({ isLoading: true });
  };

  onPreProc = () => {
    this.setState({ isProcShow: true });
  };

  onPreProcCancel = () => {
    const form = {
      checkOne: false, checkTwo: false, checkThree: false, checkFour: false,
    };
    this.setState({ isProcShow: false, form });
  };

  onChange = (event) => {
    const { name, checked } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: checked,
      },
    });
  };

  render() {
    const {
      isLoading, data, displayMonth, nextDispMonth, nextMonth, check, isShowCheck, isProcShow, form,
    } = this.state;

    if (isLoading) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return (
      <Container maxWidth="xl">
        {!isProcShow && (
          <Box mt={2}>
            <Box sx={{ fontWeight: 'bold' }}>{`現在：${displayMonth}分の${data.processKbnName}`}</Box>
            <Box mt={2} display="flex">
              {data.processKbn === '2' && (
                <Box mr={2}>
                  <Button variant="outlined" onClick={(e) => this.onCheck(e, nextMonth)}>{`${nextDispMonth}仮締め前チェック`}</Button>
                </Box>
              )}
              {data.processKbn === '2' && (
                <Box>
                  <Button variant="contained" onClick={(event) => this.onClick(event, nextMonth, '1', false)}>{`${nextDispMonth}仮締め実行`}</Button>
                </Box>
              )}
              {data.processKbn === '1' && (
                <Box mr={2}>
                  <Button variant="contained" color="secondary" onClick={(event) => this.onClick(event, data.processMonth, '2', true)}>{`${displayMonth}仮締めキャンセル`}</Button>
                </Box>
              )}
              {data.processKbn === '1' && (
                <Box>
                  <Button variant="contained" onClick={this.onPreProc}>{`${displayMonth}本締め`}</Button>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {isProcShow && (
          <Box>
            <Box>{`${displayMonth}分の本締めを行います。元に戻すことはできません。`}</Box>
            <Box>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={form.checkOne} name="checkOne" onChange={this.onChange} />} label="対象月の支払入力が完了している" />
                <FormControlLabel control={<Checkbox checked={form.checkFour} name="checkFour" onChange={this.onChange} />} label="対象月の検収入力が完了している" />
                <FormControlLabel control={<Checkbox checked={form.checkTwo} name="checkTwo" onChange={this.onChange} />} label="対象月の請求支払入力が完了している" />
                <FormControlLabel control={<Checkbox checked={form.checkThree} name="checkThree" onChange={this.onChange} />} label="作業報告承認が完了している" />
              </FormGroup>
            </Box>
            <Box mt={2} display="flex">
              <Box mr={2}>
                <Button variant="contained" color="secondary" onClick={this.onPreProcCancel}>キャンセル</Button>
              </Box>
              <Box>
                <Button variant="contained" disabled={!form.checkOne || !form.checkTwo || !form.checkThree || !form.checkFour} onClick={(event) => this.onClick(event, data.processMonth, '2', false)}>{`${displayMonth}本締め`}</Button>
              </Box>
            </Box>
          </Box>
        )}
        {isShowCheck && (
          <SalesCheckClose data={check} open={isShowCheck} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  salesCloseInfo: state.salesCloseStore,
});

export default connect(mapStateToProps)(SalesClose);
