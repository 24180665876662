/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workPlanResultSlice = createSlice({
  name: 'workPlanResult',
  initialState: {
    data: {},
  },
  reducers: {
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const workPlanResultActions = workPlanResultSlice.actions;

export default workPlanResultSlice.reducer;
