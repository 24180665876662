/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { workStyleActions } from './workStyleState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SETTING.WORK_STYLE.WORK_STYLE, payload);
    yield put(workStyleActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SETTING.WORK_STYLE.WORK_STYLE, payload);
    yield put(workStyleActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SETTING.WORK_STYLE.WORK_STYLE_ID, payload);
    yield put(workStyleActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SETTING.WORK_STYLE.WORK_STYLE, payload);
    yield put(workStyleActions.saveDataSuccess(result));
  } catch (_) {}
}

function* workStyleSaga() {
  yield takeLatest('workStyle/getDatas', getData);
  yield takeLatest('workStyle/deleteData', deleteData);
  yield takeLatest('workStyle/getData', getDataFromId);
  yield takeLatest('workStyle/saveData', saveDatas);
}

export default workStyleSaga;
