/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const topTodoSlice = createSlice({
  name: 'topTodo',
  initialState: {
    datas: [],
  },
  reducers: {
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
  },
});

export const topTodoActions = topTodoSlice.actions;

export default topTodoSlice.reducer;
