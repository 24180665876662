import React from 'react';
import { isValid, format } from 'date-fns';
import jaLocale from 'dayjs/locale/ja';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker as MuiTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

export default function TimePicker(props) {
  const {
    value,
    handleChange,
    name,
    isDisabled,
  } = props;

  const onChange = (changeValue) => {
    let formatValue = changeValue ? changeValue.$d : null;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'HH:mm');
    }
    const event = {
      target: {
        name,
        value: formatValue,
      },
    };

    handleChange(event);
  };

  const fnsValue = value ? new Date(`1999/12/31 ${value}`) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={jaLocale}>
      <MuiTimePicker
        value={fnsValue}
        disabled={isDisabled}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
