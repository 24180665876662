import React from 'react';
import {
  DialogContent, DialogActions, Box,
  FormControlLabel, FormControl, RadioGroup, Radio,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { cacheActions } from '../../../redux/office/cache/cacheState';

export default function TesuryoDialog(props) {
  const [tesuryo, setTesuryo] = React.useState('');
  const [tesuryoKbn, setTesuryoKbn] = React.useState('0');
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const dispatch = useDispatch();
  const {
    open,
    onClose,
    searchKbn,
    selected,
  } = props;

  const onChange = (event) => {
    const { value, name } = event.target;
    if (name === 'tesuryo') {
      setTesuryo(value);
    } else {
      setTesuryoKbn(value);
    }
  };

  const onClick = () => {
    dispatch(cacheActions.updateChange({
      targetKbn: searchKbn, ids: { ids: selected }, tesuryo, tesuryoKbn,
    }));
  };

  React.useEffect(() => {
    if (open) {
      setTesuryo('');
      setTesuryoKbn('0');
    }
  }, [open]);

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle>手数料変更処理</DialogColorTitle>
      <DialogContent dividers>
        <Box mb={2}>選択したデータの手数料を変更します</Box>
        <Box mb={2}>
          <FormTitle title="手数料" />
          <MoneyInput fullWidth value={tesuryo} name="tesuryo" onChange={onChange} />
        </Box>
        <Box>
          <FormTitle title="手数料区分" />
          <FormControl>
            <RadioGroup
              row
              name="tesuryoKbn"
              value={tesuryoKbn}
              onChange={onChange}
            >
              <FormControlLabel checked={tesuryoKbn === '0'} value="0" control={<Radio />} label="当方" />
              <FormControlLabel checked={tesuryoKbn === '1'} value="1" control={<Radio />} label="先方" />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={isLoading} color="inherit">キャンセル</LoadingButton>
        <LoadingButton
          onClick={onClick}
          color="primary"
          loading={isLoading}
          disabled={!tesuryo || !tesuryoKbn}
        >
          変更する
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
