/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const topNoticeSlice = createSlice({
  name: 'topNotice',
  initialState: {
    datas: [],
  },
  reducers: {
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
  },
});

export const topNoticeActions = topNoticeSlice.actions;

export default topNoticeSlice.reducer;
