import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Card, CardContent, Grid, Button, Box, Checkbox, FormControlLabel,
  Paper, Alert, Container, Tabs, Tab, List, ListItem as MuiListItem, ListItemText,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails,
} from '@mui/material';
import {
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { roleDetailActions } from '../../../redux/setting/roleDetail/roleDetailState';
import RoleDetailDialog from './RoleDetailDialog';
import {
  getIndex, getIncludes, getEdit, getFormatScreens,
} from './role-detail-proc';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters defaultExpanded elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  margin: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const ListItem = styled((props) => (
  <MuiListItem {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  margin: '5px',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

class RoleDetail extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      screens: new Map(),
      categories: [],
      roleScreens: [],
      members: [],
      role: { id: null, name: '' },
      tabValue: 0,
      updateModalOpen: false,
      successSnackOpen: props.roleDetail.isSaveSuccess,
      successSnackMessage: props.roleDetail.isSaveSuccess ? '権限の登録が完了しました' : '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };

    this.props.dispatch(roleDetailActions.clearFlag());
    this.props.dispatch(roleDetailActions.getScreen());
    this.props.dispatch(roleDetailActions.getData({ id }));
  }

  componentDidUpdate(prevProps) {
    const { roleDetail } = this.props;

    if (prevProps.roleDetail.screens !== roleDetail.screens && roleDetail.screens) {
      this.setScreens(roleDetail.screens);
    }

    if (prevProps.roleDetail.data !== roleDetail.data && roleDetail.data) {
      this.setData(roleDetail.data);
    }

    if (prevProps.roleDetail.isUpdateSuccess
      !== roleDetail.isUpdateSuccess && roleDetail.isUpdateSuccess) {
      this.setUpdateSuccess();
    }

    if (prevProps.roleDetail.isSaveScreenSuccess
      !== roleDetail.isSaveScreenSuccess && roleDetail.isSaveScreenSuccess) {
      this.setScreenUpdateSuccess();
    }
  }

  setScreens = (tempScreens) => {
    const value = getFormatScreens(tempScreens);
    this.setState({ screens: value.values, categories: value.category });
  };

  setData = (roleDetail) => {
    this.setState({
      roleScreens: roleDetail.roleScreens,
      role: roleDetail.role,
      members: roleDetail.members,
    });
  };

  onTabChange = (_, tabValue) => this.setState({ tabValue });

  onUpdate = () => this.setState({ updateModalOpen: true, successSnackOpen: false });

  handleClick = (_, screenId) => {
    const { roleScreens } = this.state;
    const selectedIndex = getIndex(screenId, roleScreens, 'id');
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...roleScreens, { id: screenId, edit: false }];
    } else {
      newSelected = [
        ...roleScreens.slice(0, selectedIndex),
        ...roleScreens.slice(selectedIndex + 1),
      ];
    }

    this.setState({ roleScreens: newSelected });
  };

  handleEditClick = (_, screenId) => {
    const { roleScreens } = this.state;
    const selectedIndex = getIndex(screenId, roleScreens, 'id');
    let newSelected = [];
    let newRoleScreen;

    if (selectedIndex === -1) {
      newRoleScreen = { id: screenId, edit: true, own: false };
      newSelected = [...roleScreens, newRoleScreen];
    } else {
      const currentRoleScreen = roleScreens[selectedIndex];
      newRoleScreen = {
        ...currentRoleScreen,
        edit: !currentRoleScreen.edit,
      };
      newSelected = [
        ...roleScreens.slice(0, selectedIndex),
        ...roleScreens.slice(selectedIndex + 1),
        newRoleScreen,
      ];
    }

    this.setState({ roleScreens: newSelected });
  };

  handleOwnClick = (_, screenId) => {
    const { roleScreens } = this.state;
    const selectedIndex = getIndex(screenId, roleScreens, 'id');
    let newSelected = [];
    let newRoleScreen;

    if (selectedIndex === -1) {
      newRoleScreen = { id: screenId, own: true, edit: false };
      newSelected = [...roleScreens, newRoleScreen];
    } else {
      const currentRoleScreen = roleScreens[selectedIndex];
      newRoleScreen = {
        ...currentRoleScreen,
        own: !currentRoleScreen.own,
      };
      newSelected = [
        ...roleScreens.slice(0, selectedIndex),
        ...roleScreens.slice(selectedIndex + 1),
        newRoleScreen,
      ];
    }

    this.setState({ roleScreens: newSelected });
  };

  updateRoleScreen = () => {
    this.setState({ successSnackOpen: false });
    const { roleScreens, role } = this.state;
    this.props.dispatch(
      roleDetailActions.saveScreenData({ id: role.id, screenIds: roleScreens }),
    );
  };

  setScreenUpdateSuccess = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '使用可能画面の保存が完了しました',
    });
    this.props.dispatch(roleDetailActions.getData({ id: this.state.role.id }));
  };

  handleDetailClose = () => this.setState({ updateModalOpen: false });

  setUpdateSuccess = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '権限の変更が完了しました',
      updateModalOpen: false,
    });
    this.props.dispatch(roleDetailActions.getData({ id: this.state.role.id }));
  };

  handleSuccessSnackbarClose = () => this.setState({ successSnackOpen: false });

  isSelected = (screenId) => getIncludes(screenId, this.state.roleScreens, 'id');

  isEditSelected = (screenId) => getEdit(screenId, this.state.roleScreens, 'id', 'edit');

  isOwnSelected = (screenId) => getEdit(screenId, this.state.roleScreens, 'id', 'own');

  render() {
    const {
      screens, updateModalOpen, role, categories, tabValue,
      successSnackOpen, successSnackMessage, isDisabled, members,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Card>
              <CardContent>
                <Box>
                  <FormTitle title="権限名" />
                  <Box>{role.name}</Box>
                </Box>
                <Box mt={2}>
                  <FormTitle title="承認権限" />
                  <Box>{role.approval ? '有' : '無'}</Box>
                </Box>
                <Box mt={2}>
                  <FormTitle title="事務権限" />
                  <Box>{role.affairs ? '有' : '無'}</Box>
                </Box>
                <Box mt={2}>
                  <FormTitle title="BP用" />
                  <Box>{role.bp ? 'BP' : ''}</Box>
                </Box>
              </CardContent>
              <Box borderTop={1} borderColor="#eeeeee" />
              {!isDisabled && (
                <Box textAlign="right" my={2} mr={2}>
                  <Button color="primary" variant="outlined" onClick={this.onUpdate}>権限の変更</Button>
                </Box>
              )}
            </Card>
          </Grid>

          <Grid item sm={8} xs={12}>
            <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
                <Tab label="画面" />
                <Tab label="メンバー" />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0} isAll>
              <Paper>
                <Box p={2}>
                  <Alert severity="info">編集にチェックをしない場合は参照のみとなります</Alert>
                </Box>
                <Box style={{ overflowY: 'scroll', maxHeight: '600px' }}>
                  {categories.map((text) => (
                    <Accordion key={`category${text}`}>
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Box>{text}</Box>
                      </AccordionSummary>
                      {text === '申請' && (
                        <Box px={2} pt={2}>
                          <Alert severity="error">全てのデータにチェックを入れると全員のデータが表示され、チェックをはずとログインしたユーザのみのデータとなります</Alert>
                        </Box>
                      )}
                      {screens.get(text).map((screen) => {
                        const isSelected = this.isSelected(screen.id);
                        const isEditSelected = this.isEditSelected(screen.id);
                        const isOwnSelected = this.isOwnSelected(screen.id);
                        return (
                          <AccordionDetails key={screen.id}>
                            <FormControlLabel
                              aria-label="screenName"
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={<Checkbox disabled={isDisabled} checked={isSelected} color="primary" onClick={(event) => this.handleClick(event, screen.id)} />}
                              label={screen.name}
                            />
                            {(screen.categoryNo !== 0
                              && screen.categoryNo !== 6 && screen.categoryNo !== 8) && (
                              <FormControlLabel
                                aria-label="edit"
                                control={(
                                  <Checkbox
                                    disabled={isDisabled}
                                    checked={isEditSelected}
                                    onClick={(event) => this.handleEditClick(event, screen.id)}
                                  />
                                )}
                                label={(screen.url !== '/vacations' && screen.categoryNo !== 1) ? '編集' : '全てのデータ'}
                              />
                            )}
                            {screen.url === '/employees' && (
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    disabled={isDisabled}
                                    checked={isOwnSelected}
                                    onClick={(event) => this.handleOwnClick(event, screen.id)}
                                  />
                                )}
                                label="自身のデータのみ"
                              />
                            )}
                            {screen.url === '/vacations' && (
                              <Alert severity="error">全てのデータにチェックを入れると全員のデータが表示され、チェックをはずとログインしたユーザのみのデータとなります</Alert>
                            )}
                          </AccordionDetails>
                        );
                      })}
                    </Accordion>
                  ))}
                </Box>
                <Box textAlign="right" p={1}>
                  {!isDisabled && (
                    <LoadingButton
                      loading={this.props.common.isLoading}
                      onClick={this.updateRoleScreen}
                    >
                      保存
                    </LoadingButton>
                  )}
                </Box>
              </Paper>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <List dense>
                {members.map((member) => (
                  <ListItem key={member.name}>
                    <ListItemText primary={member.name} />
                  </ListItem>
                ))}
              </List>
            </TabPanel>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={successSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successSnackMessage}
        />

        <RoleDetailDialog
          open={updateModalOpen}
          handleClose={this.handleDetailClose}
          id={role.id}
          role={role}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  roleDetail: state.roleDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(RoleDetail));
