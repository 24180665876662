/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const paySlice = createSlice({
  name: 'pay',
  initialState: {
    datas: {},
    searchCondition: null,
    isDeleteSuccess: false,
    isSaveSuccess: false,
    select: {},
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    insertData: (state) => {
      state.isCreateSuccess = false;
    },
    insertDataSuccess: (state, action) => {
      state.isCreateSuccess = true;
      state.saveId = action.payload;
    },
    resetFlg: (state) => {
      state.isCreateSuccess = false;
      state.isUpdateSuccess = false;
    },
    updateData: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDataSuccess: (state, action) => {
      state.isUpdateSuccess = true;
      state.saveId = action.payload;
    },
    updateStatus: (state) => {
      state.isStatusSuccess = false;
    },
    updateStatusSuccess: (state) => {
      state.isStatusSuccess = true;
    },
    getSelect: (state) => {
      state.select = null;
    },
    getSelectSuccess: (state, action) => {
      state.select = action.payload;
    },
  },
});

export const payActions = paySlice.actions;

export default paySlice.reducer;
