/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { overtimeRequestListActions } from './overtimeRequestListState';
import { getDatas, saveData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.OVERTIME_REQUEST.OVERTIME_REQUEST, payload);
    yield put(overtimeRequestListActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveApproval({ payload }) {
  try {
    yield call(saveData, REST_API.REQUEST.OVERTIME_REQUEST.OVERTIME_REQUEST_APPROVAL, payload);
    yield put(overtimeRequestListActions.saveApprovalSuccess());
  } catch (_) {}
}

function* overtimeRequestListSaga() {
  yield takeLatest('overtimeRequestList/getDatas', getData);
  yield takeLatest('overtimeRequestList/saveApproval', saveApproval);
}

export default overtimeRequestListSaga;
