import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Box, Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails, Button, Grid, Paper,
} from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function SummaryData(props) {
  const {
    data, onClickApproval, onClickRequest, isNotEdit, isError, flex, isBp, isLoginBp,
  } = props;
  const isApproval = useSelector((state) => state.loginStore.userInfo).user.approval;
  return (
    <Box my={2}>
      <Box textAlign="right" mb={1} display="flex">
        <Box style={{ flex: '1' }} />
        {data.approverName && (
          <Box mr={1} display="flex" alignItems="center">{`承認者：${data.approverName}`}</Box>
        )}
        {(data.requestFlg && !data.approverName && !isNotEdit && isApproval) && (
          <Box mr={1}><Button variant="contained" onClick={(event) => onClickApproval(event, true)}>承認</Button></Box>
        )}
        {(data.approverName && !isNotEdit && isApproval) && (
          <Box><Button variant="contained" color="secondary" onClick={(event) => onClickApproval(event, false)}>承認解除</Button></Box>
        )}
        {((!data.requestFlg && !data.approverName && !isNotEdit && !isError)
          || (isBp && !data.requestFlg && !data.approverName && !isError)) && (
          <Button variant="contained" onClick={(event) => onClickRequest(event, true)}>承認依頼</Button>
        )}
        {(data.requestFlg && !data.approverName && !isNotEdit) && (
          <Button variant="contained" color="secondary" onClick={(event) => onClickRequest(event, false)}>承認依頼取消</Button>
        )}
      </Box>
      {!isLoginBp && (
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">サマリ</AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>作業日数</Box>
                  <Box>{data.workCnt}</Box>
                </Item>
              </Grid>
              {flex && (
                <Grid item xs={3} md={2}>
                  <Item>
                    <Box sx={{ fontWeight: 'bold' }}>作業時間(有休除く)</Box>
                    <Box>{data.allWorkTm}</Box>
                  </Item>
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>作業時間</Box>
                  <Box>{data.workTm}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>法定外</Box>
                  <Box>{data.dispHtgTm}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>所定外</Box>
                  <Box>{data.sytgTm}</Box>
                </Item>
              </Grid>
              {flex && (
                <Grid item xs={3} md={1}>
                  <Item>
                    <Box sx={{ fontWeight: 'bold' }}>基準所定</Box>
                    <Box>{data.sytWkTm}</Box>
                  </Item>
                </Grid>
              )}
              {flex && (
                <Grid item xs={3} md={1}>
                  <Item>
                    <Box sx={{ fontWeight: 'bold' }}>基準法定</Box>
                    <Box>{data.kjnHtgTm}</Box>
                  </Item>
                </Grid>
              )}

            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>休日出勤</Box>
                  <Box>{data.holidWorkTm}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>法定休日</Box>
                  <Box>{data.htkyjtTm}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>深夜割増</Box>
                  <Box>{data.midOverTm}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>遅刻早退</Box>
                  <Box>{data.lateTm}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>欠勤日数</Box>
                  <Box>{data.noWorkCnt}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>有休取得</Box>
                  <Box>{data.payOffCnt}</Box>
                </Item>
              </Grid>
              <Grid item xs={3} md={2}>
                <Item>
                  <Box sx={{ fontWeight: 'bold' }}>特別休暇</Box>
                  <Box>{data.specialDyCnt}</Box>
                </Item>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}
