export function getMessage() {
  return {
    syainMei: '',
    syainMeiRubi: '',
    birthday: '',
    yubinNo: '',
    entryDt: '',
    outDt: '',
    loginId: '',
    workStday: '',
  };
}

export function getForm() {
  return {
    id: null,
    seibetsu: 'M',
  };
}
