import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Button,
  Tabs, Tab, TextField, FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { format } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { skillActions } from '../../../redux/sales/skill/skillState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import TextArea from '../../../components/atoms/textArea/TextArea';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import ChipInput from './ChipInput';

class SkillDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      projects: [],
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      isSuccessOpen: false,
      tabValue: 0,
      message: '',
      form: {
        id,
        syainId: props.loginUser.userInfo.user.id,
        timingFrom: format(new Date(), 'yyyy/MM/dd'),
        timingTo: '2999/12/31',
        dispPjName: '',
        overview: '',
      },
      errorMessage: {
        syainId: '',
        timingFrom: '',
        timingTo: '',
        dispPjName: '',
      },
    };
    props.dispatch(skillActions.getProjects({ targetDate: format(new Date(), 'yyyy/MM/dd') }));
    if (id) {
      props.dispatch(skillActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.skillInfo.data !== this.props.skillInfo.data) {
      if (this.props.skillInfo.data) {
        this.setDatas(this.props.skillInfo.data);
      }
    }
    if (prevProps.skillInfo.isSaveSuccess !== this.props.skillInfo.isSaveSuccess) {
      if (this.props.skillInfo.isSaveSuccess) {
        this.onSaveSuccess(this.props.skillInfo.saveId);
      }
    }
    if (prevProps.skillInfo.projects !== this.props.skillInfo.projects) {
      if (this.props.skillInfo.projects) {
        this.setProjects(this.props.skillInfo.projects);
      }
    }
  }

  setProjects = (projects) => {
    this.setState({ projects });
  };

  onSaveSuccess = (saveId) => {
    const { form } = this.state;
    this.setState({ form: { ...form, id: saveId }, isSuccessOpen: true, message: '保存が完了しました' });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onCopy = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, id: null }, isSuccessOpen: true, message: 'コピーが完了しました' });
  };

  onSave = () => {
    const { errorMessage, form } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.status);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (!tempMessage.stDt && !tempMessage.edDt) {
      const msg = Validation.compareDateValidate(form.stDt, form.edDt);
      if (msg) {
        isError = true;
      }
      tempMessage.edDt = msg;
    }

    tempMessage.defectProjectName = '';
    if (form.contractKbn === '5' && !form.defectProjectName) {
      tempMessage.defectProjectName = '選択してください';
    }
    this.setState({ errorMessage: tempMessage });
    if (isError) {
      return;
    }
    this.props.dispatch(skillActions.resetFlg());
    this.props.dispatch(skillActions.saveData(form));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;
    const tmpForm = {
      ...form,
      [name]: value,
    };
    if (name === 'timingFrom') {
      this.props.dispatch(skillActions.getProjects({ targetDate: value }));
    }
    this.setState({ form: tmpForm });
  };

  onProjectChange = (event) => {
    const { value } = event.target;
    const { form, projects } = this.state;
    const targetValues = projects.filter((row) => row.id === value);
    let project = {};
    if (targetValues.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      project = targetValues[0];
    }
    const tmpForm = {
      ...form,
      projectId: value,
      dispPjName: project.name,
      overview: project.overview,
    };
    this.setState({ form: tmpForm });
  };

  onCheckChange = (event) => {
    const { name, checked } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: checked,
      },
    });
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, isDisabled, errorMessage, projects, message,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth={false}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="工程" />
            <Tab label="構成" />
            <Tab label="使用OS・ツール" />
            <Tab label="使用言語・サーバー・DB" />
            <Tab label="メモ" />
            <Tab label="プロフィール" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} isAll>
          <Box>
            <FormTitle title="名前" isRequired />
            <SelectSyain
              name="syainId"
              error={errorMessage.syainId !== ''}
              targetMonth={form.timingFrom}
              value={form.syainId}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.name}</FormErrorText>
          </Box>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={3}>
              <FormTitle title="参画開始日" isRequired />
              <DatePicker
                handleChange={this.onChange}
                value={form.timingFrom}
                error={errorMessage.timingFrom !== ''}
                name="timingFrom"
              />
              <FormErrorText>{errorMessage.timingFrom}</FormErrorText>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormTitle title="参画終了日" isRequired />
              <DatePicker
                handleChange={this.onChange}
                error={errorMessage.timingTo !== ''}
                value={form.timingTo}
                name="timingTo"
              />
              <FormErrorText>{errorMessage.timingTo}</FormErrorText>
            </Grid>
          </Grid>
          <Box mt={2}>
            <FormTitle title="プロジェクト" />
            <SearchSelectBox
              name="projectId"
              isDisabled={isDisabled}
              options={projects}
              value={form.projectId}
              onChange={this.onProjectChange}
            />
          </Box>
          <Box mt={2}>
            <FormTitle title="スキルシート表示プロジェクト名" isRequired />
            <TextField autoComplete="off" variant="outlined" error={errorMessage.dispPjName !== ''} fullWidth value={form.dispPjName} inputProps={{ maxLength: 100 }} name="dispPjName" onChange={this.onChange} />
            <FormErrorText>{errorMessage.dispPjName}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="案件概要" />
            <TextArea name="overview" value={form.overview} onChange={this.onChange} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box style={{ maxWidth: '170px' }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={form.process01} name="process01" onChange={this.onCheckChange} />} label="調査" />
              <FormControlLabel control={<Checkbox checked={form.process02} name="process02" onChange={this.onCheckChange} />} label="要件定義" />
              <FormControlLabel control={<Checkbox checked={form.process03} name="process03" onChange={this.onCheckChange} />} label="基本設計" />
              <FormControlLabel control={<Checkbox checked={form.process04} name="process04" onChange={this.onCheckChange} />} label="詳細設計" />
              <FormControlLabel control={<Checkbox checked={form.process05} name="process05" onChange={this.onCheckChange} />} label="実装" />
              <FormControlLabel control={<Checkbox checked={form.process06} name="process06" onChange={this.onCheckChange} />} label="単体テスト" />
              <FormControlLabel control={<Checkbox checked={form.process07} name="process07" onChange={this.onCheckChange} />} label="結合テスト" />
              <FormControlLabel control={<Checkbox checked={form.process08} name="process08" onChange={this.onCheckChange} />} label="システムテスト" />
              <FormControlLabel control={<Checkbox checked={form.process09} name="process09" onChange={this.onCheckChange} />} label="運用・保守" />
              <FormControlLabel control={<Checkbox checked={form.process10} name="process10" onChange={this.onCheckChange} />} label="その他" />
            </FormGroup>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={2} isAll>
          <Box>
            <FormTitle title="担当箇所" />
            <TextArea name="part" value={form.part} onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="チーム構成" />
            <TextArea name="position" value={form.position} onChange={this.onChange} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Box>
            <FormTitle title="OS" />
            <ChipInput name="os" onChange={this.onChange} datas={form.os} />
          </Box>
          <Box mt={2}>
            <FormTitle title="ツール" />
            <ChipInput name="tool" onChange={this.onChange} datas={form.tool} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <Box>
            <FormTitle title="言語" />
            <ChipInput name="lang" onChange={this.onChange} datas={form.lang} />
          </Box>
          <Box mt={2}>
            <FormTitle title="APサーバー" />
            <ChipInput name="ap" onChange={this.onChange} datas={form.ap} />
          </Box>
          <Box mt={2}>
            <FormTitle title="DB" />
            <ChipInput name="db" onChange={this.onChange} datas={form.db} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={5} isAll>
          <Box>
            <FormTitle title="メモ" />
            <TextArea name="memo" value={form.memo} onChange={this.onChange} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={6} isAll>
          <Box>
            <FormTitle title="自己PR" />
            <TextArea name="sellingPoint" value={form.sellingPoint} onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="趣味・特技" />
            <TextArea name="hobby" value={form.hobby} onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="得意技術" />
            <TextArea name="tech" value={form.tech} onChange={this.onChange} />
          </Box>
        </TabPanel>
        {!isDisabled && (
          <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <Box display="flex" justifyContent="center">
              {form.id && (
                <Box pr={1}>
                  <Button variant="outlined" onClick={this.onCopy}>コピー</Button>
                </Box>
              )}
              <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
            </Box>
          </Box>
        )}
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={message}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  skillInfo: state.skillStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(SkillDetail));
