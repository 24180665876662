function mergeCells(worksheet, targets) {
  targets.forEach((cell) => {
    worksheet.mergeCells(cell);
  });
}

function hiddenRow(workSheet, dataCount) {
  for (let i = 0; i < dataCount; i += 1) {
    const row = workSheet.getRow(39 - i);
    row.hidden = true;
  }
}

export default function createData(workbook, datas, targetMonth) {
  const worksheet = workbook.getWorksheet('作業報告書');
  const syain = datas[0];
  let row = worksheet.getRow(3);
  row.getCell(16).value = syain.workUnit;
  row.getCell(2).value = targetMonth;
  row = worksheet.getRow(5);
  row.getCell(3).value = syain.clientName;
  row = worksheet.getRow(6);
  row.getCell(3).value = syain.syainMei;
  mergeCells(worksheet, ['L3:M3', 'B3:C3', 'A5:B5', 'C5:F5', 'A6:B6', 'C6:F6', 'L4:M6']);
  const cellNumber = 9;
  datas.forEach((work, index) => {
    const rowNumber = cellNumber + index;
    row = worksheet.getRow(rowNumber);
    row.getCell(3).value = work.status;
    row.getCell(9).value = work.biko;
    row.getCell(16).value = work.startTm;
    row.getCell(17).value = work.endTm;
    row.getCell(18).value = work.offTm;
    row.getCell(19).value = work.workTm;
    row.getCell(20).value = work.workDt;
    row.getCell(21).value = work.holyFlg;
    mergeCells(worksheet, [`H${rowNumber}:M${rowNumber}`]);
  });
  hiddenRow(worksheet, 31 - datas.length);
}
