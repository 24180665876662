/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workRequestListSlice = createSlice({
  name: 'workRequestList',
  initialState: {
    data: {},
    searchCondition: null,
    isApprovalSuccess: false,
    isCertificateSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveApproval: (state) => {
      state.isApprovalSuccess = false;
    },
    saveApprovalSuccess: (state) => {
      state.isApprovalSuccess = true;
    },
    saveCertificate: (state) => {
      state.isCertificateSuccess = false;
    },
    saveCertificateSuccess: (state) => {
      state.isCertificateSuccess = true;
    },
  },
});

export const workRequestListActions = workRequestListSlice.actions;

export default workRequestListSlice.reducer;
