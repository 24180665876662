/* eslint-disable max-len */
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Box, IconButton, Tooltip, FormControlLabel, FormControl, RadioGroup, Radio,
  DialogContent, DialogActions, Button, TextField, DialogTitle, Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '../../../../components/atoms/dialog/Dialog';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';

class SelectDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSyainId: '',
      otherName: '',
      isType: '0',
      isError: false,
      select: {
        syains: [],
        outsources: [],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectBalanceInfo.select !== this.props.projectBalanceInfo.select) {
      if (this.props.projectBalanceInfo.select) {
        this.setData(this.props.projectBalanceInfo.select);
      }
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.init();
      }
    }
  }

  init = () => {
    this.setState({
      selectedSyainId: '',
      otherName: '',
      isType: '0',
      isError: false,
    });
  };

  setData = (select) => {
    this.setState({ select });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onTypeChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      selectedSyainId: '',
      otherName: '',
    });
  };

  onSetClick = (e) => {
    const { isSyain, exists } = this.props;
    const {
      selectedSyainId, otherName, isType, select,
    } = this.state;
    let targetName = otherName;
    if (isSyain) {
      targetName = select.syains.filter((row) => row.id === selectedSyainId)[0].name;
    } else if (isType === '0') {
      targetName = select.outsources.filter((row) => row.id === selectedSyainId)[0].name;
    }

    const isError = exists.filter((row) => row.costName === targetName).length > 0;
    if (isError) {
      this.setState({ isError });
      return;
    }
    this.props.onAddSet(e, isSyain, { syainId: selectedSyainId, costName: targetName });
  };

  render() {
    const { isOpen, onClose, isSyain } = this.props;
    const {
      select, selectedSyainId, otherName, isType, isError,
    } = this.state;

    return (
      <Dialog
        onClickClose={onClose}
        isOpen={isOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box>{`${isSyain ? 'プロパー' : '外注'}行追加`}</Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <Box mx={2}>
            {isError && (
              <Box mb={1}>
                <Alert severity="error">
                  <Box>既に追加済です</Box>
                </Alert>
              </Box>
            )}
            {!isSyain && (
              <Box>
                <FormControl>
                  <RadioGroup
                    row
                    name="isType"
                    value={isType}
                    onChange={this.onTypeChange}
                  >
                    <FormControlLabel checked={isType === '0'} value="0" control={<Radio />} label="BP" />
                    <FormControlLabel checked={isType === '1'} value="1" control={<Radio />} label="その他" />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            {((!isSyain && isType === '0') || isSyain) && (
              <SearchSelectBox
                name="selectedSyainId"
                options={isSyain ? select.syains : select.outsources}
                value={selectedSyainId}
                onChange={this.onChange}
              />
            )}
            {isType === '1' && (
              <Box mt={2}>
                <TextField value={otherName} name="otherName" fullWidth onChange={this.onChange} />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Tooltip title="設定する">
            <Button onClick={this.onSetClick} disabled={(isSyain && !selectedSyainId) || (!isSyain && isType === '0' && !selectedSyainId) || (!isSyain && isType === '1' && !otherName)}>設定する</Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  projectBalanceInfo: state.projectBalanceStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(SelectDialog);
