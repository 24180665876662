import commonReducer from './common/commonState';
import loginReducer from './login/loginState';

import topCloseReducer from './top/close/topCloseState';
import topNoticeReducer from './top/notice/topNoticeState';
import topTodoReducer from './top/todo/topTodoState';

import costCloseReducer from './close/costClose/costCloseState';
import salesCloseReducer from './close/salesClose/salesCloseState';
import closeEditReducer from './close/closeEdit/closeEditState';

import clientReducer from './sales/client/clientState';
import clientContactReducer from './sales/client/clientContactState';
import clientAgreementReducer from './sales/client/clientAgreementState';
import projectReducer from './sales/project/projectState';
import estimateDetailReducer from './sales/estimate/estimateDetailState';
import estimateReducer from './sales/estimate/estimateState';
import orderReducer from './sales/order/orderState';
import orderDetailReducer from './sales/order/orderDetailState';
import claimReducer from './sales/claim/claimState';
import claimDetailReducer from './sales/claim/claimDetailState';
import payReducer from './sales/pay/payState';
import payDetailReducer from './sales/pay/payDetailState';
import approvalHistoryReducer from './sales/approvalHistory/approvalHistoryState';
import partnerReducer from './sales/partner/partnerState';
import inspectReducer from './sales/inspect/inspectState';
import inspectDetailReducer from './sales/inspect/inspectDetailState';
import skillReducer from './sales/skill/skillState';

import salaryListReducer from './office/salary/salaryListState';
import salaryDetailReducer from './office/salary/salaryDetailState';
import cacheReducer from './office/cache/cacheState';
import vacationReducer from './office/vacation/vacationState';
import employeeReducer from './office/employee/employeeState';
import employeeHistoryReducer from './office/employee/employeeHistoryState';
import employeeLicenseHistoryReducer from './office/employee/employeeLicenseHistoryState';
import certificateCampaignReducer from './office/certificateCampaign/certificateCampaignState';
import fixedExpenseReducer from './office/fixedExpense/fixedExpenseState';

import workPlanReducer from './work/workPlan/workPlanState';
import workResultReducer from './work/workResult/workResultState';
import workResultInputReducer from './work/workResultInput/workResultInputState';
import workPlanResultReducer from './work/workPlanResult/workPlanResultState';
import workClockReducer from './work/workClock/workClockState';
import workCostListReducer from './work/workCostList/workCostListState';
import workSummaryReducer from './work/workSummary/workSummaryState';
import workDeviationReducer from './work/workDeviation/workDeviationState';

import workRequestListReducer from './request/workRequest/workRequestListState';
import workRequestDetailReducer from './request/workRequest/workRequestDetailState';
import overtimeRequestListReducer from './request/overtimeRequest/overtimeRequestListState';
import overtimeRequestDetailReducer from './request/overtimeRequest/overtimeRequestDetailState';
import trafficRequestListReducer from './request/trafficRequest/trafficRequestListState';
import trafficRequestDetailReducer from './request/trafficRequest/trafficRequestDetailState';
import trafficRequestInputReducer from './request/trafficRequest/trafficRequestInputState';
import assetRequestListReducer from './request/assetRequest/assetRequestListState';
import assetRequestDetailReducer from './request/assetRequest/assetRequestDetailState';
import expenseRequestListReducer from './request/expenseRequest/expenseRequestListState';
import expenseRequestDetailReducer from './request/expenseRequest/expenseRequestDetailState';
import workFixRequestReducer from './request/workFixRequest/workFixRequestState';

import roleListReducer from './setting/roleList/roleListState';
import roleDetailReducer from './setting/roleDetail/roleDetailState';
import workStyleReducer from './setting/workStyle/workStyleState';
import noticeReducer from './setting/notice/noticeState';
import loginUserReducer from './setting/loginUser/loginUserState';
import calendarReducer from './setting/calendar/calendarState';

import certificateReducer from './master/certificate/certificateState';
import assetReducer from './master/asset/assetState';

import outputReducer from './file/output/outputState';
import inputReducer from './file/input/inputState';

import passwordReducer from './profile/password/passwordState';
import userSettingReducer from './profile/userSetting/userSettingState';

import reportReducer from './summary/report/reportState';
import projectBalanceReducer from './summary/projectBalance/projectBalanceState';
import divisionBalanceReducer from './summary/divisionBalance/divisionBalanceState';
import companyBalanceReducer from './summary/companyBalance/companyBalanceState';

const rootReducer = {
  loginStore: loginReducer,
  commonStore: commonReducer,
  roleListStore: roleListReducer,
  roleDetailStore: roleDetailReducer,
  workStyleStore: workStyleReducer,
  certificateCampaignStore: certificateCampaignReducer,
  fixedExpenseStore: fixedExpenseReducer,
  noticeStore: noticeReducer,
  loginUserStore: loginUserReducer,
  calendarStore: calendarReducer,

  topCloseStore: topCloseReducer,
  topNoticeStore: topNoticeReducer,
  topTodoStore: topTodoReducer,

  certificateStore: certificateReducer,
  assetStore: assetReducer,

  costCloseStore: costCloseReducer,
  salesCloseStore: salesCloseReducer,
  closeEditStore: closeEditReducer,

  approvalHistoryStore: approvalHistoryReducer,
  clientStore: clientReducer,
  clientContactStore: clientContactReducer,
  clientAgreementStore: clientAgreementReducer,
  projectStore: projectReducer,
  estimateStore: estimateReducer,
  estimateDetailStore: estimateDetailReducer,
  orderStore: orderReducer,
  orderDetailStore: orderDetailReducer,
  claimStore: claimReducer,
  claimDetailStore: claimDetailReducer,
  payStore: payReducer,
  payDetailStore: payDetailReducer,
  partnerStore: partnerReducer,
  inspectStore: inspectReducer,
  inspectDetailStore: inspectDetailReducer,
  skillStore: skillReducer,

  salaryListStore: salaryListReducer,
  salaryDetailStore: salaryDetailReducer,
  cacheStore: cacheReducer,
  employeeStore: employeeReducer,
  employeeHistoryStore: employeeHistoryReducer,
  employeeLicenseHistoryStore: employeeLicenseHistoryReducer,
  vacationStore: vacationReducer,

  workDeviationStore: workDeviationReducer,
  workPlanStore: workPlanReducer,
  workResultStore: workResultReducer,
  workResultInputStore: workResultInputReducer,
  workPlanResultStore: workPlanResultReducer,
  workClockStore: workClockReducer,
  workCostListStore: workCostListReducer,
  workSummaryStore: workSummaryReducer,

  workRequestListStore: workRequestListReducer,
  workRequestDetailStore: workRequestDetailReducer,
  overtimeRequestListStore: overtimeRequestListReducer,
  overtimeRequestDetailStore: overtimeRequestDetailReducer,
  trafficRequestListStore: trafficRequestListReducer,
  trafficRequestDetailStore: trafficRequestDetailReducer,
  trafficRequestInputStore: trafficRequestInputReducer,
  assetRequestListStore: assetRequestListReducer,
  assetRequestDetailStore: assetRequestDetailReducer,
  expenseRequestListStore: expenseRequestListReducer,
  expenseRequestDetailStore: expenseRequestDetailReducer,
  workFixRequestStore: workFixRequestReducer,

  passwordStore: passwordReducer,
  userSettingStore: userSettingReducer,

  outputStore: outputReducer,
  inputStore: inputReducer,

  reportStore: reportReducer,
  projectBalanceStore: projectBalanceReducer,
  divisionBalanceStore: divisionBalanceReducer,
  companyBalanceStore: companyBalanceReducer,
};

export default rootReducer;
