import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box, LinearProgress,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import urls from '../../../constants/frontUrls';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import { expenseRequestListActions } from '../../../redux/request/expenseRequest/expenseRequestListState';
import {
  isCheckableCount, isNotDisabled, getCheckableCount,
} from './expense-request-proc';
import Link from '../../../components/atoms/link/Link';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const userInfo = useSelector((state) => state.loginStore.userInfo).user;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelectAllClick = (event) => {
    const { rows } = props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((data) => data.statusCode === '1')
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const headCells = [
    { id: 'offer_mei', label: '名前' },
    { id: 'use_dt', label: '対象日' },
    { id: 'offer_amount', label: '金額' },
    { id: 'status_name', label: '状況' },
  ];

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    loading,
  } = props;

  const onAdd = () => {
    navigate(urls.EXPENSE_REQUEST_DETAIL);
  };

  const handleClose = () => {
    setConfOpen(false);
  };

  const onClickDelete = () => {
    setConfOpen(true);
  };

  const onProcClick = () => {
    setConfOpen(false);
    props.onProcStart();
    dispatch(expenseRequestListActions.deleteData({ ids: selected }));
    setSelected([]);
  };

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              {getCheckableCount(rows, selected, userInfo) >= 1 && (
                <Box mr={2}>
                  <Tooltip title="削除">
                    <Button color="secondary" variant="contained" onClick={(e) => onClickDelete(e, 1)}>削除</Button>
                  </Tooltip>
                </Box>
              )}
            </>
          ) : (
            <>
              <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
              <Tooltip title="新規申請">
                <Button color="primary" variant="outlined" onClick={onAdd}>新規申請</Button>
              </Tooltip>
            </>
          )}
        </Toolbar>
        {loading && (<LinearProgress />)}
        <TableContainer sx={{ maxHeight: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell key="table-header-check" padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0
                      && isCheckableCount(rows) !== selected.length
                    }
                    checked={
                      selected.length > 0
                      && isCheckableCount(rows) === selected.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color="primary"
                    disabled={isCheckableCount(rows) === 0}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      {isNotDisabled(row, userInfo) && (
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Link to={urls.EXPENSE_REQUEST_DETAIL + row.id}>{row.offerMei}</Link>
                    </TableCell>
                    <TableCell>{row.useDt}</TableCell>
                    <TableCell>{row.offerAmount.toLocaleString()}</TableCell>
                    <TableCell>{row.statusName}</TableCell>
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <ConfirmDialog
          isError
          open={confOpen}
          onClose={handleClose}
          title="選択した申請削除"
          content="選択した申請を削除します"
          onClick={onProcClick}
          procName="削除する"
        />
      </Paper>
    </Box>
  );
}
