/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { trafficRequestDetailActions } from './trafficRequestDetailState';
import {
  getDataById, deleteDatas, updateData, uploadFiles, saveData, getDatas,
} from '../../../services/base.service';

function* initData() {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_INIT);
    yield put(trafficRequestDetailActions.initDataSuccess(result));
  } catch (_) {}
}

function* copyData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_COPY, payload);
    yield put(trafficRequestDetailActions.copyDataSuccess(result));
  } catch (_) {}
}

function* copyDataUpdate({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_COPY, payload);
    yield put(trafficRequestDetailActions.copyDataUpdateSuccess(result));
  } catch (_) {}
}

function* createData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST, payload);
    yield put(trafficRequestDetailActions.createDataSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_ID, payload);
    yield put(trafficRequestDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_DETAIL, payload);
    yield put(trafficRequestDetailActions.deleteDataSuccess());
  } catch (_) {}
}

function* saveStatus({ payload }) {
  try {
    yield call(updateData, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_ID, payload);
    yield put(trafficRequestDetailActions.saveStatusSuccess());
  } catch (_) {}
}

function* uploadFile({ payload }) {
  try {
    yield call(uploadFiles, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_FILE_ID, payload);
    yield put(trafficRequestDetailActions.uploadFileSuccess());
  } catch (_) {}
}

function* deleteFile({ payload }) {
  try {
    yield call(deleteDatas, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_FILE, payload);
    yield put(trafficRequestDetailActions.deleteFileSuccess());
  } catch (_) {}
}

function* trafficRequestDetailSaga() {
  yield takeLatest('trafficRequestDetail/initData', initData);
  yield takeLatest('trafficRequestDetail/copyData', copyData);
  yield takeLatest('trafficRequestDetail/copyDataUpdate', copyDataUpdate);
  yield takeLatest('trafficRequestDetail/createData', createData);
  yield takeLatest('trafficRequestDetail/getData', getData);
  yield takeLatest('trafficRequestDetail/deleteData', deleteData);
  yield takeLatest('trafficRequestDetail/saveStatus', saveStatus);
  yield takeLatest('trafficRequestDetail/uploadFile', uploadFile);
  yield takeLatest('trafficRequestDetail/deleteFile', deleteFile);
}

export default trafficRequestDetailSaga;
