import { call, put, takeLatest } from 'redux-saga/effects';
import { workResultActions } from './workResultState';
import { getDatas, saveData } from '../../../services/base.service';
import { REST_API } from '../../../constants/serverUrls';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_RESULT.WORK_RESULT, payload);
    yield put(workResultActions.getDatasSuccess(result));
  } catch (_) {}
}

function* request({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_RESULT.WORK_RESULT_REQUEST, payload);
    yield put(workResultActions.updateRequestSuccess());
  } catch (_) {}
}

function* approval({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_RESULT.WORK_RESULT_APPROVAL, payload);
    yield put(workResultActions.updateApprovalSuccess());
  } catch (_) {}
}

function* workResultSaga() {
  yield takeLatest('workResult/getDatas', getData);
  yield takeLatest('workResult/updateRequest', request);
  yield takeLatest('workResult/updateApproval', approval);
}

export default workResultSaga;
