import React from 'react';
import { connect } from 'react-redux';
import SearchSelectBox from '../../atoms/searchSelectBox/SearchSelectBox';
import { commonActions } from '../../../redux/common/commonState';

class SelectProjectContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.common.contacts ? props.common.contacts : [],
    };
    if (!props.projectId) {
      return;
    }
    this.props.dispatch(
      commonActions.getClientContacts({
        projectId: props.projectId,
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.contacts !== this.props.common.contacts) {
      if (this.props.common.contacts) {
        this.setData(this.props.common.contacts);
      }
    }
    if (prevProps.projectId !== this.props.projectId) {
      if (this.props.projectId) {
        this.getData(this.props.projectId);
      }
    }
  }

  getData = (projectId) => {
    this.props.dispatch(commonActions.getClientContacts({ projectId }));
  };

  setData = (options) => {
    this.setState({ options });
  };

  render() {
    const { options } = this.state;
    const {
      value, error, name, onChange, isDisabled,
    } = this.props;

    return (
      <SearchSelectBox
        error={error}
        name={name}
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={onChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({ common: state.commonStore });

export default connect(mapStateToProps)(SelectProjectContact);
