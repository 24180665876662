import React from 'react';
import { TextField } from '@mui/material';

export default function TimeInput(props) {
  const {
    value, onChange, error, name, disabled,
  } = props;

  const handleChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    });
  };

  const onfocus = () => {
    if (!value) {
      return;
    }
    onChange({
      target: {
        name,
        value: value.replace(':', ''),
      },
    });
  };

  const onblur = () => {
    if (!value) {
      return;
    }
    let tempValue = value;
    if (value.length < 4) {
      tempValue = `0${value}`;
    }
    if (tempValue.length === 4) {
      let reg = 'HH:MM';
      reg = reg.replace('HH', tempValue.substr(0, 2));
      tempValue = reg.replace('MM', tempValue.substr(2, 2));
    }
    if (!tempValue.match('^([01]?[0-9]|2[0-9]):([0-5][0-9])$')) {
      tempValue = 0;
    }
    onChange({
      target: {
        name,
        value: tempValue,
      },
    });
  };

  return (
    <TextField
      inputProps={{ maxLength: 5, pattern: '^([01]?[0-9]|2[0-9]):([0-5][0-9])$' }}
      value={value}
      onChange={handleChange}
      onBlur={onblur}
      onFocus={onfocus}
      name={name}
      error={error}
      autoComplete="off"
      disabled={disabled}
      size="small"
    />
  );
}
