import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button,
  Tabs, Tab,
} from '@mui/material';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../../components/atoms/tabPanel/TabPanel';
import { projectActions } from '../../../../redux/sales/project/projectState';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled, checkIsDisabledFromUrl } from '../../../../utils/authCheck.helper';
import EstimateList from './EstimateList';
import ProjectDialog from '../ProjectDialog';
import OrderList from './OrderList';
import urls from '../../../../constants/frontUrls';
import Link from '../../../../components/atoms/link/Link';

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.projectInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(projectActions.resetFlg());

    this.state = {
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      successMessage,
      isSuccessOpen,
      tabValue: 0,
      isCopy: false,
      form: { projectId: id },
      isDetailOpen: false,
      isPlDisabled: checkIsDisabledFromUrl(props.loginUser.userInfo.user, '/project-balances'),
    };
    if (id) {
      props.dispatch(projectActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectInfo.data !== this.props.projectInfo.data) {
      if (this.props.projectInfo.data) {
        this.setDatas(this.props.projectInfo.data);
      }
    }
  }

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(projectActions.getData({ id: form.projectId }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, isCopy: false });
  };

  onCopy = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, isCopy: true });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  onClickPl = () => {
    const { form } = this.state;
    window.location.href = urls.PROJECT_BALANCE_DETAIL + form.projectId;
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, successMessage, isDisabled, isDetailOpen, isCopy, isPlDisabled,
    } = this.state;

    return (
      <Container maxWidth="lg">
        {!isDisabled && (
          <Box display="flex" my={1}>
            <Box flex="1%" />
            <Box pr={1}>
              <Button variant="contained" size="small" onClick={this.onDetail}>編集</Button>
            </Box>
            <Box pr={1}>
              <Button variant="outlined" size="small" onClick={this.onCopy}>コピー</Button>
            </Box>
            {!isPlDisabled && (
              <Box pr={1}>
                <Button variant="contained" size="small" onClick={this.onClickPl}>PL</Button>
              </Box>
            )}
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="名前" />
              <Box style={{ wordWrap: 'break-word' }}>{form.projectName}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="SBS表示名" />
              <Box style={{ wordWrap: 'break-word' }}>{form.nickName}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="ステータス" />
              <Box>{form.statusName}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="営業担当" />
              <Box>{form.eigyo}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="プロジェクトオーナー" />
              <Box>{form.ownerByName}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="注文" />
            <Tab label="発注" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} isAll>
          <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
            <Box>
              <FormTitle title="プロジェクト名" />
              <Box style={{ wordWrap: 'break-word' }}>{form.projectName}</Box>
            </Box>
            <Box mt={2}>
              <FormTitle title="SBS表示名" />
              <Box style={{ wordWrap: 'break-word' }}>{form.nickName}</Box>
            </Box>
            <Box mt={2}>
              <FormTitle title="取引先" />
              <Link to={urls.CLIENT_DETAIL + form.clientId}>{form.clientNickName}</Link>
            </Box>
            <Box mt={2}>
              <FormTitle title="契約区分" />
              {form.contractKbn === '1' && (<Box>SES</Box>)}
              {form.contractKbn === '2' && (<Box>一括</Box>)}
              {form.contractKbn === '3' && (<Box>保守</Box>)}
              {form.contractKbn === '4' && (<Box>準委任</Box>)}
              {form.contractKbn === '5' && (<Box>瑕疵</Box>)}
            </Box>
            {form.contractKbn === '5' && (
              <Box mt={2}>
                <FormTitle title="ベースプロジェクト" />
                <Box style={{ wordWrap: 'break-word' }}>{form.defectProjectName}</Box>
              </Box>
            )}
            <Box mt={2}>
              <FormTitle title="ステータス" />
              <Box>{form.statusName}</Box>
            </Box>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTitle title="開始日" />
                <Box>{form.stDt}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="終了日" />
                <Box>{form.edDt}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="営業担当" />
                <Box>{form.eigyo}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="プロジェクトオーナー" />
                <Box>{form.ownerByName}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="担当部" />
                <Box>{form.tantoBuName}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="担当課" />
                <Box>{form.mstDivisionName}</Box>
              </Grid>
            </Grid>
          </Paper>
          {(form.status === '2' || form.status === '99') && (
            <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormTitle title="受注日" />
                  <Box>{form.orderDate}</Box>
                </Grid>
                <Grid item xs={6}>
                  <FormTitle title="受注起因" />
                  <Box>{form.orderCauseName}</Box>
                </Grid>
              </Grid>
            </Paper>
          )}
          {form.status === '3' && (
            <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
              <Box>
                <FormTitle title="失注理由" />
                {form.lostCause && form.lostCause.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Box>
            </Paper>
          )}
          <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
            <Box>
              <FormTitle title="備考" />
              {form.biko && form.biko.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
            </Box>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1} isAll>
          <EstimateList projectId={form.projectId} />
        </TabPanel>
        <TabPanel value={tabValue} index={2} isAll>
          <OrderList projectId={form.projectId} />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <ProjectDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          projectId={form.projectId}
          isCopy={isCopy}
          onSaveSuccess={this.onSaveSuccess}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  projectInfo: state.projectStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ProjectDetail));
