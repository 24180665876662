import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Slide, Dialog, TextField, Box, AppBar, Toolbar, IconButton, Typography,
  FormControl, RadioGroup, Radio, FormControlLabel, Grid, Switch, FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { clientActions } from '../../../redux/sales/client/clientState';
import { commonActions } from '../../../redux/common/commonState';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import TextArea from '../../../components/atoms/textArea/TextArea';
import urls from '../../../constants/frontUrls';
import FreeSoloBox from '../../../components/atoms/freeSoloBox/FreeSoloBox';

const tmpMessage = {
  clientName: '',
  clientNickName: '',
  tokuisakiTesuryo: '',
  siresakiTesuryo: '',
  yubinNo: '',
};
const tmpForm = {
  clientId: '',
  clientName: '',
  clientNickName: '',
  tTesuryoKbn: '0',
  tokuisakiTesuryo: '',
  siresakiTesuryo: '',
  sTesuryoKbn: '0',
  remarks: '',
  industry: '',
  yubinNo: '',
  add1: '',
  add2: '',
  hpUrl: '',
  registNumber: '',
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ClientDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const [zipCodeLoading, setZipCodeLoading] = React.useState(false);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, clientId, onSaveSuccess, isCopy,
  } = props;
  const data = useSelector((state) => state.clientStore.data);
  const isCreateSuccess = useSelector((state) => state.clientStore.isCreateSuccess);
  const isUpdateSuccess = useSelector((state) => state.clientStore.isUpdateSuccess);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const saveId = useSelector((state) => state.clientStore.saveId);
  const dbSelect = useSelector((state) => state.clientStore.select);
  const isAddressSuccess = useSelector((state) => state.commonStore.isAddressSuccess);
  const address = useSelector((state) => state.commonStore.address);
  const [select, setSelect] = React.useState([]);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (isAddressSuccess) {
      let yubinNo = '';
      if (!address) {
        yubinNo = '存在しない郵便番号です';
      }
      const tempMessage = {
        ...errorMessage,
        yubinNo,
      };
      const tempForm = {
        ...form,
        add1: address,
      };
      setZipCodeLoading(false);
      setForm(tempForm);
      setErrorMessage(tempMessage);
    }
  }, [isAddressSuccess]);
  React.useEffect(() => {
    if (isCreateSuccess) {
      navigate(urls.CLIENT_DETAIL + saveId);
      if (isCopy) {
        window.location.reload();
      }
    }
  }, [isCreateSuccess]);
  React.useEffect(() => {
    if (dbSelect) {
      setSelect(dbSelect);
    }
  }, [dbSelect]);

  React.useEffect(() => {
    if (isUpdateSuccess) {
      onSaveSuccess();
      dispatch(clientActions.resetFlg());
    }
  }, [isUpdateSuccess]);

  React.useEffect(() => {
    if (isOpen) {
      if (clientId) {
        dispatch(
          clientActions.getData({ id: clientId }),
        );
      }
      setForm({
        ...tmpForm,
        clientId,
      });
      setErrorMessage(tmpMessage);
      dispatch(
        clientActions.getSelect(),
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      const tempData = {
        ...data,
        clientId: isCopy ? null : clientId,
      };
      setForm(tempData);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.tFlg, form.sFlg);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!form.tFlg && !form.sFlg) {
      isError = true;
      tempMessage.tFlg = '得意先または仕入先のチェックは必須です';
    }
    setErrorMessage(tempMessage);

    if (isError) {
      return;
    }

    if (!clientId || isCopy) {
      dispatch(clientActions.insertData(form));
    } else {
      dispatch(clientActions.updateData(form));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  const onChangeZipCode = (event) => {
    const { name, value } = event.target;
    let loading = false;
    if (value.length === 7 || value.length === 8) {
      loading = true;
      dispatch(commonActions.getAddress({
        zipCode: value,
      }));
    }

    const tempForm = {
      ...form,
      [name]: value,
    };
    setZipCodeLoading(loading);
    setForm(tempForm);
  };

  const onToggleChange = (event) => {
    const { name, checked } = event.target;
    const tempForm = {
      ...form,
      [name]: checked,
    };
    let tTempFlg = form.tFlg;
    let sTempFlg = form.sFlg;
    if (name === 'tFlg') {
      tTempFlg = checked;
    }
    if (name === 'sFlg') {
      sTempFlg = checked;
    }
    setForm(tempForm);
    setErrorMessage({
      ...errorMessage,
      tokuisakiTesuryo: Validation.formValidate('tokuisakiTesuryo', form.tokuisakiTesuryo, tTempFlg, sTempFlg),
      siresakiTesuryo: Validation.formValidate('siresakiTesuryo', form.siresakiTesuryo, tTempFlg, sTempFlg),
    });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            取引先編集
          </Typography>
          <LoadingButton loading={isLoading} autoFocus color="inherit" onClick={onClickSave}>
            保存
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Box p={2} style={{ overflowY: 'auto' }} mt={8}>
        <Box>
          {form.clientId && (
            <Box mb={1}>
              <FormTitle title="取引先番号" />
              <Box>{form.clientNo}</Box>
            </Box>
          )}
        </Box>
        <Box>
          <FormTitle title="取引先名" isRequired />
          <TextField value={form.clientName} name="clientName" error={errorMessage.clientName !== ''} inputProps={{ maxLength: 80 }} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.clientName}</FormErrorText>
        </Box>
        <Box mt={1}>
          <FormTitle title="SBS表示名" isRequired />
          <TextField value={form.clientNickName} name="clientNickName" error={errorMessage.clientNickName !== ''} inputProps={{ maxLength: 80 }} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.clientNickName}</FormErrorText>
        </Box>
        <Box mt={1}>
          <FormTitle title="業種" />
          <FreeSoloBox
            name="industry"
            options={select}
            value={form.industry}
            onChange={onChange}
          />
        </Box>
        <Box mt={2}>
          <FormTitle title="郵便番号" />
          <TextField value={form.yubinNo} name="yubinNo" onChange={onChangeZipCode} error={errorMessage.yubinNo !== ''} />
          {zipCodeLoading && (
            <FormHelperText style={{ color: 'black' }}>検索しています..</FormHelperText>
          )}
          <FormErrorText>{errorMessage.yubinNo}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="都道府県・市区町村" />
          <TextField value={form.add1} name="add1" fullWidth onChange={onChange} />
        </Box>
        <Box mt={2}>
          <FormTitle title="丁目番地・その他" />
          <TextField value={form.add2} name="add2" fullWidth onChange={onChange} />
        </Box>
        <Box mt={1}>
          <FormTitle title="HPURL" />
          <TextField value={form.hpUrl} name="hpUrl" inputProps={{ maxLength: 2048 }} fullWidth onChange={onChange} />
        </Box>
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} md={6}>
            <FormTitle title="得意先" />
            <FormControlLabel control={<Switch checked={form.tFlg} name="tFlg" onChange={onToggleChange} />} label="得意先" />
            <FormErrorText>{errorMessage.tFlg}</FormErrorText>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: { xs: 2, sm: 0 } }}
          >
            <FormTitle title="仕入先" />
            <FormControlLabel control={<Switch checked={form.sFlg} name="sFlg" onChange={onToggleChange} />} label="仕入先" />
          </Grid>
        </Grid>

        <Grid container mt={2} spacing={2}>
          <Grid item xs={12} md={6}>
            <FormTitle title="手数料負担(得意先)" />
            <FormControl>
              <RadioGroup
                row
                name="tTesuryoKbn"
                value={form.tTesuryoKbn}
                onChange={onChange}
              >
                <FormControlLabel checked={form.tTesuryoKbn === '0'} value="0" control={<Radio />} label="当方" />
                <FormControlLabel checked={form.tTesuryoKbn === '1'} value="1" control={<Radio />} label="先方" />
              </RadioGroup>
            </FormControl>
            <FormErrorText>{errorMessage.tTesuryoKbn}</FormErrorText>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: { xs: 2, sm: 0 } }}
          >
            <FormTitle title="手数料負担(仕入先)" />
            <FormControl>
              <RadioGroup
                row
                name="sTesuryoKbn"
                value={form.sTesuryoKbn}
                onChange={onChange}
              >
                <FormControlLabel checked={form.sTesuryoKbn === '0'} value="0" control={<Radio />} label="当方" />
                <FormControlLabel checked={form.sTesuryoKbn === '1'} value="1" control={<Radio />} label="先方" />
              </RadioGroup>
            </FormControl>
            <FormErrorText>{errorMessage.sTesuryoKbn}</FormErrorText>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormTitle title="手数料金額(得意先)" />
            <MoneyInput fullWidth value={form.tokuisakiTesuryo} name="tokuisakiTesuryo" error={errorMessage.tokuisakiTesuryo !== ''} onChange={onChange} />
            <FormErrorText>{errorMessage.tokuisakiTesuryo}</FormErrorText>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ marginTop: { xs: 2, sm: 0 } }}
          >
            <FormTitle title="手数料金額(仕入先)" />
            <MoneyInput fullWidth value={form.siresakiTesuryo} name="siresakiTesuryo" error={errorMessage.siresakiTesuryo !== ''} onChange={onChange} />
            <FormErrorText>{errorMessage.siresakiTesuryo}</FormErrorText>
          </Grid>
        </Grid>
        <Box mt={1}>
          <FormTitle title="登録番号" />
          <TextField value={form.registNumber} name="registNumber" inputProps={{ maxLength: 80 }} fullWidth onChange={onChange} />
        </Box>
        <Box mt={1}>
          <FormTitle title="備考" />
          <TextArea
            value={form.remarks}
            name="remarks"
            onChange={onChange}
          />
        </Box>
        {form.clientId && (
          <Box mt={1}>
            <FormTitle title="契約関連格納パス名" />
            <TextField value={form.contractPathName} name="contractPathName" inputProps={{ maxLength: 200 }} fullWidth onChange={onChange} />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
