import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'offerAmount':
      case 'usePurpose':
      case 'expectedEffect':
        return requiredValidation(value);
      case 'useDt':
        return dateValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
