import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, FormControl, Radio, FormControlLabel, RadioGroup, Switch,
} from '@mui/material';
import { format } from 'date-fns';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { cacheActions } from '../../../redux/office/cache/cacheState';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import SelectClient from '../../../components/organisms/selectClient/SelectClient';

class CacheList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      startDate: format(new Date(), 'yyyy/MM/dd'),
      endDate: '',
      unpaid: false,
      searchKbn: '0',
      searchClientId: '',
    };

    if (props.cacheInfo.searchCondition) {
      searchCondition = props.cacheInfo.searchCondition;
    }

    props.dispatch(cacheActions.setSearchCondition(searchCondition));
    props.dispatch(cacheActions.getData(searchCondition));
    this.state = {
      datas: [],
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cacheInfo.datas !== this.props.cacheInfo.datas) {
      if (this.props.cacheInfo.datas) {
        this.setDatas(this.props.cacheInfo.datas);
      }
    }
    if (prevProps.cacheInfo.isDeleteSuccess !== this.props.cacheInfo.isDeleteSuccess) {
      if (this.props.cacheInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess = (successMessage) => {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onChange = (event) => {
    const { value, name } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.setState({ datas: [] });
    this.props.dispatch(cacheActions.setSearchCondition(searchCondition));
    this.props.dispatch(cacheActions.getData(searchCondition));
  };

  onToggleChange = (event) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      unpaid: event.target.checked,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={6} md={3}>
            <FormTitle title="基準開始日" />
            <DatePicker value={searchCondition.startDate} name="startDate" handleChange={this.onChange} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormTitle title="基準終了日" />
            <DatePicker value={searchCondition.endDate} name="endDate" handleChange={this.onChange} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormTitle title="対象" />
            <FormControl>
              <RadioGroup
                row
                onChange={this.onChange}
                name="searchKbn"
                value={searchCondition.searchKbn}
              >
                <FormControlLabel value="0" control={<Radio />} label="入金" />
                <FormControlLabel value="1" control={<Radio />} label="支払" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <FormTitle title="未済" />
            <FormControlLabel control={<Switch checked={searchCondition.unpaid} onChange={this.onToggleChange} />} label="未済" />
          </Grid>
          <Grid item xs={12}>
            <FormTitle title="取引先" />
            <SelectClient
              name="searchClientId"
              value={searchCondition.searchClientId}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>
        <DataTable
          searchKbn={searchCondition.searchKbn}
          rows={datas || []}
          disabled={isDisabled}
          onResearch={this.setSuccess}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  cacheInfo: state.cacheStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(CacheList);
