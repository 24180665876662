/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const employeeLicenseHistorySlice = createSlice({
  name: 'employeeLicenseHistory',
  initialState: {
    data: {},
    searchCondition: null,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getSelect: (state) => {
      state.selects = null;
    },
    getSelectSuccess: (state, action) => {
      state.selects = action.payload;
    },
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    reset: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
  },
});

export const employeeLicenseHistoryActions = employeeLicenseHistorySlice.actions;

export default employeeLicenseHistorySlice.reducer;
