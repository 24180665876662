import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container,
} from '@mui/material';
import { format } from 'date-fns';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { orderActions } from '../../../redux/sales/order/orderState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import OrderDialog from './OrderDialog';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';

class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'order_no', label: '発注番号' },
      { id: 'client_name', label: '発注先' },
      { id: 'project_name', label: 'プロジェクト' },
      { id: 'app_status', label: 'ステータス' },
      { id: 'period', label: '契約期間' },
      { id: 'order_amount', label: '発注金額' },
      { id: 'claim_amount', label: '支払額' },
    ];

    let searchCondition = {
      searchAppStatus: [],
      searchOrderNo: '',
      searchProjectId: '',
      searchKeyword: '',
      searchMonth: format(new Date(), 'yyyy/MM/dd'),
      ball: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.orderInfo.searchCondition) {
      searchCondition = props.orderInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      select: {
        codes: [], appStatus: [], clients: [],
      },
      isDetailOpen: false,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.props.dispatch(orderActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderInfo.datas !== this.props.orderInfo.datas) {
      if (this.props.orderInfo.datas) {
        this.setDatas(this.props.orderInfo.datas);
      }
    }
    if (prevProps.orderInfo.select !== this.props.orderInfo.select) {
      if (this.props.orderInfo.select) {
        this.setSelect(this.props.orderInfo.select);
      }
    }
    if (prevProps.orderInfo.isDeleteSuccess
      !== this.props.orderInfo.isDeleteSuccess) {
      if (this.props.orderInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(orderActions.setSearchCondition(searchCondition));
    this.props.dispatch(orderActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(orderActions.deleteData({ ids }));
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      select,
      isDetailOpen,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="発注番号" />
              <TextField value={searchCondition.searchOrderNo} fullWidth name="searchOrderNo" onChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="ステータス" />
              <SearchMultipleSelectBox
                name="searchAppStatus"
                options={select.appStatus}
                values={searchCondition.searchAppStatus}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="対象月" />
              <MonthPicker value={searchCondition.searchMonth} name="searchMonth" handleChange={this.onChange} />
            </Grid>
          </Grid>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} md={3}>
              <FormTitle title="プロジェクト名／取引先名" />
              <TextField value={searchCondition.searchKeyword} fullWidth name="searchKeyword" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="ボール" />
              <SelectSyain onChange={this.onChange} name="ball" value={searchCondition.ball} targetMonth={searchCondition.searchMonth} />
            </Grid>
          </Grid>
        </SearchBox>
        <DeleteDataTable
          url={urls.ORDER_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          onAddOpen={this.onAdd}
          procName="発注"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <OrderDialog isOpen={isDetailOpen} onClose={this.onClose} orderId={null} />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  orderInfo: state.orderStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(OrderList);
