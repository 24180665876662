import React from 'react';
import { connect } from 'react-redux';
import {
  Box, IconButton, Tooltip,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchSelectBox from '../../atoms/searchSelectBox/SearchSelectBox';
import { commonActions } from '../../../redux/common/commonState';

class SelectSyain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.props.dispatch(
      commonActions.getSyainList({
        targetMonth: props.targetMonth,
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.syains !== this.props.common.syains) {
      if (this.props.common.syains) {
        this.setData(this.props.common.syains);
      }
    }
    if (prevProps.targetMonth !== this.props.targetMonth) {
      this.getData(this.props.targetMonth);
    }
  }

  getData = () => {
    this.props.dispatch(
      commonActions.getSyainList({
        targetMonth: this.props.targetMonth,
      }),
    );
  };

  setData = (options) => {
    this.setState({ options });
  };

  onClickMe = () => {
    if (!this.props.loginUser.userInfo) {
      return;
    }
    this.props.onChange({
      target: {
        name: this.props.name,
        value: this.props.loginUser.userInfo.user.id,
      },
    });
  };

  render() {
    const { options } = this.state;
    const {
      value, error, name, onChange, isDisabled, disableClearable,
    } = this.props;

    return (
      <Box display="flex">
        <Box style={{ flex: 1 }}>
          <SearchSelectBox
            error={error}
            name={name}
            isDisabled={isDisabled}
            options={options}
            value={value}
            onChange={onChange}
            disableClearable={disableClearable}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Tooltip title="自分">
            <IconButton size="small" onClick={this.onClickMe}>
              <PersonIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.commonStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(SelectSyain);
