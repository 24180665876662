import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Switch, FormControlLabel,
} from '@mui/material';
import getColumn from './claim-proc';
import DataGridSheet from '../../../components/templates/dataGridSheet/DataGridSheet';
import { claimDetailActions } from '../../../redux/sales/claim/claimDetailState';
import DetailTable from '../../../components/templates/detailTable/DetailTable';

class ClaimDetailInput extends React.Component {
  constructor(props) {
    super(props);
    this.columns = getColumn();
    this.dataGridRef = React.createRef();
    this.state = {
      datas: [],
      checked: false,
    };
    props.dispatch(claimDetailActions.getDatas({ claimId: props.id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claimDetailInfo.datas !== this.props.claimDetailInfo.datas) {
      if (this.props.claimDetailInfo.datas) {
        this.setDatas(this.props.claimDetailInfo.datas);
      }
    }
    if (prevProps.claimDetailInfo.isSaveSuccess
      !== this.props.claimDetailInfo.isSaveSuccess) {
      if (this.props.claimDetailInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setDataGridRef = (ref) => {
    this.dataGridRef = ref;
  };

  onSave = (datas) => {
    this.props.dispatch(claimDetailActions.saveData({
      claimId: this.props.id,
      datas,
    }));
  };

  setSuccess = () => {
    this.props.onSaveSuccess();
    this.props.dispatch(claimDetailActions.getDatas({ claimId: this.props.id }));
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const { datas, checked } = this.state;
    const { isDisabled } = this.props;
    return (
      <Box>
        {!isDisabled && (
          <Box textAlign="right">
            <FormControlLabel control={<Switch checked={checked} onChange={this.handleChange} />} label="Excel入力" />
          </Box>
        )}
        {(!isDisabled && checked) && (
          <DataGridSheet
            data={datas}
            isDisabled={isDisabled}
            onSave={this.onSave}
            dataGridRef={this.dataGridRef}
            setDataGridRef={this.setDataGridRef}
            columns={this.columns}
          />
        )}

        {!checked && (
          <DetailTable datas={datas} isDisabled={isDisabled} onSave={this.onSave} isTax />
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  claimDetailInfo: state.claimDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(ClaimDetailInput);
