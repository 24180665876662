/* eslint-disable no-restricted-globals */
import React from 'react';
import { connect } from 'react-redux';
import {
  Card, Box, FormControl, Select, MenuItem, Button, Divider,
  Grid, IconButton, TextField,
} from '@mui/material';
import {
  Clear as ClearIcon, Add as AddIcon, ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { workResultInputActions } from '../../../redux/work/workResultInput/workResultInputState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TimeInput from '../../../components/atoms/timeInput/TimeInput';
import TextArea from '../../../components/atoms/textArea/TextArea';
import ProjectSelectDialog from './ProjectSelectDialog';
import FixRequestDialog from './FixRequestDialog';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

const tempForm = {
  keitai: '',
  jyotai: '',
  startTm: '',
  endTm: '',
  regularStartTm: '',
  regularEndTm: '',
  workTm: '',
  biko: '',
  isEditable: false,
  costList: [],
};

class WorkResultInput extends React.Component {
  constructor(props) {
    super(props);
    props.dispatch(workResultInputActions.getSelects());
    const { inputTargetDate, syainId } = props;
    props.dispatch(workResultInputActions.getDatas({
      syainId, searchDt: inputTargetDate.formatDate,
    }));
    props.dispatch(workResultInputActions.getProjects({
      syainId, searchDt: inputTargetDate.formatDate,
    }));
    this.state = {
      selects: {
        keitais: [],
        jyotais: [],
      },
      diffTm: 0,
      costTm: 0,
      form: tempForm,
      isOpenProject: false,
      isOpenSnackbar: false,
      isOpenFix: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.inputTargetDate.formatDate !== prevProps.inputTargetDate.formatDate
      || this.props.syainId !== prevProps.syainId) {
      if (this.props.inputTargetDate.formatDate && this.props.syainId) {
        this.initData();
      }
    }
    if (this.props.workResultInputInfo.selects !== prevProps.workResultInputInfo.selects) {
      if (this.props.workResultInputInfo.selects) {
        this.setSelects(this.props.workResultInputInfo.selects);
      }
    }
    if (this.props.workResultInputInfo.data !== prevProps.workResultInputInfo.data) {
      if (this.props.workResultInputInfo.data) {
        this.setData(this.props.workResultInputInfo.data);
      }
    }
    if (this.props.workResultInputInfo.workTm !== prevProps.workResultInputInfo.workTm) {
      if (this.props.workResultInputInfo.workTm) {
        this.setWorkTm(this.props.workResultInputInfo.workTm);
      }
    }
    if (this.props.workResultInputInfo.isSaveSuccess
      !== prevProps.workResultInputInfo.isSaveSuccess) {
      if (this.props.workResultInputInfo.isSaveSuccess) {
        this.saveSuccess();
      }
    }
    if (this.props.workResultInputInfo.costList !== prevProps.workResultInputInfo.costList) {
      if (this.props.workResultInputInfo.costList) {
        this.setCostList(this.props.workResultInputInfo.costList);
      }
    }
    if (this.props.workResultInputInfo.isFixSuccess
      !== prevProps.workResultInputInfo.isFixSuccess) {
      if (this.props.workResultInputInfo.isFixSuccess) {
        this.getData();
      }
    }
  }

  setCostList = (costList) => {
    const { form } = this.state;
    let costTm = 0;
    costList.forEach((item) => {
      costTm += parseInt(item.cost, 10);
    });
    costTm = !isNaN(costTm) ? costTm : 0;
    const workTm = form.workTm ? parseInt(form.workTm, 10) : 0;
    const diffTm = workTm - costTm;
    this.setState({
      costTm,
      diffTm,
      form: {
        ...form,
        costList,
      },
    });
  };

  saveSuccess = () => {
    this.setState({ isOpenSnackbar: true });
    this.props.onResearch();
    this.getData();
  };

  setWorkTm = (workTm) => {
    const { form } = this.state;
    let costTm = 0;
    form.costList.forEach((item) => {
      costTm += parseInt(item.cost, 10);
    });
    costTm = !isNaN(costTm) ? costTm : 0;
    const diffTm = workTm - costTm;
    this.setState({
      diffTm,
      form: {
        ...form,
        workTm,
      },
    });
  };

  setData = (form) => {
    let costTm = 0;
    form.costList.forEach((item) => {
      costTm += parseInt(item.cost, 10);
    });
    costTm = !isNaN(costTm) ? costTm : 0;
    const workTm = form.workTm ? parseInt(form.workTm, 10) : 0;
    const diffTm = workTm - costTm;
    this.setState({
      costTm, diffTm, form,
    });
  };

  setSelects = (selects) => {
    this.setState({ selects });
  };

  initData = () => {
    const { inputTargetDate, syainId } = this.props;
    this.props.dispatch(workResultInputActions.getDatas({
      syainId, searchDt: inputTargetDate.formatDate,
    }));
    this.props.dispatch(workResultInputActions.getProjects({
      syainId, searchDt: inputTargetDate.formatDate,
    }));
    this.setState({ form: tempForm });
  };

  getData = () => {
    const { inputTargetDate, syainId, onResearch } = this.props;
    this.props.dispatch(workResultInputActions.getDatas({
      syainId, searchDt: inputTargetDate.formatDate,
    }));
    onResearch();
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  };

  onAddCost = (_, costs) => {
    const { form } = this.state;
    let costList = form.costList || [];
    costs.forEach((item) => {
      const cost = { projectId: item.projectId, projectName: item.projectName, cost: 0 };
      costList = [...costList, cost];
    });

    this.setState({
      isOpenProject: false,
      form: {
        ...form,
        costList,
      },
    });
  };

  onCostChange = (event, index) => {
    const { form } = this.state;
    const costList = [...form.costList];
    let obj = costList[index];
    obj = {
      ...obj,
      cost: event.target.value,
    };
    let costTm = 0;
    costList.splice(index, 1, obj);
    costList.forEach((item) => {
      const value = item.cost ? item.cost : 0;
      costTm += parseInt(value, 10);
    });
    costTm = !isNaN(costTm) ? costTm : 0;
    const workTm = form.workTm ? parseInt(form.workTm, 10) : 0;
    const diffTm = workTm - costTm;
    this.setState({
      costTm,
      diffTm,
      form: {
        ...form,
        costList,
      },
    });
  };

  onClickDeleteCost = (_, index) => {
    const { form } = this.state;
    const costList = [...form.costList];
    costList.splice(index, 1);
    this.setState({
      form: {
        ...form,
        costList,
      },
    });
  };

  onClickClose = () => {
    this.setState({ isOpenProject: false, isOpenFix: false });
  };

  onOpenFix = () => {
    this.setState({ isOpenFix: true });
  };

  onOpenProject = () => {
    this.setState({ isOpenProject: true });
  };

  onClickRecalc = () => {
    const { form } = this.state;
    const { inputTargetDate, syainId, flex } = this.props;
    if (!form.startTm || form.startTm.length !== 5 || !form.endTm || form.endTm.length !== 5) {
      return;
    }
    const targetForm = {
      ...form,
      syainId,
      flex,
      targetDate: inputTargetDate.formatDate,
    };
    this.props.dispatch(workResultInputActions.recalcWorkTime(targetForm));
  };

  onClickSave = () => {
    const { form } = this.state;
    const { inputTargetDate, syainId, flex } = this.props;
    this.setState({ isOpenSnackbar: false });
    const targetForm = {
      ...form,
      syainId,
      flex,
      targetDate: inputTargetDate.formatDate,
      rested: inputTargetDate.rested,
    };
    this.props.dispatch(workResultInputActions.saveWorkTime(targetForm));
  };

  onClickCopy = () => {
    const { inputTargetDate, syainId } = this.props;
    const param = {
      syainId,
      targetDate: inputTargetDate.formatDate,
    };
    this.props.dispatch(workResultInputActions.copyCosts(param));
  };

  onSnackbarClose = () => {
    this.setState({ isOpenSnackbar: false });
  };

  render() {
    const {
      form, selects, isOpenProject, costTm, diffTm, isOpenSnackbar, isOpenFix,
    } = this.state;
    const {
      inputTargetDate, syainId, flex, isNotEdit, isBp,
    } = this.props;

    if (!inputTargetDate.day) {
      return (
        <Card variant="outlined">
          <Box m={2}>選択してください</Box>
        </Card>
      );
    }

    return (
      <>
        <SuccessSnackbar open={isOpenSnackbar} handleClose={this.onSnackbarClose} message="保存しました" />
        <ProjectSelectDialog
          onAddCost={this.onAddCost}
          syainId={syainId}
          isOpen={isOpenProject}
          onClickClose={this.onClickClose}
          searchDt={inputTargetDate.formatDate}
        />
        <FixRequestDialog
          isOpen={isOpenFix}
          onClickClose={this.onClickClose}
          syainId={syainId}
          jyotai={form.jyotai}
          keitai={form.keitai}
          targetDate={inputTargetDate.formatDate}
        />
        <Card variant="outlined">
          <Box m={1}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{
                flexGrow: 1, display: 'flex', color: inputTargetDate.rested ? 'red' : '', alignItems: 'center',
              }}
              >
                <Box mr={1}>{inputTargetDate.day}</Box>
                <Box>{inputTargetDate.week}</Box>
              </Box>
              <Box>
                {!isNotEdit && (
                  <Button size="small" onClick={this.onClickSave} variant="outlined">保存</Button>
                )}
              </Box>
            </Box>
            <Box my={1}>
              {!flex && (
                <Box>
                  <FormTitle title="形態" />
                  <FormControl fullWidth>
                    <Select
                      labelId="keitai-label"
                      value={form.keitai}
                      name="keitai"
                      onChange={this.onChange}
                    >
                      {selects.keitais.map((item) => (
                        <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Box mt={1}>
                <FormTitle title="状態" />
                <FormControl fullWidth>
                  <Select
                    labelId="jyotai-label"
                    value={form.jyotai}
                    name="jyotai"
                    onChange={this.onChange}
                  >
                    {selects.jyotais.map((item) => (
                      <MenuItem value={item.code} key={item.code}>{item.codeMei}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={1}>
                <FormTitle title="出勤" />
                <Box>
                  <TimeInput name="startTm" value={form.startTm} disabled={!form.editable && !isBp} onChange={this.onChange} />
                </Box>
              </Box>
              <Box mt={1}>
                <FormTitle title="退勤" />
                <Box>
                  <TimeInput name="endTm" value={form.endTm} disabled={!form.editable && !isBp} onChange={this.onChange} />
                </Box>
              </Box>
              {(!form.editable && !isNotEdit && !isBp) && (
                <Box textAlign="right">
                  <Button size="small" color="secondary" variant="outlined" onClick={this.onOpenFix}>修正申告</Button>
                </Box>
              )}
              {form.editable && (
                <Box my={1}>{form.fixComment}</Box>
              )}
              <Box mt={1}>
                <FormTitle title="備考" />
                <TextArea name="biko" value={form.biko} onChange={this.onChange} />
              </Box>
            </Box>
            {!isBp && (
              <Box>
                <Divider />
                <Box display="flex" mt={2} mb={2}>
                  <Box style={{ flex: '1 50%' }}>工数</Box>
                  {!isNotEdit && (
                    <Button variant="outlined" size="small" onClick={this.onClickCopy} startIcon={<ContentCopyIcon />}>前回分コピー</Button>
                  )}
                </Box>
                <Box mt={1} mb={1}>
                  {form.costList.map((item, index) => (
                    <Grid container mt={1} key={item.id}>
                      <Grid item xs={!isNotEdit ? 8 : 9} style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{item.projectName}</div>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField size="small" variant="outlined" value={item.cost} inputProps={{ type: 'number' }} onChange={(event) => this.onCostChange(event, index)} />
                      </Grid>
                      {!isNotEdit && (
                        <Grid item xs={1} style={{ display: 'flex', textAlign: 'center' }}>
                          <IconButton size="small" onClick={(event) => this.onClickDeleteCost(event, index)}>
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Box>
                {!isNotEdit && (
                  <Box m={1} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                    <Button variant="outlined" size="small" startIcon={<AddIcon />} onClick={this.onOpenProject}>追加</Button>
                  </Box>
                )}
                <Divider />
                <Box mt={1} mb={1}>
                  {!isNotEdit && (
                    <Box textAlign="right">
                      <Button size="small" component="label" onClick={this.onClickRecalc} variant="outlined">
                        再計算
                      </Button>
                    </Box>
                  )}
                  <Grid container mt={1}>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                      <Box>
                        作業
                      </Box>
                      <Box>{form.workTm}</Box>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                      <Box>工数</Box>
                      <Box>{costTm}</Box>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                      <Box>残数</Box>
                      <Box sx={{ color: diffTm > 0 ? 'red' : '' }}>{diffTm}</Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  workResultInputInfo: state.workResultInputStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(WorkResultInput);
