import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  Container, Grid, Box, Paper, Button,
  Tabs, Tab, MenuItem, Menu,
} from '@mui/material';
import {
  InsertDriveFile as InsertDriveFileIcon, ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { inspectActions } from '../../../redux/sales/inspect/inspectState';
import { estimateActions } from '../../../redux/sales/estimate/estimateState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import InspectDialog from './InspectDialog';
import Link from '../../../components/atoms/link/Link';
import urls from '../../../constants/frontUrls';
import InspectDetailInput from './InspectDetailInput';
import ApprovalHistory from '../approvalHistory/ApprovalHistory';
import ErrorDialog from '../../../components/templates/errorDialog/ErrorDialog';
import PjAppStatus from '../../../components/templates/pjAppStatus/PjAppStatus';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import CreateInspectPdf from './file/CreateInspectPdf';

class InspectDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.inspectInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(inspectActions.resetFlg());
    if (props.estimateInfo.isCopyToInspectSuccess) {
      isSuccessOpen = true;
      successMessage = 'コピーが完了しました';
    }
    props.dispatch(estimateActions.resetFlg());

    this.state = {
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      isAffairs: props.loginUser.userInfo.user.affairs,
      successMessage,
      isSuccessOpen,
      tabValue: 0,
      isError: false,
      form: { inspectId: id, notEdit: false },
      isDetailOpen: false,
      isConfOpen: false,
      isPdfOpen: false,
      fileType: '1',
      fileAnchorEl: null,
      copyAnchorEl: null,
    };
    if (id) {
      props.dispatch(inspectActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inspectInfo.data !== this.props.inspectInfo.data) {
      if (this.props.inspectInfo.data) {
        this.setDatas(this.props.inspectInfo.data);
      }
    }
    if (prevProps.inspectInfo.isStatusSuccess !== this.props.inspectInfo.isStatusSuccess) {
      if (this.props.inspectInfo.isStatusSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.inspectInfo.isCopyToClaimSuccess
      !== this.props.inspectInfo.isCopyToClaimSuccess) {
      if (this.props.inspectInfo.isCopyToClaimSuccess) {
        this.copySuccess('S');
      }
    }
  }

  copySuccess = (target) => {
    let url = urls.ESTIMATE_DETAIL + this.props.inspectInfo.copyId;
    if (target === 'N') {
      url = urls.INSPECT_DETAIL + this.props.inspectInfo.copyId;
    }
    if (target === 'S') {
      url = urls.CLAIM_DETAIL + this.props.inspectInfo.copyId;
    }
    window.open(url, '_blank');
  };

  setSuccess = () => {
    const { form, approvalStatus } = this.state;
    this.props.dispatch(inspectActions.getData({ id: form.id }));
    let msg = '承認';
    if (approvalStatus === '1') {
      msg = '承認依頼';
    }
    if (approvalStatus === '0') {
      msg = '承認取下';
    }
    this.setState({
      isSuccessOpen: true,
      successMessage: `${msg}が完了しました`,
    });
  };

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(inspectActions.getData({ id: form.id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false, isConfOpen: false, isPdfOpen: false });
  };

  onUpdate = (_, approvalStatus, ball) => {
    const { form } = this.state;
    if (!form.projectOwner) {
      this.setState({ isError: true });
      return;
    }
    this.props.dispatch(inspectActions.updateStatus({ id: form.id, approvalStatus, ball }));
    this.setState({ isSuccessOpen: false, approvalStatus, isConfOpen: false });
  };

  onConfUpdate = () => {
    this.setState({ isConfOpen: true });
  };

  onClickFileOpen = (event) => {
    this.setState({ fileAnchorEl: event.currentTarget });
  };

  onPdfOpen = (_, fileType) => {
    this.setState({ fileType, isPdfOpen: true, fileAnchorEl: null });
  };

  onCloseError = () => {
    this.setState({ isError: false });
  };

  onClickCopyOpen = (event) => {
    this.setState({ copyAnchorEl: event.currentTarget });
  };

  onClickCopyClose = () => {
    this.setState({ copyAnchorEl: null });
  };

  onCopy = (_, target) => {
    const { form } = this.state;
    if (target === '2') {
      this.props.dispatch(inspectActions.copyToClaim({ id: form.id }));
    }
    this.setState({ copyAnchorEl: null });
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, successMessage, isAffairs, fileAnchorEl,
      isDisabled, isDetailOpen, isError, isConfOpen, isPdfOpen, fileType, copyAnchorEl,
    } = this.state;

    return (
      <Container maxWidth="lg">
        {(form.approvalStatus !== '0' && form.approvalStatus !== '4') && (
          <Box mb={2}>
            <PjAppStatus activeStep={form.approvalStatus} isExclusion />
          </Box>
        )}
        {!isDisabled && (
          <Box p={1} textAlign="right">
            {(form.approvalStatus === '0' && !form.notEdit) && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '1', form.projectOwner)} style={{ marginRight: '10px' }}>承認依頼</Button>
            )}
            {(form.approvalStatus === '1' && !form.notEdit) && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '2', form.managerId)} style={{ marginRight: '10px' }}>PJオーナー承認</Button>
            )}
            {(form.approvalStatus === '2' && !form.notEdit && isAffairs) && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '4', form.managerId)} style={{ marginRight: '10px' }}>総務承認</Button>
            )}
            {(form.approvalStatus !== '0' && !form.notEdit) && (
              <Button variant="contained" color="secondary" size="small" onClick={this.onConfUpdate} style={{ marginRight: '10px' }}>取下</Button>
            )}
            {!form.notEdit && (
              <Button variant="contained" size="small" onClick={this.onDetail} style={{ marginRight: '10px' }}>編集</Button>
            )}
            <Button variant="contained" size="small" onClick={this.onClickCopyOpen} startIcon={<ExpandMoreIcon />} style={{ marginRight: '10px' }}>コピーして作成</Button>
            <Menu
              id="basic-menu"
              anchorEl={copyAnchorEl}
              open={copyAnchorEl !== null}
              onClose={this.onClickCopyClose}
            >
              {/* <MenuItem onClick={(e) => this.onCopy(e, '2')}>検収</MenuItem> */}
              <MenuItem onClick={(e) => this.onCopy(e, '2')}>請求</MenuItem>
            </Menu>
            <Button variant="contained" size="small" onClick={this.onClickFileOpen} startIcon={<InsertDriveFileIcon />}>検収書作成</Button>
            <Menu
              id="basic-menu"
              anchorEl={fileAnchorEl}
              open={fileAnchorEl !== null}
              onClose={this.onClickFileEstimateClose}
            >
              <MenuItem onClick={(e) => this.onPdfOpen(e, '1')}>作業報告書 兼 検収書</MenuItem>
              <MenuItem onClick={(e) => this.onPdfOpen(e, '2')}>納品書 兼 検収書</MenuItem>
            </Menu>

          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="検収番号" />
              <Box style={{ wordWrap: 'break-word' }}>{form.inspectNo}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="計上月" />
              <Box>{form.inspectDt ? format(new Date(form.inspectDt), 'yyyy/MM') : ''}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="金額" />
              <Box>{form.kingaku}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="承認" />
              <Box>{form.approvalStatusName}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="状況" />
              <Box>{form.statusName}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="明細" />
            <Tab label="承認履歴" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormTitle title="検収番号" />
                <Box style={{ wordWrap: 'break-word' }}>{form.inspectNo}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="計上月" />
                <Box>{form.inspectDt ? format(new Date(form.inspectDt), 'yyyy/MM') : ''}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="承認" />
                <Box>{form.approvalStatusName}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="状況" />
                <Box>{form.statusName}</Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormTitle title="消費税" />
                <Box>{`${form.zeiKubun}%`}</Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormTitle title="備考" />
                <Box>{form.remarks && form.remarks.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="プロジェクト" />
                <Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="注文" />
                <Link to={urls.ESTIMATE_DETAIL + form.estimateId}>{form.chumonNo}</Link>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTitle title="納品日" />
                <Box>{form.deliveryDate}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="件名" />
                {form.title && form.title.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="送付先担当" />
                <Box>{form.contactName}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="準委任作業開始日" />
                <Box>{form.workStartDate}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="準委任作業終了日" />
                <Box>{form.workEndDate}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="作業報告書用特記事項" />
                <Box>{form.workMemo}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="検収書用備考" />
                {form.externalBiko && form.externalBiko.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <FormTitle title="検収額" />
                <Box>{form.kingaku}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="消費税" />
                <Box>{form.kingakuTax}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="合計検収額" />
                <Box>{form.kingakuGokei}</Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1} isAll>
          <InspectDetailInput
            id={form.id}
            data={form}
            onSaveSuccess={this.onSaveSuccess}
            isDisabled={isDisabled || form.approvalStatus === '4' || form.notEdit}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2} isAll>
          <ApprovalHistory inspectId={form.id} />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <InspectDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          inspectId={form.id}
          onSaveSuccess={this.onSaveSuccess}
        />
        <ErrorDialog
          open={isError}
          onClose={this.onCloseError}
          title="エラー"
          content="プロジェクトオーナーが入力されていません"
        />
        <ConfirmDialog
          isError
          open={isConfOpen}
          onClose={this.onClose}
          title="取下"
          content="取下処理を行います。"
          onClick={(e) => this.onUpdate(e, '0', null)}
          procName="取下"
        />
        <CreateInspectPdf
          fileType={fileType}
          isOpen={isPdfOpen}
          onClose={this.onClose}
          inspectId={form.id}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectInfo: state.inspectStore,
  loginUser: state.loginStore,
  estimateInfo: state.estimateStore,
});

export default withParams(connect(mapStateToProps)(InspectDetail));
