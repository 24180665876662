import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, DialogTitle, DialogContent, DialogActions, Box, TextField,
  Switch, FormControlLabel,
} from '@mui/material';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { workStyleActions } from '../../../redux/setting/workStyle/workStyleState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import TimeInput from '../../../components/atoms/timeInput/TimeInput';
import Validation from './validation';

const tmpForm = {
  id: '',
  beforeSortOrder: '',
  afterSortOrder: '',
  title: '',
  startTime: '',
  breakStTime: '',
  endTime: '',
  operateTime: '',
  enabled: true,
};

export default function SalaryCreateDialog(props) {
  const [form, setForm] = React.useState(tmpForm);
  const [errorMessage, setErrorMessage] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, onSaveSuccess, id, isDisabled,
  } = props;
  const isSaveSuccess = useSelector((state) => state.workStyleStore.isSaveSuccess);
  const data = useSelector((state) => state.workStyleStore.data);

  React.useEffect(() => {
    if (isOpen) {
      setForm(tmpForm);
      setErrorMessage(tmpForm);
      if (id) {
        dispatch(workStyleActions.getData({ id }));
      }
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      dispatch(workStyleActions.resetFlg(form));
      onSaveSuccess('保存が完了しました');
    }
  }, [isSaveSuccess]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.attendanceKind);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    if (!tempMessage.startTime && !tempMessage.endTime) {
      const msg = Validation.compareTimeValidate(form.startTime, form.endTime);
      if (msg) {
        isError = true;
      }
      tempMessage.endTime = msg;
    }
    if (!tempMessage.startTime && !tempMessage.breakStTime) {
      const msg = Validation.compareTimeValidate(form.startTime, form.breakStTime);
      if (msg) {
        isError = true;
      }
      tempMessage.breakStTime = msg;
    }
    setErrorMessage(tempMessage);
    if (isError) {
      return;
    }
    if (!form.beforeSortOrder) {
      form.beforeSortOrder = '0';
    }
    dispatch(workStyleActions.saveData(form));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  const onToggleChange = (event) => {
    const { checked, name } = event.target;
    const tempForm = {
      ...form,
      [name]: checked,
    };
    setForm(tempForm);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>勤務形態詳細</DialogTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="表示名" isRequired />
          <TextField value={form.title} name="title" error={errorMessage.title !== ''} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.title}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="出勤" isRequired />
          <TimeInput name="startTime" value={form.startTime} error={errorMessage.startTime !== ''} onChange={onChange} />
          <FormErrorText>{errorMessage.startTime}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="休憩開始" isRequired />
          <TimeInput name="breakStTime" value={form.breakStTime} error={errorMessage.breakStTime !== ''} onChange={onChange} />
          <FormErrorText>{errorMessage.breakStTime}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="退勤" isRequired />
          <TimeInput name="endTime" value={form.endTime} error={errorMessage.endTime !== ''} onChange={onChange} />
          <FormErrorText>{errorMessage.endTime}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="稼働時間" isRequired />
          <TextField value={form.operateTime} name="operateTime" error={errorMessage.operateTime !== ''} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.operateTime}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="並び順" isRequired />
          <TextField value={form.afterSortOrder} inputProps={{ type: 'number' }} name="afterSortOrder" error={errorMessage.afterSortOrder !== ''} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.afterSortOrder}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="有効" isRequired />
          <FormControlLabel control={<Switch checked={form.enabled} name="enabled" onChange={onToggleChange} />} label="有効" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave} disabled={isDisabled}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
