import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container, FormControlLabel, Switch,
} from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { partnerActions } from '../../../redux/sales/partner/partnerState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';

class PartnerList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'bp_id', label: 'BPID' },
      { id: 'name', label: '名前' },
      { id: 'rubi', label: 'ふりがな' },
      { id: 'client_name', label: '取引先' },
    ];

    let searchCondition = {
      searchContract: true,
      searchName: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.partnerInfo.searchCondition) {
      searchCondition = props.partnerInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partnerInfo.datas !== this.props.partnerInfo.datas) {
      if (this.props.partnerInfo.datas) {
        this.setDatas(this.props.partnerInfo.datas);
      }
    }
    if (prevProps.partnerInfo.isDeleteSuccess
      !== this.props.partnerInfo.isDeleteSuccess) {
      if (this.props.partnerInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onBlur = () => {
    this.onSearch();
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(partnerActions.setSearchCondition(searchCondition));
    this.props.dispatch(partnerActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(partnerActions.deleteData({ ids }));
  };

  onToggleChange = (event) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchContract: event.target.checked,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Grid container spacing={1} mb={2}>
          <Grid item xs={6} md={3}>
            <FormTitle title="キーワード" />
            <TextField value={searchCondition.searchName} fullWidth name="searchName" onChange={this.onChange} onBlur={this.onBlur} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormTitle title="契約中" />
            <FormControlLabel control={<Switch checked={searchCondition.searchContract} onChange={this.onToggleChange} />} label="契約中" />
          </Grid>
        </Grid>
        <DeleteDataTable
          url={urls.PARTNER_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="パートナー"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerInfo: state.partnerStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(PartnerList);
