import React from 'react';
import { Box } from '@mui/material/';
import DetailDisplayTable from './DetailDisplayTable';
import DetailInputTable from './DetailInputTable';

export default function DetailTable(props) {
  const {
    datas, onSave, isDisabled, isTax,
  } = props;

  return (
    <Box>
      {isDisabled && (
        <DetailDisplayTable datas={datas} isTax={isTax} />
      )}
      {!isDisabled && (
        <DetailInputTable datas={datas} onSave={onSave} isTax={isTax} />
      )}
    </Box>
  );
}
