/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const salesCloseSlice = createSlice({
  name: 'salesClose',
  initialState: {
    data: '',
    isUpdateSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.data = null;
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    checkData: (state) => {
      state.checkData = null;
    },
    checkDataSuccess: (state, action) => {
      state.checkData = action.payload;
    },
    updateData: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
  },
});

export const salesCloseActions = salesCloseSlice.actions;

export default salesCloseSlice.reducer;
