import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Slide, Dialog, Paper, AppBar, Toolbar, IconButton, Box,
  Table, TableBody, TableCell, Checkbox, Tooltip, Button,
  TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import { trafficRequestDetailActions } from '../../../../redux/request/trafficRequest/trafficRequestDetailState';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CopyDialog(props) {
  const [selected, setSelected] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, parentId, onSaveSuccess, targetDate,
  } = props;
  const copyData = useSelector((state) => state.trafficRequestDetailStore.copyData);
  const isCopySuccess = useSelector((state) => state.trafficRequestDetailStore.isCopySuccess);

  React.useEffect(() => {
    if (isOpen) {
      setSelected([]);
      dispatch(
        trafficRequestDetailActions.copyData({ id: parentId }),
      );
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (isCopySuccess) {
      dispatch(trafficRequestDetailActions.clearFlag());
      onSaveSuccess('コピー処理が完了しました');
    }
  }, [isCopySuccess]);
  React.useEffect(() => {
    if (copyData) {
      setDatas(copyData);
    }
  }, [copyData]);

  const onClickSave = () => {
    setConfOpen(false);
    dispatch(
      trafficRequestDetailActions.copyDataUpdate({ parentId, targetDate, ids: selected }),
    );
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = datas.map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const onClickSaveConf = () => {
    setConfOpen(true);
  };

  const onClickConfClose = () => {
    setConfOpen(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            コピー
          </Typography>
        </Toolbar>
      </AppBar>
      <Box p={2} style={{ overflowY: 'auto' }} mt={8}>
        <Paper>
          <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
            {selected.length > 0 && (
              <>
                <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                  <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  {selected.length}
                  {' '}
                  件選択中
                </Typography>
                <Tooltip title="コピーする">
                  <Button color="primary" onClick={onClickSaveConf}>コピーする</Button>
                </Tooltip>
              </>
            )}
          </Toolbar>

          <TableContainer sx={{ maxHeight: 500, minWidth: 400 }}>
            <Table
              size="medium"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && datas.length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && datas.length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={datas.length === 0}
                    />
                  </TableCell>
                  <TableCell>用途</TableCell>
                  <TableCell>乗車</TableCell>
                  <TableCell>降車</TableCell>
                  <TableCell>区分</TableCell>
                  <TableCell>金額</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell>{row.content}</TableCell>
                      <TableCell>{row.rideRoute + row.ridePoint}</TableCell>
                      <TableCell>{row.rideOffRoute + row.rideOffPoint}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.fee.toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
                {
                  datas.length < 1 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={6}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <ConfirmDialog
            open={confOpen}
            onClose={onClickConfClose}
            title="選択したデータのコピー"
            content="選択したデータをコピーします"
            onClick={onClickSave}
            procName="コピーする"
          />
        </Paper>
      </Box>
    </Dialog>
  );
}
