function mergeCells(worksheet) {
  ['B3:C3', 'A5:B5', 'C5:F5', 'G5:H5', 'I5:J5', 'K5:L5', 'D43:E43', 'F43:G43', 'H43:I43', 'J43:K43', 'D44:E44', 'F44:G44', 'H44:I44', 'J44:K44',
    'D45:E45', 'F45:G45', 'H45:I45', 'J45:K45', 'D46:E46', 'F46:G46', 'H46:I46', 'J46:K46', 'D42:E42', 'F42:G42', 'H42:I42', 'J42:K42',
  ].forEach((cell) => {
    worksheet.mergeCells(cell);
  });
}

function hiddenRow(workSheet, dataCount) {
  for (let i = 0; i < dataCount; i += 1) {
    const row = workSheet.getRow(39 - i);
    row.hidden = true;
  }
}

export default function createData(workbook, datas, targetMonth) {
  const worksheet = workbook.getWorksheet('作業報告書');
  worksheet.pageSetup = {
    orientation: 'portrait', paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 99,
  };

  datas.forEach((works) => {
    const syain = works[0];
    const copySheet = workbook.addWorksheet('Sheet');
    copySheet.model = Object.assign(worksheet.model, {
      mergeCells: worksheet.model.merges,
      name: syain.syainMei,
    });
    copySheet.name = syain.syainMei;
    let row = copySheet.getRow(3);
    row.getCell(2).value = targetMonth;
    row = copySheet.getRow(5);
    row.getCell(3).value = syain.buName;
    row.getCell(9).value = syain.syainId;
    row.getCell(13).value = syain.syainMei;
    mergeCells(copySheet);
    const cellNumber = 9;
    works.forEach((work, index) => {
      row = copySheet.getRow(cellNumber + index);
      row.getCell(3).value = work.status;
      row.getCell(13).value = work.biko;
      row.getCell(15).value = work.startTm;
      row.getCell(16).value = work.endTm;
      row.getCell(17).value = work.workTm;
      row.getCell(18).value = work.htgTm;
      row.getCell(19).value = work.sytgTm;
      row.getCell(20).value = work.holidWorkTm;
      row.getCell(21).value = work.htkyjtTm;
      row.getCell(22).value = work.midOverTm;
      row.getCell(23).value = work.lateTm;
      row.getCell(24).value = work.holyFlg;
      row.getCell(25).value = work.workDt;
    });
    hiddenRow(copySheet, 31 - works.length);
  });

  workbook.removeWorksheet(worksheet.id);
}
