/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { workResultInputActions } from './workResultInputState';
import { getDatas, saveData } from '../../../services/base.service';
import { REST_API } from '../../../constants/serverUrls';

function* getSelect() {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_SELECT);
    yield put(workResultInputActions.getSelectsSuccess(result));
  } catch (_) {}
}

function* getProject({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_PROJECT, payload);
    yield put(workResultInputActions.getProjectsSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT, payload);
    yield put(workResultInputActions.getDatasSuccess(result));
  } catch (_) {}
}

function* projectPin({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_PIN, payload);
    const result = yield call(getDatas, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_PROJECT, payload);
    yield put(workResultInputActions.getProjectsSuccess(result));
  } catch (_) {}
}

function* recalcs({ payload }) {
  try {
    const result = yield call(saveData, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_RECALC, payload);
    yield put(workResultInputActions.recalcWorkTimeSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT, payload);
    yield put(workResultInputActions.saveWorkTimeSuccess());
  } catch (_) {}
}

function* copyDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_COPY, payload);
    yield put(workResultInputActions.copyCostsSuccess(result));
  } catch (_) {}
}

function* fixDatas({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_RESULT_INPUT.WORK_RESULT_INPUT_FIX, payload);
    yield put(workResultInputActions.fixWorkTimeSuccess());
  } catch (_) {}
}

function* workResultInputSaga() {
  yield takeLatest('workResultInput/getSelects', getSelect);
  yield takeLatest('workResultInput/getProjects', getProject);
  yield takeLatest('workResultInput/getDatas', getData);
  yield takeLatest('workResultInput/postPin', projectPin);
  yield takeLatest('workResultInput/recalcWorkTime', recalcs);
  yield takeLatest('workResultInput/saveWorkTime', saveDatas);
  yield takeLatest('workResultInput/copyCosts', copyDatas);
  yield takeLatest('workResultInput/fixWorkTime', fixDatas);
}

export default workResultInputSaga;
