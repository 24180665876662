import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Container, Switch, FormGroup, FormControlLabel,
} from '@mui/material';
import { format } from 'date-fns';
import DataTable from './DataTable';
import { workSummaryActions } from '../../../redux/work/workSummary/workSummaryState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';

class WorkSummary extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      targetStartMonth: format(new Date(), 'yyyy/MM/dd'),
      targetEndMonth: format(new Date(), 'yyyy/MM/dd'),
      searchReq: false,
      page: 0,
      records: 50,
      order: '',
      orderBy: '',
    };

    let lastMonth = format(new Date(), 'yyyy/MM/dd');
    if (props.workSummaryInfo.searchCondition) {
      searchCondition = props.workSummaryInfo.searchCondition;
      lastMonth = props.workSummaryInfo.searchCondition.targetEndMonth;
    }

    props.dispatch(workSummaryActions.setSearchCondition(searchCondition));
    props.dispatch(workSummaryActions.getData(searchCondition));
    this.state = {
      data: {},
      searchCondition,
      lastMonth,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workSummaryInfo.data !== this.props.workSummaryInfo.data) {
      if (this.props.workSummaryInfo.data) {
        this.setDatas(this.props.workSummaryInfo.data);
      }
    }
  }

  setDatas(data) {
    this.setState({ data });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onToggleChange = (event) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      searchReq: event.target.checked,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(workSummaryActions.setSearchCondition(searchCondition));
    this.setState({ lastMonth: searchCondition.targetEndMonth });
    this.props.dispatch(workSummaryActions.getData(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      data, lastMonth,
      searchCondition,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={4}>
              <FormTitle title="表示開始" />
              <MonthPicker value={searchCondition.targetStartMonth} name="targetStartMonth" handleChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormTitle title="表示終了" />
              <MonthPicker value={searchCondition.targetEndMonth} name="targetEndMonth" handleChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormTitle title="承認依頼" />
                <FormControlLabel control={<Switch checked={searchCondition.searchReq} onChange={this.onToggleChange} />} label="承認依頼済" />
              </FormGroup>
            </Grid>
          </Grid>
        </SearchBox>

        <DataTable
          rows={data.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={data.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          lastMonth={lastMonth}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  workSummaryInfo: state.workSummaryStore,
});

export default connect(mapStateToProps)(WorkSummary);
