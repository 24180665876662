import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, DialogTitle, DialogContent, DialogActions,
  FormControl, RadioGroup, Radio, FormControlLabel,
} from '@mui/material';
import { format } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import LinearProgress from '@mui/material/LinearProgress';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { payDetailActions } from '../../../../redux/sales/pay/payDetailState';
import MoneyInput from '../../../../components/atoms/moneyInput/MoneyInput';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
import { onRecalc } from './pay-detail-proc';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';

const tmpMessage = {
  shubetuCd: '',
  kingaku: '',
  shohizei: '',
  gokeiKingaku: '',
  zeiKubun: '',
  syainId: '',
};

const tmpForm = {
  id: null,
  shubetuCd: '0',
  syainId: '',
  kingaku: '',
  shohizei: '',
  gokeiKingaku: '',
  zeiKubun: '10',
};

export default function PayDetailDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const [select, setSelect] = React.useState({ codes: [], bps: [] });
  const dispatch = useDispatch();
  const {
    isOpen, onClose, payDetailId, payId, targetMonth,
  } = props;
  const data = useSelector((state) => state.payDetailStore.data);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const dbSelect = useSelector((state) => state.payDetailStore.select);

  React.useEffect(() => {
    if (isOpen) {
      if (payDetailId) {
        dispatch(
          payDetailActions.getDataFromId({ id: payDetailId }),
        );
      }
      setForm({
        ...tmpForm,
        trnPayId: payId,
      });
      setErrorMessage(tmpMessage);
      dispatch(payDetailActions.getSelect({ targetMonth: format(new Date(targetMonth), 'yyyy/MM/dd') }));
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (dbSelect) {
      setSelect(dbSelect);
    }
  }, [dbSelect]);
  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (isError) {
      return;
    }

    dispatch(payDetailActions.saveData(form));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(onRecalc(tempForm));
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  return (
    <MaterialDialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>支払明細</DialogTitle>
      <LinearProgress style={{ display: isLoading ? '' : 'none' }} />
      <DialogContent dividers style={{ display: isLoading ? 'none' : '' }}>
        <Box>
          <FormTitle title="名前" />
          <SearchSelectBox
            name="syainId"
            options={select.bps}
            value={form.syainId}
            onChange={onChange}
            disableClearable
          />
        </Box>
        <Box mt={2}>
          <FormTitle title="種別" isRequired />
          <FormControl>
            <RadioGroup
              row
              value={form.shubetuCd}
              onChange={onChange}
              name="shubetuCd"
            >
              {select.codes.map((code) => (
                <FormControlLabel
                  key={code.id}
                  checked={form.shubetuCd === code.id}
                  value={code.id}
                  control={<Radio />}
                  label={code.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <FormErrorText>{errorMessage.zeiKubun}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="消費税" isRequired />
          <FormControl>
            <RadioGroup
              row
              value={form.zeiKubun}
              onChange={onChange}
              name="zeiKubun"
            >
              <FormControlLabel
                checked={form.zeiKubun === '0'}
                value="0"
                control={<Radio />}
                label="なし"
              />
              <FormControlLabel
                checked={form.zeiKubun === '10'}
                value="10"
                control={<Radio />}
                label="10%"
              />
            </RadioGroup>
          </FormControl>
          <FormErrorText>{errorMessage.zeiKubun}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="金額" isRequired />
          <MoneyInput
            fullWidth
            error={errorMessage.kingaku !== ''}
            value={form.kingaku}
            name="kingaku"
            onChange={onChange}
          />
          <FormErrorText>{errorMessage.kingaku}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="消費税" isRequired />
          <MoneyInput
            fullWidth
            error={errorMessage.shohizei !== ''}
            value={form.shohizei}
            name="shohizei"
            onChange={onChange}
          />
          <FormErrorText>{errorMessage.shohizei}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="合計" isRequired />
          <MoneyInput
            fullWidth
            error={errorMessage.gokeiKingaku !== ''}
            value={form.gokeiKingaku}
            name="gokeiKingaku"
            onChange={onChange}
          />
          <FormErrorText>{errorMessage.gokeiKingaku}</FormErrorText>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} color="inherit" onClick={onClose}>
          キャンセル
        </LoadingButton>
        <LoadingButton loading={isLoading} color="primary" onClick={onClickSave}>
          保存
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
