import React from 'react';
import { connect } from 'react-redux';
import {
  Card, CardContent, Grid, Button, Box, Checkbox, FormControlLabel,
  Paper, Container, Toolbar, Typography, Divider, Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { reportActions } from '../../../redux/summary/report/reportState';
import ReportDetailDialog from './ReportDetailDialog';
import {
  getIndex, getIncludes, getDatas,
} from './report-detail-proc';
import { checkIsDisabled } from '../../../utils/authCheck.helper';

class RoleDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.reportInfo.isSaveSuccess) {
      isSuccessOpen = true;
      successMessage = 'レポートの登録が完了しました';
    }
    props.dispatch(reportActions.clearFlag());

    const isDisabled = checkIsDisabled(props.loginUser.userInfo.user);
    this.state = {
      syains: [],
      selected: [],
      report: {
        id: null,
        name: '',
      },
      isUpdateModalOpen: false,
      isSuccessOpen,
      successMessage,
      isDisabled,
    };

    if (isDisabled) {
      window.location.href = '/reports';
      return;
    }
    props.dispatch(reportActions.getSyain());
    props.dispatch(reportActions.getData({ id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportInfo.syains !== this.props.reportInfo.syains) {
      if (this.props.reportInfo.syains) {
        this.setSyains(this.props.reportInfo.syains);
      }
    }
    if (prevProps.reportInfo.data !== this.props.reportInfo.data) {
      if (this.props.reportInfo.data) {
        this.setData(this.props.reportInfo.data);
      }
    }
    if (prevProps.reportInfo.isUpdateSuccess !== this.props.reportInfo.isUpdateSuccess) {
      if (this.props.reportInfo.isUpdateSuccess) {
        this.setUpdateSuccess();
      }
    }
    if (prevProps.reportInfo.isSaveDetailSuccess !== this.props.reportInfo.isSaveDetailSuccess) {
      if (this.props.reportInfo.isSaveDetailSuccess) {
        this.detailUpdateSuccess();
      }
    }
  }

  setSyains(syains) {
    this.setState({ syains });
  }

  setData(data) {
    this.setState({
      selected: data.targets.map((n) => n.syainId),
      report: data.report,
    });
  }

  onUpdate = () => {
    this.setState({ isUpdateModalOpen: true, isSuccessOpen: false });
  };

  handleClick = (_, syainId) => {
    const { selected } = this.state;
    const selectedIndex = getIndex(syainId, selected);
    let newSelected = [];

    if (selectedIndex === -1) {
      const newSyains = [syainId];
      newSelected = newSelected.concat(selected, newSyains);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  onUpdateTarget = () => {
    this.setState({ isSuccessOpen: false });
    const { selected, report } = this.state;
    this.props.dispatch(reportActions.saveDetailData({ id: report.id, ids: selected }));
  };

  detailUpdateSuccess = () => {
    this.setState({
      isSuccessOpen: true,
      successMessage: '共有者の保存が完了しました',
    });
    this.props.dispatch(reportActions.getData({ id: this.state.report.id }));
  };

  handleDetailClose = () => {
    this.setState({ isUpdateModalOpen: false });
  };

  setUpdateSuccess = () => {
    this.setState({
      isSuccessOpen: true,
      successMessage: 'レポートの変更が完了しました',
      isUpdateModalOpen: false,
    });
    this.props.dispatch(reportActions.getData({ id: this.state.report.id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  isSelected = (syainId) => getIncludes(syainId, this.state.selected);

  handleSelectAllClick = (event) => {
    const { syains } = this.state;
    let selectedData = [];
    if (event.target.checked) {
      const newSelecteds = syains.map((n) => n.syainId);
      selectedData = newSelecteds;
    }
    this.setState({ selected: selectedData });
  };

  onCopy = () => {
    const { syains, selected } = this.state;
    navigator.clipboard.writeText(getDatas(syains, selected));
    this.setState({ successMessage: 'コピーしました', isSuccessOpen: true });
  };

  render() {
    const {
      syains, isUpdateModalOpen, report, selected,
      isSuccessOpen, successMessage, isDisabled,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Card>
              <CardContent>
                <Box>
                  <FormTitle title="カテゴリ" />
                  <Box>{report.category}</Box>
                </Box>
                <Box mt={2}>
                  <FormTitle title="名前" />
                  <Box>{report.name}</Box>
                </Box>
                <Box mt={2}>
                  <FormTitle title="URL" />
                  <Box><Button href={report.reportUrl} target="_blank">{report.reportUrl}</Button></Box>
                </Box>
                <Box mt={2}>
                  <FormTitle title="備考" />
                  <Box>{report.remarks}</Box>
                </Box>
              </CardContent>
              <Box borderTop={1} borderColor="#eeeeee" />
              {!isDisabled && (
                <Box textAlign="right" my={2} mr={2}>
                  <Button color="primary" variant="outlined" onClick={this.onUpdate}>レポートの変更</Button>
                </Box>
              )}
            </Card>
          </Grid>

          <Grid item sm={8} xs={12}>
            <Paper>
              <Toolbar>
                <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                  共有者
                </Typography>
                <Tooltip title="共有者をクリップボードへ">
                  <Button variant="outlined" onClick={this.onCopy} disabled={!navigator.clipboard}>共有者をクリップボードへ</Button>
                </Tooltip>
              </Toolbar>
              <Box style={{ overflowY: 'scroll', maxHeight: '600px' }} ml={2}>
                <Box>
                  <FormControlLabel
                    aria-label="screenName"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                      <Checkbox
                        indeterminate={
                          selected.length > 0 && syains.length !== selected.length
                        }
                        checked={
                          selected.length > 0 && syains.length === selected.length
                        }
                        onChange={this.handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        color="primary"
                        disabled={syains.length === 0 || isDisabled}
                      />
                    )}
                    label="すべて選択"
                  />
                </Box>
                <Divider />
                {syains.map((syain) => {
                  const isSelected = this.isSelected(syain.syainId);
                  return (
                    <Box key={syain.syainId}>
                      <FormControlLabel
                        aria-label="screenName"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox disabled={isDisabled} checked={isSelected} color="primary" onClick={(event) => this.handleClick(event, syain.syainId)} />}
                        label={syain.syainMei}
                      />
                    </Box>
                  );
                })}
              </Box>
              <Box textAlign="right" p={1}>
                {!isDisabled && (
                  <LoadingButton
                    loading={this.props.common.isLoading}
                    onClick={this.onUpdateTarget}
                  >
                    保存
                  </LoadingButton>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />

        <ReportDetailDialog
          open={isUpdateModalOpen}
          handleClose={this.handleDetailClose}
          id={report.id}
          report={report}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  reportInfo: state.reportStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(RoleDetail));
