/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const trafficRequestInputSlice = createSlice({
  name: 'trafficRequestInput',
  initialState: {
    codes: [],
    data: {},
    isSaveSuccess: false,
    saveId: null,
  },
  reducers: {
    getCodes: (state) => {
      state.codes = null;
    },
    getCodesSuccess: (state, action) => {
      state.codes = action.payload;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
  },
});

export const trafficRequestInputActions = trafficRequestInputSlice.actions;

export default trafficRequestInputSlice.reducer;
