/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const topCloseSlice = createSlice({
  name: 'topClose',
  initialState: {
    data: {},
  },
  reducers: {
    getDatas: (state) => {
      state.data = null;
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const topCloseActions = topCloseSlice.actions;

export default topCloseSlice.reducer;
