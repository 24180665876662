import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Box, Popover, DialogTitle,
  DialogActions, DialogContent,
  Card, CardMedia, Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReceiptTable from './ReceiptTable';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';
import Dropzone from '../../../../components/atoms/dropzone/Dropzone';
import { trafficRequestDetailActions } from '../../../../redux/request/trafficRequest/trafficRequestDetailState';

class ReceiptList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuccessOpen: false,
      isLoading: false,
      isUploadOpen: false,
      anchorEl: null,
      previewUrl: null,
      uploadFile: null,
      successMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trafficRequestDetailInfo.isUploadSuccess
      !== this.props.trafficRequestDetailInfo.isUploadSuccess) {
      if (this.props.trafficRequestDetailInfo.isUploadSuccess) {
        this.setSuccess('アップロードが完了しました');
      }
    }
    if (prevProps.trafficRequestDetailInfo.isFileDeleteSuccess
      !== this.props.trafficRequestDetailInfo.isFileDeleteSuccess) {
      if (this.props.trafficRequestDetailInfo.isFileDeleteSuccess) {
        this.setSuccess('定期券の削除が完了しました');
      }
    }
  }

  setSuccess = (successMessage) => {
    this.props.onResearch();
    this.setState({
      isLoading: false, isSuccessOpen: true, successMessage, isUploadOpen: false,
    });
  };

  onUploadOpen = () => {
    this.setState({ isUploadOpen: true });
  };

  onProcStart = () => {
    this.setState({ isLoading: true, isSuccessOpen: false });
  };

  onPreview = (event, url) => {
    this.setState({ previewUrl: url });
    this.setAnchorEl(event.currentTarget);
  };

  onCloseUpload = () => {
    this.setState({ isUploadOpen: false });
  };

  onUploadFile = () => {
    const { uploadFile } = this.state;
    const { header } = this.props;
    this.setState({ isLoading: true });
    this.props.dispatch(trafficRequestDetailActions.uploadFile({ id: header.id, uploadFile }));
  };

  uploadFile = (uploadFile) => {
    this.setState({ uploadFile });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onUploadClose = () => {
    this.setState({ isUploadOpen: false });
  };

  render() {
    const {
      isUploadOpen, isLoading, isSuccessOpen,
      anchorEl, previewUrl,
      successMessage,
    } = this.state;
    const { header, datas } = this.props;
    const previewOpen = Boolean(anchorEl);
    const previewId = previewOpen ? 'simple-popover' : undefined;

    return (
      <div>

        <ReceiptTable
          loading={isLoading}
          rows={datas || []}
          onProcStart={this.onProcStart}
          header={header}
          onAdd={this.onUploadOpen}
          onPreview={this.onPreview}
          previewClose={this.previewClose}
        />

        <Box>
          <MaterialDialog open={isUploadOpen} onClose={this.onCloseUpload}>
            <DialogTitle>定期券アップロード</DialogTitle>
            <DialogContent dividers>
              <Box mb={2}>
                <Alert severity="info">複数枚の画像を一度にアップロードできません</Alert>
              </Box>
              <Dropzone multiple={false} handleChange={this.uploadFile} />
            </DialogContent>
            <DialogActions>
              <LoadingButton loading={isLoading} onClick={this.onUploadClose} color="inherit">キャンセル</LoadingButton>
              <LoadingButton loading={isLoading} onClick={this.onUploadFile}>保存</LoadingButton>
            </DialogActions>
          </MaterialDialog>
        </Box>

        <Popover
          id={previewId}
          open={previewOpen}
          anchorEl={anchorEl}
          onClose={this.previewClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Card onClick={this.previewClose}>
            <CardMedia
              component="img"
              src={previewUrl}
              title="Paella dish"
            />
          </Card>
        </Popover>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  trafficRequestDetailInfo: state.trafficRequestDetailStore,
});

export default connect(mapStateToProps)(ReceiptList);
