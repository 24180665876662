/* eslint-disable prefer-destructuring */
import React from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';

export default function SearchMultipleSelectBox(props) {
  const {
    options,
    error,
    values,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
  } = props;

  const tempValues = [];
  options.forEach((c) => {
    if (values) {
      values.forEach((v) => {
        if (v === c.id) {
          tempValues.push(c);
        }
      });
    }
  });
  return (
    <Autocomplete
      size="small"
      multiple
      options={options}
      value={tempValues}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      onChange={(_, newValue) => {
        onChange({
          target: {
            name,
            value: newValue.map((n) => n.id),
          },
        });
      }}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip
          label={option.name}
          {...getTagProps({ index })}
        />
      ))}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}
