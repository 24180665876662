/* eslint-disable max-len */
import React from 'react';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { connect } from 'react-redux';
import {
  Button, DialogContent, DialogActions, Box,
  FormControl, Select, MenuItem, FormHelperText,
} from '@mui/material';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { saveTargetDate, isRested } from './work-plan-proc';
import { workPlanActions } from '../../../redux/work/workPlan/workPlanState';

class WorkPlanDeleteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repeat: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.initData();
      }
    }
  }

  initData = () => {
    const { targetDateItem, restCalendars } = this.props;
    const targetFormatDate = format(new Date(targetDateItem.formatDate), 'yyyy年MM月dd日(EEEEEE)', { locale: ja });
    const targetFormatMonth = format(new Date(targetDateItem.formatDate), 'yyyy年MM月', { locale: ja });
    const { week } = targetDateItem;
    const rested = isRested(restCalendars, targetDateItem.formatDate);
    this.setState({
      repeat: 0, targetFormatDate, targetFormatMonth, week, rested,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onClickDelete = () => {
    const { repeat, week } = this.state;
    const {
      targetDateItem, syainId, onClickClose, restCalendars,
    } = this.props;
    const targetMonth = format(new Date(targetDateItem.formatDate), 'yyyy/MM', { locale: ja });
    const targetMonths = saveTargetDate({
      month: targetMonth, targetDate: targetDateItem.formatDate, type: repeat, fnsWeek: week, rests: restCalendars,
    });
    this.props.dispatch(
      workPlanActions.deleteWorkPlan({
        syainId,
        targetMonths,
      }),
    );
    onClickClose();
  };

  render() {
    const { onClickClose, isOpen } = this.props;
    const {
      repeat, targetFormatMonth, targetFormatDate, week, rested,
    } = this.state;
    return (
      <Dialog
        onClose={onClickClose}
        isOpen={isOpen}
      >
        <DialogContent dividers>
          <Box mb={2}>{`${targetFormatDate}の予定`}</Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <Select
                value={repeat}
                name="repeat"
                onChange={this.onChange}
              >
                <MenuItem value={0}>繰り返さない</MenuItem>
                {!rested && (<MenuItem value={1}>以降の毎週平日</MenuItem>)}
                <MenuItem value={2}>
                  以降の毎週
                  {week}
                  曜日
                </MenuItem>
              </Select>
              <FormHelperText>
                {targetFormatMonth}
                にのみ適用
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose} color="inherit">キャンセル</Button>
          <Button color="error" onClick={this.onClickDelete}>削除</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  workPlanInfo: state.workPlanStore,
});

export default connect(mapStateToProps)(WorkPlanDeleteDialog);
