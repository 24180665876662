export function getApprovalCount(datas, selected, userInfo) {
  if (!userInfo || !userInfo.approval) {
    return 0;
  }
  const targets = [];
  selected.forEach((c) => {
    datas.forEach((v) => {
      if (c === v.id) {
        targets.push(v);
      }
    });
  });
  return targets.filter((v) => v.applyStatus === '0').length;
}

export function isCheckableCount(datas, userInfo) {
  if (!userInfo || !userInfo.approval) {
    return 0;
  }
  return datas.filter((v) => v.applyStatus === '0').length;
}

export function isNotDisabled(data, userInfo) {
  if (!userInfo || !userInfo.approval) {
    return false;
  }
  return data.applyStatus === '0';
}
