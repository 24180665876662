import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import DeleteConfirmDialog from '../../../../components/templates/deleteConfirmDialog/DeleteConfirmDialog';

export default function DeleteDataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [deleteConfOpen, setDeleteConfOpen] = React.useState(false);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const {
    rows,
    disabled,
    onAdd,
    onDetail,
  } = props;

  const handleHeaderClose = () => {
    setSelected([]);
  };

  React.useEffect(() => {
    handleHeaderClose();
  }, [rows]);

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const onPreDelete = () => {
    setDeleteConfOpen(true);
  };

  const onDelete = () => {
    props.onDelete(selected);
    setDeleteConfOpen(false);
    setSelected([]);
  };

  const handleClose = () => {
    setDeleteConfOpen(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const addProc = (
    <>
      <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
      {!disabled && (
        <Tooltip title="支払の追加">
          <Button color="primary" variant="outlined" onClick={onAdd} disabled={disabled}>支払の追加</Button>
        </Tooltip>
      )}
    </>
  );

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Tooltip title="支払の削除">
                <Button color="secondary" variant="outlined" onClick={onPreDelete} disabled={disabled}>支払の削除</Button>
              </Tooltip>
            </>
          ) : addProc}
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500, minWidth: 400 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!disabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.length === 0}
                    />
                  </TableCell>
                )}
                <TableCell>名前</TableCell>
                <TableCell>種別</TableCell>
                <TableCell>金額</TableCell>
                <TableCell>消費税</TableCell>
                <TableCell>合計金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!disabled && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                          disabled={disabled}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Button onClick={(e) => onDetail(e, row.id)}>{row.syainId}</Button>
                    </TableCell>
                    <TableCell>{row.shubetuCd}</TableCell>
                    <TableCell>{row.kingaku.toLocaleString()}</TableCell>
                    <TableCell>{row.shohizei.toLocaleString()}</TableCell>
                    <TableCell>{row.gokeiKingaku.toLocaleString()}</TableCell>
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={6}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <DeleteConfirmDialog
          open={deleteConfOpen}
          onClose={handleClose}
          onDelete={onDelete}
          procName="支払"
          loading={isLoading}
        />
      </Paper>
    </div>
  );
}
