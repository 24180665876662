import { call, put, takeLatest } from 'redux-saga/effects';
import { workPlanResultActions } from './workPlanResultState';
import { getDatas } from '../../../services/base.service';
import { REST_API } from '../../../constants/serverUrls';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_PLAN_RESULT, payload);
    yield put(workPlanResultActions.getDatasSuccess(result));
  } catch (_) {}
}

function* workPlanResultSaga() {
  yield takeLatest('workPlanResult/getDatas', getData);
}

export default workPlanResultSaga;
