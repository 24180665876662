import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Slide, Dialog, TextField, Box, AppBar, Toolbar, IconButton, Typography, Grid,
  FormControl, RadioGroup, Radio, FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { claimActions } from '../../../redux/sales/claim/claimState';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import urls from '../../../constants/frontUrls';
import { commonActions } from '../../../redux/common/commonState';
import TextArea from '../../../components/atoms/textArea/TextArea';
import { estimateActions } from '../../../redux/sales/estimate/estimateState';
import SelectProjectContact from '../../../components/organisms/selectProjectContact/SelectProjectContact';

const tmpMessage = {
  seikyuNo: '',
  seikyuDt: '',
  nyukinDt: '',
  status: '',
  claimDate: '',
  workMemo: '',
  workEndDate: '',
  workStartDate: '',
};

const tmpForm = {
  id: null,
  seikyuNo: '',
  seikyuDt: '',
  nyukinDt: '',
  status: '',
  claimDate: '',
  title: '',
  workMemo: '',
  workEndDate: '',
  workStartDate: '',
  externalBiko: '振込手数料は貴社負担でお願い申し上げます。',
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ClaimDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const [selects, setSelect] = React.useState([]);
  const {
    isOpen, onClose, claimId, parentId, onSaveSuccess,
  } = props;
  const data = useSelector((state) => state.claimStore.data);
  const isCreateSuccess = useSelector((state) => state.claimStore.isCreateSuccess);
  const isUpdateSuccess = useSelector((state) => state.claimStore.isUpdateSuccess);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const saveId = useSelector((state) => state.claimStore.saveId);
  const statuses = useSelector((state) => state.commonStore.statuses);
  const estimateData = useSelector((state) => state.estimateStore.data);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (statuses && statuses.length >= 0) {
      setSelect(statuses);
    }
  }, [statuses]);
  React.useEffect(() => {
    if (isCreateSuccess) {
      navigate(urls.CLAIM_DETAIL + saveId);
    }
  }, [isCreateSuccess]);
  React.useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(claimActions.resetFlg());
      onSaveSuccess();
    }
  }, [isUpdateSuccess]);
  React.useEffect(() => {
    if (isOpen) {
      if (claimId) {
        dispatch(
          claimActions.getData({ id: claimId }),
        );
      } else {
        dispatch(
          estimateActions.getData({ id: parentId }),
        );
      }
      setForm({
        ...tmpForm,
        estimateId: parentId,
      });
      setErrorMessage(tmpMessage);
      dispatch(
        commonActions.getStatuses({ code: 'CLAIM_STATE' }),
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);
  React.useEffect(() => {
    if (estimateData) {
      setForm({
        ...form,
        title: estimateData.title,
        seikyuNo: estimateData.chumonNo.replace('M', 'S'),
      });
    }
  }, [estimateData]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (isError) {
      return;
    }

    if (!claimId) {
      dispatch(claimActions.insertData(form));
    } else {
      dispatch(claimActions.updateData(form));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            請求編集
          </Typography>
          <LoadingButton loading={isLoading} autoFocus color="inherit" onClick={onClickSave}>
            保存
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Box p={2} style={{ overflowY: 'auto' }} mt={8}>
        <Box>
          <FormTitle title="請求番号" isRequired />
          <TextField variant="outlined" error={errorMessage.seikyuNo !== ''} fullWidth value={form.seikyuNo} inputProps={{ maxLength: 30 }} name="seikyuNo" onChange={onChange} />
          <FormErrorText>{errorMessage.seikyuNo}</FormErrorText>
        </Box>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="計上月" isRequired />
            <MonthPicker
              handleChange={onChange}
              error={errorMessage.seikyuDt !== ''}
              value={form.seikyuDt}
              name="seikyuDt"
            />
            <FormErrorText>{errorMessage.seikyuDt}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="入金予定" isRequired />
            <DatePicker
              handleChange={onChange}
              value={form.nyukinDt}
              error={errorMessage.nyukinDt !== ''}
              name="nyukinDt"
            />
            <FormErrorText>{errorMessage.nyukinDt}</FormErrorText>
          </Grid>
        </Grid>
        <Box mt={2}>
          <FormTitle title="状況" isRequired />
          <FormControl>
            <RadioGroup
              row
              value={form.status}
              onChange={onChange}
              name="status"
            >
              {selects.map((item) => {
                if (item.id === '3') {
                  return null;
                }
                return (
                  <FormControlLabel
                    key={item.id}
                    checked={form.status === item.id}
                    value={item.id}
                    control={<Radio />}
                    label={item.name}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <FormErrorText>{errorMessage.status}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="請求日" />
          <DatePicker
            handleChange={onChange}
            value={form.claimDate}
            name="claimDate"
            error={errorMessage.claimDate !== ''}
          />
          <FormErrorText>{errorMessage.claimDate}</FormErrorText>
        </Box>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="件名" />
            <TextArea name="title" minRows={1} value={form.title} onChange={onChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="送付先担当" />
            <SelectProjectContact
              name="contactId"
              projectId={form.projectId}
              value={form.contactId}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="準委任作業開始日" />
            <DatePicker
              handleChange={onChange}
              value={form.workStartDate}
              name="workStartDate"
              error={errorMessage.workStartDate !== ''}
            />
            <FormErrorText>{errorMessage.workStartDate}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="準委任作業終了日" />
            <DatePicker
              handleChange={onChange}
              value={form.workEndDate}
              name="workEndDate"
              error={errorMessage.workEndDate !== ''}
            />
            <FormErrorText>{errorMessage.workEndDate}</FormErrorText>
          </Grid>
          <Grid item xs={12}>
            <FormTitle title="作業報告書用特記事項" />
            <TextArea name="workMemo" minRows={1} value={form.workMemo} onChange={onChange} />
          </Grid>
          <Grid item xs={12}>
            <FormTitle title="請求書用備考" />
            <TextArea name="externalBiko" value={form.externalBiko} onChange={onChange} />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
