/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const inputSlice = createSlice({
  name: 'input',
  initialState: {
    workSheets: [],
  },
  reducers: {
    updateExpense: (state) => {
      state.isSuccess = false;
    },
    updateSuccess: (state, action) => {
      state.isSuccess = true;
      state.uploadCount = action.payload;
    },
    updateEmployee: (state) => {
      state.isSuccess = false;
    },
  },
});

export const inputActions = inputSlice.actions;

export default inputSlice.reducer;
