import React from 'react';
import { Container } from '@mui/material';

export default function TabPanel(props) {
  const {
    children, value, index, isAll,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {value === index && (
        <Container maxWidth={isAll ? false : 'lg'} style={{ padding: '16px', marginBottom: isAll ? '0px' : '80px' }}>
          {children}
        </Container>
      )}
    </div>
  );
}
