/* eslint-disable class-methods-use-this */
import axios from 'axios';
import UrlHelper from './url.helper';
import { commonActions } from '../redux/common/commonState';
import rootStore from '../redux/rootStore';

export default class HttpConnection {
  constructor(isNotLoading) {
    rootStore.dispatch(commonActions.executeLoading(false));
    if (!isNotLoading) {
      rootStore.dispatch(commonActions.executeLoading(true));
    }
    rootStore.dispatch(commonActions.resetServerError());
    this.header = this.headerOptions();
    axios.defaults.withCredentials = true;
  }

  headerOptions() {
    const TokenInCookie = `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1')}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `${TokenInCookie}`,
    };
    return headers;
  }

  uploadHeaderOptions() {
    const TokenInCookie = `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*=\s*([^;]*).*$)|^.*$/, '$1')}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `${TokenInCookie}`,
    };
    return headers;
  }

  handleSuccessResponse(response) {
    rootStore.dispatch(commonActions.executeLoading(false));
    if (response.status === 200) {
      return response.data;
    }
    rootStore.dispatch(commonActions.serverError({
      errorMessages: response.errors,
    }));
    throw new Error();
  }

  handleErrorResponse(error) {
    rootStore.dispatch(commonActions.executeLoading(false));
    const { response } = error;
    if (response.status && response.status !== 400 && response.status !== 401) {
      window.location.href = '/error';
      return;
    }
    rootStore.dispatch(commonActions.serverError({
      errorMessages: error.response.data.errorDetails,
    }));
    throw error;
  }

  /**
   * Http Get
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async get(url, parameter) {
    const getUrl = encodeURI(UrlHelper.convertQueryUrl(url, parameter));
    const requestOptions = {
      method: 'GET',
      headers: this.header,
      url: getUrl,
      data: null,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Get
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async getById(url, parameter) {
    const getUrl = UrlHelper.convertPathUrl(url, parameter);
    const requestOptions = {
      method: 'GET',
      headers: this.header,
      url: getUrl,
      data: null,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Post
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async post(url, parameter) {
    const requestOptions = {
      method: 'POST',
      headers: this.header,
      data: parameter,
      url,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Put
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async put(url, queryParameter, parameter) {
    const putUrl = UrlHelper.convertPathUrl(url, queryParameter);
    const requestOptions = {
      method: 'PUT',
      headers: this.header,
      data: parameter,
      url: putUrl,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Delete
   *
   * @param {string} url
   * @param queryParameter
   * @returns {Promise<any>}
   */
  async delete(url, queryParameter) {
    const deleteUrl = UrlHelper.convertPathUrl(url, queryParameter);
    const requestOptions = {
      method: 'DELETE',
      headers: this.header,
      data: null,
      url: deleteUrl,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Deletes
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async deletes(url, parameter) {
    const requestOptions = {
      method: 'DELETE',
      headers: this.header,
      url,
      data: parameter,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }

  /**
   * Http Post(create)
   *
   * @param {string} url
   * @param parameter
   * @returns {Promise<any>}
   */
  async upload(url, queryParameter, parameter) {
    const putUrl = UrlHelper.convertPathUrl(url, queryParameter);
    const requestOptions = {
      method: 'PUT',
      headers: this.uploadHeaderOptions(),
      data: parameter,
      url: putUrl,
    };
    const result = await axios(requestOptions)
      .then(this.handleSuccessResponse)
      .catch((error) => {
        this.handleErrorResponse(error);
      });
    return result;
  }
}
