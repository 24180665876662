/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { vacationActions } from './vacationState';
import { getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.VACATION.VACATION, payload);
    yield put(vacationActions.getDataSuccess(result));
  } catch (_) {}
}

function* getDetailData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.VACATION.VACATION_DETAIL, payload);
    yield put(vacationActions.getDetailDataSuccess(result));
  } catch (_) {}
}

function* vacationSaga() {
  yield takeLatest('vacation/getData', getData);
  yield takeLatest('vacation/getDetailData', getDetailData);
}

export default vacationSaga;
