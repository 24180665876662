/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Table, TableContainer, IconButton, Radio,
  TableHead, TableRow, TableBody, Tooltip, FormControl, RadioGroup, FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { addMonths, format } from 'date-fns';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from '@mui/icons-material';
import { companyBalanceActions } from '../../../redux/summary/companyBalance/companyBalanceState';
import {
  getMonths, getTargetYear, getData, getMonthSummary, getDivisionSummary, getSummary, getDiffData, getBetDiffSummary, getDiffArariSummary, getBumonArariRitsuAll,
  getDiffMonthSummary, getDiffDivisionSummary, getDiffSummary, getMonthBetSummary, getBetSummary, getBetDiffMonthSummary, getDiffArariMonthSummary, getCache,
  getArariData, getArariDivisionSummary, getArariMonthSummary, getArariSummary, getCacheAll, getBetAllSummary, getBetDiffAllSummary, getShisyutsuReulstMonthSummary,
  getBumonMonthPlanSummary, getBumonPlanSummary, getBetAllMonthSummary, getBetDiffAllMonthSummary, getShisyutsuMonthSummary, getShisyutsuSummary, getShisyutsuDiffSummary,
  getBumonMonthSummary, getBumonSummary, getBumonDiffSummary, getBumonMonthDiffSummary, getBumonArariRitsu, getShisyutsuReulstSummary, getShisyutsuMonthDiffSummary,
} from './company-balance-proc';

const HeaderTd = styled('th')(() => ({
  border: 'solid 1px #808080',
  fontSize: '13px',
}));

const DataTr = styled(TableRow)(({ index }) => ({
  ...(index % 2 === 0 && {
    background: '#f5f5f5',
  }),
}));

const TitleTd = styled('td')(() => ({
  border: 'solid 1px #808080',
  fontSize: '15px',
  paddingRight: 3,
  textAlign: 'left',
}));

const DataTd = styled('td')(() => ({
  border: 'solid 1px #808080',
  fontSize: '15px',
  textAlign: 'right',
  paddingRight: 3,
}));

class CompanyBalance extends React.Component {
  constructor(props) {
    super(props);
    const condition = getTargetYear();
    let searchCondition = {
      startMonth: condition.startMonth,
      endMonth: condition.endMonth,
    };

    if (props.companyBalanceInfo.searchCondition) {
      searchCondition = props.companyBalanceInfo.searchCondition;
    }
    this.state = {
      searchCondition,
      months: getMonths(searchCondition.startMonth, searchCondition.endMonth),
      balances: [],
      departments: [],
      budgets: [],
      indirects: [],
      indirectBumons: [],
      allBudgets: [],
      expenses: [],
      displayType: '0',
    };
    this.search(searchCondition);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyBalanceInfo.data !== this.props.companyBalanceInfo.data) {
      if (this.props.companyBalanceInfo.data) {
        this.setData(this.props.companyBalanceInfo.data);
      }
    }
  }

  setData = (data) => {
    this.setState({
      balances: data.balances,
      departments: data.departments,
      budgets: data.budgets,
      indirects: data.indirects,
      expenses: data.expenses,
      indirectBumons: data.indirectBumons,
      allBudgets: data.allBudgets,
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(companyBalanceActions.getData(searchCondition));
  };

  onClickBefore = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.startMonth), -6), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.startMonth), -1), 'yyyy/MM/dd'),
    };
    this.setState({
      searchCondition: tmpCondition,
      months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth),
    });
    this.search(tmpCondition);
  };

  onClickAfter = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.endMonth), 1), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.endMonth), 6), 'yyyy/MM/dd'),
    };
    this.setState({
      searchCondition: tmpCondition,
      months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth),
    });
    this.search(tmpCondition);
  };

  onRadioChange = (event) => {
    const { value } = event.target;
    this.setState({ displayType: value });
  };

  render() {
    const {
      searchCondition, months, balances, departments, budgets, displayType, indirects, indirectBumons, expenses, allBudgets,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Box display="flex" mb={2}>
          <Box display="flex" alignItems="center">
            <Tooltip title="前へ">
              <IconButton color="primary" size="small" onClick={this.onClickBefore}>
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">{format(new Date(searchCondition.startMonth), 'yyyy年MM月')}</Box>
          <Box display="flex" alignItems="center" mx={1}>～</Box>
          <Box display="flex" alignItems="center">{format(new Date(searchCondition.endMonth), 'yyyy年MM月')}</Box>
          <Box>
            <Tooltip title="次へ">
              <IconButton color="primary" size="small" onClick={this.onClickAfter}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box mt={1}>
          <FormControl>
            <RadioGroup
              row
              onChange={this.onRadioChange}
              name="displayType"
              value={displayType}
            >
              <FormControlLabel value="0" control={<Radio size="small" />} label="千円" />
              <FormControlLabel value="1" control={<Radio size="small" />} label="実数" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mb={3}>
          <TableContainer sx={{ maxHeight: 800 }}>
            <Table
              width="100%"
              stickyHeader
              sx={{
                border: 'solid 1px #DDD',
                borderCollapse: 'collapse',
                textAlign: 'center',
              }}
            >
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  background: '#fff',
                }}
              >
                <TableRow>
                  <HeaderTd colSpan={4} />
                  {months.map((item) => (
                    <HeaderTd key={`${item.targetDate}s`}>{item.displayDate}</HeaderTd>
                  ))}
                  <HeaderTd>合計</HeaderTd>
                </TableRow>
              </TableHead>
              <TableBody>
                <React.Fragment id="uriage">
                  <TableRow>
                    <TitleTd
                      rowSpan={departments.length + 4}
                      style={{
                        width: '20px', padding: 3, fontWeight: 'bold', background: '#FCE4D6',
                      }}
                    >
                      売上
                    </TitleTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ background: '#FCE4D6', fontWeight: 'bold' }}>予算</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}Sum`}>
                        {getMonthSummary(budgets, item.targetDate, 'budgetProceed', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(budgets, 'budgetProceed', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}uriage`}>
                      {index === 0 && (
                        <TitleTd
                          rowSpan={departments.length + 1}
                          colSpan={2}
                          style={{
                            width: '20px', padding: 2, background: '#FCE4D6', fontWeight: 'bold',
                          }}
                        >
                          実績
                        </TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}uriage${item.targetDate}`}>
                          {getData(balances, department.sykmCd, item.targetDate, 'uriage', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, department.sykmCd, 'uriage', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  <TableRow hover>
                    <TitleTd style={{ background: '#FCE4D6', fontWeight: 'bold' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}Sum`} style={{ background: '#FCE4D6', fontWeight: 'bold' }}>
                        {getMonthSummary(balances, item.targetDate, 'uriage', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ background: '#FCE4D6', fontWeight: 'bold' }}>{getSummary(balances, 'uriage', displayType)}</DataTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ fontWeight: 'bold', background: '#FCE4D6' }}>差異</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                        {getDiffMonthSummary(balances, budgets, item.targetDate, 'uriage', 'budgetProceed', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getDiffSummary(balances, budgets, 'uriage', 'budgetProceed', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <React.Fragment id="genka">
                  <TableRow>
                    <TitleTd
                      rowSpan={departments.length + 17}
                      style={{
                        width: '20px', padding: 3, fontWeight: 'bold', background: '#D9E1F2',
                      }}
                    >
                      原価
                    </TitleTd>
                  </TableRow>
                  <TitleTd
                    rowSpan={4}
                    colSpan={2}
                    style={{
                      width: '20px', padding: 2, fontWeight: 'bold', background: '#D9E1F2',
                    }}
                  >
                    予算
                  </TitleTd>
                  <TableRow hover>
                    <TitleTd>プロパ</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProperCost${item.targetDate}Sum`}>
                        {getMonthSummary(budgets, item.targetDate, 'budgetProperCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(budgets, 'budgetProperCost', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd>外注</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}Sum`}>
                        {getMonthSummary(budgets, item.targetDate, 'budgetOutsourceCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(budgets, 'budgetOutsourceCost', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd style={{ background: '#D9E1F2', fontWeight: 'bold' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProperCost${item.targetDate}Sum`} style={{ background: '#D9E1F2', fontWeight: 'bold' }}>
                        {getMonthBetSummary(budgets, item.targetDate, 'budgetProperCost', 'budgetOutsourceCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ background: '#D9E1F2', fontWeight: 'bold' }}>{getBetSummary(budgets, 'budgetProperCost', 'budgetOutsourceCost', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}properGenka`}>
                      {index === 0 && (
                        <React.Fragment key={`${department.sykmCd}genkaresultsyain`}>
                          <TitleTd
                            rowSpan={departments.length + 5}
                            style={{
                              background: '#D9E1F2', width: '20px', padding: 2, fontWeight: 'bold',
                            }}
                          >
                            実績
                          </TitleTd>
                          <TitleTd rowSpan={departments.length + 1} style={{ width: '20px', padding: 2 }}>プロパ</TitleTd>
                        </React.Fragment>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}properGenka${item.targetDate}`}>
                          {getData(balances, department.sykmCd, item.targetDate, 'properGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, department.sykmCd, 'properGenka', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  <TableRow hover>
                    <TitleTd>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`properGenka${item.targetDate}Sum`}>
                        {getMonthSummary(balances, item.targetDate, 'properGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(balances, 'properGenka', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}outsourceGenka`}>
                      {index === 0 && (
                        <TitleTd rowSpan={departments.length + 1} style={{ width: '20px', padding: 2 }}>外注</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}outsourceGenka${item.targetDate}`}>
                          {getData(balances, department.sykmCd, item.targetDate, 'outsourceGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, department.sykmCd, 'outsourceGenka', displayType)}</DataTd>
                    </DataTr>
                  ))}

                  <TableRow hover>
                    <TitleTd>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`outsourceGenka${item.targetDate}Sum`}>
                        {getMonthSummary(balances, item.targetDate, 'outsourceGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(balances, 'outsourceGenka', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd colSpan={2} style={{ background: '#D9E1F2', fontWeight: 'bold' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`outsourceGenka${item.targetDate}Sum`} style={{ background: '#D9E1F2', fontWeight: 'bold' }}>
                        {getMonthBetSummary(balances, item.targetDate, 'outsourceGenka', 'properGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ background: '#D9E1F2', fontWeight: 'bold' }}>{getBetSummary(balances, 'outsourceGenka', 'properGenka', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}properGenka`}>
                      {index === 0 && (
                        <React.Fragment key={`${department.sykmCd}genkaresultsyain`}>
                          <TitleTd
                            rowSpan={departments.length + 5}
                            style={{
                              width: '20px', padding: 2, background: '#D9E1F2', fontWeight: 'bold',
                            }}
                          >
                            差異
                          </TitleTd>
                          <TitleTd rowSpan={departments.length + 1} style={{ width: '20px', padding: 2 }}>プロパ</TitleTd>
                        </React.Fragment>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}properGenka${item.targetDate}`}>
                          {getDiffData(balances, budgets, department.sykmCd, item.targetDate, 'properGenka', 'budgetProperCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffDivisionSummary(balances, budgets, department.sykmCd, 'properGenka', 'budgetProperCost', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  <TableRow hover>
                    <TitleTd>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`properGenka${item.targetDate}Sum`}>
                        {getDiffMonthSummary(balances, budgets, item.targetDate, 'properGenka', 'budgetProperCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getDiffSummary(balances, budgets, 'properGenka', 'budgetProperCost', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}outsourceGenka`}>
                      {index === 0 && (
                        <TitleTd rowSpan={departments.length + 1} style={{ width: '20px', padding: 2 }}>外注</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}outsourceGenka${item.targetDate}`}>
                          {getDiffData(balances, budgets, department.sykmCd, item.targetDate, 'outsourceGenka', 'budgetOutsourceCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffDivisionSummary(balances, budgets, department.sykmCd, 'outsourceGenka', 'budgetOutsourceCost', displayType)}</DataTd>
                    </DataTr>
                  ))}

                  <TableRow hover>
                    <TitleTd>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`outsourceGenka${item.targetDate}Sum`}>
                        {getDiffMonthSummary(balances, budgets, item.targetDate, 'outsourceGenka', 'budgetOutsourceCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getDiffSummary(balances, budgets, 'outsourceGenka', 'budgetOutsourceCost', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd colSpan={2} style={{ background: '#D9E1F2', fontWeight: 'bold' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`outsourceGenka${item.targetDate}Sum`} style={{ background: '#D9E1F2', fontWeight: 'bold' }}>
                        {getBetDiffMonthSummary(balances, budgets, item.targetDate, 'outsourceGenka', 'budgetOutsourceCost', 'properGenka', 'budgetProperCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ background: '#D9E1F2', fontWeight: 'bold' }}>{getBetDiffSummary(balances, budgets, 'outsourceGenka', 'budgetOutsourceCost', 'properGenka', 'budgetProperCost', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <TableRow>
                  <TitleTd
                    style={{
                      width: '20px', padding: 3, background: '#FFF2CC', fontWeight: 'bold',
                    }}
                    colSpan={4}
                  >
                    仕掛
                  </TitleTd>
                  {months.map((item) => (
                    <DataTd key={`shikakari${item.targetDate}Sum`} style={{ background: '#FFF2CC' }}>
                      {getMonthSummary(balances, item.targetDate, 'shikakari', displayType)}
                    </DataTd>
                  ))}
                  <DataTd style={{ background: '#FFF2CC' }}>{getSummary(balances, 'shikakari', displayType)}</DataTd>
                </TableRow>

                <React.Fragment id="arari">
                  <TableRow>
                    <TitleTd
                      rowSpan={departments.length + 4}
                      style={{
                        width: '20px', padding: 3, background: '#FFFD9B', fontWeight: 'bold',
                      }}
                    >
                      粗利
                    </TitleTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ background: '#FFFD9B', fontWeight: 'bold' }}>予算</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProperCost${item.targetDate}Sum`}>
                        {getMonthSummary(budgets, item.targetDate, 'arari', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getArariSummary(budgets, 'budgetProceed', 'budgetCost', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}properGenka`}>
                      {index === 0 && (
                        <TitleTd
                          rowSpan={departments.length + 1}
                          colSpan={2}
                          style={{
                            width: '20px', padding: 2, background: '#FFFD9B', fontWeight: 'bold',
                          }}
                        >
                          実績
                        </TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}properGenka${item.targetDate}`}>
                          {getArariData(balances, department.sykmCd, item.targetDate, 'uriage', 'allGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getArariDivisionSummary(balances, department.sykmCd, 'uriage', 'allGenka', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  <TableRow hover>
                    <TitleTd style={{ background: '#FFFD9B', fontWeight: 'bold' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`properGenka${item.targetDate}Sum`} style={{ background: '#FFFD9B', fontWeight: 'bold' }}>
                        {getArariMonthSummary(balances, item.targetDate, 'uriage', 'allGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ background: '#FFFD9B', fontWeight: 'bold' }}>{getArariSummary(balances, 'uriage', 'allGenka', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd style={{ background: '#FFFD9B', fontWeight: 'bold' }} colSpan={3}>差異</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`properGenka${item.targetDate}Sum`}>
                        {getDiffArariMonthSummary(balances, budgets, item.targetDate, 'uriage', 'allGenka', 'budgetProceed', 'budgetCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getDiffArariSummary(balances, budgets, 'uriage', 'allGenka', 'budgetProceed', 'budgetCost', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <TableRow hover>
                  <TitleTd
                    style={{
                      width: '20px', padding: 3, background: '#FFCCFF', fontWeight: 'bold',
                    }}
                    colSpan={4}
                  >
                    粗利率
                  </TitleTd>
                  {months.map((item) => (
                    <DataTd key={`arariRitsu${item.targetDate}Sum`}>
                      {getMonthSummary(balances, item.targetDate, 'arariRitsu', displayType)}
                    </DataTd>
                  ))}
                  <DataTd>{getSummary(balances, 'arariRitsu', displayType)}</DataTd>
                </TableRow>

                <React.Fragment id="zensyakansetu">
                  <TableRow>
                    <TitleTd
                      rowSpan={departments.length + 4}
                      style={{
                        width: '20px', padding: 3, background: '#F2F2F2', fontWeight: 'bold',
                      }}
                    >
                      全社間接費
                    </TitleTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd style={{ background: '#F2F2F2', fontWeight: 'bold' }} colSpan={3}>間接部門</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetIndirect${item.targetDate}Sum`}>
                        {getMonthSummary(indirectBumons, item.targetDate, 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(indirectBumons, 'salary', displayType)}</DataTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd style={{ background: '#F2F2F2', fontWeight: 'bold' }} colSpan={3}>共通費</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetIndirect${item.targetDate}Sum`}>
                        {getMonthSummary(expenses, item.targetDate, 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(expenses, 'salary', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd
                      rowSpan={departments.length + 1}
                      colSpan={2}
                      style={{
                        width: '20px', padding: 2, background: '#F2F2F2', fontWeight: 'bold',
                      }}
                    >
                      間接費計
                    </TitleTd>
                    <TitleTd style={{ background: '#F2F2F2', fontWeight: 'bold' }}>予算</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`indirect${item.targetDate}Sum`}>
                        {getMonthSummary(allBudgets, item.targetDate, 'indirect', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(allBudgets, 'indirect', displayType)}</DataTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd style={{ background: '#F2F2F2', fontWeight: 'bold' }}>実績</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                        {getBetAllMonthSummary(indirectBumons, expenses, item.targetDate, 'salary', 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBetAllSummary(indirectBumons, expenses, 'salary', 'salary', displayType)}</DataTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd style={{ background: '#F2F2F2', fontWeight: 'bold' }}>差異</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                        {getBetDiffAllMonthSummary(indirectBumons, expenses, allBudgets, item.targetDate, 'salary', 'salary', 'indirect', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBetDiffAllSummary(indirectBumons, expenses, allBudgets, 'salary', 'salary', 'indirect', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <React.Fragment id="kansetsu">
                  <TableRow>
                    <TitleTd
                      rowSpan={departments.length + 4}
                      style={{
                        width: '20px', padding: 3, background: '#D6DCE4', fontWeight: 'bold',
                      }}
                    >
                      部門間接
                    </TitleTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd style={{ background: '#D6DCE4', fontWeight: 'bold' }} colSpan={3}>予算</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetIndirect${item.targetDate}Sum`}>
                        {getMonthSummary(budgets, item.targetDate, 'budgetIndirect', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(budgets, 'budgetIndirect', displayType)}</DataTd>
                  </TableRow>

                  {departments.map((department, index) => (
                    <DataTr hover key={`${department.sykmCd}kansetsu`}>
                      {index === 0 && (
                        <TitleTd
                          rowSpan={departments.length + 1}
                          colSpan={2}
                          style={{
                            width: '20px', padding: 2, background: '#D6DCE4', fontWeight: 'bold',
                          }}
                        >
                          実績
                        </TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{department.departmentName}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${department.sykmCd}kansetsu${item.targetDate}`}>
                          {getData(indirects, department.sykmCd, item.targetDate, 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(indirects, department.sykmCd, 'kansetsu', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  <TableRow hover>
                    <TitleTd>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`kansetsu${item.targetDate}Sum`}>
                        {getMonthSummary(indirects, item.targetDate, 'kansetsu', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(indirects, 'kansetsu', displayType)}</DataTd>
                  </TableRow>

                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ background: '#D6DCE4', fontWeight: 'bold' }}>差異</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                        {getDiffMonthSummary(indirects, budgets, item.targetDate, 'kansetsu', 'budgetIndirect', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getDiffSummary(indirects, budgets, 'kansetsu', 'budgetIndirect', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <React.Fragment id="bumonarari">
                  <TableRow>
                    <TitleTd
                      rowSpan={4}
                      style={{
                        width: '20px', padding: 3, background: '#548235', fontWeight: 'bold', color: '#fff',
                      }}
                    >
                      営業利益
                    </TitleTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ background: '#548235', fontWeight: 'bold', color: '#fff' }}>予算</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}Sum`}>
                        {getBumonMonthPlanSummary(budgets, expenses, item.targetDate, 'arari', 'budgetIndirect', 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBumonPlanSummary(budgets, expenses, 'arari', 'budgetIndirect', 'salary', displayType)}</DataTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ background: '#548235', fontWeight: 'bold', color: '#fff' }}>実績</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}Sum`}>
                        {getBumonMonthSummary(balances, indirects, indirectBumons, item.targetDate, 'arari', 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBumonSummary(balances, indirects, indirectBumons, 'arari', 'salary', displayType)}</DataTd>
                  </TableRow>
                  <TableRow hover>
                    <TitleTd colSpan={3} style={{ background: '#548235', fontWeight: 'bold', color: '#fff' }}>差異</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                        {getBumonMonthDiffSummary(budgets, expenses, balances, indirects, indirectBumons, item.targetDate, 'arari', 'budgetIndirect', 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBumonDiffSummary(budgets, expenses, balances, indirects, indirectBumons, 'arari', 'budgetIndirect', 'salary', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <React.Fragment id="bumonarari">
                  <TableRow>
                    <TitleTd style={{ width: '20px', padding: 3 }} rowSpan={2} colSpan={4}>粗利率</TitleTd>
                  </TableRow>
                  <DataTr hover>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}`}>
                        {getBumonArariRitsu(balances, indirects, indirectBumons, item.targetDate, 'uriage', 'arari', 'salary', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBumonArariRitsuAll(balances, indirects, indirectBumons, 'uriage', 'arari', 'salary', displayType)}</DataTd>
                  </DataTr>
                </React.Fragment>

                <React.Fragment id="sousisitsu">
                  <TableRow>
                    <TitleTd style={{ width: '20px', padding: 3 }} rowSpan={4}>総支出</TitleTd>
                  </TableRow>
                  <DataTr hover>
                    <TitleTd colSpan={3}>予算</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}`}>
                        {getShisyutsuMonthSummary(budgets, allBudgets, item.targetDate, 'budgetCost', 'budgetIndirect', 'indirect', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getShisyutsuSummary(budgets, allBudgets, 'budgetCost', 'budgetIndirect', 'indirect', displayType)}</DataTd>
                  </DataTr>
                  <DataTr hover>
                    <TitleTd colSpan={3}>実績</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}`}>
                        {getShisyutsuReulstMonthSummary(balances, indirectBumons, expenses, indirects, item.targetDate, 'properGenka', 'salary', 'kansetsu', 'outsourceGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getShisyutsuReulstSummary(balances, indirectBumons, expenses, indirects, 'properGenka', 'salary', 'kansetsu', 'outsourceGenka', displayType)}</DataTd>
                  </DataTr>
                  <DataTr hover>
                    <TitleTd colSpan={3}>差異</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}`}>
                        {getShisyutsuMonthDiffSummary(
                          budgets,
                          allBudgets,
                          item.targetDate,
                          'budgetCost',
                          'budgetIndirect',
                          'indirect',
                          displayType,
                          balances,
                          indirectBumons,
                          expenses,
                          indirects,
                          'properGenka',
                          'salary',
                          'kansetsu',
                          'outsourceGenka',
                        )}
                      </DataTd>
                    ))}
                    <DataTd>
                      {getShisyutsuDiffSummary(
                        budgets,
                        allBudgets,
                        'budgetCost',
                        'budgetIndirect',
                        'indirect',
                        displayType,
                        balances,
                        indirectBumons,
                        expenses,
                        indirects,
                        'properGenka',
                        'salary',
                        'kansetsu',
                        'outsourceGenka',
                      )}
                    </DataTd>
                  </DataTr>
                </React.Fragment>

                <React.Fragment id="cache">
                  <TableRow>
                    <TitleTd style={{ width: '20px', padding: 3 }} rowSpan={2} colSpan={4}>キャッシュ</TitleTd>
                  </TableRow>
                  <DataTr hover>
                    {months.map((item) => (
                      <DataTd key={`getCache${item.targetDate}`}>
                        {getCache(balances, indirects, item.targetDate, 'uriage', 'allGenka', 'kansetsu', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getCacheAll(balances, indirects, indirectBumons, expenses, 'uriage', 'allGenka', 'kansetsu', 'salary', displayType)}</DataTd>
                  </DataTr>
                </React.Fragment>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  companyBalanceInfo: state.companyBalanceStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(CompanyBalance);
