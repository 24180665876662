import createSagaMiddleware from '@redux-saga/core';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const saga = createSagaMiddleware();

const middlewareList = [...getDefaultMiddleware().concat(save()), saga];

const rootStore = configureStore({
  reducer: rootReducer,
  preloadedState: load(),
  middleware: middlewareList,
});

saga.run(rootSaga);

export default rootStore;
