/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { topNoticeActions } from './topNoticeState';
import { getDataNoLoading } from '../../../services/base.service';

function* getData() {
  try {
    const result = yield call(getDataNoLoading, REST_API.TOP.NOTICE);
    yield put(topNoticeActions.getDatasSuccess(result));
  } catch (_) {}
}

function* topNoticeSaga() {
  yield takeLatest('topNotice/getDatas', getData);
}

export default topNoticeSaga;
