import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { trafficRequestListActions } from './trafficRequestListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getCodes() {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CODES, { code: 'TRFC_STATE' });
    yield put(trafficRequestListActions.getCodesSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST, payload);
    yield put(trafficRequestListActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST, payload);
    yield put(trafficRequestListActions.deleteDataSuccess());
  } catch (_) {}
}

function* trafficRequestListSaga() {
  yield takeLatest('trafficRequestList/getCodes', getCodes);
  yield takeLatest('trafficRequestList/getDatas', getData);
  yield takeLatest('trafficRequestList/deleteData', deleteData);
}

export default trafficRequestListSaga;
