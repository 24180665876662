import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { expenseRequestListActions } from './expenseRequestListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.EXPENSE_REQUEST.EXPENSE_REQUEST, payload);
    yield put(expenseRequestListActions.getDataSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.REQUEST.EXPENSE_REQUEST.EXPENSE_REQUEST, payload);
    yield put(expenseRequestListActions.deleteDataSuccess());
  } catch (_) {}
}

function* expenseRequestListSaga() {
  yield takeLatest('expenseRequestList/getData', getData);
  yield takeLatest('expenseRequestList/deleteData', deleteData);
}

export default expenseRequestListSaga;
