import React from 'react';
import { styled } from '@mui/material/styles';

const TextareaAutosize = styled('textarea')(({ error }) => ({
  boxSizing: 'border-box',
  width: '100%',
  fontFamily: "'IBM Plex Sans', sans-serif",
  fontSize: 'inherit',
  fontWeight: 400,
  lineHeight: 1.5,
  padding: '8px 12px',
  borderRadius: '8px',
  color: '#000',
  background: '#fff',
  border: error ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.23)',
  '&:hover': {
    borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.87)',
  },
  '&:focus': {
    border: error ? '1px solid red' : '2px solid rgb(30,144,255)',
  },
  '&:focus-visible': {
    outline: 0,
  },
}));

export default function TextArea(props) {
  const {
    value, onChange, name, isDisabled, error, minRows,
  } = props;

  const onLocalChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    });
  };

  return (
    <TextareaAutosize
      disabled={isDisabled}
      name={name}
      error={error}
      value={value || ''}
      onChange={onLocalChange}
      rows={minRows || 5}
    />
  );
}
