/* eslint-disable class-methods-use-this */
import React from 'react';
import ExcelJS from 'exceljs';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Button, Box, FormControl, Radio, FormControlLabel, RadioGroup, Alert,
} from '@mui/material';
import { format } from 'date-fns';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import { outputActions } from '../../../../redux/file/output/outputState';
import createData from './skill-sheet-proc';
import SelectSyain from '../../../../components/organisms/selectSyain/SelectSyain';

class SkillSheet extends React.Component {
  constructor(props) {
    super(props);

    this.today = format(new Date(), 'yyyy/MM/dd');
    this.state = {
      syainId: '',
      outputType: '0',
      memo: '0',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.outputInfo.skillSheets !== this.props.outputInfo.skillSheets) {
      if (this.props.outputInfo.skillSheets) {
        this.setDatas(this.props.outputInfo.skillSheets);
      }
    }
  }

  setDatas = (data) => {
    if (data.length <= 0) {
      this.props.onNotData();
      return;
    }
    this.download(data);
  };

  download = async (datas) => {
    const { memo } = this.state;
    const res = await axios.get('/スキルシート.xlsx', { responseType: 'arraybuffer' });
    const data = new Uint8Array(res.data);
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;
    await workbook.xlsx.load(data);
    createData(workbook, datas, memo);
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `スキルシート_${datas[0].syainMei}.xlsx`;
    a.click();
    a.remove();
    this.props.onNotLoading();
  };

  onGetData = () => {
    const { syainId, outputType } = this.state;
    this.props.onLoading();
    this.props.dispatch(outputActions.getSkillSheets({ syainId, outputType }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { syainId, outputType, memo } = this.state;

    return (
      <Box>
        <Box style={{ maxWidth: '300px' }}>
          <FormTitle title="名前" />
          <SelectSyain
            name="syainId"
            targetMonth={this.today}
            value={syainId}
            onChange={this.onChange}
          />
        </Box>
        <Box mt={3}>
          <FormTitle title="出力タイプ" isRequired />
          <FormControl>
            <RadioGroup
              row
              onChange={this.onChange}
              name="outputType"
              value={outputType}
            >
              <FormControlLabel value="0" control={<Radio />} label="イニシャル" />
              <FormControlLabel value="1" control={<Radio />} label="正規" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mt={3}>
          <FormTitle title="メモ" isRequired />
          <FormControl>
            <RadioGroup
              row
              onChange={this.onChange}
              name="memo"
              value={memo}
            >
              <FormControlLabel value="0" control={<Radio />} label="出力しない" />
              <FormControlLabel value="1" control={<Radio />} label="出力する" />
            </RadioGroup>
          </FormControl>
          <Box><Alert severity="warning">メモはL列に出力します。取り扱いには十分注意してください。</Alert></Box>
        </Box>
        <Box mt={2}>
          <Button variant="contained" onClick={this.onGetData} disabled={!syainId}>出力</Button>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  outputInfo: state.outputStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(SkillSheet);
