import React from 'react';
import { connect } from 'react-redux';
import {
  Tabs, Box, Container, Tab, TextField, Grid, Paper,
  FormControlLabel, Switch,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { assetActions } from '../../../redux/master/asset/assetState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import Validation from './validation';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import TextArea from '../../../components/atoms/textArea/TextArea';

class AssetDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      data: {
        id,
        name: '',
        assetId: '',
        assetKbn: '',
        state: '',
      },
      errorMessage: {
        assetKbn: '',
        name: '',
        assetId: '',
        purchaseDate: '',
        maintenanceEndDate: '',
        subscriptionDate: '',
        cancellationDate: '',
        contractRenewalDate: '',
        state: '',
      },
      tabValue: 0,
      select: {
        statuses: [],
        categories: [],
        softs: [],
      },
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };

    props.dispatch(assetActions.getSelect());
    if (id) {
      props.dispatch(assetActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetInfo.data !== this.props.assetInfo.data) {
      if (this.props.assetInfo.data) {
        this.setData(this.props.assetInfo.data);
      }
    }
    if (prevProps.assetInfo.isSaveSuccess !== this.props.assetInfo.isSaveSuccess) {
      if (this.props.assetInfo.isSaveSuccess) {
        this.setUpdateSuccess(this.props.assetInfo.saveId);
      }
    }
    if (prevProps.assetInfo.selects !== this.props.assetInfo.selects) {
      if (this.props.assetInfo.selects) {
        this.setSelect(this.props.assetInfo.selects);
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setData(data) {
    this.setState({ data });
  }

  onChange = (event) => {
    const { data, errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  setUpdateSuccess = (id) => {
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存が完了しました',
    });
    this.props.dispatch(assetActions.getData({ id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSave = () => {
    const { data, errorMessage } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(data).forEach((key) => {
      const msg = Validation.formValidate(key, data[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessage: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(assetActions.saveData(data));
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onToggleChange = (event) => {
    const { data } = this.state;
    const { name, checked } = event.target;
    const tempData = {
      ...data,
      [name]: checked,
    };
    this.setState({ data: tempData });
  };

  render() {
    const {
      data, isSuccessOpen, successMessage, isDisabled, tabValue, errorMessage,
      select,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
            <Tab label="基本情報" />
            <Tab label="PC情報" />
            <Tab label="Wifi情報" />
            <Tab label="Software情報" />
            <Tab label="その他" />
            <Tab label="付属情報" disabled={!data.id} />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0} isAll>
          <Box>
            <FormTitle title="資産ID" isRequired />
            <TextField value={data.assetId} name="assetId" inputProps={{ maxLength: 30 }} error={errorMessage.assetId !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.assetId}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="資産名" isRequired />
            <TextField value={data.name} name="name" inputProps={{ maxLength: 100 }} error={errorMessage.name !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.name}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="カテゴリ" isRequired />
            <SearchSelectBox
              error={errorMessage.assetKbn !== ''}
              name="assetKbn"
              disableClearable
              options={select.categories}
              value={data.assetKbn}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.assetKbn}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="状態" isRequired />
            <SearchSelectBox
              error={errorMessage.state !== ''}
              name="state"
              disableClearable
              options={select.statuses}
              value={data.state}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.state}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="購入日" />
            <DatePicker
              handleChange={this.onChange}
              name="purchaseDate"
              value={data.purchaseDate}
              error={errorMessage.purchaseDate !== ''}
            />
            <FormErrorText>{errorMessage.purchaseDate}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="購入金額" />
            <MoneyInput
              fullWidth
              value={data.purchaseMoney}
              name="purchaseMoney"
              onChange={this.onChange}
            />
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1} isAll>
          <Box>
            <FormTitle title="インストール済Office資産Id" />
            <SearchSelectBox
              name="installOfficeId"
              options={select.softs}
              value={data.installOfficeId}
              onChange={this.onChange}
            />
          </Box>
          <Box mt={2}>
            <FormTitle title="メーカー" />
            <TextField value={data.manufacturer} name="manufacturer" inputProps={{ maxLength: 100 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="モデル" />
            <TextField value={data.model} name="model" inputProps={{ maxLength: 100 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="シリアル" />
            <TextField value={data.serial} name="serial" inputProps={{ maxLength: 100 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="メモリ" />
            <TextField value={data.memory} name="memory" inputProps={{ maxLength: 10 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="メンテナンス終了日" />
            <DatePicker
              handleChange={this.onChange}
              name="maintenanceEndDate"
              value={data.maintenanceEndDate}
              error={errorMessage.maintenanceEndDate !== ''}
            />
            <FormErrorText>{errorMessage.maintenanceEndDate}</FormErrorText>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={2} isAll>
          <Box>
            <FormTitle title="Wifiパスワード" />
            <TextField value={data.wifiPassword} name="wifiPassword" inputProps={{ maxLength: 30 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="BBNavi会員ID" />
            <TextField value={data.bbnaviMemberId} name="bbnaviMemberId" inputProps={{ maxLength: 10 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="BBNavi会員パスワード" />
            <TextField value={data.bbnaviMemberPassword} name="bbnaviMemberPassword" inputProps={{ maxLength: 30 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="加入年月" />
            <DatePicker
              handleChange={this.onChange}
              name="subscriptionDate"
              value={data.subscriptionDate}
              error={errorMessage.subscriptionDate !== ''}
            />
            <FormErrorText>{errorMessage.subscriptionDate}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="解約予定年月" />
            <DatePicker
              handleChange={this.onChange}
              name="cancellationDate"
              value={data.cancellationDate}
              error={errorMessage.cancellationDate !== ''}
            />
            <FormErrorText>{errorMessage.cancellationDate}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="契約更新年月" />
            <DatePicker
              handleChange={this.onChange}
              name="contractRenewalDate"
              value={data.contractRenewalDate}
              error={errorMessage.contractRenewalDate !== ''}
            />
            <FormErrorText>{errorMessage.contractRenewalDate}</FormErrorText>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={3} isAll>
          <Box>
            <FormTitle title="購入時プロダクトキー" />
            <TextField value={data.purchaseProductKey} name="purchaseProductKey" inputProps={{ maxLength: 30 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="インストール用プロダクトキー" />
            <TextField value={data.installProductKey} name="installProductKey" inputProps={{ maxLength: 30 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="オフィスアカウント" />
            <TextField value={data.officeAccount} name="officeAccount" inputProps={{ maxLength: 30 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="オフィスアカウントパスワード" />
            <TextField value={data.officePassword} name="officePassword" inputProps={{ maxLength: 30 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="CD有" />
            <FormControlLabel control={<Switch checked={data.cdHad} name="cdHad" onChange={this.onToggleChange} />} label="CD有" />
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={4} isAll>
          <Box>
            <FormTitle title="予備1" />
            <TextField value={data.reserveOne} name="reserveOne" inputProps={{ maxLength: 100 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="予備2" />
            <TextField value={data.reserveTwo} name="reserveTwo" inputProps={{ maxLength: 100 }} fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="備考" />
            <TextArea name="remarks" value={data.remarks} onChange={this.onChange} />
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={5}>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTitle title="作成日" />
                <Box>{data.createdAt}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="作成者" />
                <Box>{data.createdBy}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="更新日" />
                <Box>{data.updatedAt}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="更新者" />
                <Box>{data.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>

        {(!isDisabled && tabValue !== 5) && (
          <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
          </Box>
        )}
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  assetInfo: state.assetStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(AssetDetail));
