import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@mui/material';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { employeeLicenseHistoryActions } from '../../../../redux/office/employee/employeeLicenseHistoryState';
import { checkIsDisabled } from '../../../../utils/authCheck.helper';

class LicenseList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.getData(props.syainId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.licenseInfo.datas !== this.props.licenseInfo.datas) {
      if (this.props.licenseInfo.datas) {
        this.setDatas(this.props.licenseInfo.datas);
      }
    }
    if (prevProps.licenseInfo.isDeleteSuccess !== this.props.licenseInfo.isDeleteSuccess) {
      if (this.props.licenseInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  getData() {
    this.props.dispatch(employeeLicenseHistoryActions.getDatas({ syainId: this.props.syainId }));
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.getData(this.props.syainId);
    this.setState({ isSuccessOpen: true, successMessage });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onResearch = () => {
    this.setSuccess('保存が完了しました');
  };

  render() {
    const {
      datas,
      isSuccessOpen,
      successMessage,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <DataTable
          rows={datas || []}
          disabled={isDisabled}
          onResearch={this.onResearch}
          syainId={this.props.syainId}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  licenseInfo: state.employeeLicenseHistoryStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(LicenseList);
