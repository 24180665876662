import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';

export default function FormErrorText(props) {
  const { children } = props;

  return (
    <FormHelperText error style={{ fontSize: '0.9rem' }}>{children}</FormHelperText>
  );
}

FormErrorText.propTypes = {
  children: PropTypes.node,
};

FormErrorText.defaultProps = {
  children: null,
};
