import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Container,
} from '@mui/material';
import { format } from 'date-fns';
import DataTable from './DataTable';
import { workCostListActions } from '../../../redux/work/workCostList/workCostListState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import SelectProject from '../../../components/organisms/selectProject/SelectProject';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';

class WorkCostList extends React.Component {
  constructor(props) {
    super(props);

    const searchCondition = {
      targetStartMonth: format(new Date(), 'yyyy/MM/dd'),
      syainId: '',
      projectId: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workCostInfo.data !== this.props.workCostInfo.data) {
      if (this.props.workCostInfo.data) {
        this.setDatas(this.props.workCostInfo.data);
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(workCostListActions.getData(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="社員" />
              <SelectSyain onChange={this.onChange} name="syainId" value={searchCondition.syainId} targetMonth={searchCondition.targetStartMonth} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="開始" />
              <MonthPicker value={searchCondition.targetStartMonth} name="targetStartMonth" handleChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="終了" />
              <MonthPicker value={searchCondition.targetEndMonth} name="targetEndMonth" handleChange={this.onChange} />
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="プロジェクト" />
              <SelectProject onChange={this.onChange} targetMonth={searchCondition.targetStartMonth} name="projectId" value={searchCondition.projectId} />
            </Grid>
          </Grid>
        </SearchBox>

        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  workCostInfo: state.workCostListStore,
});

export default connect(mapStateToProps)(WorkCostList);
