/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const assetRequestDetailSlice = createSlice({
  name: 'assetRequestDetail',
  initialState: {
    data: {},
    codes: [],
    saveId: null,
    isSaveSuccess: false,
    isUpdateSuccess: false,
    isReturnSuccess: false,
  },
  reducers: {
    clearFlag: (state) => {
      state.isSaveSuccess = false;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    updateDatas: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDatasSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    returnDate: (state) => {
      state.isReturnSuccess = false;
    },
    returnDateSuccess: (state) => {
      state.isReturnSuccess = true;
    },
  },
});

export const assetRequestDetailActions = assetRequestDetailSlice.actions;

export default assetRequestDetailSlice.reducer;
