import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, DialogTitle, DialogContent, DialogActions, Box, TextField,
  FormControl, Radio, FormControlLabel, RadioGroup,
} from '@mui/material';
import Dialog from '../../../../components/atoms/dialog/Dialog';
import { employeeActions } from '../../../../redux/office/employee/employeeState';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
import DatePicker from '../../../../components/atoms/datePicker/DatePicker';
import urls from '../../../../constants/frontUrls';
import Validation from './Validation';

const tmpForm = {
  id: '',
  syainMei: '',
  syainMeiRubi: '',
  seibetsu: '',
  entryDt: '',
  birthday: '',
  depCode: '',
  rankCode: '',
  mail: '',
  loginId: '',
  roleId: '',
};

export default function EmployeeCreateDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpForm);
  const [form, setForm] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onClickClose } = props;
  const isInsertSuccess = useSelector((state) => state.employeeStore.isInsertSuccess);
  const saveId = useSelector((state) => state.employeeStore.saveId);
  const select = useSelector((state) => state.employeeStore.select);

  React.useEffect(() => {
    if (isOpen) {
      setErrorMessage(tmpForm);
      setForm(tmpForm);
      dispatch(employeeActions.getSelect());
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (isInsertSuccess) {
      navigate(urls.EMPLPYEE_DETAIL + saveId);
    }
  }, [isInsertSuccess]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.status);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (isError) {
      return;
    }

    dispatch(employeeActions.insertData(form));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClickClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>社員の追加</DialogTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="社員ID" isRequired />
          <TextField value={form.id} name="id" inputProps={{ maxLength: 6 }} autoComplete="off" error={errorMessage.id !== ''} onChange={onChange} />
          <FormErrorText>{errorMessage.id}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="社員名" isRequired />
          <TextField value={form.syainMei} name="syainMei" inputProps={{ maxLength: 50 }} error={errorMessage.syainMei !== ''} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.syainMei}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="社員名カナ" isRequired />
          <TextField value={form.syainMeiRubi} name="syainMeiRubi" inputProps={{ maxLength: 50 }} error={errorMessage.syainMeiRubi !== ''} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.syainMeiRubi}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="メールアドレス" isRequired />
          <TextField value={form.mail} name="mail" inputProps={{ maxLength: 40 }} error={errorMessage.mail !== ''} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.mail}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="入社日" isRequired />
          <DatePicker
            handleChange={onChange}
            name="entryDt"
            value={form.entryDt}
            error={errorMessage.entryDt !== ''}
          />
          <FormErrorText>{errorMessage.entryDt}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="性別" isRequired />
          <FormControl>
            <RadioGroup
              row
              onChange={onChange}
              name="seibetsu"
              value={form.seibetsu}
            >
              <FormControlLabel value="M" control={<Radio />} label="男" />
              <FormControlLabel value="F" control={<Radio />} label="女" />
            </RadioGroup>
          </FormControl>
          <FormErrorText>{errorMessage.seibetsu}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="生年月日" isRequired />
          <DatePicker
            handleChange={onChange}
            name="birthday"
            value={form.birthday}
            error={errorMessage.birthday !== ''}
          />
          <FormErrorText>{errorMessage.birthday}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="部署" isRequired />
          <SearchSelectBox
            error={errorMessage.depCode !== ''}
            name="depCode"
            disableClearable
            options={select.busyoList}
            value={form.depCode}
            onChange={onChange}
          />
          <FormErrorText>{errorMessage.depCode}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="ランク" isRequired />
          <SearchSelectBox
            error={errorMessage.rankCode !== ''}
            name="rankCode"
            disableClearable
            options={select.rankList}
            value={form.rankCode}
            onChange={onChange}
          />
          <FormErrorText>{errorMessage.rankCode}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="ログインID" isRequired />
          <TextField value={form.loginId} name="loginId" inputProps={{ maxLength: 40 }} error={errorMessage.loginId !== ''} onChange={onChange} />
          <FormErrorText>{errorMessage.loginId}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="権限" isRequired />
          <SearchSelectBox
            error={errorMessage.roleId !== ''}
            name="roleId"
            disableClearable
            options={select.roleList}
            value={form.roleId}
            onChange={onChange}
          />
          <FormErrorText>{errorMessage.roleId}</FormErrorText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
