/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const closeEditSlice = createSlice({
  name: 'closeEdit',
  initialState: {
    data: '',
    isUpdateSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.data = null;
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
  },
});

export const closeEditActions = closeEditSlice.actions;

export default closeEditSlice.reducer;
