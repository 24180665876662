import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box, IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';

const DataTableRow = styled(TableRow)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  React.useEffect(() => {
    if (props.rows) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
    }
  }, [props.rows]);

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const headCells = [
    { id: 'name', label: '名前' },
    { id: 'target_month', label: '対象月' },
    { id: 'project_name', label: 'プロジェクト' },
    { id: 'man_hour', label: '工数' },
    { id: 'persent', label: '割合' },
  ];

  const geTotalCost = () => {
    let total = 0;
    selected.forEach((c) => {
      props.rows.forEach((v) => {
        if (c === v.id) {
          total += Number.parseFloat(v.manHour);
        }
      });
    });
    return Math.round(total * 100) / 100;
  };

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
  } = props;

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Box mr={2}>
                <Box>{`工数合計：${geTotalCost()}`}</Box>
              </Box>
            </>
          )}
        </Toolbar>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <DataTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.targetMonth}</TableCell>
                    <TableCell>{row.projectName}</TableCell>
                    <TableCell>{row.manHour}</TableCell>
                    <TableCell>{row.persent}</TableCell>
                  </DataTableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={4}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
