import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Container, Paper, Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { employeeHistoryActions } from '../../../../redux/office/employee/employeeHistoryState';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import HistoryDialog from './HistoryDialog';
import { checkIsDisabled } from '../../../../utils/authCheck.helper';

class HistoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      isSuccessOpen: false,
      successMessage: '',
      isConfOpen: false,
      isDetailOpen: false,
      isNew: false,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.getData(props.syainId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.historyInfo.datas !== this.props.historyInfo.datas) {
      if (this.props.historyInfo.datas) {
        this.setDatas(this.props.historyInfo.datas);
      }
    }
    if (prevProps.historyInfo.isDeleteSuccess !== this.props.historyInfo.isDeleteSuccess) {
      if (this.props.historyInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
    if (prevProps.historyInfo.isSaveSuccess !== this.props.historyInfo.isSaveSuccess) {
      if (this.props.historyInfo.isSaveSuccess) {
        this.setSuccess('保存処理が完了しました');
      }
    }
  }

  getData() {
    this.props.dispatch(employeeHistoryActions.getDatas({ syainId: this.props.syainId }));
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.getData(this.props.syainId);
    this.setState({
      isSuccessOpen: true, successMessage, isConfOpen: false, isDetailOpen: false,
    });
  }

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  deleteConfirm = () => {
    this.setState({ isConfOpen: true });
  };

  onClose = () => {
    this.setState({ isConfOpen: false, isDetailOpen: false });
  };

  onClickDelete = () => {
    this.props.dispatch(employeeHistoryActions.deleteData({ syainId: this.props.syainId }));
  };

  onDetail = (_, isNew) => {
    this.setState({ isNew, isDetailOpen: true });
  };

  render() {
    const {
      datas,
      isSuccessOpen,
      successMessage,
      isConfOpen,
      isDetailOpen,
      isNew,
      isDisabled,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth="xl">
        {!isDisabled && (
          <Box display="flex" mb={2}>
            <Box flex="auto">
              <LoadingButton variant="contained" loading={isLoading} onClick={this.deleteConfirm} color="secondary" disabled={datas.length <= 1}>削除</LoadingButton>
              <LoadingButton variant="contained" loading={isLoading} onClick={(e) => this.onDetail(e, false)} style={{ marginLeft: 20 }}>編集</LoadingButton>
            </Box>
            <Box>
              <LoadingButton variant="contained" loading={isLoading} onClick={(e) => this.onDetail(e, true)}>新規</LoadingButton>
            </Box>
          </Box>
        )}
        <Paper>
          <TableContainer sx={{ maxHeight: 750 }}>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell>部</TableCell>
                  <TableCell>課</TableCell>
                  <TableCell>ランク</TableCell>
                  <TableCell>役員報酬</TableCell>
                  <TableCell>年俸基本</TableCell>
                  <TableCell>配偶</TableCell>
                  <TableCell>扶養</TableCell>
                  <TableCell>家族</TableCell>
                  <TableCell>間接</TableCell>
                  <TableCell>間接負担</TableCell>
                  <TableCell>休職</TableCell>
                  <TableCell>フレックス</TableCell>
                  <TableCell>残業申請時間</TableCell>
                  <TableCell>開始</TableCell>
                  <TableCell>終了</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>{row.sykmCd}</TableCell>
                    <TableCell>{row.mstDivisionId}</TableCell>
                    <TableCell>{row.rank}</TableCell>
                    <TableCell>{row.ykin.toLocaleString()}</TableCell>
                    <TableCell>{row.nnpKhnKyu.toLocaleString()}</TableCell>
                    <TableCell>{row.partnerHad ? '有' : ''}</TableCell>
                    <TableCell>{row.fuyou}</TableCell>
                    <TableCell>{row.kazoku}</TableCell>
                    <TableCell>{row.indirected ? '対象' : ''}</TableCell>
                    <TableCell>{row.indirectBorne ? '対象' : ''}</TableCell>
                    <TableCell>{row.leaved ? '対象' : ''}</TableCell>
                    <TableCell>{row.flex ? '対象' : ''}</TableCell>
                    <TableCell>{row.overRequestTime}</TableCell>
                    <TableCell>{format(new Date(row.useSt), 'yyyy年MM月dd日')}</TableCell>
                    <TableCell>{format(new Date(row.useEd), 'yyyy年MM月dd日')}</TableCell>
                  </TableRow>
                ))}
                {
                  datas.length < 1 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={9}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <HistoryDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          syainId={this.props.syainId}
          isNew={isNew}
        />
        <ConfirmDialog
          open={isConfOpen}
          isError
          onClose={this.onClose}
          title="選択した履歴の削除"
          content="最新の社員レベル履歴データを削除します"
          onClick={this.onClickDelete}
          procName="削除する"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  historyInfo: state.employeeHistoryStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(HistoryList);
