function hiddenRow(workSheet, dataCount) {
  for (let i = 0; i < dataCount; i += 1) {
    const row = workSheet.getRow(60 - i);
    row.hidden = true;
  }
}

export default function createData(workbook, datas, memo) {
  const worksheet = workbook.getWorksheet('スキルシート');
  worksheet.pageSetup = { orientation: 'portrait', paperSize: 9 };
  const syain = datas[0];
  let row = worksheet.getRow(2);
  row.getCell(4).value = syain.syainMeiRubi;
  row = worksheet.getRow(3);
  row.getCell(4).value = syain.syainMei;
  row.getCell(6).value = syain.seibetsu;
  row.getCell(7).value = syain.age;
  row.getCell(8).value = syain.career;
  row = worksheet.getRow(5);
  row.getCell(2).value = syain.school;
  row.getCell(5).value = syain.stationMei;
  row.getCell(9).value = syain.national;
  row = worksheet.getRow(7);
  row.getCell(2).value = syain.certify;
  let n = syain.certify.match(/\r/g);
  let len = 1;
  if (n) {
    len = n.length + 1;
  }
  row.height = len * 10;

  row = worksheet.getRow(62);
  row.getCell(2).value = syain.remarks;
  n = syain.remarks.match(/\r/g);
  len = 1;
  if (n) {
    len = n.length + 1;
  }
  row.height = len * 10;
  const cellNumber = 11;
  datas.forEach((data, index) => {
    row = worksheet.getRow(cellNumber + index);
    row.getCell(3).value = data.sankakuTerm;
    row.getCell(4).value = data.overview;
    row.getCell(5).value = data.jobEngin;
    row.getCell(6).value = data.os;
    row.getCell(7).value = data.db;
    row.getCell(8).value = data.ap;
    row.getCell(9).value = data.lang;
    row.getCell(10).value = data.tool;
    if (memo === '1') {
      row.getCell(12).value = data.memo;
    }
  });
  hiddenRow(worksheet, 50 - datas.length);
}
