import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Box, Grid,
  DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { payActions } from '../../../redux/sales/pay/payState';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import urls from '../../../constants/frontUrls';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';

const tmpMessage = {
  payNo: '',
  seikyuDt: '',
  nyukinDt: '',
};

const tmpForm = {
  id: null,
  payNo: '',
  seikyuDt: '',
  nyukinDt: '',
};

export default function PayDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, payId, parentId, onSaveSuccess,
  } = props;
  const data = useSelector((state) => state.payStore.data);
  const isCreateSuccess = useSelector((state) => state.payStore.isCreateSuccess);
  const isUpdateSuccess = useSelector((state) => state.payStore.isUpdateSuccess);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const saveId = useSelector((state) => state.payStore.saveId);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (isCreateSuccess) {
      navigate(urls.PAY_DETAIL + saveId);
    }
  }, [isCreateSuccess]);
  React.useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(payActions.resetFlg());
      onSaveSuccess();
    }
  }, [isUpdateSuccess]);
  React.useEffect(() => {
    if (isOpen) {
      if (payId) {
        dispatch(
          payActions.getData({ id: payId }),
        );
      }
      setForm({
        ...tmpForm,
        orderId: parentId,
      });
      setErrorMessage(tmpMessage);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (isError) {
      return;
    }

    if (!payId) {
      dispatch(payActions.insertData(form));
    } else {
      dispatch(payActions.updateData(form));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  return (
    <MaterialDialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>支払編集</DialogTitle>
      <LinearProgress style={{ display: isLoading ? '' : 'none' }} />
      <DialogContent dividers style={{ display: isLoading ? 'none' : '' }}>
        <Box>
          <FormTitle title="支払番号" isRequired />
          <TextField variant="outlined" error={errorMessage.payNo !== ''} fullWidth value={form.payNo} inputProps={{ maxLength: 30 }} name="payNo" onChange={onChange} />
          <FormErrorText>{errorMessage.payNo}</FormErrorText>
        </Box>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="計上月" isRequired />
            <MonthPicker
              handleChange={onChange}
              error={errorMessage.seikyuDt !== ''}
              value={form.seikyuDt}
              name="seikyuDt"
            />
            <FormErrorText>{errorMessage.seikyuDt}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="支払予定" isRequired />
            <DatePicker
              handleChange={onChange}
              value={form.nyukinDt}
              error={errorMessage.nyukinDt !== ''}
              name="nyukinDt"
            />
            <FormErrorText>{errorMessage.nyukinDt}</FormErrorText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} color="inherit" onClick={onClose}>
          キャンセル
        </LoadingButton>
        <LoadingButton loading={isLoading} color="primary" onClick={onClickSave}>
          保存
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
