import React from 'react';
import { connect } from 'react-redux';
import {
  Tabs, Box, Container, Tab, TextField, Grid, Paper,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { certificateActions } from '../../../redux/master/certificate/certificateState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import FreeSoloBox from '../../../components/atoms/freeSoloBox/FreeSoloBox';
import HistoryList from './HistoryList';
import Validation from './validation';

class CertificateDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      data: {
        id,
        category: '',
        name: '',
        certificateLevel: '',
      },
      targets: [],
      errorMessage: {
        category: '',
        name: '',
        certificateLevel: '',
      },
      tabValue: 0,
      select: [],
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };

    props.dispatch(certificateActions.getSelect());
    if (id) {
      props.dispatch(certificateActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.certificateInfo.data !== this.props.certificateInfo.data) {
      if (this.props.certificateInfo.data) {
        this.setData(this.props.certificateInfo.data);
      }
    }
    if (prevProps.certificateInfo.isSaveSuccess !== this.props.certificateInfo.isSaveSuccess) {
      if (this.props.certificateInfo.isSaveSuccess) {
        this.setUpdateSuccess(this.props.certificateInfo.saveId);
      }
    }
    if (prevProps.certificateInfo.selects !== this.props.certificateInfo.selects) {
      if (this.props.certificateInfo.selects) {
        this.setSelect(this.props.certificateInfo.selects);
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setData(data) {
    this.setState({ data: data.entity, targets: data.targets });
  }

  onChange = (event) => {
    const { data, errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  setUpdateSuccess = (id) => {
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存が完了しました',
    });
    this.props.dispatch(certificateActions.getData({ id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSave = () => {
    const { data, errorMessage } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(data).forEach((key) => {
      const msg = Validation.formValidate(key, data[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    this.setState({ errorMessage: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(certificateActions.saveData(data));
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const {
      data, isSuccessOpen, successMessage, isDisabled, tabValue, errorMessage,
      select, targets,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
            <Tab label="基本情報" />
            {data.id && (
              <Tab label="取得者" />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} isAll>
          <Box>
            <FormTitle title="カテゴリ" isRequired />
            <FreeSoloBox
              error={errorMessage.category !== ''}
              name="category"
              disableClearable
              options={select}
              value={data.category}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.category}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="資格名" isRequired />
            <TextField value={data.name} name="name" error={errorMessage.name !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.name}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="資格レベル" />
            <TextField value={data.certificateLevel} name="certificateLevel" error={errorMessage.certificateLevel !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.certificateLevel}</FormErrorText>
          </Box>
          {data.id && (
            <Paper variant="outlined" style={{ padding: '10px', marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <FormTitle title="作成日" />
                  <Box>{data.createdAt}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="作成者" />
                  <Box>{data.createdBy}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="更新日" />
                  <Box>{data.updatedAt}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="更新者" />
                  <Box>{data.updatedBy}</Box>
                </Grid>
              </Grid>
            </Paper>
          )}
        </TabPanel>

        {data.id && (
          <TabPanel value={tabValue} index={1} isAll>
            <HistoryList datas={targets} />
          </TabPanel>
        )}

        {(!isDisabled && tabValue === 0) && (
          <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
          </Box>
        )}
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  certificateInfo: state.certificateStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(CertificateDetail));
