/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { projectActions } from './projectState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PROJECT.PROJECT, payload);
    yield put(projectActions.getDatasSuccess(result));
  } catch (_) {}
}

function* getSubData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PROJECT.PROJECT, payload);
    yield put(projectActions.getSubDataSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.PROJECT.PROJECT, payload);
    yield put(projectActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.PROJECT.PROJECT_ID, payload);
    yield put(projectActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.PROJECT.PROJECT, payload);
    yield put(projectActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.PROJECT.PROJECT, payload);
    yield put(projectActions.updateDataSuccess(result));
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PROJECT.PROJECT_SELECT, payload);
    yield put(projectActions.getSelectSuccess(result));
  } catch (_) {}
}

function* projectSaga() {
  yield takeLatest('project/getDatas', getData);
  yield takeLatest('project/getSubData', getSubData);
  yield takeLatest('project/deleteData', deleteData);
  yield takeLatest('project/getData', getDataFromId);
  yield takeLatest('project/insertData', insertData);
  yield takeLatest('project/updateData', updateData);
  yield takeLatest('project/getSelect', getSelect);
}

export default projectSaga;
