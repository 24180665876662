import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, TextField,
  Switch, FormControlLabel, FormControl, Select, MenuItem,
} from '@mui/material';
import { format } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { partnerActions } from '../../../redux/sales/partner/partnerState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import SelectClient from '../../../components/organisms/selectClient/SelectClient';
import TextArea from '../../../components/atoms/textArea/TextArea';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';

const tmpMessage = {
  bpName: '',
  bpNameKana: '',
  clientId: '',
  startDate: '',
  endDate: '',
  mail: '',
};

const tmpForm = {
  bpName: '',
  bpNameKana: '',
  clientId: '',
  startDate: '',
  endDate: '',
  biko: '',
  workUnit: '',
};

class PartnerDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      successMessage: '',
      isSuccessOpen: false,
      exForm: {},
      form: tmpForm,
      errorMessage: tmpMessage,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    if (id) {
      props.dispatch(partnerActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partnerInfo.data !== this.props.partnerInfo.data) {
      if (this.props.partnerInfo.data) {
        this.setDatas(this.props.partnerInfo.data);
      }
    }
    if (prevProps.partnerInfo.isSaveSuccess
      !== this.props.partnerInfo.isSaveSuccess) {
      if (this.props.partnerInfo.isSaveSuccess) {
        this.setSaveSuccess(this.props.partnerInfo.saveId);
      }
    }
  }

  setSaveSuccess = (id) => {
    this.props.dispatch(partnerActions.getData({ id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました' });
  };

  onCopy = () => {
    const { exForm } = this.state;
    this.setState({
      isSuccessOpen: true,
      successMessage: 'コピーが完了しました',
      errorMessage: tmpMessage,
      form: {
        ...exForm,
        partnerId: '',
      },
    });
  };

  setDatas = (form) => {
    this.setState({ form, exForm: form });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessage } = this.state;
    const tempForm = {
      ...form,
      [name]: value,
    };
    if (name === 'uriageDt') {
      this.props.dispatch(partnerActions.getSelect({ targetMonth: format(new Date(), 'yyyy/MM/dd') }));
    }
    this.setState({
      form: tempForm,
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  onToggleChange = (event) => {
    const { form } = this.state;
    const { checked, name } = event.target;
    this.setState({ form: { ...form, [name]: checked } });
  };

  onSave = () => {
    const { form, errorMessage } = this.state;

    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.tFlg, form.sFlg);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.startDate && !tempMessage.endDate) {
      const msg = Validation.compareDateValidate(form.startDate, form.endDate);
      if (msg) {
        isError = true;
      }
      tempMessage.endDate = msg;
    }
    if (form.pwFlg && !form.mail) {
      isError = true;
      tempMessage.mail = '入力してください';
    }

    this.setState({ errorMessage: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(partnerActions.saveData(form));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      form, errorMessage, isSuccessOpen, successMessage, isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        {!isDisabled && (
          <Box p={1} textAlign="right">
            <Box sx={{ flex: '0 0 auto' }}>
              {form.partnerId && (
                <LoadingButton
                  style={{ marginRight: 20 }}
                  disabled={isDisabled}
                  loading={this.props.common.isLoading}
                  onClick={this.onCopy}
                  variant="outlined"
                >
                  コピーする
                </LoadingButton>
              )}
              <LoadingButton disabled={isDisabled} loading={this.props.common.isLoading} variant="contained" onClick={this.onSave}>保存</LoadingButton>
            </Box>
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Box>
            <FormTitle title="名前" isRequired />
            <TextField value={form.bpName} name="bpName" error={errorMessage.bpName !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.bpName}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="ふりがな" isRequired />
            <TextField value={form.bpNameKana} name="bpNameKana" error={errorMessage.bpNameKana !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.bpNameKana}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="取引先" isRequired />
            <SelectClient
              error={errorMessage.clientId !== ''}
              name="clientId"
              disableClearable
              value={form.clientId}
              onChange={this.onChange}
            />
            <FormErrorText>{errorMessage.clientId}</FormErrorText>
          </Box>
          <Grid container mt={2}>
            <Grid item xs={6} md={3}>
              <FormTitle title="開始日" isRequired />
              <DatePicker
                handleChange={this.onChange}
                value={form.startDate}
                name="startDate"
              />
              <FormErrorText>{errorMessage.startDate}</FormErrorText>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="終了日" isRequired />
              <DatePicker
                handleChange={this.onChange}
                value={form.endDate}
                name="endDate"
              />
              <FormErrorText>{errorMessage.endDate}</FormErrorText>
            </Grid>
          </Grid>
          <Box mt={2}>
            <FormTitle title="メール" />
            <TextField value={form.mail} name="mail" fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.mail}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="システム使用" />
            <FormControlLabel control={<Switch checked={form.pwFlg} name="pwFlg" onChange={this.onToggleChange} />} label="許可" />
          </Box>
          <Box mt={2}>
            <FormTitle title="作業時間丸め単位" />
            <FormControl style={{ minWidth: '150px' }}>
              <Select
                value={form.workUnit}
                name="workUnit"
                onChange={this.onChange}
              >
                <MenuItem value="">なし</MenuItem>
                <MenuItem value="15">15分</MenuItem>
                <MenuItem value="30">30分</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormTitle title="備考" />
            <TextArea
              value={form.biko}
              name="biko"
              onChange={this.onChange}
            />
          </Box>
          {form.id && (
            <Grid container mt={3}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          )}
        </Paper>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  partnerInfo: state.partnerStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(PartnerDetail));
