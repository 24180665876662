/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { projectBalanceActions } from './projectBalanceState';
import {
  getDatas, saveData,
} from '../../../services/base.service';

function* getDetailSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.PROJECT_BALANCE.PROJECT_BALANCE_DETAIL_SELECT, payload);
    yield put(projectBalanceActions.getDetailSelectSuccess(result));
  } catch (_) {}
}

function* getCost({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.PROJECT_BALANCE.PROJECT_BALANCE_DETAIL_COST, payload);
    yield put(projectBalanceActions.getCostSuccess(result));
  } catch (_) {}
}

function* getDetail({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.PROJECT_BALANCE.PROJECT_BALANCE_DETAIL, payload);
    yield put(projectBalanceActions.getDetailSuccess(result));
  } catch (_) {}
}

function* updateDetail({ payload }) {
  try {
    yield call(saveData, REST_API.SUMMARY.PROJECT_BALANCE.PROJECT_BALANCE_DETAIL, payload);
    yield put(projectBalanceActions.updateDetailSuccess());
  } catch (_) {}
}

function* getListSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.PROJECT_BALANCE.PROJECT_BALANCE_SELECT, payload);
    yield put(projectBalanceActions.getListSelectSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.PROJECT_BALANCE.PROJECT_BALANCE, payload);
    yield put(projectBalanceActions.getDataSuccess(result));
  } catch (_) {}
}

function* projectBalanceSaga() {
  yield takeLatest('projectBalance/getCost', getCost);
  yield takeLatest('projectBalance/getDetailSelect', getDetailSelect);
  yield takeLatest('projectBalance/getDetail', getDetail);
  yield takeLatest('projectBalance/updateDetail', updateDetail);
  yield takeLatest('projectBalance/getListSelect', getListSelect);
  yield takeLatest('projectBalance/getData', getData);
}

export default projectBalanceSaga;
