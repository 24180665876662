import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box, LinearProgress,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import urls from '../../../../constants/frontUrls';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../../components/atoms/tableHeaderCell/TableHeaderCell';
import { employeeActions } from '../../../../redux/office/employee/employeeState';
import Link from '../../../../components/atoms/link/Link';
import EmployeeCreateDialog from './EmployeeCreateDialog';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleSelectAllClick = (event) => {
    const { rows } = props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.filter((data) => !data.delNotFlg).map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const headCells = [
    { id: 'id', label: '社員ID' },
    { id: 'syain_mei', label: '名前' },
    { id: 'department', label: '部' },
    { id: 'division', label: '課' },
    { id: 'rank', label: 'ランク' },
  ];

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    loading,
    disabled,
  } = props;

  const onAdd = () => {
    setAddOpen(true);
  };

  const onAddClose = () => {
    setAddOpen(false);
  };

  const handleClose = () => {
    setConfOpen(false);
  };

  const onClickDelete = () => {
    dispatch(employeeActions.deleteData({ ids: selected }));
    setConfOpen(false);
    setSelected([]);
  };

  const onClickConfirmDelete = () => {
    setConfOpen(true);
  };

  const addProc = (
    <>
      <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
      {!disabled && (
        <Tooltip title="新規作成">
          <Button color="primary" variant="outlined" onClick={onAdd}>新規作成</Button>
        </Tooltip>
      )}
    </>
  );

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Box mr={2}>
                <Tooltip title="削除">
                  <Button color="secondary" variant="contained" onClick={onClickConfirmDelete}>削除</Button>
                </Tooltip>
              </Box>
            </>
          ) : addProc}
        </Toolbar>
        {loading && (<LinearProgress />)}
        <TableContainer sx={{ maxHeight: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!disabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((data) => !data.delNotFlg).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((data) => !data.delNotFlg).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((data) => !data.delNotFlg).length === 0}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!disabled && (
                      <TableCell padding="checkbox">
                        {!row.delNotFlg && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell>
                      <Link to={urls.EMPLPYEE_DETAIL + row.id}>{row.id}</Link>
                    </TableCell>
                    <TableCell>{row.syainMei}</TableCell>
                    <TableCell>{row.department}</TableCell>
                    <TableCell>{row.division}</TableCell>
                    <TableCell>{row.rank}</TableCell>
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={6}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <EmployeeCreateDialog isOpen={addOpen} onClickClose={onAddClose} />
        <ConfirmDialog
          open={confOpen}
          isError
          onClose={handleClose}
          title="選択した社員の削除"
          content="選択した社員を削除します"
          onClick={onClickDelete}
          procName="削除する"
        />
      </Paper>
    </Box>
  );
}
