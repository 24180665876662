import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Switch, FormControlLabel,
} from '@mui/material';
import getColumn from './estimate-proc';
import DataGridSheet from '../../../components/templates/dataGridSheet/DataGridSheet';
import { estimateDetailActions } from '../../../redux/sales/estimate/estimateDetailState';
import DetailTable from '../../../components/templates/detailTable/DetailTable';

class EstimateDetailInput extends React.Component {
  constructor(props) {
    super(props);
    this.columns = getColumn();
    this.dataGridRef = React.createRef();
    this.state = {
      datas: [],
      checked: false,
    };
    props.dispatch(estimateDetailActions.getDatas({ estimateId: props.id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estimateDetailInfo.datas !== this.props.estimateDetailInfo.datas) {
      if (this.props.estimateDetailInfo.datas) {
        this.setDatas(this.props.estimateDetailInfo.datas);
      }
    }
    if (prevProps.estimateDetailInfo.isSaveSuccess
      !== this.props.estimateDetailInfo.isSaveSuccess) {
      if (this.props.estimateDetailInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setDataGridRef = (ref) => {
    this.dataGridRef = ref;
  };

  onSave = (datas) => {
    this.props.dispatch(estimateDetailActions.saveData({
      estimateId: this.props.id,
      datas,
    }));
  };

  setSuccess = () => {
    this.props.onSaveSuccess();
    this.props.dispatch(estimateDetailActions.getDatas({ estimateId: this.props.id }));
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const { datas, checked } = this.state;
    const { isDisabled } = this.props;
    return (
      <Box>
        {!isDisabled && (
          <Box textAlign="right">
            <FormControlLabel control={<Switch checked={checked} onChange={this.handleChange} />} label="Excel入力" />
          </Box>
        )}
        {(!isDisabled && checked) && (
          <DataGridSheet
            data={datas}
            isDisabled={isDisabled}
            onSave={this.onSave}
            dataGridRef={this.dataGridRef}
            setDataGridRef={this.setDataGridRef}
            columns={this.columns}
          />
        )}

        {!checked && (
          <DetailTable datas={datas} isDisabled={isDisabled} onSave={this.onSave} />
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  estimateDetailInfo: state.estimateDetailStore,
});

export default connect(mapStateToProps)(EstimateDetailInput);
