import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField as MateralTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextField = styled(MateralTextField)(() => ({
  '& .MuiOutlinedInput-input': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: '3px',
    paddingLeft: '3px',
    textAlign: 'right',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    '-webkit-text-fill-color': '#000',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px',
  },
  '& .Mui-disabled': {
    color: '#000',
    background: 'rgba(0, 0, 0, 0.03)',
  },
}));

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value.formattedValue,
          },
        });
      }}
      thousandSeparator
    />
  );
});

export default function SmallMoneyInput(props) {
  const {
    value, onChange, fullWidth, error, name, disabled, onBlur,
  } = props;
  return (
    <TextField
      error={error}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      fullWidth={fullWidth}
      autoComplete="off"
      variant="outlined"
      disabled={disabled}
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
}
