import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import DeleteConfirmDialog from '../../../components/templates/deleteConfirmDialog/DeleteConfirmDialog';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import urls from '../../../constants/frontUrls';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import './login.css';
import { loginUserActions } from '../../../redux/setting/loginUser/loginUserState';
import LoginUserDialog from './LoginUserDialog';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [deleteConfopen, setDeleteConfopen] = React.useState(false);
  const [confOpen, setConfOpen] = React.useState(false);
  const [detailModalOpen, setDetailModalOpen] = React.useState(false);
  const isSaveSuccess = useSelector((state) => state.roleDetailStore.isSaveSuccess);
  const saveId = useSelector((state) => state.roleDetailStore.saveId);
  const userInfo = useSelector((state) => state.loginStore.userInfo).user;
  const navigate = useNavigate();
  const isDisabled = checkIsDisabled(userInfo);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isSaveSuccess) {
      navigate(urls.ROLE_DETAIL + saveId);
    }
  }, [isSaveSuccess]);

  const {
    rows, totalElements,
    rowsPerPage, page, handleChangePage,
    handleChangeRowsPerPage, handleRequestSort,
    order, orderBy,
  } = props;

  const headCells = [
    { id: 'user_id', label: 'ユーザID' },
    { id: 'syain_name', label: '名前' },
    { id: 'login_id', label: 'ログインID' },
    { id: 'role_name', label: '権限' },
    { id: 'slack_id', label: 'SlackID' },
  ];

  const handleSelectAllClick = (event) => {
    let selectedData = [];
    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const onResetConf = (_, row) => {
    setConfOpen(true);
    setSelectedRow(row);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const onPreDelete = () => {
    setDeleteConfopen(true);
  };

  const onDelete = () => {
    setDeleteConfopen(false);
    dispatch(loginUserActions.deleteData({ ids: selected }));
    setSelected([]);
  };

  const onReset = () => {
    setConfOpen(false);
    dispatch(loginUserActions.reset(selectedRow));
  };

  const handleClose = () => {
    setDeleteConfopen(false);
    setDetailModalOpen(false);
    setConfOpen(false);
  };

  const onDetailOpen = (_, row) => {
    setDetailModalOpen(true);
    setSelectedRow(row);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Tooltip title="ログインユーザの削除">
                <Button color="secondary" variant="outlined" onClick={onPreDelete} disabled={isDisabled}>ログインユーザの削除</Button>
              </Tooltip>
            </>
          )}
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!isDisabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((row) => row.deleteable).length === 0 || isDisabled}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                <TableCell style={{ width: '1px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    className="tableRow"
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!isDisabled && (
                      <TableCell padding="checkbox">
                        {row.deleteable && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                            disabled={isDisabled}
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.syainName}</TableCell>
                    <TableCell>{row.loginId}</TableCell>
                    <TableCell>{row.roleName}</TableCell>
                    <TableCell>{row.slackId}</TableCell>
                    <td className="customTd">
                      {!isDisabled && (
                        <div className="customButton">
                          <Button style={{ marginRight: '10px' }} onClick={(e) => onResetConf(e, row)}>パスワードリセット</Button>
                          <Button onClick={(e) => onDetailOpen(e, row)}>変更</Button>
                        </div>
                      )}
                    </td>
                  </TableRow>
                );
              })}
              {rows.length < 1 && (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={7}>
                    データがありません
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <DeleteConfirmDialog
          open={deleteConfopen}
          onClose={handleClose}
          onDelete={onDelete}
          procName="ログインユーザ"
        />
        <ConfirmDialog
          open={confOpen}
          onClose={handleClose}
          title="パスワードリセット"
          content={`${selectedRow.syainName}のパスワードをリセットします`}
          onClick={onReset}
          procName="リセットする"
        />
        <LoginUserDialog
          isOpen={detailModalOpen}
          onClose={handleClose}
          row={selectedRow}
        />
      </Paper>
    </div>
  );
}
