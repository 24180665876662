import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { assetRequestListActions } from './assetRequestListState';
import { getDatas, saveData } from '../../../services/base.service';

function* getAssetCodes() {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CODES, { code: 'ASSET_KBN' });
    yield put(assetRequestListActions.getAssetCodesSuccess(result));
  } catch (_) {}
}

function* getCodes() {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CODES, { code: 'ASSET_STAT' });
    yield put(assetRequestListActions.getCodesSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.ASSET_REQUEST.ASSET_REQUEST, payload);
    yield put(assetRequestListActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveReturned({ payload }) {
  try {
    yield call(saveData, REST_API.REQUEST.ASSET_REQUEST.ASSET_REQUEST_CONFIRM, payload);
    yield put(assetRequestListActions.saveReturnedSuccess());
  } catch (_) {}
}

function* assetRequestListSaga() {
  yield takeLatest('assetRequestList/getAssetCodes', getAssetCodes);
  yield takeLatest('assetRequestList/getCodes', getCodes);
  yield takeLatest('assetRequestList/getDatas', getData);
  yield takeLatest('assetRequestList/saveReturned', saveReturned);
}

export default assetRequestListSaga;
