import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MaterialDialog from '../../atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../atoms/dialogColorTitle/DialogColorTitle';

export default function DeleteConfirmDialog(props) {
  const {
    open,
    procName,
    onClose,
    onDelete,
    loading,
  } = props;

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle isDelete>{`選択した${procName}の削除`}</DialogColorTitle>
      <DialogContent dividers>{`選択した${procName}を削除します。この操作は元に戻すことができません。`}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={onDelete}
          loading={loading}
          color="secondary"
        >
          削除する
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
