import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Container,
} from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { certificateCampaignActions } from '../../../redux/office/certificateCampaign/certificateCampaignState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { employeeLicenseHistoryActions } from '../../../redux/office/employee/employeeLicenseHistoryState';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';

class CertificateCampaignList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'certificate_name', label: '資格名' },
      { id: 'started_on', label: '開始日' },
      { id: 'ended_on', label: '終了日' },
      { id: 'payment_amount', label: '支給額' },
      { id: 'count_number', label: '対象人数' },
    ];

    let searchCondition = {
      targetDate: '',
      certificates: [],
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.certificateCampaignInfo.searchCondition) {
      searchCondition = props.certificateCampaignInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      codes: [],
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    props.dispatch(employeeLicenseHistoryActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.certificateCampaignInfo.datas !== this.props.certificateCampaignInfo.datas) {
      if (this.props.certificateCampaignInfo.datas) {
        this.setDatas(this.props.certificateCampaignInfo.datas);
      }
    }
    if (prevProps.employeeLicenseHistoryInfo.selects
      !== this.props.employeeLicenseHistoryInfo.selects) {
      if (this.props.employeeLicenseHistoryInfo.selects) {
        this.setCodes(this.props.employeeLicenseHistoryInfo.selects);
      }
    }
    if (prevProps.certificateCampaignInfo.isDeleteSuccess
      !== this.props.certificateCampaignInfo.isDeleteSuccess) {
      if (this.props.certificateCampaignInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setCodes = (codes) => {
    this.setState({ codes });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(certificateCampaignActions.setSearchCondition(searchCondition));
    this.props.dispatch(certificateCampaignActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(certificateCampaignActions.deleteData({ ids }));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      codes,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="資格" />
              <SearchMultipleSelectBox
                name="certificates"
                options={codes}
                multiple
                values={searchCondition.certificates}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="基準日" />
              <DatePicker
                handleChange={this.onChange}
                name="targetDate"
                value={searchCondition.targetDate}
              />
            </Grid>
          </Grid>
        </SearchBox>
        <DeleteDataTable
          url={urls.CERTIFICATE_CAMPAIGN_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="資格キャンペーン"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  certificateCampaignInfo: state.certificateCampaignStore,
  employeeLicenseHistoryInfo: state.employeeLicenseHistoryStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(CertificateCampaignList);
