/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const companyBalanceSlice = createSlice({
  name: 'companyBalance',
  initialState: {
    data: {},
  },
  reducers: {
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const companyBalanceActions = companyBalanceSlice.actions;

export default companyBalanceSlice.reducer;
