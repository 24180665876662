import { call, put, takeLatest } from 'redux-saga/effects';
import { workClockActions } from './workClockState';
import { getDatas, saveData } from '../../../services/base.service';
import { REST_API } from '../../../constants/serverUrls';

function* getData() {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_CLOCK);
    yield put(workClockActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.WORK.WORK_CLOCK, payload);
    yield put(workClockActions.saveDataSuccess());
  } catch (_) {}
}

function* workClockSaga() {
  yield takeLatest('workClock/getDatas', getData);
  yield takeLatest('workClock/saveData', saveDatas);
}

export default workClockSaga;
