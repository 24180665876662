/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const salaryDetailSlice = createSlice({
  name: 'salaryDetail',
  initialState: {
    data: {},
    isSaveSuccess: false,
  },
  reducers: {
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetFlg: (state) => {
      state.isInsertSuccess = false;
    },
    insertData: (state) => {
      state.isInsertSuccess = false;
    },
    insertDataSuccess: (state, action) => {
      state.isInsertSuccess = true;
      state.saveId = action.payload;
    },
    updateData: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    recalc: () => {},
    recalcInsurance: () => {},
  },
});

export const salaryDetailActions = salaryDetailSlice.actions;

export default salaryDetailSlice.reducer;
