import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { TextField, Grid, Container } from '@mui/material';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { fixedExpenseActions } from '../../../redux/office/fixedExpense/fixedExpenseState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import FixedExpenseDialog from './FixedExpenseDialog';

class FixedExpenseList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'subject_code', label: 'コード' },
      { id: 'subject_sub_code', label: 'サブコード' },
      { id: 'subject_name', label: '名前' },
      { id: 'cost', label: '金額' },
      { id: 'period_from', label: '適用開始' },
      { id: 'period_to', label: '適用終了' },
    ];

    const searchCondition = {
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
      subjectCode: '',
      subjectName: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };
    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isDetailOpen: false,
      selectedId: null,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fixedExpense.datas !== this.props.fixedExpense.datas) {
      if (this.props.fixedExpense.datas) {
        this.setDatas(this.props.fixedExpense.datas);
      }
    }
    if (prevProps.fixedExpense.isDeleteSuccess !== this.props.fixedExpense.isDeleteSuccess) {
      if (this.props.fixedExpense.isDeleteSuccess) {
        this.setSuccess('権限の削除が完了しました');
      }
    }
    if (prevProps.fixedExpense.isSaveSuccess !== this.props.fixedExpense.isSaveSuccess) {
      if (this.props.fixedExpense.isSaveSuccess) {
        this.setSuccess('保存が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage, isDetailOpen: false });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(fixedExpenseActions.getDatas(searchCondition));
  };

  onDelete = (ids) => {
    this.props.dispatch(fixedExpenseActions.deleteData({ ids }));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  onAddOpen = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, selectedId: null });
  };

  onDetailOpen = (_, row) => {
    this.setState({ isDetailOpen: true, selectedId: row.id, isSuccessOpen: false });
  };

  render() {
    const {
      datas, searchCondition, isDisabled, isDetailOpen,
      isSuccessOpen, successMessage, selectedId,
    } = this.state;
    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12} md={3}>
              <TextField name="subjectCode" placeholder="勘定科目コード" size="small" variant="outlined" autoComplete="off" fullWidth value={searchCondition.subjectCode} onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField name="subjectName" placeholder="勘定科目名" size="small" variant="outlined" autoComplete="off" fullWidth value={searchCondition.subjectName} onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <MonthPicker
                handleChange={this.onChange}
                value={searchCondition.targetMonth}
                name="targetMonth"
              />
            </Grid>
          </Grid>
        </SearchBox>

        <DeleteDataTable
          rows={datas.content || []}
          headCells={this.headCells}
          procName="固定経費"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
          onDetailOpen={this.onDetailOpen}
          onAddOpen={this.onAddOpen}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
        <FixedExpenseDialog
          isOpen={isDetailOpen}
          id={selectedId}
          onClose={this.onClose}
          isDisabled={isDisabled}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  fixedExpense: state.fixedExpenseStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(FixedExpenseList);
