import React from 'react';
import { connect } from 'react-redux';
import { Container, Box, TextField } from '@mui/material';
import { format } from 'date-fns';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { employeeActions } from '../../../../redux/office/employee/employeeState';
import MonthPicker from '../../../../components/atoms/monthPicker/MonthPicker';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import { checkIsDisabled } from '../../../../utils/authCheck.helper';

class EmployeeList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.employeeInfo.searchCondition) {
      searchCondition = props.employeeInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isLoading: true,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employeeInfo.data !== this.props.employeeInfo.data) {
      if (this.props.employeeInfo.data) {
        this.setDatas(this.props.employeeInfo.data);
      }
    }
    if (prevProps.employeeInfo.isDeleteSuccess !== this.props.employeeInfo.isDeleteSuccess) {
      if (this.props.employeeInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas, isLoading: false });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage, isLoading: false });
  }

  onProcStart = () => {
    this.setState({ isLoading: true, isSuccessOpen: false });
  };

  onChange = (event, isSearch) => {
    const { value, name } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    if (isSearch) {
      this.search(tempCondition);
    }
  };

  onBlur = () => {
    this.onSearch();
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isLoading: true });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(employeeActions.setSearchCondition(searchCondition));
    this.props.dispatch(employeeActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isLoading,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box display="flex">
          <Box>
            <FormTitle title="基準月" />
            <MonthPicker value={searchCondition.targetMonth} name="targetMonth" handleChange={(e) => this.onChange(e, true)} />
          </Box>
          <Box ml={1}>
            <FormTitle title="社員名" />
            <TextField value={searchCondition.syainName} name="syainName" onChange={(e) => this.onChange(e, false)} onBlur={this.onBlur} />
          </Box>
        </Box>
        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          loading={isLoading}
          disabled={isDisabled}
          onProcStart={this.onProcStart}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeInfo: state.employeeStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(EmployeeList);
