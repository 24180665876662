/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workResultInputSlice = createSlice({
  name: 'workResultInput',
  initialState: {
    selects: {},
    projects: [],
    data: {},
    isLoading: false,
    workTm: null,
    isSaveSuccess: false,
    costList: [],
    isFixSuccess: false,
  },
  reducers: {
    getSelects: () => {},
    getSelectsSuccess: (state, action) => {
      state.selects = action.payload;
    },
    getProjects: () => {},
    getProjectsSuccess: (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    postPin: (state) => {
      state.isLoading = true;
    },
    recalcWorkTime: (state) => {
      state.workTm = 0;
    },
    recalcWorkTimeSuccess: (state, action) => {
      state.workTm = action.payload;
    },
    saveWorkTime: (state) => {
      state.isSaveSuccess = false;
    },
    saveWorkTimeSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    copyCosts: (state) => {
      state.costList = [];
    },
    copyCostsSuccess: (state, action) => {
      state.costList = action.payload;
    },
    fixWorkTime: (state) => {
      state.isFixSuccess = false;
    },
    fixWorkTimeSuccess: (state) => {
      state.isFixSuccess = true;
    },
  },
});

export const workResultInputActions = workResultInputSlice.actions;

export default workResultInputSlice.reducer;
