/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSettingSlice = createSlice({
  name: 'userSetting',
  initialState: {
    isSuccess: false,
    data: {},
  },
  reducers: {
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    getSelect: (state) => {
      state.selects = null;
    },
    getSelectSuccess: (state, action) => {
      state.selects = action.payload;
    },
    updateData: (state) => {
      state.isSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isSuccess = true;
    },
  },
});

export const userSettingActions = userSettingSlice.actions;

export default userSettingSlice.reducer;
