export function getApprovalCount(datas, selected, userInfo) {
  if (!userInfo || !userInfo.approval) {
    return 0;
  }
  const targets = [];
  selected.forEach((c) => {
    datas.forEach((v) => {
      if (c === v.id) {
        targets.push(v);
      }
    });
  });
  return targets.filter((v) => v.applyStatusCode === '0').length;
}

export function getCertificateCount(datas, selected, userInfo) {
  if (!userInfo || !userInfo.affairs) {
    return 0;
  }
  const targets = [];
  selected.forEach((c) => {
    datas.forEach((v) => {
      if (c === v.id) {
        targets.push(v);
      }
    });
  });
  return targets.filter((v) => v.attendanceKindCode === 'B' && !v.certificate && (v.applyStatusCode === '0' || v.applyStatusCode === '1')).length;
}

export function isCheckableCount(datas, userInfo) {
  if (!userInfo) {
    return 0;
  }
  let appDatas = datas.filter((v) => v.applyStatusCode === '0').length;
  if (!userInfo.approval) {
    appDatas = 0;
  }
  let certifyDatas = datas.filter((v) => v.attendanceKindCode === 'B' && !v.certificate).length;
  if (!userInfo.affairs) {
    certifyDatas = 0;
  }
  return appDatas + certifyDatas;
}

export function isNotDisabled(data, userInfo) {
  if (!userInfo || (!userInfo.approval && !userInfo.affairs)) {
    return false;
  }
  return data.applyStatusCode === '0' || (data.attendanceKindCode === 'B' && !data.certificate && (data.applyStatusCode === '0' || data.applyStatusCode === '1'));
}
