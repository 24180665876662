import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button,
  Tabs, Tab, Menu, MenuItem,
} from '@mui/material';
import { InsertDriveFile as InsertDriveFileIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { estimateActions } from '../../../redux/sales/estimate/estimateState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import EstimateDialog from './EstimateDialog';
import CreateEstimatePdf from './file/CreateEstimatePdf';
import ClaimList from './ClaimList';
import InspectList from './InspectList';
import EstimateDetailInput from './EstimateDetailInput';
import ApprovalHistory from '../approvalHistory/ApprovalHistory';
import ErrorDialog from '../../../components/templates/errorDialog/ErrorDialog';
import Link from '../../../components/atoms/link/Link';
import urls from '../../../constants/frontUrls';
import PjAppStatus from '../../../components/templates/pjAppStatus/PjAppStatus';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';

class EstimateDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.estimateInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(estimateActions.resetFlg());

    if (props.estimateInfo.isCopySuccess) {
      isSuccessOpen = true;
      successMessage = 'コピーが完了しました';
    }
    props.dispatch(estimateActions.resetFlg());

    this.state = {
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      isAffairs: props.loginUser.userInfo.user.affairs,
      successMessage,
      isSuccessOpen,
      tabValue: 0,
      isConfOpen: false,
      form: { id },
      isDetailOpen: false,
      isPdfOpen: false,
      isError: false,
      approvalStatus: '',
      fileAnchorEl: null,
      copyAnchorEl: null,
      fileType: '1',
    };
    if (id) {
      props.dispatch(estimateActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estimateInfo.data !== this.props.estimateInfo.data) {
      if (this.props.estimateInfo.data) {
        this.setDatas(this.props.estimateInfo.data);
      }
    }
    if (prevProps.estimateInfo.isStatusSuccess !== this.props.estimateInfo.isStatusSuccess) {
      if (this.props.estimateInfo.isStatusSuccess) {
        this.setSuccess();
      }
    }
    if (prevProps.estimateInfo.isCopySuccess !== this.props.estimateInfo.isCopySuccess) {
      if (this.props.estimateInfo.isCopySuccess) {
        this.copySuccess('M');
      }
    }
    if (prevProps.estimateInfo.isCopyToInspectSuccess
      !== this.props.estimateInfo.isCopyToInspectSuccess) {
      if (this.props.estimateInfo.isCopyToInspectSuccess) {
        this.copySuccess('N');
      }
    }
    if (prevProps.estimateInfo.isCopyToClaimSuccess
      !== this.props.estimateInfo.isCopyToClaimSuccess) {
      if (this.props.estimateInfo.isCopyToClaimSuccess) {
        this.copySuccess('S');
      }
    }
  }

  copySuccess = (target) => {
    let url = urls.ESTIMATE_DETAIL + this.props.estimateInfo.copyId;
    if (target === 'N') {
      url = urls.INSPECT_DETAIL + this.props.estimateInfo.copyId;
    }
    if (target === 'S') {
      url = urls.CLAIM_DETAIL + this.props.estimateInfo.copyId;
    }
    window.open(url, '_blank');
  };

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(estimateActions.getData({ id: form.id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  setSuccess = () => {
    const { form, approvalStatus } = this.state;
    this.props.dispatch(estimateActions.getData({ id: form.id }));
    let msg = '承認';
    if (approvalStatus === '1') {
      msg = '承認依頼';
    }
    if (approvalStatus === '0') {
      msg = '承認取下';
    }
    this.setState({
      isSuccessOpen: true,
      successMessage: `${msg}が完了しました`,
    });
  };

  onUpdate = (_, approvalStatus, ball) => {
    const { form } = this.state;
    if (!form.projectOwner || !form.managerId) {
      this.setState({ isError: true });
      return;
    }
    this.props.dispatch(estimateActions.updateStatus({ id: form.id, approvalStatus, ball }));
    this.setState({ approvalStatus, isSuccessOpen: false, isConfOpen: false });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true });
  };

  onClose = () => {
    this.setState({
      isDetailOpen: false,
      isError: false,
      isConfOpen: false,
      isPdfOpen: false,
    });
  };

  onConfUpdate = () => {
    this.setState({ isConfOpen: true });
  };

  onClickFileEstimateOpen = (event) => {
    this.setState({ fileAnchorEl: event.currentTarget });
  };

  onClickCopyOpen = (event) => {
    this.setState({ copyAnchorEl: event.currentTarget });
  };

  onClickCopyClose = () => {
    this.setState({ copyAnchorEl: null });
  };

  onCopy = (_, target) => {
    const { form } = this.state;
    if (target === '1') {
      this.props.dispatch(estimateActions.copyData({ id: form.id }));
    }
    if (target === '2') {
      this.props.dispatch(estimateActions.copyToInspect({ id: form.id }));
    }
    if (target === '3') {
      this.props.dispatch(estimateActions.copyToClaim({ id: form.id }));
    }
    this.setState({ copyAnchorEl: null });
  };

  onClickFileEstimateClose = () => {
    this.setState({ fileAnchorEl: null });
  };

  onPdfOpen = (_, fileType) => {
    this.setState({ fileType, isPdfOpen: true, fileAnchorEl: null });
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, successMessage, isDisabled, isDetailOpen, isError, isPdfOpen,
      isConfOpen, isAffairs, fileType, fileAnchorEl, copyAnchorEl,
    } = this.state;

    return (
      <Container maxWidth="lg">
        {(form.approvalStatus !== '0' && form.approvalStatus !== '4') && (
          <Box mb={2}>
            <PjAppStatus activeStep={form.approvalStatus} isExclusion={false} />
          </Box>
        )}
        {!isDisabled && (
          <Box p={1} textAlign="right">
            {form.approvalStatus === '0' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '1', form.projectOwner)} style={{ marginRight: '10px' }}>承認依頼</Button>
            )}
            {form.approvalStatus === '1' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '2', form.managerId)} style={{ marginRight: '10px' }}>PJオーナー承認</Button>
            )}
            {form.approvalStatus === '2' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '3', null)} style={{ marginRight: '10px' }}>上長承認</Button>
            )}
            {(form.approvalStatus === '3' && isAffairs) && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '4', null)} style={{ marginRight: '10px' }}>総務承認</Button>
            )}
            {form.approvalStatus !== '0' && (
              <Button variant="contained" color="secondary" size="small" onClick={this.onConfUpdate} style={{ marginRight: '10px' }}>取下</Button>
            )}
            <Button variant="contained" size="small" onClick={this.onDetail} style={{ marginRight: '10px' }}>編集</Button>
            <Button variant="contained" size="small" onClick={this.onClickCopyOpen} startIcon={<ExpandMoreIcon />} style={{ marginRight: '10px' }}>コピーして作成</Button>
            <Menu
              id="basic-menu"
              anchorEl={copyAnchorEl}
              open={copyAnchorEl !== null}
              onClose={this.onClickCopyClose}
            >
              <MenuItem onClick={(e) => this.onCopy(e, '1')}>見積</MenuItem>
              <MenuItem onClick={(e) => this.onCopy(e, '2')}>検収</MenuItem>
              <MenuItem onClick={(e) => this.onCopy(e, '3')}>請求</MenuItem>
            </Menu>
            <Button variant="contained" size="small" onClick={this.onClickFileEstimateOpen} startIcon={<InsertDriveFileIcon />}>見積書作成</Button>
            <Menu
              id="basic-menu"
              anchorEl={fileAnchorEl}
              open={fileAnchorEl !== null}
              onClose={this.onClickFileEstimateClose}
            >
              <MenuItem onClick={(e) => this.onPdfOpen(e, '1')}>見積書</MenuItem>
              <MenuItem onClick={(e) => this.onPdfOpen(e, '2')}>見積書 兼 注文書</MenuItem>
            </Menu>
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="注文番号" />
              <Box style={{ wordWrap: 'break-word' }}>{form.chumonNo}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="プロジェクト" />
              <Box>
                <Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="注文金額" />
              <Box>{form.hachuKingaku}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="承認" />
              <Box>{form.approvalStatusName}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="状況" />
              <Box>{form.chumonStatusName}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="明細" />
            <Tab label="検収" />
            <Tab label="請求" />
            <Tab label="承認履歴" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} isAll>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTitle title="注文番号" />
                <Box style={{ wordWrap: 'break-word' }}>{form.chumonNo}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="承認" />
                <Box>{form.approvalStatusName}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="契約開始" />
                <Box>{form.sagyoStDt}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="契約終了" />
                <Box>{form.sagyoEdDt}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="状況" />
                <Box>{form.chumonStatusName}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="消費税" />
                <Box>{`${form.taxType}%`}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="プロジェクト" />
                <Box><Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link></Box>
              </Grid>
              <Grid item md={6}>
                <FormTitle title="顧客提出" />
                <Box>{form.filingOn}</Box>
              </Grid>
              <Grid item md={6}>
                <FormTitle title="受注日" />
                <Box>{form.orderDate}</Box>
              </Grid>
              {form.contractKbn === '3' && (
                <Grid item md={6}>
                  <FormTitle title="契約時間" />
                  <Box>{form.contractYears}</Box>
                </Grid>
              )}
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTitle title="見積日" />
                <Box>{form.estimateDate}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="納期" />
                <Box>{form.deliveryDate}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="見積期限" />
                <Box>{form.deadline}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="支払条件" />
                <Box>{form.paymentTerm}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="件名" />
                {form.title && form.title.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="送付先担当" />
                <Box>{form.contactName}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="見積書用備考" />
                {form.externalBiko && form.externalBiko.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <FormTitle title="注文金額(税抜)" />
                <Box>{form.hachuKingaku}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="消費税" />
                <Box>{form.tax}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="注文金額(税込)" />
                <Box>{form.hachuKingakuIncluded}</Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTitle title="格納パス" />
                <Box style={{ wordWrap: 'break-word' }}>{form.storagePath}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="備考" />
                {form.biko && form.biko.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>

        <TabPanel value={tabValue} index={1} isAll>
          <EstimateDetailInput
            id={form.id}
            onSaveSuccess={this.onSaveSuccess}
            isDisabled={isDisabled || form.approvalStatus === '4'}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={2} isAll>
          <InspectList parentId={form.id} projectId={form.projectId} />
        </TabPanel>

        <TabPanel value={tabValue} index={3} isAll>
          <ClaimList parentId={form.id} projectId={form.projectId} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <ApprovalHistory estimateId={form.id} />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <EstimateDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          estimateId={form.id}
          onSaveSuccess={this.onSaveSuccess}
        />
        <CreateEstimatePdf
          isOpen={isPdfOpen}
          onClose={this.onClose}
          estimateId={form.id}
          fileType={fileType}
        />
        <ErrorDialog
          open={isError}
          onClose={this.onClose}
          title="エラー"
          content="プロジェクトオーナーまたは担当部が入力されていません"
        />
        <ConfirmDialog
          isError
          open={isConfOpen}
          onClose={this.onClose}
          title="取下"
          content="取下処理を行います。"
          onClick={(e) => this.onUpdate(e, '0', null)}
          procName="取下"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  estimateInfo: state.estimateStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(EstimateDetail));
