/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { assetRequestDetailActions } from './assetRequestDetailState';
import {
  getDataById, saveData, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.REQUEST.ASSET_REQUEST.ASSET_REQUEST_ID, payload);
    yield put(assetRequestDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.ASSET_REQUEST.ASSET_REQUEST, payload);
    yield put(assetRequestDetailActions.saveDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    yield call(updateData, REST_API.REQUEST.ASSET_REQUEST.ASSET_REQUEST_ID, payload);
    yield put(assetRequestDetailActions.updateDatasSuccess());
  } catch (_) {}
}

function* returnDate({ payload }) {
  try {
    yield call(updateData, REST_API.REQUEST.ASSET_REQUEST.ASSET_REQUEST_RETURN, payload);
    yield put(assetRequestDetailActions.returnDateSuccess());
  } catch (_) {}
}

function* assetRequestDetailSaga() {
  yield takeLatest('assetRequestDetail/getData', getData);
  yield takeLatest('assetRequestDetail/saveData', saveDatas);
  yield takeLatest('assetRequestDetail/updateDatas', updateDatas);
  yield takeLatest('assetRequestDetail/returnDate', returnDate);
}

export default assetRequestDetailSaga;
