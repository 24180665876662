import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  Container, Grid, Box, Paper, Button,
  Tabs, Tab,
} from '@mui/material';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../../components/atoms/tabPanel/TabPanel';
import { payActions } from '../../../../redux/sales/pay/payState';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../../utils/authCheck.helper';
import PayDialog from '../PayDialog';
import ApprovalHistory from '../../approvalHistory/ApprovalHistory';
import Link from '../../../../components/atoms/link/Link';
import urls from '../../../../constants/frontUrls';
import PayDetailList from './PayDetailList';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';

class PayDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.payInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(payActions.resetFlg());

    this.state = {
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      successMessage,
      isSuccessOpen,
      tabValue: 0,
      form: { payId: id, deleteable: false },
      isDetailOpen: false,
      isConfOpen: false,
    };
    if (id) {
      props.dispatch(payActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payInfo.data !== this.props.payInfo.data) {
      if (this.props.payInfo.data) {
        this.setDatas(this.props.payInfo.data);
      }
    }
    if (prevProps.payInfo.isStatusSuccess !== this.props.payInfo.isStatusSuccess) {
      if (this.props.payInfo.isStatusSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess = () => {
    const { form, approvalStatus } = this.state;
    this.props.dispatch(payActions.getData({ id: form.id }));
    let msg = '承認';
    if (approvalStatus === '1') {
      msg = '承認依頼';
    }
    if (approvalStatus === '0') {
      msg = '承認取下';
    }
    this.setState({
      isSuccessOpen: true,
      successMessage: `${msg}が完了しました`,
    });
  };

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(payActions.getData({ id: form.id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  onResearch = () => {
    const { form } = this.state;
    this.props.dispatch(payActions.getData({ id: form.id }));
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false, isConfOpen: false });
  };

  onUpdate = (_, approvalStatus) => {
    const { form } = this.state;
    this.props.dispatch(payActions.updateStatus({ id: form.id, approvalStatus }));
    this.setState({ isSuccessOpen: false, approvalStatus, isConfOpen: false });
  };

  onConfUpdate = () => {
    this.setState({ isConfOpen: true });
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, successMessage, isDisabled, isDetailOpen, isConfOpen,
    } = this.state;

    return (
      <Container maxWidth="lg">
        {(!isDisabled && form.deleteable) && (
          <Box p={1} textAlign="right">
            {form.approvalStatus === '0' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '1')} style={{ marginRight: '10px' }}>承認依頼</Button>
            )}
            {form.approvalStatus === '1' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '4', form.managerId)} style={{ marginRight: '10px' }}>総務承認</Button>
            )}
            {form.approvalStatus !== '0' && (
              <Button variant="contained" color="secondary" size="small" onClick={this.onConfUpdate} style={{ marginRight: '10px' }}>取下</Button>
            )}
            <Button variant="contained" size="small" onClick={this.onDetail}>編集</Button>
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="支払番号" />
              <Box>{form.payNo}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="計上月" />
              <Box>{form.seikyuDt ? format(new Date(form.seikyuDt), 'yyyy/MM') : ''}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="金額" />
              <Box>{form.kingaku}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="承認" />
              <Box>{form.approvalStatusName}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="明細" />
            <Tab label="承認履歴" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormTitle title="支払番号" />
                <Box>{form.payNo}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="承認" />
                <Box>{form.approvalStatusName}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="計上月" />
                <Box>{form.seikyuDt ? format(new Date(form.seikyuDt), 'yyyy/MM') : ''}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="支払予定" />
                <Box>{form.nyukinDt}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="プロジェクト" />
                <Box><Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="発注" />
                <Box><Link to={urls.ORDER_DETAIL + form.orderId}>{form.orderNo}</Link></Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <FormTitle title="支払額" />
                <Box>{form.kingaku}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="消費税" />
                <Box>{form.shohizei}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="合計額" />
                <Box>{form.gokeiKingaku}</Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormTitle title="支払確認者" />
                <Box>{form.checkedName}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="支払日" />
                <Box>{form.checkedOn}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1} isAll>
          <PayDetailList targetMonth={form.seikyuDt} payId={form.id} isDisabled={isDisabled || form.approvalStatus !== '0' || !form.deleteable} onResearch={this.onResearch} />
        </TabPanel>
        <TabPanel value={tabValue} index={2} isAll>
          <ApprovalHistory payId={form.id} />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <PayDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          payId={form.id}
          onSaveSuccess={this.onSaveSuccess}
        />
        <ConfirmDialog
          isError
          open={isConfOpen}
          onClose={this.onClose}
          title="取下"
          content="取下処理を行います。"
          onClick={(e) => this.onUpdate(e, '0', null)}
          procName="取下"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  payInfo: state.payStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(PayDetail));
