import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button,
} from '@mui/material';
import { format } from 'date-fns';
import TextArea from '../../../components/atoms/textArea/TextArea';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import AuthorizerList from '../../../components/organisms/authorizerList/AuthorizerList';
import withParams from '../../../components/atoms/withParam/WithParams';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { overtimeRequestDetailActions } from '../../../redux/request/overtimeRequest/overtimeRequestDetailState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import TimeInput from '../../../components/atoms/timeInput/TimeInput';

class OvertimeRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      isAcceptable: props.loginUser.userInfo.user.approval,
      isDisabled: false,
      successMessage: '',
      isSuccessOpen: false,
      form: {
        id,
        syainId: props.loginUser.userInfo.user.id,
        syainName: props.loginUser.userInfo.user.name,
        overDt: format(new Date(), 'yyyy/MM/dd'),
        overStrTm: '',
        overEndTm: '',
        approverResponsibleId: null,
        overReason: '',
      },
      errorMessages: {
        syainId: '',
        overDt: '',
        overStrTm: '',
        overEndTm: '',
        approverResponsibleId: '',
        overReason: '',
      },
    };
    if (id) {
      props.dispatch(overtimeRequestDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.overtimeRequestDetailInfo.isAcceptable
      !== this.props.overtimeRequestDetailInfo.isAcceptable) {
      this.setAcceptable(this.props.overtimeRequestDetailInfo.isAcceptable);
    }
    if (prevProps.overtimeRequestDetailInfo.data !== this.props.overtimeRequestDetailInfo.data) {
      if (this.props.overtimeRequestDetailInfo.data) {
        this.setDatas(this.props.overtimeRequestDetailInfo.data);
      }
    }
    if (prevProps.overtimeRequestDetailInfo.isSaveSuccess
      !== this.props.overtimeRequestDetailInfo.isSaveSuccess) {
      if (this.props.overtimeRequestDetailInfo.isSaveSuccess) {
        this.setSaveSuccess(this.props.overtimeRequestDetailInfo.saveId);
      }
    }
    if (prevProps.overtimeRequestDetailInfo.isStatusUpdateSuccess
      !== this.props.overtimeRequestDetailInfo.isStatusUpdateSuccess) {
      if (this.props.overtimeRequestDetailInfo.isStatusUpdateSuccess) {
        this.setSaveStatusSuccess();
      }
    }
  }

  setAcceptable = (isAcceptable) => {
    this.setState({ isAcceptable });
  };

  setSaveStatusSuccess = () => {
    const { form, updateStatus } = this.state;
    this.props.dispatch(overtimeRequestDetailActions.getData({ id: form.id }));
    const proc = updateStatus === '1' ? '承認' : '取下';
    this.setState({ isSuccessOpen: true, successMessage: `${proc}処理が完了しました` });
  };

  setSaveSuccess = (id) => {
    this.props.dispatch(overtimeRequestDetailActions.getData({ id }));
    this.setState({ isSuccessOpen: true, successMessage: '申請が完了しました' });
  };

  setDatas = (form) => {
    this.setState({ form, isDisabled: form.applyStatusCd !== '3' });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessages } = this.state;

    const tempForm = {
      ...form,
      [name]: value,
    };
    this.setState({
      form: tempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onSave = () => {
    const { form, errorMessages } = this.state;

    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.overStrTm && !tempMessage.overEndTm) {
      const msg = Validation.compareTimeValidate(form.overStrTm, form.overEndTm);
      if (msg) {
        isError = true;
      }
      tempMessage.overEndTm = msg;
    }

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(overtimeRequestDetailActions.saveData(form));
  };

  onSaveStatus = (_, status) => {
    const { form } = this.state;
    this.setState({ updateStatus: status, isSuccessOpen: false });
    this.props.dispatch(overtimeRequestDetailActions.saveStatus({ id: form.id, status }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      form, errorMessages, isAcceptable, isSuccessOpen, successMessage, isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box p={1} textAlign="right">
          {(!form.id || form.applyStatusCd === '3') && (
            <Button variant="contained" size="small" onClick={this.onSave}>申請</Button>
          )}
          <Box sx={{ flex: '0 0 auto' }}>
            {(form.applyStatusCd === '0' && isAcceptable) && (
              <Button variant="contained" size="small" onClick={(e) => this.onSaveStatus(e, '1')} style={{ marginRight: '5px' }}>承認</Button>
            )}
            {((form.applyStatusCd === '0' || form.applyStatusCd === '1') && isAcceptable) && (
              <Button variant="contained" color="secondary" size="small" onClick={(e) => this.onSaveStatus(e, '3')}>取下</Button>
            )}
          </Box>
        </Box>
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container>
            <Grid item xs={12}>
              <FormTitle title="名前" isRequired />
              {!isAcceptable && (<Box>{form.syainName}</Box>)}
              {isAcceptable && (
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <SelectSyain onChange={this.onChange} isDisabled={isDisabled} targetMonth={format(new Date(), 'yyyy/MM/dd')} name="syainId" error={errorMessages.syainId !== ''} value={form.syainId} />
                    <FormErrorText>{errorMessages.syainId}</FormErrorText>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box mt={2}>
            <FormTitle title="申請日" isRequired />
            <Grid container mt={2}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  handleChange={this.onChange}
                  name="overDt"
                  value={form.overDt}
                  isDisabled={isDisabled || !isAcceptable}
                  error={errorMessages.overDt !== ''}
                  minDate={isAcceptable ? '' : format(new Date(), 'yyyy/MM/dd')}
                  maxDate={isAcceptable ? '' : format(new Date(), 'yyyy/MM/dd')}
                />
                <FormErrorText>{errorMessages.overDt}</FormErrorText>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <FormTitle title="残業時間" isRequired />
            <Box display="flex" alignItems="center">
              <Box>
                <TimeInput name="overStrTm" value={form.overStrTm} disabled={isDisabled} onChange={this.onChange} />
                <FormErrorText>{errorMessages.overStrTm}</FormErrorText>
              </Box>
              <Box px={2}>～</Box>
              <Box>
                <TimeInput name="overEndTm" value={form.overEndTm} disabled={isDisabled} onChange={this.onChange} />
                <FormErrorText>{errorMessages.overEndTm}</FormErrorText>
              </Box>
            </Box>
          </Box>
          <Grid container mt={2}>
            <Grid item xs={12} md={6}>
              <AuthorizerList isRequired label="承認者" onChange={this.onChange} isDisabled={isDisabled} name="approverResponsibleId" error={errorMessages.approverResponsibleId !== ''} value={form.approverResponsibleId} />
              <FormErrorText>{errorMessages.approverResponsibleId}</FormErrorText>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12} md={6}>
              <FormTitle title="理由" isRequired />
              <TextArea
                value={form.overReason}
                name="overReason"
                onChange={this.onChange}
                isDisabled={isDisabled}
              />
              <FormErrorText>{errorMessages.overReason}</FormErrorText>
            </Grid>
          </Grid>
          {form.id && (
            <Grid container mt={2}>
              <Grid item xs={6} md={2}>
                <FormTitle title="申請日" />
                <Box>{form.approveDt}</Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormTitle title="状況" />
                <Box>{form.applyStatusName}</Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormTitle title="承認日" />
                <Box>{form.approveDt}</Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormTitle title="承認者" />
                <Box>{form.approveName}</Box>
              </Grid>
            </Grid>
          )}
        </Paper>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  overtimeRequestDetailInfo: state.overtimeRequestDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(OvertimeRequestDetail));
