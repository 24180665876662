import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import {
  Slide, Dialog, TextField, Box, AppBar, Toolbar, IconButton, Typography, Grid,
  Paper, TableContainer, TableHead, TableRow, Button, Table, TableBody, TableCell,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { projectActions } from '../../../redux/sales/project/projectState';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import SearchBox from '../../../components/templates/searchBox/SearchBox';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const tempCondition = {
  searchKeyword: '',
  searchStatus: ['2', '99'],
  searchContract: ['2'],
  projectOwner: '',
  privateFlg: false,
  targetMonth: format(new Date(), 'yyyy/MM/dd'),
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};
const headCells = [
  { id: 'nick_name', label: '名前' },
  { id: 'client_nick_name', label: '取引先' },
];
export default function ProjectDialog(props) {
  const [condition, setCondition] = React.useState(tempCondition);
  const [rows, setRows] = React.useState([]);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, onSet,
  } = props;
  const data = useSelector((state) => state.projectStore.subDatas) || {};

  React.useEffect(() => {
    if (isOpen) {
      setCondition(tempCondition);
      dispatch(projectActions.getSubData(tempCondition));
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data && data.content) {
      setRows(data.content || []);
    }
  }, [data]);

  const search = (tmpCondition) => {
    dispatch(projectActions.getSubData(tmpCondition));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tCondition = {
      ...condition,
      [name]: value,
      page: 0,
    };
    setCondition(tCondition);
  };

  const onSearch = () => {
    search(condition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tCondition = {
      ...condition,
      page: 0,
      orderBy,
      order,
    };
    setCondition(tCondition);
    search(tempCondition);
  };

  const handleChangePage = (_, newPage) => {
    const tCondition = {
      ...condition,
      page: newPage,
    };
    setCondition(tCondition);
    search(tempCondition);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tCondition = {
      ...condition,
      page: 0,
      records: rowsPerPage,
    };
    setCondition(tCondition);
    search(tempCondition);
  };

  const onClick = (_, row) => {
    onSet({ projectId: row.id, projectName: row.nickName });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            プロジェクト選択
          </Typography>
        </Toolbar>
      </AppBar>
      <Box p={2} style={{ overflowY: 'auto' }} mt={8}>
        <SearchBox onSearch={onSearch}>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} md={6}>
              <FormTitle title="PJ名／取引先名" />
              <TextField value={condition.searchKeyword} fullWidth name="searchKeyword" onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTitle title="対象月" />
              <MonthPicker value={condition.targetMonth} name="targetMonth" handleChange={onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <Paper>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table
              size="medium"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableHeaderCell
                      headCell={headCell}
                      orderBy={condition.orderBy}
                      order={condition.order}
                      key={headCell.id}
                      handleRequestSort={handleRequestSort}
                    />
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell>{row.nickName}</TableCell>
                    <TableCell>{row.clientNickName}</TableCell>
                    <TableCell><Button onClick={(e) => onClick(e, row)}>設定する</Button></TableCell>
                  </TableRow>
                ))}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={3}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            totalElements={data.totalElements}
            rowsPerPage={condition.records}
            page={condition.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Dialog>
  );
}
