import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'assetId':
      case 'name':
      case 'assetKbn':
        return requiredValidation(value);
      case 'purchaseDate':
      case 'maintenanceEndDate':
      case 'subscriptionDate':
      case 'cancellationDate':
      case 'contractRenewalDate':
        return dateValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
