import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Slide, Dialog, TextField, Box, AppBar, Toolbar, IconButton, Typography,
  FormControl, RadioGroup, Radio, FormControlLabel, Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { estimateActions } from '../../../redux/sales/estimate/estimateState';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import TextArea from '../../../components/atoms/textArea/TextArea';
import urls from '../../../constants/frontUrls';
import { commonActions } from '../../../redux/common/commonState';
import SelectProject from '../../../components/organisms/selectProject/SelectProject';
import { projectActions } from '../../../redux/sales/project/projectState';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import SelectProjectContact from '../../../components/organisms/selectProjectContact/SelectProjectContact';
import Accordion from '../../../components/templates/accordion/Accordion';

const tmpMessage = {
  chumonNo: '',
  projectId: '',
  sagyoStDt: '',
  sagyoEdDt: '',
  chumonStatus: '',
  filingOn: '',
  taxType: '',
  deliveryDate: '',
  estimateDate: '',
  contractYears: '',
  orderDate: '',
};

const tmpForm = {
  id: null,
  chumonNo: '',
  clientNo: '',
  projectId: '',
  sagyoStDt: '',
  sagyoEdDt: '',
  chumonStatus: '0',
  filingOn: '',
  storagePath: '',
  biko: '',
  deadline: '2週間',
  paymentTerm: '月末締翌月末払',
  title: '',
  externalBiko: '振込手数料は貴社負担でお願い申し上げます。',
  taxType: '10',
  deliveryDate: '',
  estimateDate: '',
  contractYears: '',
  orderDate: '',
  contactName: '',
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EstimateDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const [selects, setSelect] = React.useState([]);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, estimateId, projectId, onSaveSuccess,
  } = props;
  const data = useSelector((state) => state.estimateStore.data);
  const statuses = useSelector((state) => state.commonStore.statuses);
  const isCreateSuccess = useSelector((state) => state.estimateStore.isCreateSuccess);
  const isUpdateSuccess = useSelector((state) => state.estimateStore.isUpdateSuccess);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const saveId = useSelector((state) => state.estimateStore.saveId);
  const projectData = useSelector((state) => state.projectStore.data);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (isCreateSuccess) {
      navigate(urls.ESTIMATE_DETAIL + saveId);
    }
  }, [isCreateSuccess]);
  React.useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(estimateActions.resetFlg());
      onSaveSuccess();
    }
  }, [isUpdateSuccess]);
  React.useEffect(() => {
    if (statuses && statuses.length >= 0) {
      setSelect(statuses);
    }
  }, [statuses]);
  React.useEffect(() => {
    if (isOpen) {
      if (estimateId) {
        dispatch(
          estimateActions.getData({ id: estimateId }),
        );
      }
      if (projectId) {
        dispatch(projectActions.getData({ id: projectId }));
      }
      setForm({
        ...tmpForm,
        projectId,
      });
      setErrorMessage(tmpMessage);
      dispatch(
        commonActions.getStatuses({ code: 'ORDER_STAT' }),
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);
  React.useEffect(() => {
    if (projectData) {
      setForm({
        ...form,
        clientNo: projectData.clientNo,
        paymentTerm: (form.paymentTerm === '月末締翌月末払' && projectData.contractKbn === '3') ? '保守開始月の翌月末払' : '月末締翌月末払',
        contractKbn: projectData.contractKbn,
      });
    }
  }, [projectData]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.contractKbn, form.chumonStatus);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (!tempMessage.sagyoStDt && !tempMessage.sagyoEdDt) {
      const msg = Validation.compareDateValidate(form.sagyoStDt, form.sagyoEdDt);
      if (msg) {
        isError = true;
      }
      tempMessage.sagyoEdDt = msg;
    }

    if (form.approvalStatus !== '4' && form.chumonStatus === '1') {
      tempMessage.chumonStatus = '未承認で受注にできません';
      isError = true;
    }
    if (isError) {
      return;
    }

    if (!estimateId) {
      dispatch(estimateActions.insertData(form));
    } else {
      dispatch(estimateActions.updateData(form));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    if (name === 'projectId') {
      dispatch(projectActions.getData({ id: value }));
    }
    let tempStatus = form.chumonStatus;
    if (name === 'chumonStatus') {
      tempStatus = value;
    }
    setForm(tempForm);
    setErrorMessage({
      ...errorMessage,
      [name]: Validation.formValidate(name, value, form.contractKbn, tempStatus),
    });
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            注文編集
          </Typography>
          <LoadingButton loading={isLoading} autoFocus color="inherit" onClick={onClickSave}>
            保存
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Box p={2} style={{ overflowY: 'auto' }} mt={8}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <FormTitle title="注文番号" isRequired />
            <TextField variant="outlined" error={errorMessage.chumonNo !== ''} autoComplete="off" fullWidth value={form.chumonNo} inputProps={{ maxLength: 30 }} name="chumonNo" onChange={onChange} />
            <FormErrorText>{errorMessage.chumonNo}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormTitle title="取引先番号" />
            <Box>{form.clientNo}</Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <FormTitle title="契約日" isRequired />
        </Box>
        <Box display="flex">
          <Box>
            <DatePicker
              handleChange={onChange}
              error={errorMessage.sagyoStDt !== ''}
              value={form.sagyoStDt}
              name="sagyoStDt"
            />
            <FormErrorText>{errorMessage.sagyoStDt}</FormErrorText>
          </Box>
          <Box mx={2} display="flex" alignItems="center">
            <Box>～</Box>
          </Box>
          <Box>
            <DatePicker
              handleChange={onChange}
              value={form.sagyoEdDt}
              error={errorMessage.sagyoEdDt !== ''}
              name="sagyoEdDt"
            />
            <FormErrorText>{errorMessage.sagyoEdDt}</FormErrorText>
          </Box>
        </Box>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="状況" isRequired />
            <FormControl>
              <RadioGroup
                row
                value={form.chumonStatus}
                onChange={onChange}
                name="chumonStatus"
              >
                {selects.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    checked={form.chumonStatus === item.id}
                    value={item.id}
                    control={<Radio />}
                    label={item.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormErrorText>{errorMessage.chumonStatus}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="消費税" isRequired />
            <FormControl>
              <RadioGroup
                row
                value={form.taxType}
                onChange={onChange}
                name="taxType"
              >
                <FormControlLabel
                  checked={form.taxType === '0'}
                  value="0"
                  control={<Radio />}
                  label="なし"
                />
                <FormControlLabel
                  checked={form.taxType === '10'}
                  value="10"
                  control={<Radio />}
                  label="10%"
                />
              </RadioGroup>
            </FormControl>
            <FormErrorText>{errorMessage.taxType}</FormErrorText>
          </Grid>
        </Grid>
        <Box mt={2}>
          <FormTitle title="プロジェクト" isRequired />
          <SelectProject
            name="projectId"
            disableClearable
            targetMonth={form.sagyoStDt}
            value={form.projectId}
            onChange={onChange}
            error={errorMessage.projectId !== ''}
          />
          <FormErrorText>{errorMessage.projectId}</FormErrorText>
        </Box>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={6}>
            <FormTitle title="顧客提出" />
            <DatePicker
              handleChange={onChange}
              value={form.filingOn}
              name="filingOn"
              error={errorMessage.filingOn !== ''}
            />
            <FormErrorText>{errorMessage.filingOn}</FormErrorText>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTitle title="受注日" />
            <DatePicker
              handleChange={onChange}
              value={form.orderDate}
              name="orderDate"
              error={errorMessage.orderDate !== ''}
            />
            <FormErrorText>{errorMessage.orderDate}</FormErrorText>
          </Grid>
          {form.contractKbn === '3' && (
            <Grid item xs={12} md={2}>
              <FormTitle title="契約時間" />
              <MoneyInput
                fullWidth
                value={form.contractYears}
                name="contractYears"
                onChange={onChange}
              />
              <FormErrorText>{errorMessage.contractYears}</FormErrorText>
            </Grid>
          )}
        </Grid>
        <Accordion style={{ marginTop: '24px' }} title="見積注文書情報">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormTitle title="見積日" />
              <DatePicker
                handleChange={onChange}
                value={form.estimateDate}
                name="estimateDate"
                error={errorMessage.estimateDate !== ''}
              />
              <FormErrorText>{errorMessage.estimateDate}</FormErrorText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="納期" />
              <DatePicker
                handleChange={onChange}
                value={form.deliveryDate}
                name="deliveryDate"
                error={errorMessage.deliveryDate !== ''}
              />
              <FormErrorText>{errorMessage.deliveryDate}</FormErrorText>
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormTitle title="見積期限" />
              <TextField variant="outlined" fullWidth value={form.deadline} autoComplete="off" inputProps={{ maxLength: 50 }} name="deadline" onChange={onChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="支払条件" />
              <TextField variant="outlined" autoComplete="off" fullWidth value={form.paymentTerm} inputProps={{ maxLength: 30 }} name="paymentTerm" onChange={onChange} />
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormTitle title="件名" />
              <TextArea name="title" minRows={1} value={form.title} onChange={onChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="送付先担当" />
              <SelectProjectContact
                name="contactId"
                projectId={form.projectId}
                value={form.contactId}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <FormTitle title="見積書備考" />
            <TextArea name="externalBiko" value={form.externalBiko} onChange={onChange} />
          </Box>
        </Accordion>
        <Box mt={3}>
          <FormTitle title="格納パス" />
          <TextField variant="outlined" fullWidth value={form.storagePath} autoComplete="off" inputProps={{ maxLength: 100 }} name="storagePath" onChange={onChange} />
          <FormErrorText>{errorMessage.projectId}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="備考" />
          <TextArea name="biko" value={form.biko} onChange={onChange} />
        </Box>
      </Box>
    </Dialog>
  );
}
