import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Container, Grid, Paper, TextField, Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { noticeActions } from '../../../redux/setting/notice/noticeState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import Editor from '../../../components/templates/wysiwyg/Editor';

class NoticeDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      data: {
        id,
        title: '',
        content: '',
        periodDateFrom: '',
        periodDateTo: '',
      },
      errorMessage: {
        title: '',
        content: '',
        periodDateFrom: '',
        periodDateTo: '',
      },
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };

    if (id) {
      props.dispatch(noticeActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noticeInfo.data !== this.props.noticeInfo.data) {
      if (this.props.noticeInfo.data) {
        this.setData(this.props.noticeInfo.data);
      }
    }
    if (prevProps.noticeInfo.isSaveSuccess
      !== this.props.noticeInfo.isSaveSuccess) {
      if (this.props.noticeInfo.isSaveSuccess) {
        this.setUpdateSuccess(this.props.noticeInfo.saveId);
      }
    }
  }

  setData(data) {
    this.setState({ data });
  }

  onChange = (event) => {
    const { data, errorMessage } = this.state;
    const { name, value } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: Validation.formValidate(name, value),
      },
    });
  };

  setUpdateSuccess = (id) => {
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存が完了しました',
    });
    this.props.dispatch(noticeActions.getData({ id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onCopy = () => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        id: null,
      },
      errorMessage: {
        title: '',
        content: '',
        periodDateFrom: '',
        periodDateTo: '',
      },
      isSuccessOpen: true,
      successMessage: 'コピーが完了しました',
    });
  };

  onSave = () => {
    const { data, errorMessage } = this.state;
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(data).forEach((key) => {
      const msg = Validation.formValidate(key, data[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.periodDateFrom && !tempMessage.periodDateTo) {
      const msg = Validation.compareDateValidate(data.periodDateFrom, data.periodDateTo);
      if (msg) {
        isError = true;
      }
      tempMessage.periodDateTo = msg;
    }

    this.setState({ errorMessage: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(noticeActions.saveData(data));
  };

  render() {
    const {
      data, isSuccessOpen, successMessage, isDisabled, errorMessage,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Box>
        <Container>
          {data.id && (
            <Box textAlign="right">
              <Button variant="outlined" size="small" onClick={this.onCopy}>コピー</Button>
            </Box>
          )}
          <Box mt={2}>
            <FormTitle title="タイトル" isRequired />
            <TextField value={data.title} name="title" fullWidth onChange={this.onChange} error={errorMessage.title !== ''} />
            <FormErrorText>{errorMessage.title}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="開始日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="periodDateFrom"
              error={errorMessage.periodDateFrom !== ''}
              value={data.periodDateFrom}
            />
            <FormErrorText>{errorMessage.periodDateFrom}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="終了日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="periodDateTo"
              error={errorMessage.periodDateTo !== ''}
              value={data.periodDateTo}
            />
            <FormErrorText>{errorMessage.periodDateTo}</FormErrorText>
          </Box>
          <Box mt={2}>
            <Box display="flex">
              <Box style={{ flex: '50%' }}>
                <FormTitle title="内容" isRequired />
              </Box>
            </Box>
            <Editor title="お知らせの" name="content" onChange={this.onChange} content={data.content} />
            <FormErrorText>{errorMessage.content}</FormErrorText>
          </Box>
          {data.id && (
            <Paper variant="outlined" style={{ padding: '10px', marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <FormTitle title="作成日" />
                  <Box>{data.createdAt}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="作成者" />
                  <Box>{data.createdBy}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="更新日" />
                  <Box>{data.updatedAt}</Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormTitle title="更新者" />
                  <Box>{data.updatedBy}</Box>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Container>
        {(!isDisabled) && (
          <Box textAlign="center" mt={2}>
            <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
          </Box>
        )}
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  noticeInfo: state.noticeStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(NoticeDetail));
