/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { reportActions } from './reportState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getCategory() {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.REPORT.REPORT_CATEGORY);
    yield put(reportActions.getCategorySuccess(result));
  } catch (_) {}
}

function* getSyain() {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.REPORT.REPORT_SYAIN);
    yield put(reportActions.getSyainSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.REPORT.REPORT, payload);
    yield put(reportActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SUMMARY.REPORT.REPORT, payload);
    yield put(reportActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SUMMARY.REPORT.REPORT_ID, payload);
    yield put(reportActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SUMMARY.REPORT.REPORT, payload);
    if (payload.id) {
      yield put(reportActions.updateDataSuccess());
    } else {
      yield put(reportActions.createDataSuccess(result));
    }
  } catch (_) {}
}

function* saveDetailData({ payload }) {
  try {
    yield call(saveData, REST_API.SUMMARY.REPORT.REPORT_DETAIL, payload);
    yield put(reportActions.saveDetailDataSuccess());
  } catch (_) {}
}

function* reportSaga() {
  yield takeLatest('report/getDatas', getData);
  yield takeLatest('report/getCategory', getCategory);
  yield takeLatest('report/getSyain', getSyain);
  yield takeLatest('report/deleteData', deleteData);
  yield takeLatest('report/getData', getDataFromId);
  yield takeLatest('report/saveData', saveDatas);
  yield takeLatest('report/saveDetailData', saveDetailData);
}

export default reportSaga;
