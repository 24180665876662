import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import { employeeLicenseHistoryActions } from '../../../../redux/office/employee/employeeLicenseHistoryState';
import LicenseDialog from './LicenseDialog';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [detailId, setDetailId] = React.useState(null);
  const dispatch = useDispatch();
  const isSaveSuccess = useSelector((state) => state.employeeLicenseHistoryStore.isSaveSuccess);
  const {
    rows, onResearch, syainId, disabled,
  } = props;

  React.useEffect(() => {
    if (isSaveSuccess) {
      onResearch();
      setDetailOpen(false);
      dispatch(employeeLicenseHistoryActions.reset());
    }
  }, [isSaveSuccess]);

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const onAdd = () => {
    setDetailOpen(true);
    setDetailId(null);
  };

  const onDetail = (_, id) => {
    setDetailOpen(true);
    setDetailId(id);
  };

  const onAddClose = () => {
    setDetailOpen(false);
  };

  const handleClose = () => {
    setConfOpen(false);
  };

  const onClickDelete = () => {
    dispatch(employeeLicenseHistoryActions.deleteData({ ids: selected }));
    setConfOpen(false);
    setSelected([]);
  };

  const onClickConfirmDelete = () => {
    setConfOpen(true);
  };

  const addProc = (
    <>
      <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
      {!disabled && (
        <Tooltip title="新規作成">
          <Button color="primary" variant="outlined" onClick={onAdd}>新規作成</Button>
        </Tooltip>
      )}
    </>
  );

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Box mr={2}>
                <Tooltip title="削除">
                  <Button color="secondary" variant="contained" onClick={onClickConfirmDelete}>削除</Button>
                </Tooltip>
              </Box>
            </>
          ) : addProc}
        </Toolbar>
        <TableContainer sx={{ maxHeight: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!disabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.length === 0}
                    />
                  </TableCell>
                )}
                <TableCell>資格名</TableCell>
                <TableCell>取得日</TableCell>
                <TableCell>備考</TableCell>
                {!disabled && (
                  <TableCell />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!disabled && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                    )}
                    <TableCell>{row.licenseName}</TableCell>
                    <TableCell>{row.acquiredDate}</TableCell>
                    <TableCell>{row.remarks}</TableCell>
                    {!disabled && (
                      <TableCell>
                        <Button onClick={(e) => onDetail(e, row.id)}>編集する</Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={9}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <LicenseDialog
          isOpen={detailOpen}
          onClickClose={onAddClose}
          detailId={detailId}
          syainId={syainId}
        />
        <ConfirmDialog
          open={confOpen}
          isError
          onClose={handleClose}
          title="選択した給与の削除"
          content="選択した給与を削除します"
          onClick={onClickDelete}
          procName="削除する"
        />
      </Paper>
    </Box>
  );
}
