import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, DialogTitle, DialogContent, DialogActions, Box,
} from '@mui/material';
import Dialog from '../../../../components/atoms/dialog/Dialog';
import { employeeLicenseHistoryActions } from '../../../../redux/office/employee/employeeLicenseHistoryState';
import DatePicker from '../../../../components/atoms/datePicker/DatePicker';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import TextArea from '../../../../components/atoms/textArea/TextArea';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';

export default function LicenseDialog(props) {
  const [acquiredDate, setAcquiredDate] = React.useState('');
  const [mstCertificateId, setMstCertificateId] = React.useState(null);
  const [remarks, setRemarks] = React.useState('');
  const [selects, setSelects] = React.useState([]);
  const dispatch = useDispatch();
  const {
    isOpen, onClickClose, detailId, syainId,
  } = props;
  const data = useSelector((state) => state.employeeLicenseHistoryStore.data);
  const select = useSelector((state) => state.employeeLicenseHistoryStore.selects);

  React.useEffect(() => {
    if (isOpen) {
      if (detailId) {
        dispatch(
          employeeLicenseHistoryActions.getData({ id: detailId }),
        );
      }
      dispatch(employeeLicenseHistoryActions.getSelect());
      setAcquiredDate('');
      setMstCertificateId(null);
      setRemarks('');
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (select) {
      setSelects(select);
    }
  }, [select]);
  React.useEffect(() => {
    if (data) {
      setAcquiredDate(data.acquiredDate);
      setMstCertificateId(data.mstCertificateId);
      setRemarks(data.remarks);
    }
  }, [data]);

  const onClickSave = () => {
    dispatch(
      employeeLicenseHistoryActions.saveData({
        id: detailId,
        syainId,
        acquiredDate,
        mstCertificateId,
        remarks,
      }),
    );
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClickClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>資格データの追加</DialogTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="資格名" isRequired />
          <SearchSelectBox
            options={selects}
            value={mstCertificateId}
            onChange={(e) => setMstCertificateId(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <FormTitle title="取得日" />
          <DatePicker
            handleChange={(e) => setAcquiredDate(e.target.value)}
            value={acquiredDate}
          />
        </Box>
        <Box mt={2}>
          <FormTitle title="備考" />
          <TextArea name="biko" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave} disabled={!mstCertificateId}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
