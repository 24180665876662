import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ListItem, ListItemText,
} from '@mui/material';

export default function HeaderMenuItem(props) {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.loginStore.userInfo).user;
  const location = useLocation();
  const {
    isNested, url, menuName, onClose,
  } = props;

  const onClick = (_, link) => {
    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
      onClose();
    }
    navigate(link);
  };

  const roleScreenList = userInfo ? userInfo.roleScreenList : [];

  const isShow = roleScreenList.filter((row) => row.url === url).length > 0;
  const path = `/${location.pathname.split('/')[1]}`;

  return (
    <ListItem
      button
      onClick={(event) => onClick(event, url)}
      style={{ display: isShow ? '' : 'none', paddingLeft: isNested ? 30 : 0 }}
      selected={url === path}
    >
      <ListItemText primary={menuName} />
    </ListItem>
  );
}
