/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const assetRequestListSlice = createSlice({
  name: 'assetRequestList',
  initialState: {
    data: {},
    assetCodes: [],
    codes: [],
    searchCondition: null,
    isReturnedSuccess: false,
  },
  reducers: {
    getCodes: (state) => {
      state.codes = [];
    },
    getCodesSuccess: (state, action) => {
      state.codes = action.payload;
    },
    getAssetCodes: (state) => {
      state.assetCodes = [];
    },
    getAssetCodesSuccess: (state, action) => {
      state.assetCodes = action.payload;
    },
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveReturned: (state) => {
      state.isReturnedSuccess = false;
    },
    saveReturnedSuccess: (state) => {
      state.isReturnedSuccess = true;
    },
  },
});

export const assetRequestListActions = assetRequestListSlice.actions;

export default assetRequestListSlice.reducer;
