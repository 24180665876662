import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box,
} from '@mui/material';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { orderActions } from '../../../../redux/sales/order/orderState';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabledFromUrl } from '../../../../utils/authCheck.helper';
import urls from '../../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import OrderDialog from '../../order/OrderDialog';
import { commonActions } from '../../../../redux/common/commonState';

class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'order_no', label: '発注番号' },
      { id: 'client_name', label: '発注先' },
      { id: 'app_status', label: '承認' },
      { id: 'period', label: '契約期間' },
      { id: 'order_amount', label: '発注額' },
      { id: 'claim_amount', label: '支払額' },
    ];

    const searchCondition = {
      searchProjectId: props.projectId,
      searchAppStatus: [],
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      codes: [],
      isDetailOpen: false,
      isDisabled: checkIsDisabledFromUrl(props.loginUser.userInfo.user, '/orders'),
    };
    this.props.dispatch(commonActions.getStatuses({ code: 'PJ_APP_STATE' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderInfo.datas !== this.props.orderInfo.datas) {
      if (this.props.orderInfo.datas) {
        this.setDatas(this.props.orderInfo.datas);
      }
    }
    if (prevProps.common.statuses !== this.props.common.statuses) {
      if (this.props.common.statuses) {
        this.setCodes(this.props.common.statuses);
      }
    }
    if (prevProps.orderInfo.isDeleteSuccess
      !== this.props.orderInfo.isDeleteSuccess) {
      if (this.props.orderInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setCodes = (codes) => {
    this.setState({ codes });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(orderActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(orderActions.deleteData({ ids }));
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      codes,
      isDetailOpen,
    } = this.state;

    return (
      <Box>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={6} md={3}>
            <FormTitle title="承認" />
            <SearchMultipleSelectBox
              name="searchAppStatus"
              options={codes}
              multiple
              values={searchCondition.searchAppStatus}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>
        <DeleteDataTable
          url={urls.ORDER_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          onAddOpen={this.onAdd}
          procName="発注"
          exclusion={['projectName']}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <OrderDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          orderId={null}
          projectId={this.props.projectId}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  orderInfo: state.orderStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(OrderList);
