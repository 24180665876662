import { isValid, isBefore } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const timeValidation = (value) => {
  if (!value) return '入力してください';
  const target = new Date(`1999/12/31 ${value}`);
  if (!isValid(new Date(target))) return '時間形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'title':
      case 'operateTime':
      case 'afterSortOrder':
        return requiredValidation(value);
      case 'startTime':
      case 'breakStTime':
      case 'endTime':
        return timeValidation(value);
      default:
        return '';
    }
  };

  static compareTimeValidate = (valueStart, valueEnd) => {
    const start = new Date(`1999/12/31 ${valueStart}`);
    const end = new Date(`1999/12/31 ${valueEnd}`);
    if (isBefore(end, start)) return '開始日より未来を設定してください';
    return '';
  };
}

export default Validation;
