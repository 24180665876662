/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { approvalHistoryActions } from './approvalHistoryState';
import { getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.APPROVAL_HISTORY, payload);
    yield put(approvalHistoryActions.getDatasSuccess(result));
  } catch (_) {}
}

function* approvalHistorySaga() {
  yield takeLatest('approvalHistory/getDatas', getData);
}

export default approvalHistorySaga;
