import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button,
  Tabs, Tab,
} from '@mui/material';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { orderActions } from '../../../redux/sales/order/orderState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import OrderDialog from './OrderDialog';
import PayList from './PayList';
import ErrorDialog from '../../../components/templates/errorDialog/ErrorDialog';
import Link from '../../../components/atoms/link/Link';
import urls from '../../../constants/frontUrls';
import PjAppStatus from '../../../components/templates/pjAppStatus/PjAppStatus';
import OrderDetailInput from './OrderDetailInput';
import ApprovalHistory from '../approvalHistory/ApprovalHistory';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.orderInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(orderActions.resetFlg());

    this.state = {
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      successMessage,
      isSuccessOpen,
      tabValue: 0,
      form: { orderId: id },
      isDetailOpen: false,
      isError: false,
      isConfOpen: false,
    };
    if (id) {
      props.dispatch(orderActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderInfo.data !== this.props.orderInfo.data) {
      if (this.props.orderInfo.data) {
        this.setDatas(this.props.orderInfo.data);
      }
    }
    if (prevProps.orderInfo.isStatusSuccess !== this.props.orderInfo.isStatusSuccess) {
      if (this.props.orderInfo.isStatusSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess = () => {
    const { form, approvalStatus } = this.state;
    this.props.dispatch(orderActions.getData({ id: form.id }));
    let msg = '承認';
    if (approvalStatus === '1') {
      msg = '承認依頼';
    }
    if (approvalStatus === '0') {
      msg = '承認取下';
    }
    this.setState({
      isSuccessOpen: true,
      successMessage: `${msg}が完了しました`,
    });
  };

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(orderActions.getData({ id: form.id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false, isConfOpen: false });
  };

  onUpdate = (_, approvalStatus, ball) => {
    const { form } = this.state;
    if (!form.projectOwner || !form.managerId) {
      this.setState({ isError: true });
      return;
    }
    this.props.dispatch(orderActions.updateStatus({ id: form.id, approvalStatus, ball }));
    this.setState({ approvalStatus, isSuccessOpen: false, isConfOpen: false });
  };

  onCloseError = () => {
    this.setState({ isError: false });
  };

  onConfUpdate = () => {
    this.setState({ isConfOpen: true });
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, successMessage, isDisabled, isDetailOpen, isError, isConfOpen,
    } = this.state;

    return (
      <Container maxWidth="lg">
        {(form.approvalStatus !== '0' && form.approvalStatus !== '4') && (
          <Box mb={2}>
            <PjAppStatus activeStep={form.approvalStatus} isExclusion={false} />
          </Box>
        )}
        {!isDisabled && (
          <Box p={1} textAlign="right">
            {form.approvalStatus === '0' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '1', form.projectOwner)} style={{ marginRight: '10px' }}>承認依頼</Button>
            )}
            {form.approvalStatus === '1' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '2', form.managerId)} style={{ marginRight: '10px' }}>PJオーナー承認</Button>
            )}
            {form.approvalStatus === '2' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '3', form.managerId)} style={{ marginRight: '10px' }}>上長承認</Button>
            )}
            {form.approvalStatus === '3' && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '4', form.managerId)} style={{ marginRight: '10px' }}>総務承認</Button>
            )}
            {form.approvalStatus !== '0' && (
              <Button variant="contained" color="secondary" size="small" onClick={this.onConfUpdate} style={{ marginRight: '10px' }}>取下</Button>
            )}
            <Button variant="contained" size="small" onClick={this.onDetail}>編集</Button>
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="発注番号" />
              <Box>{form.orderNo}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="発注先" />
              <Box><Link to={urls.CLIENT_DETAIL + form.clientId}>{form.clientName}</Link></Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="プロジェクト" />
              <Box><Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link></Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="発注額" />
              <Box>{form.orderAmount}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="承認" />
              <Box>{form.approvalStatusName}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
            <Tabs value={tabValue} onChange={this.onTabChange}>
              <Tab label="基本情報" />
              <Tab label="明細" />
              <Tab label="支払" />
              <Tab label="承認履歴" />
            </Tabs>
          </Box>
        </Container>
        <TabPanel value={tabValue} index={0}>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormTitle title="発注番号" />
                <Box>{form.orderNo}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="承認" />
                <Box>{form.approvalStatusName}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="契約開始" />
                <Box>{form.orderStDt}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="契約終了" />
                <Box>{form.orderEdDt}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="発注先" />
                <Box><Link to={urls.CLIENT_DETAIL + form.clientId}>{form.clientName}</Link></Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="プロジェクト" />
                <Box><Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link></Box>
              </Grid>
              <Grid item xs={6} md={12}>
                <FormTitle title="消費税" />
                <Box>{`${form.taxType}%`}</Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <FormTitle title="発注額(税抜)" />
                <Box>{form.orderAmount}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="消費税" />
                <Box>{form.tax}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="発注額(税込)" />
                <Box>{form.orderAmountIncluded}</Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <OrderDetailInput id={form.id} onSaveSuccess={this.onSaveSuccess} isDisabled={isDisabled || form.approvalStatus === '4'} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PayList parentId={form.id} disabled={form.approvalStatus !== '4'} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ApprovalHistory orderId={form.id} />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <OrderDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          orderId={form.id}
          projectId={form.projectId}
          onSaveSuccess={this.onSaveSuccess}
        />
        <ErrorDialog
          open={isError}
          onClose={this.onCloseError}
          title="エラー"
          content="プロジェクトオーナーまたは担当部が入力されていません"
        />
        <ConfirmDialog
          isError
          open={isConfOpen}
          onClose={this.onClose}
          title="取下"
          content="取下処理を行います。"
          onClick={(e) => this.onUpdate(e, '0', null)}
          procName="取下"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  orderInfo: state.orderStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(OrderDetail));
