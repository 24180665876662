import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

let steps = [];
export default function PjAppStatus(props) {
  const { isExclusion, activeStep } = props;
  React.useEffect(() => {
    steps = [];
    steps.push('承認依頼中', 'PJオーナー承認');
    if (!isExclusion) {
      steps.push('上長承認');
    }
    steps.push('総務承認');
  }, [isExclusion]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
