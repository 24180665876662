/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const estimateDetailSlice = createSlice({
  name: 'estimateDetail',
  initialState: {
    datas: {},
    isSaveSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
  },
});

export const estimateDetailActions = estimateDetailSlice.actions;

export default estimateDetailSlice.reducer;
