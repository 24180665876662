/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { trafficRequestInputActions } from './trafficRequestInputState';
import {
  getDataById, saveData, getDatas,
} from '../../../services/base.service';

function* getCodes() {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CODES, { code: 'TRFC_KBN' });
    yield put(trafficRequestInputActions.getCodesSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_DETAIL_ID, payload);
    yield put(trafficRequestInputActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.TRAFFIC_REQUEST.TRAFFIC_REQUEST_DETAIL, payload);
    yield put(trafficRequestInputActions.saveDataSuccess(result));
  } catch (_) {}
}

function* trafficRequestInputSaga() {
  yield takeLatest('trafficRequestInput/getCodes', getCodes);
  yield takeLatest('trafficRequestInput/getData', getData);
  yield takeLatest('trafficRequestInput/saveData', saveDatas);
}

export default trafficRequestInputSaga;
