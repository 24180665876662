import React from 'react';
import { connect } from 'react-redux';
import SearchSelectBox from '../../atoms/searchSelectBox/SearchSelectBox';
import { commonActions } from '../../../redux/common/commonState';

class SelectProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.common.projects,
    };
    this.props.dispatch(
      commonActions.getProjects({
        targetMonth: props.targetMonth,
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.projects !== this.props.common.projects) {
      if (this.props.common.projects) {
        this.setData(this.props.common.projects);
      }
    }
    if (prevProps.targetMonth !== this.props.targetMonth) {
      this.getData(this.props.targetMonth);
    }
  }

  getData = (targetMonth) => {
    this.props.dispatch(commonActions.getProjects({ targetMonth }));
  };

  setData = (options) => {
    this.setState({ options });
  };

  render() {
    const { options } = this.state;
    const {
      value, error, name, onChange, isDisabled,
    } = this.props;

    return (
      <SearchSelectBox
        error={error}
        name={name}
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={onChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({ common: state.commonStore });

export default connect(mapStateToProps)(SelectProject);
