/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { orderActions } from './orderState';
import {
  getDatas, deleteDatas, saveData, getDataById, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.ORDER.ORDER, payload);
    yield put(orderActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.ORDER.ORDER, payload);
    yield put(orderActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.ORDER.ORDER_ID, payload);
    yield put(orderActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ORDER.ORDER, payload);
    yield put(orderActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.ORDER.ORDER, payload);
    yield put(orderActions.updateDataSuccess(result));
  } catch (_) {}
}

function* updateStatus({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.ORDER.ORDER_ID, payload);
    yield put(orderActions.updateStatusSuccess());
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.ORDER.ORDER_SELECT, payload);
    yield put(orderActions.getSelectSuccess(result));
  } catch (_) {}
}

function* orderSaga() {
  yield takeLatest('order/getDatas', getData);
  yield takeLatest('order/deleteData', deleteData);
  yield takeLatest('order/getData', getDataFromId);
  yield takeLatest('order/insertData', insertData);
  yield takeLatest('order/updateData', updateDatas);
  yield takeLatest('order/updateStatus', updateStatus);
  yield takeLatest('order/getSelect', getSelect);
}

export default orderSaga;
