import React from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress, Backdrop } from '@mui/material';

export default function Loading() {
  const isLoading = useSelector((state) => state.commonStore.isLoading);

  const loadingScreen = (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, background: 'rgba(0, 0, 0, 0.1)' }}
      open
    >
      <LinearProgress
        color="secondary"
        style={{
          position: 'fixed', top: '85px', width: '100%', zIndex: 2000,
        }}
      />
    </Backdrop>
  );
  return isLoading ? loadingScreen : null;
}
