export function getIndex(value, arr, prop) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1;
}

export function getIncludes(value, arr, prop) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i][prop] === value) {
      return true;
    }
  }
  return false;
}

export function getEdit(value, arr, screen, edit) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i][screen] === value) {
      return arr[i][edit];
    }
  }
  return false;
}

export function getFormatScreens(screen) {
  const map = new Map();
  const category = [];
  screen.forEach((c) => {
    let lists = [];
    if (map.get(c.category)) {
      lists = map.get(c.category);
    } else {
      category.push(c.category);
    }
    lists.push(c);
    map.set(c.category, lists);
  });
  return {
    values: map,
    category,
  };
}
