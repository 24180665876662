import React from 'react';

import RoleList from '../pages/setting/role/RoleList';
import RoleDetail from '../pages/setting/role/RoleDetail';
import WorkStyleList from '../pages/setting/workStyle/WorkStyleList';
import NoticeList from '../pages/setting/notice/NoticeList';
import NoticeDetail from '../pages/setting/notice/NoticeDetail';
import LoginUserList from '../pages/setting/loginUser/LoginUserList';
import CalendarList from '../pages/setting/calendar/CalendarList';

import WorkDeviation from '../pages/work/workDeviation/WorkDeviation';
import WorkPlan from '../pages/work/workPlan/WorkPlan';
import WorkResult from '../pages/work/workResult/WorkResult';
import WorkCostList from '../pages/work/workCostList/WorkCostList';
import WorkSummary from '../pages/work/workSummary/WorkSummary';

import CostClose from '../pages/close/CostClose';
import SalesClose from '../pages/close/SalesClose';
import CloseEdit from '../pages/close/CloseEdit';
import CheckSalesClose from '../pages/close/CheckSalesClose';

import WorkRequestList from '../pages/request/workRequest/WorkRequestList';
import WorkRequestDetail from '../pages/request/workRequest/WorkRequestDetail';
import OvertimeRequestList from '../pages/request/overtimeRequest/OvertimeRequestList';
import OvertimeRequestDetail from '../pages/request/overtimeRequest/OvertimeRequestDetail';
import TrafficRequestList from '../pages/request/trafficRequest/TrafficRequestList';
import TrafficRequestDetail from '../pages/request/trafficRequest/detail/TrafficRequestDetail';
import ExpenseRequestList from '../pages/request/expenseRequest/ExpenseRequestList';
import ExpenseRequestDetail from '../pages/request/expenseRequest/ExpenseRequestDetail';
import AssetRequestList from '../pages/request/assetRequest/AssetRequestList';
import WorkFixRequest from '../pages/request/workFixRequest/WorkFixRequest';

import ClientList from '../pages/sales/client/ClientList';
import ClientDetail from '../pages/sales/client/ClientDetail';
import ProjectList from '../pages/sales/project/ProjectList';
import ProjectDetail from '../pages/sales/project/deital/ProjectDetail';
import EstimateList from '../pages/sales/estimate/EstimateList';
import EstimateDetail from '../pages/sales/estimate/EstimateDetail';
import ClaimDetail from '../pages/sales/claim/ClaimDetail';
import InspectDetail from '../pages/sales/inspect/InspectDetail';
import OrderList from '../pages/sales/order/OrderList';
import OrderDetail from '../pages/sales/order/OrderDetail';
import PayDetail from '../pages/sales/pay/detail/PayDetail';
import PartnerList from '../pages/sales/partner/PartnerList';
import PartnerDetail from '../pages/sales/partner/PartnerDetail';
import ClaimList from '../pages/sales/claim/ClaimList';
import InspectList from '../pages/sales/inspect/InspectList';
import SkillList from '../pages/sales/skill/SkillList';
import SkillDetail from '../pages/sales/skill/SkillDetail';

import EmployeeList from '../pages/office/employee/employee/EmployeeList';
import EmployeeDetail from '../pages/office/employee/employee/EmployeeDetail';
import SalaryList from '../pages/office/salary/SalaryList';
import SalaryDetail from '../pages/office/salary/SalaryDetail';
import CacheList from '../pages/office/cache/CacheList';
import VacationList from '../pages/office/vacation/VacationList';
import VacationDetail from '../pages/office/vacation/VacationDetail';
import CertificateCampaignList from '../pages/office/certificateCampaign/CertificateCampaignList';
import CertificateCampaignDetail from '../pages/office/certificateCampaign/CertificateCampaignDetail';
import FixedExpenseList from '../pages/office/fixedExpense/FixedExpenseList';

import CertificateList from '../pages/master/certificate/CertificateList';
import CertificateDetail from '../pages/master/certificate/CertificateDetail';
import AssetList from '../pages/master/asset/AssetList';
import AssetDetail from '../pages/master/asset/AssetDetail';

import ReportList from '../pages/summary/report/ReportList';
import ReportDetail from '../pages/summary/report/ReportDetail';
import ProjectBalanceDetail from '../pages/summary/projectBalance/detail/ProjectBalanceDetail';
import ProjectBalance from '../pages/summary/projectBalance/list/ProjectBalance';
import DivisionBalance from '../pages/summary/divisionBalance/DivisionBalance';
import CompanyBalance from '../pages/summary/companyBalance/CompanyBalance';

import Output from '../pages/file/output/Output';
import Input from '../pages/file/input/Input';

const urls = {
  COMMON: {
    HOME: { name: 'home', url: '/' },
    LOGIN: { name: 'ログイン', url: '/login' },
    SYSTEM_ERROR: { name: 'エラー', url: '/error' },
  },
  PROFILE: {
    PASSWORD: { name: 'パスワード変更', url: '/change-password' },
    USER_SETTING: { name: 'パスワード変更', url: '/user-settings' },
  },
  WORKS: [
    { name: '予定入力', url: '/work-plans', component: <WorkPlan /> },
    { name: '実績入力', url: '/work-results', component: <WorkResult /> },
    { name: '作業時間差分', url: '/work-deviations', component: <WorkDeviation /> },
    { name: '工数一覧', url: '/work-costs', component: <WorkCostList /> },
    { name: '作業時間一覧', url: '/work-summary', component: <WorkSummary /> },
  ],
  CLOSE: [
    { name: '売上締めチェック', url: '/check-sales-closes', component: <CheckSalesClose /> },
    { name: '経費締め', url: '/cost-closes', component: <CostClose /> },
    { name: '売上締め', url: '/sales-closes', component: <SalesClose /> },
    { name: '締め編集', url: '/close-edits', component: <CloseEdit /> },
  ],
  REQUESTS: [
    {
      name: '勤怠申請', url: '/work-requests', component: <WorkRequestList />, isMenuDisplay: true,
    },
    { name: '勤怠申請詳細', url: '/work-requests/detail/:id', component: <WorkRequestDetail /> },
    { name: '勤怠申請詳細', url: '/work-requests/detail', component: <WorkRequestDetail /> },
    {
      name: '残業申請', url: '/overtime-requests', component: <OvertimeRequestList />, isMenuDisplay: true,
    },
    { name: '残業申請詳細', url: '/overtime-requests/detail/:id', component: <OvertimeRequestDetail /> },
    { name: '残業申請詳細', url: '/overtime-requests/detail', component: <OvertimeRequestDetail /> },
    {
      name: '交通費申請', url: '/traffic-requests', component: <TrafficRequestList />, isMenuDisplay: true,
    },
    { name: '交通費申請詳細', url: '/traffic-requests/detail/:id', component: <TrafficRequestDetail /> },
    { name: '交通費申請詳細', url: '/traffic-requests/detail', component: <TrafficRequestDetail /> },
    {
      name: '資産申請', url: '/asset-requests', component: <AssetRequestList />, isMenuDisplay: true,
    },
    {
      name: '経費申請', url: '/expense-requests', component: <ExpenseRequestList />, isMenuDisplay: true,
    },
    { name: '経費申請詳細', url: '/expense-requests/detail/:id', component: <ExpenseRequestDetail /> },
    { name: '経費申請詳細', url: '/expense-requests/detail', component: <ExpenseRequestDetail /> },
    {
      name: '作業修正申告', url: '/work-fix-requests', component: <WorkFixRequest />, isMenuDisplay: true,
    },
  ],
  SETTING: [
    {
      name: '権限', url: '/roles', component: <RoleList />, isMenuDisplay: true,
    },
    { name: '権限詳細', url: '/roles/detail/:id', component: <RoleDetail /> },
    {
      name: '勤務形態', url: '/work-styles', component: <WorkStyleList />, isMenuDisplay: true,
    },
    {
      name: 'お知らせ', url: '/notices', component: <NoticeList />, isMenuDisplay: true,
    },
    { name: 'お知らせ詳細', url: '/notices/detail/:id', component: <NoticeDetail /> },
    { name: 'お知らせ詳細', url: '/notices/detail', component: <NoticeDetail /> },
    {
      name: 'ログインユーザ', url: '/login-users', component: <LoginUserList />, isMenuDisplay: true,
    },
    {
      name: 'カレンダー', url: '/calendars', component: <CalendarList />, isMenuDisplay: true,
    },
  ],
  SALES: [
    {
      name: '取引先', url: '/clients', component: <ClientList />, isMenuDisplay: true,
    },
    { name: '取引先詳細', url: '/clients/detail/:id', component: <ClientDetail /> },
    { name: '取引先詳細', url: '/clients/detail', component: <ClientDetail /> },
    {
      name: 'プロジェクト', url: '/projects', component: <ProjectList />, isMenuDisplay: true,
    },
    { name: 'プロジェクト詳細', url: '/projects/detail/:id', component: <ProjectDetail /> },
    {
      name: '注文', url: '/estimates', component: <EstimateList />, isMenuDisplay: true,
    },
    { name: '注文詳細', url: '/estimates/detail/:id', component: <EstimateDetail /> },
    {
      name: '請求', url: '/claims', component: <ClaimList />, isMenuDisplay: true,
    },
    { name: '請求詳細', url: '/claims/detail/:id', component: <ClaimDetail /> },
    {
      name: '検収', url: '/inspects', component: <InspectList />, isMenuDisplay: true,
    },
    { name: '検収詳細', url: '/inspects/detail/:id', component: <InspectDetail /> },
    {
      name: '発注', url: '/orders', component: <OrderList />, isMenuDisplay: true,
    },
    { name: '発注詳細', url: '/orders/detail/:id', component: <OrderDetail /> },
    { name: '支払詳細', url: '/pays/:id', component: <PayDetail /> },
    {
      name: 'パートナー', url: '/partners', component: <PartnerList />, isMenuDisplay: true,
    },
    { name: 'パートナー詳細', url: '/partners/detail/:id', component: <PartnerDetail /> },
    { name: 'パートナー詳細', url: '/partners/detail', component: <PartnerDetail /> },
    {
      name: 'スキル管理', url: '/skills', component: <SkillList />, isMenuDisplay: true,
    },
    { name: 'スキル管理詳細', url: '/skills/detail/:id', component: <SkillDetail /> },
    { name: 'スキル管理詳細', url: '/skills/detail', component: <SkillDetail /> },
  ],
  OFFICE: [
    {
      name: '給与一覧', url: '/salaries', component: <SalaryList />, isMenuDisplay: true,
    },
    { name: '給与詳細', url: '/salaries/detail/:id', component: <SalaryDetail /> },
    {
      name: '社員一覧', url: '/employees', component: <EmployeeList />, isMenuDisplay: true,
    },
    { name: '社員詳細', url: '/employees/detail/:id', component: <EmployeeDetail /> },
    {
      name: '入金支払確認', url: '/caches', component: <CacheList />, isMenuDisplay: true,
    },
    {
      name: '有休一覧', url: '/vacations', component: <VacationList />, isMenuDisplay: true,
    },
    { name: '有休詳細', url: '/vacations/detail/:id', component: <VacationDetail /> },
    {
      name: '資格キャンペーン', url: '/certificate-campaigns', component: <CertificateCampaignList />, isMenuDisplay: true,
    },
    { name: '資格キャンペーン詳細', url: '/certificate-campaigns/detail/:id', component: <CertificateCampaignDetail /> },
    { name: '資格キャンペーン詳細', url: '/certificate-campaigns/detail', component: <CertificateCampaignDetail /> },
    {
      name: '固定経費', url: '/fixed-expenses', component: <FixedExpenseList />, isMenuDisplay: true,
    },
  ],
  MASTER: [
    {
      name: '資格', url: '/certificates', component: <CertificateList />, isMenuDisplay: true,
    },
    { name: '資格詳細', url: '/certificates/detail/:id', component: <CertificateDetail /> },
    { name: '資格詳細', url: '/certificates/detail', component: <CertificateDetail /> },
    {
      name: '資産', url: '/assets', component: <AssetList />, isMenuDisplay: true,
    },
    { name: '資産詳細', url: '/assets/detail/:id', component: <AssetDetail /> },
    { name: '資産詳細', url: '/assets/detail', component: <AssetDetail /> },
  ],
  FILE: [
    { name: 'ファイル出力', url: '/file-output', component: <Output /> },
    { name: 'ファイル入力', url: '/file-input', component: <Input /> },
  ],
  SUMMARY: [
    {
      name: 'レポート', url: '/reports', component: <ReportList />, isMenuDisplay: true,
    },
    { name: 'レポート詳細', url: '/reports/detail/:id', component: <ReportDetail /> },
    {
      name: 'プロジェクト収支', url: '/project-balances', component: <ProjectBalance />, isMenuDisplay: true,
    },
    { name: 'プロジェクト収支詳細', url: '/project-balances/detail/:id', component: <ProjectBalanceDetail /> },
    {
      name: '部収支', url: '/division-balances', component: <DivisionBalance />, isMenuDisplay: true,
    },
    {
      name: '全体収支', url: '/company-balances', component: <CompanyBalance />, isMenuDisplay: true,
    },
  ],

  PROJECT_BALANCE_DETAIL: '/project-balances/detail/',
  REPORT_DETAIL: '/reports/detail/',
  NOTICE_DETAIL: '/notices/detail/',
  CERTIFICATE_CAMPAIGN_DETAIL: '/certificate-campaigns/detail/',
  CERTIFICATE_DETAIL: '/certificates/detail/',
  ASSET_DETAIL: '/assets/detail/',
  PAY_DETAIL: '/pays/',
  CLAIM_DETAIL: '/claims/detail/',
  INSPECT_DETAIL: '/inspects/detail/',
  PARTNER_DETAIL: '/partners/detail/',
  ORDER_DETAIL: '/orders/detail/',
  ESTIMATE_DETAIL: '/estimates/detail/',
  PROJECT_DETAIL: '/projects/detail/',
  CLIENT_DETAIL: '/clients/detail/',
  SKILL_DETAIL: '/skills/detail/',
  EMPLPYEE_DETAIL: '/employees/detail/',
  SALARY_DETAIL: '/salaries/detail/',
  VACATION_DETAIL: '/vacations/detail/',
  ROLE_DETAIL: '/roles/detail/',
  WORK_REQUEST_DETAIL: '/work-requests/detail/',
  OVERTIME_REQUEST_DETAIL: '/overtime-requests/detail/',
  TRAFFIC_REQUEST_DETAIL: '/traffic-requests/detail/',
  EXPENSE_REQUEST_DETAIL: '/expense-requests/detail/',
};
export default urls;
