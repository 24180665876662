import React from 'react';
import { Box, IconButton } from '@mui/material';
import {
  Business as BusinessIcon, Home as HomeIcon,
  ModeEdit as ModeEditIcon, Delete as DeleteIcon,
  OtherHouses as OtherHousesIcon,
} from '@mui/icons-material';

export default function CalendarContent(props) {
  const {
    data, item, onDayClick, onDayDeleteClick,
  } = props;
  if (!item.isSomeMonth) {
    return (<div />);
  }
  let linkElement = null;
  if (data.id) {
    let icon = data.placeCode === '1' ? <BusinessIcon fontSize="small" /> : <HomeIcon fontSize="small" />;
    if (data.placeCode === '3') {
      icon = <OtherHousesIcon fontSize="small" />;
    }
    linkElement = (
      <Box>
        <Box>
          {icon}
          {data.placeCode === '3' ? data.placeOther : data.placeName}
        </Box>
        <Box>{`${data.startTime}-${data.endTime}`}</Box>
      </Box>
    );
  }

  return (
    <Box>
      <div>{item.day}</div>
      {' '}
      {linkElement}
      {' '}
      <Box>
        <IconButton component="label" onClick={(event) => onDayClick(event, data, item)} size="small">
          <ModeEditIcon />
        </IconButton>
        {data.id && (
          <IconButton component="label" onClick={(event) => onDayDeleteClick(event, data, item)} size="small">
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
