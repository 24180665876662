/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const trafficRequestListSlice = createSlice({
  name: 'trafficRequestList',
  initialState: {
    data: {},
    codes: [],
    searchCondition: null,
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getCodes: (state) => {
      state.codes = [];
    },
    getCodesSuccess: (state, action) => {
      state.codes = action.payload;
    },
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
  },
});

export const trafficRequestListActions = trafficRequestListSlice.actions;

export default trafficRequestListSlice.reducer;
