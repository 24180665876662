import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../constants/serverUrls';
import { commonActions } from './commonState';
import { getDatas } from '../../services/base.service';

function* getStatuses({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CODES, payload);
    yield put(commonActions.getStatusesSuccess(result));
  } catch (_) {}
}

function* getRestCalendars({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.REST_CALENDAR, payload);
    yield put(commonActions.getRestCalendarSuccess(result));
  } catch (_) {}
}

function* getSyainList({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.SYAIN_LISTS, payload);
    yield put(commonActions.getSyainsSuccess(result));
  } catch (_) {}
}

function* getSyainListGreaterThan({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.SYAIN_LISTS_GREATER_THAN, payload);
    yield put(commonActions.getSyainListGreaterThanSuccess(result));
  } catch (_) {}
}

function* getProjects({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.PROJECT_LISTS, payload);
    yield put(commonActions.getProjectsSuccess(result));
  } catch (_) {}
}

function* getAuthorizerList({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.AUTHORIZER_LISTS, payload);
    yield put(commonActions.getAuthorizerSuccess(result));
  } catch (_) {}
}

function* getAddress({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.ADDRESS, payload);
    yield put(commonActions.getAddressSuccess(result));
  } catch (_) {}
}

function* getClients({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CLIENT_LISTS, payload);
    yield put(commonActions.getClientsSuccess(result));
  } catch (_) {}
}

function* getClientContacts({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CONTACT_LISTS, payload);
    yield put(commonActions.getClientContactsSuccess(result));
  } catch (_) {}
}

function* commonSaga() {
  yield takeLatest('common/getStatuses', getStatuses);
  yield takeLatest('common/getRestCalendar', getRestCalendars);
  yield takeLatest('common/getSyainList', getSyainList);
  yield takeLatest('common/getSyainListGreaterThan', getSyainListGreaterThan);
  yield takeLatest('common/getClients', getClients);
  yield takeLatest('common/getAuthorizerList', getAuthorizerList);
  yield takeLatest('common/getProjects', getProjects);
  yield takeLatest('common/getAddress', getAddress);
  yield takeLatest('common/getClientContacts', getClientContacts);
}

export default commonSaga;
