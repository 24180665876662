import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
} from '@mui/material';
import getColumn from '../estimate/estimate-proc';
import DataGridSheet from '../../../components/templates/dataGridSheet/DataGridSheet';
import { orderDetailActions } from '../../../redux/sales/order/orderDetailState';

class OrderDetailInput extends React.Component {
  constructor(props) {
    super(props);
    this.columns = getColumn();
    this.dataGridRef = React.createRef();
    this.state = {
      datas: [],
    };
    props.dispatch(orderDetailActions.getDatas({ orderId: props.id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderDetailInfo.datas !== this.props.orderDetailInfo.datas) {
      if (this.props.orderDetailInfo.datas) {
        this.setDatas(this.props.orderDetailInfo.datas);
      }
    }
    if (prevProps.orderDetailInfo.isSaveSuccess
      !== this.props.orderDetailInfo.isSaveSuccess) {
      if (this.props.orderDetailInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setDataGridRef = (ref) => {
    this.dataGridRef = ref;
  };

  onSave = (datas) => {
    this.props.dispatch(orderDetailActions.saveData({
      orderId: this.props.id,
      datas,
    }));
  };

  setSuccess = () => {
    this.props.onSaveSuccess();
    this.props.dispatch(orderDetailActions.getDatas({ orderId: this.props.id }));
  };

  render() {
    const { datas } = this.state;
    const { isDisabled } = this.props;
    return (
      <Box>
        <DataGridSheet
          data={datas}
          isDisabled={isDisabled}
          onSave={this.onSave}
          dataGridRef={this.dataGridRef}
          setDataGridRef={this.setDataGridRef}
          columns={this.columns}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  orderDetailInfo: state.orderDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(OrderDetailInput);
