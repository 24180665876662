import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { TextField, Autocomplete } from '@mui/material';
import { commonActions } from '../../../../redux/common/commonState';

class SelectExpenseSyain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.props.dispatch(
      commonActions.getSyainList({
        targetMonth: format(new Date(), 'yyyy/MM/dd'),
        notBp: true,
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.syains !== this.props.common.syains) {
      if (this.props.common.syains) {
        this.setData(this.props.common.syains);
      }
    }
  }

  setData = (options) => {
    this.setState({ options });
  };

  render() {
    const { options } = this.state;
    const {
      value, error, onChange,
    } = this.props;

    return (
      <Autocomplete
        size="small"
        options={options}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
        renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} placeholder="選択してください" />}
        value={value}
        onChange={(e, selectValue) => {
          onChange(e, selectValue);
        }}
        noOptionsText="データがありません"
      />
    );
  }
}

const mapStateToProps = (state) => ({ common: state.commonStore });

export default connect(mapStateToProps)(SelectExpenseSyain);
