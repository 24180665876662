/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const roleDetailSlice = createSlice({
  name: 'roleDetail',
  initialState: {
    screens: [],
    data: {},
    saveId: null,
    isSaveSuccess: false,
    isUpdateSuccess: false,
    isSaveScreenSuccess: false,
  },
  reducers: {
    clearFlag: (state) => {
      state.isSaveSuccess = false;
    },
    getScreen: (state) => {
      state.screens = null;
    },
    getScreenSuccess: (state, action) => {
      state.screens = action.payload;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.isUpdateSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    saveScreenData: (state) => {
      state.isSaveScreenSuccess = false;
    },
    saveScreenDataSuccess: (state) => {
      state.isSaveScreenSuccess = true;
    },
  },
});

export const roleDetailActions = roleDetailSlice.actions;

export default roleDetailSlice.reducer;
