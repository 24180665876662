import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
} from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { workStyleActions } from '../../../redux/setting/workStyle/workStyleState';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import DataTable from './DataTable';
import WorkStyleDetailDialog from './WorkStyleDetailDialog';

class WorkStyleList extends React.Component {
  constructor(props) {
    super(props);

    const searchCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: 'sort_order',
    };

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isDetailOpen: false,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workStyleInfo.datas !== this.props.workStyleInfo.datas) {
      if (this.props.workStyleInfo.datas) {
        this.setDatas(this.props.workStyleInfo.datas);
      }
    }
    if (prevProps.workStyleInfo.isDeleteSuccess
      !== this.props.workStyleInfo.isDeleteSuccess) {
      if (this.props.workStyleInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess = (successMessage) => {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage, isDetailOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(workStyleActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClose = () => {
    this.setState({ isSuccessOpen: false, isDetailOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(workStyleActions.deleteData({ ids }));
  };

  onDetail = (_, selectedId) => {
    this.setState({ selectedId, isDetailOpen: true, isSuccessOpen: false });
  };

  onAdd = () => {
    this.setState({ selectedId: null, isDetailOpen: true, isSuccessOpen: false });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      isDetailOpen,
      selectedId,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
          onDetail={this.onDetail}
          onAdd={this.onAdd}
        />

        <WorkStyleDetailDialog
          id={selectedId}
          isOpen={isDetailOpen}
          onClose={this.onClose}
          onSaveSuccess={this.setSuccess}
          isDisabled={isDisabled}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  workStyleInfo: state.workStyleStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(WorkStyleList);
