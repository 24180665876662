import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import './dropzone.css';

export default function Dropzone(props) {
  const {
    multiple,
    handleChange,
  } = props;

  return (
    <FileUploader
      multiple={multiple}
      handleChange={handleChange}
      name="file"
      classes="dropzone-root"
      label="クリックしてファイルを選択 または 画像をドラッグ&ドロップする"
    />
  );
}
