import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper, Box, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import { workResultActions } from '../../../redux/work/workResult/workResultState';

export default function DataTable(props) {
  const headCells = [
    { id: 'syain_mei', label: '名前' },
    { id: 'approver_flg', label: '承認' },
    { id: 'work_tm', label: '作業' },
    { id: 'over_tm', label: '残業' },
    { id: 'htg_tm', label: '法定外' },
    { id: 'sytg_tm', label: '所定外' },
    { id: 'holid_work_tm', label: '休日' },
    { id: 'htkyjt_tm', label: '法定休日' },
    { id: 'mid_over_tm', label: '深夜' },
    { id: 'kjy_tm', label: '控除' },
    { id: 'htg_cnt', label: '45超過' },
    { id: 'no_work_cnt', label: '欠勤' },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
  } = props;

  const onClick = (e, row) => {
    dispatch(workResultActions.setSearchCondition({
      syainId: row.id, targetMonth: props.lastMonth,
    }));
    navigate('/work-results');
  };

  const toStrTime = (time) => {
    if (!time || time === 0) return '0';
    return `${Math.floor(time / 60)}:${String(time > 0 ? time % 60 : -time % 60).padStart(2, '0')}`;
  };

  return (
    <Box mt={1}>
      <Paper>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell><Button variant="text" onClick={(e) => onClick(e, row)}>{row.syainMei}</Button></TableCell>
                  <TableCell>{row.approverFlg}</TableCell>
                  <TableCell>{toStrTime(row.workTm)}</TableCell>
                  <TableCell>{toStrTime(row.overTm)}</TableCell>
                  <TableCell>{toStrTime(row.htgTm)}</TableCell>
                  <TableCell>{toStrTime(row.sytgTm)}</TableCell>
                  <TableCell>{toStrTime(row.holidWorkTm)}</TableCell>
                  <TableCell>{toStrTime(row.htkyjtTm)}</TableCell>
                  <TableCell>{toStrTime(row.midOverTm)}</TableCell>
                  <TableCell>{toStrTime(row.kjyTm)}</TableCell>
                  <TableCell>{row.htgCnt}</TableCell>
                  <TableCell>{row.noWorkCnt}</TableCell>
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
