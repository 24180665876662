import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { workRequestDetailActions } from './workRequestDetailState';
import {
  getDatas, getDataById, saveData, updateData,
} from '../../../services/base.service';

function* getCode() {
  try {
    const result = yield call(getDatas, REST_API.COMMON.CODES, { code: 'WORK_STATE' });
    yield put(workRequestDetailActions.getCodeSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.REQUEST.WORK_REQUEST.WORK_REQUEST_ID, payload);
    yield put(workRequestDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.WORK_REQUEST.WORK_REQUEST, payload);
    yield put(workRequestDetailActions.saveDataSuccess(result));
  } catch (_) {}
}

function* saveStatus({ payload }) {
  try {
    yield call(updateData, REST_API.REQUEST.WORK_REQUEST.WORK_REQUEST_ID, payload);
    yield put(workRequestDetailActions.saveStatusSuccess());
  } catch (_) {}
}

function* workRequestDetailSaga() {
  yield takeLatest('workRequestDetail/getCode', getCode);
  yield takeLatest('workRequestDetail/getData', getData);
  yield takeLatest('workRequestDetail/saveData', saveDatas);
  yield takeLatest('workRequestDetail/saveStatus', saveStatus);
}

export default workRequestDetailSaga;
