import React from 'react';
import { connect } from 'react-redux';
import {
  DialogContent, Button,
  DialogActions, TextField,
  Box, DialogTitle, Grid, LinearProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';
import DatePicker from '../../../../components/atoms/datePicker/DatePicker';
import Validation from '../validation';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import { trafficRequestInputActions } from '../../../../redux/request/trafficRequest/trafficRequestInputState';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
import MoneyInput from '../../../../components/atoms/moneyInput/MoneyInput';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';

const tempForm = {
  targetDay: '',
  content: '',
  rideRoute: '',
  ridePoint: '',
  rideOffRoute: '',
  rideOffPoint: '',
  category: '0',
  fee: '',
  regularStartDate: '',
  regularEndDate: '',
};

const tempError = {
  targetDay: '',
  content: '',
  rideRoute: '',
  ridePoint: '',
  rideOffRoute: '',
  rideOffPoint: '',
  category: '',
  fee: '',
  regularStartDate: '',
  regularEndDate: '',
};

class TrafficRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      loading: false,
      btnLoading: false,
      form: tempForm,
      errorMessages: tempError,
      isSuccessOpen: false,
    };
    props.dispatch(trafficRequestInputActions.getCodes());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trafficRequestInputInfo.codes !== this.props.trafficRequestInputInfo.codes) {
      if (this.props.trafficRequestInputInfo.codes) {
        this.setCodes(this.props.trafficRequestInputInfo.codes);
      }
    }
    if (prevProps.trafficRequestInputInfo.data !== this.props.trafficRequestInputInfo.data) {
      if (this.props.trafficRequestInputInfo.data) {
        this.setData(this.props.trafficRequestInputInfo.data);
      }
    }
    if (prevProps.trafficRequestInputInfo.isSaveSuccess
      !== this.props.trafficRequestInputInfo.isSaveSuccess) {
      if (this.props.trafficRequestInputInfo.isSaveSuccess) {
        this.setSaveSuccess(this.props.trafficRequestInputInfo.saveId);
      }
    }
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  setSaveSuccess = (id) => {
    const { form } = this.state;
    const tmpForm = {
      ...form,
      id,
    };
    this.setState({ form: tmpForm, isSuccessOpen: true, btnLoading: false });
  };

  setData = (form) => {
    this.setState({ form, loading: false });
  };

  init = () => {
    const { id } = this.props;
    let loading = false;
    if (id) {
      loading = true;
      this.props.dispatch(trafficRequestInputActions.getData({ id }));
    }
    this.setState({
      loading, form: tempForm, errorMessages: tempError, isSuccessOpen: false,
    });
  };

  setCodes = (codes) => {
    this.setState({ codes });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessages } = this.state;
    const tmpForm = {
      ...form,
      [name]: value,
    };
    this.setState({
      form: tmpForm,
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value, form.category),
      },
    });
  };

  onSave = () => {
    const { form, errorMessages } = this.state;
    const { header } = this.props;
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.category);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.regularStartDate && !tempMessage.regularEndDate && form.category === '0') {
      const msg = Validation.compareDateValidate(form.regularStartDate, form.regularEndDate);
      if (msg) {
        isError = true;
      }
      tempMessage.regularEndDate = msg;
    }

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false, btnLoading: !isError });

    if (isError) {
      return;
    }

    const tmpForm = {
      ...form,
      parentId: header.id,
      content: form.category === '0' ? `${form.regularStartDate}~${form.regularEndDate}` : form.content,
    };

    this.props.dispatch(trafficRequestInputActions.saveData(tmpForm));
  };

  onClickCopy = () => {
    const { form } = this.state;
    const tmpForm = {
      ...form,
      id: null,
    };
    this.setState({ form: tmpForm });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      open, onClose,
    } = this.props;
    const {
      loading, btnLoading, form, errorMessages, codes, isSuccessOpen,
    } = this.state;
    return (
      <MaterialDialog maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
          交通費新規申請
          {form.id && (
            <Button
              sx={{
                position: 'absolute',
                right: 8,
              }}
              onClick={this.onClickCopy}
            >
              コピーする
            </Button>
          )}
        </DialogTitle>
        {loading && (<LinearProgress />)}
        {!loading && (
          <DialogContent dividers>
            <Box>
              <FormTitle title="日付" isRequired />
              <DatePicker
                handleChange={this.onChange}
                // minDate={format(new Date(`${header.internalMonth}/01`), 'yyyy/MM/dd')}
                // maxDate={format(endOfMonth(new
                // Date(`${header.internalMonth}/01`)), 'yyyy/MM/dd')}
                name="targetDay"
                value={form.targetDay}
                error={errorMessages.targetDay !== ''}
              />
              <FormErrorText>{errorMessages.targetDay}</FormErrorText>
            </Box>
            <Box mt={2}>
              <FormTitle title="区分" isRequired />
              <SearchSelectBox options={codes} disableClearable onChange={this.onChange} name="category" value={form.category} error={errorMessages.category !== ''} />
              <FormErrorText>{errorMessages.category}</FormErrorText>
            </Box>
            {form.category === '0' && (
              <Box mt={2}>
                <FormTitle title="定期期間" isRequired />
                <Box display="flex" alignItems="center">
                  <Box>
                    <DatePicker handleChange={this.onChange} name="regularStartDate" value={form.regularStartDate} error={errorMessages.regularStartDate !== ''} />
                    <FormErrorText>{errorMessages.regularStartDate}</FormErrorText>
                  </Box>
                  <Box px={2}>～</Box>
                  <Box>
                    <DatePicker handleChange={this.onChange} name="regularEndDate" value={form.regularEndDate} error={errorMessages.regularEndDate !== ''} />
                    <FormErrorText>{errorMessages.regularEndDate}</FormErrorText>
                  </Box>
                </Box>
              </Box>
            )}
            {form.category !== '0' && (
              <Box mt={2}>
                <FormTitle title="用途" isRequired />
                <TextField size="small" fullWidth variant="outlined" name="content" error={errorMessages.content !== ''} value={form.content} onChange={this.onChange} />
                <FormErrorText>{errorMessages.content}</FormErrorText>
              </Box>
            )}
            <Box mt={2}>
              <FormTitle title="金額" isRequired />
              <MoneyInput fullWidth value={form.fee} name="fee" error={errorMessages.fee !== ''} onChange={this.onChange} />
              <FormErrorText>{errorMessages.fee}</FormErrorText>
            </Box>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={6}>
                <FormTitle title="乗車路線" isRequired />
                <TextField size="small" fullWidth variant="outlined" error={errorMessages.rideRoute !== ''} name="rideRoute" value={form.rideRoute} onChange={this.onChange} />
                <FormErrorText>{errorMessages.rideRoute}</FormErrorText>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="乗車駅" isRequired />
                <TextField size="small" fullWidth variant="outlined" error={errorMessages.ridePoint !== ''} name="ridePoint" value={form.ridePoint} onChange={this.onChange} />
                <FormErrorText>{errorMessages.ridePoint}</FormErrorText>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={6}>
                <FormTitle title="降車路線" isRequired />
                <TextField size="small" fullWidth variant="outlined" error={errorMessages.rideOffRoute !== ''} name="rideOffRoute" value={form.rideOffRoute} onChange={this.onChange} />
                <FormErrorText>{errorMessages.rideOffRoute}</FormErrorText>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="降車駅" isRequired />
                <TextField size="small" fullWidth variant="outlined" error={errorMessages.rideOffPoint !== ''} name="rideOffPoint" value={form.rideOffPoint} onChange={this.onChange} />
                <FormErrorText>{errorMessages.rideOffPoint}</FormErrorText>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        {!loading && (
          <DialogActions>
            <LoadingButton onClick={onClose} loading={btnLoading} color="inherit">キャンセル</LoadingButton>
            <LoadingButton
              onClick={this.onSave}
              loading={btnLoading}
              color="primary"
            >
              保存
            </LoadingButton>
          </DialogActions>
        )}
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message="保存処理が完了しました"
        />
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  trafficRequestInputInfo: state.trafficRequestInputStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(TrafficRequestDetail);
