/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { inspectActions } from './inspectState';
import {
  getDatas, deleteDatas, saveData, getDataById, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.INSPECT.INSPECT, payload);
    yield put(inspectActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.INSPECT.INSPECT, payload);
    yield put(inspectActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.INSPECT.INSPECT_ID, payload);
    yield put(inspectActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.INSPECT.INSPECT, payload);
    yield put(inspectActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.INSPECT.INSPECT, payload);
    yield put(inspectActions.updateDataSuccess(result));
  } catch (_) {}
}

function* updateStatus({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.INSPECT.INSPECT_ID, payload);
    yield put(inspectActions.updateStatusSuccess());
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.INSPECT.INSPECT_SELECT, payload);
    yield put(inspectActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getPdfData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.INSPECT.INSPECT_PDF, payload);
    yield put(inspectActions.getPdfDataSuccess(result));
  } catch (_) {}
}

function* updatePdfData({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.INSPECT.INSPECT_PDF, payload);
    yield put(inspectActions.updatePdfDataSuccess());
  } catch (_) {}
}

function* copyToClaim({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.INSPECT.INSPECT_COPY_CLAIM, payload);
    yield put(inspectActions.copyToClaimSuccess(result));
  } catch (_) {}
}

function* inspectSaga() {
  yield takeLatest('inspect/getDatas', getData);
  yield takeLatest('inspect/deleteData', deleteData);
  yield takeLatest('inspect/getData', getDataFromId);
  yield takeLatest('inspect/insertData', insertData);
  yield takeLatest('inspect/updateData', updateDatas);
  yield takeLatest('inspect/updateStatus', updateStatus);
  yield takeLatest('inspect/getSelect', getSelect);
  yield takeLatest('inspect/getPdfData', getPdfData);
  yield takeLatest('inspect/updatePdfData', updatePdfData);
  yield takeLatest('inspect/copyToClaim', copyToClaim);
}

export default inspectSaga;
