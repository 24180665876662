/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Table, TableContainer, IconButton, Select, MenuItem,
  TableHead, TableRow, TableBody, Tooltip, FormControl,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { addMonths, format } from 'date-fns';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
  Create as CreateIcon,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../../components/atoms/withParam/WithParams';
import { projectBalanceActions } from '../../../../redux/summary/projectBalance/projectBalanceState';
import {
  getMonths, getTargetYear, getData, getMonthSummary, getProjectSummary, getSummary,
} from './project-balance-proc';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
import urls from '../../../../constants/frontUrls';

const HeaderTd = styled('th')(() => ({
  border: 'solid 1px #DDD',
  fontSize: '13px',
}));

const DataTr = styled(TableRow)(({ index }) => ({
  ...(index % 2 === 0 && {
    background: '#f5f5f5',
  }),
}));

const TitleTd = styled('td')(({ isEnd, isNotTop, isHover }) => ({
  ...(!isEnd && {
    borderBottom: 'solid 1px #DDD',
  }),
  ...(!isNotTop && {
    borderTop: 'solid 1px #DDD',
  }),
  borderLeft: 'solid 1px #DDD',
  borderRight: 'solid 1px #DDD',
  fontSize: '15px',
  paddingRight: 3,
  ...(isHover && {
    '&:hover': {
      cursor: 'pointer',
    },
  }),

}));

const DataTd = styled('td')(({ isEnd, isNotTop }) => ({
  ...(!isEnd && {
    borderBottom: 'solid 1px #DDD',
  }),
  ...(!isNotTop && {
    borderTop: 'solid 1px #DDD',
  }),
  borderTop: 'solid 1px #DDD',
  borderLeft: 'solid 1px #DDD',
  borderRight: 'solid 1px #DDD',
  fontSize: '15px',
  textAlign: 'right',
  paddingRight: 3,
}));

class ProjectBalance extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const condition = getTargetYear();
    let searchCondition = {
      startMonth: condition.startMonth,
      endMonth: condition.endMonth,
      projectId: id,
    };

    if (props.projectBalanceInfo.searchCondition) {
      searchCondition = props.projectBalanceInfo.searchCondition;
    }
    this.state = {
      searchCondition,
      months: getMonths(searchCondition.startMonth, searchCondition.endMonth),
      datas: [],
      projects: [],
      select: {
        departments: [],
        divisions: [],
      },
      selectedId: '',
    };
    this.search(searchCondition);
    props.dispatch(projectBalanceActions.getListSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectBalanceInfo.balanceData !== this.props.projectBalanceInfo.balanceData) {
      if (this.props.projectBalanceInfo.balanceData) {
        this.setData(this.props.projectBalanceInfo.balanceData);
      }
    }
    if (prevProps.projectBalanceInfo.selectList !== this.props.projectBalanceInfo.selectList) {
      if (this.props.projectBalanceInfo.selectList) {
        this.setSelect(this.props.projectBalanceInfo.selectList);
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setData = (data) => {
    this.setState({ datas: data.datas, projects: data.projects });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
  };

  search = (searchCondition) => {
    if (!searchCondition.departmentId || !searchCondition.divisionId) {
      return;
    }
    this.props.dispatch(projectBalanceActions.setSearchCondition(searchCondition));
    this.props.dispatch(projectBalanceActions.getData(searchCondition));
  };

  onClickBefore = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.startMonth), -6), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.startMonth), -1), 'yyyy/MM/dd'),
    };
    this.setState({
      searchCondition: tmpCondition,
      months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth),
    });
    this.search(tmpCondition);
  };

  onClickAfter = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.endMonth), 1), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.endMonth), 6), 'yyyy/MM/dd'),
    };
    this.setState({
      searchCondition: tmpCondition,
      months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth),
    });
    this.search(tmpCondition);
  };

  onProjectSelect = (_, projectId) => {
    const { selectedId } = this.state;
    let tmpProject = projectId;
    if (selectedId) {
      tmpProject = '';
    }
    this.setState({ selectedId: tmpProject });
  };

  render() {
    const {
      searchCondition, months, datas, select, projects, selectedId,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Box display="flex" mb={2}>
          <Box display="flex" alignItems="center">
            <Tooltip title="前へ">
              <IconButton color="primary" size="small" onClick={this.onClickBefore}>
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">{format(new Date(searchCondition.startMonth), 'yyyy年MM月')}</Box>
          <Box display="flex" alignItems="center" mx={1}>～</Box>
          <Box display="flex" alignItems="center">{format(new Date(searchCondition.endMonth), 'yyyy年MM月')}</Box>
          <Box>
            <Tooltip title="次へ">
              <IconButton color="primary" size="small" onClick={this.onClickAfter}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <SearchSelectBox
              name="departmentId"
              disableClearable
              options={select.departments}
              value={searchCondition.departmentId}
              onChange={this.onChange}
            />
          </Box>
          <Box>
            <FormControl>
              <Select
                value={searchCondition.divisionId}
                name="divisionId"
                onChange={this.onChange}
              >
                {select.divisions.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" ml={1}>
            <LoadingButton
              variant="contained"
              size="small"
              loading={this.props.common.isLoading}
              onClick={this.onSearch}
            >
              検索
            </LoadingButton>
          </Box>
        </Box>
        {projects.length > 0 && (
          <Box mt={1} mb={3}>
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table
                width="100%"
                stickyHeader
                sx={{
                  border: 'solid 1px #DDD',
                  borderCollapse: 'collapse',
                  textAlign: 'center',
                }}
              >
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: 0,
                    background: '#fff',
                  }}
                >
                  <TableRow>
                    <HeaderTd colSpan={3} />
                    {months.map((item) => (
                      <HeaderTd key={`${item.targetDate}s`}>{item.displayDate}</HeaderTd>
                    ))}
                    <HeaderTd>合計</HeaderTd>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TitleTd rowSpan={projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).length + 2} isEnd style={{ width: '20px', padding: 3, background: '#FCE4D6' }}>売上</TitleTd>
                  </TableRow>
                  {projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).map((project) => (
                    <React.Fragment key={`${project.projectId}uriagetitle`}>
                      <DataTr hover>
                        <TitleTd style={{ width: '20px' }}>
                          <Tooltip title="詳細">
                            <IconButton size="small" href={urls.PROJECT_BALANCE_DETAIL + project.projectId} target="_blank" style={{ padding: '0px' }}>
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                        </TitleTd>
                        <TitleTd isHover style={{ width: '500px', background: project.finished ? '#E6E6E6' : '' }} onClick={(e) => this.onProjectSelect(e, project.projectId)}>{project.projectName}</TitleTd>
                        {months.map((item) => (
                          <DataTd key={`${project.projectId}uriage${item.targetDate}`}>
                            {getData(datas, project.projectId, item.targetDate, 'uriage')}
                          </DataTd>
                        ))}
                        <DataTd>{getProjectSummary(datas, project.projectId, 'uriage')}</DataTd>
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <TableRow style={{ borderBottom: '2px solid #000' }}>
                    <TitleTd isEnd colSpan={2}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd isEnd key={`uriage${item.targetDate}Sum`}>
                        {getMonthSummary(datas, item.targetDate, 'uriage')}
                      </DataTd>
                    ))}
                    <DataTd isEnd>{getSummary(datas, 'uriage')}</DataTd>
                  </TableRow>
                  <TableRow>
                    <TitleTd rowSpan={projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).length + 2} style={{ width: '20px', padding: 3, background: '#D9E1F2' }}>原価</TitleTd>
                  </TableRow>
                  {projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).map((project) => (
                    <React.Fragment key={`${project.projectId}genkatitle`}>
                      <DataTr hover>
                        <TitleTd isNotTop colSpan={2}>{project.projectName}</TitleTd>
                        {months.map((item) => (
                          <DataTd key={`${project.projectId}genka${item.targetDate}`} isNotTop>
                            {getData(datas, project.projectId, item.targetDate, 'genka')}
                          </DataTd>
                        ))}
                        <DataTd isNotTop>{getProjectSummary(datas, project.projectId, 'genka')}</DataTd>
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <DataTr style={{ borderBottom: '2px solid #000' }}>
                    <TitleTd isEnd colSpan={2}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd isEnd key={`genka${item.targetDate}Sum`}>
                        {getMonthSummary(datas, item.targetDate, 'genka')}
                      </DataTd>
                    ))}
                    <DataTd isEnd>{getSummary(datas, 'genka')}</DataTd>
                  </DataTr>
                  <DataTr>
                    <TitleTd rowSpan={projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).length + 2} style={{ width: '20px', padding: 3, background: '#FFF2CC' }}>仕掛</TitleTd>
                  </DataTr>
                  {projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).map((project) => (
                    <React.Fragment key={`shikakari${project.projectId}title`}>
                      <DataTr hover>
                        <TitleTd isNotTop colSpan={2}>{project.projectName}</TitleTd>
                        {months.map((item) => (
                          <DataTd isNotTop key={`${project.projectId}shikakari${item.targetDate}`}>
                            {getData(datas, project.projectId, item.targetDate, 'shikakari')}
                          </DataTd>
                        ))}
                        <DataTd isNotTop />
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <DataTr style={{ borderBottom: '2px solid #000' }}>
                    <TitleTd isEnd colSpan={2}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd isEnd key={`shikakari${item.targetDate}Sum`}>
                        {getMonthSummary(datas, item.targetDate, 'shikakari')}
                      </DataTd>
                    ))}
                    <DataTd isEnd />
                  </DataTr>
                  <DataTr>
                    <TitleTd rowSpan={projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).length + 2} style={{ width: '20px', padding: 3, background: '#FFFD9B' }}>粗利</TitleTd>
                  </DataTr>
                  {projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).map((project) => (
                    <React.Fragment key={`arari${project.projectId}title`}>
                      <DataTr hover>
                        <TitleTd isNotTop colSpan={2}>{project.projectName}</TitleTd>
                        {months.map((item) => (
                          <DataTd isNotTop key={`${project.projectId}arari${item.targetDate}`}>
                            {getData(datas, project.projectId, item.targetDate, 'arari')}
                          </DataTd>
                        ))}
                        <DataTd isNotTop>{getProjectSummary(datas, project.projectId, 'arari')}</DataTd>
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <DataTr style={{ borderBottom: '2px solid #000' }}>
                    <TitleTd isEnd colSpan={2}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd isEnd key={`arari${item.targetDate}Sum`}>
                        {getMonthSummary(datas, item.targetDate, 'arari')}
                      </DataTd>
                    ))}
                    <DataTd isEnd>{getSummary(datas, 'arari')}</DataTd>
                  </DataTr>

                  <DataTr>
                    <TitleTd rowSpan={projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).length + 2} style={{ width: '20px', padding: 3, background: '#FFCCFF' }}>粗利率</TitleTd>
                  </DataTr>
                  {projects.filter((v) => v.projectId.indexOf(selectedId) !== -1).map((project) => (
                    <React.Fragment key={`arariRitsu${project.projectId}title`}>
                      <DataTr hover>
                        <TitleTd colSpan={2}>{project.projectName}</TitleTd>
                        {months.map((item) => (
                          <DataTd key={`${project.projectId}arariRitsu${item.targetDate}`}>
                            {getData(datas, project.projectId, item.targetDate, 'arariRitsu')}
                          </DataTd>
                        ))}
                        <DataTd>{getProjectSummary(datas, project.projectId, 'arariRitsu')}</DataTd>
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <DataTr>
                    <TitleTd colSpan={2}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`arariRitsu${item.targetDate}Sum`}>
                        {getMonthSummary(datas, item.targetDate, 'arariRitsu')}
                      </DataTd>
                    ))}
                    <DataTd>{getSummary(datas, 'arariRitsu')}</DataTd>
                  </DataTr>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  projectBalanceInfo: state.projectBalanceStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(ProjectBalance));
