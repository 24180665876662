/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { salaryDetailActions } from './salaryDetailState';
import { getDataById, saveData, updateData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OFFICE.SALARY.SALARY_ID, payload);
    yield put(salaryDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OFFICE.SALARY.SALARY, payload);
    yield put(salaryDetailActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    yield call(updateData, REST_API.OFFICE.SALARY.SALARY_ID, payload);
    yield put(salaryDetailActions.updateDataSuccess());
  } catch (_) {}
}

function* recalc({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OFFICE.SALARY.SALARY_RECALC, payload);
    yield put(salaryDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* recalcInsurance({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OFFICE.SALARY.SALARY_RECALC_INSURANCE, payload);
    yield put(salaryDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* salaryDetailSaga() {
  yield takeLatest('salaryDetail/getData', getData);
  yield takeLatest('salaryDetail/insertData', insertData);
  yield takeLatest('salaryDetail/updateData', updateDatas);
  yield takeLatest('salaryDetail/recalc', recalc);
  yield takeLatest('salaryDetail/recalcInsurance', recalcInsurance);
}

export default salaryDetailSaga;
