import React from 'react';
import {
  Typography,
  Accordion as MuiAccordion,
  AccordionActions as MuiAccordionActions,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  experimentalStyled as styled,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Accordion = styled((props) => (
  <MuiAccordion defaultExpanded disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionActions = styled(MuiAccordionActions)(() => ({
  justifyContent: 'center',
}));

export default function SearchBox(props) {
  const { onSearch, onClear, children } = props;

  return (
    <Accordion style={{ marginBottom: '10px' }}>
      <AccordionSummary style={{ height: '40px', minHeight: '0px' }}>
        <Typography>検索条件</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
      <AccordionActions>
        {onClear && (
          <Button variant="contained" size="large" onClick={onClear}>
            クリアする
          </Button>
        )}
        <Button variant="contained" color="primary" size="medium" onClick={onSearch}>
          検索する
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

SearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onClear: PropTypes.func,
};

SearchBox.defaultProps = {
  onClear: null,
};
