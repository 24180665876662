/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { employeeHistoryActions } from './employeeHistoryState';
import {
  getDatas, deleteDatas, saveData, updateData, getDataById,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_HISTORY, payload);
    yield put(employeeHistoryActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_HISTORY, payload);
    yield put(employeeHistoryActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_HISTORY_ID, payload);
    yield put(employeeHistoryActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    yield call(saveData, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_HISTORY, payload);
    yield put(employeeHistoryActions.saveDataSuccess());
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    yield call(updateData, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_HISTORY_ID, payload);
    yield put(employeeHistoryActions.saveDataSuccess());
  } catch (_) {}
}

function* employeeHistorySaga() {
  yield takeLatest('employeeHistory/getDatas', getData);
  yield takeLatest('employeeHistory/deleteData', deleteData);
  yield takeLatest('employeeHistory/getData', getDataFromId);
  yield takeLatest('employeeHistory/insertData', insertData);
  yield takeLatest('employeeHistory/updateData', updateDatas);
}

export default employeeHistorySaga;
