/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { claimDetailActions } from './claimDetailState';
import {
  getDatas, saveData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.CLAIM.CLAIM_DETAIL, payload);
    yield put(claimDetailActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.SALES.CLAIM.CLAIM_DETAIL, payload);
    yield put(claimDetailActions.saveDataSuccess());
  } catch (_) {}
}

function* claimDetailSaga() {
  yield takeLatest('claimDetail/getDatas', getData);
  yield takeLatest('claimDetail/saveData', saveDatas);
}

export default claimDetailSaga;
