/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    datas: {},
    data: {},
    select: {},
    searchCondition: null,
    isDeleteSuccess: false,
    isCreateSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getCategory: (state) => {
      state.category = null;
    },
    getCategorySuccess: (state, action) => {
      state.category = action.payload;
    },
    getSyain: (state) => {
      state.syains = null;
    },
    getSyainSuccess: (state, action) => {
      state.syains = action.payload;
    },
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    clearFlag: (state) => {
      state.isCreateSuccess = false;
      state.isUpdateSuccess = false;
    },
    saveData: (state) => {
      state.isCreateSuccess = false;
      state.isUpdateSuccess = false;
    },
    createDataSuccess: (state, action) => {
      state.isCreateSuccess = true;
      state.saveId = action.payload;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    saveDetailData: (state) => {
      state.isSaveDetailSuccess = false;
    },
    saveDetailDataSuccess: (state) => {
      state.isSaveDetailSuccess = true;
    },
  },
});

export const reportActions = reportSlice.actions;

export default reportSlice.reducer;
