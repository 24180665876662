import React from 'react';
import { Box } from '@mui/material';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';

export default function Input(props) {
  const {
    title, name, onChange, data, onBlur,
  } = props;

  return (
    <Box mt={3}>
      <FormTitle title={title} />
      <MoneyInput fullWidth value={data[name]} name={name} onChange={onChange} onBlur={onBlur} />
    </Box>
  );
}
