import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { workCostListActions } from './workCostListState';
import { getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_COST_LIST, payload);
    yield put(workCostListActions.getDataSuccess(result));
  } catch (_) {}
}

function* workCostListSaga() {
  yield takeLatest('workCostList/getData', getData);
}

export default workCostListSaga;
