export function checkIsDisabled(user) {
  const urls = window.location.pathname.split('/');
  if (urls.length <= 1) {
    return true;
  }
  const url = `/${urls[1]}`;
  const roleScreenList = user ? user.roleScreenList : [];
  const urlKey = 'url';
  const editKey = 'edit';
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i][urlKey] === url) {
      return !roleScreenList[i][editKey];
    }
  }
  return true;
}

export function checkIsDisabledFromUrl(user, urls) {
  if (urls.length <= 1) {
    return true;
  }
  const url = urls;
  const roleScreenList = user ? user.roleScreenList : [];
  const urlKey = 'url';
  const editKey = 'edit';
  for (let i = 0; i < roleScreenList.length; i += 1) {
    if (roleScreenList[i][urlKey] === url) {
      return !roleScreenList[i][editKey];
    }
  }
  return true;
}

export function getParentPath() {
  const urls = window.location.pathname.split('/');
  if (urls.length <= 1) {
    return '/';
  }
  const url = `/${urls[1]}`;
  return url;
}
