/* eslint-disable class-methods-use-this */
import React from 'react';
import ExcelJS from 'exceljs';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Button, Box, Alert, TextField, Autocomplete,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../../../components/atoms/monthPicker/MonthPicker';
import { outputActions } from '../../../../redux/file/output/outputState';
import createData from './work-sheet-bp-proc';
import { commonActions } from '../../../../redux/common/commonState';

class WorkSheetBp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
      options: [],
      bpId: '',
    };
    this.getBp(format(new Date(), 'yyyy/MM/dd'));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.outputInfo.workSheetsBp !== this.props.outputInfo.workSheetsBp) {
      if (this.props.outputInfo.workSheetsBp) {
        this.setDatas(this.props.outputInfo.workSheetsBp);
      }
    }
    if (prevProps.common.syains !== this.props.common.syains) {
      if (this.props.common.syains) {
        this.setoptions(this.props.common.syains);
      }
    }
  }

  setoptions = (options) => {
    this.setState({ options });
  };

  setDatas = (data) => {
    if (data.length <= 0) {
      this.props.onNotData();
      return;
    }
    this.download(data);
  };

  download = async (datas) => {
    const { targetMonth, bpId } = this.state;
    const targetStrMonth = format(new Date(targetMonth), 'yyyy年MM月');
    const res = await axios.get('/作業報告書BP.xlsx', { responseType: 'arraybuffer' });
    const data = new Uint8Array(res.data);
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;
    await workbook.xlsx.load(data);
    createData(workbook, datas, targetStrMonth);
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `作業報告書_${targetStrMonth}分_${bpId.name}.xlsx`;
    a.click();
    a.remove();
    this.props.onNotLoading();
  };

  onGetData = () => {
    const { targetMonth, bpId } = this.state;
    this.props.onLoading();
    this.props.dispatch(outputActions.getWorkSheetsBp({ targetMonth, bpId: bpId.id }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'targetMonth') {
      this.getBp(value);
    }
    this.setState({ [name]: value });
  };

  dateValidation = (value, bpId) => {
    if (!value || !bpId) return true;
    if (!isValid(new Date(value))) return true;
    return false;
  };

  getBp = (targetMonth) => {
    this.props.dispatch(
      commonActions.getSyainList({
        targetMonth,
        onlyBp: true,
      }),
    );
  };

  render() {
    const { targetMonth, options, bpId } = this.state;

    return (
      <Box>
        <Alert severity="warning">
          <Box>未承認データは想定通りに出力されません</Box>
          <Box>作業時間丸め単位はパートナーマスタで設定してください</Box>
        </Alert>
        <Box mt={2}>
          <FormTitle title="出力年月" isRequired />
          <MonthPicker
            handleChange={this.onChange}
            value={targetMonth}
            name="targetMonth"
          />
        </Box>
        <Box mt={2} style={{ maxWidth: '300px' }}>
          <FormTitle title="出力対象者" isRequired />
          <Autocomplete
            size="small"
            options={options}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
            renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" placeholder="選択してください" />}
            value={bpId}
            onChange={(_, selectValue) => {
              this.onChange({ target: { name: 'bpId', value: selectValue } });
            }}
            noOptionsText="データがありません"
          />
        </Box>
        <Box mt={2}>
          <Button variant="contained" onClick={this.onGetData} disabled={this.dateValidation(targetMonth, bpId)}>出力</Button>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  outputInfo: state.outputStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(WorkSheetBp);
