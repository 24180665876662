export function toNumber(number) {
  const tmpNumber = String(number);
  const removed = tmpNumber.replace(/,/g, '');
  return parseInt(removed, 10);
}

export function onRecalc(tmpForm) {
  if (!tmpForm.kingaku || !tmpForm.zeiKubun) {
    return tmpForm;
  }
  const zei = tmpForm.zeiKubun / 100;
  const shohizei = Math.trunc(toNumber(tmpForm.kingaku) * zei);
  const form = {
    ...tmpForm,
    shohizei: shohizei.toLocaleString(),
    gokeiKingaku: (toNumber(tmpForm.kingaku) + shohizei).toLocaleString(),
  };
  return form;
}
