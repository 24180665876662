import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const CustomLink = styled(RouteLink)(() => ({
  color: '#1E90FF',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export default function Link(props) {
  const { children, to } = props;

  return (
    <CustomLink to={to}>
      {children}
    </CustomLink>
  );
}
