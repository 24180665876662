import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container,
} from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { certificateActions } from '../../../redux/master/certificate/certificateState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import SearchBox from '../../../components/templates/searchBox/SearchBox';

class CertificateList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '名前' },
      { id: 'category', label: 'カテゴリ' },
      { id: 'certificate_level', label: 'レベル' },
    ];

    let searchCondition = {
      searchName: '',
      searchCategory: [],
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.certificateInfo.searchCondition) {
      searchCondition = props.certificateInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      codes: [],
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.props.dispatch(certificateActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.certificateInfo.datas !== this.props.certificateInfo.datas) {
      if (this.props.certificateInfo.datas) {
        this.setDatas(this.props.certificateInfo.datas);
      }
    }
    if (prevProps.certificateInfo.selects !== this.props.certificateInfo.selects) {
      if (this.props.certificateInfo.selects) {
        this.setCodes(this.props.certificateInfo.selects);
      }
    }
    if (prevProps.certificateInfo.isDeleteSuccess
      !== this.props.certificateInfo.isDeleteSuccess) {
      if (this.props.certificateInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setCodes = (codes) => {
    this.setState({ codes });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(certificateActions.setSearchCondition(searchCondition));
    this.props.dispatch(certificateActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(certificateActions.deleteData({ ids }));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      codes,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="カテゴリ" />
              <SearchMultipleSelectBox
                name="searchCategory"
                options={codes}
                multiple
                values={searchCondition.searchCategory}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="資格名" />
              <TextField value={searchCondition.searchName} fullWidth name="searchName" onChange={this.onChange} />
            </Grid>
          </Grid>
        </SearchBox>
        <DeleteDataTable
          url={urls.CERTIFICATE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="資格"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  certificateInfo: state.certificateStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(CertificateList);
