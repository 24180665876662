/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const divisionBalanceSlice = createSlice({
  name: 'divisionBalance',
  initialState: {
    detail: {},
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getSelect: () => {},
    getSelectSuccess: (state, action) => {
      state.select = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const divisionBalanceActions = divisionBalanceSlice.actions;

export default divisionBalanceSlice.reducer;
