import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box, LinearProgress,
  Checkbox, Tooltip, IconButton, Button, Chip,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  isBefore, isEqual, subDays, format,
} from 'date-fns';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import { assetRequestListActions } from '../../../redux/request/assetRequest/assetRequestListState';
import { assetRequestDetailActions } from '../../../redux/request/assetRequest/assetRequestDetailState';
import {
  isCheckableCount, isNotDisabled, getCheckableCount,
} from './asset-request-proc';
import CreateAssetDialog from './CreateAssetDialog';
import AssetRequestDetail from './AssetRequestDetail';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [detailId, setDetailId] = React.useState(false);
  const userInfo = useSelector((state) => state.loginStore.userInfo).user;
  const dispatch = useDispatch();
  const isSaveSuccess = useSelector((state) => state.assetRequestDetailStore.isSaveSuccess);
  const saveId = useSelector((state) => state.assetRequestDetailStore.saveId);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setDetailId(saveId);
      setDetailOpen(true);
      setCreateOpen(false);
      setCreated(true);
      dispatch(assetRequestDetailActions.clearFlag());
      props.onResearch();
    }
  }, [isSaveSuccess]);

  const handleSelectAllClick = (event) => {
    const { rows } = props;
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((data) => data.stateCd === '3')
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const newHeadCells = [
    { id: 'asset_name_id', label: '資産ID' },
    { id: 'asset_name', label: '資産名' },
    { id: 'asset_kbn_mei', label: '資産区分' },
  ];
  const lentHeadCells = [
    { id: 'asset_name_id', label: '資産ID' },
    { id: 'syain_mei', label: '名前' },
    { id: 'asset_kbn_mei', label: '資産区分' },
    { id: 'state', label: '状況' },
    { id: 'return_date', label: '返却日' },
  ];

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    loading,
    searchKbn,
  } = props;

  const handleClose = () => {
    setConfOpen(false);
    setCreateOpen(false);
    setDetailOpen(false);
    setCreated(false);
  };

  const onDetailClose = () => {
    setDetailOpen(false);
    props.onResearch();
  };

  const onRequestClick = (_, row) => {
    setCreateOpen(true);
    setSelectedRow(row);
  };

  const onClickConfirm = () => {
    setConfOpen(true);
  };

  const onProcClick = () => {
    setConfOpen(false);
    props.onProcStart();
    dispatch(assetRequestListActions.saveReturned({ ids: selected }));
    setSelected([]);
  };

  const onDetail = (_, id) => {
    setDetailId(id);
    setDetailOpen(true);
    setCreated(false);
  };

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              {getCheckableCount(rows, selected, userInfo) >= 1 && (
                <Box mr={2}>
                  <Tooltip title="返却確認">
                    <Button color="primary" variant="contained" onClick={onClickConfirm}>返却確認</Button>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </Toolbar>
        {loading && (<LinearProgress />)}
        <TableContainer sx={{ maxHeight: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell key="table-header-check" padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0
                      && isCheckableCount(rows, userInfo) !== selected.length
                    }
                    checked={
                      selected.length > 0
                      && isCheckableCount(rows, userInfo) === selected.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color="primary"
                    disabled={isCheckableCount(rows, userInfo) === 0}
                  />
                </TableCell>
                {searchKbn === '0' && newHeadCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {searchKbn === '1' && lentHeadCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
                {searchKbn === '0' && (<TableCell />)}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const targetDate = subDays(new Date(row.returnDate), 5);
                const today = new Date(format(new Date(), 'yyyy/MM/dd'));
                const isMajika = (isBefore(targetDate, today) || isEqual(today, targetDate)) && row.stateCd === '1';
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      {isNotDisabled(row, userInfo) && (
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {searchKbn === '1' && (<Button onClick={(e) => onDetail(e, row.id)}>{row.assetNameId}</Button>)}
                      {searchKbn === '0' && (<Box>{row.assetNameId }</Box>)}
                    </TableCell>
                    {searchKbn === '1' && (<TableCell>{row.syainMei}</TableCell>)}
                    {searchKbn === '0' && (<TableCell>{row.assetName}</TableCell>)}
                    <TableCell>
                      {row.assetKbnMei}
                      {row.primaryPc && (<Box><Chip size="small" variant="outlined" label="primary" color="primary" /></Box>)}
                    </TableCell>
                    {searchKbn === '1' && (<TableCell>{row.state}</TableCell>)}
                    {searchKbn === '1' && (
                      <TableCell>
                        {row.returnDate}
                        <Box display={isMajika ? '' : 'none'}><Chip size="small" variant="outlined" label="間もなく使用期限切れ" color="secondary" /></Box>
                      </TableCell>
                    )}
                    {searchKbn === '0' && (<TableCell><Button onClick={(e) => onRequestClick(e, row)}>申請する</Button></TableCell>)}
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={9}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <CreateAssetDialog selectedRow={selectedRow} onClose={handleClose} open={createOpen} />
        <AssetRequestDetail
          open={detailOpen}
          onClose={onDetailClose}
          id={detailId}
          isCreated={created}
        />
        <ConfirmDialog
          open={confOpen}
          onClose={handleClose}
          title="選択した資産返却確認"
          content="選択した資産を返却確認します"
          onClick={onProcClick}
          procName="確認済にする"
        />
      </Paper>
    </Box>
  );
}
