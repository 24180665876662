import React from 'react';
import {
  Container, Paper, Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function ApplicationError() {
  const theme = useTheme();

  return (
    <Container maxWidth="md" style={{ padding: theme.spacing(2) }}>
      <Paper variant="outlined" style={{ padding: theme.spacing(2) }}>
        <Box sx={{ fontWeight: 'bold' }} textAlign="center">予期せぬエラーが発生しました</Box>
        <Box textAlign="center" mt={1}>恐れ入りますがTOPに戻り、操作をやり直してください。</Box>
      </Paper>
    </Container>
  );
}
