import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Button, FormControl,
  Radio, FormControlLabel, RadioGroup,
} from '@mui/material';
import { closeEditActions } from '../../redux/close/closeEdit/closeEditState';
import SuccessSnackbar from '../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../components/atoms/monthPicker/MonthPicker';

class CloseEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuccessOpen: false,
      data: {
        processedMonth: '',
        processedDistinction: '',
        costProcessedMonth: '',
      },
    };
    props.dispatch(closeEditActions.getDatas());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.closeEditInfo.data !== this.props.closeEditInfo.data) {
      if (this.props.closeEditInfo.data) {
        this.setDatas(this.props.closeEditInfo.data);
      }
    }
    if (prevProps.closeEditInfo.isUpdateSuccess !== this.props.closeEditInfo.isUpdateSuccess) {
      if (this.props.closeEditInfo.isUpdateSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas(data) {
    this.setState({ data });
  }

  setSuccess = () => {
    this.setState({ isSuccessOpen: true });
    this.props.dispatch(closeEditActions.getDatas());
  };

  onClick = () => {
    const { data } = this.state;
    this.props.dispatch(closeEditActions.updateData(data));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });
  };

  onClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      data, isSuccessOpen,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box mt={2}>
          <FormTitle title="売上締め月" isRequired />
          <MonthPicker
            handleChange={this.onChange}
            value={data.processedMonth}
            name="processedMonth"
          />
        </Box>
        <Box mt={2}>
          <FormControl>
            <RadioGroup
              row
              onChange={this.onChange}
              name="processedDistinction"
              value={data.processedDistinction}
            >
              <FormControlLabel value="1" control={<Radio />} label="仮締め" />
              <FormControlLabel value="2" control={<Radio />} label="本締め" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormTitle title="経費締め月" isRequired />
          <MonthPicker
            handleChange={this.onChange}
            value={data.costProcessedMonth}
            name="costProcessedMonth"
          />
        </Box>
        <Box mt={2}>
          <Button variant="contained" onClick={this.onClick} disabled={!data.costProcessedMonth || !data.processedDistinction || !data.processedMonth}>保存</Button>
        </Box>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onClose}
          message="保存が完了しました"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  closeEditInfo: state.closeEditStore,
});

export default connect(mapStateToProps)(CloseEdit);
