import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function DialogColorTitle(props) {
  const { children, isDelete } = props;
  const theme = useTheme();
  return (
    <DialogTitle style={{
      backgroundColor: isDelete ? theme.palette.secondary.main : theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    }}
    >
      {children}
    </DialogTitle>
  );
}

DialogColorTitle.propTypes = {
  children: PropTypes.node.isRequired,
  isDelete: PropTypes.bool,
};

DialogColorTitle.defaultProps = {
  isDelete: false,
};
