import {
  getMonth, getYear, addMonths, format, differenceInMonths,
  isBefore, isEqual,
} from 'date-fns';

export function getTargetYear() {
  const today = new Date();
  const month = getMonth(today) + 1;
  const year = getYear(today);
  let startMonth = `${year}/08/01`;
  if (month >= 2 && month <= 7) {
    startMonth = `${year}/02/01`;
  } else if (month === 1) {
    startMonth = `${year - 1}/08/01`;
  }
  return {
    startMonth,
    endMonth: format(addMonths(new Date(startMonth), 5), 'yyyy/MM/dd'),
  };
}

export function getAddCosts(months, syain) {
  const retval = [];
  for (let i = 0; i < months.length; i += 1) {
    const target = new Date(months[i].targetDate);
    retval.push({ ...syain, targetMonth: format(target, 'yyyy/MM/dd') });
  }
  return retval;
}

export function getMonths(startMonth, endMonth) {
  const diff = differenceInMonths(new Date(endMonth), new Date(startMonth));
  const months = [];
  for (let i = 0; i <= diff; i += 1) {
    const target = addMonths(new Date(startMonth), i);
    months.push({ targetDate: format(target, 'yyyy/MM/dd'), displayDate: format(target, 'yyyy年MM月') });
  }
  return months;
}

export function getBalance(datas, month, key) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  const targets = datas.filter((row) => row.targetMonth === targetMonth);
  if (targets.length <= 0) {
    return '0';
  }
  return targets[0][key];
}

export function getCost(datas, month, costName, key) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  const targets = datas.filter((row) => row.targetMonth === targetMonth
  && row.costName === costName);
  if (targets.length <= 0) {
    return '0';
  }
  return targets[0][key];
}

function toNumber(param) {
  const kingaku = !param ? '0' : param;
  return parseFloat(kingaku.toString().replace(/,/g, ''));
}

export function isInputDisabled(target, proc) {
  return isEqual(new Date(target), new Date(proc)) || isBefore(new Date(target), new Date(proc));
}

export function getTanka(tanka, cost) {
  const result = tanka * toNumber(cost);
  return Math.floor(result).toLocaleString();
}

export function setBalance(datas, month, key, value) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let target = {};
  let index = -1;
  datas.forEach((row, idx) => {
    if (row.targetMonth === targetMonth) {
      target = row;
      index = idx;
    }
  });
  const tmp = {
    ...target,
    [key]: value,
    targetMonth: month,
  };
  const tmpData = {
    ...tmp,
    planAllPrice: (toNumber(tmp.planUnitPrice) + toNumber(tmp.planOtherPrice)).toLocaleString(),
    actualAllPrice: (toNumber(
      tmp.actualUnitPrice,
    ) + toNumber(tmp.actualOtherPrice)).toLocaleString(),
  };
  const tempDatas = [
    ...datas,
  ];
  if (index === -1) {
    tempDatas.push(tmpData);
  } else {
    tempDatas.splice(index, 1, tmpData);
  }
  return tempDatas;
}

export function setCost(datas, month, syain, key, value) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let target = {};
  let index = -1;
  datas.forEach((row, idx) => {
    if (row.targetMonth === targetMonth && row.costName === syain.costName) {
      target = row;
      index = idx;
    }
  });

  const tempDatas = [
    ...datas,
  ];
  const tmpData = {
    ...target,
    [key]: value,
    targetMonth: month,
    costName: syain.costName,
    syainId: syain.syainId,
  };

  if (index === -1) {
    tempDatas.push(tmpData);
  } else {
    tempDatas.splice(index, 1, tmpData);
  }
  return tempDatas;
}

export function getBalanceSummary(datas, key) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
  });
  return sum.toLocaleString();
}

export function getCostSummary(datas, costName, key) {
  let sum = 0;
  datas.filter((row) => row.costName === costName).forEach((row) => {
    sum += toNumber(row[key]);
  });
  return sum.toLocaleString();
}

export function getCostSummaryByTarget(datas, targetMonth, key) {
  let sum = 0;
  let targets = datas;
  if (targetMonth) {
    targets = datas.filter((row) => row.targetMonth === targetMonth);
  }
  targets.forEach((row) => {
    if (key === 'planWorkCost') {
      sum += toNumber(row.properPlanWorkCost) + toNumber(row.outsourcePlanWorkCost);
    }
    if (key === 'actualWorkCost') {
      sum += toNumber(row.properActualWorkCost) + toNumber(row.outsourceActualWorkCost);
    }
    if (key === 'planPriceCost') {
      sum += toNumber(row.properPlanPriceCost) + toNumber(row.outsourcePlanPriceCost);
    }
    if (key === 'actualPriceCost') {
      sum += toNumber(row.properActualPriceCost) + toNumber(row.outsourceActualPriceCost);
    }
    if (key === 'planProperCost') {
      sum += toNumber(row.properPlanPriceCost);
    }
    if (key === 'actualProperCost') {
      sum += toNumber(row.properActualPriceCost);
    }
    if (key === 'planOutsourceCost') {
      sum += toNumber(row.outsourcePlanPriceCost);
    }
    if (key === 'actualOutsourceCost') {
      sum += toNumber(row.outsourceActualPriceCost);
    }
    sum += toNumber(row[key]);
  });
  return sum.toLocaleString();
}

export function deleteDetail(costName, target, data) {
  let tempOutsource = data.outsources;
  let tempSyains = data.syains;
  const costs = [];
  if (target === '1') {
    tempOutsource = [];
    data.outsources.forEach((row) => {
      if (row.costName !== costName) {
        tempOutsource.push(row);
      }
    });
  }
  if (target === '0') {
    tempSyains = [];
    data.syains.forEach((row) => {
      if (row.costName !== costName) {
        tempSyains.push(row);
      }
    });
  }
  data.costs.forEach((row) => {
    if (row.costName !== costName) {
      costs.push(row);
    }
  });
  const tmpData = {
    ...data,
    outsources: tempOutsource,
    syains: tempSyains,
    costs,
  };
  return tmpData;
}
