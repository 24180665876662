import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Chip as MuiChip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';

const Chip = styled(MuiChip)(() => ({
  color: '#fff',
  backgroundColor: '#f50057',
  borderRadius: '0px',
  marginLeft: '5px',
}));

export default function FormTitle(props) {
  const { title, isRequired, helper } = props;

  return (
    <Box sx={{ fontWeight: 'bold' }} mb={1} display="flex">
      {title}
      {isRequired && (<Chip label="必須" size="small" />)}
      {helper && (
        <Tooltip title={helper}>
          <HelpIcon />
        </Tooltip>
      )}
    </Box>
  );
}

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  helper: PropTypes.string,
};

FormTitle.defaultProps = {
  isRequired: false,
  helper: null,
};
