/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const outputSlice = createSlice({
  name: 'output',
  initialState: {
    workSheets: [],
  },
  reducers: {
    getWorkSheets: () => {},
    getWorkSheetsSuccess: (state, action) => {
      state.workSheets = action.payload;
    },
    getWorkSheetsBp: () => {},
    getWorkSheetsBpSuccess: (state, action) => {
      state.workSheetsBp = action.payload;
    },
    getSalarySheets: () => {},
    getSalarySheetsSuccess: (state, action) => {
      state.salarySheets = action.payload;
    },
    getTrafficSheets: () => {},
    getTrafficSheetsSuccess: (state, action) => {
      state.trafficSheets = action.payload;
    },
    getSkillSheets: () => {},
    getSkillSheetsSuccess: (state, action) => {
      state.skillSheets = action.payload;
    },
  },
});

export const outputActions = outputSlice.actions;

export default outputSlice.reducer;
