/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  Container, Typography, Box, TextField, Button, Avatar, CircularProgress,
  Backdrop, Link, InputAdornment, IconButton, OutlinedInput, Paper, Divider,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginActions } from '../../redux/login/loginState';
import logo from '../../asset/logo.png';

class Login extends React.Component {
  constructor(props) {
    super(props);
    document.cookie = 'jwt=; path=/; max-age=0';
    localStorage.clear();
    props.dispatch(loginActions.clearLoginInfo());
    this.state = {
      info: {
        loginId: '',
        password: '',
      },
      showPassword: false,
      isSuccess: false,
      message: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginInfo !== this.props.loginInfo) {
      if (this.props.loginInfo.isLoginSuccess) {
        const { token } = this.props.loginInfo;
        document.cookie = `jwt=${token}; path=/; max-age=86400`;
        this.setMessage(null, true);
      }
      if (this.props.loginInfo.isLoginSuccess === false) {
        this.setMessage('ログインIDまたはパスワードが間違っています', false);
      }
    }
  }

  setMessage = (message, isSuccess) => {
    this.setState({ loading: isSuccess, message, isSuccess });
  };

  handleBtnLogin = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    const { info } = this.state;

    if (!info.loginId || !info.password) {
      return;
    }

    this.setState({ loading: true });
    this.props.dispatch(
      loginActions.executeLogin({
        loginId: info.loginId,
        password: info.password,
      }),
    );
  };

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const { info } = this.state;
    this.setState({ info: { ...info, [key]: value } });
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      info, message, loading, isSuccess, showPassword,
    } = this.state;

    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={15} style={{ padding: '30px' }}>
          {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          )}
          {isSuccess && (
          <Navigate to="/" replace />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            mb={2}
          >
            <Avatar sx={{ m: 1 }} src={logo} />
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box color="red" mt={2}>{message}</Box>
            <Box component="form" onSubmit={this.handleBtnLogin} noValidate sx={{ mt: 1 }}>
              <Box>ログインID</Box>
              <TextField
                value={info.loginId}
                required
                fullWidth
                id="loginId"
                name="loginId"
                autoComplete="loginId"
                autoFocus
                onChange={this.handleChange}
              />
              <Box mt={2}>パスワード</Box>
              <OutlinedInput
                required
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={info.password}
                name="password"
                id="password"
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
                autoComplete="current-password"
                onChange={this.handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                ログイン
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://sbs.sodech.com/">
                Sodech
              </Link>
              {' '}
              {new Date().getFullYear()}
              .
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
});

export default connect(mapStateToProps)(Login);
