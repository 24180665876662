export function getCheckableCount(datas, selected) {
  const targets = [];
  selected.forEach((c) => {
    datas.forEach((v) => {
      if (c === v.id) {
        targets.push(v);
      }
    });
  });
  return targets.filter((v) => v.statusCode === '1').length;
}

export function isCheckableCount(datas) {
  return datas.filter((v) => v.statusCode === '1').length;
}

export function isNotDisabled(data) {
  return data.statusCode === '1';
}

export function excelData(form, worksheet) {
  let row = worksheet.getRow(3);
  row.getCell(2).value = form.offerName;
  row.getCell(5).value = form.useDt;
  row = worksheet.getRow(4);
  row.getCell(2).value = `${form.offerAmount}円`;
  row.getCell(5).value = form.offerDt;
  row = worksheet.getRow(5);
  let otherDetail = '';
  form.detailList.forEach((v) => {
    otherDetail += `${v.syainMei}
`;
  });
  row.getCell(2).value = otherDetail;
  row = worksheet.getRow(6);
  row.getCell(2).value = form.otherJoin;
  row = worksheet.getRow(7);
  row.getCell(2).value = form.usePurpose;
  row = worksheet.getRow(8);
  row.getCell(2).value = form.expectedEffect;
  row = worksheet.getRow(10);
  row.getCell(2).value = form.approvarNo;
  const name = form.approvarName.split('　')[0];
  row.getCell(9).value = name;
  row = worksheet.getRow(11);
  row.getCell(2).value = form.approvarDt;
}
