import { REST_API } from '../../constants/serverUrls';
import HttpConnection from '../../utils/http-connection';

export function login(userInfo) {
  const httpConnection = new HttpConnection();
  return httpConnection.post(REST_API.COMMON.LOGIN, userInfo);
}

export function getLoginInfo() {
  const httpConnection = new HttpConnection();
  return httpConnection.get(REST_API.COMMON.LOGIN_INFO);
}
