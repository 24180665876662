/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { employeeActions } from './employeeState';
import {
  getDatas, deleteDatas, saveData, updateData, getDataById,
} from '../../../services/base.service';

function* getSelect() {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_SELECT);
    yield put(employeeActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE, payload);
    yield put(employeeActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE, payload);
    yield put(employeeActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_ID, payload);
    yield put(employeeActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.OFFICE.EMPLOYEE.EMPLOYEE, payload);
    yield put(employeeActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    yield call(updateData, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_ID, payload);
    yield put(employeeActions.updateDataSuccess());
  } catch (_) {}
}

function* employeeSaga() {
  yield takeLatest('employee/getDatas', getData);
  yield takeLatest('employee/getSelect', getSelect);
  yield takeLatest('employee/deleteData', deleteData);
  yield takeLatest('employee/getData', getDataFromId);
  yield takeLatest('employee/insertData', insertData);
  yield takeLatest('employee/updateData', updateDatas);
}

export default employeeSaga;
