import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, FormControl, Select, MenuItem, Tabs, Tab,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { userSettingActions } from '../../../redux/profile/userSetting/userSettingState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';

class UserSetting extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch(userSettingActions.getSelect());
    props.dispatch(userSettingActions.getData());

    this.state = {
      form: {
        baseKmktCd: null,
      },
      selects: [],
      isSuccessOpen: false,
      tabValue: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userSettingInfo.isSuccess !== this.props.userSettingInfo.isSuccess) {
      if (this.props.userSettingInfo.isSuccess) {
        this.onSuccessOpen();
      }
    }
    if (prevProps.userSettingInfo.selects !== this.props.userSettingInfo.selects) {
      if (this.props.userSettingInfo.selects) {
        this.setSelects(this.props.userSettingInfo.selects);
      }
    }
    if (prevProps.userSettingInfo.data !== this.props.userSettingInfo.data) {
      if (this.props.userSettingInfo.data) {
        this.setData(this.props.userSettingInfo.data);
      }
    }
  }

  setData = (form) => {
    this.setState({ form });
  };

  setSelects = (selects) => {
    this.setState({ selects });
  };

  onSuccessOpen = () => {
    this.setState({ isSuccessOpen: true });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  };

  onSave = () => {
    const { form } = this.state;
    if (!form.baseKmktCd) {
      return;
    }

    this.props.dispatch(userSettingActions.updateData(form));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const {
      form, isSuccessOpen, selects, tabValue,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
            <Tab label="勤務設定" />
            <Tab label="通知設定" disabled />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Box>
            <FormTitle title="形態" isRequired />
            <FormControl fullWidth>
              <Select
                value={form.baseKmktCd}
                name="baseKmktCd"
                onChange={this.onChange}
              >
                {selects.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </TabPanel>

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message="保存が完了しました"
        />
        <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
          <LoadingButton variant="contained" loading={this.props.common.isLoading} onClick={this.onSave} disabled={!form.baseKmktCd}>保存</LoadingButton>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  userSettingInfo: state.userSettingStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(UserSetting);
