import React from 'react';
import { Chip, Box } from '@mui/material';
import {
  Train as TrainIcon, Flag as FlagIcon,
} from '@mui/icons-material';

export default function CalendarContent(props) {
  const { data, item, isBp } = props;
  const isDispCode = (statusCd) => statusCd === '1' || statusCd === '4' || statusCd === '5' || statusCd === '8';
  const getName = (param) => {
    if (param.statusCd === '1' || param.statusCd === '4' || param.statusCd === '5' || param.statusCd === '8') {
      return param.statusName;
    }
    return `${data.startTm}-${data.endTm}`;
  };

  if (!item.isSomeMonth) {
    return (<div />);
  }
  return (
    <Box>
      <div>{item.day}</div>
      <Box>
        {((data.startTm && data.startTm !== '0') || (data.endTm && data.endTm !== '0') || isDispCode(data.statusCd)) && (
          <Box my={1} sx={{ display: { xs: 'none', sm: 'block' } }}>{getName(data)}</Box>
        )}
        <Box display="flex" justifyContent="center" mb={1}>
          {(data.existTraffic && !isBp) && (
            <TrainIcon style={{ color: '#212121' }} size="small" />
          )}
          {((data.overtimeErr || data.costErr) && !isBp) && (
            <Chip label="エラー" color="secondary" variant="outlined" size="small" />
          )}
          {(data.fixed && !isBp) && (
            <FlagIcon color="secondary" />
          )}
          {((!data.overtimeErr && !data.costErr && (data.workTm !== '0')) || (isBp && data.workTm !== '0')) && (
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{data.workTm}</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
