import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { workRequestListActions } from './workRequestListState';
import { getDatas, saveData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.WORK_REQUEST.WORK_REQUEST, payload);
    yield put(workRequestListActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveApproval({ payload }) {
  try {
    yield call(saveData, REST_API.REQUEST.WORK_REQUEST.WORK_REQUEST_APPROVAL, payload);
    yield put(workRequestListActions.saveApprovalSuccess());
  } catch (_) {}
}

function* saveCertificate({ payload }) {
  try {
    yield call(saveData, REST_API.REQUEST.WORK_REQUEST.WORK_REQUEST_CONFIRM, payload);
    yield put(workRequestListActions.saveCertificateSuccess());
  } catch (_) {}
}

function* workRequestListSaga() {
  yield takeLatest('workRequestList/getDatas', getData);
  yield takeLatest('workRequestList/saveApproval', saveApproval);
  yield takeLatest('workRequestList/saveCertificate', saveCertificate);
}

export default workRequestListSaga;
