import React from 'react';
import { format, isValid } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import {
  DialogContent, Box, DialogActions, FormGroup, Switch, FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import TextArea from '../../../components/atoms/textArea/TextArea';
import { assetRequestDetailActions } from '../../../redux/request/assetRequest/assetRequestDetailState';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';

export default function CreateAssetDialog(props) {
  const userId = useSelector((state) => state.loginStore.userInfo).user.id;
  const [loanDate, setLoanDate] = React.useState(format(new Date(), 'yyyy/MM/dd'));
  const [syainId, setSyainId] = React.useState(userId);
  const [primaryPc, setPrimaryPc] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [data, setData] = React.useState({});
  const loading = useSelector((state) => state.commonStore.isLoading);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.open) {
      setLoanDate(format(new Date(), 'yyyy/MM/dd'));
      setSyainId(userId);
      setReason('');
      setData(props.selectedRow);
    }
  }, [props.open]);

  const {
    open,
    onClose,
  } = props;

  const onClick = () => {
    if (!syainId || !loanDate || !reason) {
      return;
    }
    dispatch(assetRequestDetailActions.saveData({
      syainId,
      loanDate,
      reason,
      primaryPc,
      shisanId: data.assetId,
    }));
  };

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle>資産申請</DialogColorTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="資産名" />
          <Box>{data.assetName}</Box>
        </Box>
        <Box mt={2}>
          <FormTitle title="名前" isRequired />
          <SelectSyain disableClearable onChange={(e) => setSyainId(e.target.value)} name="syainId" value={syainId} targetMonth={format(new Date(), 'yyyy/MM/dd')} />
        </Box>
        <Box mt={2}>
          <FormTitle title="借用日" isRequired />
          <DatePicker handleChange={(e) => setLoanDate(e.target.value)} name="loanDate" value={loanDate} />
        </Box>
        {data.assetKbnMei === 'PC' && (
          <Box mt={2}>
            <FormTitle title="PrimaryPC" />
            <FormGroup>
              <FormControlLabel control={<Switch defaultChecked checked={primaryPc} onChange={(e) => setPrimaryPc(e.target.checked)} />} label="PrimaryPCにする" />
            </FormGroup>
          </Box>
        )}
        <Box mt={2}>
          <FormTitle title="理由" isRequired />
          <TextArea
            value={reason}
            name="reason"
            onChange={(e) => setReason(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={loading} color="inherit">キャンセル</LoadingButton>
        <LoadingButton
          onClick={onClick}
          loading={loading}
          color="primary"
          disabled={!syainId || !loanDate || !reason || !isValid(new Date(loanDate))}
        >
          申請する
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
