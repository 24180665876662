export function getCheckableCount(datas, selected) {
  const targets = [];
  selected.forEach((c) => {
    datas.forEach((v) => {
      if (c === v.id) {
        targets.push(v);
      }
    });
  });
  return targets.filter((v) => v.statusCode === '0').length;
}

export function isCheckableCount(datas) {
  return datas.filter((v) => v.statusCode === '0').length;
}

export function isNotDisabled(data) {
  return data.statusCode === '0';
}
