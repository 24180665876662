/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { clientActions } from './clientState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.CLIENT.CLIENT, payload);
    yield put(clientActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.CLIENT.CLIENT, payload);
    yield put(clientActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.CLIENT.CLIENT_ID, payload);
    yield put(clientActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.CLIENT.CLIENT, payload);
    yield put(clientActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.CLIENT.CLIENT, payload);
    yield put(clientActions.updateDataSuccess(result));
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.CLIENT.CLIENT_SELECT, payload);
    yield put(clientActions.getSelectSuccess(result));
  } catch (_) {}
}

function* clientSaga() {
  yield takeLatest('client/getDatas', getData);
  yield takeLatest('client/deleteData', deleteData);
  yield takeLatest('client/getData', getDataFromId);
  yield takeLatest('client/insertData', insertData);
  yield takeLatest('client/updateData', updateData);
  yield takeLatest('client/getSelect', getSelect);
}

export default clientSaga;
