import { call, put, takeLatest } from 'redux-saga/effects';
import { workDeviationActions } from './workDeviationState';
import { getDatas } from '../../../services/base.service';
import { REST_API } from '../../../constants/serverUrls';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_DEVIATION, payload);
    yield put(workDeviationActions.getDataSuccess(result));
  } catch (_) {}
}

function* workDeviationSaga() {
  yield takeLatest('workDeviation/getData', getData);
}

export default workDeviationSaga;
