/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { employeeLicenseHistoryActions } from './employeeLicenseHistoryState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_LICENSE_HISTORY_SELECT, payload);
    yield put(employeeLicenseHistoryActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_LICENSE_HISTORY, payload);
    yield put(employeeLicenseHistoryActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_LICENSE_HISTORY, payload);
    yield put(employeeLicenseHistoryActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_LICENSE_HISTORY_ID, payload);
    yield put(employeeLicenseHistoryActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.OFFICE.EMPLOYEE.EMPLOYEE_LICENSE_HISTORY, payload);
    yield put(employeeLicenseHistoryActions.saveDataSuccess());
  } catch (_) {}
}

function* employeeLicenseHistorySaga() {
  yield takeLatest('employeeLicenseHistory/getDatas', getData);
  yield takeLatest('employeeLicenseHistory/getSelect', getSelect);
  yield takeLatest('employeeLicenseHistory/deleteData', deleteData);
  yield takeLatest('employeeLicenseHistory/getData', getDataFromId);
  yield takeLatest('employeeLicenseHistory/saveData', saveDatas);
}

export default employeeLicenseHistorySaga;
