/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const clientAgreementSlice = createSlice({
  name: 'clientAgreement',
  initialState: {
    datas: {},
  },
  reducers: {
    getDatas: (state) => {
      state.datas = {};
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    getNames: () => {},
    getNamesSuccess: (state, action) => {
      state.names = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
    },
  },
});

export const clientAgreementActions = clientAgreementSlice.actions;

export default clientAgreementSlice.reducer;
