/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { userSettingActions } from './userSettingState';
import { saveData, getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PROFILE.USER_SETTING.USER_SETTING, payload);
    yield put(userSettingActions.getDataSuccess(result));
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.PROFILE.USER_SETTING.USER_SETTING_SELECT, payload);
    yield put(userSettingActions.getSelectSuccess(result));
  } catch (_) {}
}

function* updateData({ payload }) {
  try {
    yield call(saveData, REST_API.PROFILE.USER_SETTING.USER_SETTING, payload);
    yield put(userSettingActions.updateDataSuccess());
  } catch (_) {}
}

function* userSettingSaga() {
  yield takeLatest('userSetting/getData', getData);
  yield takeLatest('userSetting/getSelect', getSelect);
  yield takeLatest('userSetting/updateData', updateData);
}

export default userSettingSaga;
