/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { divisionBalanceActions } from './divisionBalanceState';
import {
  getDatas,
} from '../../../services/base.service';

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.DIVISION_BALANCE.DIVISION_BALANCE_SELECT, payload);
    yield put(divisionBalanceActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SUMMARY.DIVISION_BALANCE.DIVISION_BALANCE, payload);
    yield put(divisionBalanceActions.getDataSuccess(result));
  } catch (_) {}
}

function* divisionBalanceSaga() {
  yield takeLatest('divisionBalance/getSelect', getSelect);
  yield takeLatest('divisionBalance/getData', getData);
}

export default divisionBalanceSaga;
