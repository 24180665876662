import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Container, TextField,
} from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { noticeActions } from '../../../redux/setting/notice/noticeState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';

class NoticeList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル' },
      { id: 'period_date_from', label: '開始日' },
      { id: 'period_date_to', label: '終了日' },
    ];

    let searchCondition = {
      selectStr: '',
      searchDt: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.noticeInfo.searchCondition) {
      searchCondition = props.noticeInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noticeInfo.datas !== this.props.noticeInfo.datas) {
      if (this.props.noticeInfo.datas) {
        this.setDatas(this.props.noticeInfo.datas);
      }
    }
    if (prevProps.noticeInfo.isDeleteSuccess
      !== this.props.noticeInfo.isDeleteSuccess) {
      if (this.props.noticeInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event, isSearch) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    if (isSearch) {
      this.search(tempCondition);
    }
  };

  onBlur = () => {
    this.onSearch();
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(noticeActions.setSearchCondition(searchCondition));
    this.props.dispatch(noticeActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(noticeActions.deleteData({ ids }));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={6} md={3}>
            <FormTitle title="キーワード" />
            <TextField value={searchCondition.selectStr} autoComplete="off" name="selectStr" fullWidth onChange={(e) => this.onChange(e, false)} onBlur={this.onBlur} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormTitle title="基準日" />
            <DatePicker
              handleChange={(e) => this.onChange(e, true)}
              name="searchDt"
              value={searchCondition.searchDt}
            />
          </Grid>
        </Grid>
        <DeleteDataTable
          url={urls.NOTICE_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="お知らせ"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  noticeInfo: state.noticeStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(NoticeList);
