/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { orderDetailActions } from './orderDetailState';
import {
  getDatas, saveData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.ORDER.ORDER_DETAIL, payload);
    yield put(orderDetailActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.SALES.ORDER.ORDER_DETAIL, payload);
    yield put(orderDetailActions.saveDataSuccess());
  } catch (_) {}
}

function* orderDetailSaga() {
  yield takeLatest('orderDetail/getDatas', getData);
  yield takeLatest('orderDetail/saveData', saveDatas);
}

export default orderDetailSaga;
