/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { payDetailActions } from './payDetailState';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PAY.PAY_DETAIL, payload);
    yield put(payDetailActions.getDatasSuccess(result));
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PAY.PAY_SELECT, payload);
    yield put(payDetailActions.getSelectSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.PAY.PAY_DETAIL, payload);
    yield put(payDetailActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.PAY.PAY_DETAIL_ID, payload);
    yield put(payDetailActions.getDataFromIdSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.SALES.PAY.PAY_DETAIL, payload);
    yield put(payDetailActions.saveDataSuccess());
  } catch (_) {}
}

function* payDetailSaga() {
  yield takeLatest('payDetail/getDatas', getData);
  yield takeLatest('payDetail/getSelect', getSelect);
  yield takeLatest('payDetail/deleteData', deleteData);
  yield takeLatest('payDetail/getDataFromId', getDataFromId);
  yield takeLatest('payDetail/saveData', saveDatas);
}

export default payDetailSaga;
