/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { inputActions } from './inputState';
import { uploadFiles } from '../../../services/base.service';

function* updateExpense({ payload }) {
  try {
    const datas = yield call(uploadFiles, REST_API.FILE.INPUT.EXPENSE, payload);
    yield put(inputActions.updateSuccess(datas));
  } catch (_) {}
}
function* updateEmployee({ payload }) {
  try {
    const datas = yield call(uploadFiles, REST_API.FILE.INPUT.EMPLOYEE, payload);
    yield put(inputActions.updateSuccess(datas));
  } catch (_) {}
}

function* inputSaga() {
  yield takeLatest('input/updateExpense', updateExpense);
  yield takeLatest('input/updateEmployee', updateEmployee);
}

export default inputSaga;
