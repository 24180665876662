/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const trafficRequestDetailSlice = createSlice({
  name: 'trafficRequestDetail',
  initialState: {
    saveId: null,
    isCreateSuccess: false,
    data: {},
    isDeleteSuccess: false,
    isStatusUpdateSuccess: false,
    isUploadSuccess: false,
    isFileDeleteSuccess: false,
  },
  reducers: {
    clearFlag: (state) => {
      state.saveId = null;
      state.isCopySuccess = false;
      state.isCreateSuccess = false;
    },
    initData: (state) => {
      state.initData = null;
    },
    initDataSuccess: (state, action) => {
      state.initData = action.payload;
    },
    copyData: (state) => {
      state.copyData = null;
    },
    copyDataSuccess: (state, action) => {
      state.copyData = action.payload;
    },
    copyDataUpdate: (state) => {
      state.isCopySuccess = false;
    },
    copyDataUpdateSuccess: (state) => {
      state.isCopySuccess = true;
    },
    createData: (state) => {
      state.isCreateSuccess = false;
    },
    createDataSuccess: (state, action) => {
      state.saveId = action.payload;
      state.isCreateSuccess = true;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    saveStatus: (state) => {
      state.isStatusUpdateSuccess = false;
    },
    saveStatusSuccess: (state) => {
      state.isStatusUpdateSuccess = true;
    },
    uploadFile: (state) => {
      state.isUploadSuccess = false;
    },
    uploadFileSuccess: (state) => {
      state.isUploadSuccess = true;
    },
    deleteFile: (state) => {
      state.isFileDeleteSuccess = false;
    },
    deleteFileSuccess: (state) => {
      state.isFileDeleteSuccess = true;
    },
  },
});

export const trafficRequestDetailActions = trafficRequestDetailSlice.actions;

export default trafficRequestDetailSlice.reducer;
