import { all } from '@redux-saga/core/effects';

import commonSaga from './common/commonSaga';
import loginSaga from './login/loginSaga';
import roleListSaga from './setting/roleList/roleListSaga';
import roleDetailSaga from './setting/roleDetail/roleDetailSaga';
import workStyleSaga from './setting/workStyle/workStyleSaga';
import noticeSaga from './setting/notice/noticeSaga';
import loginUserSaga from './setting/loginUser/loginUserSaga';
import calendarSaga from './setting/calendar/calendarSaga';

import topCloseSaga from './top/close/topCloseSaga';
import topNoticeSaga from './top/notice/topNoticeSaga';
import topTodoSaga from './top/todo/topTodoSaga';

import costCloseSaga from './close/costClose/costCloseSaga';
import salesCloseSaga from './close/salesClose/salesCloseSaga';
import closeEditSaga from './close/closeEdit/closeEditSaga';

import approvalHistorySaga from './sales/approvalHistory/approvalHistorySaga';
import clientSaga from './sales/client/clientSaga';
import clientContactSaga from './sales/client/clientContactSaga';
import clientAgreementSaga from './sales/client/clientAgreementSaga';
import projectSaga from './sales/project/projectSaga';
import estimateSaga from './sales/estimate/estimateSaga';
import estimateDetailSaga from './sales/estimate/estimateDetailSaga';
import orderSaga from './sales/order/orderSaga';
import orderDetailSaga from './sales/order/orderDetailSaga';
import claimSaga from './sales/claim/claimSaga';
import claimDetailSaga from './sales/claim/claimDetailSaga';
import paySaga from './sales/pay/paySaga';
import payDetailSaga from './sales/pay/payDetailSaga';
import partnerSaga from './sales/partner/partnerSaga';
import inspectSaga from './sales/inspect/inspectSaga';
import inspectDetailSaga from './sales/inspect/inspectDetailSaga';
import skillSaga from './sales/skill/skillSaga';

import salaryListSaga from './office/salary/salaryListSaga';
import salaryDetailSaga from './office/salary/salaryDetailSaga';
import cacheSaga from './office/cache/cacheSaga';
import employeeSaga from './office/employee/employeeSaga';
import employeeHistorySaga from './office/employee/employeeHistorySaga';
import employeeLicenseHistorySaga from './office/employee/employeeLicenseHistorySaga';
import vacationSaga from './office/vacation/vacationSaga';
import certificateCampaignSaga from './office/certificateCampaign/certificateCampaignSaga';
import fixedExpenseSaga from './office/fixedExpense/fixedExpenseSaga';

import workPlanSaga from './work/workPlan/workPlanSaga';
import workResultSaga from './work/workResult/workResultSaga';
import workDeviationSaga from './work/workDeviation/workDeviationSaga';
import workPlanResultSaga from './work/workPlanResult/workPlanResultSaga';
import workResultInputSaga from './work/workResultInput/workResultInputSaga';
import workClockSaga from './work/workClock/workClockSaga';
import workCostListSaga from './work/workCostList/workCostListSaga';
import workSummarySaga from './work/workSummary/workSummarySaga';

import workRequestListSaga from './request/workRequest/workRequestListSaga';
import workRequestDetailSaga from './request/workRequest/workRequestDetailSaga';
import overtimeRequestListSaga from './request/overtimeRequest/overtimeRequestListSaga';
import overtimeRequestDetailSaga from './request/overtimeRequest/overtimeRequestDetailSaga';
import trafficResuestListSaga from './request/trafficRequest/trafficRequestListSaga';
import trafficResuestDetailSaga from './request/trafficRequest/trafficRequestDetailSaga';
import trafficResuestInputSaga from './request/trafficRequest/trafficRequestInputSaga';
import assetRequestListSaga from './request/assetRequest/assetRequestListSaga';
import assetRequestDetailSaga from './request/assetRequest/assetRequestDetailSaga';
import expenseResuestListSaga from './request/expenseRequest/expenseRequestListSaga';
import expenseResuestDetailSaga from './request/expenseRequest/expenseRequestDetailSaga';
import workFixRequestSaga from './request/workFixRequest/workFixRequestSaga';

import certificateSaga from './master/certificate/certificateSaga';
import assetSaga from './master/asset/assetSaga';

import outputSaga from './file/output/outputSaga';
import inputSaga from './file/input/inputSaga';

import passwordSaga from './profile/password/passwordSaga';
import userSettingSaga from './profile/userSetting/userSettingSaga';

import reportSaga from './summary/report/reportSaga';
import projectBalanceSaga from './summary/projectBalance/projectBalanceSaga';
import divisionBalanceSaga from './summary/divisionBalance/divisionBalanceSaga';
import companyBalanceSaga from './summary/companyBalance/companyBalanceSaga';

export default function* rootSaga() {
  yield all([
    commonSaga(),
    loginSaga(),
    roleListSaga(),
    roleDetailSaga(),
    workStyleSaga(),
    certificateCampaignSaga(),
    fixedExpenseSaga(),
    noticeSaga(),
    loginUserSaga(),
    calendarSaga(),

    topCloseSaga(),
    topNoticeSaga(),
    topTodoSaga(),

    certificateSaga(),
    assetSaga(),

    costCloseSaga(),
    salesCloseSaga(),
    closeEditSaga(),

    approvalHistorySaga(),
    clientSaga(),
    clientContactSaga(),
    clientAgreementSaga(),
    projectSaga(),
    estimateSaga(),
    estimateDetailSaga(),
    orderSaga(),
    orderDetailSaga(),
    claimSaga(),
    claimDetailSaga(),
    paySaga(),
    payDetailSaga(),
    partnerSaga(),
    inspectSaga(),
    inspectDetailSaga(),
    skillSaga(),

    salaryListSaga(),
    salaryDetailSaga(),
    cacheSaga(),
    employeeSaga(),
    employeeHistorySaga(),
    employeeLicenseHistorySaga(),
    vacationSaga(),

    workPlanSaga(),
    workResultSaga(),
    workResultInputSaga(),
    workPlanResultSaga(),
    workDeviationSaga(),
    workClockSaga(),
    workCostListSaga(),
    workSummarySaga(),

    workRequestListSaga(),
    workRequestDetailSaga(),
    overtimeRequestListSaga(),
    overtimeRequestDetailSaga(),
    trafficResuestListSaga(),
    trafficResuestDetailSaga(),
    trafficResuestInputSaga(),
    assetRequestListSaga(),
    assetRequestDetailSaga(),
    expenseResuestListSaga(),
    expenseResuestDetailSaga(),
    workFixRequestSaga(),

    passwordSaga(),
    userSettingSaga(),

    outputSaga(),
    inputSaga(),

    reportSaga(),
    projectBalanceSaga(),
    divisionBalanceSaga(),
    companyBalanceSaga(),
  ]);
}
