import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const requiredIdValidation = (value) => {
  if (!value) return '入力してください';
  if (value.length !== 6) return '6桁で入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'id':
        return requiredIdValidation(value);
      case 'syainMei':
      case 'syainMeiRubi':
      case 'seibetsu':
      case 'rankCode':
      case 'depCode':
      case 'loginId':
      case 'roleId':
        return requiredValidation(value);
      case 'entryDt':
      case 'birthday':
        return dateValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
