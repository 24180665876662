/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { inspectDetailActions } from './inspectDetailState';
import {
  getDatas, saveData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.INSPECT.INSPECT_DETAIL, payload);
    yield put(inspectDetailActions.getDatasSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    yield call(saveData, REST_API.SALES.INSPECT.INSPECT_DETAIL, payload);
    yield put(inspectDetailActions.saveDataSuccess());
  } catch (_) {}
}

function* calcCost({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.INSPECT.INSPECT_COST, payload);
    yield put(inspectDetailActions.calcCostSuccess(result));
  } catch (_) {}
}

function* inspectDetailSaga() {
  yield takeLatest('inspectDetail/getDatas', getData);
  yield takeLatest('inspectDetail/saveData', saveDatas);
  yield takeLatest('inspectDetail/calcCost', calcCost);
}

export default inspectDetailSaga;
