/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workClockSlice = createSlice({
  name: 'workClock',
  initialState: {
    data: {},
    isSaveSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
  },
});

export const workClockActions = workClockSlice.actions;

export default workClockSlice.reducer;
