/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { format, addMonths } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, ListItemAvatar, List, ListItem,
  Avatar, ListItemText, Paper,
} from '@mui/material';
import {
  Info as InfoIcon, TaskAlt as TaskAltIcon,
} from '@mui/icons-material';
import { topTodoActions } from '../../redux/top/todo/topTodoState';
import { workRequestListActions } from '../../redux/request/workRequest/workRequestListState';
import { overtimeRequestListActions } from '../../redux/request/overtimeRequest/overtimeRequestListState';
import { assetRequestListActions } from '../../redux/request/assetRequest/assetRequestListState';
import { trafficRequestListActions } from '../../redux/request/trafficRequest/trafficRequestListState';
import { expenseRequestListActions } from '../../redux/request/expenseRequest/expenseRequestListState';
import { estimateActions } from '../../redux/sales/estimate/estimateState';
import { orderActions } from '../../redux/sales/order/orderState';
import { workPlanActions } from '../../redux/work/workPlan/workPlanState';

export default function TopTodo() {
  const dispatch = useDispatch();
  const [datas, setDatas] = React.useState([]);
  const navigate = useNavigate();
  const dbDatas = useSelector((state) => state.topTodoStore.datas);
  const userId = useSelector((state) => (state.loginStore.userInfo ? state.loginStore.userInfo.user.id : null));

  React.useEffect(() => {
    dispatch(topTodoActions.getDatas());
  }, []);

  React.useEffect(() => {
    if (dbDatas && dbDatas.length > 0) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const onClick = (_, data) => {
    if (data.target === '1') {
      dispatch(workRequestListActions.setSearchCondition({
        targetMonth: '',
        syainId: null,
        autherId: userId,
        status: '0',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/work-requests');
    }
    if (data.target === '2') {
      dispatch(overtimeRequestListActions.setSearchCondition({
        targetMonth: '',
        syainId: '',
        autherId: userId,
        status: '0',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/overtime-requests');
    }
    if (data.target === '3') {
      dispatch(assetRequestListActions.setSearchCondition({
        searchSisanId: '',
        selectFin: false,
        searchSisanKubun: '',
        searchSyainId: userId,
        status: '2',
        searchKbn: '1',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/asset-requests');
    }
    if (data.target === '4') {
      dispatch(assetRequestListActions.setSearchCondition({
        searchSisanId: '',
        selectFin: false,
        searchSisanKubun: '',
        searchSyainId: userId,
        status: '1',
        searchKbn: '1',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/asset-requests');
    }
    if (data.target === '5') {
      dispatch(trafficRequestListActions.setSearchCondition({
        targetMonth: '',
        syainId: '',
        status: '1',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/traffic-requests');
    }
    if (data.target === '6') {
      dispatch(expenseRequestListActions.setSearchCondition({
        targetMonth: '',
        syainId: '',
        status: '2',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/expense-requests');
    }
    if (data.target === '7') {
      dispatch(trafficRequestListActions.setSearchCondition({
        targetMonth: '',
        syainId: '',
        status: '3',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/traffic-requests');
    }
    if (data.target === '8') {
      dispatch(estimateActions.setSearchCondition({
        searchAppStatus: [],
        searchStatus: [],
        searchOrderNo: '',
        searchProjectId: '',
        searchKeyword: '',
        ball: userId,
        searchMonth: '',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/estimates');
    }
    if (data.target === '9') {
      dispatch(estimateActions.setSearchCondition({
        searchAppStatus: ['3'],
        searchStatus: [],
        searchOrderNo: '',
        searchProjectId: '',
        searchKeyword: '',
        ball: '',
        searchMonth: '',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/estimates');
    }
    if (data.target === '10') {
      dispatch(orderActions.setSearchCondition({
        searchAppStatus: [],
        searchOrderNo: '',
        searchProjectId: '',
        searchKeyword: '',
        searchMonth: '',
        ball: userId,
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/orders');
    }
    if (data.target === '11') {
      dispatch(orderActions.setSearchCondition({
        searchAppStatus: ['3'],
        searchOrderNo: '',
        searchProjectId: '',
        searchKeyword: '',
        searchMonth: '',
        ball: '',
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      }));
      navigate('/orders');
    }
    if (data.target === '12') {
      let today = format(new Date(), 'yyyy/MM/dd');
      if (format(new Date(), 'dd') >= 25) {
        today = format(addMonths(new Date(), 1), 'yyyy/MM/dd');
      }
      dispatch(workPlanActions.setSearchCondition({
        targetMonth: today,
      }));
      navigate('/work-plans');
    }
    if (data.target === '13') {
      navigate('/work-requests');
    }
  };

  return (
    <Box>
      {datas.length > 0 && (
        <Paper elevation={3}>
          <Box display="flex" pt={2} pl={2}>
            <InfoIcon />
            <Box sx={{ fontWeight: 'bold' }}>Todo</Box>
          </Box>
          <List
            sx={{
              bgcolor: 'background.paper',
            }}
          >
            {datas.map((data) => (
              <ListItem button onClick={(e) => onClick(e, data)} key={data.content}>
                <ListItemAvatar>
                  <Avatar>
                    <TaskAltIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={data.content} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
}
