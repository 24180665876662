import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField, Box, DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import Dialog from '../../../../components/atoms/dialog/Dialog';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { clientContactActions } from '../../../../redux/sales/client/clientContactState';
import { commonActions } from '../../../../redux/common/commonState';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';

const tmpMessage = {
  lastName: '',
};
const tmpForm = {
  id: '',
  trnClientId: '',
  lastName: '',
  firstName: '',
  department: '',
  post: '',
  roleCode: '',
  email: '',
  phone: '',
};

export default function ClientContactDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, clientId, id, onSaveSuccess,
  } = props;
  const data = useSelector((state) => state.clientContactStore.data);
  const isSaveSuccess = useSelector((state) => state.clientContactStore.isSaveSuccess);
  const statuses = useSelector((state) => state.commonStore.statuses);
  const [select, setSelect] = React.useState([]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      dispatch(clientContactActions.resetFlg());
      onSaveSuccess();
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (statuses && statuses.length >= 0) {
      setSelect(statuses);
    }
  }, [statuses]);

  React.useEffect(() => {
    if (isOpen) {
      if (id) {
        dispatch(
          clientContactActions.getData({ id }),
        );
      }
      setForm({
        ...tmpForm,
        id,
      });
      setErrorMessage(tmpMessage);
      dispatch(
        commonActions.getStatuses({ code: 'CONTACT_ROLE' }),
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      const tempData = {
        ...data,
      };
      setForm(tempData);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...errorMessage,
        [key]: msg,
      };
    });

    setErrorMessage(tempMessage);

    if (isError) {
      return;
    }

    dispatch(clientContactActions.saveData({ ...form, trnClientId: clientId }));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>取引先担当者</DialogTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="姓" isRequired />
          <TextField value={form.lastName} name="lastName" error={errorMessage.lastName !== ''} inputProps={{ maxLength: 20 }} fullWidth onChange={onChange} />
          <FormErrorText>{errorMessage.lastName}</FormErrorText>
        </Box>
        <Box mt={1}>
          <FormTitle title="名" />
          <TextField value={form.firstName} name="firstName" inputProps={{ maxLength: 20 }} fullWidth onChange={onChange} />
        </Box>
        <Box mt={1}>
          <FormTitle title="部署" />
          <TextField value={form.department} name="department" inputProps={{ maxLength: 20 }} fullWidth onChange={onChange} />
        </Box>
        <Box mt={1}>
          <FormTitle title="役職" />
          <TextField value={form.post} name="post" inputProps={{ maxLength: 20 }} fullWidth onChange={onChange} />
        </Box>
        <Box mt={1}>
          <FormTitle title="役割" />
          <SearchSelectBox
            name="roleCode"
            options={select}
            value={form.roleCode}
            onChange={onChange}
          />
        </Box>
        <Box mt={1}>
          <FormTitle title="メール" />
          <TextField value={form.email} name="email" inputProps={{ maxLength: 80 }} fullWidth onChange={onChange} />
        </Box>
        <Box mt={1}>
          <FormTitle title="電話番号" />
          <TextField value={form.phone} name="phone" inputProps={{ maxLength: 20 }} fullWidth onChange={onChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
