/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const costCloseSlice = createSlice({
  name: 'costClose',
  initialState: {
    data: '',
    isUpdateSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.data = {};
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state) => {
      state.isUpdateSuccess = {};
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
  },
});

export const costCloseActions = costCloseSlice.actions;

export default costCloseSlice.reducer;
