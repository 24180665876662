import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@mui/material';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import { payDetailActions } from '../../../../redux/sales/pay/payDetailState';
import DataTable from './DataTable';
import PayDetailDialog from './PayDetailDialog';

class PayDetailList extends React.Component {
  constructor(props) {
    super(props);

    this.props.dispatch(payDetailActions.getDatas({ payId: props.payId }));
    this.state = {
      datas: [],
      isSuccessOpen: false,
      successMessage: '',
      isDetailOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payDetailInfo.datas !== this.props.payDetailInfo.datas) {
      if (this.props.payDetailInfo.datas) {
        this.setDatas(this.props.payDetailInfo.datas);
      }
    }
    if (prevProps.payDetailInfo.isSaveSuccess !== this.props.payDetailInfo.isSaveSuccess) {
      if (this.props.payDetailInfo.isSaveSuccess) {
        this.setSuccess('保存が完了しました');
      }
    }
    if (prevProps.payDetailInfo.isDeleteSuccess
      !== this.props.payDetailInfo.isDeleteSuccess) {
      if (this.props.payDetailInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.props.onResearch();
    this.setState({ isSuccessOpen: true, successMessage, isDetailOpen: false });
  }

  onSearch = () => {
    this.props.dispatch(payDetailActions.getDatas({ payId: this.props.payId }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDelete = (ids) => {
    this.props.dispatch(payDetailActions.deleteData({ trnPayId: this.props.payId, ids: { ids } }));
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true, payDetailId: null });
  };

  onDetail = (_, id) => {
    this.setState({ isDetailOpen: true, payDetailId: id });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  render() {
    const {
      datas,
      isSuccessOpen,
      successMessage,
      isDetailOpen,
      payDetailId,
    } = this.state;
    const { isDisabled } = this.props;

    return (
      <Container maxWidth="xl">
        <DataTable
          rows={datas || []}
          onAdd={this.onAdd}
          onDetail={this.onDetail}
          onDelete={this.onDelete}
          disabled={isDisabled}
        />

        <PayDetailDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          payDetailId={payDetailId}
          payId={this.props.payId}
          targetMonth={this.props.targetMonth}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  payDetailInfo: state.payDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(PayDetailList);
