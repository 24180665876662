import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MaterialDialog from '../../atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../atoms/dialogColorTitle/DialogColorTitle';

export default function ConfirmDialog(props) {
  const {
    open,
    title,
    content,
    onClose,
    onClick,
    loading,
    procName,
    isError,
  } = props;

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle isDelete={isError}>{title}</DialogColorTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={loading} color="inherit">キャンセル</LoadingButton>
        <LoadingButton
          onClick={onClick}
          loading={loading}
          color={isError ? 'secondary' : 'primary'}
        >
          {procName}
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
