import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container,
} from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { assetActions } from '../../../redux/master/asset/assetState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DataTable from './DataTable';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import SearchBox from '../../../components/templates/searchBox/SearchBox';

class AssetList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      searchName: '',
      searchCategory: [],
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.assetInfo.searchCondition) {
      searchCondition = props.assetInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      selects: {
        statuses: [],
        categories: [],
      },
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.props.dispatch(assetActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetInfo.datas !== this.props.assetInfo.datas) {
      if (this.props.assetInfo.datas) {
        this.setDatas(this.props.assetInfo.datas);
      }
    }
    if (prevProps.assetInfo.selects !== this.props.assetInfo.selects) {
      if (this.props.assetInfo.selects) {
        this.setSelect(this.props.assetInfo.selects);
      }
    }
    if (prevProps.assetInfo.isDeleteSuccess
      !== this.props.assetInfo.isDeleteSuccess) {
      if (this.props.assetInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setSelect = (selects) => {
    this.setState({ selects });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(assetActions.setSearchCondition(searchCondition));
    this.props.dispatch(assetActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(assetActions.deleteData({ ids }));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      selects,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="資格ID" />
              <TextField value={searchCondition.searchId} fullWidth name="searchId" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="カテゴリ" />
              <SearchSelectBox
                name="searchKubun"
                options={selects.categories}
                value={searchCondition.searchKubun}
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="状況" />
              <SearchSelectBox
                name="searchStatus"
                options={selects.statuses}
                value={searchCondition.searchStatus}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
        </SearchBox>
        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  assetInfo: state.assetStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(AssetList);
