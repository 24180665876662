import React from 'react';
import {
  DialogContent, DialogActions, Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isValid } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { cacheActions } from '../../../redux/office/cache/cacheState';

export default function ConfCheckDialog(props) {
  const [targetDate, setTargetDate] = React.useState('');
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const dispatch = useDispatch();
  const {
    open,
    onClose,
    searchKbn,
    selected,
  } = props;

  const onChange = (event) => {
    const { value } = event.target;
    setTargetDate(value);
  };

  const dateValidation = (value) => {
    if (!value) return true;
    if (!isValid(new Date(value))) return true;
    return '';
  };

  const onClick = () => {
    dispatch(cacheActions.updateConfirm({
      targetKbn: searchKbn, ids: { ids: selected }, targetDate,
    }));
  };

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle>確認済処理</DialogColorTitle>
      <DialogContent dividers>
        <Box mb={2}>選択したデータを確認済にします</Box>
        <FormTitle title="確認日" />
        <DatePicker
          handleChange={onChange}
          value={targetDate}
          name="targetDate"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={isLoading} color="inherit">キャンセル</LoadingButton>
        <LoadingButton
          onClick={onClick}
          color="primary"
          loading={isLoading}
          disabled={dateValidation(targetDate)}
        >
          確認済にする
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
