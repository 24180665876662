import React from 'react';
import { Box } from '@mui/material';

export default function CalendarContent(props) {
  const { datas, item } = props;

  if (!item.isSomeMonth) {
    return (<div />);
  }
  return (
    <Box>
      <div>{item.day}</div>
      <Box>
        {datas.map((data) => (
          <Box
            key={data.id}
          >
            {data.category !== '定期' && (
              <Box>{data.ridePoint + (data.category === '往復' ? '⇔' : '→') + data.rideOffPoint}</Box>
            )}
            {data.category === '定期' && (
              <Box>{`定：${data.ridePoint}⇔${data.rideOffPoint}`}</Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
