import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Table, TableBody, TableCell, Checkbox,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper, Box, LinearProgress,
  Tooltip, IconButton, Button, ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import {
  Close as CloseIcon, CalendarMonth as CalendarMonthIcon, TableView as TableViewIcon,
} from '@mui/icons-material';
import ConfirmDialog from '../../../../components/templates/confirmDialog/ConfirmDialog';
import TrafficDetailDialog from './TrafficDetailDialog';
import { trafficRequestDetailActions } from '../../../../redux/request/trafficRequest/trafficRequestDetailState';
import {
  getCalnedarMonthly,
} from './traffic-request-proc';
import CalendarContent from './CalendarContent';

const DataTd = styled('td')(({ disabled, rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  height: '80px',
  verticalAlign: 'top',
  ...(disabled && {
    background: '#e6e6e6',
    color: '#999999',
  }),
  ...(rested && {
    color: 'red',
  }),
  ...(!disabled && {
    '&:hover': {
      background: '#f1f3f4',
    },
  }),
}));

const HeaderTd = styled('th')(({ rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  verticalAlign: 'top',
  ...(rested && {
    color: 'red',
  }),
}));

export default function DataDetailTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [confOpen, setConfOpen] = React.useState(false);
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [displayType, setDisplayType] = React.useState('table');
  const dispatch = useDispatch();

  const {
    rows,
    header,
    loading,
    onCopy,
  } = props;

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const headCells = ['日付', '用途', '乗車路線', '乗車駅', '降車路線', '降車駅', '区分', '金額'];

  const onAdd = () => {
    setDetailOpen(true);
    setSelectedId(null);
  };

  const onDetail = (_, id) => {
    setDetailOpen(true);
    setSelectedId(id);
  };

  const handleClose = () => {
    setConfOpen(false);
  };

  const onClickConfirm = () => {
    setConfOpen(true);
  };

  const onProcClick = () => {
    setConfOpen(false);
    dispatch(trafficRequestDetailActions.deleteData({ id: header.id, ids: selected }));
    props.onProcStart();
    setSelected([]);
  };

  const onCloseDetailDialog = () => {
    setDetailOpen(false);
    props.onClose();
  };

  const onDisplayChange = (_, value) => {
    if (!value) {
      return;
    }
    setDisplayType(value);
  };

  const calendarMonthly = getCalnedarMonthly(header.internalMonth);

  return (
    <Box mt={1}>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 && (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Box mr={2}>
                <Tooltip title="削除">
                  <Button color="secondary" variant="contained" onClick={onClickConfirm}>削除</Button>
                </Tooltip>
              </Box>
            </>
          )}
          {(header.statusCode === '0' && selected.length === 0) && (
            <>
              <Box fontWeight="bold" style={{ flex: '1 1 30%' }} ml={2}>
                明細
              </Box>
              <Box mr={2}>
                <Tooltip title="前月コピー">
                  <Button color="primary" variant="outlined" onClick={onCopy}>前月コピー</Button>
                </Tooltip>
              </Box>
              <Tooltip title="新規申請">
                <Button color="primary" variant="outlined" onClick={onAdd}>新規申請</Button>
              </Tooltip>
            </>
          )}
          <Box sx={{ textAlign: 'right' }} ml={2}>
            <ToggleButtonGroup
              size="small"
              value={displayType}
              exclusive
              onChange={onDisplayChange}
              aria-label="text alignment"
            >
              <ToggleButton value="table">
                <TableViewIcon />
              </ToggleButton>
              <ToggleButton value="calendar">
                <CalendarMonthIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Toolbar>
        {loading && (<LinearProgress />)}
        {displayType === 'table' && (
          <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
            <Table size="medium" stickyHeader>
              <TableHead>
                <TableRow>
                  {header.statusCode === '0' && (
                    <TableCell key="table-header-check" padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selected.length > 0
                          && rows.length !== selected.length
                        }
                        checked={
                          selected.length > 0
                          && rows.length === selected.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        color="primary"
                        disabled={rows.length === 0}
                      />
                    </TableCell>
                  )}
                  {headCells.map((headCell) => (
                    <TableCell key={headCell}>{headCell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {header.statusCode === '0' && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        {header.statusCode === '0' && (
                          <Button onClick={(e) => onDetail(e, row.id)}>{row.targetDay}</Button>
                        )}
                        {header.statusCode !== '0' && (
                          <Box>{row.targetDay}</Box>
                        )}
                      </TableCell>
                      <TableCell>{row.content}</TableCell>
                      <TableCell>{row.rideRoute}</TableCell>
                      <TableCell>{row.ridePoint}</TableCell>
                      <TableCell>{row.rideOffRoute}</TableCell>
                      <TableCell>{row.rideOffPoint}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.fee.toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
                {
                  rows.length < 1 && (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={9}>
                        データがありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {displayType === 'calendar' && (
          <Table
            width="100%"
            sx={{
              border: 'solid 1px #DDD',
              borderCollapse: 'collapse',
              textAlign: 'center',
            }}
          >
            <thead>
              <tr>
                {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
                  <HeaderTd key={week} rested={week === '日' || week === '土'}>{week}</HeaderTd>
                ))}
              </tr>
            </thead>
            <tbody>
              {calendarMonthly.map((weekRow, index) => (
                <tr key={`${weekRow[index].date}month`}>
                  {weekRow.map((item) => {
                    const data = rows.filter((row) => row.targetDay === item.formatDate);
                    return (
                      <DataTd
                        key={item.day}
                        disabled={!item.isSomeMonth}
                        rested={item.week === '日' || item.week === '土'}
                      >
                        <CalendarContent datas={data} item={item} />
                      </DataTd>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <ConfirmDialog
          isError
          open={confOpen}
          onClose={handleClose}
          title="選択した明細削除"
          content="選択した明細を削除します"
          onClick={onProcClick}
          procName="削除する"
        />
        <TrafficDetailDialog
          header={header}
          open={detailOpen}
          id={selectedId}
          onClose={onCloseDetailDialog}
        />
      </Paper>
    </Box>
  );
}
