import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { roleListActions } from './roleListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SETTING.ROLE_LIST, payload);
    yield put(roleListActions.getDataSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SETTING.ROLE_LIST, payload);
    yield put(roleListActions.deleteDataSuccess());
  } catch (_) {}
}

function* roleListSaga() {
  yield takeLatest('roleList/getData', getData);
  yield takeLatest('roleList/deleteData', deleteData);
}

export default roleListSaga;
