import React from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Paper, TextField } from '@mui/material';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipInput(props) {
  const { datas, onChange, name } = props;
  const [inputValue, setInputValue] = React.useState('');

  const values = datas ? datas.split(',') : [];

  const handleDelete = (_, chipToDelete) => {
    const retData = values.filter((row) => row !== chipToDelete);
    onChange({ target: { name, value: retData.join(',') } });
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const onEnter = (e) => {
    if (e.key !== 'Enter') {
      return;
    }
    const { value } = e.target;
    const isExist = values.filter((row) => row === value).length > 0;
    setInputValue('');
    if (isExist) {
      return;
    }
    const retValue = datas ? `${datas},${value}` : value;
    onChange({ target: { name, value: retValue } });
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      variant="outlined"
      component="ul"
    >
      {values.map((data) => (
        <ListItem key={data}>
          <Chip
            label={data}
            onDelete={(e) => handleDelete(e, data)}
          />
        </ListItem>
      ))}
      <TextField
        variant="standard"
        autoComplete="off"
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={onEnter}
      />
    </Paper>
  );
}
