import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  TextField, DialogActions, DialogContent, Button,
  LinearProgress, Box, Switch, FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { roleDetailActions } from '../../../redux/setting/roleDetail/roleDetailState';

class RoleDetailDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        id: null,
        name: '',
        approval: false,
        affairs: false,
        bp: false,
      },
      nameErrorMessage: '',
      btnLoading: false,
      loading: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
  }

  handleSave = () => {
    const { info } = this.state;
    this.props.dispatch(roleDetailActions.saveData(info));
  };

  onChange = (event) => {
    const { value, name } = event.target;
    const { info, nameErrorMessage } = this.state;

    let message = nameErrorMessage;
    if (name === 'name') {
      message = value ? '' : '入力してください';
    }

    this.setState({
      info: { ...info, [name]: value },
      nameErrorMessage: message,
    });
  };

  onToggleChange = (event) => {
    const { info } = this.state;
    const { checked, name } = event.target;
    this.setState({ info: { ...info, [name]: checked } });
  };

  init() {
    const { id, role } = this.props;

    let info = {
      id: id || null,
      name: '',
      approval: false,
      affairs: false,
    };
    if (id) {
      info = {
        id,
        ...role,
      };
    }

    this.setState({
      loading: false,
      info,
      nameErrorMessage: '',
      btnLoading: false,
    });
  }

  render() {
    const {
      open, handleClose,
    } = this.props;

    const {
      info, nameErrorMessage, loading, btnLoading,
    } = this.state;

    return (
      <MaterialDialog open={open} onClose={handleClose}>
        <DialogColorTitle>{ info.id ? '権限の変更' : '権限の追加' }</DialogColorTitle>
        <LinearProgress style={{ display: loading ? '' : 'none' }} />
        <DialogContent dividers style={{ display: loading ? 'none' : '' }}>
          <Box>
            <FormTitle title="名前" isRequired />
            <TextField
              id="auth-name"
              name="name"
              autoComplete="off"
              size="small"
              variant="outlined"
              value={info.name}
              onChange={this.onChange}
              fullWidth
              error={nameErrorMessage !== ''}
              inputProps={{
                maxLength: 50,
              }}
            />
            <FormErrorText>{nameErrorMessage}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="承認権限" />
            <FormControlLabel control={<Switch checked={info.approval} name="approval" onChange={this.onToggleChange} />} label="付与" />
          </Box>
          <Box mt={2}>
            <FormTitle title="事務権限" />
            <FormControlLabel control={<Switch checked={info.affairs} name="affairs" onChange={this.onToggleChange} />} label="付与" />
          </Box>
          <Box mt={2}>
            <FormTitle title="BP用" />
            <FormControlLabel control={<Switch checked={info.bp} name="bp" onChange={this.onToggleChange} />} label="付与" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">キャンセル</Button>
          <LoadingButton
            onClick={this.handleSave}
            loading={btnLoading}
            disabled={!info.name}
          >
            保存する
          </LoadingButton>
        </DialogActions>
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  roleList: state.roleListStore,
  roleDetail: state.roleDetailStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(RoleDetailDialog);
