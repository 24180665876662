/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Table, TableContainer, IconButton,
  TableHead, TableRow, TableBody, Button, Tooltip,
} from '@mui/material';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { addMonths, format } from 'date-fns';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../../components/atoms/withParam/WithParams';
import { checkIsDisabled } from '../../../../utils/authCheck.helper';
import {
  getTargetYear, getMonths, getBalance, setBalance, getCostSummaryByTarget, getAddCosts,
  getBalanceSummary, getCost, setCost, getCostSummary, getTanka, isInputDisabled, deleteDetail,
} from './project-balance-detail-proc';
import SmallMoneyInput from '../../../../components/atoms/smallMoneyInput/SmallMoneyInput';
import SmallNumberInput from '../../../../components/atoms/smallNumberInput/SmallNumberInput';
import { projectBalanceActions } from '../../../../redux/summary/projectBalance/projectBalanceState';
import SelectDialog from './SelectDialog';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';

const HeaderTd = styled('th')(() => ({
  border: 'solid 1px #DDD',
  fontSize: '13px',
  width: '100px',
  maxWidth: '100px',
}));

const DataTr = styled(TableRow)(({ index }) => ({
  ...(index % 2 === 0 && {
    background: '#f8f8f8',
  }),
}));

const DataTd = styled('td')(() => ({
  border: 'solid 1px #DDD',
  fontSize: '13px',
  padding: 0,
}));

class ProjectBalanceDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const isDisabled = checkIsDisabled(props.loginUser.userInfo.user);
    const condition = getTargetYear();
    const searchCondition = {
      startMonth: condition.startMonth,
      endMonth: condition.endMonth,
      projectId: id,
    };
    this.tmpTake = {};

    this.state = {
      searchCondition,
      months: getMonths(condition.startMonth, condition.endMonth),
      isDisabled,
      data: {
        balances: [], syains: [], outsources: [], costs: [],
      },
      projectName: '',
      closeDate: '',
      isAddDialog: false,
      isSyain: false,
      isSuccess: false,
    };
    this.search(searchCondition);
    props.dispatch(projectBalanceActions.getDetailSelect(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectBalanceInfo.detail !== this.props.projectBalanceInfo.detail) {
      if (this.props.projectBalanceInfo.detail) {
        this.setData(this.props.projectBalanceInfo.detail);
      }
    }
    if (prevProps.projectBalanceInfo.tanka !== this.props.projectBalanceInfo.tanka) {
      if (this.props.projectBalanceInfo.tanka) {
        this.setTanka(this.props.projectBalanceInfo.tanka);
      }
    }
    if (prevProps.projectBalanceInfo.isUpdateSuccess !== this.props.projectBalanceInfo.isUpdateSuccess) {
      if (this.props.projectBalanceInfo.isUpdateSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
    this.setState({ isSuccess: true });
  };

  setTanka = (cost) => {
    const {
      target, detailIndex,
    } = this.tmpTake;
    const { data } = this.state;
    const tempDetailDatas = [
      ...data.costs,
    ];
    const tmpDetailData = {
      ...target,
      properPlanPriceCost: getTanka(cost, target.properPlanWorkCost),
    };
    tempDetailDatas.splice(detailIndex, 1, tmpDetailData);

    const tmpData = {
      ...data,
      costs: tempDetailDatas,
    };
    this.setState({ data: tmpData });
  };

  setData = (data) => {
    this.setState({
      data: data.data,
      closeDate: data.closeDate,
      projectName: data.projectName,
    });
  };

  search = (searchCondition) => {
    this.props.dispatch(projectBalanceActions.getDetail(searchCondition));
  };

  onBalanceChange = (e, targetMonth) => {
    const { name, value } = e.target;
    const { data } = this.state;
    const tmpBalances = setBalance(data.balances, targetMonth, name, value);
    const tmpData = {
      ...data,
      balances: tmpBalances,
    };
    this.setState({ data: tmpData });
  };

  onCostChange = (e, targetMonth, syain) => {
    const { name, value } = e.target;
    const { data } = this.state;
    const tmpCosts = setCost(data.costs, targetMonth, syain, name, value);
    const tmpData = {
      ...data,
      costs: tmpCosts,
    };
    this.setState({ data: tmpData });
  };

  onCostBlur = (_, targetMonth, syainId) => {
    let target = {};
    let detailIndex = -1;
    const { data } = this.state;
    data.costs.forEach((row, idx) => {
      if (row.targetMonth === targetMonth && row.syainId === syainId) {
        target = row;
        detailIndex = idx;
      }
    });
    this.tmpTake = {
      detailIndex, target, data,
    };
    this.props.dispatch(projectBalanceActions.getCost({ syainId, targetMonth }));
  };

  onAddDialogOpen = (_, isSyain) => {
    this.setState({
      isAddDialog: true, isSyain,
    });
  };

  onAddDialogClose = () => {
    this.setState({ isAddDialog: false, isSuccess: false });
  };

  onAddSet = (_, isSyain, target) => {
    const {
      data, months,
    } = this.state;
    let tmpOut = data.outsources;
    let tmpSyain = data.syains;
    let tmpCosts = data.costs;
    if (isSyain) {
      tmpSyain = [
        ...tmpSyain,
        target,
      ];
    } else {
      tmpOut = [
        ...tmpOut,
        target,
      ];
    }
    tmpCosts = [
      ...tmpCosts,
    ];
    tmpCosts.concat(getAddCosts(months, target));
    const tmpData = {
      ...data,
      costs: tmpCosts,
      outsources: tmpOut,
      syains: tmpSyain,
    };
    this.setState({ isAddDialog: false, data: tmpData });
  };

  onUpdate = () => {
    const { data, searchCondition, months } = this.state;
    const param = {
      data,
      projectId: searchCondition.projectId,
      startDate: months[0].targetDate,
      endDate: months[months.length - 1].targetDate,
    };
    this.props.dispatch(projectBalanceActions.updateDetail(param));
    this.setState({ isSuccess: false });
  };

  onSuccessClose = () => {
    this.setState({ isSuccess: false });
  };

  onClickBefore = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.startMonth), -6), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.startMonth), -1), 'yyyy/MM/dd'),
    };
    this.setState({ searchCondition: tmpCondition, months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth) });
    this.search(tmpCondition);
  };

  onClickAfter = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.endMonth), 1), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.endMonth), 6), 'yyyy/MM/dd'),
    };
    this.setState({ searchCondition: tmpCondition, months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth) });
    this.search(tmpCondition);
  };

  onDetailDelete = (_, costName, target) => {
    const { data } = this.state;
    const tmpDatas = deleteDetail(costName, target, data);
    this.setState({ data: tmpDatas });
  };

  render() {
    const {
      isDisabled, searchCondition, months, projectName, isSuccess,
      isAddDialog, isSyain, data, closeDate,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Box display="flex" mb={2}>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <Tooltip title="前へ">
                <IconButton color="primary" size="small" onClick={this.onClickBefore}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center">{format(new Date(searchCondition.startMonth), 'yyyy年MM月')}</Box>
            <Box display="flex" alignItems="center" mx={1}>～</Box>
            <Box display="flex" alignItems="center">{format(new Date(searchCondition.endMonth), 'yyyy年MM月')}</Box>
            <Box>
              <Tooltip title="次へ">
                <IconButton color="primary" size="small" onClick={this.onClickAfter}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box style={{ flex: '50%', fontWeight: 'bold' }} display="flex" alignItems="center">{projectName}</Box>
          {!isDisabled && (
            <Box>
              <LoadingButton
                loading={this.props.common.isLoading}
                onClick={this.onUpdate}
                variant="contained"
              >
                保存
              </LoadingButton>
            </Box>
          )}
        </Box>
        <Box mt={1} mb={3}>
          <Box mt={2}>
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table
                width="100%"
                stickyHeader
                sx={{
                  border: 'solid 1px #DDD',
                  borderCollapse: 'collapse',
                  textAlign: 'center',
                }}
              >
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: 0,
                    background: '#fff',
                  }}
                >
                  <TableRow>
                    <HeaderTd rowSpan={2} colSpan={2} />
                    {months.map((item) => (
                      <HeaderTd key={`${item.targetDate}s`} colSpan={2}>{item.displayDate}</HeaderTd>
                    ))}
                    <HeaderTd colSpan={2}>合計</HeaderTd>
                  </TableRow>
                  <TableRow>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}title`}>
                        <HeaderTd>予定</HeaderTd>
                        <HeaderTd>実績</HeaderTd>
                      </React.Fragment>
                    ))}
                    <HeaderTd>予定</HeaderTd>
                    <HeaderTd>実績</HeaderTd>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <DataTd colSpan={2}>工数売上</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}UnitPrice`}>
                        <DataTd>
                          <SmallMoneyInput
                            name="planUnitPrice"
                            value={getBalance(data.balances, item.targetDate, 'planUnitPrice')}
                            disabled={isInputDisabled(item.targetDate, closeDate)}
                            onChange={
                              (e) => this.onBalanceChange(e, item.targetDate)
                            }
                          />
                        </DataTd>
                        <DataTd>
                          <SmallMoneyInput
                            name="actualUnitPrice"
                            value={getBalance(data.balances, item.targetDate, 'actualUnitPrice')}
                            disabled={isInputDisabled(item.targetDate, closeDate)}
                            onChange={
                              (e) => this.onBalanceChange(e, item.targetDate)
                            }
                          />
                        </DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getBalanceSummary(data.balances, 'planUnitPrice')}</DataTd>
                    <DataTd>{getBalanceSummary(data.balances, 'actualUnitPrice')}</DataTd>
                  </TableRow>
                  <TableRow>
                    <DataTd colSpan={2}>その他売上</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}OtherPrice`}>
                        <DataTd>
                          <SmallMoneyInput
                            name="planOtherPrice"
                            value={getBalance(data.balances, item.targetDate, 'planOtherPrice')}
                            disabled={isInputDisabled(item.targetDate, closeDate)}
                            onChange={
                              (e) => this.onBalanceChange(e, item.targetDate)
                            }
                          />
                        </DataTd>
                        <DataTd>
                          <SmallMoneyInput
                            name="actualOtherPrice"
                            value={getBalance(data.balances, item.targetDate, 'actualOtherPrice')}
                            disabled={isInputDisabled(item.targetDate, closeDate)}
                            onChange={
                              (e) => this.onBalanceChange(e, item.targetDate)
                            }
                          />
                        </DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getBalanceSummary(data.balances, 'planOtherPrice')}</DataTd>
                    <DataTd>{getBalanceSummary(data.balances, 'actualOtherPrice')}</DataTd>
                  </TableRow>
                  <TableRow>
                    <DataTd colSpan={2}>売上合計</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}AllPrice`}>
                        <DataTd>{getBalance(data.balances, item.targetDate, 'planAllPrice')}</DataTd>
                        <DataTd>{getBalance(data.balances, item.targetDate, 'actualAllPrice')}</DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getBalanceSummary(data.balances, 'planAllPrice')}</DataTd>
                    <DataTd>{getBalanceSummary(data.balances, 'actualAllPrice')}</DataTd>
                  </TableRow>
                  <TableRow style={{ background: '#DDD', fontSize: '15px' }}>
                    <td colSpan={(months.length * 2) + 4} style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <Box display="flex">
                        <Box display="flex" alignItems="center">プロパー</Box>
                        <Button onClick={(e) => this.onAddDialogOpen(e, true)} size="small">行追加</Button>
                      </Box>
                    </td>
                  </TableRow>
                  {data.syains.map((syain) => (
                    <React.Fragment key={syain.costName}>
                      <DataTr hover>
                        <DataTd rowSpan={2} style={{ width: '20px' }}>
                          <Tooltip title="削除する">
                            <IconButton size="small" onClick={(e) => this.onDetailDelete(e, syain.costName, '0')}><ClearIcon /></IconButton>
                          </Tooltip>
                        </DataTd>
                        <DataTd rowSpan={2} style={{ width: '200px' }}>{syain.costName}</DataTd>
                        {months.map((item) => (
                          <React.Fragment key={`${syain.costName + item.targetDate}workCost`}>
                            <DataTd>
                              <SmallNumberInput
                                name="properPlanWorkCost"
                                value={getCost(data.costs, item.targetDate, syain.costName, 'properPlanWorkCost')}
                                disabled={isInputDisabled(item.targetDate, closeDate)}
                                onChange={
                                  (e) => this.onCostChange(e, item.targetDate, syain)
                                }
                                onBlur={
                                  (e) => this.onCostBlur(e, item.targetDate, syain.syainId)
                                }
                              />
                            </DataTd>
                            <DataTd>
                              <Box textAlign="right" mr={1}>{getCost(data.costs, item.targetDate, syain.costName, 'properActualWorkCost')}</Box>
                            </DataTd>
                          </React.Fragment>
                        ))}
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'properPlanWorkCost')}</DataTd>
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'properActualWorkCost')}</DataTd>
                      </DataTr>
                      <DataTr hover>
                        {months.map((item) => (
                          <React.Fragment key={`${syain.costName + item.targetDate}PriceCost`}>
                            <DataTd>
                              <SmallMoneyInput
                                name="properPlanPriceCost"
                                disabled={isInputDisabled(item.targetDate, closeDate)}
                                value={getCost(data.costs, item.targetDate, syain.costName, 'properPlanPriceCost')}
                                onChange={
                                  (e) => this.onCostChange(e, item.targetDate, syain)
                                }
                              />
                            </DataTd>
                            <DataTd>
                              <Box textAlign="right" mr={1}>{getCost(data.costs, item.targetDate, syain.costName, 'properActualPriceCost')}</Box>
                            </DataTd>
                          </React.Fragment>
                        ))}
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'properPlanPriceCost')}</DataTd>
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'properActualPriceCost')}</DataTd>
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <TableRow style={{ background: '#DDD', fontSize: '15px' }}>
                    <td colSpan={(months.length * 2) + 4} style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <Box display="flex">
                        <Box display="flex" alignItems="center">外注</Box>
                        <Button onClick={(e) => this.onAddDialogOpen(e, false)} size="small">行追加</Button>
                      </Box>
                    </td>
                  </TableRow>
                  {data.outsources.map((syain) => (
                    <React.Fragment key={syain.costName}>
                      <DataTr hover>
                        <DataTd rowSpan={2} style={{ width: '20px' }}>
                          <Tooltip title="削除する">
                            <IconButton size="small" onClick={(e) => this.onDetailDelete(e, syain.costName, '1')}><ClearIcon /></IconButton>
                          </Tooltip>
                        </DataTd>
                        <DataTd rowSpan={2} style={{ width: '200px' }}>{syain.costName}</DataTd>
                        {months.map((item) => (
                          <React.Fragment key={`${syain.costName + item.targetDate}workCost`}>
                            <DataTd>
                              <SmallNumberInput
                                name="outsourcePlanWorkCost"
                                disabled={isInputDisabled(item.targetDate, closeDate)}
                                value={getCost(data.costs, item.targetDate, syain.costName, 'outsourcePlanWorkCost')}
                                onChange={
                                  (e) => this.onCostChange(e, item.targetDate, syain)
                                }
                              />
                            </DataTd>
                            <DataTd>
                              <SmallNumberInput
                                name="outsourceActualWorkCost"
                                disabled={isInputDisabled(item.targetDate, closeDate)}
                                value={getCost(data.costs, item.targetDate, syain.costName, 'outsourceActualWorkCost')}
                                onChange={
                                  (e) => this.onCostChange(e, item.targetDate, syain)
                                }
                              />
                            </DataTd>
                          </React.Fragment>
                        ))}
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'outsourcePlanWorkCost')}</DataTd>
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'outsourceActualWorkCost')}</DataTd>
                      </DataTr>
                      <DataTr hover>
                        {months.map((item) => (
                          <React.Fragment key={`${syain.costName + item.targetDate}PriceCost`}>
                            <DataTd>
                              <SmallMoneyInput
                                name="outsourcePlanPriceCost"
                                disabled={isInputDisabled(item.targetDate, closeDate)}
                                value={getCost(data.costs, item.targetDate, syain.costName, 'outsourcePlanPriceCost')}
                                onChange={
                                  (e) => this.onCostChange(e, item.targetDate, syain)
                                }
                              />
                            </DataTd>
                            <DataTd>
                              <SmallMoneyInput
                                name="outsourceActualPriceCost"
                                disabled={isInputDisabled(item.targetDate, closeDate)}
                                value={getCost(data.costs, item.targetDate, syain.costName, 'outsourceActualPriceCost')}
                                onChange={
                                  (e) => this.onCostChange(e, item.targetDate, syain)
                                }
                              />
                            </DataTd>
                          </React.Fragment>
                        ))}
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'outsourcePlanPriceCost')}</DataTd>
                        <DataTd>{getCostSummary(data.costs, syain.costName, 'outsourceActualPriceCost')}</DataTd>
                      </DataTr>
                    </React.Fragment>
                  ))}
                  <TableRow style={{ borderTop: '3px double #000' }}>
                    <DataTd colSpan={2}>工数合計</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}SumWorkCost`}>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'planWorkCost')}</DataTd>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'actualWorkCost')}</DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'planWorkCost')}</DataTd>
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'actualWorkCost')}</DataTd>
                  </TableRow>
                  <TableRow>
                    <DataTd colSpan={2}>費用合計</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}SumPriceCost`}>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'planPriceCost')}</DataTd>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'actualPriceCost')}</DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'planPriceCost')}</DataTd>
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'actualPriceCost')}</DataTd>
                  </TableRow>
                  <TableRow>
                    <DataTd colSpan={2}>プロパ費</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}SumPriceCost`}>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'planProperCost')}</DataTd>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'actualProperCost')}</DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'planProperCost')}</DataTd>
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'actualProperCost')}</DataTd>
                  </TableRow>
                  <TableRow>
                    <DataTd colSpan={2}>外注費</DataTd>
                    {months.map((item) => (
                      <React.Fragment key={`${item.targetDate}SumPriceCost`}>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'planOutsourceCost')}</DataTd>
                        <DataTd>{getCostSummaryByTarget(data.costs, item.targetDate, 'actualOutsourceCost')}</DataTd>
                      </React.Fragment>
                    ))}
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'planOutsourceCost')}</DataTd>
                    <DataTd>{getCostSummaryByTarget(data.costs, null, 'actualOutsourceCost')}</DataTd>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <SelectDialog
          isOpen={isAddDialog}
          onClose={this.onAddDialogClose}
          isSyain={isSyain}
          onAddSet={this.onAddSet}
          exists={data.syains}
        />
        <SuccessSnackbar
          open={isSuccess}
          handleClose={this.onSuccessClose}
          message="保存が完了しました"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  projectBalanceInfo: state.projectBalanceStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(ProjectBalanceDetail));
