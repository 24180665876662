export function getCheckableCount(datas, selected, userInfo) {
  if (!userInfo || !userInfo.affairs) {
    return 0;
  }
  const targets = [];
  selected.forEach((c) => {
    datas.forEach((v) => {
      if (c === v.id) {
        targets.push(v);
      }
    });
  });
  return targets.filter((v) => v.stateCd === '3').length;
}

export function isCheckableCount(datas, userInfo) {
  if (!userInfo) {
    return 0;
  }
  let certifyDatas = datas.filter((v) => v.stateCd === '3').length;
  if (!userInfo.affairs) {
    certifyDatas = 0;
  }
  return certifyDatas;
}

export function isNotDisabled(data, userInfo) {
  if (!userInfo || (!userInfo.affairs)) {
    return false;
  }
  return data.stateCd === '3';
}
