/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const expenseRequestDetailSlice = createSlice({
  name: 'expenseRequestDetail',
  initialState: {
    data: {},
    saveId: null,
    isSaveSuccess: false,
    isStatusUpdateSuccess: false,
  },
  reducers: {
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    saveStatus: (state) => {
      state.isStatusUpdateSuccess = false;
    },
    saveStatusSuccess: (state) => {
      state.isStatusUpdateSuccess = true;
    },
  },
});

export const expenseRequestDetailActions = expenseRequestDetailSlice.actions;

export default expenseRequestDetailSlice.reducer;
