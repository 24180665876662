import React from 'react';
import { connect } from 'react-redux';
import {
  format, isBefore, isEqual, subDays,
} from 'date-fns';
import {
  Grid, Box, DialogContent, Button, DialogActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import { assetRequestDetailActions } from '../../../redux/request/assetRequest/assetRequestDetailState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';

class AssetRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: '',
      isSuccessOpen: false,
      form: {},
      today: format(new Date(), 'yyyy/MM/dd'),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetRequestDetailInfo.data !== this.props.assetRequestDetailInfo.data) {
      if (this.props.assetRequestDetailInfo.data) {
        this.setDatas(this.props.assetRequestDetailInfo.data);
      }
    }
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.init();
      }
    }
    if (prevProps.assetRequestDetailInfo.isReturnSuccess
      !== this.props.assetRequestDetailInfo.isReturnSuccess) {
      if (this.props.assetRequestDetailInfo.isReturnSuccess) {
        this.setSuccess('返却処理が完了しました');
      }
    }
    if (prevProps.assetRequestDetailInfo.isUpdateSuccess
      !== this.props.assetRequestDetailInfo.isUpdateSuccess) {
      if (this.props.assetRequestDetailInfo.isUpdateSuccess) {
        this.setSuccess('継続利用処理が完了しました');
      }
    }
  }

  setSuccess = (successMessage) => {
    this.setState({ successMessage, isSuccessOpen: true });
    this.props.dispatch(assetRequestDetailActions.getData({ id: this.props.id }));
  };

  init = () => {
    this.props.dispatch(assetRequestDetailActions.getData({ id: this.props.id }));
    let isSuccessOpen = false;
    let successMessage = '';
    if (this.props.isCreated) {
      successMessage = '申請処理が完了しました';
      isSuccessOpen = true;
    }
    this.setState({ successMessage, isSuccessOpen });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onReturn = () => {
    this.setState({ isSuccessOpen: false });
    const { form } = this.state;
    this.props.dispatch(assetRequestDetailActions.returnDate({ id: form.id }));
  };

  onContinue = () => {
    this.setState({ isSuccessOpen: false });
    const { form } = this.state;
    this.props.dispatch(assetRequestDetailActions.updateDatas({ id: form.id }));
  };

  render() {
    const {
      form, isSuccessOpen, successMessage, today,
    } = this.state;
    const { open, onClose } = this.props;

    return (
      <MaterialDialog open={open} onClose={onClose}>
        <DialogColorTitle>資産申請詳細</DialogColorTitle>
        <Box p={1} textAlign="right">
          <Box sx={{ flex: '0 0 auto' }}>
            {((isBefore(subDays(new Date(form.returnDate), 5), new Date(today))
            || isEqual(new Date(today), subDays(new Date(form.returnDate), 5))) && (form.state === '1' || form.state === '2')) && (
              <LoadingButton loading={this.props.common.isLoading} variant="contained" size="small" onClick={this.onContinue} style={{ marginRight: '5px' }}>継続使用</LoadingButton>
            )}
            {(!form.completed && (form.state === '1' || form.state === '2')) && (
              <LoadingButton loading={this.props.common.isLoading} variant="contained" color="secondary" size="small" onClick={this.onReturn}>返却</LoadingButton>
            )}
          </Box>
        </Box>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={4} md={4}>
              <FormTitle title="資産ID" />
              <Box>{form.sisanId}</Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormTitle title="資産名" />
              <Box>{form.sisanName}</Box>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={4} md={4}>
              <FormTitle title="名前" />
              <Box>{form.syainName}</Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <FormTitle title="理由" />
              <Box>{form.borrowingReason}</Box>
            </Grid>
          </Grid>
          {form.id && (
            <Grid container mt={2}>
              <Grid item xs={4} md={4}>
                <FormTitle title="申請日" />
                <Box>{form.applyDt}</Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <FormTitle title="借用日" />
                <Box>{form.loanDate}</Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <FormTitle title="返却予定日" />
                <Box>{form.returnDate}</Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>閉じる</Button>
        </DialogActions>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </MaterialDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  assetRequestDetailInfo: state.assetRequestDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(AssetRequestDetail));
