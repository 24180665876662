import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Box,
} from '@mui/material';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import { approvalHistoryActions } from '../../../redux/sales/approvalHistory/approvalHistoryState';

export default function ApprovalHistory(props) {
  const data = useSelector((state) => state.approvalHistoryStore.data);
  const [searchCondition, setSearchCondition] = React.useState({
    page: 0,
    records: 50,
    order: 'desc',
    orderBy: 'created_at',
  });
  const [rows, setRows] = React.useState([]);
  const [totalElements, setTotalElements] = React.useState(0);
  const {
    estimateId, claimId, orderId, payId, inspectId,
  } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (estimateId || claimId || orderId || payId || inspectId) {
      const tempCondition = {
        ...searchCondition,
        page: 0,
        estimateId: estimateId || null,
        claimId: claimId || null,
        orderId: orderId || null,
        payId: payId || null,
        inspectId: inspectId || null,
      };
      setSearchCondition(tempCondition);
      dispatch(approvalHistoryActions.getDatas(tempCondition));
    }
  }, [estimateId, claimId, orderId, payId]);

  React.useEffect(() => {
    if (data) {
      setRows(data.content || []);
      setTotalElements(data.totalElements);
    }
  }, [data]);

  const headCells = [
    { id: 'approval_status', label: 'ステータス' },
    { id: 'created_by', label: '実行者' },
    { id: 'created_at', label: '実行日' },
  ];

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    dispatch(approvalHistoryActions.getDatas(tempCondition));
  };

  const handleChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    dispatch(approvalHistoryActions.getDatas(tempCondition));
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    dispatch(approvalHistoryActions.getDatas(tempCondition));
  };

  return (
    <Box mt={1}>
      <Paper>
        <TableContainer sx={{ maxHeight: 750 }}>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={searchCondition.orderBy}
                    order={searchCondition.order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                >
                  <TableCell>{row.approvalStatus}</TableCell>
                  <TableCell>{row.createdBy}</TableCell>
                  <TableCell>{row.createdAt}</TableCell>
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={3}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={searchCondition.records}
          page={searchCondition.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
