import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Slide, Dialog, TextField, Box, AppBar, Toolbar, IconButton, Typography, Button,
  FormControl, RadioGroup, Radio, FormControlLabel, Grid, MenuItem, Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { projectActions } from '../../../redux/sales/project/projectState';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import TextArea from '../../../components/atoms/textArea/TextArea';
import SelectSyainGreaterThan from '../../../components/organisms/selectSyainGreaterThan/SelectSyainGreaterThan';
import urls from '../../../constants/frontUrls';
import ProjectSubDialog from './ProjectSubDialog';

const tmpMessage = {
  projectName: '',
  nickName: '',
  clientId: '',
  contractKbn: '',
  status: '',
  eigyoTnto: '',
  stDt: '',
  edDt: '',
  projectOwner: '',
  tantoBu: '',
  orderDate: '',
  orderCause: '',
  lostCause: '',
};

const tmpForm = {
  projectId: null,
  projectName: '',
  nickName: '',
  clientId: '',
  contractKbn: '',
  status: '1',
  eigyoTnto: '',
  stDt: '',
  edDt: '',
  projectOwner: '',
  tantoBu: '',
  biko: '',
  orderDate: '',
  orderCause: '',
  mstDivisionId: null,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ProjectDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const [subOpen, setSubOpen] = React.useState(false);
  const [select, setSelect] = React.useState({
    clients: [], codes: [], busyoList: [], divisionList: [], orderCauseCodes: [],
  });
  const dispatch = useDispatch();
  const {
    isOpen, onClose, projectId, onSaveSuccess, isCopy,
  } = props;
  const data = useSelector((state) => state.projectStore.data);
  const dbSelect = useSelector((state) => state.projectStore.select);
  const isCreateSuccess = useSelector((state) => state.projectStore.isCreateSuccess);
  const isUpdateSuccess = useSelector((state) => state.projectStore.isUpdateSuccess);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const saveId = useSelector((state) => state.projectStore.saveId);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (isCreateSuccess) {
      navigate(urls.PROJECT_DETAIL + saveId);
      if (isCopy) {
        window.location.reload();
      }
    }
  }, [isCreateSuccess]);

  React.useEffect(() => {
    if (isUpdateSuccess) {
      onSaveSuccess();
      dispatch(projectActions.resetFlg());
    }
  }, [isUpdateSuccess]);

  React.useEffect(() => {
    if (dbSelect && dbSelect.clients) {
      setSelect(dbSelect);
    }
  }, [dbSelect]);

  React.useEffect(() => {
    if (isOpen) {
      if (projectId) {
        dispatch(
          projectActions.getData({ id: projectId }),
        );
      }
      setForm(tmpForm);
      setErrorMessage(tmpMessage);
      dispatch(
        projectActions.getSelect(),
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      const tempData = {
        ...data,
        projectId: isCopy ? null : data.projectId,
      };
      setForm(tempData);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.status);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (!tempMessage.stDt && !tempMessage.edDt) {
      const msg = Validation.compareDateValidate(form.stDt, form.edDt);
      if (msg) {
        isError = true;
      }
      tempMessage.edDt = msg;
    }

    tempMessage.defectProjectName = '';
    if (form.contractKbn === '5' && !form.defectProjectName) {
      tempMessage.defectProjectName = '選択してください';
    }
    if (isError) {
      return;
    }

    if (!projectId || isCopy) {
      dispatch(projectActions.insertData(form));
    } else {
      dispatch(projectActions.updateData(form));
    }
  };

  const getNickname = (clientId, projectName) => {
    if (!projectName || !clientId) {
      return '';
    }
    const targets = select.clients.filter((row) => row.id === clientId);
    let clinetName = '';
    if (targets.length > 0) {
      clinetName = targets[0].name;
    }
    return `${projectName}―${clinetName}`;
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    let { nickName } = form;
    if (name === 'clientId') {
      nickName = getNickname(value, form.projectName);
    }
    if (name === 'projectName') {
      nickName = getNickname(form.clientId, value);
    }
    const tempForm = {
      ...form,
      nickName,
      [name]: value,
    };
    let tempStatus = form.status;
    if (name === 'status') {
      tempStatus = value;
    }
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value, tempStatus) });
  };

  const onDefectProject = (defect) => {
    const tempForm = {
      ...form,
      defectProjectId: defect.projectId,
      defectProjectName: defect.projectName,
    };
    setForm(tempForm);
    setSubOpen(!subOpen);
  };

  const onClickSub = () => {
    setSubOpen(!subOpen);
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            プロジェクト編集
          </Typography>
          <LoadingButton loading={isLoading} autoFocus color="inherit" onClick={onClickSave}>
            保存
          </LoadingButton>
        </Toolbar>
      </AppBar>
      <Box p={2} style={{ overflowY: 'auto' }} mt={9}>
        <Box>
          <Box>
            <FormTitle title="プロジェクト名" isRequired />
            <TextField autoComplete="off" variant="outlined" error={errorMessage.projectName !== ''} fullWidth value={form.projectName} inputProps={{ maxLength: 80 }} name="projectName" onChange={onChange} />
            <FormErrorText>{errorMessage.projectName}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="取引先" isRequired />
            <SearchSelectBox
              error={errorMessage.clientId !== ''}
              name="clientId"
              disableClearable
              options={select.clients}
              value={form.clientId}
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.clientId}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="SBS表示名" isRequired />
            <TextField autoComplete="off" variant="outlined" fullWidth value={form.nickName} error={errorMessage.nickName !== ''} inputProps={{ maxLength: 80 }} name="nickName" onChange={onChange} />
            <FormErrorText>{errorMessage.nickName}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="契約区分" isRequired />
            <FormControl>
              <RadioGroup
                row
                value={form.contractKbn}
                onChange={onChange}
                name="contractKbn"
              >
                <FormControlLabel checked={form.contractKbn === '1'} value="1" control={<Radio />} label="SES" />
                <FormControlLabel checked={form.contractKbn === '2'} value="2" control={<Radio />} label="一括" />
                <FormControlLabel checked={form.contractKbn === '3'} value="3" control={<Radio />} label="保守" />
                <FormControlLabel checked={form.contractKbn === '4'} value="4" control={<Radio />} label="準委任" />
                <FormControlLabel checked={form.contractKbn === '5'} value="5" control={<Radio />} label="瑕疵" />
              </RadioGroup>
            </FormControl>
            <FormErrorText>{errorMessage.contractKbn}</FormErrorText>
          </Box>
          {form.contractKbn === '5' && (
            <Box mt={2}>
              <FormTitle title="ベースプロジェクト" isRequired />
              <Box style={{ wordWrap: 'break-word' }}>{form.defectProjectName}</Box>
              <Button onClick={onClickSub}>選択する</Button>
              <FormErrorText>{errorMessage.defectProjectName}</FormErrorText>
            </Box>
          )}
          <Box mt={2}>
            <FormTitle title="ステータス" isRequired />
            <SearchSelectBox
              error={errorMessage.status !== ''}
              name="status"
              disableClearable
              options={select.codes}
              value={form.status}
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.status}</FormErrorText>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormTitle title="プロジェクト開始" />
              <DatePicker
                handleChange={onChange}
                value={form.stDt}
                name="stDt"
              />
              <FormErrorText>{errorMessage.stDt}</FormErrorText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="プロジェクト終了" />
              <DatePicker
                handleChange={onChange}
                value={form.edDt}
                name="edDt"
              />
              <FormErrorText>{errorMessage.edDt}</FormErrorText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="営業担当" />
              <SelectSyainGreaterThan
                name="eigyoTnto"
                disableClearable
                targetMonth={form.stDt}
                value={form.eigyoTnto}
                onChange={onChange}
              />
              <FormErrorText>{errorMessage.eigyoTnto}</FormErrorText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="プロジェクトオーナー" />
              <SelectSyainGreaterThan
                error={errorMessage.projectOwner !== ''}
                name="projectOwner"
                targetMonth={form.stDt}
                value={form.projectOwner}
                onChange={onChange}
              />
              <FormErrorText>{errorMessage.projectOwner}</FormErrorText>
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormTitle title="担当部" />
              <SearchSelectBox
                error={errorMessage.tantoBu !== ''}
                name="tantoBu"
                options={select.busyoList}
                value={form.tantoBu}
                onChange={onChange}
              />
              <FormErrorText>{errorMessage.tantoBu}</FormErrorText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTitle title="担当課" />
              <FormControl fullWidth>
                <Select
                  value={form.mstDivisionId}
                  name="mstDivisionId"
                  onChange={onChange}
                >
                  {select.divisionList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="受注日" />
            <DatePicker
              error={errorMessage.orderDate !== ''}
              handleChange={onChange}
              value={form.orderDate}
              name="orderDate"
            />
            <FormErrorText>{errorMessage.orderDate}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="受注起因" />
            <SearchSelectBox
              error={errorMessage.orderCause !== ''}
              name="orderCause"
              options={select.orderCauseCodes}
              value={form.orderCause}
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.orderCause}</FormErrorText>
          </Grid>
        </Grid>
        {form.status === '3' && (
          <Box mt={2}>
            <FormTitle title="失注理由" />
            <TextArea name="lostCause" value={form.lostCause} onChange={onChange} />
            <FormErrorText>{errorMessage.lostCause}</FormErrorText>
          </Box>
        )}
        <Box mt={2}>
          <FormTitle title="備考" />
          <TextArea name="biko" value={form.biko} onChange={onChange} />
        </Box>
      </Box>
      <ProjectSubDialog isOpen={subOpen} onClose={onClickSub} onSet={onDefectProject} />
    </Dialog>
  );
}
