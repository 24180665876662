/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: {
    data: {},
    selects: [],
    isDeleteSuccess: false,
    isUpdateSuccess: false,
    isResetSuccess: false,
  },
  reducers: {
    getData: (state) => {
      state.datas = {};
    },
    getDataSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getSelect: (state) => {
      state.selects = [];
    },
    getSelectSuccess: (state, action) => {
      state.selects = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    update: (state) => {
      state.isUpdateSuccess = false;
    },
    updateSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
    reset: (state) => {
      state.isResetSuccess = false;
    },
    resetSuccess: (state) => {
      state.isResetSuccess = true;
    },
  },
});

export const loginUserActions = loginUserSlice.actions;

export default loginUserSlice.reducer;
