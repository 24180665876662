import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box,
  Tabs, Tab,
} from '@mui/material';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import WorkSheet from './workSheet/WorkSheet';
import WorkSheetBp from './workSheetBp/WorkSheetBp';
import LoadingDialog from './LoadingDialog';
import NoDataSnackbar from './NoDataSnackbar';
import SalarySheet from './salarySheet/SalarySheet';
import TrafficSheet from './trafficSheet/TrafficSheet';
import SkillSheet from './skillSheet/SkillSheet';

class Output extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      isLoading: false,
      isNotData: false,
    };
  }

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onLoading = () => {
    this.setState({ isLoading: true });
  };

  onNotLoading = () => {
    this.setState({ isLoading: false });
  };

  onNotData = () => {
    this.setState({ isNotData: true, isLoading: false });
  };

  onCloseNotData = () => {
    this.setState({ isNotData: false });
  };

  render() {
    const { tabValue, isLoading, isNotData } = this.state;

    return (
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
            <Tab label="作業報告" />
            <Tab label="給与台帳" />
            <Tab label="交通費明細" />
            <Tab label="スキルシート" />
            <Tab label="BP用作業報告" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <WorkSheet
            onLoading={this.onLoading}
            onNotLoading={this.onNotLoading}
            onNotData={this.onNotData}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SalarySheet
            onLoading={this.onLoading}
            onNotLoading={this.onNotLoading}
            onNotData={this.onNotData}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <TrafficSheet
            onLoading={this.onLoading}
            onNotLoading={this.onNotLoading}
            onNotData={this.onNotData}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <SkillSheet
            onLoading={this.onLoading}
            onNotLoading={this.onNotLoading}
            onNotData={this.onNotData}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <WorkSheetBp
            onLoading={this.onLoading}
            onNotLoading={this.onNotLoading}
            onNotData={this.onNotData}
          />
        </TabPanel>
        <LoadingDialog open={isLoading} />
        <NoDataSnackbar open={isNotData} onClose={this.onCloseNotData} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(Output);
