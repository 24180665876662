import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Container,
} from '@mui/material';
import { format } from 'date-fns';
import DataTable from './DataTable';
import { workFixRequestActions } from '../../../redux/request/workFixRequest/workFixRequestState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../../components/atoms/monthPicker/MonthPicker';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import { checkIsDisabled } from '../../../utils/authCheck.helper';

class WorkFixRequest extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      searchMonth: format(new Date(), 'yyyy/MM/dd'),
      searchSyainId: '',
      page: 0,
      records: 50,
      order: '',
      orderBy: '',
    };

    if (props.workFixRequestInfo.searchCondition) {
      searchCondition = props.workFixRequestInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      data: {},
      searchCondition,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workFixRequestInfo.data !== this.props.workFixRequestInfo.data) {
      if (this.props.workFixRequestInfo.data) {
        this.setDatas(this.props.workFixRequestInfo.data);
      }
    }
  }

  setDatas(data) {
    this.setState({ data });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(workFixRequestActions.setSearchCondition(searchCondition));
    this.props.dispatch(workFixRequestActions.getData(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      data,
      searchCondition,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box display="flex">
          <Box mr={2}>
            <FormTitle title="対象月" />
            <MonthPicker value={searchCondition.searchMonth} name="searchMonth" handleChange={this.onChange} />
          </Box>
          {!isDisabled && (
            <Box>
              <FormTitle title="名前" />
              <SelectSyain
                name="searchSyainId"
                targetMonth={searchCondition.searchMonth}
                value={searchCondition.searchSyainId}
                onChange={this.onChange}
              />
            </Box>
          )}
        </Box>

        <DataTable
          rows={data.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={data.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  workFixRequestInfo: state.workFixRequestStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(WorkFixRequest);
