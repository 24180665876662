import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Link, Drawer, CssBaseline, AppBar as MuiAppBar, Toolbar, List,
  Typography, Divider, IconButton, Button, Menu, MenuItem, Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu as MenuIcon, ChevronLeft as ChevronLeftIcon,
  Web as WebIcon, EventAvailable as EventAvailableIcon,
} from '@mui/icons-material';
import { topCloseActions } from '../redux/top/close/topCloseState';
import urls from '../constants/frontUrls';
import RouterBreadcrumbs from '../components/organisms/routerBreadcrumbs/RouterBreadcrumbs';
import CollapseList from '../components/organisms/collapseList/CollapseList';
import HeaderMenuItem from '../components/atoms/headerMenuItem/HeaderMenuItem';
import Loading from './common/Loading';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    marginTop: 90,
    padding: 5,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Layout(props) {
  const [open, setOpen] = React.useState(false);
  const [openWork, setOpenWork] = React.useState(false);
  const [openRequest, setOpenRequest] = React.useState(false);
  const [openSales, setOpenSales] = React.useState(false);
  const [openOffice, setOpenOffice] = React.useState(false);
  const [openSetting, setOpenSetting] = React.useState(false);
  const [openFile, setOpenFile] = React.useState(false);
  const [openSummary, setOpenSummary] = React.useState(false);
  const [openMaster, setOpenMaster] = React.useState(false);
  const [openClose, setOpenClose] = React.useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [closeAnchorEl, setCloseAnchorEl] = React.useState(null);
  const [closeFrom, setCloseForm] = React.useState({ um: '', uk: '', cm: '' });
  const [closeLoading, setCloseLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.topCloseStore.data);

  React.useEffect(() => {
    if (data) {
      setCloseForm(data);
      setCloseLoading(false);
    }
  }, [data]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onCloseAnchorClose = () => {
    setCloseAnchorEl(null);
  };

  const onCloseAnchorOpen = (event) => {
    setCloseAnchorEl(event.currentTarget);
    dispatch(topCloseActions.getDatas());
    setCloseLoading(true);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleWork = () => {
    setOpenWork(!openWork);
  };

  const handleRequest = () => {
    setOpenRequest(!openRequest);
  };
  const handleSetting = () => {
    setOpenSetting(!openSetting);
  };
  const handleSummary = () => {
    setOpenSummary(!openSummary);
  };
  const handleFile = () => {
    setOpenFile(!openFile);
  };
  const handleMaster = () => {
    setOpenMaster(!openMaster);
  };
  const handleClose = () => {
    setOpenClose(!openClose);
  };
  const handleSales = () => {
    setOpenSales(!openSales);
  };
  const handleOffice = () => {
    setOpenOffice(!openOffice);
  };
  const onClickLogout = () => {
    localStorage.clear();
    window.location.href = urls.COMMON.LOGIN.url;
  };

  const onPortal = () => {
    const url = 'https://sites.google.com/sodech.com/portal';
    window.open(url, '_blank');
  };

  const onClickPassword = () => {
    navigate(urls.PROFILE.PASSWORD.url);
    setProfileAnchorEl(null);
  };

  const onClickUserSetting = () => {
    navigate(urls.PROFILE.USER_SETTING.url);
    setProfileAnchorEl(null);
  };

  const usetData = useSelector((state) => state.loginStore.userInfo);
  const userInfo = usetData ? usetData.user : {};
  const screens = userInfo.roleScreenList ? userInfo.roleScreenList : [];
  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <Link href="/" underline="none" color="white">SBS</Link>
          </Typography>
          <Tooltip title="ポータル">
            <IconButton color="inherit" onClick={onPortal}>
              <WebIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="締め">
            <IconButton color="inherit" onClick={onCloseAnchorOpen}>
              <EventAvailableIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={closeAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(closeAnchorEl)}
            onClose={onCloseAnchorClose}
          >
            {!closeLoading && (
              <Box p={2}>
                <Box>
                  <Box sx={{ fontWeight: 'bold' }}>売上締め</Box>
                  <Divider />
                  <Box mb={2}>{`${closeFrom.um} ${closeFrom.uk}`}</Box>
                </Box>
                <Box>
                  <Box sx={{ fontWeight: 'bold' }}>経費締め</Box>
                  <Divider />
                  <Box>{`${closeFrom.cm} 本締め済`}</Box>
                </Box>
              </Box>
            )}
          </Menu>
          <div>
            <Button
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Typography variant="h6" noWrap>
                {userInfo && userInfo.name}
              </Typography>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={profileAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={onClickPassword}>
                パスワード変更
              </MenuItem>
              <MenuItem onClick={onClickUserSetting}>
                ユーザ設定
              </MenuItem>
              <Divider />
              <MenuItem onClick={onClickLogout}>
                ログアウト
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <RouterBreadcrumbs />
      </AppBar>

      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />

        <CollapseList
          isShow={screens.filter((row) => row.category === '0').length > 0}
          onClick={handleWork}
          menuTitle="勤怠"
          isOpen={openWork}
        >
          <List>
            {urls.WORKS.map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '1').length > 0}
          onClick={handleRequest}
          menuTitle="申請"
          isOpen={openRequest}
        >
          <List>
            {urls.REQUESTS.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '2').length > 0}
          onClick={handleSales}
          menuTitle="営業"
          isOpen={openSales}
        >
          <List>
            {urls.SALES.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '3').length > 0}
          onClick={handleOffice}
          menuTitle="総務"
          isOpen={openOffice}
        >
          <List>
            {urls.OFFICE.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '4').length > 0}
          onClick={handleSetting}
          menuTitle="設定"
          isOpen={openSetting}
        >
          <List>
            {urls.SETTING.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '5').length > 0}
          onClick={handleMaster}
          menuTitle="マスタ"
          isOpen={openMaster}
        >
          <List>
            {urls.MASTER.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '6').length > 0}
          onClick={handleFile}
          menuTitle="ファイル入出力"
          isOpen={openFile}
        >
          <List>
            {urls.FILE.map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '7').length > 0}
          onClick={handleSummary}
          menuTitle="集計"
          isOpen={openSummary}
        >
          <List>
            {urls.SUMMARY.filter((row) => row.isMenuDisplay).map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

        <CollapseList
          isShow={screens.filter((row) => row.category === '8').length > 0}
          onClick={handleClose}
          menuTitle="締め"
          isOpen={openClose}
        >
          <List>
            {urls.CLOSE.map((item) => (
              <HeaderMenuItem
                isNested
                onClose={handleDrawerClose}
                url={item.url}
                menuName={item.name}
                key={item.url}
              />
            ))}
          </List>
        </CollapseList>

      </Drawer>
      <Loading />
      <Main open={open}>
        {props.children}
      </Main>
    </Box>
  );
}
