import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper,
} from '@mui/material';

export default function HistoryList(props) {
  const { datas } = props;

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 750 }}>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>社員名</TableCell>
              <TableCell>取得日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
              >
                <TableCell>{row.syainName}</TableCell>
                <TableCell>{row.acquiredDate}</TableCell>
              </TableRow>
            ))}
            {
              datas.length < 1 && (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={2}>
                    データがありません
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
