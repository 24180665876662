import { format, addMonths } from 'date-fns';

export default function createData(workbook, datas, targetMonth) {
  const worksheet = workbook.getWorksheet('一覧表');
  worksheet.pageSetup = {
    orientation: 'landscape', paperSize: 9, fitToPage: true, fitToWidth: 99, fitToHeight: 1, printTitlesColumn: 'A:C',
  };

  let row = worksheet.getRow(3);
  row.getCell(6).value = format(new Date(targetMonth), 'yyyy/MM');
  row.getCell(7).value = `${format(addMonths(new Date(targetMonth), 1), 'yyyy/MM')}/10`;

  datas.forEach((data, i) => {
    const index = 5 + i;
    row = worksheet.getRow(5);
    row.getCell(index).value = data.syainMei;
    row = worksheet.getRow(6);
    row.getCell(index).value = data.buName;
    row = worksheet.getRow(7);
    row.getCell(index).value = data.entryDt;
    row = worksheet.getRow(8);
    row.getCell(index).value = data.birthday;
    row = worksheet.getRow(9);
    row.getCell(index).value = data.fuyou;
    row = worksheet.getRow(10);
    row.getCell(index).value = data.workCnt;
    row = worksheet.getRow(11);
    row.getCell(index).value = data.holidWorkCnt;
    row = worksheet.getRow(12);
    row.getCell(index).value = data.noWorkCnt;
    row = worksheet.getRow(13);
    row.getCell(index).value = data.lateTm;
    row = worksheet.getRow(14);
    row.getCell(index).value = data.overTm;
    row = worksheet.getRow(15);
    row.getCell(index).value = data.midOverTm;
    row = worksheet.getRow(16);
    row.getCell(index).value = data.htkyjtTm;
    row = worksheet.getRow(17);
    row.getCell(index).value = data.workTm;
    row = worksheet.getRow(19);
    row.getCell(index).value = data.rank;
    row = worksheet.getRow(20);
    row.getCell(index).value = data.payoffCnt;
    row = worksheet.getRow(21);
    row.getCell(index).value = data.specialDyCnt;
    row = worksheet.getRow(22);
    row.getCell(index).value = data.yknHsh;
    row = worksheet.getRow(23);
    row.getCell(index).value = data.khnKyu;
    row = worksheet.getRow(24);
    row.getCell(index).value = data.mgTt;
    row = worksheet.getRow(25);
    row.getCell(index).value = data.seikaKyu;
    row = worksheet.getRow(26);
    row.getCell(index).value = data.sdTt;
    row = worksheet.getRow(27);
    row.getCell(index).value = data.seikinTt;
    row = worksheet.getRow(28);
    row.getCell(index).value = data.sykmTt;
    row = worksheet.getRow(29);
    row.getCell(index).value = data.pjTt;
    row = worksheet.getRow(30);
    row.getCell(index).value = data.kzkTt;
    row = worksheet.getRow(31);
    row.getCell(index).value = data.tkbtsTt;
    row = worksheet.getRow(32);
    row.getCell(index).value = data.zngyTt;
    row = worksheet.getRow(33);
    row.getCell(index).value = data.kknKj;
    row = worksheet.getRow(35);
    row.getCell(index).value = data.nnmtsTysKnpkn;
    row = worksheet.getRow(37);
    row.getCell(index).value = data.knkHk;
    row = worksheet.getRow(38);
    row.getCell(index).value = data.kgHkn;
    row = worksheet.getRow(39);
    row.getCell(index).value = data.ksnNkn;
    row = worksheet.getRow(40);
    row.getCell(index).value = data.kyHkn;
    row = worksheet.getRow(41);
    row.getCell(index).value = data.sytkZei;
    row = worksheet.getRow(42);
    row.getCell(index).value = data.jymnZei;
    row = worksheet.getRow(43);
    row.getCell(index).value = data.syainKaihi;
    row = worksheet.getRow(44);
    row.getCell(index).value = data.kstskHns;
    row = worksheet.getRow(45);
    row.getCell(index).value = data.ryh;
    row = worksheet.getRow(46);
    row.getCell(index).value = data.ponGk;
    row = worksheet.getRow(55);
    row.getCell(index).value = data.ksyKnkHk;
    row = worksheet.getRow(56);
    row.getCell(index).value = data.ksyKgHkn;
    row = worksheet.getRow(57);
    row.getCell(index).value = data.ksyKsnNkn;
    row = worksheet.getRow(58);
    row.getCell(index).value = data.jdTtTystKn;
    row = worksheet.getRow(59);
    row.getCell(index).value = data.ksyKyHkn;
    row = worksheet.getRow(60);
    row.getCell(index).value = data.rsHkn;
    row = worksheet.getRow(62);
    row.getCell(index).value = data.keihiGk;
    row = worksheet.getRow(63);
    row.getCell(index).value = data.trafficGk;
    row = worksheet.getRow(64);
    row.getCell(index).value = data.kenkoShindanGk;
    row = worksheet.getRow(65);
    row.getCell(index).value = data.tasyaGk;
  });
}
