import React from 'react';
import { GoogleMap as Map, LoadScript, Marker } from '@react-google-maps/api';

export default function GoogleMap(props) {
  const location = { lat: props.lat, lng: props.lng };
  const zoom = 17;

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <Map
        mapContainerStyle={{
          width: '100%',
          height: props.height ? props.height : '500px',
        }}
        center={location}
        zoom={zoom}
      >
        <Marker position={location} />
      </Map>
    </LoadScript>
  );
}
