import React from 'react';
import { connect } from 'react-redux';
import { TextField, Box, Container } from '@mui/material';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { reportActions } from '../../../redux/summary/report/reportState';

class RoleList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      searchKeyword: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.reportInfo.searchCondition) {
      searchCondition = props.reportInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: [],
      searchCondition,
      isSuccessOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportInfo.datas !== this.props.reportInfo.datas) {
      if (this.props.reportInfo.datas) {
        this.setDatas(this.props.reportInfo.datas);
      }
    }
    if (prevProps.reportInfo.isDeleteSuccess !== this.props.reportInfo.isDeleteSuccess) {
      if (this.props.reportInfo.isDeleteSuccess) {
        this.setDeleteSuccess();
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setDeleteSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(reportActions.setSearchCondition(searchCondition));
    this.props.dispatch(reportActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (value) => {
    this.props.dispatch(reportActions.deleteData({ ids: value }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas, searchCondition,
      isSuccessOpen,
    } = this.state;
    return (
      <Container maxWidth="xl">
        <Box mb={1} width="50%">
          <TextField name="searchKeyword" placeholder="キーワード" size="small" variant="outlined" autoComplete="off" fullWidth value={searchCondition.searchKeyword} onChange={this.onChange} />
        </Box>

        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onClickDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
        <SuccessSnackbar open={isSuccessOpen} handleClose={this.handleSuccessSnackbarClose} message="レポートの削除が完了しました" />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  reportInfo: state.reportStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(RoleList);
