function toNumber(kingaku) {
  if (!kingaku) {
    return 0;
  }
  return parseFloat(kingaku.toString().replace(/,/g, ''));
}

export default function calc(data) {
  const yknHsh = toNumber(data.yknHsh);
  const nnpKhnKyu = toNumber(data.nnpKhnKyu);
  const abilityKyu = toNumber(data.abilityKyu);
  const mgTt = toNumber(data.mgTt);
  const seikinTt = toNumber(data.seikinTt);
  const sykmTt = toNumber(data.sykmTt);
  const seikaKyu = toNumber(data.seikaKyu);
  const tkbtsTt = toNumber(data.tkbtsTt);
  const zngyTt = toNumber(data.zngyTt);
  const kknKj = toNumber(data.kknKj);
  const hsrKj = toNumber(data.hsrKj);

  const kazeiSum = yknHsh
  + nnpKhnKyu
  + abilityKyu
  + mgTt
  + seikaKyu
  + seikinTt
  + sykmTt
  + tkbtsTt
  + zngyTt
  - kknKj
  - hsrKj;

  const nnmtsTysKnpkn = toNumber(data.nnmtsTysKnpkn);
  const shkyGkSum = nnmtsTysKnpkn + kazeiSum;

  const knkHk = toNumber(data.knkHk);
  const kgHkn = toNumber(data.kgHkn);
  const ksnNkn = toNumber(data.ksnNkn);
  const kyHkn = toNumber(data.kyHkn);
  const sytkZei = toNumber(data.sytkZei);
  const jymnZei = toNumber(data.jymnZei);
  const syainKaihi = toNumber(data.syainKaihi);
  const kstskHns = toNumber(data.kstskHns);
  const ryh = toNumber(data.ryh);
  const ponGk = toNumber(data.ponGk);

  const kjyGkSum = knkHk
  + kgHkn
  + ksnNkn
  + kyHkn
  + sytkZei
  + jymnZei
  + syainKaihi
  + kstskHns
  + ryh
  + ponGk;

  const skRy = toNumber(data.skRy);
  const ksyKnkHk = toNumber(data.ksyKnkHk);
  const ksyKgHkn = toNumber(data.ksyKgHkn);
  const ksyKsnNkn = toNumber(data.ksyKsnNkn);
  const jdTtTystKn = toNumber(data.jdTtTystKn);
  const ksyKyHkn = toNumber(data.ksyKyHkn);
  const rsHkn = toNumber(data.rsHkn);
  const knstHi = toNumber(data.knstHi);
  const apkhGk = toNumber(data.apkhGk);
  const kth = toNumber(data.kth);
  const knkSdDai = toNumber(data.knkSdDai);
  const tsyHttyBun = toNumber(data.tsyHttyBun);

  const khGkSum = skRy
  + ksyKnkHk
  + ksyKgHkn
  + ksyKsnNkn
  + ksyKyHkn
  + jdTtTystKn
  + rsHkn
  + kth
  + knstHi
  + knkSdDai
  + tsyHttyBun
  + apkhGk;

  const tempData = {
    ...data,
    kzShkyGk: kazeiSum,
    shkyGk: shkyGkSum,
    diffGk: shkyGkSum - kjyGkSum,
    kjyGk: kjyGkSum,
    khGk: khGkSum,
  };
  return tempData;
}
