/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
import {
  startOfMonth, endOfMonth, format, isBefore,
  getDate, eachDayOfInterval, isValid, isEqual,
  endOfWeek, eachWeekOfInterval, addDays,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

function isSomeMonth(d1, d2) {
  return (d1.getFullYear() + d1.getMonth()) - (d2.getFullYear() + d2.getMonth()) === 0;
}

export function isNotEdit(item) {
  const today = new Date(format(new Date(), 'yyyy/MM/dd'));
  const targetDate = new Date(item.formatDate);
  return isEqual(today, targetDate) || isBefore(targetDate, today);
}

export function getCalnedarMonthly(paramDate) {
  const targetDate = new Date(paramDate);

  const weeks = eachWeekOfInterval(
    {
      start: startOfMonth(targetDate),
      end: endOfMonth(targetDate),
    },
    { weekStartsOn: 0 },
  );
  const monthly = weeks.map((week) => eachDayOfInterval(
    {
      start: week,
      end: endOfWeek(week, { weekStartsOn: 0 }),
    },
  ));
  const monthArray = [];
  monthly.forEach((item) => {
    const weekArray = [];
    item.forEach((fnsDate) => {
      weekArray.push(
        {
          date: fnsDate, day: getDate(fnsDate), formatDate: format(fnsDate, 'yyyy/MM/dd', { locale: ja }), week: format(fnsDate, 'EEEEEE', { locale: ja }), isSomeMonth: isSomeMonth(fnsDate, targetDate),
        },
      );
    });
    monthArray.push(weekArray);
  });
  return monthArray;
}

export function getMonthly(paramDate) {
  const firstDate = new Date(`${format(new Date(paramDate), 'yyyy/MM')}/01`);
  const endDay = parseInt(getDate(endOfMonth(firstDate)), 10) - 1;
  const monthArray = [];
  for (let i = 0; i <= endDay; i++) {
    const targetDate = addDays(firstDate, i);
    const day = {
      date: targetDate, formatDate: format(targetDate, 'yyyy/MM/dd'), day: getDate(targetDate), week: format(targetDate, 'EEEEEE', { locale: ja }),
    };
    monthArray.push(day);
  }
  return monthArray;
}

export function getData(datas, targetDate) {
  const dayDatas = datas.filter((row) => row.targetDate === targetDate);
  let targetdate = {};
  if (dayDatas.length > 0) {
    targetdate = dayDatas[0];
  }
  return targetdate;
}

export function isRested(rests, date) {
  const datas = rests.filter((row) => row === date);
  return datas.length > 0;
}

export function saveTargetDate(param) {
  const {
    month, targetDate, type, fnsWeek, rests,
  } = param;
  const monthly = getMonthly(month);
  const targets = [targetDate];
  if (type === 0) {
    return targets;
  }
  const fnsDate = new Date(targetDate);
  monthly.forEach((item) => {
    if (isBefore(item.date, fnsDate) || isEqual(item.date, fnsDate)) {
      return;
    }
    if (type === 2 && fnsWeek === item.week) {
      targets.push(item.formatDate);
    }
    if (type === 1 && !isRested(rests, item.formatDate)) {
      targets.push(item.formatDate);
    }
  });
  return targets;
}

function isEqualAfter(one, two) {
  return isEqual(one, two) || isBefore(one, two);
}

function getRest(start, end, startRest, endRest) {
  if (!startRest || !endRest) {
    return 0;
  }
  const startTime = new Date(`1999/12/31 ${startRest}`);
  const endTime = new Date(`1999/12/31 ${endRest}`);
  if (!isValid(startTime) || !isValid(endTime)) {
    return 0;
  }
  if (isEqualAfter(start, startTime) && isEqualAfter(endTime, end)) {
    return Math.abs(endTime.getTime() - startTime.getTime()) / (60 * 1000);
  }
  return 0;
}

export function getRestWorkTime(startTime, endTime, restData) {
  if (!startTime || !endTime) {
    return { restTime: 0, workTime: 0 };
  }
  const start = new Date(`1999/12/31 ${startTime}`);
  const end = new Date(`1999/12/31 ${endTime}`);
  if (!isValid(start) || !isValid(end)) {
    return { restTime: 0, workTime: 0 };
  }
  let restTime = 0;
  let workTime = 0;
  workTime = Math.abs(end.getTime() - start.getTime()) / (60 * 1000);

  restTime += getRest(start, end, restData.flexOneRestStartTime, restData.flexOneRestEndTime);
  restTime += getRest(start, end, restData.flexTwoRestStartTime, restData.flexTwoRestEndTime);
  restTime += getRest(start, end, restData.flexThreeRestStartTime, restData.flexThreeRestEndTime);
  restTime += getRest(start, end, restData.flexFourRestStartTime, restData.flexFourRestEndTime);

  return {
    restTime, workTime: workTime - restTime,
  };
}
