import React from 'react';
import jspreadsheet from 'jspreadsheet-ce';
import 'jspreadsheet-ce/dist/jspreadsheet.css';
import 'jsuites/dist/jsuites.css';
import {
  Box, Button, Tooltip, IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import JALang from './ja.json';

export default function DataGridSheet(props) {
  const [updateData, setUpdateData] = React.useState([]);

  const {
    data,
    dataGridRef,
    setDataGridRef,
    columns,
    onSave,
    isDisabled,
    isNonTooltip,
  } = props;

  const onCheckSave = () => {
    onSave(updateData);
    setUpdateData([]);
  };

  const onAfterChangeData = (instance) => {
    const tempData = instance.jexcel.getJson();
    setUpdateData(tempData);
  };

  const sheetOptions = {
    includeHeadersOnDownload: true,
    loadingSpin: true,
    minDimensions: [columns.length, 1],
    filters: true,
    columns,
    wordWrap: true,
    allowManualInsertRow: false,
    allowDeleteColumn: false,
    allowInsertColumn: false,
    allowManualInsertColumn: false,
    allowInsertRow: true,
    allowDeleteRow: true,
    autoCasting: true,
    rowResize: true,
    columnSorting: true,
    onafterchanges: (instance, records) => {
      onAfterChangeData(instance, records);
    },
    ondeleterow: (instance, records) => {
      onAfterChangeData(instance, records);
    },
  };

  React.useEffect(() => {
    if (!dataGridRef.current.jspreadsheet) {
      const jspreadsheetObj = jspreadsheet(dataGridRef.current, sheetOptions);
      const mutableData = JSON.parse(JSON.stringify(data)); // Create a deep copy
      jspreadsheetObj.setData(mutableData);
      jspreadsheetObj.options.text = JALang;
    } else if (data && data.length > 0) {
      const mutableData = JSON.parse(JSON.stringify(data)); // Ensure data is mutable
      dataGridRef.current.jspreadsheet.setData(mutableData);
    }
    setUpdateData([]);
    setDataGridRef(dataGridRef);
  }, [data]);

  return (
    <>
      {!isDisabled && (
        <Box mb={1} display="flex">
          <Box mr={2} sx={{ flex: 1 }}>
            <Button variant="contained" onClick={onCheckSave} size="small" disabled={updateData.length <= 0}>保存</Button>
          </Box>
          <Box display={isNonTooltip ? 'none' : ''}>
            <Tooltip title="行追加、行削除は右クリックから可能です">
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
      <Box ref={dataGridRef} />
    </>
  );
}
