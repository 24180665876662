import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Container,
} from '@mui/material';
import { format } from 'date-fns';
import SkillTabel from './SkillTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { skillActions } from '../../../redux/sales/skill/skillState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SelectSyain from '../../../components/organisms/selectSyain/SelectSyain';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import LoadingDialog from '../../file/output/LoadingDialog';
import SkillSheetDialog from './SkillSheetDialog';
import NoDataSnackbar from './NoDataSnackbar';

class SkillList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'project_name', label: 'プロジェクト名' },
      { id: 'syain_name', label: '名前' },
      { id: 'timing', label: '参画時期' },
    ];

    let searchCondition = {
      searchProject: '',
      searchRefDate: format(new Date(), 'yyyy/MM/dd'),
      searchSyain: props.loginUser.userInfo.user.id,
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.skillInfo.searchCondition) {
      searchCondition = props.skillInfo.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isModalOpen: false,
      isSuccessOpen: false,
      isNoDataOpen: false,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      onLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.skillInfo.datas !== this.props.skillInfo.datas) {
      if (this.props.skillInfo.datas) {
        this.setDatas(this.props.skillInfo.datas);
      }
    }
    if (prevProps.skillInfo.isDeleteSuccess
      !== this.props.skillInfo.isDeleteSuccess) {
      if (this.props.skillInfo.isDeleteSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess() {
    this.onSearch();
    this.setState({ isSuccessOpen: true });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseSkillSheetDialog = () => {
    this.setState({ isModalOpen: false });
  };

  onLoading = () => {
    this.setState({ onLoading: true, isModalOpen: false });
  };

  onNotLoading = () => {
    this.setState({ onLoading: false });
  };

  notData = () => {
    this.setState({ onLoading: false, isNoDataOpen: true, isModalOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(skillActions.setSearchCondition(searchCondition));
    this.props.dispatch(skillActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleNoDataSnackbarClose = () => {
    this.setState({ isNoDataOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(skillActions.deleteData({ ids }));
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      isDisabled,
      isModalOpen,
      onLoading,
      isNoDataOpen,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="対象日" />
              <DatePicker value={searchCondition.searchRefDate} name="searchRefDate" handleChange={this.onChange} />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="PJ名" />
              <TextField value={searchCondition.searchProject} fullWidth name="searchProject" onChange={this.onChange} />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormTitle title="名前" />
              <SelectSyain
                name="searchSyain"
                targetMonth={searchCondition.searchRefDate}
                value={searchCondition.searchSyain}
                onChange={this.onChange}
              />
            </Grid>
          </Grid>
        </SearchBox>
        <SkillTabel
          url={urls.SKILL_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="スキル管理"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
          skillButtonDisabled={!searchCondition.searchSyain}
          onCreateSkillSheet={this.openModal}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message="削除が完了しました"
        />
        <NoDataSnackbar
          open={isNoDataOpen}
          onClose={this.handleNoDataSnackbarClose}
        />
        <SkillSheetDialog
          open={isModalOpen}
          handleClose={this.handleCloseSkillSheetDialog}
          onLoading={this.onLoading}
          onNotLoading={this.onNotLoading}
          syainId={searchCondition.searchSyain}
          onNotData={this.notData}
        />
        <LoadingDialog open={onLoading} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  skillInfo: state.skillStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(SkillList);
