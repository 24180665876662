import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  DialogContent, DialogActions, Button, DialogTitle,
} from '@mui/material';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { workResultInputActions } from '../../../redux/work/workResultInput/workResultInputState';
import TextArea from '../../../components/atoms/textArea/TextArea';

export default function FixRequestDialog(props) {
  const [comment, setComment] = React.useState([]);
  const dispatch = useDispatch();
  const { isOpen, onClickClose } = props;

  const onChangeComment = (event) => {
    setComment(event.target.value);
  };

  const onClickSave = () => {
    const {
      targetDate, syainId, keitai, jyotai,
    } = props;
    dispatch(workResultInputActions.fixWorkTime({
      targetDate, syainId, comment, keitai, jyotai,
    }));
    onClickClose();
  };

  React.useEffect(() => {
    setComment('');
  }, [isOpen]);

  return (
    <Dialog
      onClickClose={onClickClose}
      isOpen={isOpen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>修正申告</DialogTitle>
      <DialogContent>
        <TextArea name="biko" value={comment} onChange={onChangeComment} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose}>閉じる</Button>
        <Button onClick={onClickSave} disabled={!comment}>申告</Button>
      </DialogActions>
    </Dialog>
  );
}
