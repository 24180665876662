import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button,
} from '@mui/material';
import TextArea from '../../../components/atoms/textArea/TextArea';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';
import AuthorizerList from '../../../components/organisms/authorizerList/AuthorizerList';
import withParams from '../../../components/atoms/withParam/WithParams';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import { workRequestDetailActions } from '../../../redux/request/workRequest/workRequestDetailState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

class WorkRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      codes: [],
      isDisabled: false,
      successMessage: '',
      isSuccessOpen: false,
      isApproval: props.loginUser.userInfo.user.approval,
      form: {
        id,
        syainId: props.loginUser.userInfo.user.id,
        syainName: props.loginUser.userInfo.user.name,
        attendanceKind: null,
        targetFrom: '',
        targetTo: '',
        approverResonsibleId: null,
        reason: '',
        applyDt: null,
        applyStatusName: null,
        approveDt: null,
        approveName: null,
        transferDate: null,
      },
      errorMessages: {
        attendanceKind: '',
        targetFrom: '',
        targetTo: '',
        approverResonsibleId: '',
        reason: '',
        transferDate: '',
      },
    };
    props.dispatch(workRequestDetailActions.getCode());
    if (id) {
      props.dispatch(workRequestDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workRequestDetailInfo.data !== this.props.workRequestDetailInfo.data) {
      if (this.props.workRequestDetailInfo.data) {
        this.setDatas(this.props.workRequestDetailInfo.data);
      }
    }
    if (prevProps.workRequestDetailInfo.codes !== this.props.workRequestDetailInfo.codes) {
      if (this.props.workRequestDetailInfo.codes) {
        this.setCodes(this.props.workRequestDetailInfo.codes);
      }
    }
    if (prevProps.workRequestDetailInfo.isSaveSuccess
      !== this.props.workRequestDetailInfo.isSaveSuccess) {
      if (this.props.workRequestDetailInfo.isSaveSuccess) {
        this.setSaveSuccess(this.props.workRequestDetailInfo.saveId);
      }
    }
    if (prevProps.workRequestDetailInfo.isStatusUpdateSuccess
      !== this.props.workRequestDetailInfo.isStatusUpdateSuccess) {
      if (this.props.workRequestDetailInfo.isStatusUpdateSuccess) {
        this.setSaveStatusSuccess();
      }
    }
  }

  setSaveStatusSuccess = () => {
    const { form, updateStatus } = this.state;
    this.props.dispatch(workRequestDetailActions.getData({ id: form.id }));
    const proc = updateStatus === '1' ? '承認' : '取下';
    this.setState({ isSuccessOpen: true, successMessage: `${proc}処理が完了しました` });
  };

  setSaveSuccess = (id) => {
    this.props.dispatch(workRequestDetailActions.getData({ id }));
    this.setState({ isSuccessOpen: true, successMessage: '申請が完了しました' });
  };

  setCodes = (codes) => {
    this.setState({ codes: codes.filter((row) => row.id !== '0' && row.id !== '3' && row.id !== '9') });
  };

  setDatas = (form) => {
    this.setState({ form, isDisabled: form.applyStatusCd !== '3' });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { form, errorMessages } = this.state;
    const tempForm = {
      ...form,
      [name]: value,
    };
    this.setState({
      form: tempForm,
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value, form.attendanceKind),
      },
    });
  };

  onSave = () => {
    const { form, errorMessages } = this.state;

    let tempMessage = errorMessages;
    let isError = false;

    if (!form.targetTo || form.attendanceKind === '4') {
      form.targetTo = form.targetFrom;
    }
    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.attendanceKind);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!tempMessage.targetFrom && !tempMessage.targetTo) {
      const msg = Validation.compareDateValidate(form.targetFrom, form.targetTo);
      if (msg) {
        isError = true;
      }
      tempMessage.targetTo = msg;
    }

    if (!tempMessage.approverResonsibleId) {
      let msg = '';
      if (form.approverResonsibleId === form.syainId) {
        msg = '自身を承認責任者にはできません';
        isError = true;
      }
      tempMessage.approverResonsibleId = msg;
    }

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });

    if (isError) {
      return;
    }

    this.props.dispatch(workRequestDetailActions.saveData(form));
  };

  onSaveStatus = (_, status) => {
    const { form } = this.state;
    this.setState({ updateStatus: status, isSuccessOpen: false });
    this.props.dispatch(workRequestDetailActions.saveStatus({ id: form.id, status }));
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  render() {
    const {
      form, errorMessages, codes, isSuccessOpen, successMessage, isDisabled, isApproval,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Box p={1} textAlign="right">
          {(!form.id || form.applyStatusCd === '3') && (
            <Button variant="contained" size="small" onClick={this.onSave}>申請</Button>
          )}
          <Box sx={{ flex: '0 0 auto' }}>
            {(form.applyStatusCd === '0' && isApproval) && (
              <Button variant="contained" size="small" onClick={(e) => this.onSaveStatus(e, '1')} style={{ marginRight: '5px' }}>承認</Button>
            )}
            {(form.applyStatusCd === '0' || form.applyStatusCd === '1') && (
              <Button variant="contained" color="secondary" size="small" onClick={(e) => this.onSaveStatus(e, '3')}>取下</Button>
            )}
          </Box>
        </Box>
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container>
            <Grid item xs={12}>
              <FormTitle title="名前" />
              <Box>{form.syainName}</Box>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12} md={6}>
              <FormTitle title="種別" isRequired />
              <SearchSelectBox disableClearable options={codes} onChange={this.onChange} isDisabled={isDisabled} error={errorMessages.attendanceKind !== ''} name="attendanceKind" value={form.attendanceKind} />
              <FormErrorText>{errorMessages.attendanceKind}</FormErrorText>
            </Grid>
          </Grid>
          <Box mt={2}>
            <FormTitle title="申請日" isRequired />
            <Box display="flex" alignItems="center">
              <Box>
                <DatePicker handleChange={this.onChange} name="targetFrom" value={form.targetFrom} isDisabled={isDisabled} error={errorMessages.targetFrom !== ''} />
                <FormErrorText>{errorMessages.targetFrom}</FormErrorText>
              </Box>
              {form.attendanceKind !== '4' && (
                <>
                  <Box px={2}>～</Box>
                  <Box>
                    <DatePicker handleChange={this.onChange} name="targetTo" value={form.targetTo} isDisabled={isDisabled} error={errorMessages.targetTo !== ''} />
                    <FormErrorText>{errorMessages.targetTo}</FormErrorText>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {form.attendanceKind === '4' && (
            <Grid container mt={2}>
              <Grid item xs={12} md={6}>
                <FormTitle title="振替予定出勤日" isRequired />
                <DatePicker handleChange={this.onChange} name="transferDate" value={form.transferDate} isDisabled={isDisabled} error={errorMessages.transferDate !== ''} />
                <FormErrorText>{errorMessages.transferDate}</FormErrorText>
              </Grid>
            </Grid>
          )}
          <Grid container mt={2}>
            <Grid item xs={12} md={6}>
              <AuthorizerList disableClearable isRequired label="承認者" onChange={this.onChange} isDisabled={isDisabled} name="approverResonsibleId" error={errorMessages.approverResonsibleId !== ''} value={form.approverResonsibleId} />
              <FormErrorText>{errorMessages.approverResonsibleId}</FormErrorText>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12} md={6}>
              <FormTitle title="理由" isRequired />
              <TextArea
                value={form.reason}
                name="reason"
                onChange={this.onChange}
                isDisabled={isDisabled}
              />
              <FormErrorText>{errorMessages.reason}</FormErrorText>
            </Grid>
          </Grid>
          {form.id && (
            <Grid container mt={2}>
              <Grid item xs={6} md={2}>
                <FormTitle title="申請日" />
                <Box>{form.applyDt}</Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormTitle title="状況" />
                <Box>{form.applyStatusName}</Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormTitle title="承認日" />
                <Box>{form.approveDt}</Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <FormTitle title="承認者" />
                <Box>{form.approveName}</Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormTitle title="証明書" />
                <Box>{form.certificate ? '有' : '無'}</Box>
              </Grid>
            </Grid>
          )}
        </Paper>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  workRequestDetailInfo: state.workRequestDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(WorkRequestDetail));
