import { call, put, takeLatest } from 'redux-saga/effects';
import { loginActions } from './loginState';
import { login, getLoginInfo } from '../../services/login/login.service';

function* fetchLogin({ payload }) {
  try {
    const result = yield call(login, payload);
    yield put(loginActions.executeLoginSuccess(result.token));
  } catch (error) {
    yield put(loginActions.executeLoginFailure());
  }
}

function* fetchLoginInfo() {
  try {
    const result = yield call(getLoginInfo);
    yield put(loginActions.executeGetLoginInfoSuccess(result));
  } catch (error) {
    yield put(loginActions.executeGetLoginInfoFailure());
  }
}

function* loginSaga() {
  yield takeLatest('login/executeLogin', fetchLogin);
  yield takeLatest('login/executeGetLoginInfo', fetchLoginInfo);
}

export default loginSaga;
