import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import MaterialDialog from '../../atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../atoms/dialogColorTitle/DialogColorTitle';

export default function ErrorDialog(props) {
  const {
    open,
    title,
    onClose,
    content,
  } = props;

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle isDelete>{title}</DialogColorTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>閉じる</Button>
      </DialogActions>
    </MaterialDialog>
  );
}

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
