import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  Container, Grid, Box, Paper, Button,
  Tabs, Tab, MenuItem, Menu,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import { claimActions } from '../../../redux/sales/claim/claimState';
import { estimateActions } from '../../../redux/sales/estimate/estimateState';
import { inspectActions } from '../../../redux/sales/inspect/inspectState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import ClaimDialog from './ClaimDialog';
import Link from '../../../components/atoms/link/Link';
import urls from '../../../constants/frontUrls';
import ClaimDetailInput from './ClaimDetailInput';
import ApprovalHistory from '../approvalHistory/ApprovalHistory';
import ConfirmDialog from '../../../components/templates/confirmDialog/ConfirmDialog';
import CreateClaimPdf from './file/CreateClaimPdf';

class ClaimDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.claimInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(claimActions.resetFlg());
    if (props.estimateInfo.isCopyToClaimSuccess) {
      isSuccessOpen = true;
      successMessage = 'コピーが完了しました';
    }
    props.dispatch(estimateActions.resetFlg());
    if (props.inspectInfo.isCopyToClaimSuccess) {
      isSuccessOpen = true;
      successMessage = 'コピーが完了しました';
    }
    props.dispatch(inspectActions.resetFlg());

    this.state = {
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
      isAffairs: props.loginUser.userInfo.user.affairs,
      successMessage,
      isSuccessOpen,
      tabValue: 0,
      form: { claimId: id, notEdit: false },
      isDetailOpen: false,
      isConfOpen: false,
      isPdfOpen: false,
      fileType: '1',
      fileAnchorEl: null,
    };
    if (id) {
      props.dispatch(claimActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claimInfo.data !== this.props.claimInfo.data) {
      if (this.props.claimInfo.data) {
        this.setDatas(this.props.claimInfo.data);
      }
    }
    if (prevProps.claimInfo.isStatusSuccess !== this.props.claimInfo.isStatusSuccess) {
      if (this.props.claimInfo.isStatusSuccess) {
        this.setSuccess();
      }
    }
  }

  setSuccess = () => {
    const { form, approvalStatus } = this.state;
    this.props.dispatch(claimActions.getData({ id: form.id }));
    let msg = '承認';
    if (approvalStatus === '1') {
      msg = '承認依頼';
    }
    if (approvalStatus === '0') {
      msg = '承認取下';
    }
    this.setState({
      isSuccessOpen: true,
      successMessage: `${msg}が完了しました`,
    });
  };

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(claimActions.getData({ id: form.id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false, isConfOpen: false, isPdfOpen: false });
  };

  onUpdate = (_, approvalStatus) => {
    const { form } = this.state;
    this.props.dispatch(claimActions.updateStatus({ id: form.id, approvalStatus }));
    this.setState({ isSuccessOpen: false, approvalStatus, isConfOpen: false });
  };

  onConfUpdate = () => {
    this.setState({ isConfOpen: true });
  };

  onClickFileOpen = (event) => {
    this.setState({ fileAnchorEl: event.currentTarget });
  };

  onPdfOpen = (_, fileType) => {
    this.setState({ fileType, isPdfOpen: true, fileAnchorEl: null });
  };

  render() {
    const {
      form, tabValue, isSuccessOpen, successMessage, isDisabled,
      isDetailOpen, isConfOpen, isPdfOpen, isAffairs, fileType, fileAnchorEl,
    } = this.state;

    return (
      <Container maxWidth="lg">
        {!isDisabled && (
          <Box p={1} textAlign="right">
            {(form.approvalStatus === '0' && !form.notEdit) && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '1')} style={{ marginRight: '10px' }}>承認依頼</Button>
            )}
            {(form.approvalStatus === '1' && !form.notEdit && isAffairs) && (
              <Button variant="contained" color="success" size="small" onClick={(e) => this.onUpdate(e, '4', form.managerId)} style={{ marginRight: '10px' }}>総務承認</Button>
            )}
            {(form.approvalStatus !== '0' && form.status !== '3' && !form.notEdit) && (
              <Button variant="contained" color="secondary" size="small" onClick={this.onConfUpdate} style={{ marginRight: '10px' }}>取下</Button>
            )}
            {(form.status !== '3' && !form.notEdit) && (
              <Button variant="contained" size="small" onClick={this.onDetail} style={{ marginRight: '10px' }}>編集</Button>
            )}
            <Button variant="contained" size="small" onClick={this.onClickFileOpen} startIcon={<InsertDriveFileIcon />}>請求書作成</Button>
            <Menu
              id="basic-menu"
              anchorEl={fileAnchorEl}
              open={fileAnchorEl !== null}
              onClose={this.onClickFileEstimateClose}
            >
              <MenuItem onClick={(e) => this.onPdfOpen(e, '1')}>通常請求書</MenuItem>
              <MenuItem onClick={(e) => this.onPdfOpen(e, '2')}>準委任請求書</MenuItem>
            </Menu>
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2}>
              <FormTitle title="請求番号" />
              <Box style={{ wordWrap: 'break-word' }}>{form.seikyuNo}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="計上月" />
              <Box>{form.seikyuDt ? format(new Date(form.seikyuDt), 'yyyy/MM') : ''}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="金額" />
              <Box>{form.seikyuKingaku}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="承認" />
              <Box>{form.approvalStatusName}</Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormTitle title="状況" />
              <Box>{form.statusName}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="明細" />
            <Tab label="承認履歴" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormTitle title="請求番号" />
                <Box style={{ wordWrap: 'break-word' }}>{form.seikyuNo}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="計上月" />
                <Box>{form.seikyuDt ? format(new Date(form.seikyuDt), 'yyyy/MM') : ''}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="入金予定" />
                <Box>{form.nyukinDt}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="承認" />
                <Box>{form.approvalStatusName}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="状況" />
                <Box>{form.statusName}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="プロジェクト" />
                <Link to={urls.PROJECT_DETAIL + form.projectId}>{form.projectName}</Link>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="注文" />
                <Link to={urls.ESTIMATE_DETAIL + form.estimateId}>{form.chumonNo}</Link>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTitle title="請求日" />
                <Box>{form.claimDate}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="件名" />
                {form.title && form.title.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="送付先担当" />
                <Box>{form.contactName}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="準委任作業開始日" />
                <Box>{form.workStartDate}</Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTitle title="準委任作業終了日" />
                <Box>{form.workEndDate}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="作業報告書用特記事項" />
                <Box>{form.workMemo}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="請求書用備考" />
                {form.externalBiko && form.externalBiko.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <FormTitle title="請求額" />
                <Box>{form.seikyuKingaku}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="消費税" />
                <Box>{form.seikyuKingakuTax}</Box>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormTitle title="合計請求額" />
                <Box>{form.seikyuKingakuGokei}</Box>
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTitle title="入金確認者" />
                <Box>{form.checkedName}</Box>
              </Grid>
              <Grid item xs={6}>
                <FormTitle title="入金確認日" />
                <Box>{form.checkedOn}</Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdBy}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedBy}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1} isAll>
          <ClaimDetailInput
            id={form.id}
            onSaveSuccess={this.onSaveSuccess}
            isDisabled={isDisabled || form.approvalStatus === '4' || form.notEdit}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2} isAll>
          <ApprovalHistory claimId={form.id} />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <ClaimDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          claimId={form.id}
          onSaveSuccess={this.onSaveSuccess}
        />
        <ConfirmDialog
          isError
          open={isConfOpen}
          onClose={this.onClose}
          title="取下"
          content="取下処理を行います。"
          onClick={(e) => this.onUpdate(e, '0', null)}
          procName="取下"
        />
        <CreateClaimPdf
          fileType={fileType}
          isOpen={isPdfOpen}
          onClose={this.onClose}
          claimId={form.id}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  claimInfo: state.claimStore,
  loginUser: state.loginStore,
  estimateInfo: state.estimateStore,
  inspectInfo: state.inspectStore,
});

export default withParams(connect(mapStateToProps)(ClaimDetail));
