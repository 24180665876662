import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { inspectActions } from '../../../redux/sales/inspect/inspectState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import DeleteDataTable from '../../../components/templates/deleteDataTable/DeleteDataTable';
import { checkIsDisabledFromUrl } from '../../../utils/authCheck.helper';
import urls from '../../../constants/frontUrls';
import SearchMultipleSelectBox from '../../../components/atoms/searchMultipleSelectBox/SearchMultipleSelectBox';
import InspectDialog from '../inspect/InspectDialog';
import { commonActions } from '../../../redux/common/commonState';

class InspectList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'inspect_no', label: '検収番号' },
      { id: 'inspect_dt', label: '計上月' },
      { id: 'kingaku', label: '金額' },
      { id: 'kingaku_tax', label: '消費税' },
      { id: 'kingaku_gokei', label: '合計額' },
      { id: 'approval_status', label: '承認' },
      { id: 'status', label: '状況' },
    ];

    const searchCondition = {
      parentId: props.parentId,
      searchAppStatus: [],
      page: 0,
      records: 50,
      inspect: 'asc',
      inspectBy: '',
    };

    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      codes: [],
      isDetailOpen: false,
      isDisabled: checkIsDisabledFromUrl(props.loginUser.userInfo.user, '/inspects'),
    };
    this.props.dispatch(commonActions.getStatuses({ code: 'PJ_APP_STATE' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inspectInfo.datas !== this.props.inspectInfo.datas) {
      if (this.props.inspectInfo.datas) {
        this.setDatas(this.props.inspectInfo.datas);
      }
    }
    if (prevProps.common.statuses !== this.props.common.statuses) {
      if (this.props.common.statuses) {
        this.setCodes(this.props.common.statuses);
      }
    }
    if (prevProps.inspectInfo.isDeleteSuccess
      !== this.props.inspectInfo.isDeleteSuccess) {
      if (this.props.inspectInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setCodes = (codes) => {
    this.setState({ codes });
  };

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(inspectActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { inspect, inspectBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      inspectBy,
      inspect,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(inspectActions.deleteData({ ids }));
  };

  onAdd = () => {
    this.setState({ isDetailOpen: true });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDisabled,
      codes,
      isDetailOpen,
    } = this.state;

    return (
      <Box>
        <Box mb={2}>
          <FormTitle title="承認" />
          <SearchMultipleSelectBox
            name="searchAppStatus"
            options={codes}
            multiple
            values={searchCondition.searchAppStatus}
            onChange={this.onChange}
          />
        </Box>
        <DeleteDataTable
          url={urls.INSPECT_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          onAddOpen={this.onAdd}
          procName="検収"
          exclusion={['projectName']}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          inspect={searchCondition.inspect}
          inspectBy={searchCondition.inspectBy}
          disabled={isDisabled || this.props.disabled}
        />

        <InspectDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          inspectId={null}
          projectId={this.props.projectId}
          parentId={this.props.parentId}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectInfo: state.inspectStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(InspectList);
