/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const cacheSlice = createSlice({
  name: 'cache',
  initialState: {
    datas: [],
    select: {},
    searchCondition: null,
    isUnConfirm: false,
    isConfirm: false,
    isChange: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getData: (state) => {
      state.datas = null;
    },
    getDataSuccess: (state, action) => {
      state.datas = action.payload;
    },
    updateConfirm: (state) => {
      state.isConfirm = false;
    },
    updateConfirmSuccess: (state) => {
      state.isConfirm = true;
    },
    updateUnConfirm: (state) => {
      state.isUnConfirm = false;
    },
    updateUnConfirmSuccess: (state) => {
      state.isUnConfirm = true;
    },
    updateChange: (state) => {
      state.isChange = false;
    },
    updateChangeSuccess: (state) => {
      state.isChange = true;
    },
    resetFlg: (state) => {
      state.isUnConfirm = false;
      state.isConfirm = false;
      state.isChange = false;
    },
  },
});

export const cacheActions = cacheSlice.actions;

export default cacheSlice.reducer;
