import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Button, Alert, Grid, Paper,
} from '@mui/material';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../../components/atoms/withParam/WithParams';
import { trafficRequestDetailActions } from '../../../../redux/request/trafficRequest/trafficRequestDetailState';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import DataDetailTable from './DataDetailTable';
import ReceiptList from './ReceiptList';
import CopyDialog from './CopyDialog';
import RejectDialog from './RejectDialog';

class TrafficRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (this.props.trafficRequestDetailInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '作成処理が完了しました';
      props.dispatch(trafficRequestDetailActions.clearFlag());
    }
    this.state = {
      updateStatus: '',
      form: {
        prepay: false,
        statusCode: '0',
        detailList: [],
        fileList: [],
      },
      successMessage,
      isLoading: false,
      isSuccessOpen,
      isReject: false,
      isCopyOpen: false,
      isAffairs: props.loginUser.userInfo.user.affairs,
    };
    if (id) {
      props.dispatch(trafficRequestDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trafficRequestDetailInfo.data !== this.props.trafficRequestDetailInfo.data) {
      if (this.props.trafficRequestDetailInfo.data) {
        this.setDatas(this.props.trafficRequestDetailInfo.data);
      }
    }
    if (prevProps.trafficRequestDetailInfo.isStatusUpdateSuccess
      !== this.props.trafficRequestDetailInfo.isStatusUpdateSuccess) {
      if (this.props.trafficRequestDetailInfo.isStatusUpdateSuccess) {
        this.setSaveStatusSuccess();
      }
    }
    if (prevProps.trafficRequestDetailInfo.isDeleteSuccess
      !== this.props.trafficRequestDetailInfo.isDeleteSuccess) {
      if (this.props.trafficRequestDetailInfo.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  onProcStart = () => {
    this.setState({ isLoading: true, isSuccessOpen: false });
  };

  onResearch = () => {
    const { form } = this.state;
    this.props.dispatch(trafficRequestDetailActions.getData({ id: form.id }));
  };

  setSaveStatusSuccess = () => {
    const { form, updateStatus } = this.state;
    this.props.dispatch(trafficRequestDetailActions.getData({ id: form.id }));
    let proc = '申請';
    if (updateStatus === '2') {
      proc = '承認';
    }
    if (updateStatus === '3') {
      proc = '差戻';
    }
    if (updateStatus === '0') {
      proc = '取下';
    }
    this.setState({ isSuccessOpen: true, successMessage: `${proc}処理が完了しました`, isReject: false });
  };

  setSuccess = (message) => {
    this.onResearch();
    this.setState({
      isSuccessOpen: true, successMessage: message, isCopyOpen: false, isLoading: false,
    });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onSaveStatus = (_, status, rejectComment) => {
    const { form } = this.state;
    this.setState({ updateStatus: status, isSuccessOpen: false });
    this.props.dispatch(trafficRequestDetailActions.saveStatus({
      id: form.id, status, rejectComment,
    }));
  };

  onClose = () => {
    this.setState({ isSuccessOpen: false, isCopyOpen: false, isReject: false });
  };

  isRequestDisabled = () => {
    const { form } = this.state;
    if (form.detailList.length <= 0) {
      return true;
    }
    const receiptCount = form.detailList.filter((row) => row.category === '定期').length;
    return receiptCount >= 1 && form.fileList.length === 0;
  };

  onCopy = () => {
    this.setState({ isSuccessOpen: false, isCopyOpen: true });
  };

  onReject = () => {
    this.setState({ isSuccessOpen: false, isReject: true });
  };

  render() {
    const {
      isSuccessOpen, successMessage, form, isAffairs, isCopyOpen, isLoading, isReject,
    } = this.state;

    return (
      <Container maxWidth="xl">
        {(form.statusCode !== '2' && form.rejectComment) && (
          <Alert severity="error">
            <Box sx={{ fontWeight: 'bold' }}>差戻コメント</Box>
            <Box>{form.rejectComment && form.rejectComment.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
          </Alert>
        )}
        {form.prepay && (<Alert severity="error">前払い申請です</Alert>)}
        <Box p={1} textAlign="right">
          {(form.statusCode === '0' && form.detailList.length > 0) && (
            <Button variant="contained" size="small" onClick={(e) => this.onSaveStatus(e, '1', null)} disabled={this.isRequestDisabled()}>申請</Button>
          )}
          {(form.statusCode === '3') && (
            <Button variant="contained" size="small" onClick={(e) => this.onSaveStatus(e, '0', null)} disabled={this.isRequestDisabled()}>取下</Button>
          )}
          {isAffairs && (
            <Box sx={{ flex: '0 0 auto' }}>
              {form.statusCode === '1' && (
                <Button variant="contained" size="small" onClick={(e) => this.onSaveStatus(e, '2', null)} style={{ marginRight: '5px' }}>承認</Button>
              )}
              {(form.statusCode === '1' || form.statusCode === '2') && (
                <Button variant="contained" color="secondary" size="small" onClick={this.onReject}>差戻</Button>
              )}
            </Box>
          )}
        </Box>
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container>
            <Grid item xs={6} md={3}>
              <FormTitle title="名前" />
              <Box>{form.syainName}</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="対象月" />
              <Box>{form.targetMonth}</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="状況" />
              <Box>{form.statusName}</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="合計" />
              <Box>{form.totalFee}</Box>
            </Grid>
          </Grid>
        </Paper>
        <Box>
          <DataDetailTable
            onClose={this.onResearch}
            rows={form.detailList}
            header={form}
            onCopy={this.onCopy}
            loading={isLoading}
            onProcStart={this.onProcStart}
          />
        </Box>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onClose}
          message={successMessage}
        />
        <CopyDialog
          parentId={form.id}
          targetDate={form.internalMonth}
          isOpen={isCopyOpen}
          onClose={this.onClose}
          onSaveSuccess={this.setSuccess}
        />
        <ReceiptList header={form} datas={form.fileList} onResearch={this.onResearch} />
        <RejectDialog
          isError
          open={isReject}
          onClose={this.onClose}
          onClick={(e, comment) => this.onSaveStatus(e, '3', comment)}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  trafficRequestDetailInfo: state.trafficRequestDetailStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(TrafficRequestDetail));
