import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const requiredDateValidation = (value) => {
  if (!value) return '入力してください';
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'inspectNo':
      case 'zeiKubun':
      case 'status':
        return requiredValidation(value);
      case 'inspectDt':
        return requiredDateValidation(value);
      case 'deliveryDate':
      case 'workStartDate':
      case 'workEndDate':
        return dateValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
