import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Box,
} from '@mui/material';
import { format } from 'date-fns';
import { workDeviationActions } from '../../../redux/work/workDeviation/workDeviationState';

class WorkDeviation extends React.Component {
  constructor(props) {
    super(props);

    const searchCondition = {
      targetDate: format(new Date(), 'yyyy/MM/dd'),
      syainId: '',
    };

    props.dispatch(workDeviationActions.getData(searchCondition));
    this.state = {
      datas: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workDeviationInfo.datas !== this.props.workDeviationInfo.datas) {
      if (this.props.workDeviationInfo.datas) {
        this.setDatas(this.props.workDeviationInfo.datas);
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  render() {
    const { datas } = this.state;

    return (
      <Container maxWidth="xl">
        <Box mt={1}>
          <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table
                size="medium"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell>社員名</TableCell>
                    <TableCell>現時点所定</TableCell>
                    <TableCell>現時点実績</TableCell>
                    <TableCell>現時点差分</TableCell>
                    <TableCell>月所定</TableCell>
                    <TableCell>予実</TableCell>
                    <TableCell>着地予想</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((row) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.syainId}
                    >
                      <TableCell>{row.syainMei}</TableCell>
                      <TableCell>{row.nowHour}</TableCell>
                      <TableCell>{row.resultWorkTm}</TableCell>
                      <TableCell>{row.nowDeviation}</TableCell>
                      <TableCell>{row.futureHour}</TableCell>
                      <TableCell>{row.resultPlanWorkTm}</TableCell>
                      <TableCell>{row.allDeviation}</TableCell>
                    </TableRow>
                  ))}
                  {
                    datas.length < 1 && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={4}>
                          データがありません
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  workDeviationInfo: state.workDeviationStore,
});

export default connect(mapStateToProps)(WorkDeviation);
