import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Paper, Box, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import { workResultActions } from '../../../redux/work/workResult/workResultState';

export default function DataTable(props) {
  const headCells = [
    { id: 'syain_mei', label: '名前' },
    { id: 'target_date', label: '対象日' },
    { id: 'reason', label: '理由' },
    { id: 'apply_at', label: '申請日' },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
  } = props;

  const onClick = (_, row) => {
    dispatch(workResultActions.setSearchCondition({
      syainId: row.syainId, targetMonth: row.targetDate,
    }));
    navigate('/work-results');
  };

  return (
    <Box mt={1}>
      <Paper>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell><Button variant="text" onClick={(e) => onClick(e, row)}>{row.syainMei}</Button></TableCell>
                  <TableCell>{row.targetDate}</TableCell>
                  <TableCell>{row.reason}</TableCell>
                  <TableCell>{row.applyAt}</TableCell>
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={4}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
