import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { loginUserActions } from './loginUserState';
import {
  getDatas, deleteDatas, saveData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SETTING.LOGIN_USER.LOGIN_USER, payload);
    yield put(loginUserActions.getDataSuccess(result));
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SETTING.LOGIN_USER.LOGIN_USER_SELECT, payload);
    yield put(loginUserActions.getSelectSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SETTING.LOGIN_USER.LOGIN_USER, payload);
    yield put(loginUserActions.deleteDataSuccess());
  } catch (_) {}
}

function* update({ payload }) {
  try {
    yield call(saveData, REST_API.SETTING.LOGIN_USER.LOGIN_USER, payload);
    yield put(loginUserActions.updateSuccess());
  } catch (_) {}
}

function* reset({ payload }) {
  try {
    yield call(saveData, REST_API.SETTING.LOGIN_USER.LOGIN_USER_RESET, payload);
    yield put(loginUserActions.resetSuccess());
  } catch (_) {}
}

function* loginUserSaga() {
  yield takeLatest('loginUser/getData', getData);
  yield takeLatest('loginUser/getSelect', getSelect);
  yield takeLatest('loginUser/deleteData', deleteData);
  yield takeLatest('loginUser/update', update);
  yield takeLatest('loginUser/reset', reset);
}

export default loginUserSaga;
