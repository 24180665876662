/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { cacheActions } from './cacheState';
import {
  getDatas, saveData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.CACHE.CACHE, payload);
    yield put(cacheActions.getDataSuccess(result));
  } catch (_) {}
}

function* updateConfirm({ payload }) {
  try {
    yield call(saveData, REST_API.OFFICE.CACHE.CACHE_CONFIRM, payload);
    yield put(cacheActions.updateConfirmSuccess());
  } catch (_) {}
}

function* updateUnConfirm({ payload }) {
  try {
    yield call(saveData, REST_API.OFFICE.CACHE.CACHE_UNCONFIRM, payload);
    yield put(cacheActions.updateUnConfirmSuccess());
  } catch (_) {}
}

function* updateChange({ payload }) {
  try {
    yield call(saveData, REST_API.OFFICE.CACHE.CACHE_CHANGE, payload);
    yield put(cacheActions.updateChangeSuccess());
  } catch (_) {}
}

function* cacheSaga() {
  yield takeLatest('cache/getData', getData);
  yield takeLatest('cache/updateConfirm', updateConfirm);
  yield takeLatest('cache/updateUnConfirm', updateUnConfirm);
  yield takeLatest('cache/updateChange', updateChange);
}

export default cacheSaga;
