/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { outputActions } from './outputState';
import {
  getDatas,
} from '../../../services/base.service';

function* getWorkSheets({ payload }) {
  try {
    const datas = yield call(getDatas, REST_API.FILE.OUTPUT.WORK_SHEET, payload);
    yield put(outputActions.getWorkSheetsSuccess(datas));
  } catch (_) {}
}

function* getWorkSheetsBp({ payload }) {
  try {
    const datas = yield call(getDatas, REST_API.FILE.OUTPUT.WORK_SHEET_BP, payload);
    yield put(outputActions.getWorkSheetsBpSuccess(datas));
  } catch (_) {}
}

function* getSalarySheets({ payload }) {
  try {
    const datas = yield call(getDatas, REST_API.FILE.OUTPUT.SALARY_SHEET, payload);
    yield put(outputActions.getSalarySheetsSuccess(datas));
  } catch (_) {}
}

function* getTrafficSheets({ payload }) {
  try {
    const datas = yield call(getDatas, REST_API.FILE.OUTPUT.TRAFFIC_SHEET, payload);
    yield put(outputActions.getTrafficSheetsSuccess(datas));
  } catch (_) {}
}

function* getSkillSheets({ payload }) {
  try {
    const datas = yield call(getDatas, REST_API.FILE.OUTPUT.SKILL_SHEET, payload);
    yield put(outputActions.getSkillSheetsSuccess(datas));
  } catch (_) {}
}

function* outputSaga() {
  yield takeLatest('output/getWorkSheets', getWorkSheets);
  yield takeLatest('output/getWorkSheetsBp', getWorkSheetsBp);
  yield takeLatest('output/getSalarySheets', getSalarySheets);
  yield takeLatest('output/getTrafficSheets', getTrafficSheets);
  yield takeLatest('output/getSkillSheets', getSkillSheets);
}

export default outputSaga;
