import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Button, Switch, FormControlLabel,
} from '@mui/material';
import getColumn from '../estimate/estimate-proc';
import DataGridSheet from '../../../components/templates/dataGridSheet/DataGridSheet';
import { inspectDetailActions } from '../../../redux/sales/inspect/inspectDetailState';
import ErrorDialog from '../../../components/templates/errorDialog/ErrorDialog';
import DetailTable from '../../../components/templates/detailTable/DetailTable';

class InspectDetailInput extends React.Component {
  constructor(props) {
    super(props);
    this.columns = getColumn();
    this.dataGridRef = React.createRef();
    this.state = {
      datas: [],
      isError: false,
      checked: false,
    };
    props.dispatch(inspectDetailActions.getDatas({ inspectId: props.id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inspectDetailInfo.datas !== this.props.inspectDetailInfo.datas) {
      if (this.props.inspectDetailInfo.datas) {
        this.setDatas(this.props.inspectDetailInfo.datas);
      }
    }
    if (prevProps.inspectDetailInfo.isSaveSuccess
      !== this.props.inspectDetailInfo.isSaveSuccess) {
      if (this.props.inspectDetailInfo.isSaveSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  setDataGridRef = (ref) => {
    this.dataGridRef = ref;
  };

  onSave = (datas) => {
    this.props.dispatch(inspectDetailActions.saveData({
      inspectId: this.props.id,
      datas,
    }));
  };

  onCloseError = () => {
    this.setState({ isError: false });
  };

  setSuccess = () => {
    this.props.onSaveSuccess();
    this.props.dispatch(inspectDetailActions.getDatas({ inspectId: this.props.id }));
  };

  onClickCost = () => {
    const { data } = this.props;
    if (!data.workStartDate || !data.workEndDate) {
      this.setState({ isError: true });
      return;
    }
    this.props.dispatch(inspectDetailActions.calcCost({
      id: this.props.id,
      projectId: data.projectId,
      startDate: data.workStartDate,
      endDate: data.workEndDate,
    }));
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const { datas, isError, checked } = this.state;
    const { isDisabled } = this.props;
    return (
      <Box>
        {!isDisabled && (
          <Box textAlign="right">
            <FormControlLabel control={<Switch checked={checked} onChange={this.handleChange} />} label="Excel入力" />
            <Button variant="contained" size="small" onClick={this.onClickCost}>作業工数時間を設定</Button>
          </Box>
        )}
        {(!isDisabled && checked) && (
          <DataGridSheet
            data={datas}
            isDisabled={isDisabled}
            onSave={this.onSave}
            dataGridRef={this.dataGridRef}
            setDataGridRef={this.setDataGridRef}
            columns={this.columns}
          />
        )}
        {!checked && (
          <DetailTable datas={datas} isDisabled={isDisabled} onSave={this.onSave} />
        )}
        <ErrorDialog open={isError} title="エラーがあります" content="準委任作業開始日、準委任作業終了日を入力してください" onClose={this.onCloseError} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  inspectDetailInfo: state.inspectDetailStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(InspectDetailInput);
