import { isValid, isBefore } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const timeValidation = (value) => {
  if (!value) return '入力してください';
  let checkVal = value.replace(':', '');
  const checkOverDay = parseInt(checkVal, 10) - 2400;
  if (checkOverDay > 0) checkVal = checkOverDay;
  checkVal = (`0000${String(checkVal)}`).slice(-4);
  const target = new Date(`2000/12/31 ${checkVal[0]}${checkVal[1]}:${checkVal[2]}${checkVal[3]}`);
  if (!isValid(target)) return '時間形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'syainId':
      case 'approverResponsibleId':
      case 'overReason':
        return requiredValidation(value);
      case 'overDt':
        return dateValidation(value);
      case 'overStrTm':
      case 'overEndTm':
        return timeValidation(value);
      default:
        return '';
    }
  };

  static compareTimeValidate = (valueStart, valueEnd) => {
    const start = new Date(`1999/12/31 ${valueStart}`);
    const end = new Date(`1999/12/31 ${valueEnd}`);
    if (isBefore(end, start)) return '開始日より未来を設定してください';
    return '';
  };
}

export default Validation;
