/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { topTodoActions } from './topTodoState';
import { getDataNoLoading } from '../../../services/base.service';

function* getData() {
  try {
    const result = yield call(getDataNoLoading, REST_API.TOP.TODO);
    yield put(topTodoActions.getDatasSuccess(result));
  } catch (_) {}
}

function* topTodoSaga() {
  yield takeLatest('topTodo/getDatas', getData);
}

export default topTodoSaga;
