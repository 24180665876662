/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { salesCloseActions } from './salesCloseState';
import { getDatas, saveData } from '../../../services/base.service';

function* getData() {
  try {
    const result = yield call(getDatas, REST_API.CLOSE.SALES);
    yield put(salesCloseActions.getDatasSuccess(result));
  } catch (_) {}
}

function* checkData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.CLOSE.SALES_CHECK, payload);
    yield put(salesCloseActions.checkDataSuccess(result));
  } catch (_) {}
}

function* updateData({ payload }) {
  try {
    yield call(saveData, REST_API.CLOSE.SALES, payload);
    yield put(salesCloseActions.updateDataSuccess());
  } catch (_) {}
}

function* salesCloseSaga() {
  yield takeLatest('salesClose/getDatas', getData);
  yield takeLatest('salesClose/checkData', checkData);
  yield takeLatest('salesClose/updateData', updateData);
}

export default salesCloseSaga;
