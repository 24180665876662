import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Box, Paper, Button, Tabs, Tab, Link,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import withParams from '../../../components/atoms/withParam/WithParams';
import { clientActions } from '../../../redux/sales/client/clientState';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { checkIsDisabled } from '../../../utils/authCheck.helper';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import ClientDialog from './ClientDialog';
import GoogleMap from '../../../components/atoms/googleMap/GoogleMap';
import ClientContactList from './contact/ClientContactList';
import ClientAgreementList from './agreement/ClientAgreementList';

class ClientDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let isSuccessOpen = false;
    let successMessage = '';
    if (props.clientInfo.isCreateSuccess) {
      isSuccessOpen = true;
      successMessage = '保存が完了しました';
    }
    props.dispatch(clientActions.resetFlg());

    this.state = {
      successMessage,
      isSuccessOpen,
      form: {
        clientId: id,
      },
      isDetailOpen: false,
      tabValue: 0,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    if (id) {
      props.dispatch(clientActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientInfo.data !== this.props.clientInfo.data) {
      if (this.props.clientInfo.data) {
        this.setDatas(this.props.clientInfo.data);
      }
    }
    if (prevProps.clientInfo.isSaveSuccess
      !== this.props.clientInfo.isSaveSuccess) {
      if (this.props.clientInfo.isSaveSuccess) {
        this.setSaveSuccess(this.props.clientInfo.saveId);
      }
    }
  }

  setSaveSuccess = (id) => {
    this.props.dispatch(clientActions.getData({ id }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました' });
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  setDatas = (form) => {
    this.setState({ form });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  onCopy = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, isCopy: true });
  };

  onDetail = () => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, isCopy: false });
  };

  onSaveSuccess = () => {
    const { form } = this.state;
    this.props.dispatch(clientActions.getData({ id: form.clientId }));
    this.setState({ isSuccessOpen: true, successMessage: '保存が完了しました', isDetailOpen: false });
  };

  render() {
    const {
      form, isSuccessOpen, successMessage, isDisabled, tabValue, isDetailOpen, isCopy,
    } = this.state;

    return (
      <Container maxWidth={false}>
        {!isDisabled && (
          <Box display="flex" my={1}>
            <Box flex="1%" />
            <Box pr={1}>
              <Button variant="contained" size="small" onClick={this.onDetail}>編集</Button>
            </Box>
            <Box pr={1}>
              <Button variant="outlined" size="small" onClick={this.onCopy}>コピー</Button>
            </Box>
          </Box>
        )}
        <Paper variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <FormTitle title="名前" />
              <Box style={{ wordWrap: 'break-word' }}>{form.clientName}</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="SBS表示名" />
              <Box style={{ wordWrap: 'break-word' }}>{form.clientNickName}</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="業種" />
              <Box style={{ wordWrap: 'break-word' }}>{form.industry}</Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <FormTitle title="HP" />
              <Link href={form.hpUrl} target="_blank" underline="hover">{form.hpUrl}</Link>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={2}>
          <Tabs value={tabValue} onChange={this.onTabChange}>
            <Tab label="基本情報" />
            <Tab label="Map" disabled={!form.latitude} />
            <Tab label="担当者情報" />
            <Tab label="契約情報" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} isAll>
          <Paper variant="outlined" style={{ padding: '10px' }}>
            <Box mb={1}>
              <FormTitle title="取引先番号" />
              <Box>{form.clientNo}</Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormTitle title="取引先名" />
                <Box style={{ wordWrap: 'break-word' }}>{form.clientName}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="SBS表示名" />
                <Box style={{ wordWrap: 'break-word' }}>{form.clientNickName}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="業種" />
                <Box style={{ wordWrap: 'break-word' }}>{form.industry}</Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormTitle title="登録番号" />
                <Box style={{ wordWrap: 'break-word' }}>{form.registNumber}</Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="住所" />
                <Box>{form.yubinNo ? `〒${form.yubinNo}` : ''}</Box>
                <Box display="flex">
                  <Box style={{ wordWrap: 'break-word' }}>{form.add1}</Box>
                  <Box style={{ wordWrap: 'break-word' }}>{form.add2}</Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title="HP" />
                <Link href={form.hpUrl} target="_blank" underline="hover">{form.hpUrl}</Link>
              </Grid>
              {form.tFlg && (
                <Grid item xs={12} md={6}>
                  <FormTitle title="得意先" />
                  <Box mt={1}>
                    <FormTitle title="手数料金額" />
                    <Box>{form.tTesuryoKbn === '0' ? '当方' : '先方'}</Box>
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="手数料負担" />
                    <Box>{form.tokuisakiTesuryo}</Box>
                  </Box>
                </Grid>
              )}
              {form.sFlg && (
                <Grid item xs={12} md={6}>
                  <FormTitle title="仕入先" />
                  <Box mt={1}>
                    <FormTitle title="手数料負担" />
                    <Box>{form.sTesuryoKbn === '0' ? '当方' : '先方'}</Box>
                  </Box>
                  <Box mt={1}>
                    <FormTitle title="手数料負担" />
                    <Box>{form.siresakiTesuryo}</Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormTitle title="備考" />
                {form.remarks && form.remarks.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Grid>
            </Grid>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Box mt={1}>
              <FormTitle title="契約関連格納パス" />
              <Box display={form.contractPathId ? 'flex' : 'none'}>
                <a target="_blank" rel="noopener noreferrer" style={{ color: '#1E90FF', textDecorationColor: 'rgba(30, 144, 255, 0.4)' }} href={`https://drive.google.com/drive/u/0/folders/${form.contractPathId}`}>{form.contractPathName}</a>
                <Box ml={2}><LaunchIcon /></Box>
              </Box>
            </Box>
          </Paper>
          <Paper variant="outlined" style={{ padding: '10px', marginTop: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成日" />
                <Box>{form.createdAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="作成者" />
                <Box>{form.createdUserName}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新日" />
                <Box>{form.updatedAt}</Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormTitle title="更新者" />
                <Box>{form.updatedUserName}</Box>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={tabValue} index={1} isAll>
          <GoogleMap
            height="600px"
            lat={form.latitude}
            lng={form.longitude}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2} isAll>
          <ClientContactList
            isDisabled={isDisabled}
            clientId={form.clientId}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3} isAll>
          <ClientAgreementList
            isDisabled={isDisabled}
            clientId={form.clientId}
          />
        </TabPanel>
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onCloseSnackbar}
          message={successMessage}
        />
        <ClientDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          clientId={form.clientId}
          isCopy={isCopy}
          onSaveSuccess={this.onSaveSuccess}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  clientInfo: state.clientStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(ClientDetail));
