/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const inspectDetailSlice = createSlice({
  name: 'inspectDetail',
  initialState: {
    datas: {},
    isSaveSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    calcCost: (state) => {
      state.datas = null;
    },
    calcCostSuccess: (state, action) => {
      state.datas = action.payload;
    },
  },
});

export const inspectDetailActions = inspectDetailSlice.actions;

export default inspectDetailSlice.reducer;
