/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { overtimeRequestDetailActions } from './overtimeRequestDetailState';
import { getDataById, saveData, updateData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.REQUEST.OVERTIME_REQUEST.OVERTIME_REQUEST_ID, payload);
    yield put(overtimeRequestDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.OVERTIME_REQUEST.OVERTIME_REQUEST, payload);
    yield put(overtimeRequestDetailActions.saveDataSuccess(result));
  } catch (_) {}
}

function* saveStatus({ payload }) {
  try {
    yield call(updateData, REST_API.REQUEST.OVERTIME_REQUEST.OVERTIME_REQUEST_ID, payload);
    yield put(overtimeRequestDetailActions.saveStatusSuccess());
  } catch (_) {}
}

function* overtimeRequestDetailSaga() {
  yield takeLatest('overtimeRequestDetail/getData', getData);
  yield takeLatest('overtimeRequestDetail/saveData', saveDatas);
  yield takeLatest('overtimeRequestDetail/saveStatus', saveStatus);
}

export default overtimeRequestDetailSaga;
