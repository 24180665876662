/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { expenseRequestDetailActions } from './expenseRequestDetailState';
import {
  getDataById, saveData, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.REQUEST.EXPENSE_REQUEST.EXPENSE_REQUEST_ID, payload);
    yield put(expenseRequestDetailActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.REQUEST.EXPENSE_REQUEST.EXPENSE_REQUEST, payload);
    yield put(expenseRequestDetailActions.saveDataSuccess(result));
  } catch (_) {}
}

function* saveStatus({ payload }) {
  try {
    yield call(updateData, REST_API.REQUEST.EXPENSE_REQUEST.EXPENSE_REQUEST_ID, payload);
    yield put(expenseRequestDetailActions.saveStatusSuccess());
  } catch (_) {}
}

function* expenseRequestDetailSaga() {
  yield takeLatest('expenseRequestDetail/getData', getData);
  yield takeLatest('expenseRequestDetail/saveData', saveDatas);
  yield takeLatest('expenseRequestDetail/saveStatus', saveStatus);
}

export default expenseRequestDetailSaga;
