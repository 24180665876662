/* eslint-disable class-methods-use-this */
import React from 'react';
import ExcelJS from 'exceljs';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Button, Box, Alert,
} from '@mui/material';
import { format, isValid } from 'date-fns';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import MonthPicker from '../../../../components/atoms/monthPicker/MonthPicker';
import { outputActions } from '../../../../redux/file/output/outputState';
import createData from './work-sheet-proc';

class WorkSheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.outputInfo.workSheets !== this.props.outputInfo.workSheets) {
      if (this.props.outputInfo.workSheets) {
        this.setDatas(this.props.outputInfo.workSheets);
      }
    }
  }

  setDatas = (data) => {
    if (data.length <= 0) {
      this.props.onNotData();
      return;
    }
    this.download(data);
  };

  download = async (datas) => {
    const { targetMonth } = this.state;
    const targetStrMonth = format(new Date(targetMonth), 'yyyy年MM月');
    const res = await axios.get('/作業報告書.xlsx', { responseType: 'arraybuffer' });
    const data = new Uint8Array(res.data);
    const workbook = new ExcelJS.Workbook();
    workbook.calcProperties.fullCalcOnLoad = true;
    await workbook.xlsx.load(data);
    createData(workbook, datas, targetStrMonth);
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `作業報告書_${targetStrMonth}分.xlsx`;
    a.click();
    a.remove();
    this.props.onNotLoading();
  };

  onGetData = () => {
    const { targetMonth } = this.state;
    this.props.onLoading();
    this.props.dispatch(outputActions.getWorkSheets({ targetMonth }));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  dateValidation = (value) => {
    if (!value) return true;
    if (!isValid(new Date(value))) return true;
    return false;
  };

  render() {
    const { targetMonth } = this.state;

    return (
      <Box>
        <Alert severity="warning">未承認データは想定通りに出力されません</Alert>
        <Box mt={2}>
          <FormTitle title="出力年月" isRequired />
          <MonthPicker
            handleChange={this.onChange}
            value={targetMonth}
            name="targetMonth"
          />
        </Box>
        <Box mt={2}>
          <Button variant="contained" onClick={this.onGetData} disabled={this.dateValidation(targetMonth)}>出力</Button>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  outputInfo: state.outputStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(WorkSheet);
