import React from 'react';
import {
  DialogContent, Button, DialogActions,
} from '@mui/material';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import TextArea from '../../../../components/atoms/textArea/TextArea';

export default function RejectDialog(props) {
  const [rejectComment, setRejectComment] = React.useState('');
  const {
    open,
    onClose,
    onClick,
  } = props;

  const onChange = (e) => {
    const { value } = e.target;
    setRejectComment(value);
  };

  const onProc = (e) => {
    onClick(e, rejectComment);
  };

  React.useEffect(() => {
    if (open) {
      setRejectComment('');
    }
  }, [open]);

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle>差戻</DialogColorTitle>
      <DialogContent dividers>
        <FormTitle title="差戻コメント" isRequired />
        <TextArea
          value={rejectComment}
          name="rejectComment"
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button
          onClick={onProc}
          color="primary"
          disabled={!rejectComment}
        >
          差戻
        </Button>
      </DialogActions>
    </MaterialDialog>
  );
}
