import React from 'react';
import PropTypes from 'prop-types';
import {
  Snackbar, IconButton, Alert, Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function SuccessSnackbar(props) {
  const { open, handleClose, message } = props;

  const handleCloseCheck = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    handleClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseCheck}
      TransitionComponent={TransitionUp}
    >
      <Alert
        elevation={10}
        variant="filled"
        onClose={handleClose}
        severity="success"
        action={(
          <IconButton aria-label="delete" size="small" onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

SuccessSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
