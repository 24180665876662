function hiddenRow(workSheet, dataCount, target) {
  for (let i = 0; i < dataCount; i += 1) {
    const row = workSheet.getRow(target - i);
    row.hidden = true;
  }
}

export default function createData(workbook, data, targetMonth) {
  const workSheet = workbook.getWorksheet('一覧');
  workSheet.pageSetup = {
    orientation: 'portrait', paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 99,
  };
  let row = workSheet.getRow(2);
  row.getCell(4).value = targetMonth;
  let cellNumber = 7;
  data.header.forEach((header, index) => {
    row = workSheet.getRow(cellNumber + index);
    row.getCell(2).value = header.syainMei;
    row.getCell(3).value = header.totalFee;
  });
  hiddenRow(workSheet, 95 - data.header.length, 101);

  const workSheetDetail = workbook.getWorksheet('明細');
  workSheetDetail.pageSetup = {
    orientation: 'landscape', paperSize: 9, fitToPage: true, fitToWidth: 1, fitToHeight: 99,
  };

  data.details.forEach((detail) => {
    const syain = detail[0];
    const copySheet = workbook.addWorksheet('Sheet');
    copySheet.model = Object.assign(workSheetDetail.model, {
      mergeCells: workSheetDetail.model.merges,
      name: syain.syainMei,
    });
    copySheet.name = syain.syainMei;
    row = copySheet.getRow(2);
    row.getCell(8).value = targetMonth;
    row = copySheet.getRow(4);
    row.getCell(3).value = syain.syainMei;
    cellNumber = 7;
    detail.forEach((work, index) => {
      row = copySheet.getRow(cellNumber + index);
      row.getCell(2).value = work.targetDay;
      row.getCell(3).value = work.use;
      row.getCell(4).value = work.rideRoute;
      row.getCell(5).value = work.ridePoint;
      row.getCell(6).value = work.rideOffRoute;
      row.getCell(7).value = work.rideOffPoint;
      row.getCell(8).value = work.categoryName;
      row.getCell(9).value = work.fee;
    });
    hiddenRow(copySheet, 101 - detail.length, 107);
  });

  workbook.removeWorksheet(workSheetDetail.id);
}
