import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { workFixRequestActions } from './workFixRequestState';
import { getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.REQUEST.WORK_FIX_REQUEST, payload);
    yield put(workFixRequestActions.getDataSuccess(result));
  } catch (_) {}
}

function* workFixRequestSaga() {
  yield takeLatest('workFixRequest/getData', getData);
}

export default workFixRequestSaga;
