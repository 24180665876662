import {
  getMonth, getYear, addMonths, format, differenceInMonths,
} from 'date-fns';

export function getTargetYear() {
  const today = new Date();
  const month = getMonth(today) + 1;
  const year = getYear(today);
  let startMonth = `${year}/08/01`;
  if (month >= 2 && month <= 7) {
    startMonth = `${year}/02/01`;
  } else if (month === 1) {
    startMonth = `${year - 1}/08/01`;
  }
  return {
    startMonth,
    endMonth: format(addMonths(new Date(startMonth), 5), 'yyyy/MM/dd'),
  };
}

export function getMonths(startMonth, endMonth) {
  const diff = differenceInMonths(new Date(endMonth), new Date(startMonth));
  const months = [];
  for (let i = 0; i <= diff; i += 1) {
    const target = addMonths(new Date(startMonth), i);
    months.push({ targetDate: format(target, 'yyyy/MM/dd'), displayDate: format(target, 'yyyy年MM月') });
  }
  return months;
}

function toNumber(param) {
  const kingaku = !param ? '0' : param;
  return parseFloat(kingaku.toString().replace(/,/g, ''));
}

export function getData(datas, projectId, month, key) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  const targets = datas.filter(
    (row) => row.targetMonth === targetMonth && row.projectId === projectId,
  );
  if (targets.length <= 0) {
    return '0';
  }
  return toNumber(targets[0][key]).toLocaleString();
}

export function getMonthSummary(datas, month, key) {
  let sum = 0;
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
  });
  return sum.toLocaleString();
}

export function getSummary(datas, key) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
  });
  return sum.toLocaleString();
}

export function getProjectSummary(datas, projectId, key) {
  const targets = datas.filter((row) => row.projectId === projectId);
  if (targets.length <= 0) {
    return '0';
  }
  let sum = 0;
  targets.forEach((row) => {
    sum += toNumber(row[key]);
  });
  return sum.toLocaleString();
}
