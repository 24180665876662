/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workCostListSlice = createSlice({
  name: 'workCostList',
  initialState: {
    data: {},
  },
  reducers: {
    getData: (state) => {
      state.data = [];
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const workCostListActions = workCostListSlice.actions;

export default workCostListSlice.reducer;
