/* eslint-disable max-len */
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, IconButton, Table, TableBody, TableCell, TableContainer, Checkbox,
  TableHead, TablePagination, TableRow, Toolbar, Typography, Tooltip,
  DialogContent, DialogActions, Button, CircularProgress, TextField, DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { workResultInputActions } from '../../../redux/work/workResultInput/workResultInputState';

export default function ProjectSelectDialog(props) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const rows = useSelector((state) => state.workResultInputStore.projects);
  const isLoading = useSelector((state) => state.workResultInputStore.isLoading);
  const dispatch = useDispatch();

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onClickPin = (_, projectId) => {
    const { searchDt, syainId } = props;
    dispatch(workResultInputActions.postPin({ projectId, syainId, searchDt }));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const { isOpen, onClickClose } = props;

  const onSetClick = (event) => {
    let costs = [];
    selected.forEach((id) => {
      rows.forEach((row) => {
        if (row.projectId === id) {
          costs = [...costs, row];
        }
      });
    });
    setSelected([]);
    props.onAddCost(event, costs);
  };

  return (
    <Dialog
      onClickClose={onClickClose}
      isOpen={isOpen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        工数選択
        <IconButton
          aria-label="close"
          onClick={onClickClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box mx={2}>
          <Box>作業選択</Box>
          <TextField
            autoComplete="off"
            size="small"
            fullWidth
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: '1 1 50%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length}
              件選択
            </Typography>
          </Toolbar>
          {isLoading && (
            <Box textAlign="center" p={3} sx={{ minWidth: '250px' }}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <>
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>作業</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows.filter((v) => v.projectName && v.projectName.indexOf(searchKeyword) !== -1).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const isItemSelected = isSelected(row.projectId);
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.projectId}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.projectId)}
                            />
                          </TableCell>
                          <TableCell>{row.projectName}</TableCell>
                          <TableCell>
                            <IconButton color={row.pin ? 'primary' : 'default'} onClick={(event) => onClickPin(event, row.projectId)}>
                              <PushPinIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 33 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="表示件数"
                labelDisplayedRows={({ from, to, count }) => `${count}件中${from}-${to}`}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Tooltip title="設定する">
          <Button onClick={onSetClick} disabled={selected.length === 0}>設定する</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
