const ROOT_URL = 'https://api-dot-sodech-sbs-project.an.r.appspot.com/';
// const ROOT_URL = 'http://localhost:8080';

export const REST_URL = `${ROOT_URL}/admin`;

export const REST_API = {
  CLOSE: {
    COST: `${REST_URL}/cost-closes`,
    EDIT: `${REST_URL}/close-edits`,
    SALES: `${REST_URL}/sales-closes`,
    SALES_CHECK: `${REST_URL}/sales-closes/check`,
  },
  COMMON: {
    LOGIN: `${REST_URL}/authenticate`,
    LOGIN_INFO: `${REST_URL}/login-info`,
    REST_CALENDAR: `${REST_URL}/common-calendars`,
    SYAIN_LISTS: `${REST_URL}/common-syains`,
    SYAIN_LISTS_GREATER_THAN: `${REST_URL}/common-syains-greater-than`,
    CLIENT_LISTS: `${REST_URL}/common-clients`,
    AUTHORIZER_LISTS: `${REST_URL}/common-authorizers`,
    CODES: `${REST_URL}/common-codes`,
    PROJECT_LISTS: `${REST_URL}/common-projects`,
    ADDRESS: `${REST_URL}/zipcode`,
    CONTACT_LISTS: `${REST_URL}/common-client-contacts`,
  },
  REQUEST: {
    WORK_FIX_REQUEST: `${REST_URL}/work-fix-requests`,
    TRAFFIC_REQUEST: {
      TRAFFIC_REQUEST_INIT: `${REST_URL}/traffic-requests/init`,
      TRAFFIC_REQUEST_COPY: `${REST_URL}/traffic-requests/copy`,
      TRAFFIC_REQUEST: `${REST_URL}/traffic-requests`,
      TRAFFIC_REQUEST_ID: `${REST_URL}/traffic-requests/{id}`,
      TRAFFIC_REQUEST_DETAIL: `${REST_URL}/traffic-requests/detail`,
      TRAFFIC_REQUEST_FILE_ID: `${REST_URL}/traffic-requests/file/{id}`,
      TRAFFIC_REQUEST_FILE: `${REST_URL}/traffic-requests/file`,
      TRAFFIC_REQUEST_DETAIL_ID: `${REST_URL}/traffic-requests/detail/{id}`,
    },
    WORK_REQUEST: {
      WORK_REQUEST: `${REST_URL}/work-requests`,
      WORK_REQUEST_ID: `${REST_URL}/work-requests/{id}`,
      WORK_REQUEST_APPROVAL: `${REST_URL}/work-requests/approval`,
      WORK_REQUEST_CONFIRM: `${REST_URL}/work-requests/confirm`,
    },
    EXPENSE_REQUEST: {
      EXPENSE_REQUEST: `${REST_URL}/expense-requests`,
      EXPENSE_REQUEST_ID: `${REST_URL}/expense-requests/{id}`,
    },
    ASSET_REQUEST: {
      ASSET_REQUEST: `${REST_URL}/asset-requests`,
      ASSET_REQUEST_ID: `${REST_URL}/asset-requests/{id}`,
      ASSET_REQUEST_CONFIRM: `${REST_URL}/asset-requests/confirm`,
      ASSET_REQUEST_RETURN: `${REST_URL}/asset-requests/returning/{id}`,
    },
    OVERTIME_REQUEST: {
      OVERTIME_REQUEST: `${REST_URL}/overtime-requests`,
      OVERTIME_REQUEST_ID: `${REST_URL}/overtime-requests/{id}`,
      OVERTIME_REQUEST_APPROVAL: `${REST_URL}/overtime-requests/approval`,
    },
  },
  SETTING: {
    ROLE_LIST: `${REST_URL}/roles`,
    CALENDARS: `${REST_URL}/calendars`,
    SCREEN_LIST: `${REST_URL}/screens`,
    ROLE_DETAIL_ID: `${REST_URL}/roles/{id}`,
    SALARY_SETTING: `${REST_URL}/salary-settings`,
    WORK_STYLE: {
      WORK_STYLE: `${REST_URL}/work-styles`,
      WORK_STYLE_ID: `${REST_URL}/work-styles/{id}`,
    },
    LOGIN_USER: {
      LOGIN_USER: `${REST_URL}/login-users`,
      LOGIN_USER_SELECT: `${REST_URL}/login-users/select`,
      LOGIN_USER_RESET: `${REST_URL}/login-users/reset`,
    },
    NOTICE: {
      NOTICE: `${REST_URL}/notices`,
      NOTICE_ID: `${REST_URL}/notices/{id}`,
    },
  },
  WORK: {
    WORK_PLAN: `${REST_URL}/work-plans`,
    WORK_COST_LIST: `${REST_URL}/work-costs`,
    WORK_SUMMARY: `${REST_URL}/work-summary`,
    WORK_CLOCK: `${REST_URL}/work-clocks`,
    WORK_PLAN_RESULT: `${REST_URL}/work-plan-results`,
    WORK_PLAN_REST: `${REST_URL}/work-plan-rests`,
    WORK_DEVIATION: `${REST_URL}/work-deviations`,
    WORK_RESULT: {
      WORK_RESULT: `${REST_URL}/work-results`,
      WORK_RESULT_REQUEST: `${REST_URL}/work-results/request`,
      WORK_RESULT_APPROVAL: `${REST_URL}/work-results/approval`,
    },
    WORK_RESULT_INPUT: {
      WORK_RESULT_INPUT_SELECT: `${REST_URL}/work-result-inputs/selects`,
      WORK_RESULT_INPUT_PROJECT: `${REST_URL}/work-result-inputs/projects`,
      WORK_RESULT_INPUT: `${REST_URL}/work-result-inputs`,
      WORK_RESULT_INPUT_PIN: `${REST_URL}/work-result-inputs/pin`,
      WORK_RESULT_INPUT_RECALC: `${REST_URL}/work-result-inputs/recalc`,
      WORK_RESULT_INPUT_COPY: `${REST_URL}/work-result-inputs/copy`,
      WORK_RESULT_INPUT_FIX: `${REST_URL}/work-result-inputs/fix`,
    },
  },
  SALES: {
    APPROVAL_HISTORY: `${REST_URL}/approval-histories`,
    CLIENT: {
      CLIENT: `${REST_URL}/clients`,
      CLIENT_SELECT: `${REST_URL}/clients/select`,
      CLIENT_ID: `${REST_URL}/clients/{id}`,
      CLIENT_CONTACT: `${REST_URL}/client-contacts`,
      CLIENT_CONTACT_ID: `${REST_URL}/client-contacts/{id}`,
      CLIENT_AGREEMENT: `${REST_URL}/client-agreements`,
      CLIENT_AGREEMENT_ID: `${REST_URL}/client-agreements/{id}`,
      CLIENT_AGREEMENT_NAMES: `${REST_URL}/client-agreements/names`,
    },
    PARTNER: {
      PARTNER: `${REST_URL}/partners`,
      PARTNER_ID: `${REST_URL}/partners/{id}`,
    },
    ORDER: {
      ORDER: `${REST_URL}/orders`,
      ORDER_SELECT: `${REST_URL}/orders/select`,
      ORDER_DETAIL: `${REST_URL}/orders/detail`,
      ORDER_ID: `${REST_URL}/orders/{id}`,
    },
    PAY: {
      PAY: `${REST_URL}/pays`,
      PAY_ID: `${REST_URL}/pays/{id}`,
      PAY_DETAIL: `${REST_URL}/pays/detail`,
      PAY_DETAIL_ID: `${REST_URL}/pays/detail/{id}`,
      PAY_SELECT: `${REST_URL}/pays/selects`,
    },
    CLAIM: {
      CLAIM: `${REST_URL}/claims`,
      CLAIM_DETAIL: `${REST_URL}/claims/detail`,
      CLAIM_ID: `${REST_URL}/claims/{id}`,
      CLAIM_PDF: `${REST_URL}/claims/pdf/{id}`,
    },
    INSPECT: {
      INSPECT_SELECT: `${REST_URL}/inspects/selects`,
      INSPECT: `${REST_URL}/inspects`,
      INSPECT_DETAIL: `${REST_URL}/inspects/detail`,
      INSPECT_ID: `${REST_URL}/inspects/{id}`,
      INSPECT_PDF: `${REST_URL}/inspects/pdf/{id}`,
      INSPECT_COST: `${REST_URL}/inspects/cost`,
      INSPECT_COPY_CLAIM: `${REST_URL}/inspects/copy/claim`,
    },
    ESTIMATE: {
      ESTIMATE: `${REST_URL}/estimates`,
      ESTIMATE_PDF: `${REST_URL}/estimates/pdf/{id}`,
      ESTIMATE_DETAIL: `${REST_URL}/estimates/detail`,
      ESTIMATE_ID: `${REST_URL}/estimates/{id}`,
      ESTIMATE_SELECT: `${REST_URL}/estimates/select`,
      ESTIMATE_COPY: `${REST_URL}/estimates/copy`,
      ESTIMATE_COPY_INSPECT: `${REST_URL}/estimates/copy/inspect`,
      ESTIMATE_COPY_CLAIM: `${REST_URL}/estimates/copy/claim`,
    },
    PROJECT: {
      PROJECT: `${REST_URL}/projects`,
      PROJECT_ID: `${REST_URL}/projects/{id}`,
      PROJECT_SELECT: `${REST_URL}/projects/select`,
    },
    SKILL: {
      SKILL: `${REST_URL}/skills`,
      SKILL_ID: `${REST_URL}/skills/{id}`,
      SKILL_PROJECT: `${REST_URL}/skills/projects`,
    },
  },
  OFFICE: {
    VACATION: {
      VACATION: `${REST_URL}/vacations`,
      VACATION_DETAIL: `${REST_URL}/vacations/detail`,
    },
    CACHE: {
      CACHE: `${REST_URL}/caches`,
      CACHE_CONFIRM: `${REST_URL}/caches/confirm`,
      CACHE_UNCONFIRM: `${REST_URL}/caches/unconfirm`,
      CACHE_CHANGE: `${REST_URL}/caches/change`,
    },
    SALARY: {
      SALARY: `${REST_URL}/salaries`,
      SALARY_CSV: `${REST_URL}/salaries/output`,
      SALARY_ID: `${REST_URL}/salaries/{id}`,
      SALARY_RECALC: `${REST_URL}/salaries/recalc`,
      SALARY_RECALC_INSURANCE: `${REST_URL}/salaries/recalc-insurance`,
    },
    EMPLOYEE: {
      EMPLOYEE: `${REST_URL}/employees`,
      EMPLOYEE_SELECT: `${REST_URL}/employees/select`,
      EMPLOYEE_ID: `${REST_URL}/employees/{id}`,
      EMPLOYEE_HISTORY: `${REST_URL}/employees/history`,
      EMPLOYEE_HISTORY_ID: `${REST_URL}/employees/history/{id}`,
      EMPLOYEE_LICENSE_HISTORY: `${REST_URL}/employees/license-history`,
      EMPLOYEE_LICENSE_HISTORY_SELECT: `${REST_URL}/employees/license-history/select`,
      EMPLOYEE_LICENSE_HISTORY_ID: `${REST_URL}/employees/license-history/{id}`,
    },
    CERTIFICATE_CAMPAIGN: {
      CERTIFICATE_CAMPAIGN: `${REST_URL}/certificate-campaigns`,
      CERTIFICATE_CAMPAIGN_ID: `${REST_URL}/certificate-campaigns/{id}`,
    },
    FIXED_EXPENSE: {
      FIXED_EXPENSE: `${REST_URL}/fixed-expenses`,
      FIXED_EXPENSE_ID: `${REST_URL}/fixed-expenses/{id}`,
    },
  },
  MASTER: {
    CERTIFICATE: {
      CERTIFICATE: `${REST_URL}/certificates`,
      CERTIFICATE_SELECT: `${REST_URL}/certificates/select`,
      CERTIFICATE_ID: `${REST_URL}/certificates/{id}`,
    },
    ASSET: {
      ASSET: `${REST_URL}/assets`,
      ASSET_SELECT: `${REST_URL}/assets/select`,
      ASSET_ID: `${REST_URL}/assets/{id}`,
    },
  },
  TOP: {
    CLOSE: `${REST_URL}/top-closes`,
    NOTICE: `${REST_URL}/top-notices`,
    TODO: `${REST_URL}/top-todos`,
  },
  PROFILE: {
    PASSWORD: `${REST_URL}/password`,
    USER_SETTING: {
      USER_SETTING: `${REST_URL}/user-settings`,
      USER_SETTING_SELECT: `${REST_URL}/user-settings/select`,
    },
  },
  FILE: {
    OUTPUT: {
      WORK_SHEET: `${REST_URL}/output/work-sheets`,
      WORK_SHEET_BP: `${REST_URL}/output/work-sheets-bp`,
      SALARY_SHEET: `${REST_URL}/output/salary-sheets`,
      TRAFFIC_SHEET: `${REST_URL}/output/traffic-sheets`,
      SKILL_SHEET: `${REST_URL}/output/skill-sheets`,
    },
    INPUT: {
      EXPENSE: `${REST_URL}/input/expenses`,
      EMPLOYEE: `${REST_URL}/input/employees`,
    },
  },
  SUMMARY: {
    REPORT: {
      REPORT: `${REST_URL}/reports`,
      REPORT_CATEGORY: `${REST_URL}/reports/categories`,
      REPORT_SYAIN: `${REST_URL}/reports/syains`,
      REPORT_ID: `${REST_URL}/reports/{id}`,
      REPORT_DETAIL: `${REST_URL}/reports/detail`,
    },
    COMPANY_BALANCE: {
      COMPANY_BALANCE: `${REST_URL}/company-balances`,
    },
    DIVISION_BALANCE: {
      DIVISION_BALANCE: `${REST_URL}/division-balances`,
      DIVISION_BALANCE_SELECT: `${REST_URL}/division-balances/select`,
    },
    PROJECT_BALANCE: {
      PROJECT_BALANCE: `${REST_URL}/project-balances`,
      PROJECT_BALANCE_SELECT: `${REST_URL}/project-balances/select`,
      PROJECT_BALANCE_DETAIL_COST: `${REST_URL}/project-balances/detail/cost`,
      PROJECT_BALANCE_DETAIL_SELECT: `${REST_URL}/project-balances/detail/select`,
      PROJECT_BALANCE_DETAIL: `${REST_URL}/project-balances/detail`,
    },
  },
};
