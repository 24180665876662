import React from 'react';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, DialogTitle, DialogContent, DialogActions, Box,
} from '@mui/material';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { salaryDetailActions } from '../../../redux/office/salary/salaryDetailState';
import SelectSyainWithMonth from '../../../components/organisms/selectSyainWithMonth/SelectSyainWithMonth';
import urls from '../../../constants/frontUrls';

export default function SalaryCreateDialog(props) {
  const [syainId, setSyainId] = React.useState(null);
  const [targetMonth, setTargetMonth] = React.useState(format(new Date(), 'yyyy/MM/dd'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onClickClose } = props;
  const isInsertSuccess = useSelector((state) => state.salaryDetailStore.isInsertSuccess);
  const saveId = useSelector((state) => state.salaryDetailStore.saveId);

  React.useEffect(() => {
    if (isOpen) {
      setSyainId(null);
      setTargetMonth(format(new Date(), 'yyyy/MM/dd'));
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isInsertSuccess) {
      navigate(urls.SALARY_DETAIL + saveId);
    }
  }, [isInsertSuccess]);

  const onSyainChange = (event) => {
    setSyainId(event.syainId);
    setTargetMonth(event.targetMonth);
  };

  const onClickSave = () => {
    dispatch(
      salaryDetailActions.insertData({
        syainId,
        targetMonth: `${format(new Date(targetMonth), 'yyyy/MM')}/01`,
      }),
    );
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClickClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>給与データの追加</DialogTitle>
      <DialogContent dividers>
        <Box>
          <SelectSyainWithMonth md={6} targetMonth={targetMonth} syainId={syainId} onChange={onSyainChange} syainLabel="名前" monthLabel="対象月" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave} disabled={!targetMonth || !syainId}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
