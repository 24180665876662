import React from 'react';
import {
  DialogContent,
  DialogActions,
  Box, Button,
} from '@mui/material';
import { format } from 'date-fns';
import MaterialDialog from '../../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import SelectExpenseSyain from './SelectExpenseSyain';
import MoneyInput from '../../../../components/atoms/moneyInput/MoneyInput';

export default function CreateTrafficDialog(props) {
  const [money, setMoney] = React.useState('0');
  const [syain, setSyain] = React.useState({});
  const [isEdit, setEdit] = React.useState({});

  const {
    open,
    onClose,
    onAdd,
    selectedRow,
  } = props;

  React.useEffect(() => {
    if (open) {
      setMoney('0');
      setSyain({});
      setEdit(false);
      if (selectedRow) {
        setEdit(true);
        setMoney(selectedRow.offerAmount);
        setSyain({ id: selectedRow.syainId, name: selectedRow.syainMei });
      }
    }
  }, [open]);

  const onClick = () => {
    if (!money || (!syain || !syain.id)) {
      return;
    }
    onAdd({ syainId: syain.id, syainMei: syain.name, offerAmount: money });
  };

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle>{`内訳${isEdit ? '編集' : '追加'}`}</DialogColorTitle>
      <DialogContent dividers>
        <Box mt={2}>
          <FormTitle title="名前" isRequired />
          <SelectExpenseSyain onChange={(_, value) => setSyain(value)} value={syain} targetMonth={format(new Date(), 'yyyy/MM/dd')} />
        </Box>
        <Box mt={2}>
          <FormTitle title="金額" isRequired />
          <MoneyInput fullWidth value={money} name="offerAmount" onChange={(e) => setMoney(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button
          onClick={onClick}
          color="primary"
          disabled={(!syain || !syain.id) || !money}
        >
          保存
        </Button>
      </DialogActions>
    </MaterialDialog>
  );
}
