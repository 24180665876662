import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value.formattedValue,
          },
        });
      }}
      thousandSeparator
    />
  );
});

export default function FormattedInputs(props) {
  const {
    value, onChange, fullWidth, error, name, isDisabled, onBlur,
  } = props;
  return (
    <TextField
      error={error}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      fullWidth={fullWidth}
      autoComplete="off"
      variant="outlined"
      disabled={isDisabled}
      id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
}
