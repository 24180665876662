import React from 'react';
import { connect } from 'react-redux';
import { format, isValid } from 'date-fns';
import {
  Grid, Box, IconButton, Tooltip,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchSelectBox from '../../atoms/searchSelectBox/SearchSelectBox';
import MonthPicker from '../../atoms/monthPicker/MonthPicker';
import { commonActions } from '../../../redux/common/commonState';
import FormTitle from '../../atoms/formTitle/FormTitle';

class SelectSyainWithMonth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.props.dispatch(
      commonActions.getSyainList({
        targetMonth: format(new Date(), 'yyyy/MM/dd'),
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.syains !== this.props.common.syains) {
      if (this.props.common.syains) {
        this.setData(this.props.common.syains);
      }
    }
    if (prevProps.targetMonth !== this.props.targetMonth) {
      if (this.props.targetMonth) {
        this.getSyains();
      }
    }
  }

  setData = (options) => {
    this.setState({ options });
  };

  getSyains = () => {
    this.props.dispatch(
      commonActions.getSyainList({
        targetMonth: this.props.targetMonth,
      }),
    );
  };

  onMonthChange = (event) => {
    if (event.target.value === 'Invalid Date' || !isValid(new Date(event.target.value))) {
      return;
    }
    this.props.onChange({
      targetMonth: event.target.value,
      syainId: this.props.syainId,
    });
  };

  onSyainChange = (event) => {
    this.props.onChange({
      syainId: event.target.value,
      targetMonth: this.props.targetMonth,
    });
  };

  onClickMe = () => {
    if (!this.props.loginUser.userInfo) {
      return;
    }
    this.props.onChange({
      syainId: this.props.loginUser.userInfo.user.id,
      targetMonth: this.props.targetMonth,
    });
  };

  render() {
    const { options } = this.state;
    const {
      targetMonth, syainId, syainLabel, monthLabel, isBp, md,
      isRequired, isNotSyain, disableClearable,
    } = this.props;
    return (
      <Grid container spacing={1}>
        {(!isBp && !isNotSyain) && (
          <Grid item xs={12} md={md || 3}>
            {syainLabel && (<FormTitle title={syainLabel} isRequired={isRequired} />)}
            <Box display="flex">
              <Box style={{ flex: 1 }}>
                <SearchSelectBox
                  options={options}
                  value={syainId}
                  onChange={this.onSyainChange}
                  disableClearable={disableClearable}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Tooltip title="自分">
                  <IconButton size="small" onClick={this.onClickMe}>
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Box>
            {monthLabel && (<FormTitle title={monthLabel} isRequired={isRequired} />)}
            <MonthPicker value={targetMonth} name="targetMonth" handleChange={this.onMonthChange} />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.commonStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(SelectSyainWithMonth);
