/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    token: null,
    isLoginSuccess: false,
    isLoginInfoError: false,
    userInfo: null,
  },
  reducers: {
    executeLogin: (state) => {
      state.isLoginSuccess = null;
    },
    executeLoginSuccess: (state, action) => {
      state.token = action.payload;
      state.isLoginSuccess = true;
    },
    executeLoginFailure: (state) => {
      state.token = '';
      state.isLoginSuccess = false;
    },
    clearLoginInfo: (state) => {
      state.userInfo = null;
      state.isLoginInfoError = false;
      state.isLoginSuccess = null;
      state.token = '';
    },
    executeGetLoginInfo: (state) => {
      state.isLoginInfoError = false;
    },
    executeGetLoginInfoSuccess: (state, action) => {
      state.userInfo = action.payload;
      state.isLoginInfoError = false;
    },
    executeGetLoginInfoFailure: (state) => {
      state.userInfo = null;
      state.isLoginInfoError = true;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
