import React from 'react';
import { connect } from 'react-redux';
import { Grid, Container, Box } from '@mui/material';
import Clock from './Clock';
import TopNotice from './TopNotice';
import TopTodo from './TopTodo';
import PrivateDeviation from './PrivateDeviation';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container maxWidth={false}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Clock />
            </Grid>
            <PrivateDeviation />
          </Grid>
        </Container>
        <Box mt={4}>
          <Container maxWidth={false}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TopTodo />
              </Grid>
              <Grid item xs={12} md={6}>
                <TopNotice />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workResultInputInfo: state.workResultInputStore,
});

export default connect(mapStateToProps)(Home);
