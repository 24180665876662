import React from 'react';
import { format, isValid, isBefore } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { connect } from 'react-redux';
import {
  Button, DialogTitle, DialogContent, DialogActions,
  IconButton, ToggleButtonGroup, ToggleButton, Box,
  FormControl, Select, MenuItem, FormHelperText, TextField,
} from '@mui/material';
import {
  Business as BusinessIcon, Home as HomeIcon, Close as CloseIcon,
  OtherHouses as OtherHousesIcon,
} from '@mui/icons-material';
import TimePicker from '../../../components/atoms/timePicker/TimePicker';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { saveTargetDate, getRestWorkTime, isRested } from './work-plan-proc';
import { workPlanActions } from '../../../redux/work/workPlan/workPlanState';

class WorkPlanInputDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: '',
      endTime: '',
      placeCode: '',
      placeOther: '',
      restData: {},
      workTime: 0,
      restTime: 0,
      rested: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.initData();
      }
    }
    if (this.props.workPlanInfo.rest !== prevProps.workPlanInfo.rest) {
      if (this.props.workPlanInfo.rest) {
        this.setRestData(this.props.workPlanInfo.rest);
      }
    }
  }

  setRestData = (restData) => {
    this.setState({ restData });
  };

  initData = () => {
    const { data, targetDateItem, restCalendars } = this.props;
    const targetFormatDate = format(new Date(targetDateItem.formatDate), 'yyyy年MM月dd日(EEEEEE)', { locale: ja });
    const targetFormatMonth = format(new Date(targetDateItem.formatDate), 'yyyy年MM月', { locale: ja });
    const targetMonth = format(new Date(targetDateItem.formatDate), 'yyyy/MM', { locale: ja });
    const { week } = targetDateItem;
    const rested = isRested(restCalendars, targetDateItem.formatDate);
    this.setState({
      targetFormatDate,
      startTime: data.startTime,
      endTime: data.endTime,
      placeCode: data.placeCode,
      placeOther: data.placeOther || '',
      repeat: 0,
      targetFormatMonth,
      week,
      targetMonth,
      restTime: data.restTime ? data.restTime : 0,
      workTime: data.workTime ? data.workTime : 0,
      rested,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onTimeChange = (event) => {
    const { name, value } = event.target;
    let targetValue = value;
    if (value && value.length === 5) {
      const hour = value.slice(0, 2);
      const minute = value.slice(-2);
      targetValue = `${hour}:${Math.floor(minute / 15) * 15}`;
    }
    const { startTime, endTime, restData } = this.state;
    const start = name === 'startTime' ? targetValue : startTime;
    const end = name === 'endTime' ? targetValue : endTime;
    const restWorkData = getRestWorkTime(start, end, restData);
    this.setState({
      [name]: targetValue,
      restTime: restWorkData.restTime,
      workTime: restWorkData.workTime,
    });
  };

  onPlaceChange = (_, placeCode) => {
    if (!placeCode) {
      return;
    }
    this.setState({ placeCode });
  };

  onClickSave = () => {
    const {
      targetMonth, repeat, week, startTime, endTime, placeCode, restTime, workTime, placeOther,
    } = this.state;
    const {
      targetDateItem, syainId, onClickClose, restCalendars,
    } = this.props;
    const targetMonths = saveTargetDate({
      month: targetMonth,
      targetDate: targetDateItem.formatDate,
      type: repeat,
      fnsWeek: week,
      rests: restCalendars,
    });
    this.props.dispatch(
      workPlanActions.saveWorkPlan({
        syainId,
        targetMonths,
        startTime,
        endTime,
        placeCode,
        placeOther,
        workTime,
        restTime,
      }),
    );
    onClickClose();
  };

  saveDisabled() {
    const {
      startTime, endTime, placeCode, placeOther,
    } = this.state;
    if (!startTime || !endTime || !placeCode) {
      return true;
    }
    if (placeCode === '3' && !placeOther) {
      return true;
    }
    const start = new Date(`1999/12/31 ${startTime}`);
    const end = new Date(`1999/12/31 ${endTime}`);
    if (!isValid(start) || !isValid(end)) {
      return true;
    }
    if (isBefore(end, start)) {
      return true;
    }
    return false;
  }

  render() {
    const { onClickClose, isOpen } = this.props;
    const {
      targetFormatDate, startTime, endTime, placeCode, repeat,
      targetFormatMonth, week, restTime, workTime, rested, placeOther,
    } = this.state;

    return (
      <Dialog
        onClose={onClickClose}
        isOpen={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {`${targetFormatDate}の予定`}
          <IconButton
            aria-label="close"
            onClick={onClickClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <FormControl fullWidth>
              <Select
                value={repeat}
                name="repeat"
                onChange={this.onChange}
              >
                <MenuItem value={0}>繰り返さない</MenuItem>
                {!rested && (<MenuItem value={1}>以降の毎週平日</MenuItem>)}
                <MenuItem value={2}>
                  以降の毎週
                  {week}
                  曜日
                </MenuItem>
              </Select>
              <FormHelperText>
                {targetFormatMonth}
                にのみ適用
              </FormHelperText>
            </FormControl>
          </Box>
          <Box display="flex" mb={2}>
            <Box mr={2} sx={{ width: '135px' }}>
              <TimePicker handleChange={this.onTimeChange} name="startTime" value={startTime} />
            </Box>
            <Box sx={{ width: '135px' }}>
              <TimePicker handleChange={this.onTimeChange} name="endTime" value={endTime} />
            </Box>
          </Box>
          <Box mb={2}>
            <ToggleButtonGroup
              color="primary"
              value={placeCode}
              exclusive
              onChange={this.onPlaceChange}
              aria-label="Platform"
            >
              <ToggleButton value="1">
                <BusinessIcon />
                本社
              </ToggleButton>
              <ToggleButton value="2">
                <HomeIcon />
                自宅
              </ToggleButton>
              <ToggleButton value="3">
                <OtherHousesIcon />
                その他
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {placeCode === '3' && (
              <TextField
                value={placeOther}
                name="placeOther"
                fullWidth
                placeholder="その他場所"
                onChange={this.onChange}
              />
            )}
          </Box>
          <Box display="flex" mt={2}>
            <Box>{`休憩 ${restTime}`}</Box>
            <Box ml={2}>{`作業 ${workTime}`}</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose} color="inherit">キャンセル</Button>
          <Button onClick={this.onClickSave} disabled={this.saveDisabled()}>保存</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  workPlanInfo: state.workPlanStore,
});

export default connect(mapStateToProps)(WorkPlanInputDialog);
