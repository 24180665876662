import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const dateNotRequiredValidation = (value) => {
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'seikyuNo':
      case 'status':
        return requiredValidation(value);
      case 'seikyuDt':
      case 'nyukinDt':
        return dateValidation(value);
      case 'workStartDate':
      case 'workEndDate':
        return dateNotRequiredValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
