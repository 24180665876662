import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
} from '@mui/material';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import DeleteDataTable from '../../../../components/templates/deleteDataTable/DeleteDataTable';
import { clientAgreementActions } from '../../../../redux/sales/client/clientAgreementState';
import ClientAgreementDialog from './ClientAgreementDialog';

class ClientAgreementList extends React.Component {
  constructor(props) {
    super(props);
    this.headCells = [
      { id: 'name', label: '契約名' },
      { id: 'conclusion_date', label: '締結日' },
      { id: 'remarks', label: '備考' },
    ];
    const { clientId } = this.props;
    const searchCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
      clientId,
    };

    this.search(searchCondition);
    this.state = {
      datas: {},
      isSuccessOpen: false,
      isDetailOpen: false,
      detailId: '',
      successMessage: '',
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contactInfo.datas !== this.props.contactInfo.datas) {
      if (this.props.contactInfo.datas) {
        this.setDatas(this.props.contactInfo.datas);
      }
    }
    if (prevProps.contactInfo.isDeleteSuccess
      !== this.props.contactInfo.isDeleteSuccess) {
      if (this.props.contactInfo.isDeleteSuccess) {
        this.setSuccess('削除が完了しました');
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.search(this.state.searchCondition);
    this.setState({ isDetailOpen: false, isSuccessOpen: true, successMessage });
  }

  onSaveSuccess = () => {
    this.setSuccess('保存が完了しました');
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(clientAgreementActions.getDatas(searchCondition));
  };

  onDelete = (ids) => {
    this.props.dispatch(clientAgreementActions.deleteData({ ids }));
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onClose = () => {
    this.setState({ isDetailOpen: false });
  };

  onDetailOpen = (_, row) => {
    this.setState({ isDetailOpen: true, isSuccessOpen: false, detailId: row ? row.id : null });
  };

  render() {
    const {
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isDetailOpen,
      detailId,
    } = this.state;
    const { isDisabled } = this.props;

    return (
      <Container maxWidth="xl">

        <DeleteDataTable
          rows={datas.content || []}
          headCells={this.headCells}
          procName="契約"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          onAddOpen={this.onDetailOpen}
          orderBy={searchCondition.orderBy}
          disabled={isDisabled}
          secondFuncText="編集する"
          onSecondFunc={this.onDetailOpen}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.onSnackbarClose}
          message={successMessage}
        />
        <ClientAgreementDialog
          isOpen={isDetailOpen}
          onClose={this.onClose}
          onSaveSuccess={this.onSaveSuccess}
          id={detailId}
          clientId={searchCondition.clientId}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  contactInfo: state.clientAgreementStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(ClientAgreementList);
