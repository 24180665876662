/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Table, TableContainer, IconButton, Select, MenuItem, Radio,
  TableHead, TableRow, TableBody, Tooltip, FormControl, RadioGroup, FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { addMonths, format } from 'date-fns';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { divisionBalanceActions } from '../../../redux/summary/divisionBalance/divisionBalanceState';
import {
  getMonths, getTargetYear, getData, getMonthSummary, getDivisionSummary, getSummary, getDiffData, getBetDiffSummary, getDiffArariSummary, getBumonArariRitsuAll,
  getDiffMonthSummary, getDiffDivisionSummary, getDiffSummary, getMonthBetSummary, getBetSummary, getBetDiffMonthSummary, getDiffArariMonthSummary, getCache,
  getArariData, getArariDiffData, getArariDivisionSummary, getDiffArariDivisionSummary, getArariMonthSummary, getArariSummary, getKansetsuData, getBumonData, getCacheAll,
  getKansetsuDivisionSummary, getKansetsuMonthSummary, getKansetsuSummary, getBumonArariPlan, getBumonDivisionPlanSummary, getBumonMonthPlanSummary, getBumonPlanSummary,
  getBumonDivisionSummary, getBumonMonthSummary, getBumonSummary, getBumonDiffData, getBumonDivisionDiffSummary, getBumonDiffSummary, getBumonMonthDiffSummary, getBumonArariRitsu,
} from './division-balance-proc';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';

const HeaderTd = styled('th')(() => ({
  border: 'solid 1px #DDD',
  fontSize: '13px',
}));

const DataTr = styled(TableRow)(({ index }) => ({
  ...(index % 2 === 0 && {
    background: '#f5f5f5',
  }),
}));

const TitleTd = styled('td')(({ isEnd, isNotTop }) => ({
  ...(!isEnd && {
    borderBottom: 'solid 1px #DDD',
  }),
  ...(!isNotTop && {
    borderTop: 'solid 1px #DDD',
  }),
  borderLeft: 'solid 1px #DDD',
  borderRight: 'solid 1px #DDD',
  fontSize: '15px',
  paddingRight: 3,
}));

const DataTd = styled('td')(({ isEnd, isNotTop }) => ({
  ...(!isEnd && {
    borderBottom: 'solid 1px #DDD',
  }),
  ...(!isNotTop && {
    borderTop: 'solid 1px #DDD',
  }),
  borderTop: 'solid 1px #DDD',
  borderLeft: 'solid 1px #DDD',
  borderRight: 'solid 1px #DDD',
  fontSize: '15px',
  textAlign: 'right',
  paddingRight: 3,
}));

class DivisionBalance extends React.Component {
  constructor(props) {
    super(props);
    const condition = getTargetYear();
    let searchCondition = {
      startMonth: condition.startMonth,
      endMonth: condition.endMonth,
    };

    if (props.divisionBalanceInfo.searchCondition) {
      searchCondition = props.divisionBalanceInfo.searchCondition;
    }
    this.state = {
      searchCondition,
      months: getMonths(searchCondition.startMonth, searchCondition.endMonth),
      balances: [],
      divisions: [],
      budgets: [],
      indirects: [],
      displayType: '0',
      select: {
        departments: [],
        divisions: [],
      },
    };
    this.search(searchCondition);
    props.dispatch(divisionBalanceActions.getSelect());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.divisionBalanceInfo.data !== this.props.divisionBalanceInfo.data) {
      if (this.props.divisionBalanceInfo.data) {
        this.setData(this.props.divisionBalanceInfo.data);
      }
    }
    if (prevProps.divisionBalanceInfo.select !== this.props.divisionBalanceInfo.select) {
      if (this.props.divisionBalanceInfo.select) {
        this.setSelect(this.props.divisionBalanceInfo.select);
      }
    }
  }

  setSelect = (select) => {
    this.setState({ select });
  };

  setData = (data) => {
    this.setState({
      balances: data.balances, divisions: data.divisions, budgets: data.budgets, indirects: data.indirects,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    this.search(searchCondition);
  };

  search = (searchCondition) => {
    if (!searchCondition.departmentId) {
      return;
    }
    this.props.dispatch(divisionBalanceActions.setSearchCondition(searchCondition));
    this.props.dispatch(divisionBalanceActions.getData(searchCondition));
  };

  onClickBefore = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.startMonth), -6), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.startMonth), -1), 'yyyy/MM/dd'),
    };
    this.setState({
      searchCondition: tmpCondition,
      months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth),
    });
    this.search(tmpCondition);
  };

  onClickAfter = () => {
    const { searchCondition } = this.state;
    const tmpCondition = {
      ...searchCondition,
      startMonth: format(addMonths(new Date(searchCondition.endMonth), 1), 'yyyy/MM/dd'),
      endMonth: format(addMonths(new Date(searchCondition.endMonth), 6), 'yyyy/MM/dd'),
    };
    this.setState({
      searchCondition: tmpCondition,
      months: getMonths(tmpCondition.startMonth, tmpCondition.endMonth),
    });
    this.search(tmpCondition);
  };

  onRadioChange = (event) => {
    const { value } = event.target;
    this.setState({ displayType: value });
  };

  render() {
    const {
      searchCondition, months, balances, select, divisions, budgets, displayType, indirects,
    } = this.state;

    return (
      <Container maxWidth={false}>
        <Box display="flex" mb={2}>
          <Box display="flex" alignItems="center">
            <Tooltip title="前へ">
              <IconButton color="primary" size="small" onClick={this.onClickBefore}>
                <ArrowBackIosIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">{format(new Date(searchCondition.startMonth), 'yyyy年MM月')}</Box>
          <Box display="flex" alignItems="center" mx={1}>～</Box>
          <Box display="flex" alignItems="center">{format(new Date(searchCondition.endMonth), 'yyyy年MM月')}</Box>
          <Box>
            <Tooltip title="次へ">
              <IconButton color="primary" size="small" onClick={this.onClickAfter}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <SearchSelectBox
              disableClearable
              name="departmentId"
              options={select.departments}
              value={searchCondition.departmentId}
              onChange={this.onChange}
            />
          </Box>
          <Box>
            <FormControl>
              <Select
                value={searchCondition.divisionId}
                name="divisionId"
                onChange={this.onChange}
              >
                <MenuItem value="">未設定</MenuItem>
                {select.divisions.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" ml={1}>
            <LoadingButton
              variant="contained"
              size="small"
              loading={this.props.common.isLoading}
              onClick={this.onSearch}
            >
              検索
            </LoadingButton>
          </Box>
        </Box>
        <Box mt={1}>
          <FormControl>
            <RadioGroup
              row
              onChange={this.onRadioChange}
              name="displayType"
              value={displayType}
            >
              <FormControlLabel value="0" control={<Radio size="small" />} label="千円" />
              <FormControlLabel value="1" control={<Radio size="small" />} label="実数" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mb={3}>
          <TableContainer sx={{ maxHeight: 800 }}>
            <Table
              width="100%"
              stickyHeader
              sx={{
                border: 'solid 1px #DDD',
                borderCollapse: 'collapse',
                textAlign: 'center',
              }}
            >
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  background: '#fff',
                }}
              >
                <TableRow>
                  <HeaderTd colSpan={4} />
                  {months.map((item) => (
                    <HeaderTd key={`${item.targetDate}s`}>{item.displayDate}</HeaderTd>
                  ))}
                  <HeaderTd>合計</HeaderTd>
                </TableRow>
              </TableHead>
              <TableBody>
                <React.Fragment id="uriage">
                  <TableRow>
                    <TitleTd isEnd rowSpan={divisions.length + (divisions.length > 1 ? 10 : 3)} style={{ width: '20px', padding: 3, background: '#FCE4D6' }}>売上</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}budgetProceed`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>予算</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}budgetProceed${item.targetDate}`}>
                          {getData(budgets, division.id, item.targetDate, 'budgetProceed', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(budgets, division.id, 'budgetProceed', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`budgetProceed${item.targetDate}Sum`}>
                          {getMonthSummary(budgets, item.targetDate, 'budgetProceed', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(budgets, 'budgetProceed', displayType)}</DataTd>
                    </TableRow>
                  )}
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}uriage`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}uriage${item.targetDate}`}>
                          {getData(balances, division.id, item.targetDate, 'uriage', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, division.id, 'uriage', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}Sum`}>
                          {getMonthSummary(balances, item.targetDate, 'uriage', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(balances, 'uriage', displayType)}</DataTd>
                    </TableRow>
                  )}
                  {divisions.map((division, index) => (
                    <DataTr hover key={`budgetProceed${division.id}uriage`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>差異</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}uriage${item.targetDate}budgetProceed`}>
                          {getDiffData(balances, budgets, division.id, item.targetDate, 'uriage', 'budgetProceed', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffDivisionSummary(balances, budgets, division.id, 'uriage', 'budgetProceed', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                          {getDiffMonthSummary(balances, budgets, item.targetDate, 'uriage', 'budgetProceed', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffSummary(balances, budgets, 'uriage', 'budgetProceed', displayType)}</DataTd>
                    </TableRow>
                  )}
                </React.Fragment>

                <React.Fragment id="genka">
                  <TableRow>
                    <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 25 : 9)} style={{ width: '20px', padding: 3, background: '#D9E1F2' }}>原価</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}budgetProperCost`}>
                      {index === 0 && (
                        <React.Fragment key={`${division.id}genkayosansyain`}>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 3 + divisions.length : 2)} style={{ width: '20px', padding: 2 }}>予算</TitleTd>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} style={{ width: '20px', padding: 2 }}>プロパ</TitleTd>
                        </React.Fragment>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}budgetProperCost${item.targetDate}`}>
                          {getData(budgets, division.id, item.targetDate, 'budgetProperCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(budgets, division.id, 'budgetProperCost', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`budgetProperCost${item.targetDate}Sum`}>
                          {getMonthSummary(budgets, item.targetDate, 'budgetProperCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(budgets, 'budgetProperCost', displayType)}</DataTd>
                    </TableRow>
                  )}
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}budgetOutsourceCost`}>
                      {index === 0 && (
                        <React.Fragment key={`${division.id}genkayosanout`}>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} style={{ width: '20px', padding: 2 }}>外注</TitleTd>
                        </React.Fragment>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}budgetOutsourceCost${item.targetDate}`}>
                          {getData(budgets, division.id, item.targetDate, 'budgetOutsourceCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(budgets, division.id, 'budgetOutsourceCost', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}Sum`}>
                          {getMonthSummary(budgets, item.targetDate, 'budgetOutsourceCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(budgets, 'budgetOutsourceCost', displayType)}</DataTd>
                    </TableRow>
                  )}

                  <TableRow hover>
                    <TitleTd colSpan={2} style={{ border: '2px solid #000' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`budgetProperCost${item.targetDate}Sum`} style={{ border: '2px solid #000' }}>
                        {getMonthBetSummary(budgets, item.targetDate, 'budgetProperCost', 'budgetOutsourceCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ border: '2px solid #000' }}>{getBetSummary(budgets, 'budgetProperCost', 'budgetOutsourceCost', displayType)}</DataTd>
                  </TableRow>

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}properGenka`}>
                      {index === 0 && (
                        <React.Fragment key={`${division.id}genkaresultsyain`}>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 3 + divisions.length : 2)} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} style={{ width: '20px', padding: 2 }}>プロパ</TitleTd>
                        </React.Fragment>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}properGenka${item.targetDate}`}>
                          {getData(balances, division.id, item.targetDate, 'properGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, division.id, 'properGenka', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`properGenka${item.targetDate}Sum`}>
                          {getMonthSummary(balances, item.targetDate, 'properGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(balances, 'properGenka', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}outsourceGenka`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} style={{ width: '20px', padding: 2 }}>外注</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}outsourceGenka${item.targetDate}`}>
                          {getData(balances, division.id, item.targetDate, 'outsourceGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, division.id, 'outsourceGenka', displayType)}</DataTd>
                    </DataTr>
                  ))}

                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`outsourceGenka${item.targetDate}Sum`}>
                          {getMonthSummary(balances, item.targetDate, 'outsourceGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(balances, 'outsourceGenka', displayType)}</DataTd>
                    </TableRow>
                  )}

                  <TableRow hover>
                    <TitleTd colSpan={2} style={{ border: '2px solid #000' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`outsourceGenka${item.targetDate}Sum`} style={{ border: '2px solid #000' }}>
                        {getMonthBetSummary(balances, item.targetDate, 'outsourceGenka', 'properGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ border: '2px solid #000' }}>{getBetSummary(balances, 'outsourceGenka', 'properGenka', displayType)}</DataTd>
                  </TableRow>

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}properGenka`}>
                      {index === 0 && (
                        <React.Fragment key={`${division.id}genkaresultsyain`}>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 3 + divisions.length : 2)} style={{ width: '20px', padding: 2 }}>差異</TitleTd>
                          <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} style={{ width: '20px', padding: 2 }}>プロパ</TitleTd>
                        </React.Fragment>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}properGenka${item.targetDate}`}>
                          {getDiffData(balances, budgets, division.id, item.targetDate, 'properGenka', 'budgetProperCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffDivisionSummary(balances, budgets, division.id, 'properGenka', 'budgetProperCost', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`properGenka${item.targetDate}Sum`}>
                          {getDiffMonthSummary(balances, budgets, item.targetDate, 'properGenka', 'budgetProperCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffSummary(balances, budgets, 'properGenka', 'budgetProperCost', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}outsourceGenka`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} style={{ width: '20px', padding: 2 }}>外注</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}outsourceGenka${item.targetDate}`}>
                          {getDiffData(balances, budgets, division.id, item.targetDate, 'outsourceGenka', 'budgetOutsourceCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffDivisionSummary(balances, budgets, division.id, 'outsourceGenka', 'budgetOutsourceCost', displayType)}</DataTd>
                    </DataTr>
                  ))}

                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`outsourceGenka${item.targetDate}Sum`}>
                          {getDiffMonthSummary(balances, budgets, item.targetDate, 'outsourceGenka', 'budgetOutsourceCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffSummary(balances, budgets, 'outsourceGenka', 'budgetOutsourceCost', displayType)}</DataTd>
                    </TableRow>
                  )}

                  <TableRow hover>
                    <TitleTd colSpan={2} style={{ border: '2px solid #000' }}>計</TitleTd>
                    {months.map((item) => (
                      <DataTd key={`outsourceGenka${item.targetDate}Sum`} style={{ border: '2px solid #000' }}>
                        {getBetDiffMonthSummary(balances, budgets, item.targetDate, 'outsourceGenka', 'budgetOutsourceCost', 'properGenka', 'budgetProperCost', displayType)}
                      </DataTd>
                    ))}
                    <DataTd style={{ border: '2px solid #000' }}>{getBetDiffSummary(balances, budgets, 'outsourceGenka', 'budgetOutsourceCost', 'properGenka', 'budgetProperCost', displayType)}</DataTd>
                  </TableRow>
                </React.Fragment>

                <React.Fragment id="shikakari">
                  <TableRow>
                    <TitleTd isEnd rowSpan={divisions.length > 1 ? divisions.length + 2 : 2} style={{ width: '20px', padding: 3, background: '#FFF2CC' }}>仕掛</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}shikakari`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length > 1 ? divisions.length + 1 : 1} colSpan={2} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}shikakari${item.targetDate}`}>
                          {getData(balances, division.id, item.targetDate, 'shikakari', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, division.id, 'shikakari', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`shikakari${item.targetDate}Sum`}>
                          {getMonthSummary(balances, item.targetDate, 'shikakari', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(balances, 'shikakari', displayType)}</DataTd>
                    </TableRow>
                  )}
                </React.Fragment>

                <React.Fragment id="arari">
                  <TableRow>
                    <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 10 : 3)} style={{ width: '20px', padding: 3, background: '#FFFD9B' }}>粗利</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}budgetProperCost`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>予算</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}budgetProperCost${item.targetDate}`}>
                          {getArariData(budgets, division.id, item.targetDate, 'budgetProceed', 'budgetCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getArariDivisionSummary(budgets, division.id, 'budgetProceed', 'budgetCost', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`budgetProperCost${item.targetDate}Sum`}>
                          {getArariMonthSummary(budgets, item.targetDate, 'budgetProceed', 'budgetCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getArariSummary(budgets, 'budgetProceed', 'budgetCost', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}properGenka`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}properGenka${item.targetDate}`}>
                          {getArariData(balances, division.id, item.targetDate, 'uriage', 'allGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getArariDivisionSummary(balances, division.id, 'uriage', 'allGenka', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`properGenka${item.targetDate}Sum`}>
                          {getArariMonthSummary(balances, item.targetDate, 'uriage', 'allGenka', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getArariSummary(balances, 'uriage', 'allGenka', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}properGenka`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 2 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>差異</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}properGenka${item.targetDate}`}>
                          {getArariDiffData(balances, budgets, division.id, item.targetDate, 'uriage', 'allGenka', 'budgetProceed', 'budgetCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffArariDivisionSummary(balances, budgets, division.id, 'uriage', 'allGenka', 'budgetProceed', 'budgetCost', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`properGenka${item.targetDate}Sum`}>
                          {getDiffArariMonthSummary(balances, budgets, item.targetDate, 'uriage', 'allGenka', 'budgetProceed', 'budgetCost', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffArariSummary(balances, budgets, 'uriage', 'allGenka', 'budgetProceed', 'budgetCost', displayType)}</DataTd>
                    </TableRow>
                  )}
                </React.Fragment>

                <React.Fragment id="ariritsu">
                  <TableRow>
                    <TitleTd isEnd rowSpan={divisions.length + (divisions.length > 1 ? 2 : 1)} style={{ width: '20px', padding: 3, background: '#FFCCFF' }}>粗利率</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}arariRitsu`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}arariRitsu${item.targetDate}`}>
                          {getData(balances, division.id, item.targetDate, 'arariRitsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(balances, division.id, 'arariRitsu', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`arariRitsu${item.targetDate}Sum`}>
                          {getMonthSummary(balances, item.targetDate, 'arariRitsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(balances, 'arariRitsu', displayType)}</DataTd>
                    </TableRow>
                  )}
                </React.Fragment>

                <React.Fragment id="kansetsu">
                  <TableRow>
                    <TitleTd isEnd rowSpan={divisions.length + (divisions.length > 1 ? 14 : 4)} style={{ width: '20px', padding: 3, background: '#D6DCE4' }}>間接</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}budgetIndirect`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>予算</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}budgetIndirect${item.targetDate}`}>
                          {getData(budgets, division.id, item.targetDate, 'budgetIndirect', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(budgets, division.id, 'budgetIndirect', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`budgetIndirect${item.targetDate}Sum`}>
                          {getMonthSummary(budgets, item.targetDate, 'budgetIndirect', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(budgets, 'budgetIndirect', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}kansetsu`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}kansetsu${item.targetDate}`}>
                          {getData(indirects, division.id, item.targetDate, 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDivisionSummary(indirects, division.id, 'kansetsu', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`kansetsu${item.targetDate}Sum`}>
                          {getMonthSummary(indirects, item.targetDate, 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getSummary(indirects, 'kansetsu', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`budgetProceed${division.id}uriage`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>差異</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}uriage${item.targetDate}budgetProceed`}>
                          {getDiffData(indirects, budgets, division.id, item.targetDate, 'kansetsu', 'budgetIndirect', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffDivisionSummary(indirects, budgets, division.id, 'kansetsu', 'budgetIndirect', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                          {getDiffMonthSummary(indirects, budgets, item.targetDate, 'kansetsu', 'budgetIndirect', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getDiffSummary(indirects, budgets, 'kansetsu', 'budgetIndirect', displayType)}</DataTd>
                    </TableRow>
                  )}

                  {divisions.map((division, index) => (
                    <DataTr hover key={`budgetProceed${division.id}uriage`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>間接率</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}uriage${item.targetDate}budgetProceed`}>
                          {getKansetsuData(indirects, balances, division.id, item.targetDate, 'kansetsu', 'properGenka')}
                        </DataTd>
                      ))}
                      <DataTd>{getKansetsuDivisionSummary(indirects, balances, division.id, 'kansetsu', 'properGenka')}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                          {getKansetsuMonthSummary(indirects, balances, item.targetDate, 'kansetsu', 'properGenka')}
                        </DataTd>
                      ))}
                      <DataTd>{getKansetsuSummary(indirects, balances, 'kansetsu', 'properGenka')}</DataTd>
                    </TableRow>
                  )}
                </React.Fragment>

                <React.Fragment id="bumonarari">
                  <TableRow>
                    <TitleTd isEnd rowSpan={divisions.length + (divisions.length > 1 ? 10 : 3)} style={{ width: '20px', padding: 3, background: '#E2EFDA' }}>部門粗利</TitleTd>
                  </TableRow>
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}budgetProceed`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>予算</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}budgetProceed${item.targetDate}`}>
                          {getBumonArariPlan(budgets, division.id, item.targetDate, 'budgetProceed', 'budgetCost', 'budgetIndirect', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getBumonDivisionPlanSummary(budgets, division.id, 'budgetProceed', 'budgetCost', 'budgetIndirect', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`budgetProceed${item.targetDate}Sum`}>
                          {getBumonMonthPlanSummary(budgets, item.targetDate, 'budgetProceed', 'budgetCost', 'budgetIndirect', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getBumonPlanSummary(budgets, 'budgetProceed', 'budgetCost', 'budgetIndirect', displayType)}</DataTd>
                    </TableRow>
                  )}
                  {divisions.map((division, index) => (
                    <DataTr hover key={`${division.id}uriage`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>実績</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}uriage${item.targetDate}`}>
                          {getBumonData(balances, indirects, division.id, item.targetDate, 'uriage', 'allGenka', 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getBumonDivisionSummary(balances, indirects, division.id, 'uriage', 'allGenka', 'kansetsu', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}Sum`}>
                          {getBumonMonthSummary(balances, indirects, item.targetDate, 'uriage', 'allGenka', 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getBumonSummary(balances, indirects, 'uriage', 'allGenka', 'kansetsu', displayType)}</DataTd>
                    </TableRow>
                  )}
                  {divisions.map((division, index) => (
                    <DataTr hover key={`budgetProceed${division.id}uriage`}>
                      {index === 0 && (
                        <TitleTd rowSpan={divisions.length + (divisions.length > 1 ? 1 : 0)} colSpan={2} style={{ width: '20px', padding: 2 }}>差異</TitleTd>
                      )}
                      <TitleTd style={{ width: '100px' }}>{division.name}</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`${division.id}uriage${item.targetDate}budgetProceed`}>
                          {getBumonDiffData(balances, indirects, division.id, item.targetDate, 'uriage', 'allGenka', 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getBumonDivisionDiffSummary(balances, indirects, division.id, 'uriage', 'allGenka', 'kansetsu', displayType)}</DataTd>
                    </DataTr>
                  ))}
                  {divisions.length > 1 && (
                    <TableRow hover>
                      <TitleTd>計</TitleTd>
                      {months.map((item) => (
                        <DataTd key={`uriage${item.targetDate}budgetProceed`}>
                          {getBumonMonthDiffSummary(balances, indirects, item.targetDate, 'uriage', 'allGenka', 'kansetsu', displayType)}
                        </DataTd>
                      ))}
                      <DataTd>{getBumonDiffSummary(balances, indirects, 'uriage', 'allGenka', 'kansetsu', displayType)}</DataTd>
                    </TableRow>
                  )}
                </React.Fragment>

                <React.Fragment id="bumonarari">
                  <TableRow>
                    <TitleTd isEnd style={{ width: '20px', padding: 3 }} rowSpan={2} colSpan={4}>部門粗利率</TitleTd>
                  </TableRow>
                  <DataTr hover>
                    {months.map((item) => (
                      <DataTd key={`budgetProceed${item.targetDate}`}>
                        {getBumonArariRitsu(balances, item.targetDate, 'uriage', 'allGenka', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getBumonArariRitsuAll(balances, 'uriage', 'allGenka', displayType)}</DataTd>
                  </DataTr>
                </React.Fragment>

                <React.Fragment id="cache">
                  <TableRow>
                    <TitleTd isEnd style={{ width: '20px', padding: 3 }} rowSpan={2} colSpan={4}>キャッシュ</TitleTd>
                  </TableRow>
                  <DataTr hover>
                    {months.map((item) => (
                      <DataTd key={`getCache${item.targetDate}`}>
                        {getCache(balances, indirects, item.targetDate, 'uriage', 'allGenka', 'kansetsu', displayType)}
                      </DataTd>
                    ))}
                    <DataTd>{getCacheAll(balances, indirects, 'uriage', 'allGenka', 'kansetsu', displayType)}</DataTd>
                  </DataTr>
                </React.Fragment>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  divisionBalanceInfo: state.divisionBalanceStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(DivisionBalance);
