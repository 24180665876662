/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const expenseRequestListSlice = createSlice({
  name: 'expenseRequestList',
  initialState: {
    data: {},
    searchCondition: null,
    isDeleteSuccess: false,
    codes: [],
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getData: (state) => {
      state.data = {};
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
  },
});

export const expenseRequestListActions = expenseRequestListSlice.actions;

export default expenseRequestListSlice.reducer;
