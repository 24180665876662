import React from 'react';
import { addMonths, format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import {
  DialogContent, LinearProgress,
  DialogActions, ToggleButton,
  Box, ToggleButtonGroup,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MaterialDialog from '../../../components/atoms/materialDialog/MaterialDialog';
import DialogColorTitle from '../../../components/atoms/dialogColorTitle/DialogColorTitle';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { trafficRequestDetailActions } from '../../../redux/request/trafficRequest/trafficRequestDetailState';

export default function CreateTrafficDialog(props) {
  const userInfo = useSelector((state) => state.loginStore.userInfo).user;
  const initData = useSelector((state) => state.trafficRequestDetailStore.initData);
  const [options, setOptions] = React.useState([]);
  const [map, setMap] = React.useState(new Map());
  const [targetMonth, setTargetMonth] = React.useState(format(addMonths(new Date(), 0), 'yyyy/MM'));
  const [prePay, setPrePay] = React.useState('0');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const {
    open,
    onClose,
  } = props;

  React.useEffect(() => {
    if (initData) {
      setLoading(false);
      const tempOptions = [];
      const tempMap = new Map();
      initData.forEach((c) => {
        tempOptions.push({ id: c.targetDate, name: c.targetDate });
        tempMap.set(c.targetDate, c.key);
      });
      setOptions(tempOptions);
      setMap(tempMap);
    }
  }, [initData]);
  React.useEffect(() => {
    if (open) {
      dispatch(trafficRequestDetailActions.initData());
      setLoading(true);
    }
  }, [open]);

  const onClick = () => {
    if (!prePay || !targetMonth) {
      return;
    }
    setLoading(true);
    dispatch(trafficRequestDetailActions.createData({
      syainId: userInfo.id,
      targetMonth,
      prePay: prePay === '1',
    }));
  };

  return (
    <MaterialDialog open={open} onClose={onClose}>
      <DialogColorTitle>交通費新規申請</DialogColorTitle>
      {loading && (
        <LinearProgress />
      )}
      <DialogContent dividers>
        <Box>
          <FormTitle title="名前" />
          <Box>{userInfo.name}</Box>
        </Box>
        <Box mt={2}>
          <FormTitle title="対象月" isRequired />
          <SearchSelectBox options={options} disableClearable onChange={(e) => setTargetMonth(e.target.value)} name="targetMonth" value={targetMonth} />
        </Box>
        {map.get(targetMonth) !== '0' && (
          <Box mt={2}>
            <FormTitle title="種別" isRequired />
            <ToggleButtonGroup
              color="primary"
              value={prePay}
              exclusive
              onChange={(_, value) => setPrePay(value)}
              aria-label="Platform"
            >
              {map.get(targetMonth) !== '1' && (
                <ToggleButton value="0">通常</ToggleButton>
              )}
              {map.get(targetMonth) !== '3' && (
                <ToggleButton value="1">前払</ToggleButton>
              )}
            </ToggleButtonGroup>
          </Box>
        )}

      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={loading} color="inherit">キャンセル</LoadingButton>
        <LoadingButton
          onClick={onClick}
          loading={loading}
          color="primary"
          disabled={!prePay || !targetMonth}
        >
          作成する
        </LoadingButton>
      </DialogActions>
    </MaterialDialog>
  );
}
