import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import Dialog from '../../../../components/atoms/dialog/Dialog';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import { clientAgreementActions } from '../../../../redux/sales/client/clientAgreementState';
import DatePicker from '../../../../components/atoms/datePicker/DatePicker';
import FreeSoloBox from '../../../../components/atoms/freeSoloBox/FreeSoloBox';
import TextArea from '../../../../components/atoms/textArea/TextArea';

const tmpForm = {
  id: '',
  trnClientId: '',
  name: '',
  conclusionDate: '',
  remarks: '',
};

export default function ClientAgreementDialog(props) {
  const [form, setForm] = React.useState(tmpForm);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, clientId, id, onSaveSuccess,
  } = props;
  const data = useSelector((state) => state.clientAgreementStore.data);
  const isSaveSuccess = useSelector((state) => state.clientAgreementStore.isSaveSuccess);
  const names = useSelector((state) => state.clientAgreementStore.names);
  const [select, setSelect] = React.useState([]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      dispatch(clientAgreementActions.resetFlg());
      onSaveSuccess();
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (names) {
      setSelect(names);
    }
  }, [names]);
  React.useEffect(() => {
    if (isOpen) {
      if (id) {
        dispatch(
          clientAgreementActions.getData({ id }),
        );
      }
      dispatch(
        clientAgreementActions.getNames(),
      );
      setForm({
        ...tmpForm,
        id,
      });
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      const tempData = {
        ...data,
      };
      setForm(tempData);
    }
  }, [data]);

  const onClickSave = () => {
    if (!form.name) {
      return;
    }

    dispatch(clientAgreementActions.saveData({ ...form, trnClientId: clientId }));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>契約</DialogTitle>
      <DialogContent dividers>
        <Box>
          <FormTitle title="契約名" isRequired />
          <FreeSoloBox
            name="name"
            disableClearable
            options={select}
            value={form.name}
            onChange={onChange}
          />
        </Box>
        <Box mt={1}>
          <FormTitle title="締結日" />
          <DatePicker
            handleChange={onChange}
            name="conclusionDate"
            value={form.conclusionDate}
          />
        </Box>
        <Box mt={1}>
          <FormTitle title="備考" />
          <TextArea
            value={form.remarks}
            name="remarks"
            onChange={onChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onClickSave} disabled={!form.name}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
