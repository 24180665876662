/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workResultSlice = createSlice({
  name: 'workResult',
  initialState: {
    searchCondition: null,
    data: {},
    isRequestSuccess: false,
    isApprovalSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.data = { datas: [], summary: {} };
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateRequest: (state) => {
      state.isRequestSuccess = false;
    },
    updateRequestSuccess: (state) => {
      state.isRequestSuccess = true;
    },
    updateApproval: (state) => {
      state.isApprovalSuccess = false;
    },
    updateApprovalSuccess: (state) => {
      state.isApprovalSuccess = true;
    },
  },
});

export const workResultActions = workResultSlice.actions;

export default workResultSlice.reducer;
