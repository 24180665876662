/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const approvalHistorySlice = createSlice({
  name: 'approvalHistory',
  initialState: {
    data: {},
  },
  reducers: {
    getDatas: (state) => {
      state.data = null;
    },
    getDatasSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const approvalHistoryActions = approvalHistorySlice.actions;

export default approvalHistorySlice.reducer;
