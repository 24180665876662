import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Container,
} from '@mui/material';
import { format } from 'date-fns';
import DataListTable from './DataListTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { trafficRequestListActions } from '../../../redux/request/trafficRequest/trafficRequestListState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import SelectSyainWithMonth from '../../../components/organisms/selectSyainWithMonth/SelectSyainWithMonth';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import { checkIsDisabled } from '../../../utils/authCheck.helper';

class TrafficRequestList extends React.Component {
  constructor(props) {
    super(props);

    let searchCondition = {
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
      syainId: props.loginUser.userInfo.user.id,
      status: null,
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    if (props.trafficRequest.searchCondition) {
      searchCondition = props.trafficRequest.searchCondition;
    }

    this.search(searchCondition);
    this.state = {
      codes: [],
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
      isLoading: true,
      isBp: props.loginUser.userInfo.user.bp,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    this.props.dispatch(trafficRequestListActions.getCodes());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trafficRequest.codes !== this.props.trafficRequest.codes) {
      if (this.props.trafficRequest.codes) {
        this.setCodes(this.props.trafficRequest.codes);
      }
    }
    if (prevProps.trafficRequest.data !== this.props.trafficRequest.data) {
      if (this.props.trafficRequest.data) {
        this.setDatas(this.props.trafficRequest.data);
      }
    }
    if (prevProps.trafficRequest.isDeleteSuccess
      !== this.props.trafficRequest.isDeleteSuccess) {
      if (this.props.trafficRequest.isDeleteSuccess) {
        this.setSuccess('削除処理が完了しました');
      }
    }
  }

  setCodes(codes) {
    this.setState({ codes });
  }

  setDatas(datas) {
    this.setState({ datas, isLoading: false });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage, isLoading: false });
  }

  onProcStart = () => {
    this.setState({ isLoading: true, isSuccessOpen: false });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSyainChange = (event) => {
    const { syainId, targetMonth } = event;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      syainId,
      targetMonth,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition, isLoading: true });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(trafficRequestListActions.setSearchCondition(searchCondition));
    this.props.dispatch(trafficRequestListActions.getDatas(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      codes,
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
      isLoading,
      isBp,
      isDisabled,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <SearchBox onSearch={this.onSearch}>
          <SelectSyainWithMonth isNotSyain={isDisabled} isBp={isBp} targetMonth={searchCondition.targetMonth} syainId={searchCondition.syainId} onChange={this.onSyainChange} syainLabel="名前" monthLabel="対象月" />
          <Grid container mt={2}>
            <Grid item xs={12} md={6}>
              <FormTitle title="状況" />
              <SearchSelectBox options={codes} onChange={this.onChange} name="status" value={searchCondition.status} />
            </Grid>
          </Grid>
        </SearchBox>

        <DataListTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
          loading={isLoading}
          onProcStart={this.onProcStart}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  trafficRequest: state.trafficRequestListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(TrafficRequestList);
