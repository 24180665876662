/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { passwordActions } from './passwordState';
import { saveData } from '../../../services/base.service';

function* updatePassword({ payload }) {
  try {
    yield call(saveData, REST_API.PROFILE.PASSWORD, payload);
    yield put(passwordActions.updatePasswordSuccess());
  } catch (_) {}
}

function* passwordSaga() {
  yield takeLatest('password/updatePassword', updatePassword);
}

export default passwordSaga;
