export function getIndex(value, arr) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] === value) {
      return i;
    }
  }
  return -1;
}

export function getIncludes(value, arr) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] === value) {
      return true;
    }
  }
  return false;
}

export function getDatas(syains, selected) {
  let retData = '';
  for (let i = 0; i < selected.length; i += 1) {
    for (let j = 0; j < syains.length; j += 1) {
      if (selected[i] === syains[j].syainId) {
        if (syains[j].mail) {
          retData += `${syains[j].mail},`;
        }
      }
    }
  }
  if (retData) {
    retData = retData.slice(0, -1);
  }
  return retData;
}
