import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Button, Backdrop, CircularProgress,
  FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { costCloseActions } from '../../redux/close/costClose/costCloseState';

class CostClose extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: '',
      isSucceess: false,
      isLoading: false,
      form: {
        checkOne: false,
        checkTwo: false,
        checkThree: false,
        checkFour: false,
      },
    };
    props.dispatch(costCloseActions.getDatas());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.costCloseInfo.data !== this.props.costCloseInfo.data) {
      if (this.props.costCloseInfo.data) {
        this.setDatas(this.props.costCloseInfo.data);
      }
    }
    if (prevProps.costCloseInfo.isUpdateSuccess !== this.props.costCloseInfo.isUpdateSuccess) {
      if (this.props.costCloseInfo.isUpdateSuccess) {
        this.setSuccess();
      }
    }
  }

  setDatas(target) {
    this.setState({ target });
  }

  setSuccess = () => {
    this.setState({ isSucceess: true });
  };

  onClick = () => {
    this.props.dispatch(costCloseActions.updateData());
  };

  onChange = (event) => {
    const { name, checked } = event.target;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: checked,
      },
    });
  };

  render() {
    const {
      target, isSucceess, isLoading, form,
    } = this.state;

    if (isLoading) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return (
      <Container maxWidth="xl">
        {!isSucceess && (
          <Box mt={2}>
            <Box>{`${target}分の経費締めを行います。元に戻すことはできません。`}</Box>
            <Box>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={form.checkOne} name="checkOne" onChange={this.onChange} />} label="対象月の給料計算が完了している" />
                <FormControlLabel control={<Checkbox checked={form.checkTwo} name="checkTwo" onChange={this.onChange} />} label="対象月の経費全て取込が完了している" />
                <FormControlLabel control={<Checkbox checked={form.checkThree} name="checkThree" onChange={this.onChange} />} label="BPの支払請求入力が完了している" />
                <FormControlLabel control={<Checkbox checked={form.checkFour} name="checkFour" onChange={this.onChange} />} label="対象月の売上締めが完了している" />
              </FormGroup>
            </Box>
            <Box mt={2}>
              <Button variant="contained" onClick={this.onClick} disabled={!form.checkOne || !form.checkTwo || !form.checkThree || !form.checkFour}>実行</Button>
            </Box>
          </Box>
        )}
        {isSucceess && (
          <Box textAlign="center" mt={2}>経費締め処理が完了しました。万が一誤って締め処理を行った場合、システム管理者にお問合せください。</Box>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  costCloseInfo: state.costCloseStore,
});

export default connect(mapStateToProps)(CostClose);
