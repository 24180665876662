/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { salaryListActions } from './salaryListState';
import { getDatas, deleteDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.SALARY.SALARY, payload);
    yield put(salaryListActions.getDatasSuccess(result));
  } catch (_) {}
}

function* getCsvData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OFFICE.SALARY.SALARY_CSV, payload);
    yield put(salaryListActions.getCsvDataSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.OFFICE.SALARY.SALARY, payload);
    yield put(salaryListActions.deleteDataSuccess());
  } catch (_) {}
}

function* salaryListSaga() {
  yield takeLatest('salaryList/getDatas', getData);
  yield takeLatest('salaryList/getCsvData', getCsvData);
  yield takeLatest('salaryList/deleteData', deleteData);
}

export default salaryListSaga;
