import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urls from './constants/frontUrls';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Layout from './pages/Layout';
import Auth from './pages/Auth';

import ServerErrorProc from './pages/common/ServerErrorProc';
import ApplicationError from './pages/common/ApplicationError';

import ChangePassword from './pages/profile/password/ChangePassword';
import InitPassword from './pages/profile/password/InitPassword';
import UserSetting from './pages/profile/userSetting/UserSetting';

const screens = [
  {
    name: urls.COMMON.HOME.name, path: urls.COMMON.HOME.url, component: <Home />,
  },
  {
    name: urls.PROFILE.PASSWORD.name,
    path: urls.PROFILE.PASSWORD.url,
    component: <ChangePassword />,
  },
  {
    name: urls.PROFILE.USER_SETTING.name,
    path: urls.PROFILE.USER_SETTING.url,
    component: <UserSetting />,
  },
  {
    name: urls.COMMON.SYSTEM_ERROR.name,
    path: urls.COMMON.SYSTEM_ERROR.url,
    component: <ApplicationError />,
  },
];

function App() {
  return (
    <>
      <ServerErrorProc />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/init-password" element={<Auth><InitPassword /></Auth>} />
        {screens.map((screen) => (
          <Route
            path={screen.path}
            key={screen.name}
            element={
              <Auth><Layout>{screen.component}</Layout></Auth>
          }
          />
        ))}
        {urls.REQUESTS
          .concat(urls.WORKS)
          .concat(urls.SALES).concat(urls.OFFICE).concat(urls.SETTING)
          .concat(urls.MASTER)
          .concat(urls.SUMMARY)
          .concat(urls.FILE)
          .concat(urls.CLOSE)
          .map((screen) => (
            <Route
              path={screen.url}
              key={screen.name}
              element={
                <Auth><Layout>{screen.component}</Layout></Auth>
            }
            />
          ))}
      </Routes>
    </>
  );
}

export default App;
