import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DialogTitle, DialogContent, DialogActions, Box, TextField, LinearProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../../components/atoms/dialog/Dialog';
import { fixedExpenseActions } from '../../../redux/office/fixedExpense/fixedExpenseState';
import FormTitle from '../../../components/atoms/formTitle/FormTitle';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Validation from './validation';
import MoneyInput from '../../../components/atoms/moneyInput/MoneyInput';
import DatePicker from '../../../components/atoms/datePicker/DatePicker';

const tmpForm = {
  id: '',
  subjectCode: '',
  subjectSubCode: '',
  subjectName: '',
  cost: '',
  periodFrom: '',
  periodTo: '',
};
const tmpMessage = {
  subjectCode: '',
  subjectSubCode: '',
  subjectName: '',
  cost: '',
  periodFrom: '',
  periodTo: '',
};

export default function FixedExpenseDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState(tmpMessage);
  const [form, setForm] = React.useState(tmpForm);
  const data = useSelector((state) => state.fixedExpenseStore.data);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const dispatch = useDispatch();
  const {
    isOpen, onClose, id, isDisabled,
  } = props;

  React.useEffect(() => {
    if (isOpen) {
      setErrorMessage(tmpMessage);
      if (id) {
        dispatch(
          fixedExpenseActions.getData({ id }),
        );
      } else {
        setForm(tmpForm);
      }
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);

    if (!tempMessage.periodFrom && !tempMessage.periodTo) {
      const msg = Validation.compareDateValidate(form.periodFrom, form.periodTo);
      if (msg) {
        isError = true;
      }
      tempMessage.sagyoEdDt = msg;
    }

    if (isError) {
      return;
    }

    dispatch(fixedExpenseActions.saveData(form));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>固定経費詳細</DialogTitle>
      <LinearProgress style={{ display: isLoading ? '' : 'none' }} />
      <DialogContent dividers style={{ display: isLoading ? 'none' : '' }}>
        <Box display="flex">
          <Box>
            <FormTitle title="コード" isRequired />
            <TextField value={form.subjectCode} name="subjectCode" error={errorMessage.subjectCode !== ''} inputProps={{ maxLength: 3 }} onChange={onChange} />
            <FormErrorText>{errorMessage.subjectCode}</FormErrorText>
          </Box>
          <Box ml={2}>
            <FormTitle title="サブコード" />
            <TextField value={form.subjectSubCode} name="subjectSubCode" inputProps={{ maxLength: 3 }} onChange={onChange} />
          </Box>
        </Box>
        <Box mt={2}>
          <FormTitle title="名前" isRequired />
          <TextField value={form.subjectName} fullWidth name="subjectName" error={errorMessage.subjectName !== ''} inputProps={{ maxLength: 50 }} onChange={onChange} />
          <FormErrorText>{errorMessage.subjectName}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="金額" isRequired />
          <MoneyInput value={form.cost} name="cost" error={errorMessage.cost !== ''} onChange={onChange} />
          <FormErrorText>{errorMessage.cost}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="適用開始" isRequired />
          <DatePicker
            handleChange={onChange}
            name="periodFrom"
            value={form.periodFrom}
            error={errorMessage.periodFrom !== ''}
          />
          <FormErrorText>{errorMessage.periodFrom}</FormErrorText>
        </Box>
        <Box mt={2}>
          <FormTitle title="適用終了" isRequired />
          <DatePicker
            handleChange={onChange}
            name="periodTo"
            value={form.periodTo}
            error={errorMessage.periodTo !== ''}
          />
          <FormErrorText>{errorMessage.periodTo}</FormErrorText>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} loading={isLoading} color="inherit">キャンセル</LoadingButton>
        <LoadingButton
          onClick={onClickSave}
          loading={isLoading}
          disabled={isDisabled}
        >
          保存
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
