/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const roleListSlice = createSlice({
  name: 'roleList',
  initialState: {
    data: {},
    isDeleteSuccess: false,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getData: (state) => {
      state.data = {};
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
  },
});

export const roleListActions = roleListSlice.actions;

export default roleListSlice.reducer;
