import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const dateRequiredValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const yearValidation = (value, kbn) => {
  if (kbn === '3' && !value) return '保守の場合は必須入力です';
  return '';
};

const orderDateValidation = (value, status) => {
  if (status === '1' && !value) return '受注の場合は必須入力です';
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value, kbn, status) => {
    switch (type) {
      case 'chumonNo':
      case 'chumonStatus':
      case 'projectId':
      case 'taxType':
        return requiredValidation(value);
      case 'sagyoStDt':
      case 'sagyoEdDt':
        return dateRequiredValidation(value);
      case 'filingOn':
      case 'estimateDate':
      case 'deliveryDate':
        return dateValidation(value);
      case 'contractYears':
        return yearValidation(value, kbn);
      case 'orderDate':
        return orderDateValidation(value, status);
      default:
        return '';
    }
  };

  static compareDateValidate = (valueStart, valueEnd) => {
    if (valueStart > valueEnd) return '開始より未来を設定してください';
    return '';
  };
}

export default Validation;
