/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const workDeviationSlice = createSlice({
  name: 'workDeviation',
  initialState: {
    datas: [],
  },
  reducers: {
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.datas = action.payload;
    },
  },
});

export const workDeviationActions = workDeviationSlice.actions;

export default workDeviationSlice.reducer;
