import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog, Box, AppBar, Toolbar, IconButton, Typography, Button, Slide, TextField,
  Switch, FormControlLabel,
} from '@mui/material';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import {
  ArrowCircleDown as ArrowCircleDownIcon, Close as CloseIcon,
} from '@mui/icons-material';
import { estimateActions } from '../../../../redux/sales/estimate/estimateState';
import withParams from '../../../../components/atoms/withParam/WithParams';
import EstimateFile from './EstimateFile';
import EstimateOrderFile from './EstimateOrderFile';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class CreateEstimatePdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      fileName: '',
      tmpFileName: '',
      isDispTanto: true,
      data: {
        estimate: {
          clientName: '', hachuKingakuIncluded: '', title: '', externalBiko: '', fileName: '',
        },
        detail: [{
          summary: '', quantity: 0, unitPrice: 0, totalPrice: 0,
        }],
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estimateInfo.pdfData !== this.props.estimateInfo.pdfData) {
      if (this.props.estimateInfo.pdfData) {
        this.setDatas(this.props.estimateInfo.pdfData);
      }
    }
    if (prevProps.estimateInfo.isPdfUpdateSuccess !== this.props.estimateInfo.isPdfUpdateSuccess) {
      if (this.props.estimateInfo.isPdfUpdateSuccess) {
        this.getData();
      }
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.getData();
      }
    }
  }

  getData = () => {
    this.props.dispatch(estimateActions.getPdfData({ id: this.props.estimateId }));
  };

  setDatas = (data) => {
    this.setState({ data, fileName: data.estimate.fileName, tmpFileName: data.estimate.fileName });
  };

  onClickEdit = () => {
    this.setState({ isEdit: true });
  };

  onChange = (e) => {
    this.setState({ fileName: e.target.value });
  };

  onClickSave = () => {
    const { fileName, tmpFileName } = this.state;
    if (fileName === tmpFileName) {
      return;
    }
    this.props.dispatch(estimateActions.updatePdfData({ fileName, id: this.props.estimateId }));
    this.setState({ isEdit: false });
  };

  onClickCancel = () => {
    this.setState({ isEdit: false });
  };

  onChangeCheck = (event) => {
    this.setState({ isDispTanto: event.target.checked });
  };

  render() {
    const { isOpen, onClose, fileType } = this.props;
    const {
      data, fileName, isEdit, isDispTanto,
    } = this.state;

    return (
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              見積書
            </Typography>
          </Toolbar>
        </AppBar>
        <Box p={2} style={{ overflowY: 'auto' }} mt={8}>
          <Box display="flex">
            <Box style={{ flexGrow: 1 }}>
              {!isEdit && (
                <Box display="flex">
                  <Box display="flex" sx={{ alignItems: 'center' }}>
                    <Box sx={{ fontWeight: 'bold' }}>ファイル名</Box>
                    <Box mx={2}>{fileName}</Box>
                  </Box>
                  <Button variant="outlined" onClick={this.onClickEdit}>編集</Button>
                </Box>
              )}
              {isEdit && (
                <Box display="flex">
                  <Box sx={{ flex: '0 0 60%' }} mr={2}>
                    <TextField value={fileName} fullWidth name="fileName" onChange={this.onChange} />
                  </Box>
                  <Box>
                    <Button variant="outlined" onClick={this.onClickSave} sx={{ marginRight: '10px' }}>保存</Button>
                    <Button variant="outlined" color="inherit" onClick={this.onClickCancel}>キャンセル</Button>
                  </Box>
                </Box>
              )}
            </Box>
            {(!isEdit && fileType === '1') && (
              <Box textAlign="right" style={{ maxWidth: '300px' }}>
                <PDFDownloadLink
                  document={<EstimateFile data={data} isDispTanto={isDispTanto} />}
                  fileName={`${fileName}.pdf`}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Box style={{
                    display: 'flex', border: '1px solid rgb(25, 118, 210)', color: 'rgb(25, 118, 210)', padding: '5px 15px', borderRadius: '4px',
                  }}
                  >
                    <ArrowCircleDownIcon />
                    <Box>ダウンロードする</Box>
                  </Box>
                </PDFDownloadLink>
              </Box>
            )}
            {(!isEdit && fileType === '2') && (
              <Box textAlign="right" style={{ maxWidth: '300px' }}>
                <PDFDownloadLink
                  document={<EstimateOrderFile data={data} isDispTanto={isDispTanto} />}
                  fileName={fileName}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Box style={{
                    display: 'flex', border: '1px solid rgb(25, 118, 210)', color: 'rgb(25, 118, 210)', padding: '5px 15px', borderRadius: '4px',
                  }}
                  >
                    <ArrowCircleDownIcon />
                    <Box>ダウンロードする</Box>
                  </Box>
                </PDFDownloadLink>
              </Box>
            )}
          </Box>
          <Box>
            <FormControlLabel control={<Switch checked={isDispTanto} onChange={this.onChangeCheck} />} label="担当者を表示する" />
          </Box>
          {fileType === '1' && (
            <PDFViewer style={{ width: '100%', height: '1123px' }} showToolbar={false}>
              <EstimateFile data={data} isDispTanto={isDispTanto} />
            </PDFViewer>
          )}
          {fileType === '2' && (
            <PDFViewer style={{ width: '100%', height: '1123px' }} showToolbar={false}>
              <EstimateOrderFile data={data} isDispTanto={isDispTanto} />
            </PDFViewer>
          )}
        </Box>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  estimateInfo: state.estimateStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(CreateEstimatePdf));
