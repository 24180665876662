/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
import {
  startOfMonth, endOfMonth, format,
  getDate, eachDayOfInterval,
  endOfWeek, eachWeekOfInterval, addDays,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

export function isSomeMonth(d1, d2) {
  return (d1.getFullYear() + d1.getMonth()) - (d2.getFullYear() + d2.getMonth()) === 0;
}

export function getCalnedarMonthly(paramDate) {
  if (!paramDate) {
    return [];
  }
  const targetDate = new Date(paramDate);

  const weeks = eachWeekOfInterval(
    {
      start: startOfMonth(targetDate),
      end: endOfMonth(targetDate),
    },
    { weekStartsOn: 0 },
  );
  const monthly = weeks.map((week) => eachDayOfInterval(
    {
      start: week,
      end: endOfWeek(week, { weekStartsOn: 0 }),
    },
  ));
  const monthArray = [];
  monthly.forEach((item) => {
    const weekArray = [];
    item.forEach((fnsDate) => {
      weekArray.push(
        {
          date: fnsDate, day: getDate(fnsDate), formatDate: format(fnsDate, 'yyyy/MM/dd', { locale: ja }), week: format(fnsDate, 'EEEEEE', { locale: ja }), isSomeMonth: isSomeMonth(fnsDate, targetDate),
        },
      );
    });
    monthArray.push(weekArray);
  });
  return monthArray;
}

export function getFirstDate(paramDate) {
  let targetDate = new Date(paramDate);
  if (format(new Date(), 'yyyy/MM', { locale: ja }) === paramDate) {
    targetDate = new Date();
  }
  return {
    date: targetDate,
    formatDate: format(targetDate, 'yyyy/MM/dd', { locale: ja }),
    day: getDate(targetDate),
    week: format(targetDate, 'EEEEEE', { locale: ja }),
  };
}

export function getMonthly(paramDate) {
  const firstDate = new Date(`${format(new Date(paramDate), 'yyyy/MM')}/01`);
  const endDay = parseInt(getDate(endOfMonth(firstDate)), 10) - 1;
  const monthArray = [];
  for (let i = 0; i <= endDay; i++) {
    const targetDate = addDays(firstDate, i);
    const day = {
      date: targetDate, formatDate: format(targetDate, 'yyyy/MM/dd', { locale: ja }), day: getDate(targetDate), week: format(targetDate, 'EEEEEE', { locale: ja }),
    };
    monthArray.push(day);
  }
  return monthArray;
}

export function getData(datas, targetDate) {
  if (datas.length <= 0) {
    return {};
  }
  const dayDatas = datas.filter((row) => row.workDt === targetDate);
  let targetdate = {};
  if (dayDatas.length > 0) {
    targetdate = dayDatas[0];
  }
  return targetdate;
}

export function isCheckError(datas, isBp) {
  let isError = false;
  if (!datas) {
    return isError;
  }
  datas.forEach((item) => {
    if ((item.overtimeErr || item.costErr) && !isBp) {
      isError = true;
      return;
    }
    if (!item.rested && !item.statusCd) {
      isError = true;
    }
  });
  return isError;
}
