import React from 'react';
import {
  Page, Document, Text, View, Image,
} from '@react-pdf/renderer';
import {
  Root, Title, Grid, Col, Box, NumberCol,
} from '../../../../components/pdf/Common';
import syaban from '../../../../asset/syaban.png';

export default function InspectWorkFile(props) {
  const { inspect, detail } = props.data || {};
  const { isDispTanto } = props;
  return (
    <Document>
      <Page size="A4" wrap>
        <Root>
          <Title>作業報告書 兼 検収書</Title>
          <Grid>
            <Col flexBasis={inspect.clientName.length > 20 ? '100%' : '70%'}>
              <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                <Text style={{ textAlign: 'center' }}>{inspect.clientName}</Text>
                {!inspect.contactName && (<Text style={{ marginLeft: '20px' }}>御中</Text>)}
              </Box>
            </Col>
            {inspect.clientName.length <= 20 && (
              <Col flexBasis="30%">
                <Box style={{ flexDirection: 'row', width: '100%' }}>
                  <Box style={{ minWidth: '40px' }}><Text>No.</Text></Box>
                  <Box><Text>{inspect.inspectNo}</Text></Box>
                </Box>
              </Col>
            )}
          </Grid>
          {inspect.clientName.length > 20 && (
            <Grid>
              <Col flexBasis="70%">
                {inspect.contactName && (
                  <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                    <Text style={{ textAlign: 'center' }}>{inspect.contactName}</Text>
                    <Text style={{ marginLeft: '20px' }}>様</Text>
                  </Box>
                )}
              </Col>
              <Col flexBasis="30%">
                <Box style={{ flexDirection: 'row', width: '100%' }}>
                  <Box style={{ minWidth: '40px' }}><Text>No.</Text></Box>
                  <Box><Text>{inspect.inspectNo}</Text></Box>
                </Box>
              </Col>
            </Grid>
          )}
          <Grid>
            <Col flexBasis="70%">
              {(inspect.contactName && inspect.clientName.length <= 20) && (
                <Box style={{ fontSize: '15px', flexDirection: 'row' }}>
                  <Text style={{ textAlign: 'center' }}>{inspect.contactName}</Text>
                  <Text style={{ marginLeft: '20px' }}>様</Text>
                </Box>
              )}
            </Col>
            <Col flexBasis="30%">
              <Box style={{ flexDirection: 'row', width: '100%' }}>
                <Box style={{ minWidth: '40px' }}><Text>発行日</Text></Box>
                <Box><Text>{inspect.deliveryDate}</Text></Box>
              </Box>
            </Col>
          </Grid>
          <Grid style={{ marginTop: '30px' }}>
            <Col flexBasis="70%">
              <Grid>
                <Col
                  flexBasis="20%"
                  subTitle
                  style={{
                    alignItems: 'center', textAlign: 'center', justifyContent: 'center',
                  }}
                >
                  <View><Text>件名</Text></View>
                </Col>
                <Col
                  borderRight
                  borderTop
                  borderBottom
                  style={{
                    paddingLeft: '5px', paddingRight: '5px', minWidth: '260px', wordWrap: 'break-word', maxWidth: '260px',
                  }}
                >
                  {inspect.title && inspect.title.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
                </Col>
              </Grid>
            </Col>
            <Col flexBasis="30%">
              <Text>株式会社ソデック</Text>
              <Text>〒104-0042</Text>
            </Col>
          </Grid>
          <Grid>
            <Col flexBasis="70%">
              <Text style={{ marginBottom: '10px' }}>下記のとおり、作業を行いましたのでご報告いたします。</Text>
            </Col>
            <Col flexBasis="30%">
              <Box style={{ position: 'relative' }}>
                <Text>東京都中央区入船1丁目4番10号</Text>
                <Text>八丁堀プレイス6F</Text>
                <Text>TEL：03-3297-3030</Text>
                {(isDispTanto && inspect.eigyoTnto) && (
                  <Text>{`担当：${inspect.eigyoTnto}`}</Text>
                )}
                <Text>登録番号：T9010001092589</Text>
                {inspect.approvalStatus === '4' && (
                  <Image
                    src={syaban}
                    style={{
                      width: '40%', position: 'absolute', top: '-15px', right: '0px',
                    }}
                  />
                )}
              </Box>
            </Col>
          </Grid>
          <Grid style={{ marginTop: '20px' }}>
            <Col flexBasis="70%">
              <Grid style={{ height: '40px' }}>
                <Box
                  flexBasis="20%"
                  subTitle
                  style={{
                    alignItems: 'center', justifyContent: 'center', display: 'flex', border: '1px solid #E4E4E4', backgroundColor: '#E4E4E4', width: '70px', fontSize: '15px',
                  }}
                >
                  <View><Text>合計</Text></View>
                </Box>
                <Box style={{
                  alignItems: 'center', marginRight: '10px', justifyContent: 'center', display: 'flex', border: '1px solid #E4E4E4', width: '250px', fontSize: '15px',
                }}
                >
                  <Text>{`${inspect.kingakuGokei ? inspect.kingakuGokei : 0}円 (税込)`}</Text>
                </Box>
              </Grid>
            </Col>
            <Col flexBasis="30%" />
          </Grid>
          <Box style={{ marginTop: '20px' }}>
            <Text>{`注文No：${inspect.chumonNo}`}</Text>
          </Box>
          <Grid>
            <Col style={{ textAlign: 'center' }} subTitle flexBasis="10%"><Text>作業期間</Text></Col>
            <Col flexBasis="90%" borderRight borderTop><Text style={{ paddingLeft: '5px' }}>{`${inspect.workStartDate}～${inspect.workEndDate}`}</Text></Col>
          </Grid>
          <Grid style={{ textAlign: 'center' }}>
            <Col subTitle flexBasis="5%"><Text>No.</Text></Col>
            <Col subTitle flexBasis="50%"><Text>メンバー</Text></Col>
            <Col subTitle flexBasis="10%"><Text>作業時間(h)</Text></Col>
            <Col subTitle flexBasis="15%"><Text>単価</Text></Col>
            <Col subTitle flexBasis="20%"><Text>金額</Text></Col>
          </Grid>
          {detail.map((item) => (
            <Grid>
              <Col borderLeft borderBottom flexBasis="5%" style={{ textAlign: 'center', justifyContent: 'center' }}><Text>{item.displayOrder}</Text></Col>
              <Col borderLeft borderBottom flexBasis="50%" style={{ paddingLeft: '5px' }}>
                {item.summary && item.summary.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
              </Col>
              <NumberCol borderLeft borderBottom flexBasis="10%"><Text>{`${item.quantity.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom flexBasis="15%"><Text>{`¥${item.unitPrice.toLocaleString()}`}</Text></NumberCol>
              <NumberCol borderLeft borderBottom borderRight flexBasis="20%"><Text>{`¥${item.totalPrice.toLocaleString()}`}</Text></NumberCol>
            </Grid>
          ))}
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="10%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>小計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${inspect.kingaku ? inspect.kingaku : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="10%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>消費税</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${inspect.kingakuTax ? inspect.kingakuTax : 0}`}</Text></NumberCol>
          </Grid>
          <Grid>
            <Col flexBasis="55%" />
            <Col flexBasis="10%" style={{ textAlign: 'center', backgroundColor: '#E4E4E4' }}><Text>合計</Text></Col>
            <NumberCol borderBottom borderRight flexBasis="35%"><Text>{`¥${inspect.kingakuGokei ? inspect.kingakuGokei : 0}`}</Text></NumberCol>
          </Grid>
          {inspect.workMemo && (
            <Text style={{ marginTop: '10px' }}>{inspect.workMemo}</Text>
          )}
          <Box style={{ marginTop: '20px' }}>
            <Text>上記の通り、検収いたしました。</Text>
          </Box>
          <Grid>
            <Col flexBasis="60%">
              <Box style={{ border: '1px solid #000' }}>
                <Box style={{ marginTop: '10px', marginLeft: '5px' }}>
                  <Text>検収日：</Text>
                </Box>
                <Grid style={{ marginTop: '15px', marginLeft: '5px' }}>
                  <Box style={{ flexDirection: 'row' }}>
                    <Text>会社名：</Text>
                    <Text>{inspect.clientName}</Text>
                  </Box>
                </Grid>
                <Grid style={{ marginTop: '15px', marginLeft: '5px' }}>
                  <Col flexBasis="90%">
                    <Box style={{ flexDirection: 'row' }}>
                      <Text style={{ marginRight: '10px' }}>役職</Text>
                      <Text>：</Text>
                    </Box>
                  </Col>
                  <Col flexBasis="10%" />
                </Grid>
                <Grid style={{ marginBottom: '10px', marginTop: '15px', marginLeft: '5px' }}>
                  <Col flexBasis="90%">
                    <Box style={{ flexDirection: 'row' }}>
                      <Text style={{ marginRight: '10px' }}>氏名</Text>
                      <Text>：</Text>
                    </Box>
                  </Col>
                  <Col flexBasis="10%" />
                </Grid>
              </Box>
            </Col>
            <Col flexBasis="40%">
              <Box style={{ marginTop: '30px', marginLeft: '100px' }}>
                <Box style={{
                  backgroundColor: '#E4E4E4', width: '120px', textAlign: 'center', border: '1px solid #000',
                }}
                >
                  <Text>検収印</Text>
                </Box>
                <Box style={{
                  borderLeft: '1px solid #000', borderRight: '1px solid #000', borderBottom: '1px solid #000', height: '80px', width: '120px',
                }}
                />
              </Box>
            </Col>
          </Grid>
          <Grid style={{ minHeight: '70px', marginTop: '30px' }}>
            <Col flexBasis="10%" style={{ textAlign: 'center', justifyContent: 'center' }} subTitle><Text>備考</Text></Col>
            <Col borderTop borderRight borderBottom flexBasis="90%" style={{ paddingLeft: '10px', justifyContent: 'center' }}>
              {inspect.externalBiko && inspect.externalBiko.split('\n').map((t, i) => <Text key={`${t} ${i + 1}`}>{t}</Text>)}
            </Col>
          </Grid>
        </Root>
      </Page>
    </Document>
  );
}
