import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, DialogTitle, DialogContent, DialogActions, Box,
  Grid, TextField, Switch, FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../../../components/atoms/dialog/Dialog';
import { employeeHistoryActions } from '../../../../redux/office/employee/employeeHistoryState';
import DatePicker from '../../../../components/atoms/datePicker/DatePicker';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import Validation from './validation';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
import MoneyInput from '../../../../components/atoms/moneyInput/MoneyInput';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';

export default function HistoryDialog(props) {
  const [errorMessage, setErrorMessage] = React.useState({});
  const [form, setForm] = React.useState({});
  const dispatch = useDispatch();
  const {
    isOpen, onClose, syainId, isNew,
  } = props;
  const data = useSelector((state) => state.employeeHistoryStore.data);
  const select = useSelector((state) => state.employeeStore.select);
  const isLoading = useSelector((state) => state.commonStore.isLoading);

  React.useEffect(() => {
    if (isOpen) {
      dispatch(
        employeeHistoryActions.getData({ id: syainId }),
      );
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const onClickSave = () => {
    let tempMessage = errorMessage;
    let isError = false;

    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key], form.attendanceKind);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessage(tempMessage);
    if (isError) {
      return;
    }

    if (isNew) {
      dispatch(employeeHistoryActions.insertData(form));
    } else {
      dispatch(employeeHistoryActions.updateData(form));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const tempForm = {
      ...form,
      [name]: value,
    };
    setForm(tempForm);
    setErrorMessage({ ...errorMessage, [name]: Validation.formValidate(name, value) });
  };

  const onToggleChange = (event) => {
    const { checked, name } = event.target;
    const tempForm = {
      ...form,
      [name]: checked,
    };
    setForm(tempForm);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      isOpen={isOpen}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>レベル履歴</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormTitle title="部署" />
            <SearchSelectBox
              error={errorMessage.sykmCd}
              name="sykmCd"
              disableClearable
              options={select.busyoList}
              value={form.sykmCd}
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.sykmCd}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="課" />
            <SearchSelectBox
              name="mstDivisionId"
              options={select.divisions}
              value={form.mstDivisionId}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTitle title="ランク" />
            <SearchSelectBox
              error={errorMessage.rank}
              name="rank"
              disableClearable
              options={select.rankList}
              value={form.rank}
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.sykmCd}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="役員報酬" />
            <MoneyInput
              fullWidth
              value={form.ykin}
              name="ykin"
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.sykmCd}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="年俸基本" />
            <MoneyInput
              fullWidth
              value={form.nnpKhnKyu}
              name="nnpKhnKyu"
              onChange={onChange}
            />
            <FormErrorText>{errorMessage.nnpKhnKyu}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="扶養" />
            <TextField variant="outlined" fullWidth value={form.fuyou} name="fuyou" inputProps={{ type: 'number' }} onChange={onChange} />
            <FormErrorText>{errorMessage.fuyou}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="家族人数" />
            <TextField variant="outlined" fullWidth value={form.kazoku} name="kazoku" inputProps={{ type: 'number' }} onChange={onChange} />
            <FormErrorText>{errorMessage.kazoku}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="残業申請必要時間(分)" />
            <TextField variant="outlined" fullWidth value={form.overRequestTime} name="overRequestTime" inputProps={{ type: 'number' }} onChange={onChange} />
            <FormErrorText>{errorMessage.overRequestTime}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <FormTitle title="適用日" />
            <DatePicker
              handleChange={onChange}
              value={form.useSt}
              name="useSt"
            />
            <FormErrorText>{errorMessage.useSt}</FormErrorText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="締切日" />
            <Box>{form.useEd}</Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="間接部門" />
            <FormControlLabel control={<Switch checked={form.indirected} name="indirected" onChange={onToggleChange} />} label="間接部門対象" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="間接負担" />
            <FormControlLabel control={<Switch checked={form.indirectBorne} name="indirectBorne" onChange={onToggleChange} />} label="間接負担対象" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="休職" />
            <FormControlLabel control={<Switch checked={form.leaved} name="leaved" onChange={onToggleChange} />} label="休職" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="配偶者" />
            <FormControlLabel control={<Switch checked={form.partnerHad} name="partnerHad" onChange={onToggleChange} />} label="配偶者" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTitle title="フレックス" />
            <FormControlLabel control={<Switch checked={form.flex} name="flex" onChange={onToggleChange} />} label="フレックス" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton loading={isLoading} onClick={onClickSave}>保存</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
