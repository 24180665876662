import React from 'react';
import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Toolbar, Typography, Paper,
  Checkbox, Tooltip, IconButton, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import DeleteConfirmDialog from '../../../components/templates/deleteConfirmDialog/DeleteConfirmDialog';
import Pagination from '../../../components/atoms/pagination/Pagination';
import TableHeaderCell from '../../../components/atoms/tableHeaderCell/TableHeaderCell';
import Link from '../../../components/atoms/link/Link';
import urls from '../../../constants/frontUrls';

export default function DataTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [deleteConfOpen, setDeleteConfOpen] = React.useState(false);
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const navigate = useNavigate();
  const headCells = [
    { id: 'asset_id', label: '資産ID' },
    { id: 'asset_name', label: '名前' },
    { id: 'asset_kbn_mei', label: 'カテゴリ' },
    { id: 'state_mei', label: '状況' },
  ];

  const {
    rows,
    totalElements,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    order,
    orderBy,
    disabled,
    isNotEdit,
  } = props;

  const onAdd = () => {
    navigate(urls.ASSET_DETAIL);
  };

  const handleHeaderClose = () => {
    setSelected([]);
  };

  React.useEffect(() => {
    handleHeaderClose();
  }, [rows]);

  const handleSelectAllClick = (event) => {
    let selectedData = [];

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((row) => row.deleteable)
        .map((n) => n.id);
      selectedData = newSelecteds;
    }
    setSelected(selectedData);
  };

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const onPreDelete = () => {
    setDeleteConfOpen(true);
  };

  const onDelete = () => {
    props.onDelete(selected);
    setDeleteConfOpen(false);
    setSelected([]);
  };

  const handleClose = () => {
    setDeleteConfOpen(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const addProc = (
    <>
      <Typography style={{ flex: '1 1 30%' }} color="inherit" variant="subtitle1" component="div" />
      {!disabled && (
        <Tooltip title="資産の追加">
          <Button color="primary" variant="outlined" onClick={onAdd} disabled={disabled}>資産の追加</Button>
        </Tooltip>
      )}
    </>
  );

  const colSpan = headCells.length + 1;

  return (
    <div>
      <Paper>
        <Toolbar style={{ backgroundColor: selected.length > 0 ? '#E8F0FE' : '', paddingLeft: 0 }}>
          {selected.length > 0 ? (
            <>
              <Typography style={{ flex: '1 1 30%', marginRight: '10px' }} color="inherit" variant="subtitle1" component="div">
                <IconButton aria-label="delete" size="medium" onClick={handleHeaderClose}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {selected.length}
                {' '}
                件選択中
              </Typography>
              <Tooltip title="資産の削除">
                <Button color="secondary" variant="outlined" onClick={onPreDelete} disabled={disabled}>資産の削除</Button>
              </Tooltip>
            </>
          ) : addProc}
        </Toolbar>

        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            size="medium"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {!disabled && (
                  <TableCell key="table-header-check" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length !== selected.length
                      }
                      checked={
                        selected.length > 0
                        && rows.filter((row) => row.deleteable).length === selected.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      color="primary"
                      disabled={rows.filter((row) => row.deleteable).length === 0}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableHeaderCell
                    headCell={headCell}
                    orderBy={orderBy}
                    order={order}
                    key={headCell.id}
                    handleRequestSort={handleRequestSort}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!disabled && (
                      <TableCell padding="checkbox">
                        {row.deleteable && (
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                            disabled={disabled}
                          />
                        )}
                      </TableCell>
                    )}
                    {!isNotEdit && (
                      <TableCell>
                        <Link to={urls.ASSET_DETAIL + row.id}>{row.assetId}</Link>
                      </TableCell>
                    )}
                    {isNotEdit && (
                      <TableCell>{row.assetId}</TableCell>
                    )}
                    <TableCell>
                      <div>{row.assetName}</div>
                      {row.installPcId && (<div>{`${row.installPcId}にインストール`}</div>)}
                    </TableCell>
                    <TableCell>{row.assetKbnMei}</TableCell>
                    <TableCell>{row.stateMei}</TableCell>
                  </TableRow>
                );
              })}
              {
                rows.length < 1 && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={colSpan}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalElements={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <DeleteConfirmDialog
          open={deleteConfOpen}
          onClose={handleClose}
          onDelete={onDelete}
          procName="資産"
          loading={isLoading}
        />
      </Paper>
    </div>
  );
}
