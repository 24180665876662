/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const vacationSlice = createSlice({
  name: 'vacation',
  initialState: {
    data: {},
    detailData: {},
  },
  reducers: {
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    getDetailData: (state) => {
      state.detailData = null;
    },
    getDetailDataSuccess: (state, action) => {
      state.detailData = action.payload;
    },
  },
});

export const vacationActions = vacationSlice.actions;

export default vacationSlice.reducer;
