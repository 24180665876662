/* eslint-disable max-len */
import {
  getMonth, getYear, addMonths, format, differenceInMonths,
} from 'date-fns';

export function getTargetYear() {
  const today = new Date();
  const month = getMonth(today) + 1;
  const year = getYear(today);
  let startMonth = `${year}/08/01`;
  if (month >= 2 && month <= 7) {
    startMonth = `${year}/02/01`;
  } else if (month === 1) {
    startMonth = `${year - 1}/08/01`;
  }
  return {
    startMonth,
    endMonth: format(addMonths(new Date(startMonth), 5), 'yyyy/MM/dd'),
  };
}

export function getMonths(startMonth, endMonth) {
  const diff = differenceInMonths(new Date(endMonth), new Date(startMonth));
  const months = [];
  for (let i = 0; i <= diff; i += 1) {
    const target = addMonths(new Date(startMonth), i);
    months.push({ targetDate: format(target, 'yyyy/MM/dd'), displayDate: format(target, 'yyyy年MM月') });
  }
  return months;
}

function toNumber(param) {
  const kingaku = !param ? '0' : param;
  return parseFloat(kingaku.toString().replace(/,/g, ''));
}

function toResult(val, type) {
  if (type === '1') {
    return toNumber(val).toLocaleString();
  }
  return Math.floor(toNumber(val) / 1000).toLocaleString();
}

export function getData(datas, sykmCd, month, key, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  const targets = datas.filter(
    (row) => row.targetMonth === targetMonth && row.sykmCd === sykmCd,
  );
  if (targets.length <= 0) {
    return '0';
  }
  return toResult(targets[0][key], type);
}

export function getDiffData(datas, budgets, sykmCd, month, key, keyTwo, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  const targets = datas.filter(
    (row) => row.targetMonth === targetMonth && row.sykmCd === sykmCd,
  );
  const btargets = budgets.filter(
    (row) => row.targetMonth === targetMonth && row.sykmCd === sykmCd,
  );
  let target = 0;
  if (targets.length > 0) {
    target = targets[0][key] || 0;
  }
  let btarget = 0;
  if (btargets.length > 0) {
    btarget = btargets[0][keyTwo] || 0;
  }
  return toResult((target - btarget), type);
}

export function getMonthBetSummary(datas, month, key, keyTwo, type) {
  let sum = 0;
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
    sum += toNumber(row[keyTwo]);
  });
  return toResult(sum, type);
}

export function getMonthSummary(datas, month, key, type) {
  let sum = 0;
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
  });
  return toResult(sum, type);
}

export function getDiffMonthSummary(datas, budgets, month, key, keyTwo, type) {
  let sum = 0;
  let bsum = 0;
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
  });
  budgets.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    bsum += toNumber(row[keyTwo]);
  });
  return toResult((sum - bsum), type);
}

export function getBetDiffMonthSummary(datas, budgets, month, outKey, outKeyTwo, syainKey, syainKeyTwo, type) {
  let outSum = 0;
  let outBsum = 0;
  let syainSum = 0;
  let syainBsum = 0;
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    outSum += toNumber(row[outKey]);
    syainSum += toNumber(row[syainKey]);
  });
  budgets.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    outBsum += toNumber(row[outKeyTwo]);
    syainBsum += toNumber(row[syainKeyTwo]);
  });
  return toResult(((outSum + syainSum) - (outBsum + syainBsum)), type);
}

export function getBetAllSummary(datas, datasTwo, key, keyTwo, type) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
  });
  datasTwo.forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  return toResult(sum, type);
}

export function getBetAllMonthSummary(datas, datasTwo, month, key, keyTwo, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
  });
  datasTwo.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  return toResult(sum, type);
}

export function getBetDiffAllMonthSummary(datas, datasTwo, dataThree, month, key, keyTwo, keyThree, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  let yosan = 0;
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
  });
  datasTwo.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  dataThree.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    yosan += toNumber(row[keyThree]);
  });
  return toResult(sum - yosan, type);
}

export function getBetDiffAllSummary(datas, datasTwo, dataThree, key, keyTwo, keyThree, type) {
  let sum = 0;
  let yosan = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
  });
  datasTwo.forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  dataThree.forEach((row) => {
    yosan += toNumber(row[keyThree]);
  });
  return toResult(sum - yosan, type);
}

export function getBetSummary(datas, key, keyTwo, type) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
    sum += toNumber(row[keyTwo]);
  });
  return toResult(sum, type);
}

export function getSummary(datas, key, type) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
  });
  return toResult(sum, type);
}

export function getDivisionSummary(datas, sykmCd, key, type) {
  const targets = datas.filter((row) => row.sykmCd === sykmCd);
  let sum = 0;
  targets.forEach((row) => {
    sum += toNumber(row[key]);
  });
  return toResult(sum, type);
}

export function getBetDiffSummary(datas, budgets, outKey, outKeyTwo, syainKey, syainKeyTwo, type) {
  let outSum = 0;
  let outBsum = 0;
  let syainSum = 0;
  let syainBsum = 0;
  datas.forEach((row) => {
    outSum += toNumber(row[outKey]);
    syainSum += toNumber(row[syainKey]);
  });
  budgets.forEach((row) => {
    outBsum += toNumber(row[outKeyTwo]);
    syainBsum += toNumber(row[syainKeyTwo]);
  });
  return toResult(((outSum + syainSum) - (outBsum + syainBsum)), type);
}

export function getDiffSummary(datas, budgets, key, keyTwo, type) {
  let sum = 0;
  let bsum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
  });
  budgets.forEach((row) => {
    bsum += toNumber(row[keyTwo]);
  });
  return toResult((sum - bsum), type);
}

export function getDiffDivisionSummary(datas, budgets, sykmCd, key, keyTwo, type) {
  let sum = 0;
  let bsum = 0;
  datas.filter((row) => row.sykmCd === sykmCd).forEach((row) => {
    sum += toNumber(row[key]);
  });
  budgets.filter((row) => row.sykmCd === sykmCd).forEach((row) => {
    bsum += toNumber(row[keyTwo]);
  });
  return toResult((sum - bsum), type);
}

export function getArariData(datas, sykmCd, month, key, keyTwo, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  let bsum = 0;
  datas.filter(
    (row) => row.targetMonth === targetMonth && row.sykmCd === sykmCd,
  ).forEach((row) => {
    sum += toNumber(row[key]);
    bsum += toNumber(row[keyTwo]);
  });
  return toResult(sum - bsum, type);
}

export function getArariDivisionSummary(datas, sykmCd, key, keyTwo, type) {
  let sum = 0;
  let bsum = 0;
  datas.filter((row) => row.sykmCd === sykmCd).forEach((row) => {
    sum += toNumber(row[key]);
    bsum += toNumber(row[keyTwo]);
  });
  return toResult(sum - bsum, type);
}

export function getArariMonthSummary(datas, month, key, keyTwo, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  let bsum = 0;
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
    bsum += toNumber(row[keyTwo]);
  });
  return toResult(sum - bsum, type);
}

export function getArariSummary(datas, key, keyTwo, type) {
  let sum = 0;
  let bsum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
    bsum += toNumber(row[keyTwo]);
  });
  return toResult(sum - bsum, type);
}

export function getDiffArariMonthSummary(datas, budgets, month, key, keyTwo, keyThree, keyFour, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  let bsum = 0;
  datas.filter(
    (row) => row.targetMonth === targetMonth,
  ).forEach((row) => {
    sum += toNumber(row[key]);
    bsum += toNumber(row[keyTwo]);
  });
  const sumOne = sum - bsum;
  sum = 0;
  bsum = 0;
  budgets.filter(
    (row) => row.targetMonth === targetMonth,
  ).forEach((row) => {
    sum += toNumber(row[keyThree]);
    bsum += toNumber(row[keyFour]);
  });
  const sumTwo = sum - bsum;
  return toResult(sumOne - sumTwo, type);
}

export function getDiffArariSummary(datas, budgets, key, keyTwo, keyThree, keyFour, type) {
  let sum = 0;
  let bsum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
    bsum += toNumber(row[keyTwo]);
  });
  const sumOne = sum - bsum;
  sum = 0;
  bsum = 0;
  budgets.forEach((row) => {
    sum += toNumber(row[keyThree]);
    bsum += toNumber(row[keyFour]);
  });
  const sumTwo = sum - bsum;
  return toResult(sumOne - sumTwo, type);
}

export function getBumonMonthPlanSummary(datas, dataTwos, month, key, keyTwo, keyThree, type) {
  const arari = toNumber(getMonthSummary(datas, month, key, type));
  const data = toNumber(getMonthSummary(datas, month, keyTwo, type));
  const dataTwo = toNumber(getMonthSummary(dataTwos, month, keyThree, type));
  return (arari - data - dataTwo).toLocaleString();
}

export function getBumonPlanSummary(datas, dataTwos, key, keyTwo, keyThree, type) {
  const arari = toNumber(getSummary(datas, key, type));
  const data = toNumber(getSummary(datas, keyTwo, type));
  const dataTwo = toNumber(getSummary(dataTwos, keyThree, type));
  return (arari - data - dataTwo).toLocaleString();
}

export function getBumonMonthSummary(datas, dataTwos, dataThrees, month, key, keyTwo, type) {
  const arari = toNumber(getMonthSummary(datas, month, key, keyTwo, type));
  const data = toNumber(getMonthSummary(dataTwos, month, keyTwo, type));
  const dataTwo = toNumber(getMonthSummary(dataThrees, month, keyTwo, type));
  return (arari - data - dataTwo).toLocaleString();
}

export function getBumonSummary(datas, dataTwos, dataThrees, key, keyTwo, type) {
  const arari = toNumber(getSummary(datas, key, type));
  const data = toNumber(getSummary(dataTwos, keyTwo, type));
  const dataTwo = toNumber(getSummary(dataThrees, keyTwo, type));
  return (arari - data - dataTwo).toLocaleString();
}

export function getBumonMonthDiffSummary(datas, dataTwos, dataThrees, dataFour, dataFive, month, key, keyTwo, keyThree, type) {
  const arari = toNumber(getBumonMonthPlanSummary(datas, dataTwos, month, key, keyTwo, keyThree, type));
  const data = toNumber(getBumonMonthSummary(dataThrees, dataFour, dataFive, month, key, keyThree, type));
  return (arari - data).toLocaleString();
}

export function getBumonDiffSummary(datas, dataTwos, dataThrees, dataFour, dataFive, key, keyTwo, keyThree, type) {
  const arari = toNumber(getBumonPlanSummary(datas, dataTwos, key, keyTwo, keyThree, type));
  const data = toNumber(getBumonSummary(dataThrees, dataFour, dataFive, key, keyThree, type));
  return (arari - data).toLocaleString();
}

export function getBumonArariRitsu(datas, dataTwos, dataThrees, month, key, keyTwo, keyThree, type) {
  const arari = toNumber(getMonthSummary(datas, month, key, type));
  const data = toNumber(getBumonMonthSummary(datas, dataTwos, dataThrees, month, keyTwo, keyThree, type));
  if (data === 0) {
    return '0%';
  }
  return `${Math.floor(arari / data)}%`;
}

export function getBumonArariRitsuAll(datas, dataTwos, dataThrees, key, keyTwo, keyThree, type) {
  const arari = toNumber(getSummary(datas, key, type));
  const data = toNumber(getBumonSummary(datas, dataTwos, dataThrees, keyTwo, keyThree, type));
  if (data === 0) {
    return '0%';
  }
  return `${Math.floor(arari / data)}%`;
}

export function getCache(datas, indirects, month, key, keyTwo, keyThree, type) {
  const uriage = toNumber(getMonthSummary(datas, month, key, type));
  const genka = toNumber(getMonthSummary(datas, month, keyTwo, type));
  const kansetu = toNumber(getMonthSummary(indirects, month, keyThree, type));
  return toResult(uriage - genka - kansetu);
}

export function getCacheAll(datas, dataTwos, dataThrees, dataFour, key, keyTwo, keyThree, type) {
  const uriage = toNumber(getSummary(datas, key, type));
  const genka = toNumber(getSummary(datas, keyTwo, type));
  const kansetu = toNumber(getSummary(dataTwos, keyThree, type));
  const kansetuBu = toNumber(getSummary(dataThrees, keyThree, type));
  const keihi = toNumber(getSummary(dataFour, keyThree, type));
  return toResult(uriage - genka - kansetu - kansetuBu - keihi);
}

export function getShisyutsuMonthSummary(datas, datasTwo, month, key, keyTwo, keyThree, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
    sum += toNumber(row[keyTwo]);
  });
  datasTwo.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[keyThree]);
  });
  return toResult(sum, type);
}

export function getShisyutsuSummary(datas, datasTwo, key, keyTwo, keyThree, type) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
    sum += toNumber(row[keyTwo]);
  });
  datasTwo.forEach((row) => {
    sum += toNumber(row[keyThree]);
  });
  return toResult(sum, type);
}

export function getShisyutsuReulstMonthSummary(datas, datasTwo, datasThree, dataFour, month, key, keyTwo, keyThree, keyFour, type) {
  const targetMonth = format(new Date(month), 'yyyy/MM/dd');
  let sum = 0;
  datas.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[key]);
    sum += toNumber(row[keyFour]);
  });
  datasTwo.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  datasThree.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  dataFour.filter((row) => row.targetMonth === targetMonth).forEach((row) => {
    sum += toNumber(row[keyThree]);
  });
  return toResult(sum, type);
}

export function getShisyutsuReulstSummary(datas, datasTwo, datasThree, dataFour, key, keyTwo, keyThree, keyFour, type) {
  let sum = 0;
  datas.forEach((row) => {
    sum += toNumber(row[key]);
    sum += toNumber(row[keyFour]);
  });
  datasTwo.forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  datasThree.forEach((row) => {
    sum += toNumber(row[keyTwo]);
  });
  dataFour.forEach((row) => {
    sum += toNumber(row[keyThree]);
  });
  return toResult(sum, type);
}

export function getShisyutsuMonthDiffSummary(datas, datasTwo, month, key, keyTwo, keyThree, type, datas1, datasTwo2, datasThree3, dataFour4, key1, keyTwo2, keyThree3, keyFour4) {
  const yosan = toNumber(getShisyutsuMonthSummary(datas, datasTwo, month, key, keyTwo, keyThree, type));
  const result = toNumber(getShisyutsuReulstMonthSummary(datas1, datasTwo2, datasThree3, dataFour4, month, key1, keyTwo2, keyThree3, keyFour4, type));
  return (result - yosan).toLocaleString();
}

export function getShisyutsuDiffSummary(datas, datasTwo, key, keyTwo, keyThree, type, datas1, datasTwo2, datasThree3, dataFour4, key1, keyTwo2, keyThree3, keyFour4) {
  const yosan = toNumber(getShisyutsuSummary(datas, datasTwo, key, keyTwo, keyThree, type));
  const result = toNumber(getShisyutsuReulstSummary(datas1, datasTwo2, datasThree3, dataFour4, key1, keyTwo2, keyThree3, keyFour4, type));
  return (result - yosan).toLocaleString();
}
