import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const dateValidation = (value) => {
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const dateValidationWithKind = (value, kind) => {
  if (kind !== '4') return '';
  if (!value) return '入力してください';
  if (!isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value, kind) => {
    switch (type) {
      case 'attendanceKind':
      case 'approverResonsibleId':
      case 'reason':
        return requiredValidation(value);
      case 'targetFrom':
      case 'targetTo':
        return dateValidation(value);
      case 'transferDate':
        return dateValidationWithKind(value, kind);
      default:
        return '';
    }
  };

  static compareDateValidate = (valueStart, valueEnd) => {
    if (valueStart > valueEnd) return '開始より未来を設定してください';
    return '';
  };
}

export default Validation;
