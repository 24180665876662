/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { clientAgreementActions } from './clientAgreementState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.CLIENT.CLIENT_AGREEMENT, payload);
    yield put(clientAgreementActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.CLIENT.CLIENT_AGREEMENT, payload);
    yield put(clientAgreementActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.CLIENT.CLIENT_AGREEMENT_ID, payload);
    yield put(clientAgreementActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.CLIENT.CLIENT_AGREEMENT, payload);
    yield put(clientAgreementActions.saveDataSuccess(result));
  } catch (_) {}
}

function* getNames({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.CLIENT.CLIENT_AGREEMENT_NAMES, payload);
    yield put(clientAgreementActions.getNamesSuccess(result));
  } catch (_) {}
}

function* clientAgreementSaga() {
  yield takeLatest('clientAgreement/getDatas', getData);
  yield takeLatest('clientAgreement/deleteData', deleteData);
  yield takeLatest('clientAgreement/getData', getDataFromId);
  yield takeLatest('clientAgreement/saveData', saveDatas);
  yield takeLatest('clientAgreement/getNames', getNames);
}

export default clientAgreementSaga;
