/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { assetActions } from './assetState';
import {
  getDatas, deleteDatas, saveData, getDataById,
} from '../../../services/base.service';

function* getSelect() {
  try {
    const result = yield call(getDatas, REST_API.MASTER.ASSET.ASSET_SELECT);
    yield put(assetActions.getSelectSuccess(result));
  } catch (_) {}
}

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.MASTER.ASSET.ASSET, payload);
    yield put(assetActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.MASTER.ASSET.ASSET, payload);
    yield put(assetActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.MASTER.ASSET.ASSET_ID, payload);
    yield put(assetActions.getDataSuccess(result));
  } catch (_) {}
}

function* saveDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.MASTER.ASSET.ASSET, payload);
    yield put(assetActions.saveDataSuccess(result));
  } catch (_) {}
}

function* assetSaga() {
  yield takeLatest('asset/getDatas', getData);
  yield takeLatest('asset/getSelect', getSelect);
  yield takeLatest('asset/deleteData', deleteData);
  yield takeLatest('asset/getData', getDataFromId);
  yield takeLatest('asset/saveData', saveDatas);
}

export default assetSaga;
