import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import SearchSelectBox from '../../atoms/searchSelectBox/SearchSelectBox';
import { commonActions } from '../../../redux/common/commonState';
import FormTitle from '../../atoms/formTitle/FormTitle';

class AppStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    props.dispatch(commonActions.getStatuses({ code: 'APP_STATE' }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.statuses !== this.props.common.statuses) {
      if (this.props.common.statuses) {
        this.setData(this.props.common.statuses);
      }
    }
  }

  setData = (options) => {
    this.setState({ options });
  };

  render() {
    const { options } = this.state;
    const {
      value, label, name, onChange, isRequired, error,
    } = this.props;

    return (
      <Box>
        {label && (<FormTitle title={label} isRequired={isRequired} />)}
        <SearchSelectBox
          error={error}
          options={options}
          value={value}
          name={name}
          onChange={onChange}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.commonStore });

export default connect(mapStateToProps)(AppStatusList);
