const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value) => {
    switch (type) {
      case 'category':
      case 'name':
        return requiredValidation(value);
      default:
        return '';
    }
  };
}

export default Validation;
