export default function getColumn() {
  const columns = [
    {
      title: '表示順',
      name: 'displayOrder',
      width: '100',
      align: 'right',
      type: 'numeric',
    },
    {
      title: '摘要', width: '300', name: 'summary', align: 'left',
    },
    {
      title: '数量',
      name: 'quantity',
      width: '100',
      align: 'right',
      type: 'numeric',
    },
    {
      title: '単価',
      name: 'unitPrice',
      width: '100',
      align: 'right',
      type: 'numeric',
    },
    {
      title: '税',
      name: 'zeiKubun',
      width: '70',
      align: 'center',
      type: 'dropdown',
      source: ['10', '0'],
    },
    {
      title: '合計金額',
      name: 'totalPrice',
      width: '100',
      mask: '[-]#,##',
      align: 'right',
      type: 'numeric',
      readOnly: true,
    },
  ];

  return columns;
}
