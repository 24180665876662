import React from 'react';
import { connect } from 'react-redux';
import {
  Tabs, Box, Container, Tab, TextField, FormHelperText, InputAdornment,
  FormControl, Radio, FormControlLabel, RadioGroup, Checkbox,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import withParams from '../../../../components/atoms/withParam/WithParams';
import SuccessSnackbar from '../../../../components/atoms/successSnackbar/SuccessSnackbar';
import FormTitle from '../../../../components/atoms/formTitle/FormTitle';
import { employeeActions } from '../../../../redux/office/employee/employeeState';
import { commonActions } from '../../../../redux/common/commonState';
import { checkIsDisabled } from '../../../../utils/authCheck.helper';
import TabPanel from '../../../../components/atoms/tabPanel/TabPanel';
import DatePicker from '../../../../components/atoms/datePicker/DatePicker';
import { getMessage, getForm } from './employeeList-proc';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import LicenseList from '../employeeLicense/LicenseList';
import HistoryList from '../employeeHistory/HistoryList';
import MonthPicker from '../../../../components/atoms/monthPicker/MonthPicker';

class EmployeeDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    let successSnackOpen = false;
    let successSnackMessage = '';
    if (props.employeeInfo.isInsertSuccess) {
      successSnackOpen = true;
      successSnackMessage = '保存が完了しました';
    }
    props.dispatch(employeeActions.resetFlg());

    this.state = {
      data: getForm(),
      errorMessage: getMessage(),
      tabValue: 1,
      zipCodeLoading: false,
      successSnackOpen,
      successSnackMessage,
      isDisabled: checkIsDisabled(props.loginUser.userInfo.user),
    };
    props.dispatch(employeeActions.getSelect());
    props.dispatch(employeeActions.getData({ id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employeeInfo.data !== this.props.employeeInfo.data) {
      if (this.props.employeeInfo.data) {
        this.setData(this.props.employeeInfo.data);
      }
    }
    if (prevProps.common.isAddressSuccess !== this.props.common.isAddressSuccess) {
      if (this.props.common.isAddressSuccess) {
        this.setAddress(this.props.common.address);
      }
    }
    if (prevProps.employeeInfo.isUpdateSuccess !== this.props.employeeInfo.isUpdateSuccess) {
      if (this.props.employeeInfo.isUpdateSuccess) {
        this.setUpdateSuccess();
      }
    }
  }

  setAddress = (value) => {
    const { data, errorMessage } = this.state;

    let yubinNo = '';
    if (!value) {
      yubinNo = '存在しない郵便番号です';
    }
    this.setState({
      data: {
        ...data,
        add1: value,
      },
      errorMessage: {
        ...errorMessage,
        yubinNo,
      },
      zipCodeLoading: false,
    });
  };

  setData(data) {
    this.setState({ data });
  }

  onChange = (event) => {
    const { data } = this.state;
    const { name, value } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: value,
      },
    });
  };

  onCheckChange = (event) => {
    const { data } = this.state;
    const { name, checked } = event.target;
    this.setState({
      data: {
        ...data,
        [name]: checked,
      },
    });
  };

  setUpdateSuccess = () => {
    this.setState({
      successSnackOpen: true,
      successSnackMessage: '保存が完了しました',
    });
    this.props.dispatch(employeeActions.getData({ id: this.state.data.id }));
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ successSnackOpen: false });
  };

  onSave = () => {
    const { data } = this.state;
    this.props.dispatch(employeeActions.updateData(data));
  };

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  onChangeZipCode = (event) => {
    const { name, value } = event.target;
    const { data } = this.state;

    let zipCodeLoading = false;
    if (value.length === 7 || value.length === 8) {
      zipCodeLoading = true;
      this.props.dispatch(commonActions.getAddress({
        zipCode: value,
      }));
    }

    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      zipCodeLoading,
    });
  };

  render() {
    const {
      data, successSnackOpen, successSnackMessage, isDisabled, tabValue, errorMessage,
      zipCodeLoading,
    } = this.state;
    const { isLoading } = this.props.common;

    return (
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: { xs: 320, sm: 800 }, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={this.onTabChange} scrollButtons="auto" variant="scrollable">
            <Tab style={{ color: '#000000' }} label={data.syainMei} disabled />
            <Tab label="基本情報" />
            <Tab label="業務情報" />
            <Tab label="スキルシート" />
            <Tab label="レベル履歴" />
            <Tab label="資格取得履歴" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0} />

        <TabPanel value={tabValue} index={1}>
          <Box>
            <FormTitle title="社員ID" />
            <Box>{data.id}</Box>
          </Box>
          <Box mt={2}>
            <FormTitle title="社員名" isRequired />
            <TextField value={data.syainMei} name="syainMei" error={errorMessage.syainMei !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.syainMei}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="社員名カナ" isRequired />
            <TextField value={data.syainMeiRubi} name="syainMeiRubi" error={errorMessage.syainMeiRubi !== ''} fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.syainMeiRubi}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="生年月日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="birthday"
              value={data.birthday}
              error={errorMessage.birthday !== ''}
            />
            <FormErrorText>{errorMessage.birthday}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="性別" isRequired />
            <FormControl>
              <RadioGroup
                row
                onChange={this.onChange}
                name="seibetsu"
                value={data.seibetsu}
              >
                <FormControlLabel value="M" control={<Radio />} label="男" />
                <FormControlLabel value="F" control={<Radio />} label="女" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormTitle title="メール" />
            <TextField value={data.mail} name="mail" fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="郵便番号" />
            <TextField value={data.yubinNo} name="yubinNo" onChange={this.onChangeZipCode} error={errorMessage.yubinNo !== ''} />
            {zipCodeLoading && (
              <FormHelperText style={{ color: 'black' }}>検索しています..</FormHelperText>
            )}
            <FormErrorText>{errorMessage.yubinNo}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="都道府県・市区町村" />
            <TextField value={data.add1} name="add1" fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="丁目番地・その他" />
            <TextField value={data.add2} name="add2" fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="最寄駅" />
            <TextField value={data.stationMei} name="stationMei" fullWidth onChange={this.onChange} />
            <FormErrorText>{errorMessage.stationMei}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="電話番号" />
            <TextField value={data.tel} name="tel" fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="携帯電話" />
            <TextField value={data.sellNo} name="sellNo" fullWidth onChange={this.onChange} />
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Box>
            <FormTitle title="入社日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="entryDt"
              value={data.entryDt}
              error={errorMessage.entryDt !== ''}
            />
            <FormErrorText>{errorMessage.entryDt}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="退社日" isRequired />
            <DatePicker
              handleChange={this.onChange}
              name="outDt"
              value={data.outDt}
              error={errorMessage.outDt !== ''}
            />
            <FormErrorText>{errorMessage.outDt}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="勤続年数" />
            <Box>{data.entryday}</Box>
          </Box>
          <Box mt={2}>
            <FormTitle title="等級" />
            <Box>{data.tky}</Box>
          </Box>
          <Box mt={2}>
            <FormTitle title="みなし残業" />
            <FormControlLabel
              label="みなし残業対象"
              control={(
                <Checkbox
                  checked={data.deemed}
                  name="deemed"
                  onChange={this.onCheckChange}
                />
              )}
            />
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Box>
            <FormTitle title="社員名イニシャル" />
            <TextField value={data.meiRk} name="meiRk" onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="国籍" />
            <TextField value={data.national} name="national" onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="業界参入日" />
            <DatePicker
              handleChange={this.onChange}
              name="workStday"
              value={data.workStday}
              error={errorMessage.workStday !== ''}
            />
            <FormErrorText>{errorMessage.workStday}</FormErrorText>
          </Box>
          <Box mt={2}>
            <FormTitle title="学歴" />
            <TextField value={data.school} name="school" fullWidth onChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="学歴略称" />
            <TextField
              value={data.schoolRk}
              name="schoolRk"
              onChange={this.onChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">卒</InputAdornment>,
              }}
            />
          </Box>
          <Box mt={2}>
            <FormTitle title="卒業年月" />
            <MonthPicker value={data.graduate} name="graduate" handleChange={this.onChange} />
          </Box>
          <Box mt={2}>
            <FormTitle title="その他学校" />
            <TextField value={data.schoolEtc} fullWidth name="schoolEtc" onChange={this.onChange} />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={4} isAll>
          <HistoryList syainId={data.id} />
        </TabPanel>

        <TabPanel value={tabValue} index={5} isAll>
          <LicenseList syainId={data.id} />
        </TabPanel>

        {(!isDisabled && tabValue <= 3) && (
          <Box position="fixed" bottom={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <LoadingButton variant="contained" loading={isLoading} onClick={this.onSave}>保存</LoadingButton>
          </Box>
        )}
        <SuccessSnackbar
          open={successSnackOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successSnackMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeInfo: state.employeeStore,
  loginUser: state.loginStore,
  common: state.commonStore,
});

export default withParams(connect(mapStateToProps)(EmployeeDetail));
