import React from 'react';
import { isValid, format } from 'date-fns';
import jaLocale from 'dayjs/locale/ja';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export default function DatePicker(props) {
  const {
    value,
    handleChange,
    error,
    maxDate,
    minDate,
    name,
    isDisabled,
  } = props;

  const onChange = (newValue) => {
    let formatValue = newValue && newValue.$d;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd');
    }
    handleChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={jaLocale}>
      <MuiDatePicker
        openTo="day"
        maxDate={maxDate}
        minDate={minDate}
        views={['year', 'month', 'day']}
        value={fnsValue}
        onChange={onChange}
        disabled={isDisabled}
        renderInput={(params) => <TextField {...params} autoComplete="off" helperText={null} error={error} />}
        inputFormat="YYYY/MM/DD"
      />
    </LocalizationProvider>
  );
}
