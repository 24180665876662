/* eslint-disable max-len */
import React from 'react';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import {
  Container, Table, Box, ToggleButtonGroup, ToggleButton, TableContainer,
  Paper, TableHead, TableRow, TableCell, TableBody, Grid,
} from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon, TableView as TableViewIcon,
} from '@mui/icons-material';
import { workResultActions } from '../../../redux/work/workResult/workResultState';
import {
  getCalnedarMonthly, getMonthly, getData, getFirstDate, isCheckError,
} from './work-result-proc';
import SelectSyainWithMonth from '../../../components/organisms/selectSyainWithMonth/SelectSyainWithMonth';
import CalendarContent from './CalendarContent';
import WorkResultInput from '../workResultInput/WorkResultInput';
import SummaryData from './SummaryData';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

const HeaderTd = styled('th')(({ rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  verticalAlign: 'top',
  ...(rested && {
    color: 'red',
  }),
}));

const DataTd = styled('td')(({ disabled, rested, selected }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  height: '80px',
  verticalAlign: 'top',
  ...(disabled && {
    background: '#e6e6e6',
    color: '#999999',
  }),
  ...(rested && {
    color: 'red',
  }),
  ...(selected && {
    background: 'rgba(25, 118, 210, 0.08)',
  }),
  ...(!disabled && {
    '&:hover': {
      background: '#f1f3f4',
      cursor: 'pointer',
    },
  }),
}));

const RestColorCell = styled(TableCell)(({ rested }) => ({
  ...(rested && {
    color: 'red',
  }),
}));

class WorkResult extends React.Component {
  constructor(props) {
    super(props);
    let targetMonth = format(new Date(), 'yyyy/MM/dd');
    const today = format(new Date(), 'yyyy/MM/dd');
    let syainId = '';
    let isBp = false;
    if (this.props.loginInfo.userInfo) {
      syainId = this.props.loginInfo.userInfo.user.id;
      isBp = syainId ? syainId.slice(0, 2) === 'BP' : false;
    }

    if (props.workResultInfo.searchCondition) {
      syainId = props.workResultInfo.searchCondition.syainId;
      targetMonth = props.workResultInfo.searchCondition.targetMonth || format(new Date(), 'yyyy/MM/dd');
      this.props.dispatch(workResultActions.setSearchCondition(null));
      isBp = syainId ? syainId.slice(0, 2) === 'BP' : false;
    }

    this.state = {
      displayType: 'calendar',
      targetMonth,
      syainId,
      datas: [],
      targetData: {},
      inputTargetDate: getFirstDate(today),
      summary: {},
      flex: false,
      notEdit: true,
      isError: true,
      isOpenSnackbar: false,
      isBp,
      isLoginBp: props.loginInfo.userInfo.user.bp,
      message: '',
    };
    if (syainId) {
      this.getDatas(syainId, targetMonth);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginInfo.userInfo !== prevProps.loginInfo.userInfo) {
      if (this.props.loginInfo.userInfo.user) {
        this.setInitDatas(this.props.loginInfo.userInfo.user.id);
      }
    }
    if (this.props.workResultInfo.data !== prevProps.workResultInfo.data) {
      if (this.props.workResultInfo.data) {
        this.setDatas(this.props.workResultInfo.data);
      }
    }
    if (this.props.workResultInfo.isRequestSuccess !== prevProps.workResultInfo.isRequestSuccess) {
      if (this.props.workResultInfo.isRequestSuccess) {
        this.setMessage('承認依頼');
      }
    }
    if (this.props.workResultInfo.isApprovalSuccess
      !== prevProps.workResultInfo.isApprovalSuccess) {
      if (this.props.workResultInfo.isApprovalSuccess) {
        this.setMessage('承認');
      }
    }
  }

  setMessage = (msg) => {
    const { syainId, targetMonth, isRelease } = this.state;
    this.getDatas(syainId, targetMonth);
    const release = isRelease ? '取消' : '';
    this.setState({ isOpenSnackbar: true, message: `${msg + release}が完了しました` });
  };

  setDatas = (data) => {
    const { isBp } = this.state;
    this.setState({
      datas: data.datas,
      summary: data.summary,
      notEdit: data.notEdit,
      flex: data.flex,
      isError: isCheckError(data.datas, isBp),
    });
  };

  getDatas = (syainId, targetMonth) => {
    this.props.dispatch(workResultActions.getDatas({ targetMonth, syainId }));
  };

  setInitDatas = (syainId) => {
    const isBp = syainId ? syainId.slice(0, 2) === 'BP' : false;
    this.setState({ syainId, isBp });
    this.getDatas(syainId, this.state.targetMonth);
  };

  onResearch = () => {
    const { syainId, targetMonth } = this.state;
    this.getDatas(syainId, targetMonth);
  };

  onDisplayChange = (_, displayType) => {
    if (!displayType) {
      return;
    }
    this.setState({ displayType });
  };

  onChange = (event) => {
    const { syainId, targetMonth } = event;
    const isBp = syainId ? syainId.slice(0, 2) === 'BP' : false;
    this.setState({
      syainId, targetMonth, inputTargetDate: getFirstDate(targetMonth), isBp,
    });
    this.getDatas(syainId, targetMonth);
  };

  onDayClick = (_, targetData, inputTargetDate, rested, isSameMonth) => {
    if (!isSameMonth) {
      return;
    }
    this.setState({
      targetData,
      inputTargetDate: {
        ...inputTargetDate,
        rested,
      },
    });
  };

  onClickRequest = (_, request) => {
    const { syainId, targetMonth, flex } = this.state;
    this.props.dispatch(workResultActions.updateRequest({
      targetMonth, syainId, flex, request,
    }));
    this.setState({ isRelease: !request, isOpenSnackbar: false });
  };

  onClickApproval = (_, request) => {
    const { syainId, targetMonth, flex } = this.state;
    this.props.dispatch(workResultActions.updateApproval({
      targetMonth, syainId, flex, request,
    }));
    this.setState({ isRelease: !request, isOpenSnackbar: false });
  };

  onSnackbarClose = () => {
    this.setState({ isOpenSnackbar: false });
  };

  render() {
    const {
      displayType, datas, targetMonth, targetData, inputTargetDate, syainId, summary,
      flex, notEdit, isError, isOpenSnackbar, message, isLoginBp, isBp,
    } = this.state;
    const calendarMonthly = getCalnedarMonthly(targetMonth);
    const monthly = getMonthly(targetMonth);
    return (
      <Container maxWidth={false}>
        <SuccessSnackbar open={isOpenSnackbar} handleClose={this.onSnackbarClose} message={message} />
        <Box>
          <SelectSyainWithMonth
            isBp={isLoginBp}
            disableClearable
            targetMonth={targetMonth}
            syainId={syainId}
            onChange={this.onChange}
          />
        </Box>
        <SummaryData
          isError={isError}
          isNotEdit={notEdit}
          data={summary}
          flex={flex}
          isBp={isBp}
          isLoginBp={isLoginBp}
          onClickRequest={this.onClickRequest}
          onClickApproval={this.onClickApproval}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Box sx={{ minHeight: '40px', display: { xs: 'none', sm: 'block' } }} my={1} />
            <WorkResultInput
              onResearch={this.onResearch}
              flex={flex}
              isNotEdit={summary.requestFlg || notEdit}
              targetData={targetData}
              syainId={syainId}
              isBp={isBp}
              inputTargetDate={inputTargetDate}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ textAlign: 'right' }} my={1}>
              <ToggleButtonGroup
                size="small"
                value={displayType}
                exclusive
                onChange={this.onDisplayChange}
                aria-label="text alignment"
              >
                <ToggleButton value="calendar" disabled={isLoginBp}>
                  <CalendarMonthIcon />
                </ToggleButton>
                <ToggleButton value="table" disabled={isLoginBp}>
                  <TableViewIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {displayType === 'calendar' && (
              <Table
                width="100%"
                sx={{
                  border: 'solid 1px #DDD',
                  borderCollapse: 'collapse',
                  textAlign: 'center',
                }}
              >
                <thead>
                  <tr>
                    {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
                      <HeaderTd key={week} rested={week === '日' || week === '土'}>{week}</HeaderTd>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {calendarMonthly.map((weekRow, index) => (
                    <tr key={`${weekRow[index].date}month`}>
                      {weekRow.map((item) => {
                        const data = getData(datas, item.formatDate);
                        return (
                          <DataTd
                            selected={item.formatDate === inputTargetDate.formatDate}
                            key={item.day}
                            disabled={!item.isSomeMonth}
                            rested={data.rested}
                            onClick={
                              (event) => this.onDayClick(event, data, item, data.rested, item.isSomeMonth)
                            }
                          >
                            <CalendarContent data={data} item={item} isBp={isBp} />
                          </DataTd>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {displayType === 'table' && (
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 700 }} component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>日</TableCell>
                        {!flex && (
                          <TableCell>形態</TableCell>
                        )}
                        <TableCell>状況</TableCell>
                        <TableCell>開始</TableCell>
                        <TableCell>終了</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                          休憩
                        </TableCell>
                        <TableCell>作業</TableCell>
                        {!flex && (
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>法外</TableCell>
                        )}
                        {!flex && (
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>所外</TableCell>
                        )}
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>休日</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>法休</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>深夜</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>控除</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>備考</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {monthly.map((row) => {
                        const data = getData(datas, row.formatDate);
                        const isSelected = row.formatDate === inputTargetDate.formatDate;
                        return (
                          <TableRow
                            key={row.day}
                            sx={{
                              background: isSelected ? 'rgba(25, 118, 210, 0.08)' : '',
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:hover': { background: '#f1f3f4', cursor: 'pointer' },
                            }}
                            onClick={(event) => this.onDayClick(event, data, row, data.rested, true)}
                          >
                            <RestColorCell component="th" scope="row" rested={data.rested}>{`${row.day}(${row.week})`}</RestColorCell>
                            {!flex && (
                              <TableCell>{data.kmktName}</TableCell>
                            )}
                            <TableCell>{data.statusName}</TableCell>
                            <TableCell>{data.startTm}</TableCell>
                            <TableCell>{data.endTm}</TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.offTm}</TableCell>
                            <TableCell>{data.workTm}</TableCell>
                            {!flex && (
                              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.htgTm}</TableCell>
                            )}
                            {!flex && (
                              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.sytgTm}</TableCell>
                            )}
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.holidWorkTm}</TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.htkyjtTm}</TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.midOverTm}</TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.lateTm}</TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{data.biko}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
  workResultInfo: state.workResultStore,
  common: state.commonStore,
});

export default connect(mapStateToProps)(WorkResult);
