import React from 'react';
import { connect } from 'react-redux';
import { TextField, Grid, Container } from '@mui/material';
import DataTable from './DataTable';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';
import { loginUserActions } from '../../../redux/setting/loginUser/loginUserState';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';

class LoginUserList extends React.Component {
  constructor(props) {
    super(props);

    const searchCondition = {
      searchRoleName: '',
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
    };

    this.props.dispatch(loginUserActions.getSelect());
    this.search(searchCondition);
    this.state = {
      datas: [],
      selects: [],
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUser.datas !== this.props.loginUser.datas) {
      if (this.props.loginUser.datas) {
        this.setDatas(this.props.loginUser.datas);
      }
    }
    if (prevProps.loginUser.selects !== this.props.loginUser.selects) {
      if (this.props.loginUser.selects) {
        this.setSelect(this.props.loginUser.selects);
      }
    }
    if (prevProps.loginUser.isDeleteSuccess !== this.props.loginUser.isDeleteSuccess) {
      if (this.props.loginUser.isDeleteSuccess) {
        this.setSuccess('権限の削除が完了しました');
      }
    }
    if (prevProps.loginUser.isResetSuccess !== this.props.loginUser.isResetSuccess) {
      if (this.props.loginUser.isResetSuccess) {
        this.setSuccess('パスワードリセットが完了しました');
      }
    }
    if (prevProps.loginUser.isUpdateSuccess !== this.props.loginUser.isUpdateSuccess) {
      if (this.props.loginUser.isUpdateSuccess) {
        this.setSuccess('変更が完了しました');
      }
    }
  }

  setSelect(selects) {
    this.setState({ selects });
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(loginUserActions.getData(searchCondition));
  };

  handleChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });

    this.search(tempCondition);
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleSuccessSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas, searchCondition,
      isSuccessOpen, selects, successMessage,
    } = this.state;
    return (
      <Container maxWidth="xl">
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={6}>
            <TextField name="searchName" placeholder="名前" size="small" variant="outlined" autoComplete="off" fullWidth value={searchCondition.searchName} onChange={this.onChange} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchSelectBox
              name="searchRoleId"
              placeholder="権限"
              options={selects}
              value={searchCondition.searchRoleId}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>

        <DataTable
          rows={datas.content || []}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          handleClose={this.handleSuccessSnackbarClose}
          message={successMessage}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginUserStore,
});

export default connect(mapStateToProps)(LoginUserList);
