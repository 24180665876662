/* eslint-disable max-len */
import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { payActions } from './payState';
import {
  getDatas, deleteDatas, saveData, getDataById, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PAY.PAY, payload);
    yield put(payActions.getDatasSuccess(result));
  } catch (_) {}
}

function* deleteData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SALES.PAY.PAY, payload);
    yield put(payActions.deleteDataSuccess());
  } catch (_) {}
}

function* getDataFromId({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SALES.PAY.PAY_ID, payload);
    yield put(payActions.getDataSuccess(result));
  } catch (_) {}
}

function* insertData({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.PAY.PAY, payload);
    yield put(payActions.insertDataSuccess(result));
  } catch (_) {}
}

function* updateDatas({ payload }) {
  try {
    const result = yield call(saveData, REST_API.SALES.PAY.PAY, payload);
    yield put(payActions.updateDataSuccess(result));
  } catch (_) {}
}

function* updateStatus({ payload }) {
  try {
    yield call(updateData, REST_API.SALES.PAY.PAY_ID, payload);
    yield put(payActions.updateStatusSuccess());
  } catch (_) {}
}

function* getSelect({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SALES.PAY.PAY_SELECT, payload);
    yield put(payActions.getSelectSuccess(result));
  } catch (_) {}
}

function* paySaga() {
  yield takeLatest('pay/getDatas', getData);
  yield takeLatest('pay/deleteData', deleteData);
  yield takeLatest('pay/getData', getDataFromId);
  yield takeLatest('pay/insertData', insertData);
  yield takeLatest('pay/updateData', updateDatas);
  yield takeLatest('pay/updateStatus', updateStatus);
  yield takeLatest('pay/getSelect', getSelect);
}

export default paySaga;
