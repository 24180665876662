import React from 'react';
import { isValid, format } from 'date-fns';
import jaLocale from 'dayjs/locale/ja';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export default function YearPicker(props) {
  const {
    value,
    handleChange,
    error,
    maxDate,
    minDate,
    name,
  } = props;

  const onChange = (newValue) => {
    let formatValue = newValue && newValue.$d;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd');
    }
    handleChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value && isValid(new Date(value)) ? new Date(value.replace(/-/g, '/')) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={jaLocale}>
      <DatePicker
        openTo="month"
        maxDate={maxDate}
        minDate={minDate}
        views={['year']}
        value={fnsValue}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} helperText={null} error={error} />}
        inputFormat="YYYY"
      />
    </LocalizationProvider>
  );
}
