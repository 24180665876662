/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const payDetailSlice = createSlice({
  name: 'payDetail',
  initialState: {
    datas: [],
    data: {},
    isSaveSuccess: false,
    isDeleteSuccess: false,
  },
  reducers: {
    getDatas: (state) => {
      state.datas = null;
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getDataFromId: (state) => {
      state.data = null;
    },
    getDataFromIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    getSelect: () => {},
    getSelectSuccess: (state, action) => {
      state.select = action.payload;
    },
  },
});

export const payDetailActions = payDetailSlice.actions;

export default payDetailSlice.reducer;
