import { isValid } from 'date-fns';

const requiredValidation = (value) => {
  if (!value) return '入力してください';
  return '';
};

const requiredStatusValidation = (value, status) => {
  if (status === '2' && !value) return '受注の場合は必須入力です';
  return '';
};

const requiredLostValidation = (value, status) => {
  if (status === '3' && !value) return '失注の場合は必須入力です';
  return '';
};

const dateValidation = (value, status) => {
  if (status === '2' && !value) return '受注の場合は必須入力です';
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

const dateTwoValidation = (value) => {
  if (value && !isValid(new Date(value))) return '日付形式で入力してください';
  return '';
};

class Validation {
  static formValidate = (type, value, status) => {
    switch (type) {
      case 'projectName':
      case 'nickName':
      case 'clientId':
      case 'contractKbn':
      case 'status':
        return requiredValidation(value);
      case 'stDt':
      case 'edDt':
      case 'orderDate':
        return dateValidation(value, status);
      case 'defectDate':
        return dateTwoValidation(value, status);
      case 'tantoBu':
      case 'projectOwner':
      case 'orderCause':
        return requiredStatusValidation(value, status);
      case 'lostCause':
        return requiredLostValidation(value, status);
      default:
        return '';
    }
  };

  static compareDateValidate = (valueStart, valueEnd) => {
    if (valueStart > valueEnd) return '開始より未来を設定してください';
    return '';
  };
}

export default Validation;
