import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { workSummaryActions } from './workSummaryState';
import { getDatas } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.WORK.WORK_SUMMARY, payload);
    yield put(workSummaryActions.getDataSuccess(result));
  } catch (_) {}
}

function* workSummarySaga() {
  yield takeLatest('workSummary/getData', getData);
}

export default workSummarySaga;
